import { httpClient } from "../../../../clients/http.client";
import { t_ProductionGeneralFormData } from "./ProductionGeneralForm.types";

const getProductionGeneralData = (payload: {}) => {
	return new Promise<t_ProductionGeneralFormData>((resolve, reject) => {
		httpClient.post("/admin/production/working_day").then(
			({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.details);
						break;

					default:
						reject(data.status);
						break;
				}
			},
			(error) => {
				reject(error);
			}
		);
	});
};

const updateProductionGeneralData = (
	payload: {} & t_ProductionGeneralFormData
) => {
	return new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/production/working_day/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

export { getProductionGeneralData, updateProductionGeneralData };
