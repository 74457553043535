import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Box, Typography, Button, MenuItem, FormControl, Select } from "@mui/material";
import { gsap } from "gsap";
import { useUserState } from "../../context/user/user.ctx";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import Bot from "../../beYou_bot.png";
import { ToastAction } from "../Toasts/ToastAction.ctx";
import { ToastsDisplay } from "../Toasts/ToastsDisplay.ctx";
import { set } from "date-fns";

interface ToastsError {
    error?: boolean;
    success?: boolean;
    show?: any;
    message?: any;
}
const useStyles = makeStyles((theme) => ({
    overlay: {
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 3000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    gridItem: {
        width: "100%",
        height: "100%",
        background: "red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontWeight: "bold",
        fontSize: 20,
        zIndex: 3000,
    },
    image: {
        opacity: 1,
        width: 80,
        height: 80,
    },
    visible: {
        transform: "translateX(0)",
        visibility: "visible",
        opacity: 1,
    },
    imageBottom: {
        transition: "transform 1s ease",
        transform: "translateY  (100%)",
    },
    visibleBottom: {
        transform: "translateY(0)",
        visibility: "visible",
    },
    textContainer: {
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
        opacity: 0,
        visibility: "hidden",
        borderRadius: 23,
        background: "red",
        display: "flex",
        alignItems: "center",
        padding: 20,
        flexFlow: "row",
        maxWidth: 600,
        minWidth: 450,
        marginBottom: 10,
        maxHeight: "auto",
        gap: "2rem",
        alignSelf: "flex-end",
    },
    close: {
        position: "absolute",
        top: -15,
        left: -5,
        width: 40,
        height: 40,
        objectFit: "contain",
        cursor: "pointer",
    },
    forward: {
        width: 30,
        height: 30,
        objectFit: "contain",
        cursor: "pointer",
    },
}));

const ToastsError = ({ error, message }: ToastsError) => {
    const classes = useStyles();
    const { showWeeklyNotification, setShowWeeklyNotification } = useContext(ToastAction);
    const { weeklySummary, setWeeklySummary } = useContext(ToastsDisplay);

    const [selectedValue, setSelectedValue] = useState("");
    const [removeNotif, setRemoveNotif] = useState(false);
    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };

    const handleClose = () => {
        if (error) {
            setRemoveNotif(true);
        }
        gsap.to(".summaryStatus", {
            x: "5000",
            duration: 5,
            ease: "power1.out",
            onStart: () => {
                gsap.set(".summaryStatus", { visibility: "visible", opacity: 1, duration: 1 });
            },
            // onComplete: () => {
            //     setRemoveNotif(true);
            // }
        });
    };
    useEffect(() => {
        if (!removeNotif) {
            gsap.fromTo(
                ".summaryStatus",
                { x: 5000, visibility: "hidden", opacity: 0 },
                { x: 0, duration: 1, opacity: 1, ease: "power1.out", delay: 1.1, visibility: "visible" },
            );
        }

        // if (error && !success) {
        //     setRemoveNotif(true);
        // } else if (success && !error) {
        //     setRemoveNotif(true);
        // } else {
        //     setRemoveNotif(false);
        // }
    }, [error]);

    return (
        <>
            {" "}
            {error ? (
                <div className={classes.overlay}>
                    <Grid container spacing={2} style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "95%",
                                alignItems: "flex-end",
                                gap: "1rem",
                                height: "50%",
                            }}
                        >
                            <Box className={`${classes.textContainer} summaryStatus`}>
                                <img id="weekly-review" src={Bot} className={classes.image} />
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        height: "100%",
                                        flexFlow: "column",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        style={{ minHeight: 20, fontWeight: "bold", color: "#FFFFFF" }}
                                    >
                                        {error ? "Error" : null}
                                    </Typography>
                                    <Box>
                                        <Typography
                                            variant="body1"
                                            align="left"
                                            style={{ textAlign: "left", color: "#FFFFFF" }}
                                        >
                                            {message}
                                        </Typography>
                                    </Box>
                                </Box>

                                <></>
                                {/* <img src="/images/ForwardArrow.png" onClick={handleOpen} className={classes.forward} /> */}
                                <img src="/images/Close.png" onClick={handleClose} className={classes.close} />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
        </>
    );
};

export default ToastsError;
