import { Fab, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getMetricFormat } from "../../misc/helpers";
import { t_User } from "../../types/user.types";
import CustomToolTip from "../Tooltip/Tooltip";

export type PillProps = t_User.Metric & {
    description?: string;
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: theme.spacing(5),
            boxShadow: "none",
            minWidth: 150,
        },
        pillText: {
            whiteSpace: "nowrap",
        },
        pillValue: {
            color: theme.palette.info.main,
        },
        tooltipText: {
            lineHeight: 1,
        },
    }),
);
const Pill: React.FC<PillProps> = ({ id, name, value, target, details, description }) => {
    const classes = useStyles();
    let navigate = useNavigate();

    const tooltipInit = () => {
        return (
            <Typography className={classes.tooltipText} variant="overline" style={{ whiteSpace: "pre-line" }}>
                {description && (
                    <>
                        {description}
                        <br />
                        <br />
                    </>
                )}
                <p>Metric Name: {name}</p>
                <p>Current Value: {value != null ? getMetricFormat(id, value) : "-"}</p>
                <br />
                <p>Target: {target != null ? getMetricFormat(id, target) : "-"}</p>
            </Typography>
        );
    };

    return (
        <CustomToolTip content={tooltipInit()}>
            <Fab
                variant="extended"
                color="secondary"
                className={classes.root}
                onClick={() => {
                    navigate(`/beyoucoach/insights/${id}`);
                }}
            >
                <Grid container spacing={2} justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                        <Typography className={classes.pillText} variant="overline">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.pillValue} variant="overline" align="right">
                            {getMetricFormat(id, value)}
                        </Typography>
                    </Grid>
                </Grid>
            </Fab>
        </CustomToolTip>
    );
};

export default Pill;
