import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, lastDayOfMonth } from "date-fns";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { useUserState } from "../../../../context/user/user.ctx";
import ProductionByDate from "../../tables/ProductionByDate/ProductionByDate";
import { getUserPeriodsEntity } from "../../tables/ProductionByDate/ProductionByDate.api";
import { t_ProductionPeriod } from "../../tables/ProductionByDate/ProductionSurveyPeriods";
import { defaultPeriod } from "../LeaderProduction/LeaderProduction.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationsContext } from "../../../../components/Toasts/NotificationsProvider.ctx";
import { Steps } from "intro.js-react";
import { useClearNotificationsMutation } from "../../../../redux/api/beYouCore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { styles } from "../LeaderProduction/LeaderProduction.styles";
import {
    setBeYouWalkThrough,
    setProductionWalkThrough,
    setWalkThroughMode,
} from "../../../../redux/slices/WalkThroughSlice";
import { useDispatch } from "react-redux";
const now = new Date();

interface Props {}

const UserProduction: React.FC<Props> = () => {
    const currentUser = useUserState();
    const [selectedDate, setSelectedDate] = React.useState<any>(new Date(Date.now()));
    const [period, setPeriod] = React.useState<t_ProductionPeriod>(defaultPeriod);
    const location = useLocation();
    const dispatch = useDispatch();
    const [clearNotifications] = useClearNotificationsMutation();
    const promptID = useSelector((state: RootState) => state.metrics.promptId);
    const stepsRef = React.useRef<any>(null);
    const navigate = useNavigate();
    const [initialStep, setInitialStep] = React.useState(0);
    const queryParams = new URLSearchParams(location.search);
    const classes = styles();
    const productionWalkthroughParam = queryParams.get("production_walkthrough");
    const productionWalkThrough = useSelector((state: RootState) => state.walkThrough.productionWalkThrough);
    const [prodWalkthrough, setProdWalkthrough] = React.useState(productionWalkthroughParam === "true");
    const { isProductionActive, setIsDowntimeModalActive, setIsProductionActive, setIsAdditionalVolumeeModalActive } =
        React.useContext(NotificationsContext);
    React.useEffect(() => {
        const relevantDate = selectedDate ? selectedDate : now;
        const fomDate = new Date(relevantDate.getFullYear(), relevantDate.getMonth());
        const firstOfMonth = format(fomDate, "yyyy-MM-dd");
        let pgPeriod: t_ProductionPeriod = {
            id: 0,
            month: firstOfMonth,
            start_date: firstOfMonth,
            end_date: format(lastDayOfMonth(relevantDate), "yyyy-MM-dd"),
        };

        getUserPeriodsEntity({
            month: firstOfMonth,
            entity_no: currentUser ? currentUser.entity_no : 0,
        })
            .then((periods) => {
                if (periods) {
                    const currMonth = selectedDate ? selectedDate.getMonth() : now.getMonth();
                    const periodsMonths: number[] =
                        periods.length > 0 ? periods.map((p: t_ProductionPeriod) => new Date(p.month).getMonth()) : [];

                    const inPeriodsIdx = periodsMonths.findIndex((perNum) => perNum === currMonth);
                    if (inPeriodsIdx !== -1) {
                        pgPeriod = periods[inPeriodsIdx];
                    }

                    setPeriod(pgPeriod);
                }
            })
            .catch((err) => {
                console.error(err);
                setPeriod(pgPeriod);
            });
    }, [selectedDate]);

    React.useEffect(() => {
        if (queryParams.get("production_walkthrough")) {
            setIsProductionActive(true);
            dispatch(setProductionWalkThrough(true));
        } else {
            setIsProductionActive(false);
            dispatch(setProductionWalkThrough(false));
        }
    }, [location, productionWalkThrough]);

    const onExit = () => {
        if (queryParams.get("production_walkthrough")) {
            location.search = "";
        } else {
            setIsProductionActive(false);
            setIsDowntimeModalActive(false);
            setIsAdditionalVolumeeModalActive(false);
            dispatch(setProductionWalkThrough(false));
            dispatch(setBeYouWalkThrough(false));
            dispatch(setWalkThroughMode(false));
            removeNotification(promptID);
        }
    };

    const steps = [
        {
            element: ".prod-by-date-area",
            title: "Table",
            intro: "Here you can capture your production.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".select-date-area",
            title: "Select a date",
            intro: "Here you can switch to view and edit your production for different dates.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".downtime-capture-area",
            title: "Downtime Capture",
            intro: "By clicking here, you can capture a downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-capture-area",
            title: "Additional Volume Capture",
            intro: "By clicking here, you can capture an additional volume capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-scapture-area",
            title: "Downtime capture Form",
            intro: "Lets take a look at capturing a downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-type",
            title: "Report Type",
            intro: "Select a downtime type to capture. ",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-minutes",
            title: "Minutes",
            intro: "Enter the amount of minutes you want to add to this downtime capture.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".downtime-capture-comment",
            title: "Comment",
            intro: "Enter a comment you want to add to this downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-clear",
            title: "Clear",
            intro: "You can clear the current entries by clicking this button. ",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-add",
            title: "Add",
            intro: "Once you are ready, you can capture the report by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-actions",
            title: "Actions",
            intro: "Once you have added a capture, you will see it appear here, team leaders can verify it here and you can see any comments.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-scapture-area",
            title: "Additional Volumne Capture Form",
            intro: "Lets take a look at capturing an additional volume capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-initative",
            title: "Initiative Type",
            intro: "Select an initiative to capture.  ",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-handing-time",
            title: "Handling Time",
            intro: "Enter a time(some initiative's time's are pre-configured with values).",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-weight",
            title: "Weight",
            intro: "Enter a weight(some initiative's weights are pre-configured with values).",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-actual-volume",
            title: "Actual Volume",
            intro: "Enter a value for the actual production volume.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-comment",
            title: "Comment",
            intro: "You can add a comment here about the capture report.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-clear",
            title: "Clear",
            intro: "You can clear the current entries by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-add",
            title: "Comment",
            intro: "Once you are ready, you can capture the report by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-actions",
            title: "Comment",
            intro: "Once you have added a capture, you will see it appear here, team leaders can verify it here and you can see any comments.",
            position: "right",
            disableInteraction: true,
        },
        {
            title: "Comment",
            intro: "You are done, please close the open modal",
            position: "right",
            disableInteraction: true,
        },
    ];
    const introOptions = {
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true,
        hideNext: false,
        doneLabel: "FINISH",
        tooltipClass: classes.toolTip,
        keyboardNavigation: false,
        exitOnOverlayClick: false,
    };
    const removeNotification = (id: number) => {
        if (id) {
            clearNotifications({ notification_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: userProduction.tsx:164 ~ .then ~ error:", error);
                });
        }
    };
    const switchSteps = (nextStepIndex: number, nextElement: any) => {
        switch (nextStepIndex) {
            case 0:
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.delete("production_walkthrough");
                const newURL = `${window.location.pathname}?${queryParams.toString()}`;
                window.history.replaceState(null, "", newURL);
                break;
            case 4:
                setIsDowntimeModalActive(true);
                break;
            case 11:
                setIsDowntimeModalActive(false);
                setIsAdditionalVolumeeModalActive(true);
                break;
            default:
        }
    };
    const onBeforeChange = (nextStepIndex: number) => {
        if (nextStepIndex >= 1 || (nextStepIndex < 18 && stepsRef.current)) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    };
    return (
        <>
            <SectionCard
                title="Production"
                fullWidth
                hasBackground
                className="user-prod-area"
                secondaryAction={
                    <DatePicker
                        className="select-date-area"
                        openTo="month"
                        views={["year", "month"]}
                        // label="Month Year"
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                }
            >
                <ProductionByDate
                    entity_no={currentUser.profile?.entity_no}
                    date={selectedDate}
                    start_date={period.start_date}
                    end_date={period.end_date}
                    className="prod-by-date-area"
                />
            </SectionCard>
            <Steps
                onStart={() => dispatch(setProductionWalkThrough(true))}
                enabled={isProductionActive}
                steps={steps}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsProductionActive(false);
                    setIsDowntimeModalActive(false);
                    setIsAdditionalVolumeeModalActive(false);
                    dispatch(setProductionWalkThrough(false));
                    dispatch(setBeYouWalkThrough(false));
                    dispatch(setWalkThroughMode(false));
                    removeNotification(promptID);
                }}
                // onBeforeExit={() => {
                //     location.search = "";
                // }}
                onExit={onExit}
                onChange={(nextStepIndex, nextElement) => switchSteps(nextStepIndex, nextElement)}
                onBeforeChange={onBeforeChange}
                ref={stepsRef}
            />
        </>
    );
};

export default UserProduction;
