import { CircularProgress, Typography } from "@mui/material";
import { Pagination } from '@mui/material';
import React, { useEffect, useState } from "react";
import BadgeList from "../../../../components/BadgeList/BadgeList";
import SectionCard, {
	SectionCardProps,
} from "../../../../components/SectionCard/SectionCard";
import { MiscBody } from "../../../../types/api.types";
import { getUserBadges } from "./ProfileBadges.api";
import profileBadgesStyles from "./ProfileBadges.styles";
import { t_UserBadge } from "./ProfileBadges.types";

interface Props {
	entity_no?: number;
	rows_per_page?: number;
	withPagination?: boolean;
	sectionCardProps?: SectionCardProps;
	badgeVariant?: "block" | "mini" | undefined;
}

const ProfileBadges: React.FC<Props> = ({
	entity_no,
	rows_per_page = 5,
	withPagination,
	sectionCardProps,
	badgeVariant,
}) => {
	const classes = profileBadgesStyles();

	const [badges, setBadges] = useState<t_UserBadge[]>();
	const [visualFeedback, setVisualFeedback] = React.useState<{
		loading: boolean;
		status?: "view_all";
	}>({
		loading: false,
	});

	const [pagination, setPagination] = useState<MiscBody["pagination"]>({
		page: 1,
		rows_per_page,
		count: 1,
	});

	const handleViewClick = (event: React.ChangeEvent<{}>) => {
		setVisualFeedback((state) => ({
			...state,
			status: state.status === "view_all" ? undefined : "view_all",
		}));
	};

	useEffect(() => {
		var isActive = true;
		if (entity_no) {
			setVisualFeedback((state) => ({ ...state, loading: true }));
			getUserBadges({ entity_no, pagination }).then((data) => {
				if (isActive) {
					withPagination &&
						setPagination((state) => ({
							...state,
							...data.pagination,
							rows_per_page,
							count:
								1 +
								Math.floor(
									(data.total_results ?? 1) / rows_per_page
								),
						}));
					setBadges(data.data.badges);
				}
				setVisualFeedback((state) => ({ ...state, loading: false }));
			});
		}
		return () => {
			isActive = false;
		};
	}, [entity_no, pagination?.page]);

	return (
		<SectionCard
			bottomActions={
				withPagination && (
					<div className={classes.fullWidthFlex}>
						<Pagination
							count={pagination?.count}
							page={pagination?.page}
							onChange={(_, page) =>
								setPagination(
									(state) => state && { ...state, page }
								)
							}
							size='large'
						/>
					</div>
				)
			}
			{...sectionCardProps}
		>
			{!!visualFeedback.loading ? (
				<div className={classes.fullWidthFlex}>
					<CircularProgress />
				</div>
			) : badges?.length ? (
				<BadgeList
					badges={badges.map((badge) => ({
						badge_img: badge.badge_img,
						title: badge.name,
						subtitle: badge.description,
						count: badge.count,
					}))}
					justify='flex-start'
					variant={badgeVariant}
				/>
			) : (
				<div className={classes.fullWidthFlex}>
					<Typography variant='caption'>
						{badges?.length === 0 && "No badges yet."}
					</Typography>
				</div>
			)}
		</SectionCard>
	);
};

export default ProfileBadges;
