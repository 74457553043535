import { httpClient } from "../../../../clients/http.client";
import { MiscBody } from "../../../../types/api.types";

const getGroupedChallengeHistory = async (
	payload: {
		challenge_type: string;
	} & MiscBody
) => {
	const body = {
		...payload,
		group_by: "month",
	};
	const response = await httpClient.post("/challenge/history", body);
	return response.data;
};

export { getGroupedChallengeHistory };
