import React from "react";
import { t_User } from "../../../../types/user.types";
import { getFriendStatus } from "./useFriendStatus.api";

interface Props {
	entity_no: t_User.Profile["entity_no"];
}
const useFriendStatus = (props: Props) => {
	const [isFriends, setIsFriends] = React.useState<boolean>();

	React.useEffect(() => {
		var isActive = true;

		getFriendStatus({ entity_no: props.entity_no }).then((data) => {
			if (isActive === true) {
				if (data?.status?.StatusID === 2) {
					setIsFriends(true);
				} else {
					setIsFriends(false);
				}
			}
		});

		return () => {
			isActive = false;
		};
	}, [props.entity_no]);

	return isFriends;
};

export default useFriendStatus;
