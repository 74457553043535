import React, { useContext } from "react";
import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { Box, Button, Typography } from "@mui/material";
import { Icons, toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setPromptDate, setPromptId } from "../../../redux/slices/MetricsSlice";
import { NotificationsContext } from "../../Toasts/NotificationsProvider.ctx";
import {
    setBeYouWalkThrough,
    setNotificationsWalkThrough,
    setPerformanceReflectionWalkThrough,
    setProductionWalkThrough,
    setTipsWalkThrough,
    setTwtWalkThrough,
} from "../../../redux/slices/WalkThroughSlice";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const Prompts = ({ prompt, setFeedbackOpen, removeNotification }: any) => {
    const { setShowFeedBackModal, setSidePanelChatBot, showWeeklyPrompt, setWeeklyPrompt, setIsOnBoarding } =
        useContext(NotificationsContext);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const promptTypes = () => {
        switch (prompt.prompt.type) {
            case "weekly_summary":
                setSidePanelChatBot(false);
                setShowFeedBackModal(true);
                dispatch(setPromptDate(prompt.prompt.date));
                break;
            case "walkthrough":
                if (location.pathname !== "/beyoucoach/dashboard") {
                    navigate("/beyoucoach/dashboard");
                }
                setSidePanelChatBot(false);
                dispatch(setBeYouWalkThrough(true));
                dispatch(setNotificationsWalkThrough(true));
                dispatch(setPerformanceReflectionWalkThrough(true));
                dispatch(setProductionWalkThrough(true));
                dispatch(setTipsWalkThrough(true));
                dispatch(setTwtWalkThrough(true));
                setIsOnBoarding(true);
                dispatch(setPromptId(prompt.id));
                break;

            default:
                break;
        }
    };

    return (
        <Box key={prompt?.id}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {prompt?.icon ||
                        Icons.info({
                            theme: prompt?.theme || "light",
                            type: toast.TYPE.INFO,
                            width: 30,
                            height: 30,
                        })}
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }} onClick={promptTypes}>
                            {prompt?.prompt?.message}
                            <Typography variant="body2" color="textSecondary" style={{ fontSize: 10, maxWidth: "90%" }}>
                                {prompt?.prompt?.message_2}
                            </Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={prompt?.prompt?.created_at} />
                            </Typography>
                        </Typography>
                        {/* <ItemActions notification={prompt} markAsRead={markAsRead} remove={remove} /> */}
                        {prompt.prompt.type !== "weekly_summary" && (
                            <Button
                                onClick={() => {
                                    removeNotification(prompt.id);
                                }}
                            >
                                <Close color="error" fontSize="small" />
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Prompts;
