import { Card, CardContent, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import MetricSurveyRecords from "../../../Surveys/tables/MetricSurveyRecords/MetricSurveyRecords";
import { t_PerformanceUser } from "../../Performance.ctx";
import { t_MetricPerformance } from "../../Performance.types";
import MetricGeneralRecords from "../../tables/MetricGeneralRecords/MetricGeneralRecords";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        lsaDialog: {
            background: "none",
        },
    }),
);
interface UserMetricRecordsProps {
    viewMetric: t_MetricPerformance;
    month: string;
    viewUser: t_PerformanceUser;
}

const MetricTableSwitch: React.FC<UserMetricRecordsProps> = ({ viewMetric, month, viewUser }) => {
    switch (viewMetric.id) {
        case 1:
        case 6:
        case 11:
        case 13:
            // case 14:
            return <MetricGeneralRecords metric_id={1} viewUser={viewUser} month={month} />;
        case 2:
        case 5:
        case 7:
        case 8:
        case 9:
        case 10:
        case 12:
        case 14:
        case 16:
            return <MetricGeneralRecords metric_id={viewMetric.id} viewUser={viewUser} month={month} />;
        case 3:
        case 4:
            return <MetricSurveyRecords viewUser={viewUser} viewMetric={viewMetric} month={month} />;

        default:
            return (
                <Card>
                    <CardContent>We struggled to find data for this metric.</CardContent>
                </Card>
            );
    }
};

const UserMetricRecords: React.FC<UserMetricRecordsProps> = (props) => {
    const classes = useStyles();

    return <MetricTableSwitch {...props} />;
};

export default UserMetricRecords;
