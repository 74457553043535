import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const sectionedDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			background: "none",
			overflowX: "hidden",
			boxShadow: "none",
			maxWidth: "none",
		},
		dialogContent: {
			padding: "8px 0 8px 0",
			overflowX: "hidden",
			overflowY: "auto",
		},
		dialogHeader: {
			backgroundColor: theme.palette.background.paper,
			textTransform: "capitalize",
		},
		dialogPaper: {
			height: "100%",
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(2),
		},
	})
);

export default sectionedDialogStyles;
