import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const userPerformanceSummaryStyles = makeStyles((theme: Theme) =>
    createStyles({
        paddedAvatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        userCard: {
            padding: theme.spacing(2),
        },
        metricCell: {
            cursor: "pointer",
            height: 90,
        },
        metricContainer: {
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(2),
            gap: theme.spacing(2),
            flexWrap: "wrap",
        },
        metricDetails: {
            cursor: "pointer",
            position: "relative",
            height: 120,
            width: 120,
            padding: theme.spacing(1),
            display: "grid",
            placeItems: "center",
            "&:hover": {
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.contrastText,
            },
        },
        metricTarget: {
            position: "absolute",
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            //@ts-ignore
            color: theme.palette.text.hint,
        },
        metricValue: {
            display: "flex",
            position: "relative",
        },
        metricComparison: {
            position: "absolute",
            left: 0,
            transform: "translateX(-100%)",
        },
        metricLabel: {
            position: "absolute",
            top: theme.spacing(1),
        },
        activeMetric: {
            backgroundColor: `${theme.palette.info.main} !important`,
            boxShadow: theme.shadows[2],
        },
        tableContainer: {
            height: "100%",
            width: "auto",
        },
        table: {
            borderCollapse: "separate",
            borderSpacing: theme.spacing(0, 2),
        },
        row: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(2),
            "& td, & th": {
                position: "relative",
                backgroundColor: theme.palette.primary.main,
                border: "none",
                padding: theme.spacing(1),
                minWidth: 160,
            },
            "& td::after": {
                content: "''",
                position: "absolute",
                height: "60%",
                width: 1,
                background: theme.palette.divider,
                right: 0,
                top: "20%",
            },
            "& th": {
                "&:first-child": {
                    paddingLeft: theme.spacing(2),
                },
                "&:last-child": {
                    paddingRight: theme.spacing(2),
                },
            },
        },
    }),
);

export default userPerformanceSummaryStyles;
