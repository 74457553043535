export const allMetrics = [
    {
        id: 0,
        name: "ALL",
    },
    {
        id: 1,
        name: "SSM",
    },
    {
        id: 2,
        name: "MBR",
    },
    {
        id: 3,
        name: "FCR",
    },
    {
        id: 4,
        name: "DPMO",
    },
    {
        id: 5,
        name: "AAA",
    },
    {
        id: 6,
        name: "Production",
    },
    {
        id: 7,
        name: "Knowledge Assessments",
    },
    // New metrics added below
    {
        id: 8,
        name: "TL SSM",
    },
    {
        id: 9,
        name: "TWT",
    },
    {
        id: 10,
        name: "TL Responsibilities",
    },
    {
        id: 11,
        name: "Service Level",
    },
    {
        id: 12,
        name: "Knowledge Assessment",
    },
    {
        id: 13,
        name: "TL People SSM",
    },
    {
        id: 14,
        name: "Adhoc Errors",
    },
    {
        id: 15,
        name: "Pinpoint Missed",
    },
    {
        id: 16,
        name: "Project Evaluation Multiplier",
    },
    {
        id: 17,
        name: "TAT",
    },
    {
        id: 18,
        name: "SPS",
    },
    {
        id: 19,
        name: "Activation Rate",
    },
    {
        id: 20,
        name: "Perception",
    },
    {
        id: 21,
        name: "Quarterly Objective",
    },
    {
        id: 22,
        name: "Combined Arrears SSM",
    },
    {
        id: 23,
        name: "BS Recon",
    },
    {
        id: 24,
        name: "Demographics SSM",
    },
    {
        id: 25,
        name: "Metric SSM",
    },
    {
        id: 26,
        name: "Rating",
    },
    {
        id: 27,
        name: "Hit Rate",
    },
    {
        id: 28,
        name: "Management Rating",
    },
    {
        id: 29,
        name: "Recruitment Target",
    },
    {
        id: 30,
        name: "Trainee Performance",
    },
    {
        id: 31,
        name: "Tasks",
    },
    {
        id: 32,
        name: "Errors",
    },
    {
        id: 33,
        name: "Visits",
    },
    {
        id: 34,
        name: "Facility Survey",
    },
    {
        id: 35,
        name: "Qualifier",
    },
    {
        id: 36,
        name: "Wellness Days",
    },
    {
        id: 37,
        name: "Employer Liaison",
    },
    {
        id: 38,
        name: "Admission Management",
    },
    {
        id: 39,
        name: "Readmission Prevention",
    },
    {
        id: 40,
        name: "Volume Booster Non-Emerg Admis",
    },
    {
        id: 41,
        name: "Collection Success",
    },
    {
        id: 42,
        name: "Informa Savings",
    },
    {
        id: 43,
        name: "Post Go Live",
    },
    {
        id: 44,
        name: "Admin",
    },
    {
        id: 45,
        name: "Service Score",
    },
    {
        id: 46,
        name: "Coverage",
    },
    {
        id: 47,
        name: "DBR",
    },
    {
        id: 48,
        name: "Regular Users Portfolio",
    },
    {
        id: 49,
        name: "Enrollment",
    },
    {
        id: 50,
        name: "Engaged Users of Portfolio",
    },
    {
        id: 51,
        name: "Engaged Users of Target",
    },
    {
        id: 52,
        name: "Happiness Survey",
    },
    {
        id: 53,
        name: "Monthly Objectives",
    },
    {
        id: 54,
        name: "Transformation",
    },
    {
        id: 55,
        name: "NPS Survey",
    },
    {
        id: 56,
        name: "Disputes",
    },
    {
        id: 57,
        name: "QA Clinical Reporting",
    },
    {
        id: 58,
        name: "Sample Audit",
    },
    {
        id: 59,
        name: "Team Target",
    },
    {
        id: 60,
        name: "QA of QA",
    },
    {
        id: 61,
        name: "QA Error",
    },
    {
        id: 62,
        name: "Continuous Improvement",
    },
    {
        id: 63,
        name: "Engagement",
    },
    {
        id: 64,
        name: "Daily Cases Worked",
    },
    {
        id: 65,
        name: "Book Worked",
    },
    {
        id: 66,
        name: "Improvement",
    },
    {
        id: 67,
        name: "Trainer Ready Assess",
    },
    {
        id: 68,
        name: "Trainer Survey",
    },
    {
        id: 69,
        name: "Management Score",
    },
    {
        id: 70,
        name: "Wellness Advisor Connect",
    },
    {
        id: 71,
        name: "Performance Score",
    },
    {
        id: 72,
        name: "Delivery to Schedule",
    },
    {
        id: 73,
        name: "Testing",
    },
    {
        id: 74,
        name: "Initiative",
    },
    {
        id: 75,
        name: "CES",
    },
    {
        id: 76,
        name: "Social Media KA",
    },
    {
        id: 77,
        name: "BLAS Loaded",
    },
    {
        id: 78,
        name: "BIC Innovation",
    },
    {
        id: 79,
        name: "ACC MAN SSM",
    },
    {
        id: 80,
        name: "Follow Up",
    },
    {
        id: 81,
        name: "Efficiency",
    },
    {
        id: 82,
        name: "PPS Monthly CIB",
    },
    {
        id: 83,
        name: "PPS Quarterly CIB",
    },
    {
        id: 84,
        name: "Convert to D2C",
    },
    {
        id: 85,
        name: "Conservation Rate",
    },
    {
        id: 86,
        name: "Member DO Arrears SSM",
    },
    {
        id: 87,
        name: "MDA Full Set Target",
    },
    {
        id: 88,
        name: "WAC Daily Ops",
    },
    {
        id: 89,
        name: "Paper Claims Received",
    },
    {
        id: 90,
        name: "Audit List Completed Filling",
    },
    {
        id: 91,
        name: "Sampleaudit",
    },
    {
        id: 92,
        name: "Dispatcher Error",
    },
    {
        id: 93,
        name: "Parcel Consolidation",
    },
    {
        id: 94,
        name: "Department Rotation",
    },
    {
        id: 95,
        name: "WS Coordinator Score",
    },
    {
        id: 96,
        name: "WS Coordinator Score Card",
    },
    {
        id: 97,
        name: "Call Rate",
    },
    {
        id: 98,
        name: "Clinical Audit",
    },
];

// export const allMetrics = [
//     { name: "SSM", id: "ssm_metric" },
//     { name: "MBR", id: "mbr_metric" },
//     { name: "FCR", id: "fcr_metric" },
//     { name: "DPMO", id: "dpmo_metric" },
//     { name: "AAA's", id: "aaa_metric" },
//     { name: "Production", id: "production_metric" },
//     { name: "Knowledge Assessments", id: "knowledge_metric" },
//     { name: "TL SSM", id: "tl_ssm_metric" },
//     { name: "TWT", id: "twt_metric" },
//     { name: "TL Responsibilities", id: "tl_responsibilities_metric" },
//     { name: "Service Level", id: "service_level_metric" },
//     { name: "Knowledge Assessment", id: "knowledge_assessment_metric" },
//     { name: "TL People SSM", id: "tl_people_ssm_metric" },
//     { name: "Adhoc Errors", id: "adhoc_errors_metric" },
//     { name: "Pinpoint Missed", id: "pinpoint_missed_metric" },
//     { name: "Project Evaluation Multiplier", id: "project_evaluation_multiplier_metric" },
//     { name: "TAT", id: "tat_metric" },
//     { name: "SPS", id: "sps_metric" },
//     { name: "Activation Rate", id: "activation_rate_metric" },
//     { name: "Perception", id: "perception_metric" },
//     { name: "Quarterly Objective", id: "quarterly_objective_metric" },
//     { name: "Combined Arrears SSM", id: "combined_arrears_ssm_metric" },
//     { name: "BS Recon", id: "bs_recon_metric" },
//     { name: "Demographics SSM", id: "demographics_ssm_metric" },
//     { name: "Metric SSM", id: "metric_ssm_metric" },
//     { name: "Rating", id: "rating_metric" },
//     { name: "Hit Rate", id: "hit_rate_metric" },
//     { name: "Management Rating", id: "management_rating_metric" },
//     { name: "Recruitment Target", id: "recruitment_target_metric" },
//     { name: "Trainee Performance", id: "trainee_performance_metric" },
//     { name: "Tasks", id: "tasks_metric" },
//     { name: "Errors", id: "errors_metric" },
//     { name: "Visits", id: "visits_metric" },
//     { name: "Facility Survey", id: "facility_survey_metric" },
//     { name: "Qualifier", id: "qualifier_metric" },
//     { name: "Wellness Days", id: "wellness_days_metric" },
//     { name: "Employer Liaison", id: "employer_liaison_metric" },
//     { name: "Admission Management", id: "admission_management_metric" },
//     { name: "Readmission Prevention", id: "readmission_prevention_metric" },
//     { name: "Volume Booster Non-Emerg Admis", id: "volume_boosternon_emerg_admis_metric" },
//     { name: "Collection Success", id: "collection_success_metric" },
//     { name: "Informa Savings", id: "informa_savings_metric" },
//     { name: "Post Go Live", id: "post_go_live_metric" },
//     { name: "Admin", id: "admin_metric" },
//     { name: "Service Score", id: "service_score_metric" },
//     { name: "Coverage", id: "coverage_metric" },
//     { name: "DBR", id: "dbr_metric" },
//     { name: "Regular Users Portfolio", id: "regular_users_portfolio_metric" },
//     { name: "Enrollment", id: "enrollment_metric" },
//     { name: "Engaged Users of Portfolio", id: "engaged_users_of_portfolio_metric" },
//     { name: "Engaged Users of Target", id: "engaged_users_of_target_metric" },
//     { name: "Happiness Survey", id: "happiness_survey_metric" },
//     { name: "Monthly Objectives", id: "monthly_objectives_metric" },
//     { name: "Transformation", id: "transformation_metric" },
//     { name: "NPS Survey", id: "nps_survey_metric" },
//     { name: "Disputes", id: "disputes_metric" },
//     { name: "QA Clinical Reporting", id: "qa_clinical_reporting_metric" },
//     { name: "Sample Audit", id: "sample_audit_metric" },
//     { name: "Team Target", id: "team_target_metric" },
//     { name: "QA of QA", id: "qa_of_qa_metric" },
//     { name: "QA Error", id: "qa_error_metric" },
//     { name: "Continuous Improvement", id: "continuous_improvement_metric" },
//     { name: "Engagement", id: "engagement_metric" },
//     { name: "Daily Cases Worked", id: "daily_cases_worked_metric" },
//     { name: "Book Worked", id: "book_worked_metric" },
//     { name: "Improvement", id: "improvement_metric" },
//     { name: "Trainer Ready Assess", id: "trainer_ready_assess_metric" },
//     { name: "Trainer Survey", id: "trainer_survey_metric" },
//     { name: "Management Score", id: "management_score_metric" },
//     { name: "Wellness Advisor Connect", id: "wellness_advisor_connect_metric" },
//     { name: "Performance Score", id: "performance_score_metric" },
//     { name: "Delivery to Schedule", id: "delivery_to_schedule_metric" },
//     { name: "Testing", id: "testing_metric" },
//     { name: "Initiative", id: "initiative_metric" },
//     { name: "CES", id: "ces_metric" },
//     { name: "Social Media KA", id: "social_media_ka_metric" },
//     { name: "BLAS Loaded", id: "blas_loaded_metric" },
//     { name: "BIC Innovation", id: "bic_innovation_metric" },
//     { name: "ACC MAN SSM", id: "acc_man_ssm_metric" },
//     { name: "Follow Up", id: "follow_up_metric" },
//     { name: "Efficiency", id: "efficiency_metric" },
//     { name: "PPS Monthly CIB", id: "pps_monthly_cib_metric" },
//     { name: "PPS Quarterly CIB", id: "pps_quarterly_cib_metric" },
//     { name: "Convert to D2C", id: "convert_to_d2c_metric" },
//     { name: "Conservation Rate", id: "conservation_rate_metric" },
//     { name: "Member DO Arrears SSM", id: "member_do_arrears_ssm_metric" },
//     { name: "MDA Full Set Target", id: "mda_full_set_target_metric" },
//     { name: "WAC Daily Ops", id: "wac_daily_ops_metric" },
//     { name: "Paper Claims Received", id: "paper_claims_received_metric" },
//     { name: "Audit List Completed Filling", id: "audit_list_completed_filling_metric" },
//     { name: "Sampleaudit", id: "sampleaudit_metric" },
//     { name: "Dispatcher Error", id: "dispatcher_error_metric" },
//     { name: "Parcel Consolidation", id: "parcel_consolidation_metric" },
//     { name: "Department Rotation", id: "department_rotation_metric" },
//     { name: "WS Coordinator Score", id: "ws_coordinator_score_metric" },
//     { name: "WS Coordinator Score Card", id: "ws_coordinator_score_card_metric" },
//     { name: "Call Rate", id: "call_rate_metric" },
//     { name: "Clinical Audit", id: "clinical_audit_metric" },
// ];

export const metrics = [
    {
        id: "ssm_metric",
        metric: "SSM",
    },
    {
        id: "mbr_metric",
        metric: "MBR",
    },
    {
        id: "fcr_metric",
        metric: "FCR",
    },
    {
        id: "dpmo_metric",
        metric: "DPMO",
    },
    {
        id: "production_metric",
        metric: "Production",
    },
    {
        id: "aaa_metric",
        metric: "AAA's",
    },
    {
        id: "knowledge_metric",
        metric: "Knowledge Assessments",
    },
    {
        id: "tl_ssm_metric",
        metric: "TL SSM",
    },
    {
        id: "twt_metric",
        metric: "TWT",
    },
    {
        id: "tl_responsibilities_metric",
        metric: "TL Responsibilities",
    },
    {
        id: "service_level_metric",
        metric: "Service Level",
    },
    {
        id: "knowledge_assessment_metric",
        metric: "Knowledge Assessment",
    },
    {
        id: "tl_people_ssm_metric",
        metric: "TL People SSM",
    },
    {
        id: "adhoc_errors_metric",
        metric: "Adhoc Errors",
    },
    {
        id: "pinpoint_missed_metric",
        metric: "Pinpoint Missed",
    },
    {
        id: "project_evaluation_multiplier_metric",
        metric: "Project Evaluation Multiplier",
    },
    {
        id: "tat_metric",
        metric: "TAT",
    },
    {
        id: "sps_metric",
        metric: "SPS",
    },
    {
        id: "activation_rate_metric",
        metric: "Activation Rate",
    },
    {
        id: "perception_metric",
        metric: "Perception",
    },
    {
        id: "quarterly_objective_metric",
        metric: "Quarterly Objective",
    },
    {
        id: "combined_arrears_ssm_metric",
        metric: "Combined Arrears SSM",
    },
    {
        id: "bs_recon_metric",
        metric: "BS Recon",
    },
    {
        id: "demographics_ssm_metric",
        metric: "Demographics SSM",
    },
    {
        id: "metric_ssm_metric",
        metric: "Metric SSM",
    },
    {
        id: "rating_metric",
        metric: "Rating",
    },
    {
        id: "hit_rate_metric",
        metric: "Hit Rate",
    },
    {
        id: "management_rating_metric",
        metric: "Management Rating",
    },
    {
        id: "recruitment_target_metric",
        metric: "Recruitment Target",
    },
    {
        id: "trainee_performance_metric",
        metric: "Trainee Performance",
    },
    {
        id: "tasks_metric",
        metric: "Tasks",
    },
    {
        id: "errors_metric",
        metric: "Errors",
    },
    {
        id: "visits_metric",
        metric: "Visits",
    },
    {
        id: "facility_survey_metric",
        metric: "Facility Survey",
    },
    {
        id: "qualifier_metric",
        metric: "Qualifier",
    },
    {
        id: "wellness_days_metric",
        metric: "Wellness Days",
    },
    {
        id: "employer_liaison_metric",
        metric: "Employer Liaison",
    },
    {
        id: "admission_management_metric",
        metric: "Admission Management",
    },
    {
        id: "readmission_prevention_metric",
        metric: "Readmission Prevention",
    },
    {
        id: "volume_boosternon_emerg_admis_metric",
        metric: "Volume Booster Non-Emerg Admis",
    },
    {
        id: "collection_success_metric",
        metric: "Collection Success",
    },
    {
        id: "informa_savings_metric",
        metric: "Informa Savings",
    },
    {
        id: "post_go_live_metric",
        metric: "Post Go Live",
    },
    {
        id: "admin_metric",
        metric: "Admin",
    },
    {
        id: "service_score_metric",
        metric: "Service Score",
    },
    {
        id: "coverage_metric",
        metric: "Coverage",
    },
    {
        id: "dbr_metric",
        metric: "DBR",
    },
    {
        id: "regular_users_portfolio_metric",
        metric: "Regular Users Portfolio",
    },
    {
        id: "enrollment_metric",
        metric: "Enrollment",
    },
    {
        id: "engaged_users_of_portfolio_metric",
        metric: "Engaged Users of Portfolio",
    },
    {
        id: "engaged_users_of_target_metric",
        metric: "Engaged Users of Target",
    },
    {
        id: "happiness_survey_metric",
        metric: "Happiness Survey",
    },
    {
        id: "monthly_objectives_metric",
        metric: "Monthly Objectives",
    },
    {
        id: "transformation_metric",
        metric: "Transformation",
    },
    {
        id: "nps_survey_metric",
        metric: "NPS Survey",
    },
    {
        id: "disputes_metric",
        metric: "Disputes",
    },
    {
        id: "qa_clinical_reporting_metric",
        metric: "QA Clinical Reporting",
    },
    {
        id: "sample_audit_metric",
        metric: "Sample Audit",
    },
    {
        id: "team_target_metric",
        metric: "Team Target",
    },
    {
        id: "qa_of_qa_metric",
        metric: "QA of QA",
    },
    {
        id: "qa_error_metric",
        metric: "QA Error",
    },
    {
        id: "continuous_improvement_metric",
        metric: "Continuous Improvement",
    },
    {
        id: "engagement_metric",
        metric: "Engagement",
    },
    {
        id: "daily_cases_worked_metric",
        metric: "Daily Cases Worked",
    },
    {
        id: "book_worked_metric",
        metric: "Book Worked",
    },
    {
        id: "improvement_metric",
        metric: "Improvement",
    },
    {
        id: "trainer_ready_assess_metric",
        metric: "Trainer Ready Assess",
    },
    {
        id: "trainer_survey_metric",
        metric: "Trainer Survey",
    },
    {
        id: "management_score_metric",
        metric: "Management Score",
    },
    {
        id: "wellness_advisor_connect_metric",
        metric: "Wellness Advisor Connect",
    },
    {
        id: "performance_score_metric",
        metric: "Performance Score",
    },
    {
        id: "delivery_to_schedule_metric",
        metric: "Delivery to Schedule",
    },
    {
        id: "testing_metric",
        metric: "Testing",
    },
    {
        id: "initiative_metric",
        metric: "Initiative",
    },
    {
        id: "ces_metric",
        metric: "CES",
    },
    {
        id: "social_media_ka_metric",
        metric: "Social Media KA",
    },
    {
        id: "blas_loaded_metric",
        metric: "BLAS Loaded",
    },
    {
        id: "bic_innovation_metric",
        metric: "BIC Innovation",
    },
    {
        id: "acc_man_ssm_metric",
        metric: "ACC MAN SSM",
    },
    {
        id: "follow_up_metric",
        metric: "Follow Up",
    },
    {
        id: "efficiency_metric",
        metric: "Efficiency",
    },
    {
        id: "pps_monthly_cib_metric",
        metric: "PPS Monthly CIB",
    },
    {
        id: "pps_quarterly_cib_metric",
        metric: "PPS Quarterly CIB",
    },
    {
        id: "convert_to_d2c_metric",
        metric: "Convert to D2C",
    },
    {
        id: "conservation_rate_metric",
        metric: "Conservation Rate",
    },
    {
        id: "member_do_arrears_ssm_metric",
        metric: "Member DO Arrears SSM",
    },
    {
        id: "mda_full_set_target_metric",
        metric: "MDA Full Set Target",
    },
    {
        id: "wac_daily_ops_metric",
        metric: "WAC Daily Ops",
    },
    {
        id: "paper_claims_received_metric",
        metric: "Paper Claims Received",
    },
    {
        id: "audit_list_completed_filling_metric",
        metric: "Audit List Completed Filling",
    },
    {
        id: "sampleaudit_metric",
        metric: "Sample Audit",
    },
    {
        id: "dispatcher_error_metric",
        metric: "Dispatcher Error",
    },
    {
        id: "parcel_consolidation_metric",
        metric: "Parcel Consolidation",
    },
    {
        id: "department_rotation_metric",
        metric: "Department Rotation",
    },
    {
        id: "ws_coordinator_score_metric",
        metric: "WS Coordinator Score",
    },
    {
        id: "ws_coordinator_score_card_metric",
        metric: "WS Coordinator Score Card",
    },
    {
        id: "call_rate_metric",
        metric: "Call Rate",
    },
    {
        id: "clinical_audit_metric",
        metric: "Clinical Audit",
    },
];

export const metricConfig = [
    { id: 1, name: "SSM", key: "ssm_metric" },
    { id: 2, name: "MBR", key: "mbr_metric" },
    { id: 3, name: "FCR", key: "fcr_metric" },
    { id: 4, name: "DPMO", key: "dpmo_metric" },
    { id: 5, name: "AAA's", key: "aaa_metric" },
    { id: 6, name: "Production", key: "production_metric" },
    { id: 7, name: "Knowledge Assessments", key: "knowledge_metric" },
    { id: 8, name: "TL SSM", key: "tl_ssm_metric" },
    { id: 9, name: "TWT", key: "twt_metric" },
    { id: 10, name: "TL Responsibilities", key: "tl_responsibilities_metric" },
    { id: 11, name: "Service Level", key: "service_level_metric" },
    { id: 12, name: "Knowledge Assessment", key: "knowledge_assessment_metric" },
    { id: 13, name: "TL People SSM", key: "tl_people_ssm_metric" },
    { id: 14, name: "Adhoc Errors", key: "adhoc_errors_metric" },
    { id: 15, name: "Pinpoint Missed", key: "pinpoint_missed_metric" },
    { id: 16, name: "Project Evaluation Multiplier", key: "project_evaluation_multiplier_metric" },
    { id: 17, name: "TAT", key: "tat_metric" },
    { id: 18, name: "SPS", key: "sps_metric" },
    { id: 19, name: "Activation Rate", key: "activation_rate_metric" },
    { id: 20, name: "Perception", key: "perception_metric" },
    { id: 21, name: "Quarterly Objective", key: "quarterly_objective_metric" },
    { id: 22, name: "Combined Arrears SSM", key: "combined_arrears_ssm_metric" },
    { id: 23, name: "BS Recon", key: "bs_recon_metric" },
    { id: 24, name: "Demographics SSM", key: "demographics_ssm_metric" },
    { id: 25, name: "Metric SSM", key: "metric_ssm_metric" },
    { id: 26, name: "Rating", key: "rating_metric" },
    { id: 27, name: "Hit Rate", key: "hit_rate_metric" },
    { id: 28, name: "Management Rating", key: "management_rating_metric" },
    { id: 29, name: "Recruitment Target", key: "recruitment_target_metric" },
    { id: 30, name: "Trainee Performance", key: "trainee_performance_metric" },
    { id: 31, name: "Tasks", key: "tasks_metric" },
    { id: 32, name: "Errors", key: "errors_metric" },
    { id: 33, name: "Visits", key: "visits_metric" },
    { id: 34, name: "Facility Survey", key: "facility_survey_metric" },
    { id: 35, name: "Qualifier", key: "qualifier_metric" },
    { id: 36, name: "Wellness Days", key: "wellness_days_metric" },
    { id: 37, name: "Employer Liaison", key: "employer_liaison_metric" },
    { id: 38, name: "Admission Management", key: "admission_management_metric" },
    { id: 39, name: "Readmission Prevention", key: "readmission_prevention_metric" },
    { id: 40, name: "Volume Booster Non-Emerg Admis", key: "volume_boosternon_emerg_admis_metric" },
    { id: 41, name: "Collection Success", key: "collection_success_metric" },
    { id: 42, name: "Informa Savings", key: "informa_savings_metric" },
    { id: 43, name: "Post Go Live", key: "post_go_live_metric" },
    { id: 44, name: "Admin", key: "admin_metric" },
    { id: 45, name: "Service Score", key: "service_score_metric" },
    { id: 46, name: "Coverage", key: "coverage_metric" },
    { id: 47, name: "DBR", key: "dbr_metric" },
    { id: 48, name: "Regular Users Portfolio", key: "regular_users_portfolio_metric" },
    { id: 49, name: "Enrollment", key: "enrollment_metric" },
    { id: 50, name: "Engaged Users of Portfolio", key: "engaged_users_of_portfolio_metric" },
    { id: 51, name: "Engaged Users of Target", key: "engaged_users_of_target_metric" },
    { id: 52, name: "Happiness Survey", key: "happiness_survey_metric" },
    { id: 53, name: "Monthly Objectives", key: "monthly_objectives_metric" },
    { id: 54, name: "Transformation", key: "transformation_metric" },
    { id: 55, name: "NPS Survey", key: "nps_survey_metric" },
    { id: 56, name: "Disputes", key: "disputes_metric" },
    { id: 57, name: "QA Clinical Reporting", key: "qa_clinical_reporting_metric" },
    { id: 58, name: "Sample Audit", key: "sample_audit_metric" },
    { id: 59, name: "Team Target", key: "team_target_metric" },
    { id: 60, name: "QA of QA", key: "qa_of_qa_metric" },
    { id: 61, name: "QA Error", key: "qa_error_metric" },
    { id: 62, name: "Continuous Improvement", key: "continuous_improvement_metric" },
    { id: 63, name: "Engagement", key: "engagement_metric" },
    { id: 64, name: "Daily Cases Worked", key: "daily_cases_worked_metric" },
    { id: 65, name: "Book Worked", key: "book_worked_metric" },
    { id: 66, name: "Improvement", key: "improvement_metric" },
    { id: 67, name: "Trainer Ready Assess", key: "trainer_ready_assess_metric" },
    { id: 68, name: "Trainer Survey", key: "trainer_survey_metric" },
    { id: 69, name: "Management Score", key: "management_score_metric" },
    { id: 70, name: "Wellness Advisor Connect", key: "wellness_advisor_connect_metric" },
    { id: 71, name: "Performance Score", key: "performance_score_metric" },
    { id: 72, name: "Delivery to Schedule", key: "delivery_to_schedule_metric" },
    { id: 73, name: "Testing", key: "testing_metric" },
    { id: 74, name: "Initiative", key: "initiative_metric" },
    { id: 75, name: "CES", key: "ces_metric" },
    { id: 76, name: "Social Media KA", key: "social_media_ka_metric" },
    { id: 77, name: "BLAS Loaded", key: "blas_loaded_metric" },
    { id: 78, name: "BIC Innovation", key: "bic_innovation_metric" },
    { id: 79, name: "ACC MAN SSM", key: "acc_man_ssm_metric" },
    { id: 80, name: "Follow Up", key: "follow_up_metric" },
    { id: 81, name: "Efficiency", key: "efficiency_metric" },
    { id: 82, name: "PPS Monthly CIB", key: "pps_monthly_cib_metric" },
    { id: 83, name: "PPS Quarterly CIB", key: "pps_quarterly_cib_metric" },
    { id: 84, name: "Convert to D2C", key: "convert_to_d2c_metric" },
    { id: 85, name: "Conservation Rate", key: "conservation_rate_metric" },
    { id: 86, name: "Member DO Arrears SSM", key: "member_do_arrears_ssm_metric" },
    { id: 87, name: "MDA Full Set Target", key: "mda_full_set_target_metric" },
    { id: 88, name: "WAC Daily Ops", key: "wac_daily_ops_metric" },
    { id: 89, name: "Paper Claims Received", key: "paper_claims_received_metric" },
    { id: 90, name: "Audit List Completed Filling", key: "audit_list_completed_filling_metric" },
    { id: 91, name: "Sampleaudit", key: "sampleaudit_metric" },
    { id: 92, name: "Dispatcher Error", key: "dispatcher_error_metric" },
    { id: 93, name: "Parcel Consolidation", key: "parcel_consolidation_metric" },
    { id: 94, name: "Department Rotation", key: "department_rotation_metric" },
    { id: 95, name: "WS Coordinator Score", key: "ws_coordinator_score_metric" },
    { id: 96, name: "WS Coordinator Score Card", key: "ws_coordinator_score_card_metric" },
    { id: 97, name: "Call Rate", key: "call_rate_metric" },
    { id: 98, name: "Clinical Audit", key: "clinical_audit_metric" },
];

export const metricKeys = [
    "ssm_metric",
    "mbr_metric",
    "fcr_metric",
    "dpmo_metric",
    "production_metric",
    "aaa_metric",
    "knowledge_metric",
    "tl_ssm_metric",
    "twt_metric",
    "tl_responsibilities_metric",
    "service_level_metric",
    "knowledge_assessment_metric",
    "tl_people_ssm_metric",
    "adhoc_errors_metric",
    "pinpoint_missed_metric",
    "project_evaluation_multiplier_metric",
    "tat_metric",
    "sps_metric",
    "activation_rate_metric",
    "perception_metric",
    "quarterly_objective_metric",
    "combined_arrears_ssm_metric",
    "bs_recon_metric",
    "demographics_ssm_metric",
    "Demographics SSM",
    "metric_ssm_metric",
    "rating_metric",
    "hit_rate_metric",
    "management_rating_metric",
    "recruitment_target_metric",
    "trainee_performance_metric",
    "tasks_metric",
    "errors_metric",
    "visits_metric",
    "facility_survey_metric",
    "qualifier_metric",
    "wellness_days_metric",
    "employer_liaison_metric",
    "admission_management_metric",
    "readmission_prevention_metric",
    "volume_boosternon_emerg_admis_metric",
    "collection_success_metric",
    "informa_savings_metric",
    "post_go_live_metric",
    "admin_metric",
    "service_score_metric",
    "coverage_metric",
    "dbr_metric",
    "regular_users_portfolio_metric",
    "enrollment_metric",
    "engaged_users_of_portfolio_metric",
    "engaged_users_of_target_metric",
    "happiness_survey_metric",
    "monthly_objectives_metric",
    "transformation_metric",
    "nps_survey_metric",
    "disputes_metric",
    "qa_clinical_reporting_metric",
    "sample_audit_metric",
    "team_target_metric",
    "qa_of_qa_metric",
    "qa_error_metric",
    "continuous_improvement_metric",
    "engagement_metric",
    "daily_cases_worked_metric",
    "book_worked_metric",
    "improvement_metric",
    "trainer_ready_assess_metric",
    "trainer_survey_metric",
    "management_score_metric",
    "wellness_advisor_connect_metric",
    "performance_score_metric",
    "delivery_to_schedule_metric",
    "testing_metric",
    "initiative_metric",
    "ces_metric",
    "social_media_ka_metric",
    "blas_loaded_metric",
    "bic_innovation_metric",
    "acc_man_ssm_metric",
    "follow_up_metric",
    "efficiency_metric",
    "pps_monthly_cib_metric",
    "pps_quarterly_cib_metric",
    "convert_to_d2c_metric",
    "conservation_rate_metric",
    "member_do_arrears_ssm_metric",
    "mda_full_set_target_metric",
    "wac_daily_ops_metric",
    "paper_claims_received_metric",
    "audit_list_completed_filling_metric",
    "sampleaudit_metric",
    "dispatcher_error_metric",
    "parcel_consolidation_metric",
    "department_rotation_metric",
    "ws_coordinator_score_metric",
    "ws_coordinator_score_card_metric",
    "call_rate_metric",
    "clinical_audit_metric",
];

export const emojiFace = [
    {
        id: 1,
        label: "",
        icon: "\u{1F600}",
    },
    {
        id: 2,
        label: "",
        icon: "\u{1F610}",
    },
    {
        id: 3,
        label: "",
        icon: "\u{1F641}",
    },
];

export const emojiThumb = [
    {
        id: 1,
        label: "",
        icon: "\u{1F44D}",
    },
    {
        id: 2,
        label: "",
        icon: "\u{1F44E}",
    },
];
