import React from "react";

export interface Props {
    maskID: string;
}

const makeColor = (name: string, color: string) => {
    const ColorComponent: React.FC<Props> = ({ maskID }) => (
        <g id={`Color/Hair/${name}`} mask={`url(#${maskID})`} fill={color}>
            <g transform="translate(-32.000000, 0.000000)" id="Color">
                <rect x="0" y="0" width="264" height="244" />
            </g>
        </g>
    );

    return ColorComponent;
};

const Auburn = makeColor("Auburn", "#A55728");
const Black = makeColor("Black", "#2C1B18");
const Blonde = makeColor("Blonde", "#B58143");
const BlondeGolden = makeColor("BlondeGolden", "#D6B370");
const Brown = makeColor("Brown", "#724133");
const BrownDark = makeColor("BrownDark", "#4A312C");
const Platinum = makeColor("Platinum", "#ECDCBF");
const Red = makeColor("Red", "#C93305");

// Define a type for the facial hair color options
export type FacialHairColorOption =
    | "Auburn"
    | "Black"
    | "Blonde"
    | "BlondeGolden"
    | "Brown"
    | "BrownDark"
    | "Platinum"
    | "Red"
    | "default";

// Mapping object for color components
const colorComponentsMap: Record<FacialHairColorOption, React.ComponentType<Props>> = {
    Auburn,
    Black,
    Blonde,
    BlondeGolden,
    Brown,
    BrownDark,
    Platinum,
    Red,
    default: Black,
};

interface FacialHairColorsProps {
    maskID: string;
    color: FacialHairColorOption;
}

const FacialHairColors: React.FC<FacialHairColorsProps> = ({ maskID, color = "default" }) => {
    const SelectedColor = colorComponentsMap[color] || colorComponentsMap["default"];
    return <SelectedColor maskID={maskID} />;
};

export default FacialHairColors;
