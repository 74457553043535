import { Edit } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    Grid,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import { useSelector } from "react-redux";

import { useUserState } from "../../../../context/user/user.ctx";
import { getImageUrl } from "../../../../misc/helpers";
import useWindowDimensions from "../../../../misc/screenSizes";
import { RootState } from "../../../../redux/store";
import OvationAvatarSvg from "../../../Ovation/OvationAvatarSvg";
import CreateAvatar from "../CreateAvatar/CreateAvatar";
import useProfileUpdate from "./profileUpdate";
import useProfileChange from "./userProfileChange";

const NewProfileCard = () => {
    const loggedInUser = useUserState();
    const { currentUser, user } = useProfileChange();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
    const editProfile = loggedInUser.entity_no === user.entity_no;
    const inputRef = useRef<HTMLInputElement>(null);
    const { height: screenHeight } = useWindowDimensions();
    const userAvatar = useSelector((state: RootState) => state.beYouAvatar.beYouAvatar);
    const isAvatarProfilePicture = Boolean(Number(userAvatar?.is_profile));

    const {
        textContent,
        handleTextContentChange,
        isBioUpdating,
        handleProfilePictureChange,
        handleSaveBio,
        isAvatarOpen,
        isBioOpen,
        setIsAvatarOpen,
        setIsBioOpen,
    } = useProfileUpdate();

    return (
        <>
            <Grid
                container
                direction={isMobile ? "column" : "row"}
                sx={{
                    background: theme.palette.primary.main,
                    borderRadius: 1,
                    boxShadow:
                        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                    overflowY: "scroll",
                    position: "relative",
                    height: "100%",
                }}
            >
                <Grid item xs={12}>
                    <Box
                        sx={{
                            backgroundImage: "url(/images/profile-bg.jpg)",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "17vh",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                background: "linear-gradient(to right bottom,rgba(0,0,0,0.3),rgba(0,0,0,0.3))",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        />
                    </Box>

                    {isAvatarProfilePicture && (
                        <Box
                            sx={{
                                position: "absolute",
                                width: 130,
                                height: 130,
                                left: 20,
                                top: screenHeight > 900 ? 60 : 30,
                                zIndex: 1,
                            }}
                        >
                            <OvationAvatarSvg />
                        </Box>
                    )}
                    {!isAvatarProfilePicture && (
                        <Box
                            sx={{
                                position: "absolute",
                                width: 130,
                                height: 130,
                                left: 20,
                                top: screenHeight > 900 ? 60 : 30,
                                cursor: "pointer",
                                zIndex: 1,
                            }}
                            onClick={() => setIsAvatarOpen(true)}
                        >
                            <Avatar
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    border: "2px solid #fff",
                                }}
                                src={getImageUrl("profile", currentUser?.profile_img)}
                            />
                            <Box
                                sx={{
                                    borderRadius: "100%",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    opacity: 0,
                                    transition: "opacity 0.3s",
                                    "&:hover": {
                                        opacity: 1,
                                    },
                                }}
                            >
                                Click to edit
                            </Box>
                        </Box>
                    )}

                    <Box sx={{ padding: 2 }}>
                        <Box>
                            <Box sx={{ mb: isMobile ? 2 : 0 }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mt: 2,
                                        fontWeight: "bold",
                                        color:
                                            loggedInUser.entity_no != currentUser.entity_no
                                                ? theme.palette.info.main
                                                : theme.palette.primary.contrastText,
                                    }}
                                >
                                    {currentUser.name}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                    {currentUser.job_title}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: isMobile ? "column" : "row",
                                        alignItems: isMobile ? "flex-start" : "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="GrayText"
                                            sx={{ mt: 1, display: "flex", alignItems: "center" }}
                                        >
                                            <img
                                                src="/images/team.svg"
                                                alt="team"
                                                style={{ marginRight: 5, width: 15, height: 15 }}
                                            />{" "}
                                            {currentUser.discovery_team}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="GrayText"
                                            sx={{ mt: 1, display: "flex", alignItems: "center" }}
                                        >
                                            <img
                                                src="/images/division.svg"
                                                alt="division"
                                                style={{ marginRight: 5, width: 15, height: 15 }}
                                            />{" "}
                                            {currentUser.division}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="GrayText"
                                            sx={{ mt: 1, display: "flex", alignItems: "center" }}
                                        >
                                            <img
                                                src="/images/building.svg"
                                                alt="cost center"
                                                style={{ marginRight: 5, width: 15, height: 15 }}
                                            />
                                            {currentUser.cost_center}
                                        </Typography>
                                        {/* <Typography
                                            variant="caption"
                                            color="GrayText"
                                            sx={{ mt: 1, display: "flex", alignItems: "center" }}
                                        >
                                            <img
                                                src="/images/work_history.svg"
                                                alt="work history"
                                                style={{ marginRight: 5, width: 15, height: 15 }}
                                            />{" "}
                                            Commencement:{" "}
                                            <span style={{ marginLeft: 5, fontWeight: "normal", fontSize: "0.9em" }}>
                                                {formatDate(currentUser.start_date)} ({tenure(currentUser.start_date)})
                                            </span>
                                        </Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ fontWeight: "bold" }}>About</Typography>
                                {editProfile && (
                                    <Tooltip title="Edit Bio">
                                        <Edit
                                            fontSize="inherit"
                                            onClick={() => setIsBioOpen(true)}
                                            sx={{ cursor: "pointer", fontSize: "0.9em" }}
                                            color="info"
                                        />
                                    </Tooltip>
                                )}
                            </Box>
                            <Typography variant="body2">{currentUser.bio}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={isBioOpen} onClose={() => setIsBioOpen(false)}>
                <Card sx={{ width: 400, padding: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Update your bio below
                        </Typography>

                        <TextField
                            fullWidth
                            multiline
                            rows={8}
                            value={textContent}
                            inputRef={inputRef}
                            onChange={handleTextContentChange}
                            color="secondary"
                            variant="outlined"
                        />
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" fullWidth onClick={handleSaveBio}>
                            {isBioUpdating ? <CircularProgress color="secondary" /> : "Update Bio"}
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
            <Dialog open={isAvatarOpen} onClose={() => setIsAvatarOpen(false)}>
                <CreateAvatar onAvatarCreated={handleProfilePictureChange} />
            </Dialog>
        </>
    );
};

export default NewProfileCard;
