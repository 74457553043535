import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

import { metricConfig } from "../../metrics";

interface RowData {
    metric: string;
    iqs_objective?: string | number;
    on_par?: string | number;
    target?: string | number;
    review_date?: string | Date | null;
}

const SubmittedMetricsTable = ({ data }: any) => {
    const columns = [
        {
            id: "metric",
            label: "Metric",
            type: "name",
        },
        {
            id: "iqs_objective",
            label: "IQS Objective",
            type: "input",
        },
        {
            id: "on_par",
            label: "On Par",
            type: "input",
        },
        {
            id: "target",
            label: "Target",
            type: "input",
        },
        {
            id: "review_date",
            label: "Review Date",
            type: "date",
        },
    ];

    const createData = (metric: any, iqs_objective: any, on_par: any, target: any, review_date: any) => {
        return { metric, iqs_objective, on_par, target, review_date };
    };

    const [rows, setRows] = useState<RowData[]>([]);

    useEffect(() => {
        const newRows: RowData[] = metricConfig.map(({ name, key }) =>
            createData(name, data[key]?.iqs_objective, data[key]?.on_par, data[key]?.target, data[key]?.review_date),
        );
        setRows(newRows);
    }, [data]);

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} align="center" sx={{ fontWeight: "bold" }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        const rowValues = Object.values(row).filter((value) => value != row.metric);
                        const isEmptyRow = rowValues.every((value) => value == null || value == "");

                        if (isEmptyRow) return null;

                        return (
                            <TableRow>
                                {columns.map((column) => {
                                    //@ts-ignore
                                    const value = row[column.id];

                                    return (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                            sx={{
                                                backgroundColor:
                                                    value == "😀" || value == "👍" || value == "✔"
                                                        ? "#D4EFDF"
                                                        : value == "😐"
                                                          ? "#FCF3CF"
                                                          : value == "🙁" || value == "👎" || value == "❌"
                                                            ? "#F5B7B1 "
                                                            : "",
                                            }}
                                        >
                                            {column.type == "date"
                                                ? moment(value, true).isValid()
                                                    ? moment(value).format("DD-MM-YYYY")
                                                    : ""
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubmittedMetricsTable;
