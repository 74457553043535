import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../store";
import {
    BadgePayload,
    badgesApiResponse,
    CoachTipApiResponse,
    CoachTipPayload,
    GlobalLeaderBoard,
    GlobalLeaderBoardPayload,
    MetricPayload,
    notificationsApiResponse,
    teamApiResponse,
} from "../types";

export const beYouCoreApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const authToken = state.auth;

            if (authToken?.api_access_token)
                headers.set("authorization", `${authToken?.token_type} ${authToken?.api_access_token}`);

            return headers;
        },
    }),
    reducerPath: "beYouCoreApi",
    endpoints: (builder) => ({
        //Metrics
        allMetrics: builder.mutation<API.Response.MetricsResponse, API.Payload.MetricPayload>({
            query: (payload) => ({
                url: `/metrics/summary/user`,
                method: "POST",
                body: payload,
            }),
        }),
        structuredTrend: builder.mutation<API.Response.MetricsResponse, API.Payload.MetricPayload>({
            query: (payload) => ({
                url: `/metrics/trend`,
                method: "POST",
                body: payload,
            }),
        }),
        structuredRecord: builder.mutation({
            query: (payload) => ({
                url: `/metrics/records`,
                method: "POST",
                body: payload,
            }),
        }),

        deleteIncentiveMetric: builder.mutation({
            query: (payload) => ({
                url: "admin/incentive/metrics/delete",
                method: "POST",
                body: payload,
            }),
        }),

        //Badges
        allBadges: builder.mutation<badgesApiResponse, BadgePayload>({
            query: (payload) => ({
                url: `/profile/badges/user`,
                method: "POST",
                body: payload,
            }),
        }),
        awardedBadges: builder.mutation({
            query: (payload) => ({
                url: `/badges/get`,
                method: "POST",
                body: payload,
            }),
        }),

        //Notifications
        getNotifications: builder.mutation<notificationsApiResponse, void>({
            query: (payload) => ({
                url: `newsfeed/notification/new`,
                method: "POST",
                body: payload,
            }),
        }),
        clearNotifications: builder.mutation({
            query: (payload) => ({
                url: `newsfeed/notification/clear`,
                method: "POST",
                body: payload,
            }),
        }),

        //Summaries
        getSummary: builder.mutation({
            query: (payload) => ({
                url: `/summary/getsummary`,
                method: "POST",
                body: payload,
            }),
        }),
        getSummaryById: builder.mutation({
            query: (payload) => ({
                url: `summary/getsummarybyid`,
                method: "POST",
                body: payload,
            }),
        }),
        saveSummary: builder.mutation({
            query: (payload) => ({
                url: `/summary/savesummary`,
                method: "POST",
                body: payload,
            }),
        }),

        //Team Members
        getTeamPerformance: builder.mutation<teamApiResponse, MetricPayload>({
            query: (payload) => ({
                url: `/metrics/summary/team`,
                method: "POST",
                body: payload,
            }),
        }),

        //Tips
        getAllTips: builder.mutation<CoachTipApiResponse, void>({
            query: (payload) => ({
                url: `/tips/all`,
                method: "POST",
                body: payload,
            }),
        }),

        getTip: builder.mutation<CoachTipApiResponse, CoachTipPayload>({
            query: (payload) => ({
                url: `/tips/gettip`,
                method: "POST",
                body: payload,
            }),
        }),

        //User
        getProfile: builder.query<any, void>({
            query: () => "/profile",
        }),
        getUserProfile: builder.mutation<any, any>({
            query: (payload) => ({
                url: "user-profile",
                method: "POST",
                body: payload,
            }),
        }),
        getCurrentChallenge: builder.mutation<any, void>({
            query: () => ({
                url: "/challenge/current",
                method: "POST",
            }),
        }),

        getBio: builder.mutation({
            query: (payload) => ({
                url: `/profile/bio`,
                method: "POST",
                body: payload,
            }),
        }),

        updateBio: builder.mutation({
            query: (payload) => ({
                url: "/profile/bio/update",
                method: "POST",
                body: payload,
            }),
        }),

        updateProfilePicure: builder.mutation({
            query: (payload) => ({
                url: "/profile/image/save",
                method: "POST",
                body: payload,
            }),
        }),

        //Messages
        getTeamMembers: builder.mutation<any, void>({
            query: () => ({
                url: "/messages/teammembers",
                method: "POST",
            }),
        }),

        getTeamMembersNotInThread: builder.mutation({
            query: (payload) => ({
                url: "/messages/teammembersnotinthread",
                method: "POST",
                body: payload,
            }),
        }),

        updateUsersInThread: builder.mutation({
            query: (payload) => ({
                url: "/messages/updateusersinthread",
                method: "POST",
                body: payload,
            }),
        }),

        getThreads: builder.mutation({
            query: (payload) => ({
                url: "/messages/threads",
                method: "POST",
                body: payload,
            }),
        }),

        readThread: builder.mutation({
            query: (payload) => ({
                url: "/messages/readthread",
                method: "POST",
                body: payload,
            }),
        }),

        createThread: builder.mutation({
            query: (payload) => ({
                url: "/messages/addthread",
                method: "POST",
                body: payload,
            }),
        }),

        getMessages: builder.mutation({
            query: (payload) => ({
                url: "",
                method: "POST",
                body: payload,
            }),
        }),

        sendMessage: builder.mutation({
            query: (payload) => ({
                url: "messages/sendmessage",
                method: "POST",
                body: payload,
            }),
        }),

        //dark mode
        getDarkMode: builder.mutation<any, void>({
            query: () => ({
                url: "/user/darkmode",
                method: "POST",
            }),
        }),

        setDarkMode: builder.mutation({
            query: (payload) => ({
                url: "/user/darkmode/save",
                method: "POST",
                body: payload,
            }),
        }),
        // Leaderboard
        getGlobalLeaderBoars: builder.mutation<GlobalLeaderBoard, GlobalLeaderBoardPayload>({
            query: (payload) => ({
                url: "/metrics/rank",
                method: "POST",
                body: payload,
            }),
        }),
        getSkills: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/skills",
                method: "POST",
            }),
        }),
        getJobTitles: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/job_titles",
                method: "POST",
            }),
        }),
        getDepartment: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/departments",
                method: "POST",
            }),
        }),
        getRegions: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/regions",
                method: "POST",
            }),
        }),
        getServiceTeams: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/service_teams",
                method: "POST",
            }),
        }),
        getDiscoveryTeams: builder.mutation<any, void>({
            query: () => ({
                url: "users/attributes/discovery_teams",
                method: "POST",
            }),
        }),
        getWidgetData: builder.mutation<any, any>({
            query: (payload) => ({
                url: "/metrics/rank/widget",
                method: "POST",
                body: payload,
            }),
        }),

        //Production Pools
        getExcludedPools: builder.query<any, void>({
            query: () => "/admin/production/team/pools/excluded",
        }),

        getIncludedPools: builder.query<any, void>({
            query: () => "/admin/production/team/pools/included",
        }),

        includePools: builder.mutation<any, any>({
            query: (payload) => ({
                url: "/admin/production/team/pools/include",
                method: "POST",
                body: payload,
            }),
        }),

        excludePools: builder.mutation<any, any>({
            query: (payload) => ({
                url: "/admin/production/team/pools/exclude",
                method: "POST",
                body: payload,
            }),
        }),

        //Forms
        getRequiredFields: builder.query({
            query: ({ formType, month, entity_no }) => {
                if (formType == "grow") {
                    return { url: `/coach/template/${formType}?entity_no=${entity_no}&month=${month}` };
                }
                return { url: `/coach/template/${formType}` };
            },
        }),

        getSubmittedForms: builder.query<any, void>({
            query: () => `/coach/submissions`,
        }),

        submitCoachForm: builder.mutation<any, any>({
            query: ({ submissionData, formEndPoint }) => ({
                url: `/coach/template/${formEndPoint}`,
                method: "POST",
                body: submissionData,
            }),
        }),

        getSubmissionCount: builder.query({
            query: ({ type, month, entity_no }) => {
                if (type != "adhoc" && type != "ops_loss" && type != "dpmo" && type != "perception") {
                    return { url: "" };
                }
                return { url: `coach/submissions/trend?type=${type}&month=${month}&entity_no=${entity_no}` };
            },
        }),

        coachSurvey: builder.mutation<any, any>({
            query: (payload) => ({
                url: "coach/survey",
                method: "POST",
                body: payload,
            }),
        }),

        //data file upload
        uploadDataFile: builder.mutation<any, any>({
            query: ({ file, fileEndpoint }) => ({
                url: `/import/${fileEndpoint}`,
                method: "POST",
                body: file,
            }),
        }),

        uploadSSMStatus: builder.query<any, void>({
            query: () => "import/ssm/status",
        }),

        //ovation
        ovationWords: builder.mutation<API.Response.OvationWordResponse, API.Payload.OvationWordPayload>({
            query: (payload) => ({
                url: "/ovation/words",
                method: "POST",
                body: payload,
            }),
        }),

        ovationRecord: builder.mutation<API.Response.OvationRecordResponse, API.Payload.OvationRecordPayload>({
            query: (payload) => ({
                url: "/ovation/records",
                method: "POST",
                body: payload,
            }),
        }),

        ovationTrend: builder.mutation<API.Response.OvationTrendResponse, API.Payload.OvationTrendPayload>({
            query: (payload) => ({
                url: "/ovation/trend",
                method: "POST",
                body: payload,
            }),
        }),

        //ovation negative
        ovationNegativeWords: builder.mutation<API.Response.OvationWordResponse, API.Payload.OvationWordPayload>({
            query: (payload) => ({
                url: "/ovation/negative/words",
                method: "POST",
                body: payload,
            }),
        }),

        ovationNegativeRecord: builder.mutation<API.Response.OvationRecordResponse, API.Payload.OvationRecordPayload>({
            query: (payload) => ({
                url: "/ovation/negative/records",
                method: "POST",
                body: payload,
            }),
        }),

        ovationNegativeTrend: builder.mutation<API.Response.OvationTrendResponse, API.Payload.OvationTrendPayload>({
            query: (payload) => ({
                url: "/ovation/negative/trend",
                method: "POST",
                body: payload,
            }),
        }),

        ovationLeaderboard: builder.mutation<
            API.Response.OvationLeaderboardResponse,
            API.Payload.OvationLeaderboardPayload
        >({
            query: (payload) => ({
                url: "/ovation/rank/team",
                method: "POST",
                body: payload,
            }),
        }),

        ovationGlobalLeaderboard: builder.mutation<
            API.Response.OvationLeaderboardResponse,
            API.Payload.OvationLeaderboardPayload
        >({
            query: (payload) => ({
                url: "/ovation/rank",
                method: "POST",
                body: payload,
            }),
        }),

        ovationNotification: builder.query<API.Response.OvationNotification, void>({
            query: () => "/ovation/me",
        }),

        //Avatar
        userAvatar: builder.query<API.Response.BeYouAvatarResponse, void>({
            query: () => "/ovation/avatar",
        }),

        getAvatarItems: builder.query<API.Response.AvatarItemsResponse, string>({
            query: (item) => `ovation/avatar/items/${item}`,
        }),

        avatarItemUnlock: builder.mutation<
            API.Response.BeYouAvatarItemUnlockResponse,
            API.Payload.BeYouAvatarItemUnlockPayload
        >({
            query: (payload) => ({
                url: "/ovation/avatar/unlock",
                method: "POST",
                body: payload,
            }),
        }),

        saveAvatar: builder.mutation<API.Response.BeYouAvatarSaveResponse, API.Payload.BeYouAvatarSavePayload>({
            query: (payload) => ({
                url: "/ovation/avatar/save",
                method: "POST",
                body: payload,
            }),
        }),
        saveAvatarImage: builder.mutation<
            API.Response.BeYouAvatarImageSaveResponse,
            API.Payload.BeYouAvatarImageSavePayload
        >({
            query: (payload) => ({
                url: "/ovation/avatar/image/save",
                method: "POST",
                body: payload,
            }),
        }),
    }),
});

export const {
    useAllBadgesMutation,
    useAllMetricsMutation,
    useAwardedBadgesMutation,
    useClearNotificationsMutation,
    useGetNotificationsMutation,
    useGetSummaryByIdMutation,
    useGetSummaryMutation,
    useGetTeamPerformanceMutation,
    useSaveSummaryMutation,
    useStructuredTrendMutation,
    useStructuredRecordMutation,
    useGetBioMutation,
    useUpdateBioMutation,
    useUpdateProfilePicureMutation,
    useGetCurrentChallengeMutation,
    useGetProfileQuery,
    useGetAllTipsMutation,
    useGetTipMutation,
    useGetTeamMembersMutation,
    useCreateThreadMutation,
    useGetMessagesMutation,
    useGetThreadsMutation,
    useSendMessageMutation,
    useGetTeamMembersNotInThreadMutation,
    useUpdateUsersInThreadMutation,
    useReadThreadMutation,
    useGetDarkModeMutation,
    useSetDarkModeMutation,
    useGetGlobalLeaderBoarsMutation,
    useGetJobTitlesMutation,
    useGetDepartmentMutation,
    useGetRegionsMutation,
    useGetServiceTeamsMutation,
    useGetDiscoveryTeamsMutation,
    useGetSkillsMutation,
    useGetWidgetDataMutation,
    useExcludePoolsMutation,
    useGetExcludedPoolsQuery,
    useIncludePoolsMutation,
    useGetIncludedPoolsQuery,
    useGetRequiredFieldsQuery,
    useGetSubmittedFormsQuery,
    useSubmitCoachFormMutation,
    useUploadDataFileMutation,
    useGetSubmissionCountQuery,
    useUploadSSMStatusQuery,
    useCoachSurveyMutation,
    useGetUserProfileMutation,
    useDeleteIncentiveMetricMutation,
    useOvationRecordMutation,
    useOvationTrendMutation,
    useOvationWordsMutation,
    useOvationNegativeRecordMutation,
    useOvationNegativeTrendMutation,
    useOvationNegativeWordsMutation,
    useAvatarItemUnlockMutation,
    useGetAvatarItemsQuery,
    useSaveAvatarMutation,
    useUserAvatarQuery,
    useOvationLeaderboardMutation,
    useOvationGlobalLeaderboardMutation,
    useSaveAvatarImageMutation,
    useOvationNotificationQuery,
} = beYouCoreApi;
