import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const homeStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: theme.palette.background.default,
			height: "100vh",
			width: "100vw",
		},
		loader: {
			position: "absolute",
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			zIndex: theme.zIndex.appBar + 1,
			backgroundColor: theme.palette.background.default,
		},
		disable: {
	pointerEvents:"none",
	content:"Loading",

		},
	})
);

export default homeStyles;
