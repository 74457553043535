import { Badge, Button, TableCell } from "@mui/material";
import { Add, ArrowDropDown } from "@mui/icons-material";
import clsx from "clsx";
import React, { ReactNode, useContext, useEffect } from "react";
import { usePermissions } from "../../../../../context/user/permissions.ctx";
import { useUserState } from "../../../../../context/user/user.ctx";
import { t_PoolColumn, t_PoolTableValues } from "../../../Production.types";
import productionTableStyles from "../../Production.table.styles";
import { t_ProductionBase } from "../../ProductionByDate/ProductionByDate.types";
import { t_ProductionCellsActions } from "./ProductionCells.type";
import { NotificationsContext } from "../../../../../components/Toasts/NotificationsProvider.ctx";

interface Props {
    entity_no: number;
    row: t_ProductionBase & t_PoolTableValues;
    onCellEvent?: (action: t_ProductionCellsActions) => void;
    poolColumns?: (t_PoolColumn & { field: string })[];
    children?: ReactNode;
}
const ProductionCells: React.FC<Props> = ({ entity_no, row, poolColumns, onCellEvent, children }) => {
    const classes = productionTableStyles();
    const currentUser = useUserState();
    const permissions = usePermissions();
    const {
        currentStepProduction,
        setCurrentStepProduction,
        isDowntimeModalActive,
        isAdditionalVolumeModalActive,
        setIsAdditionalVolumeeModalActive,
        setIsDowntimeModalActive,
    } = useContext(NotificationsContext);
    const handleCellClick = (name: t_ProductionCellsActions["type"]) => {
        if (onCellEvent) {
            onCellEvent({ type: name, row });
            if (name === "downtime" && isDowntimeModalActive) {
                setCurrentStepProduction(5);
            } else {
                setCurrentStepProduction(currentStepProduction);
            }
        } else {
            setCurrentStepProduction(0);
        }
    };
    useEffect(() => {
        if (isDowntimeModalActive) {
            handleCellClick("downtime");
            setIsAdditionalVolumeeModalActive(false);
        }
        if (isAdditionalVolumeModalActive) {
            setIsDowntimeModalActive(false);
            handleCellClick("additional_volume");
        }
    }, [isDowntimeModalActive, isAdditionalVolumeModalActive]);

    return (
        <>
            {children}
            <TableCell>
                {permissions.external_leave_create ? (
                    <Button
                        onClick={() => handleCellClick("leave")}
                        className={classes.cellButton}
                        variant="text"
                        // endIcon={<ArrowDropDown fontSize="inherit" />}
                    >
                        {row.leave}
                    </Button>
                ) : (
                    row.leave
                )}
            </TableCell>
            <TableCell>{row.target}</TableCell>
            <TableCell>{row.total}</TableCell>
            <TableCell align="center">{row.downtime_volume}</TableCell>
            <TableCell align="center" className="downtime-capture-area">
                {permissions.team_manage || currentUser.profile?.entity_no == entity_no ? (
                    <Badge variant="dot" invisible={!row.unapproved_downtime} color="primary">
                        <Button
                            onClick={() => handleCellClick("downtime")}
                            className={`${classes.cellButton} `}
                            variant="text"
                            endIcon={<Add fontSize="inherit" className="downtime-capture-area" />}
                        >
                            {row.downtime}
                        </Button>
                    </Badge>
                ) : (
                    row.downtime
                )}
            </TableCell>
            <TableCell align="center" className="additional-volume-capture-area">
                {permissions.team_manage || currentUser.profile?.entity_no == entity_no ? (
                    <Badge variant="dot" invisible={!row.unapproved_additional_volume} color="primary">
                        <Button
                            onClick={() => handleCellClick("additional_volume")}
                            className={classes.cellButton}
                            variant="text"
                            endIcon={<Add fontSize="inherit" />}
                        >
                            {row.additional_volume}
                        </Button>
                    </Badge>
                ) : (
                    row.additional_volume
                )}
            </TableCell>
            <TableCell
                align="center"
                className={clsx({
                    [classes.fadedCell]: row.actual_volume === row.total,
                })}
            >
                {row.actual_volume}
            </TableCell>
            <TableCell
                align="center"
                className={clsx(classes.borderedCell, {
                    [classes.boldCell]: row.weighted_volume !== 0,
                })}
            >
                {row.weighted_volume}
            </TableCell>
            {poolColumns?.map((column) => (
                <TableCell key={`${column.name}-value`} align="center">
                    {(column.field && row.pool_values[column.field]?.value) ?? "-"}
                </TableCell>
            ))}
        </>
    );
};

export default ProductionCells;
