import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Bar, Cell, ComposedChart, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface ReasonTrendData {
    [key: string]: number;
}

interface SubmissionFrequencyProps {
    data: {
        reason_trend: ReasonTrendData;
    };
    entity_no: number;
}

const SubmissionFrequency: React.FC<SubmissionFrequencyProps> = ({ data, entity_no }) => {
    const trendData = Array.isArray(data?.reason_trend) ? data?.reason_trend.filter((reason) => reason.count > 0) : [];
    const theme = useTheme();
    const truncateLabel = (label: string, maxLength: number) => {
        return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
    };

    return (
        <Box>
            {entity_no ? (
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart
                        width={500}
                        height={400}
                        data={trendData}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <XAxis
                            dataKey="name"
                            stroke={theme.palette.primary.contrastText}
                            interval={0}
                            tickFormatter={(label) => truncateLabel(label, 10)}
                        />
                        <YAxis stroke={theme.palette.primary.contrastText} />
                        <Tooltip />
                        <Legend />
                        <defs>
                            {trendData?.map((trend: any, index: any) => {
                                const colorBarDark = "#ff6242";
                                const colorBarLight = "#ff6242";

                                return (
                                    <linearGradient id={`insights${index}`} x1="0" y1="0" x2="0" y2="1" key={index}>
                                        <stop offset="5%" stopColor={colorBarDark} stopOpacity={0.8} />
                                        <stop offset="95%" stopColor={colorBarLight} stopOpacity={0.3} />
                                    </linearGradient>
                                );
                            })}
                        </defs>
                        <Bar
                            dataKey="count"
                            fill="#ff6242"
                            barSize={10}
                            radius={10}
                            name="Submission Reason"
                            isAnimationActive={false}
                        >
                            {trendData?.map((trend: any, index: any) => <Cell fill={`url(#insights${index})`} />)}
                            <LabelList dataKey="count" position="top" />
                        </Bar>
                    </ComposedChart>
                </ResponsiveContainer>
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> Team member not selected</Typography>
                </Box>
            )}
        </Box>
    );
};

export default SubmissionFrequency;
