import { Cancel, DeleteSweep, GetApp, Save } from "@mui/icons-material";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import BUMaterialTable from "../../../../../../components/BUMaterialTable/BUMaterialTable";
import DropdownSelect from "../../../../../../components/DropdownSelect/DropdownSelect";
import SubmitButton from "../../../../../../components/SubmitButton/SubmitButton";
import { useStateCallback } from "../../../../../../misc/hooks";
import { t_IncentiveType } from "../../MaintainIncentive.types";
import { MetricBand, MetricIncentive } from "../IncentiveDetails";
import { createIncentiveStructures, getMetrics } from "./IncentiveMetricDialog.api";

const EditMetricBandColumns = [
    {
        title: "From",
        field: "from",
        initialEditValue: 0,
    },
    {
        title: "To",
        field: "to",
        initialEditValue: 0,
    },
    {
        title: "Multiplier",
        field: "multiplier",
        initialEditValue: 0,
    },
];
interface IncentiveMetricDialogProps {
    category_id: t_IncentiveType["id"];
    metric: MetricIncentive;
    bands: MetricBand[];
    onClose: () => void;
}

const IncentiveMetricDialog: React.FC<IncentiveMetricDialogProps> = ({ category_id, metric, bands, onClose }) => {
    const tableRef = React.useRef<any>();
    const { enqueueSnackbar } = useSnackbar();
    const [saving, setSaving] = React.useState<boolean>(false);
    const [editMetric, setEditMetric] = useState({
        metric_id: metric.metric_id,
        game_metric: metric.game_metric,
        incentive_metric: metric.incentive_metric,
        from_date: (new Date(bands[0]?.from_date) as any) ?? null,
    });
    const [metricBands, setMetricBands] = useStateCallback<MetricBand[]>(bands);
    const [metrics, setMetrics] = useState<{ id: number; name: string }[]>([]);

    const handleSetEffectiveDate = (date: any) => {
        setEditMetric((state) => ({
            ...state,
            from_date: date,
        }));
    };
    const handleDetailsChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
        if (event.target?.name) {
            const name = event.target.name;
            setEditMetric((state) => ({
                ...state,
                [name]: Number(checked),
            }));
        }
    };
    const handleMetricChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => {
        if (event.target?.name) {
            const name = event.target.name;
            setEditMetric((state) => ({
                ...state,
                [name]: event.target.value,
            }));
        }
    };

    const handleNewBand = (newBand: MetricBand) => {
        return new Promise<void>((resolve) => {
            setMetricBands(
                (state: MetricBand[]) => {
                    const band = {
                        ...newBand,
                        id: state.length * -1,
                        band_date: "",
                        from_date: "",
                        to_date: "",
                    };
                    return [...state, band];
                },
                () => resolve(),
            );
        });
    };
    const handleBandEdit = (newData: MetricBand, oldData?: MetricBand | undefined) => {
        return new Promise<void>((resolve) => {
            setMetricBands(
                (state: MetricBand[]) => {
                    const index = state.findIndex((item) => item.id === newData.id);
                    const newState = Array.from(state);
                    newState[index] = { ...newData };
                    return newState;
                },
                () => resolve(),
            );
        });
    };
    const handleBandDuplicate = (e: any, rowData: MetricBand | MetricBand[]) => {
        if (!Array.isArray(rowData)) {
            setMetricBands((state: MetricBand[]) => {
                const band = {
                    ...rowData,
                    id: state.length * -1,
                    band_date: "",
                    from_date: "",
                    to_date: "",
                };
                return [...state, band];
            });
        }
    };
    const handleBulkUpdate = (
        changes: Record<
            number,
            {
                oldData: MetricBand;
                newData: MetricBand;
            }
        >,
    ) =>
        new Promise<void>((resolve) => {
            setMetricBands(
                (state: MetricBand[]) => {
                    const newState = state.map((val, ind) => {
                        if (ind in changes) {
                            return changes[ind].newData;
                        }
                        return val;
                    });
                    return newState;
                },
                () => resolve(),
            );
        });
    const handleBandDelete = (oldData: MetricBand) => {
        return new Promise<void>((resolve) => {
            setMetricBands(
                (state: MetricBand[]) => {
                    const index = state.findIndex((item) => item.id === oldData.id);
                    const newState = Array.from(state);
                    newState.splice(index, 1);
                    return newState;
                },
                () => resolve(),
            );
        });
    };
    const handleClearBands = () => {
        setMetricBands([]);
    };
    const handleMetricBandCreate = () => {
        if (editMetric.from_date == null) {
            enqueueSnackbar("Please select a date.");
            return;
        }

        setSaving(true);
        createIncentiveStructures({
            ...editMetric,
            from_date: editMetric.from_date.toDateString(),
            category_id,
            metric_bands: metricBands,
        })
            .then((data) => {
                enqueueSnackbar("Saved successfully.", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("We ran into an error.", { variant: "error" });
            })
            .finally(() => {
                setSaving(false);
                onClose();
            });
    };
    const handleGetMetrics = () => {
        getMetrics().then((data) => setMetrics(data));
    };
    return (
        <Dialog open={Boolean(editMetric)} onClose={() => onClose()}>
            <DialogTitle>Incentive Category Metric</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography>Details</Typography>
                    </Grid>
                    <Grid item sm={6}>
                        {metric.metric_name !== "" ? (
                            <TextField label="For Metric" value={metric.metric_name} fullWidth />
                        ) : (
                            <DropdownSelect
                                value={editMetric.metric_id}
                                onClick={handleGetMetrics}
                                name="metric_id"
                                label="Select Metric"
                                onChange={handleMetricChange}
                                fullWidth
                            >
                                {metrics.map((metric) => (
                                    <MenuItem value={metric.id}>{metric.name}</MenuItem>
                                ))}
                            </DropdownSelect>
                        )}
                    </Grid>
                    <Grid item sm={6}>
                        <DatePicker
                            // disableToolbar
                            // inputVariant='standard'
                            format="dd/MM/yyyy"
                            // margin='none'
                            // id='date-picker-inline'
                            label="Effective From Date"
                            value={editMetric.from_date}
                            onChange={handleSetEffectiveDate}
                            // fullWidth
                            slotProps={{
                                textField: {
                                    helperText: "YYYY-MM-DD",
                                    variant: "standard",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(editMetric.game_metric)}
                                    onChange={handleDetailsChange}
                                    name="game_metric"
                                    id="game_metric"
                                />
                            }
                            label="Game Metric"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(editMetric.incentive_metric)}
                                    onChange={handleDetailsChange}
                                    name="incentive_metric"
                                    id="incentive_metric"
                                />
                            }
                            label="Incentive Metric"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <BUMaterialTable
                            tableRef={tableRef}
                            title={"Metric Bands"}
                            columns={EditMetricBandColumns}
                            data={metricBands}
                            editable={{
                                onRowAdd: handleNewBand,
                                onRowUpdate: handleBandEdit,
                                onRowDelete: handleBandDelete,
                                onBulkUpdate: handleBulkUpdate,
                            }}
                            actions={[
                                {
                                    icon: GetApp,
                                    tooltip: "Copy band",
                                    onClick: handleBandDuplicate,
                                },
                                {
                                    icon: DeleteSweep,
                                    tooltip: "Clear bands",
                                    onClick: handleClearBands,
                                    isFreeAction: true,
                                },
                            ]}
                            options={{
                                search: false,
                                pageSize: 10,
                                padding: "dense",
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} startIcon={<Cancel />}>
                    Cancel
                </Button>
                <SubmitButton
                    disabled={!(metricBands != null && metricBands.length > 0)}
                    loading={saving}
                    startIcon={<Save />}
                    onClick={handleMetricBandCreate}
                >
                    Save
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default IncentiveMetricDialog;
