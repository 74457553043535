import { httpClient } from "../../clients/http.client";
import { MiscBody } from "../../types/api.types";
import { t_LookupField, t_UserBase } from "./User.types";

const getUserBasicInfo = async (payload: { entity_no: number }) => {
	const response = await httpClient.post("/users/detailed", payload);
	return response.data.data.details;
};

const getTeamMembers = (payload: { entity_no: number }) =>
	new Promise<t_UserBase[]>((resolve, reject) => {
		httpClient
			.post("/users/team_members", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.team_members);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const getServiceTeams = () =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/users/attributes/service_teams")
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.service_teams);
						break;
					default:
						reject(data.message);
						break;
				}
			})
			.catch(reject);
	});

const getSkills = () =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/users/attributes/skills")
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.skills);
						break;
					default:
						reject(data.message);
						break;
				}
			})
			.catch(reject);
	});

const getJobLevel = (payload: MiscBody) =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/users/attributes/job_levels", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.job_levels);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const getJobTitles = (payload: MiscBody) =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/users/attributes/job_titles", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.job_titles);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const getSummary = (payload: any) => {
	return new Promise<any>((res, rej) => {
		httpClient
			.post("/summary/getsummary", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						const respData = data.data;
						res({
							data: respData,

						});
						break;
					case "error":
						rej(data.message);
						break;
					default:
						rej(data.message);
				}
			})
			.catch((err) => rej(err.message));
	});
};
export {
	getUserBasicInfo,
	getTeamMembers,
	getServiceTeams,
	getSkills,
	getJobLevel,
	getJobTitles,
	getSummary
};
