import { List, ListItem, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getProductionGeneralData, updateProductionGeneralData } from "./ProductionGeneralForm.api";
import { t_ProductionGeneralFormData } from "./ProductionGeneralForm.types";

const productionGeneralSchema: yup.SchemaOf<t_ProductionGeneralFormData> = yup
    .object({
        working_day_minutes: yup
            .number()
            .min(1, "Must be greater than 0.")
            .max(720, "Must be less than 720 minutes")
            .required("Must be greater than 0 and less than 720 minutes."),
        expected_handling_time: yup
            .number()
            .min(1, "Must be greater than 0.")
            .max(60, "Must be less than 60 minutes")
            .required("Must be greater than 0 and less than 60 minutes."),
    })
    .defined();

interface Props {}

const ProductionGeneralForm: React.FC<Props> = ({}) => {
    const [formData, setFormData] = React.useState<t_ProductionGeneralFormData>({
        working_day_minutes: 1,
        expected_handling_time: 1,
    });

    const productionGeneralSubmit = (values: t_ProductionGeneralFormData) => {
        return updateProductionGeneralData(values);
    };
    React.useEffect(() => {
        var isActive = true;
        getProductionGeneralData({}).then((data) => {
            if (isActive) {
                setFormData(data);
            }
        });

        return () => {
            isActive = false;
        };
    }, []);

    return (
        <Formik
            initialValues={formData}
            validationSchema={productionGeneralSchema}
            onSubmit={productionGeneralSubmit}
            enableReinitialize
        >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <List>
                        <ListItem>
                            <TextField
                                fullWidth
                                id="working_day_minutes"
                                name="working_day_minutes"
                                type="number"
                                label="Working day (minutes)"
                                value={values.working_day_minutes}
                                onChange={handleChange}
                                error={touched.working_day_minutes && Boolean(errors.working_day_minutes)}
                                helperText={touched.working_day_minutes && errors.working_day_minutes}
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                fullWidth
                                id="expected_handling_time"
                                name="expected_handling_time"
                                type="number"
                                label="Default expected handling time (minutes)"
                                value={values.expected_handling_time}
                                onChange={handleChange}
                                error={touched.expected_handling_time && Boolean(errors.expected_handling_time)}
                                helperText={
                                    (touched.expected_handling_time && errors.expected_handling_time) ||
                                    "This is the handling time that will be associated to a weighting of 1."
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting}>
                                Update
                            </SubmitButton>
                        </ListItem>
                    </List>
                </form>
            )}
        </Formik>
    );
};

export default ProductionGeneralForm;
