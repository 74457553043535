import React from "react";

const Default = () => {
    return (
        <g id="Eyes/Default-😀" transform="translate(0.000000, 8.000000)" fillOpacity="0.599999964">
            <circle id="Eye" cx="30" cy="22" r="6" />
            <circle id="Eye" cx="82" cy="22" r="6" />
        </g>
    );
};

export default Default;
