import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const profileStatsStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
		cardContent: {
			padding: theme.spacing(4, 0),
		},
		progressListContainer: {
			padding: theme.spacing(0, 2),
		},
	})
);

export default profileStatsStyles;
