import { Box, Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import CoachProfileCard from "../../../Profile/components/CoachProfileCard/CoachProfileCard";
import SingleBadge from "../../../Badges/SingleBadge";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { usePerformanceContext } from "../../../Performance/Performance.ctx";
import ProgressBar from "@ramonak/react-progress-bar";
import { useAllMetricsMutation } from "../../../../redux/api/beYouCore";

const FriendProfile = ({ entity_no }: any) => {
    const [bades, setBadges] = useState(false);
    const [{ month, comparison_month }, performanceDispatch] = usePerformanceContext();

    const navigate = useNavigate();

    const [metrics, setMetrics] = useState<any>();

    const [allMetrics, { isLoading }] = useAllMetricsMutation();

    useEffect(() => {
        if (entity_no && month) {
            allMetrics({
                entity_no,
                month,
                comparison_month,
            })
                .unwrap()
                .then((data: any) => {
                    setMetrics(data.data.metric_values);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [month, entity_no]);

    return (
        <Grid>
            <Grid container spacing={2} style={{ padding: 16 }}>
                <Grid item xs={12} sm={12} lg={8} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SectionCard hasBackground>
                                <CoachProfileCard />
                            </SectionCard>
                        </Grid>
                        <Grid item xs={12}>
                            <SectionCard
                                title="Badges"
                                hasBackground
                                fontSize={20}
                                secondaryAction={
                                    <>
                                        <Button
                                            onClick={() => navigate("/beyoucoach/badges")}
                                            variant="contained"
                                            style={{ boxShadow: "none" }}
                                            color="secondary"
                                        >
                                            View all badges
                                        </Button>
                                        {/* {currentUser?.profile?.entity_no !== user?.entity_no ? (
                                            <Button
                                                onClick={() => setAwardBadge(true)}
                                                variant="contained"
                                                style={{
                                                    boxShadow: "none",
                                                    marginLeft: 10,
                                                }}
                                                color="secondary"
                                            >
                                                Award Badge
                                            </Button>
                                        ) : null} */}
                                    </>
                                }
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"flex-start"}
                                    alignContent={"center"}
                                    height={"100%"}
                                >
                                    <SingleBadge badgeOnly={true} badgeLimit entity_no={entity_no} />
                                    <Dialog open={bades} maxWidth={"xl"} scroll="body" onClose={() => setBadges(false)}>
                                        <SectionCard
                                            fontSize={20}
                                            hasBackground
                                            title={"Badge Showcase"}
                                            secondaryAction={
                                                <IconButton onClick={() => setBadges(false)}>
                                                    <Close />
                                                </IconButton>
                                            }
                                        >
                                            <Grid container spacing={3}>
                                                <SingleBadge badgeOnly={false} entity_no={entity_no} />
                                            </Grid>
                                        </SectionCard>
                                    </Dialog>
                                </Box>
                            </SectionCard>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={12} lg={4} maxHeight={400}>
                    <SectionCard
                        title="Performance Summary"
                        fontSize={20}
                        fullWidth
                        hasBackground
                        scrolling
                        hasDivider={false}
                    >
                        {metrics?.map((metric: any, i: any) => (
                            <Box key={metric.id} style={{}} mb={2} borderRadius="10px" p={1}>
                                <Box display="flex" justifyContent="space-between" px={1}>
                                    <Typography variant="body2" gutterBottom>
                                        {metric.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {" "}
                                        {metric.value} {metric.value && metric.target ? `/ ${metric.target}` : ""}
                                    </Typography>
                                </Box>
                                <ProgressBar
                                    completed={metric.percentage_of_target}
                                    height="5px"
                                    maxCompleted={100}
                                    isLabelVisible={false}
                                    bgColor={`${
                                        i === 0 || i === 1
                                            ? "#30D843"
                                            : i === metrics?.length - 1 || i === metrics?.length - 2
                                            ? "#FF4040"
                                            : "#0086FC"
                                    }`}
                                />
                            </Box>
                        ))}
                    </SectionCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FriendProfile;
