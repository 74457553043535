import { Box } from "@mui/material";
import { MTableBody, MTableBodyRow } from "material-table";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import ExportButton from "../../../components/ExportButton/ExportButton";
import { useReportExport } from "../../../hooks/export/useReportExport";
import { getApiUrl } from "../../../misc/helpers";

interface props {
    data: any;
    headings: any;
    loading: boolean;
    periodsArr: any;
}
const IqsObjectivesTable = (props: props) => {
    const { loading: exportLoading, downloadExportFileMulti } =
        useReportExport();

    const handleExportMulti = (file_type: string) => {
        let reqPeriods: string[] = [];
        reqPeriods = props.periodsArr.map((period: any) => {
            const [y, m, _] = period.split("-");
            return [y, m, "05"].join("-");
        });

        const lsa_exp_url = new URL(
            `${getApiUrl()}/admin/twt/iqsreports/export`
        );
        lsa_exp_url.searchParams.append("periods", reqPeriods.toString());
        lsa_exp_url.searchParams.append("file_type", file_type);
        downloadExportFileMulti(lsa_exp_url.toString(), "IQS Export");
    };

    const colorControl = (obj: any) => {
        let target = obj["Target"];
        let isBelow = false;
        for (var i = 0; i < Object.keys(obj).length; i++) {
            if (obj[Object.keys(obj)[2]] < target) {
                isBelow = true;
                break;
            }
        }
        if (isBelow === true) {
            return { backgroundColor: "#ffc5c5", fontSize: 12 };
        } else {
            return { fontSize: 12 };
        }
    };

    return (
        <>
            <Box
                marginLeft={"auto"}
                marginBottom={1}
                marginRight={5}
                width={"fit-content"}
            >
                <ExportButton
                    dispatch={handleExportMulti}
                    loading={exportLoading}
                    disabled={exportLoading}
                >
                    Export
                </ExportButton>
            </Box>
            <BUMaterialTable
                title={"IQS Objectives"}
                columns={props.headings}
                data={props.data}
                options={{
                    search: false,
                    sorting: false,
                    header: true,
                    draggable: false,
                    paging: false,
                    maxBodyHeight: "600px",
                }}
                isLoading={props.loading}
                components={{
                    Row: (props) => (
                        <>
                            <MTableBodyRow
                                {...props}
                                options={{
                                    rowStyle: colorControl(props.data),
                                }}
                            />
                        </>
                    ),
                    Body: (props) => (
                        <>
                            <MTableBody
                                option={{
                                    enableStickyHeader: true,
                                }}
                                {...props}
                            />
                        </>
                    ),
                }}
            />
        </>
    );
};

export default IqsObjectivesTable;
