import {
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	IconButton,
	InputBase,
	TextField,
} from "@mui/material";
import { Done, Edit, Error, Save } from "@mui/icons-material";
import React from "react";

interface Props {
	title: string;
	content: string;
	editable?: boolean;
	onEdit?: (current: string, updated: string) => Promise<boolean>;
	[propName: string]: any;
}

const TextCard: React.FC<Props> = ({
	title,
	content,
	editable,
	onEdit,
	...other
}) => {
	const [editing, setEditing] = React.useState(false);
	const [textContent, setTextContent] = React.useState(content);
	const [visualFeedback, setVisualFeedback] = React.useState<{
		loading: boolean;
		status?: "success" | "error";
	}>({
		loading: false,
	});
	const inputRef = React.useRef<HTMLInputElement>(null);

	/** Constants */
	const text_input_rows = 8;
	/** END */

	const handleTextContentChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setTextContent(event.currentTarget.value);
	};
	const handleActionClick = () => {
		if (editing) {
			if (onEdit !== undefined) {
				setVisualFeedback({ loading: true });

				onEdit(content, textContent).then((success) => {
					if (success) {
						setVisualFeedback({
							loading: false,
							status: "success",
						});
					} else {
						setVisualFeedback({ loading: false, status: "error" });
						setTextContent(content);
					}
					setTimeout(
						() => setVisualFeedback({ loading: false }),
						1000
					);
				});
			}
		} else {
			inputRef.current?.focus();
		}
		setEditing((state) => !state);
	};

	React.useEffect(() => {
		setTextContent(content);
	}, [content]);
	return (
        <Card {...other}>
			<CardHeader
				title={title}
				action={
					editable && (
						<IconButton
                            aria-label='Edit content.'
                            onClick={handleActionClick}
                            disabled={
								visualFeedback.loading ||
								Boolean(visualFeedback.status)
							}
                            size="large">
							{editing ? (
								<Save />
							) : (
								<>
									{!Boolean(visualFeedback.status) && (
										<Edit />
									)}
									{visualFeedback.status === "success" && (
										<Done />
									)}
									{visualFeedback.status === "error" && (
										<Error />
									)}
								</>
							)}
							{visualFeedback.loading && (
								<CircularProgress
									style={{ position: "absolute" }}
									color='secondary'
								/>
							)}
						</IconButton>
					)
				}
			/>
			<CardContent>
				{editing ? (
					<TextField
						fullWidth
						multiline
						rows={text_input_rows}
						value={textContent}
						inputRef={inputRef}
						onChange={handleTextContentChange}
						color='secondary'
						variant='outlined'
					/>
				) : (
					<InputBase
						fullWidth
						multiline
						readOnly={true}
						rows={text_input_rows}
						value={textContent}
					></InputBase>
				)}
			</CardContent>
		</Card>
    );
};

export default TextCard;
