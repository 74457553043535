import React, { ReactNode } from "react";
import { getMyMetrics } from "../../api/user.api";
import { t_User } from "../../types/user.types";

type t_UpdateStatus = "loading" | "updated" | "error" | "idle";

type t_UserMetrics = {
    metrics: Array<t_User.Metric>;
    status: t_UpdateStatus;
    // placeholder to stagger loading of the metrics
};

const DEFAULT: t_UserMetrics = {
    metrics: [],
    status: "idle",
};
interface t_UserMetricActions {
    fetch: () => Promise<boolean>;
}

type t_UserMetricContext = [t_UserMetrics, t_UserMetricActions];

const UserMetricsContext = React.createContext<t_UserMetricContext | undefined>(undefined);

const useUserMetrics = (): [t_UserMetrics, t_UserMetricActions] => {
    const context = React.useContext(UserMetricsContext);

    if (context === undefined) {
        throw new Error("Context must be used within it's relevant provider. [UserMetrics];");
    }

    return context;
};

const UserMetricsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userMetrics, setUserMetrics] = React.useState<t_UserMetrics>(DEFAULT);

    const actions: t_UserMetricActions = {
        fetch: React.useCallback(async (): Promise<boolean> => {
            setUserMetrics((state) => ({
                ...state,
                status: "loading",
            }));
            try {
                const metrics = await getMyMetrics();

                if (metrics.length > 0) {
                    setUserMetrics({
                        metrics: Array.from(metrics),
                        status: "updated",
                    });
                }
                return true;
            } catch (e: any) {
                // @MM we should be logging errors somewhere
                setUserMetrics({ metrics: [], status: "error" });
                throw e;
            }
        }, []),
    };
    /**
     * Clean up the status changes by allowing triggers from some active status (updated, error) to idle after a period of time.
     */
    React.useEffect(() => {
        let isActive = true;
        let status = userMetrics.status;

        let timer: NodeJS.Timeout;

        if (status === "updated" || status === "error") {
            timer = setTimeout(() => {
                if (isActive) {
                    setUserMetrics((state) => ({ ...state, status: "idle" }));
                }
            }, 1500);
        }

        return () => {
            isActive = false;

            if (timer != null) {
                clearTimeout(timer);
            }
        };
    }, [userMetrics.status]);

    return <UserMetricsContext.Provider value={[userMetrics, actions]}>{children}</UserMetricsContext.Provider>;
};

export { UserMetricsProvider, useUserMetrics };
