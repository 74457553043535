import Bot from "../../../beYou_bot.png";


const dummyMessages = [
    {
        id: 1,
        senderId: 1,
        recipientId: 2,
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra nulla eu felis vulputate, in commodo sapien semper. Nulla porta massa in nibh cursus, vitae vulputate metus finibus. Phasellus venenatis ut dui quis posuere. Integer tempor mollis purus, faucibus consectetur nisi convallis rhoncus. Ut eget dolor ornare, convallis diam a, ultricies nibh. Fusce eleifend mauris vel justo laoreet, ut pellentesque sapien maximus. Aenean vestibulum quam ligula, sit amet tempus tortor finibus quis. Nam in cursus libero. Cras quis dolor sem. Duis quis nunc at felis commodo aliquam et placerat justo.",
        timestamp: new Date("2023-08-15T08:00:00Z"),
    },
    {
        id: 2,
        senderId: 1,
        recipientId: 2,
        message: "I'm good too. Just working on a project.",
        timestamp: new Date("2023-08-15T08:05:00Z"),
    },
    {
        id: 3,
        senderId: 2,
        recipientId: 1,
        message: "Im working ona  project too",
        timestamp: new Date("2023-08-15T08:10:00Z"),
    },
    {
        id: 4,
        senderId: 3,
        recipientId: 1,
        message: "Im working ona  project too",
        timestamp: new Date("2023-08-15T08:10:00Z"),
    },

];

const dummyContacts = [
    {
        id: 1,
        name: "John Doe",
        unReadCount: 5,
        email: "john@example.com",
        avatar: Bot,
    },
    {
        id: 2,
        name: "John Doe",
        unReadCount: 5,
        email: "john@example.com",
        avatar: Bot,
    },
];

export { dummyMessages, dummyContacts };
