import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const globalSearchStyles = makeStyles((theme: Theme) =>
	createStyles({
		sectionContainer: {
			height: "100%",
		},
		subSectionContainer: {
			height: "100%",
		},
	})
);

export default globalSearchStyles;
