import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { getWeightedPools, saveWeightedPool } from "./PoolWeights.api";
import { t_WeightedPool } from "./PoolWeights.types";

const PoolWeights: React.FC = () => {
    const [columns] = React.useState<Column<t_WeightedPool>[]>([
        {
            title: "Pool Entity",
            field: "entity_no",
            editable: "onAdd",
        },
        { title: "Pool Name", field: "name", editable: "onAdd" },
        {
            title: "Expected Handling Time",
            field: "handling_time",
            editable: "always",
            validate: (rowData) => {
                let num = Number(rowData.handling_time ?? 0);
                if (num == null || num < 0 || num > 60) {
                    return "The entered time must be between 0-60 min.";
                }
                return true;
            },
            filtering: false,
        },
        {
            title: "Weight",
            field: "weight",
            editable: "never",
            filtering: false,
        },
    ]);
    return (
        <BUMaterialTable
            title="Pools"
            columns={columns}
            data={(query) => getWeightedPools({ query })}
            editable={{
                onRowUpdate: (newData, oldData) => saveWeightedPool({ pool: newData }),
            }}
            options={{
                actionsColumnIndex: -1,
                filtering: true,
                search: false,
                maxBodyHeight: "600px",
            }}
        />
    );
};

export default PoolWeights;
