import React from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import BUAutocomplete from "../../../../components/BUAutocomplete/BUAutocomplete";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { MiscBody } from "../../../../types/api.types";
import styles from "./styles.module.css";
import { getWeightedPoolsSearch } from "../../../Production/tables/PoolWeights/PoolWeights.api";
import { saveProductionTeamPool } from "../../ProductionPoolWeight/ProductPoolWeight.api";
// import { t_Pool } from "../../TwtGroup.types";

type ProductionPoolGroupProps = {
    handleClose: () => void;
    addTeamPool: (poolNumber: number, userEntityNo?: number) => void;
    userEntityNo?: number;
    memberName?: string;
};

const ProductionPoolGroup = ({ handleClose, addTeamPool, userEntityNo }: ProductionPoolGroupProps) => {
    const [currentPool, setCurrentPool] = React.useState<any>();
    const [currentHandlingTime, setCurrentHandlingTime] = React.useState<any>();

    const selectPools = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedPoolsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const poolOpts = resp.data.pools.map((pool: any) => ({
                        id: pool.id,
                        name: pool.name + "   " + "(" + pool.entity_no + ")",
                        entity_no: pool.entity_no,
                        weight: pool.weight,
                    }));

                    res(
                        poolOpts.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        })),
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const handleSelectPool = (value: any) => {
        if (value) {
            setCurrentPool(value);

            const handlingTimeFromPool = value.weight;
            setCurrentHandlingTime(handlingTimeFromPool);
        }
    };

    const addPool = () => {
        const pool = {
            entity_no: userEntityNo,
            pool_id: currentPool.id,
            handling_time: currentHandlingTime,
        };
        saveProductionTeamPool({ pool });
        handleClose();
    };

    return (
        <SectionCard
            title={`Assign Team Pool`}
            titleProps={{
                variant: "h6",
            }}
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <div className={styles.autoCompl}>
                <BUAutocomplete
                    label="Select Pool"
                    inputClassName={styles.poolField}
                    dataSource={selectPools}
                    value={currentPool}
                    onChange={handleSelectPool}
                />
                <TextField
                    label={currentHandlingTime ? "" : "Expected Handling Time"}
                    fullWidth
                    value={currentHandlingTime}
                    onChange={(e) => setCurrentHandlingTime(e.target.value)}
                    style={{ marginTop: 30 }}
                />
            </div>
            <Button variant="outlined" style={{ display: "block", margin: "2em auto 0" }} onClick={addPool}>
                Add Pool
            </Button>
        </SectionCard>
    );
};

export default ProductionPoolGroup;
