import React from "react";

import Close from "./Close";
import Cry from "./Cry";
import Default from "./Default";
import Dizzy from "./Dizzy";
import EyeRoll from "./EyeRoll";
import Happy from "./Happy";
import Hearts from "./Hearts";
import Side from "./Side";
import Squint from "./Squint";
import Surprised from "./Surprised";
import Wink from "./Wink";
import WinkWacky from "./WinkWacky";

// Define a type for the eye options
export type EyesOption =
    | "Close"
    | "Cry"
    | "Default"
    | "Dizzy"
    | "EyeRoll"
    | "Happy"
    | "Hearts"
    | "Side"
    | "Squint"
    | "Surprised"
    | "Wink"
    | "WinkWacky"
    | "default";

// Mapping object for eye components
const eyesComponentsMap: Record<EyesOption, React.ComponentType> = {
    Close,
    Cry,
    Default,
    Dizzy,
    EyeRoll,
    Happy,
    Hearts,
    Side,
    Squint,
    Surprised,
    Wink,
    WinkWacky,
    default: Default, // Default to Default if no match is found
};

interface EyesProps {
    option: EyesOption;
}

const Eyes: React.FC<EyesProps> = ({ option = "default" }) => {
    const SelectedEyes = eyesComponentsMap[option] || eyesComponentsMap["default"];
    return <SelectedEyes />;
};

export default Eyes;
