import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const notifications = makeStyles((theme: Theme) =>
    createStyles({
        filter: {
            background: theme.palette.background.paper,
        },
        overlayContentBound: {
            position: "absolute",
            right: 0,
            top: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            zIndex: 1298,
        },
        insightsCard: {
            background: "#F0F0F0 !important",
            alignSelf: "center",
        },
        largeAvatar: {
            height: theme.spacing(5),
            width: theme.spacing(5),
        },
        tabCard: {
            width: "100%",
        },
        tabContent: {
            height: "90%",
            width: "100%",
            overflow: "scroll",
            marginBottom: 20,
        },
        button: {
            width: "100%",
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            fontWeight: "bold",
            textTransform: "capitalize",
            marginTop: 20,
            marginBottom: 40,
            "&:hover": {
                background: theme.palette.background.default,
                color: theme.palette.text.primary,
            },
        },
        tabs: {
            marginTop: 10,
            background: theme.palette.background.paper,
        },
        fullWidth: {
            width: "100%",
            background: theme.palette.background.paper,
        },
    }),
);

export default notifications;
