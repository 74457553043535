import { httpClient } from "../../clients/http.client";
import { MiscBody } from "../../types/api.types";
import { t_LookupField, t_UserBase } from "../User/User.types";
import { t_Permission, t_PermissionUser, t_Role } from "./Access.types";

const updateRole = (payload: { id?: number; name: string; slug: string }) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/roles/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const getAccessUsers = (payload: { search: string } & MiscBody) => {
	return new Promise<t_UserBase[]>((resolve, reject) => {
		httpClient.post("/users/search", payload).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve(data.data.users);
					break;
				default:
					reject(data.message);
					break;
			}
		});
	});
};
const getPermissions = (payload?: MiscBody) =>
	new Promise<t_Permission[]>((resolve, reject) => {
		httpClient
			.post("/admin/permissions", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.permissions);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const getRoles = (payload?: MiscBody) =>
	new Promise<t_Role[]>((resolve, reject) => {
		httpClient
			.post("/admin/roles", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.roles);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const getPermissionUsers = (
	payload: {
		permission_id: number;
	} & MiscBody
) =>
	new Promise<t_PermissionUser[]>((resolve, reject) => {
		httpClient
			.post("/admin/permissions/users", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.users);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const getRolePermissions = (
	payload: {
		role_id: number;
	} & MiscBody
) =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/admin/roles/permissions", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.permissions);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const getRoleUsers = (
	payload: {
		role_id: number;
	} & MiscBody
) =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/admin/roles/users", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.users);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const getRoleJobLevels = (
	payload: {
		role_id: number;
	} & MiscBody
) =>
	new Promise<t_LookupField[]>((resolve, reject) => {
		httpClient
			.post("/admin/roles/job_levels", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.job_levels);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
export {
	getAccessUsers,
	getPermissions,
	getPermissionUsers,
	updateRole,
	getRoles,
	getRolePermissions,
	getRoleUsers,
	getRoleJobLevels,
};
