import { Button, ButtonProps, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		isActive: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,
		},
	})
);

type Props = ButtonProps & {
	active?: boolean;
};
const ActiveButton: React.FC<Props> = ({ active, ...buttonProps }) => {
	const classes = useStyles();
	return (
		<Button
			{...buttonProps}
			variant={!active ? "text" : undefined}
			className={clsx(buttonProps.className, {
				[classes.isActive]: active,
			})}
		/>
	);
};

export default ActiveButton;
