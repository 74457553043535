import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        token_type: sessionStorage.getItem("token_type"),
        api_access_token:
            sessionStorage.getItem("masquerade_access_token") || sessionStorage.getItem("api_access_token"),
    },
    reducers: {
        setCredentials: (state, action) => {
            const { token_type, api_access_token } = action.payload;
            state.api_access_token = api_access_token;
            state.token_type = token_type;
        },
    },
});

export const { setCredentials } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentTokenType = (state: any) => state.auth.token_type;
export const selectCurrentAccessToken = (state: any) => state.auth.api_access_token;
