import { Avatar, Box, CardHeader, CircularProgress, List, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import React, { useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { getImageUrl } from "../../../../misc/helpers";
import { MiscBody } from "../../../../types/api.types";
import { t_UserBase } from "../../User.types";
import { getUserBy } from "./UserSearch.api";

interface Props {
    searchTerm?: string;
    onUserSelected?: (user: any) => void;
}

const UserSearch: React.FC<Props> = ({ searchTerm, onUserSelected }) => {
    const [users, setUsers] = useState<t_UserBase[]>([]);
    const [selectedUserID, setSelectedUserID] = useState<number>();
    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>({
        page: 1,
        rows_per_page: 5,
    });
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSelectUser = (user: t_UserBase) => {
        setSelectedUserID(user.id);
        if (onUserSelected != null) {
            onUserSelected(user);
        }
    };

    React.useEffect(() => {
        var isActive = true;
        if (searchTerm && searchTerm.length > 0) {
            setLoading(true);
            getUserBy({
                search: searchTerm,
                pagination,
            }).then((data) => {
                if (isActive) {
                    setLoading(false);
                    setUsers(data.data.users);
                    if (data.total_results != null && data.pagination != null && data.pagination.rows_per_page > 0) {
                        setPagination({
                            ...data.pagination,
                            count: Math.ceil(data.total_results / data.pagination.rows_per_page),
                        });
                    }
                }
            });
        }
        return () => {
            isActive = false;
        };
    }, [searchTerm, pagination?.page]);

    return (
        <SectionCard
            noHeader
            bottomActions={
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Pagination
                        page={pagination?.page}
                        count={pagination?.count}
                        onChange={(_, page) => setPagination((state) => state && { ...state, page })}
                    />
                </div>
            }
        >
            <List>
                {loading ? (
                    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    users.map((user) => (
                        <ListItem
                            key={`user-${user.id}`}
                            button
                            onClick={() => handleSelectUser(user)}
                            selected={user.id === selectedUserID}
                        >
                            <CardHeader
                                avatar={<Avatar src={getImageUrl("profile", user.profile_img)} />}
                                title={user.name}
                                subheader={user.job_title}
                            />
                        </ListItem>
                    ))
                )}
            </List>
        </SectionCard>
    );
};

export default UserSearch;
