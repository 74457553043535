import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { ExpandMore, Send } from "@mui/icons-material";
import clsx from "clsx";
import React, { useState } from "react";
import SectionCard, {
	SectionCardProps,
} from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { useUserState } from "../../../../context/user/user.ctx";
import { getImageUrl } from "../../../../misc/helpers";
import { getComments, postComment } from "./LsaComments.api";
import lsaCommentsStyles from "./LsaComments.styles";
import { t_LsaComment } from "./LsaComments.types";

type Props = {
	lsa_id?: number;
	canComment?: boolean;
} & SectionCardProps;
const LsaComments: React.FC<Props> = ({
	lsa_id,
	canComment = false,
	...sectionCardProps
}) => {
	const classes = lsaCommentsStyles();
	const currentUser = useUserState();
	const [comments, setComments] = useState<t_LsaComment[]>([]);

	const [commenting, setCommenting] = React.useState(false);
	const [comment, setComment] = React.useState<string>("");
	const [posting, setPosting] = React.useState(false);

	const lastCommentRef = React.useRef<HTMLDivElement>(null);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	};

	React.useEffect(() => {
		var isActive = true;
		var interval: NodeJS.Timeout;

		if (lsa_id != null) {
			getComments({ lsa_id }).then((data) => {
				isActive && setComments(data);
			});

			interval = setInterval(() => {
				getComments({ lsa_id }).then((data) => {
					isActive && setComments(data);
				});
			}, 10000);
		}

		return () => {
			isActive = false;
			if (interval != null) {
				clearInterval(interval);
			}
		};
	}, [lsa_id]);

	React.useEffect(() => {
		var isActive = true;
		if (lsa_id != null && posting === true) {
			if (comment.trim() !== "") {
				postComment({ lsa_id, comment }).then((data) => {
					setPosting(false);
					setComment("");
					setCommenting(false);
					setComments((state) => [data, ...state]);
				});
			} else {
				setPosting(false);
			}
		}
	}, [posting]);

	React.useLayoutEffect(() => {
		if (lastCommentRef.current) {
			lastCommentRef.current.scrollIntoView();
		}
	}, [lastCommentRef.current]);

	return (
        <SectionCard
			noHeader
			hasBackground
			scrolling
			bottomActions={
				lsa_id != null && (
					<div className={classes.bottomActions}>
						<TextField
							value={comment}
							variant='outlined'
							onFocus={() => setCommenting(true)}
							className={clsx({
								[classes.hidden]: commenting,
							})}
							disabled={!canComment || commenting}
						/>
						<SubmitButton
							className={classes.submitButton}
							loading={posting}
							onClick={() => setPosting(true)}
							disabled={!canComment || !(comment.trim() !== "")}
							endIcon={<Send />}
						>
							{commenting && "Send"}
						</SubmitButton>
					</div>
				)
			}
		>
			<div className={classes.root}>
				{commenting ? (
					<Card elevation={0}>
						<CardHeader
							title={
								<Typography variant='caption'>
									Comment
								</Typography>
							}
							action={
								<IconButton onClick={() => setCommenting(false)} size="large">
									<ExpandMore />
								</IconButton>
							}
						/>
						<CardContent>
							<TextField
								autoFocus={true}
								value={comment}
								onChange={handleChange}
								fullWidth
								variant='outlined'
								multiline
							/>
						</CardContent>
					</Card>
				) : comments.length > 0 ? (
					comments.map((comment, index) => (
						<div
							ref={index === 0 ? lastCommentRef : undefined}
							key={comment.feedback_id}
							className={clsx(classes.chipWrapper, {
								[classes.myWrapper]:
									currentUser.profile?.id === comment.user.id,
							})}
						>
							<Typography variant='caption'>
								{comment.date}
							</Typography>
							<Card
								className={clsx(classes.card, {
									[classes.myCard]:
										currentUser.profile?.id ===
										comment.user.id,
								})}
							>
								<Chip
									label={comment.user.name}
									avatar={
										<Avatar
											src={getImageUrl(
												"profile",
												comment.user.profile_img
											)}
										/>
									}
									className={classes.chip}
								/>
								<CardContent>{comment.comment}</CardContent>
							</Card>
						</div>
					))
				) : (
					<Box display='flex' width='100%' justifyContent='center'>
						No comments.
					</Box>
				)}
			</div>
		</SectionCard>
    );
};

export default LsaComments;
