import React from "react";

const SadConcerned = () => {
    return (
        <g id="Eyebrow/Outline/Sad-Concerned" fillOpacity="0.599999964" fillRule="nonzero">
            <path
                d="M15.9726042,19.4088529 C17.452356,11.0203704 30.0622688,5.22829657 39.2106453,8.9774793 C40.2254706,9.39337449 41.4016967,8.94600219 41.8378196,7.97824531 C42.2739426,7.01048842 41.8048116,5.88881678 40.7899862,5.47292159 C29.3457328,0.782843812 13.9550264,7.85221132 12.0280273,18.7760684 C11.84479,19.8148122 12.5792704,20.798534 13.6685352,20.9732726 C14.7578,21.1480113 15.7893668,20.4475967 15.9726042,19.4088529 Z"
                id="Eyebrow"
                transform="translate(27.000414, 12.500000) scale(-1, -1) translate(-27.000414, -12.500000) "
            />
            <path
                d="M73.9726042,19.4088529 C75.452356,11.0203704 88.0622688,5.22829657 97.2106453,8.9774793 C98.2254706,9.39337449 99.4016967,8.94600219 99.8378196,7.97824531 C100.273943,7.01048842 99.8048116,5.88881678 98.7899862,5.47292159 C87.3457328,0.782843812 71.9550264,7.85221132 70.0280273,18.7760684 C69.84479,19.8148122 70.5792704,20.798534 71.6685352,20.9732726 C72.7578,21.1480113 73.7893668,20.4475967 73.9726042,19.4088529 Z"
                id="Eyebrow"
                transform="translate(85.000414, 12.500000) scale(1, -1) translate(-85.000414, -12.500000) "
            />
        </g>
    );
};

export default SadConcerned;
