import { httpClient } from "../../../../clients/http.client";
import { t_LeaveType, t_ProductionLeave } from "./OtherLeave.types";

const getLeaveTypes = async () => {
    return new Promise<t_LeaveType[]>((resolve, reject) => {
        httpClient
            .post("/admin/production/external_leave")
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.leave_types);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const getLeaveData = async (payload: { entity_no: number; capture_date: string }) => {
    return new Promise<t_ProductionLeave>((resolve, reject) => {
        httpClient
            .post("/production/other_leave", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        if (data.data.other_leave != null) {
                            resolve({
                                ...data.data.other_leave,
                                leave_type_id: data.data.other_leave?.leave_type?.id,
                            });
                        } else {
                            resolve(data.data.other_leave);
                        }
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const submitLeaveData = async (
    payload: {
        entity_no: number;
        capture_date: string;
    } & t_ProductionLeave,
) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/production/other_leave/save", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.success);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const deleteLeave = async (payload: { id: number }) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/production/other_leave/delete", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.success);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

export { getLeaveTypes, getLeaveData, submitLeaveData, deleteLeave };
