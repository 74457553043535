import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    TypographyVariant,
} from "@mui/material";
import clsx from "clsx";
import React, { ReactNode, createRef, useState } from "react";
import sectionCardStyles from "./SectionCard.style";
interface SectionCardProps {
    title?: string | any;
    titleProps?: {
        variant?: TypographyVariant;
    };
    subtitle?: string | any;
    primaryAction?: any;
    secondaryAction?: any;
    bottomActions?: any;
    hasBackground?: boolean;
    hasBackgroundColor?: any;
    hasDivider?: boolean;
    fullWidth?: boolean;
    scrolling?: boolean;
    noHeader?: boolean;
    className?: string;
    innerRef?: React.Ref<any>;
    fontSize?: any;
    children?: ReactNode;
    styles?: string | any;
}

const SectionCard: React.FC<SectionCardProps> = ({
    children,
    title,
    titleProps = { variant: "h5" },
    subtitle,
    primaryAction,
    secondaryAction,
    bottomActions,
    hasBackground = false,
    fullWidth = false,
    scrolling = false,
    noHeader = false,
    className,
    innerRef,
    hasDivider,
    hasBackgroundColor,
    fontSize,
    styles,
}) => {
    const classes = sectionCardStyles({ hasBackground });
    const [bodyHeight, setBodyHeight] = useState("100%");
    const CardHeaderRef = createRef<HTMLDivElement>();
    const CardActionsRef = createRef<HTMLDivElement>();

    React.useEffect(() => {
        setBodyHeight(
            `calc(100% - ${(CardHeaderRef.current?.offsetHeight ?? 0) + (CardActionsRef.current?.offsetHeight ?? 0)
            }px)`,
        );
    }, [CardHeaderRef, CardHeaderRef.current?.offsetHeight, CardActionsRef, CardActionsRef.current?.offsetHeight]);
    return (
        <Card
            style={{ backgroundColor: hasBackgroundColor ? "#eaf0f4" : "" }}
            className={clsx(classes.card, className)}
            classes={{
                root: hasBackground ? classes.elevatedCard : classes.flatCard,
            }}
            elevation={hasBackground ? 2 : 0}
        >
            {!noHeader && (
                <CardHeader
                    style={styles}
                    className={className}
                    ref={CardHeaderRef}
                    title={
                        <Grid container spacing={2} alignContent="center">
                            {title && (
                                <Grid item className={classes.titleSection}>
                                    <Typography
                                        style={{ fontSize: fontSize }}
                                        className={classes.capitalize}
                                        {...titleProps}
                                    >
                                        {title}
                                    </Typography>
                                    {subtitle && (
                                        <Typography className={classes.capitalize} variant="subtitle2">
                                            {subtitle}
                                        </Typography>
                                    )}
                                </Grid>
                            )}
                            {primaryAction && (
                                <React.Fragment>
                                    {title && (
                                        <Grid item>
                                            <Divider
                                                orientation="vertical"
                                                className={
                                                    !hasBackground ? classes.flatDivider : classes.elevatedDivider
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>{primaryAction}</Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    }
                    action={secondaryAction}
                    classes={{
                        root: hasBackground && hasDivider ? classes.elevatedHeader : classes.flatHeader,
                        action: classes.headerActions,
                    }}
                />
            )}
            <CardContent
                className={scrolling ? classes.cardContentScroll : undefined}
                style={{
                    padding: fullWidth ? 0 : undefined,
                    height: bodyHeight,
                }}
                ref={innerRef}
            >
                {children}
            </CardContent>
            {bottomActions && (
                <CardActions
                    ref={CardActionsRef}
                    style={{
                        padding: 8,
                    }}
                >
                    {bottomActions}
                </CardActions>
            )}
        </Card>
    );
};

export type { SectionCardProps };
export default SectionCard;
