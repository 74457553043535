import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const userAttributesStyles = makeStyles((theme: Theme) =>
	createStyles({
		autocomplete: {
			width: 300,
		},
		formList: {
			height: "100%",
		},
		submit: {
			position: "absolute",
			bottom: 0,
			right: 0,
			backgroundColor: theme.palette.info.main,
			borderRadius: theme.spacing(0.5),
		},
		formActions: {
			width: "100%",
			display: "flex",
			justifyContent: "space-evenly",
		},
	})
);

export default userAttributesStyles;
