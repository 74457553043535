import { format } from "date-fns";
import { Query } from "material-table";

type DateFormatOption = "short" | "full" | undefined;
interface getDateStringParams {
    timestamp: number;
    day?: DateFormatOption;
    month?: DateFormatOption;
    year?: DateFormatOption;
}
export const getDateString = (params: getDateStringParams): string => {
    const Days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const Months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const date = new Date(params.timestamp);
    let dateString = "";

    switch (params.day) {
        case "short":
            dateString += Days[date.getDay()].substr(0, 3);
            break;
        case "full":
            dateString += Days[date.getDay()];
            break;
        default:
            break;
    }
    switch (params.month) {
        case "short":
            dateString += " " + Months[date.getMonth()].substr(0, 3);
            break;
        case "full":
            dateString += " " + Months[date.getMonth()];
            break;
        default:
            break;
    }
    switch (params.year) {
        case "short":
            dateString += " " + date.getFullYear().toString().substr(2, 4);
            break;
        case "full":
            dateString += " " + date.getFullYear().toString();
            break;
        default:
            break;
    }
    return dateString;
};

export const findLastDayOfMonth = (year: number, monthIndex: number) => {
    for (let d = 28; d <= 31; d++) {
        const date = new Date(year, monthIndex, d);
        if (date.getMonth() !== monthIndex) return --d;
    }

    return 31;
};

export const formatApiDate = (date: string | Date | undefined, custom_format: string = "yyyy-MM-dd") => {
    let ret = "";

    try {
        if (date != null) {
            ret = format(new Date(date), custom_format);
        }
    } catch (e) {
        console.error(e);
        ret = "-";
    }
    return ret;
};
export const groupBy = (arr: any[], key: string) => {
    return arr.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getMetricFormat = (metric_id: number, value?: number): string => {
    if (value == null) {
        return "-";
    }

    const num_val = Number(value);

    switch (metric_id) {
        case 1: // aht
        case 2: // dpmo
            return Math.round(num_val).toLocaleString();
        case 3: // fcr
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 4: // mbr
            return num_val.toLocaleString(undefined, {
                maximumFractionDigits: 2,
            });
        case 5: // production
            return Math.round(num_val).toLocaleString();
        case 6: // transfer ratio
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 7: // attendance
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 8: // twt
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 9: // adherance
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 10: // knowledge
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 11: // total inbound
            return Math.round(num_val).toLocaleString();
        case 12: // engagement
            return (
                num_val.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                }) + "%"
            );
        case 13: // avg hold time
            return Math.round(num_val).toLocaleString();
        default:
            return Number(value).toLocaleString();
    }
};

export const convertQuery = (query: Query<any>) => {
    return {
        sort: query.orderBy
            ? {
                  column_name: String(query.orderBy?.field),
                  order: query.orderDirection,
              }
            : null,
        filter: query.filters.map((filter) => ({
            column_name: String(filter.column.field),
            search_term: filter.value,
        })),
        pagination: {
            page: query.page + 1,
            rows_per_page: query.pageSize,
        },
    };
};

export const getImageUrl = (type: "profile" | "badge", url?: string) => {
    if (url != null) {
        switch (type) {
            case "profile":
                return `${getApiUrl()}/profile/image/${url}`;
            case "badge":
                return `${process.env.REACT_APP_BADGE_URL}/${url}`;
        }
    }
    return url;
};

export const getApiUrl = () => {
    return `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
};

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value?.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringAvatar = (name: string) => {
    const nameParts = name?.split(" ");

    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: "0.9em",
        },
        children: nameParts[0] !== "" ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[1][0]}`,
    };
};
