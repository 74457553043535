import { Box, Dialog, Typography } from "@mui/material";
import { Column } from "material-table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { useContext, useState } from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { t_TeamMember, t_TeamMemberPool, t_TeamPool } from "../TwtGroup.types";
import NewPoolGroup from "../sections/NewPoolGroup/NewPoolGroup";
import { getTeamMemberPools, getTeamPools, getTeamMembers, addPoolToTeam, removeTeamPool } from "../twtTeams.api";
import { useStyles } from "./MaintainTWTteams.styles";
import { useUserState } from "../../../context/user/user.ctx";
import TeamGroupPool from "../sections/TeamGroupPool";
import { Add, ContactSupportOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { getWeightedPoolsSearch } from "../../Production/tables/PoolWeights/PoolWeights.api";
import DeleteTeamPool from "../sections/DeleteTeamPool";
import { NotificationsContext } from "../../../components/Toasts/NotificationsProvider.ctx";
import { useLocation } from "react-router-dom";
import { Steps } from "intro.js-react";
import { setBeYouWalkThrough, setTwtWalkThrough, setWalkThroughMode } from "../../../redux/slices/WalkThroughSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    get_individual_team_pool_twt,
    get_team_pool_twt,
    team_members_twt,
} from "../../../pages/Home/BeYouCoach/WalkThroughData";
import { useClearNotificationsMutation } from "../../../redux/api/beYouCore";

interface DeleteStyles {
    position: "relative" | "unset" | undefined;
    zIndex: number | undefined | string;
    background: string | undefined;
}

const MaintainTWTteams = () => {
    const twtWalkThrough = useSelector((state: RootState) => state.walkThrough.twtWalkThrough);
    const [dialogState, setDialogState] = React.useState<{
        open: boolean;
        row?: t_TeamPool;
        userEntity?: number;
    }>({
        open: false,
    });
    const [isOnBoardingOpen, setIsOnBoarding] = React.useState(false);
    const [deleteDialogState, setDeleteDialogState] = React.useState<{
        open: boolean;
        data?: any;
    }>({
        open: false,
    });
    const [newPoolDialogOpen, setNewPoolDialogOpen] = React.useState(false);
    const { isTWTWalkthroughActive, setIsTWTWalkthroughActive } = useContext(NotificationsContext);
    const promptID = useSelector((state: RootState) => state.metrics.promptId);
    const [clearNotifications] = useClearNotificationsMutation();
    const location = useLocation();

    const [isTwtWalkthrough, setIsTwtWalkthrough] = React.useState<boolean>(false);
    let queryParams = new URLSearchParams(window.location.search);

    // let twtTeamParam = queryParams.get("twt_walkthrough");
    const [twtTeamParam, setTWTTeamParam] = useState(queryParams.get("twt_walkthrough"));
    const [twtTeamWalkthrough, setTwtTeamWalkthrough] = React.useState(twtTeamParam === "true");
    const [initialStep, setInitialStep] = React.useState(0);
    const [teamMemberList, setTeamMemberList] = React.useState<t_TeamMember[]>([]);
    const [submitState, setSubmitState] = React.useState<"not submitted" | "pending" | "success" | "error">(
        "not submitted",
    );
    const dispatch = useDispatch();
    const [deleteStyles, setDeleteStyles] = React.useState<DeleteStyles>({
        position: "unset",
        zIndex: "auto",
        background: undefined,
    });
    const [teamColumns] = React.useState<Column<any>[]>([
        {
            title: "Pool Name",
            field: "Name",
            sorting: true,
        },
        {
            title: "Pool Entity",
            field: "PoolID",
        },
        {
            title: "Actions",
            align: "center",
            render: (data: t_TeamPool) => {
                return (
                    <div style={{ ...deleteStyles }} className="delete-team-member-pool-area">
                        <div onClick={() => setDeleteDialogState({ open: true, data })}>
                            <DeleteOutlineIcon className="delete-team-member-pool-area" />
                        </div>
                    </div>
                );
            },
            sorting: false,
        },
    ]);
    const [memberColumns] = React.useState<Column<any>[]>([
        {
            title: "Team Member",
            field: "DisplayName",
            sorting: true,
        },
        {
            title: "Pool Name",
            field: "Name",
            sorting: true,
        },
        {
            title: "Pool Entity",
            field: "PoolID",
        },
        {
            title: "Actions",

            align: "center",

            render: (data: t_TeamMemberPool) => {
                return (
                    <div>
                        <DeleteOutlineIcon onClick={() => deleteTeamPool(data.PoolID, data.EntityNo)} />
                    </div>
                );
            },

            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                marginLeft: "1em",
            },
            sorting: false,
        },
    ]);

    const [memberListColumns] = React.useState<Column<any>[]>([
        {
            title: "Entity No",
            field: "entity_no",
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Job Title",
            field: "job_title",
        },
    ]);

    const styles = useStyles();

    const removeNotification = (id: number) => {
        if (id) {
            clearNotifications({ notification_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: OnBoarding.tsx:164 ~ .then ~ error:", error);
                });
        }
    };

    const getTeamList = () => {
        getTeamMembers({ entity_no: user.entity_no })
            .then((resp) => {
                setTeamMemberList(resp);
            })
            .catch((err) => console.error(err));
    };

    const addTeamPool = (poolNumber: number, userEntity?: number) => {
        const today = format(new Date(), "yyyy-MM-dd");
        const payload = userEntity
            ? {
                  pool_numbers: [poolNumber],
                  entity_no: userEntity,
                  date_from: today,
              }
            : {
                  pool_numbers: [poolNumber],
                  date_from: today,
              };

        addPoolToTeam(payload)
            .then(() => {
                getTeamList();
                table1ref.current && table1ref.current.onQueryChange();
                userEntity && setDialogState({ open: false });
            })
            .catch((err) => console.error(err));
    };

    const deleteTeamPool = (poolNumber: number, entityNo?: number) => {
        const payload = entityNo ? { pool_numbers: [poolNumber], entity_no: entityNo } : { pool_numbers: [poolNumber] };

        removeTeamPool(payload)
            .then(() => {
                table1ref.current && table1ref.current.onQueryChange();
                setDeleteDialogState({ open: false });
                entityNo && table3ref.current && table3ref.current.onQueryChange();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const removePoolFromTeam = (data: any) => {
        deleteTeamPool(data.PoolID);
    };

    React.useEffect(() => {
        getTeamList();
        if (queryParams.get("twt_walkthrough")) {
            setIsTWTWalkthroughActive(true);
            dispatch(setTwtWalkThrough(true));
        } else {
            setIsTWTWalkthroughActive(false);
            dispatch(setTwtWalkThrough(false));
        }
    }, [location, twtTeamParam, twtWalkThrough]);

    const switchSteps = (nextStepIndex: number, nextElement: any) => {
        switch (nextStepIndex) {
            case 0:
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.delete("twt_walkthrough");
                const newURL = `${window.location.pathname}?${queryParams.toString()}`;
                window.history.replaceState(null, "", newURL);
                break;
        }
    };
    const introOptions = {
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true,
        hideNext: false,
        doneLabel: "Finish",
        buttonClass: styles.next,
        keyboardNavigation: false,
        exitOnOverlayClick: false,
    };
    const user = useUserState();
    const table1ref = React.useRef<any>();
    const table2ref = React.useRef<any>();
    const table3ref = React.useRef<any>();

    const steps = [
        {
            element: ".pool-groups-area",
            title: "Pool Groups",
            intro: "Here you have the team pool groups, where team leaders can add pools that belong to their team. Each individual gets the pools assigned here.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".add-member-pool-members",
            title: "Add pool groups",
            intro: "You can add pools to your whole team by clicking here.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".delete-team-member-pool-area",
            title: "Delete pool groups",
            intro: "You can delete pools from your entire team by clicking here.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".member-groups-area",
            title: "Member Pools",
            intro: "This table contains the individual team member pools data. Here you can remove and add pools to individuals.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".member-groups-row",
            title: "TWT Metric",
            intro: "Pools assigned to team members will determine the metric results for the individuals and team leader, as only pools that have been assigned will factor in to the metric total..",
            position: "right",
            disableInteraction: true,
        },
    ];

    return (
        <>
            <div className={styles.teamPoolsContainer}>
                <SectionCard title={"Maintain TWT Teams"} fullWidth>
                    <div className={`${styles.tablesContainer} `}>
                        <Box className="pool-groups-area">
                            <BUMaterialTable
                                title={"Pool Groups"}
                                columns={teamColumns}
                                tableRef={table1ref}
                                data={
                                    twtWalkThrough
                                        ? get_team_pool_twt
                                        : (query) =>
                                              getTeamPools({
                                                  ...query,
                                                  pagination: {
                                                      page: query.page + 1,
                                                      rows_per_page: query.pageSize,
                                                  },
                                                  filter: [...query.filters],
                                                  sort: query.orderBy
                                                      ? {
                                                            column_name: query.orderBy?.field || "",
                                                            order: query.orderDirection,
                                                        }
                                                      : "",
                                              })
                                }
                                options={{
                                    search: false,
                                    sorting: false,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10],
                                    maxBodyHeight: "600px",
                                }}
                                actions={[
                                    {
                                        isFreeAction: true,
                                        icon: () => (
                                            <div
                                                className="add-team-member-pool-area"
                                                style={{
                                                    display: "flex",
                                                    gap: ".5em",
                                                }}
                                            >
                                                <Typography className="add-member-pool-members">
                                                    Add Team Pool
                                                </Typography>
                                                <AddCircleIcon />
                                            </div>
                                        ),
                                        onClick: () => setDialogState({ open: true }),
                                    },
                                ]}
                            />
                        </Box>
                        <Box className="member-groups-area">
                            <BUMaterialTable
                                title={"Member Pools"}
                                columns={memberListColumns}
                                data={twtWalkThrough ? team_members_twt : teamMemberList}
                                tableRef={table2ref}
                                detailPanel={(rowData) => {
                                    return (
                                        <Box>
                                            <BUMaterialTable
                                                title={rowData.name}
                                                columns={memberColumns}
                                                tableRef={table3ref}
                                                data={
                                                    twtWalkThrough
                                                        ? get_individual_team_pool_twt
                                                        : (query) => {
                                                              return getTeamMemberPools({
                                                                  ...query,
                                                                  pagination: {
                                                                      page: query.page + 1,
                                                                      rows_per_page: query.pageSize,
                                                                  },
                                                                  filter: [
                                                                      {
                                                                          column_name: "DisplayName",
                                                                          search_term: rowData.name,
                                                                      },
                                                                  ],
                                                                  sort: query.orderBy
                                                                      ? {
                                                                            column_name: query.orderBy?.field || "",
                                                                            order: query.orderDirection,
                                                                        }
                                                                      : "",
                                                              });
                                                          }
                                                }
                                                options={{
                                                    search: false,
                                                    filtering: false,
                                                    showTitle: false,
                                                    pageSize: 5,
                                                    pageSizeOptions: [5, 10],
                                                }}
                                                actions={[
                                                    {
                                                        isFreeAction: true,
                                                        icon: () => (
                                                            <div className="add-team-member--area">
                                                                <Typography>Add Member Pool</Typography>
                                                                <AddCircleIcon />
                                                            </div>
                                                        ),
                                                        onClick: () => {
                                                            setDialogState({
                                                                open: true,
                                                                userEntity: rowData.entity_no,
                                                            });
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </div>
                </SectionCard>
            </div>
            <Dialog open={dialogState.open} onClose={() => setDialogState({ open: false })} scroll="paper">
                <TeamGroupPool
                    handleClose={() => {
                        setDialogState({ open: false });
                    }}
                    addTeamPool={addTeamPool}
                    userEntityNo={dialogState.userEntity}
                />
            </Dialog>

            <Dialog open={deleteDialogState.open} onClose={() => setDeleteDialogState({ open: false })} scroll="paper">
                {deleteDialogState.data && (
                    <DeleteTeamPool
                        onClose={() => {
                            setDeleteDialogState({ open: false });
                        }}
                        deleteAction={deleteTeamPool}
                        submitState={submitState}
                        data={deleteDialogState.data}
                    />
                )}
            </Dialog>
            <Steps
                enabled={isTWTWalkthroughActive}
                steps={steps}
                options={introOptions}
                initialStep={initialStep}
                onExit={() => {
                    setIsTWTWalkthroughActive(false);
                    dispatch(setTwtWalkThrough(false));
                    dispatch(setBeYouWalkThrough(false));
                    dispatch(setWalkThroughMode(false));
                    removeNotification(promptID);
                }}
                onComplete={() => {
                    setIsTWTWalkthroughActive(false);
                    dispatch(setTwtWalkThrough(false));
                    dispatch(setBeYouWalkThrough(false));
                    dispatch(setWalkThroughMode(false));
                    removeNotification(promptID);
                }}
                onBeforeExit={() => {
                    location.search = "";
                }}
                onChange={(nextStepIndex, nextElement) => switchSteps(nextStepIndex, nextElement)}
            />
        </>
    );
};

export default MaintainTWTteams;
