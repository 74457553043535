import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

const SubmittedPipOptionsTable = ({ data }: any) => {
    const { options_object } = data;
    const columns = [
        {
            id: "options",
            label: "Options",
            type: "dropdown",
        },
        {
            id: "option_set",
            label: "Option Set",
            type: "emojiThumb",
        },
        {
            id: "option_followed",
            label: "Option Followed",
            type: "emojiThumb",
        },
        {
            id: "review_date",
            label: "Review Date",
            type: "date",
        },
    ];

    const createData = (options: any, option_set: any, option_followed: any, review_date: any) => {
        return { options, option_set, option_followed, review_date };
    };

    const [rows, setRows] = useState([
        createData("", "", "", ""),
        createData("", "", "", ""),
        createData("", "", "", ""),
        createData("", "", "", ""),
    ]);

    useEffect(() => {
        if (data.type == "PIP_FOLLOW_UP" || data.type == "PIP_INITIATION") {
            setRows([
                createData(
                    options_object[0]?.name,
                    options_object[0]?.set,
                    options_object[0]?.followed,
                    options_object[0]?.review_date,
                ),
                createData(
                    options_object[1]?.name,
                    options_object[1]?.set,
                    options_object[1]?.followed,
                    options_object[1]?.review_date,
                ),
                createData(
                    options_object[2]?.name,
                    options_object[2]?.set,
                    options_object[2]?.followed,
                    options_object[2]?.review_date,
                ),
                createData(
                    options_object[3]?.name,
                    options_object[3]?.set,
                    options_object[3]?.followed,
                    options_object[3]?.review_date,
                ),
            ]);
        }
    }, [data]);

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell align="center" key={column.id} sx={{ fontWeight: "bold", width: "25%" }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIndex) => {
                        return (
                            <TableRow>
                                {columns.map((column) => {
                                    //@ts-ignore
                                    const value = row[column.id];

                                    return (
                                        <TableCell
                                            key={column.id}
                                            align="center"
                                            sx={{
                                                backgroundColor:
                                                    value == "😀" || value == "👍" || value == "✔"
                                                        ? "#D4EFDF"
                                                        : value == "😐"
                                                        ? "#FCF3CF"
                                                        : value == "🙁" || value == "👎" || value == "❌"
                                                        ? "#F5B7B1 "
                                                        : "",
                                            }}
                                        >
                                            {column.type == "date"
                                                ? moment(value, true).isValid()
                                                    ? moment(value).format("DD-MM-YYYY")
                                                    : ""
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubmittedPipOptionsTable;
