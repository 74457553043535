import { t_Challenge } from "../Challenge/Challenge.types";
import { t_UserBadge } from "../Profile/sections/ProfileBadges/ProfileBadges.types";
import { t_UserBase } from "../User/User.types";

type t_Interaction = {
	EntityNo: number;
	NewsfeedCommentID: null;
	NewsfeedInteractionTypeID: number;
	NewsfeedItemID: number;
	created_at: string;
	updated_at: string;
};

type t_ActivityComment = {
	id: number;
	name: string;
	profile_img: string;
	comment: string;
	like_count: number;
	liked: boolean;
};
type t_ActivityTier = {
	type: "tier";
	tier: {
		name: string;
		colour: string;
	};
};
type t_ActivityBadge = {
	type: "badge";
	badge: t_UserBadge;
};
type t_ActivityChallenge = {
	type: "challenge";
	challenge: t_Challenge;
};
type t_ActivityFriendRequest = {
	type: "friend request";
	"friend request": {
		requesting_user: t_UserBase;
		status: number;
	};
};
type t_ActivityData = {
	activity_id: any;
	display_name: string;
	profile_img: string;
	date: string;
	like_count?: number;
	liked?: boolean;
	featured_comment?: t_ActivityComment;
	comment_count?: number;
} & (
	| t_ActivityTier
	| t_ActivityBadge
	| t_ActivityChallenge
	| t_ActivityFriendRequest
);

type t_NewsFeedActions = {
	type:
		| "REFRESH_NEWSFEED"
		| "GET_COMMENTS"
		| "SUBMIT_COMMENT"
		| "COMMENT_INTERACTION"
		| "ITEM_INTERACTION";
	item?: t_ActivityData;
};

const LIKE_INTERACTION: number = 1;

export { LIKE_INTERACTION };
export type {
	t_ActivityComment,
	t_ActivityTier,
	t_ActivityBadge,
	t_ActivityChallenge,
	t_ActivityFriendRequest,
	t_ActivityData,
	t_NewsFeedActions,
};
