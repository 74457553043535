import React, { useEffect, useRef, useState } from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import { Box, Dialog, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { Close } from "@mui/icons-material";
import FormViewer from "../FormViewer";

interface FormSubmissionCount {
    [type: string]: number;
}

interface FormSubmissionData {
    name: string;
    typeCounts: Array<{ type: string; count: number }>;
}

interface Props {
    data: any;
    formFields: any;
    metricID: any;
    setMetricID: any;
    selectedForm: any;
    setSelectedForm: any;
}

const FormsTable = ({ data, formFields, metricID, setMetricID, selectedForm, setSelectedForm }: Props) => {
    const table1ref = useRef<any>();
    const formSubmissionCounts: { [name: string]: FormSubmissionCount } = {};
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [viewForm, setViewForm] = useState<boolean>(false);
    const [selectedFormType, setSelectedFormType] = useState<string>("");
    const [selectedPerson, setSelectedPerson] = useState<string>("");

    const filteredSubmittedFormsList = data
        .filter((form: any) => form.type.toLowerCase() == selectedFormType.toLowerCase())
        .filter((person: any) => person.name.toLowerCase() == selectedPerson.toLowerCase());

    data.forEach((submission: any) => {
        const { name, type } = submission;
        if (!formSubmissionCounts[name]) {
            formSubmissionCounts[name] = {};
        }
        if (!formSubmissionCounts[name][type]) {
            formSubmissionCounts[name][type] = 0;
        }
        formSubmissionCounts[name][type]++;
    });

    const tableData: FormSubmissionData[] = Object.entries(formSubmissionCounts).map(([name, types]) => {
        const typeEntries = Object.entries(types);
        const typeCounts = typeEntries.map(([type, count]) => ({ type, count }));
        return { name, typeCounts };
    });

    const handleFormListView = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const RenderCell = (type: string, rowData: any) => {
        const cellData = rowData?.typeCounts.find((typeCount: any) => typeCount.type === type);

        return (
            <Tooltip title={`Click number to view form for ${type}`}>
                <Box
                    onClick={() => {
                        setSelectedFormType(cellData.type.toLowerCase());
                        setSelectedPerson(rowData?.name.toLowerCase());
                    }}
                >
                    <span onClick={handleFormListView} style={{ cursor: "pointer" }}>
                        <Typography>{cellData?.count || 0}</Typography>
                    </span>
                </Box>
            </Tooltip>
        );
    };

    const formsTableColumns = [
        {
            title: "Name",
            field: "name",
        },

        {
            title: "ADHOC",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("ADHOC", rowData),
        },
        {
            title: "Absenteeism | Back to Work",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("ABSENCE", rowData),
        },
        {
            title: "OPS Losses",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("OPS_LOSS", rowData),
        },
        {
            title: "DPMO",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("DPMO", rowData),
        },
        {
            title: "Perception",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("PERCEPTION", rowData),
        },
        {
            title: "Grow Coaching",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("GROW", rowData),
        },
        {
            title: "PIP | Initiation",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("PIP_INITIATION", rowData),
        },
        {
            title: "PIP | Follow-Up",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("PIP_FOLLOW_UP", rowData),
        },
        {
            title: "Written Warning",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("WRITTEN_WARNING", rowData),
        },
        {
            title: "Expections",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("EXPECTATIONS", rowData),
        },
        {
            title: "Handover",
            field: "typeCounts",
            render: (rowData: any) => RenderCell("HANDOVER", rowData),
        },
    ];

    return (
        <Box sx={{ mt: 5 }}>
            <BUMaterialTable
                title={"Team Members"}
                columns={formsTableColumns}
                tableRef={table1ref}
                data={tableData}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 50, 100],
                }}
            />
            <Menu
                id="compare-menu-weekly-feedback"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                    setSelectedFormType("");
                    setSelectedPerson("");
                }}
            >
                {filteredSubmittedFormsList.map((listItem: any) => (
                    <MenuItem
                        onClick={() => {
                            setSelectedForm(listItem);
                            setViewForm(true);
                            setAnchorEl(null);
                        }}
                    >
                        {listItem?.name} {listItem?.subject}
                    </MenuItem>
                ))}
            </Menu>
            <Dialog open={viewForm} maxWidth={"md"} scroll="body" onClose={() => setViewForm(false)}>
                <SectionCard
                    title={`${selectedFormType} | ${selectedPerson}`}
                    scrolling
                    secondaryAction={<Close onClick={() => setViewForm(false)} sx={{ cursor: "pointer" }} />}
                >
                    <div style={{ maxHeight: "70vh" }}>
                        <FormViewer
                            formFields={formFields}
                            form={selectedForm}
                            metricID={metricID}
                            setMetricID={setMetricID}
                        />
                    </div>
                </SectionCard>
            </Dialog>
        </Box>
    );
};

export default FormsTable;
