import {
	Box,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import React, { useState } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import progressListStyles from "./ProgressList.styles";
import ProgressListInfo from "./ProgressListInfo";

type ProgressListItem = {
	label: string;
	value: number;
	value_label?: string;
	target?: number;
};
type Props = {
	items: ProgressListItem[];
	baseline?: number;
	total?: number;
	variant?: "list" | "condensed";
	infoWindow?: boolean;
	rhs?: boolean | false;
};

const colours = (value: number) => {
	switch (true) {
		case value <= 1:
			return "red";
		case value === 2:
			return "yellow";
		case value >= 3:
			return "green";
		default:
			return "red";
	}
};

const ProgressList: React.FC<Props> = ({
	items,
	baseline = 100,
	total = 120,
	variant = "list",
	infoWindow = false,
	rhs = false,
}) => {
	const [infoWindowState, setInfoWindowState] = useState<boolean>(false);
	const classes = progressListStyles({
		baseline: !rhs ? baseline : total - baseline,
		total,
	});

	return (
        <Box className={classes.root}>
			{infoWindow && (
				<ProgressListInfo
					modalOpenState={infoWindowState}
					handleClose={() => setInfoWindowState(false)}
				/>
			)}
			<List>
				{items.map((item, index) => (
					<ListItem
						key={item.label + index}
						dense={variant === "condensed"}
					>
						<Grid container spacing={1} direction='column'>
							<Grid
								item
								container
								spacing={0}
								justifyContent='space-between'
							>
								<Typography variant='body2' component='div'>
									{!rhs ? (
										<Box>
											{item.label}
											{infoWindow && (
												<IconButton
													aria-label='info'
													size='small'
													className={
														classes.infoWindowButton
													}
													onClick={() =>
														setInfoWindowState(true)
													}
												>
													<InfoRounded
														classes={{
															root: classes.infoWindowIcon,
														}}
													/>
												</IconButton>
											)}
										</Box>
									) : (
										<Box>
											<span className={classes.value}>
												{item.value_label ?? item.value}
											</span>
											{item.target != null &&
												item.target > 0 && (
													<span
														className={
															classes.target
														}
													>
														/ {item.target}
													</span>
												)}
										</Box>
									)}
								</Typography>
								<Typography variant='body2' component='div'>
									{!rhs ? (
										<Box>
											<span className={classes.value}>
												{item.value_label ?? item.value}
											</span>{" "}
											{item.target != null &&
												item.target > 0 && (
													<span
														className={
															classes.target
														}
													>
														/ {item.target}
													</span>
												)}
										</Box>
									) : (
										<Box>
											{infoWindow && (
												<IconButton
													aria-label='info'
													size='small'
													className={
														classes.infoWindowButton
													}
													onClick={() =>
														setInfoWindowState(true)
													}
												>
													<InfoRounded
														classes={{
															root: classes.infoWindowIcon,
														}}
													/>
												</IconButton>
											)}
											{item.label}
										</Box>
									)}
								</Typography>
							</Grid>
							<Grid item>
								<ProgressBar
									dir={!rhs ? "ltr" : "rtl"}
									variant='buffer'
									colour={colours(
										item.target
											? Math.floor(
													item.value /
														item.target /
														0.25
											  )
											: 0
									)}
									value={
										((item.target
											? (item.value / item.target) * 100
											: 0) /
											total) *
										100
									}
									valueBuffer={(baseline / total) * 100}
								/>
							</Grid>
						</Grid>
					</ListItem>
				))}
			</List>
			{baseline > 0 && (
				<Divider
					orientation='vertical'
					absolute
					className={classes.baseline}
				/>
			)}
		</Box>
    );
};

export default ProgressList;
