import { httpClient } from "../../../../clients/http.client";

type t_FriendStatus = {
	can_request: boolean;
	notification: any;
	status?: {
		StatusID: number;
		Status: string;
	};
	record: {
		EntityNo: number;
		FriendEntityNo: number;
		FriendID: number;
		StatusID: number;
	};
	type: "outgoing" | "incoming";
};
const getFriendStatus = async (payload: { entity_no: number }) => {
	const response = await httpClient.post("/friends/status", payload);
	return response.data.data.friend_status;
};

const sendFriendRequest = async (payload: { entity_no: number }) => {
	const response = await httpClient.post("/friends/send_request", payload);
	return response.data.data.friend_status;
};

const respondToRequest = async (payload: {
	friend_request_id: number;
	accepted: boolean;
}) => {
	const response = await httpClient.post(
		"/friends/respond_to_request",
		payload
	);
	return response.data.data;
};

const removeFriend = async (payload: { friend_request_id: number }) => {
	const response = await httpClient.post("/friends/remove_friend", payload);
	return response.data.data;
};
export type { t_FriendStatus };
export { getFriendStatus, sendFriendRequest, respondToRequest, removeFriend };
