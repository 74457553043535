import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const awardBadgesStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: "transparent",
			display: "flex",
		},
		cardMedia: {
			padding: theme.spacing(2),
		},
		avatar: {
			height: 80,
			width: 80,
		},
		awardedBadgeCard: {
			border: `1px solid ${theme.palette.divider}`,
			width: "265px",
			height: "115px",
		},
		emptyBadgeContent: {
			display: "grid",
			placeItems: "center",
			width: "100%",
			height: "100%",
			color: theme.palette.text.disabled,
		},
		selectedBadge: {
			border: `1px solid ${theme.palette.info.main}`,
		},
		awardButton: {
			border: `1px solid ${theme.palette.info.main}`,
			// color: theme.palette.info.contrastText,
		},
	})
);

export default awardBadgesStyles;
