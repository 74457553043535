import React from "react";
import { Navigate, Outlet, Route, RouteProps } from "react-router-dom";

type Props = {
    permissions?: boolean[];
} & RouteProps;
const ProtectedRoute: React.FC<Props> = ({ permissions, children, Component, ...routeProps }) => {
    if (!permissions || permissions?.filter((permission) => permission !== true).length <= 0) {
        // return <Route {...routeProps} element={children} />;
        return <Outlet />;
    }
    // return null component if permissions are not met;
    return (
        // <Route path={routeProps.path}>
        <Navigate to={"/"} />
        // </Route>
    );
};

export default ProtectedRoute;
