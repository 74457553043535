import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const downtimeFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      width: "100%",
    },
    actionListItem: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const downtimeStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellActions: {
      display: "flex",
    },
    greyRow: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    editing: {
      color: theme.palette.info.main,
    },
    tableBody: {
      maxHeight: 250,
      overflowY: "auto",
    },
  })
);

export { downtimeFormStyles, downtimeStyles };
