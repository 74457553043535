import { Backdrop, Drawer, IconButton, List, ListItemText, useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import ViewList from "@mui/icons-material/ViewList";
import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard, { SectionCardProps } from "../../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import AppealRecord from "../../../Appeals/components/AppealRecord/AppealRecord";
import LsaRecord from "../../../LowScoreAnalysis/components/LsaRecord/LsaRecord";
import { t_SurveyPeriod } from "../../../Surveys/Surveys.types";
import AgentSurveyRecords from "../../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecords";
import { actions_AgentSurveyRecord } from "../../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecords.api";
import { SurveyReports } from "../../PerceptionReport";
import {
    AgentColumns,
    getTeamLeaderSummariesAny,
    // getTeamMemberSummaries,
    getTeamMemberSummariesAny,
    TeamLeaderColumns,
    TeamMember,
} from "./TeamLeaderSummary.api";
import teamLeaderSummaryStyles from "./TeamLeaderSummary.style";

type t_DialogState = {
    lsa_id?: number;
    appeal_id?: number;
    comment?: string;
};

const dialogDispatcher = (state: t_DialogState, { action, rowData }: actions_AgentSurveyRecord): t_DialogState => {
    if (!rowData) return {};

    switch (action) {
        case "lsa":
            return {
                lsa_id: rowData.LsaID,
            };
        case "appeal":
            return {
                appeal_id: rowData.AppealID,
            };
        case "comment":
            return {
                comment: rowData.Comments,
            };
    }
    return {};
};

interface TeamLeaderSummaryProps {
    periods: {
        [key: string]: t_SurveyPeriod;
    };
    report: SurveyReports;
    columns: (base: Column<any>[]) => Column<any>[];
    sectionCardProps?: SectionCardProps;
}

const TeamLeaderSummary: React.FC<TeamLeaderSummaryProps> = ({ periods, report, columns, sectionCardProps }) => {
    const theme = useTheme();
    const classes = teamLeaderSummaryStyles();
    const tableRef = React.createRef<any>();
    const dialogRef = React.createRef<any>();
    const [viewAgent, setViewAgent] = React.useState<TeamMember>();
    const [dialogState, dialogDispatch] = React.useReducer(dialogDispatcher, {});

    const handleAgentView = (agent: TeamMember) => {
        setViewAgent(agent);
    };

    React.useEffect(() => {
        if (tableRef.current != null && !tableRef.current.isLoading) {
            tableRef.current.onQueryChange();
        }
    }, [periods]);
    return (
        <SectionCard title="Team Leader Details" titleProps={{ variant: "subtitle1" }} fullWidth {...sectionCardProps}>
            <BUMaterialTable
                key={`${report}-team-leader-table`}
                tableRef={tableRef}
                columns={columns(TeamLeaderColumns)}
                data={(query) => getTeamLeaderSummariesAny({ query, report, periods })}
                options={{
                    padding: "dense",
                    toolbar: false,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                detailPanel={(rowData) => (
                    <div className={classes.agentTableContainer}>
                        <BUMaterialTable
                            columns={columns(AgentColumns)}
                            data={(query) =>
                                getTeamMemberSummariesAny({
                                    query,
                                    report,
                                    periods,
                                    teamLeader: rowData as TeamMember,
                                })
                            }
                            localization={{
                                header: {
                                    actions: "View",
                                },
                            }}
                            options={{
                                toolbar: false,
                                padding: "dense",
                            }}
                            actions={[
                                {
                                    icon: ViewList,
                                    tooltip: "View Agent Records",
                                    onClick: (_, rowData) => handleAgentView(rowData as TeamMember),
                                },
                            ]}
                        />
                    </div>
                )}
            />
            <SectionedDialog
                ref={dialogRef}
                open={Boolean(viewAgent)}
                onClose={() => {
                    setViewAgent(undefined);
                    dialogDispatch({});
                }}
                fullWidth
                maxWidth="lg"
                title={`${viewAgent?.DisplayName} - ${report.length > 3 ? report : report.toUpperCase()} Records`}
            >
                <Drawer
                    anchor="right"
                    variant="persistent"
                    PaperProps={{ style: { position: "absolute" } }}
                    BackdropProps={{ style: { position: "absolute" } }}
                    ModalProps={{
                        container: dialogRef.current,
                        style: { position: "absolute" },
                    }}
                    open={Boolean(
                        dialogState.lsa_id !== undefined ||
                            dialogState.appeal_id !== undefined ||
                            dialogState.comment !== undefined,
                    )}
                    onClose={() => dialogDispatch({})}
                >
                    <SectionCard
                        title={
                            (dialogState.lsa_id !== undefined && "LSA Details") ||
                            (dialogState.appeal_id !== undefined && "Appeal Details") ||
                            (dialogState.comment !== undefined && "Comment") ||
                            ""
                        }
                        titleProps={{ variant: "h6" }}
                        secondaryAction={
                            <IconButton onClick={() => dialogDispatch({})} size="large">
                                <ArrowForward />
                            </IconButton>
                        }
                        scrolling
                    >
                        {dialogState.lsa_id !== undefined && <LsaRecord lsa_id={dialogState.lsa_id} />}
                        {dialogState.appeal_id !== undefined && <AppealRecord appeal_id={dialogState.appeal_id} />}
                        {dialogState.comment !== undefined && (
                            <List>
                                <ListItemText
                                    style={{
                                        width: 400,
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {dialogState.comment}
                                </ListItemText>
                            </List>
                        )}
                    </SectionCard>
                </Drawer>
                <Backdrop
                    open={Boolean(
                        dialogState.lsa_id !== undefined ||
                            dialogState.appeal_id !== undefined ||
                            dialogState.comment !== undefined,
                    )}
                    onClick={() => dialogDispatch({})}
                    style={{ zIndex: theme.zIndex.drawer - 1 }}
                />
                {viewAgent && (
                    <AgentSurveyRecords
                        periods={periods}
                        survey={report}
                        entity_no={viewAgent.EntityNo}
                        dispatch={dialogDispatch}
                    />
                )}
            </SectionedDialog>
        </SectionCard>
    );
};

export default TeamLeaderSummary;
