import { Avatar, Badge, Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import badgeCardStyles from "./Badges.styles";
import gsap from "gsap";
import { getAwardableBadges, t_Badge } from "../User/components/AwardBadge/AwardBadge.api";
import { MiscBody } from "../../types/api.types";
import { t_UserBadge } from "../Profile/sections/ProfileBadges/ProfileBadges.types";

interface ActiveBadge {
    data: t_UserBadge;
    badgeAwardedBy: any;
}

const ActiveBadge = ({ data, badgeAwardedBy }: ActiveBadge) => {
    const classes = badgeCardStyles();

    useEffect(() => {
        // if (selectedUserBadge || selectedSystemBadge) {
        //     gsap.from(".badgeContent", { x: 0 });
        //     gsap.from(".badgeContent", { x: 600, duration: 1, ease: "power1.out" });
        // }
    });
    return (
        <>
            <SectionCard noHeader>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 10,
                        gap: ".5rem",
                    }}
                >
                    <Typography variant="h6">My Badges</Typography>
                    <Typography variant="subtitle2">{data?.count} Badge(s)</Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        flexFlow: "column",
                        alignItems: "center",
                        gap: ".8rem",
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                    className="badgeContent"
                >
                    <Box>
                        <Avatar src={data?.badge_img} className={classes.largeAvatar} />
                        <Typography variant="body1" style={{ textAlign: "center" }}>
                            {data?.name}
                        </Typography>
                        <Typography variant="body1" style={{ textAlign: "center" }}>
                            Number of badges acquired: {data?.count}
                        </Typography>
                    </Box>
                    <>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                flexFlow: "row",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant={"body2"} style={{ textAlign: "center" }}>
                                Badge Received from
                            </Typography>
                            <Typography variant={"body2"} style={{ textAlign: "center" }}>
                                Date Received
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: "100%",
                                maxHeight: 200,
                                overflowY: "scroll",
                            }}
                        >
                            {badgeAwardedBy
                                ? badgeAwardedBy.map((badge: any) => (
                                      <Box
                                          key={badge.badgeId}
                                          style={{
                                              borderTop: "solid 1px #F0F0F0",
                                              borderBottom: "solid 1px #F0F0F0",
                                              marginTop: 10,
                                              padding: 8,
                                              width: "100%",
                                          }}
                                      >
                                          <Box
                                              style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between",
                                                  borderRadius: 23,
                                                  margin: "auto",
                                                  padding: 5,
                                                  gap: ".5rem",
                                              }}
                                          >
                                              <Avatar
                                                  src={badge.badge_img}
                                                  style={{
                                                      width: 40,
                                                      height: 40,
                                                  }}
                                              />
                                              <Typography
                                                  style={{
                                                      width: 100,
                                                      textAlign: "left",
                                                  }}
                                                  className={classes.clampTeam}
                                              >
                                                  {badge.awarded_by}
                                              </Typography>
                                              <Typography
                                                  style={{
                                                      width: 150,
                                                      textAlign: "right",
                                                  }}
                                                  className={classes.clampTeam}
                                              >
                                                  {badge.Comment}
                                              </Typography>
                                              <Typography
                                                  style={{
                                                      width: 150,
                                                      textAlign: "right",
                                                      fontWeight: "bold",
                                                  }}
                                                  className={classes.clampTeam}
                                              >
                                                  {badge.DataDate}
                                              </Typography>
                                          </Box>
                                      </Box>
                                  ))
                                : null}
                        </Box>
                    </>
                </Box>
            </SectionCard>
        </>
    );
};

export default ActiveBadge;
