import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    threadUpdate: {},
};

const threadSlice = createSlice({
    name: "thread",
    initialState,
    reducers: {
        setThreadUpdate: (state, action) => {
            state.threadUpdate = action.payload;
        },
    },
});

export const { setThreadUpdate } = threadSlice.actions;

export default threadSlice.reducer;
