import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useGetTipMutation } from "../../redux/api/beYouCore";
import CoachTipsCard from "./CoachTipsCard";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import { Avatar, Box, List, ListItem, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import bot from "../../beYou_bot.png";
import { NotificationsContext } from "../../components/Toasts/NotificationsProvider.ctx";
import { walkThroughTips } from "../../pages/Home/BeYouCoach/WalkThroughData";

const CoachTipsNotifications = () => {
    const [tips, setTips] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const TeamMetrics = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const tipsWalkThrough = useSelector((state: RootState) => state.walkThrough.tipsWalkThrough);
    const tipsToExclude = useSelector((state: RootState) => state.tips.removeTip);
    const [getTip] = useGetTipMutation();
    const [{ month }] = usePerformanceContext();
    const { showSidePanelChatBot } = useContext(NotificationsContext);

    const badPerformance = allMetrics.filter((metric: any) => metric.percentage_of_target < 100);

    function getTeamMembersWithPercentageOfTarget(metricData: any, metricName: any) {
        const teamMembers: {
            name: string;
            percentage_of_target: number;
            target: number;
            score: number;
        }[] = [];
        for (const memberData of metricData) {
            if (metricName in memberData.metrics) {
                const metric = memberData.metrics[metricName];
                if (metric.percentage_of_target == 100) {
                    teamMembers.push({
                        name: memberData.user.name,
                        percentage_of_target: metric.percentage_of_target,
                        target: metric.target,
                        score: metric.value,
                    });
                }
            }
        }
        return teamMembers;
    }

    function getTeamMembersWithoutPercentageOfTarget(metricData: any, metricName: any) {
        const teamMembers: {
            name: string;
            percentage_of_target: number;
            target: number;
            score: number;
        }[] = [];
        for (const memberData of metricData) {
            if (metricName in memberData.metrics) {
                const metric = memberData.metrics[metricName];
                if (metric.percentage_of_target != 100) {
                    teamMembers.push({
                        name: memberData.user.name,
                        percentage_of_target: metric.percentage_of_target,
                        target: metric.target,
                        score: metric.value,
                    });
                }
            }
        }
        return teamMembers;
    }

    const results: any = [];

    useEffect(() => {
        if (showSidePanelChatBot) {
            if (tipsWalkThrough) {
                setTips(walkThroughTips);
            } else {
                setIsLoading(true);
                const promises: any = [];
                badPerformance.map((bpMetric: any) => {
                    const metric_id = bpMetric.id;
                    const promise = getTip({ metric_id })
                        .unwrap()
                        .then((data) => {
                            const tipNumber = Math.floor(Math.random() * data.data.tips.length);
                            const res = data.data.tips[tipNumber];
                            if (res) {
                                const data = { ...res, metricId: metric_id };
                                results.push(data);
                            }
                        });
                    promises.push(promise);
                });

                Promise.all(promises)
                    .then(() => {
                        const data = results.filter(Boolean);
                        setTips(data);
                    })
                    .catch((error) => {
                        console.error("Metrics Error:", error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    }, [month, showSidePanelChatBot, tipsWalkThrough]);

    if (isLoading) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
                <Skeleton width="100%" height="80px" />
                <Skeleton width="100%" height="80px" />
                <Skeleton width="100%" height="80px" />
            </Box>
        );
    }

    const metricsWithTeamMembers = allMetrics.map((metric: any) => ({
        metricName: metric.name,
        with: getTeamMembersWithPercentageOfTarget(TeamMetrics, metric.name),
        without: getTeamMembersWithoutPercentageOfTarget(TeamMetrics, metric.name),
    }));

    const filteredTips = tips.filter((tip: any) => !tipsToExclude.includes(tip.metricId));

    return (
        <>
            {badPerformance.length <= 0 && (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary">
                        You are currently doing well on your metrics, no tips are available at present.
                    </Typography>
                </Box>
            )}
            {filteredTips.map((tip: any, index: any) => (
                <CoachTipsCard tip={tip} id={tip.metricId} key={`${index}-${tip?.CoachTipID}`} />
            ))}
            {metricsWithTeamMembers.map((metricData: any) => (
                <MetricList key={metricData.metricName} metricData={metricData} userMetrics={allMetrics} />
            ))}
        </>
    );
};

const MetricList = ({ metricData }: any) => {
    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const { metricName, with: teamMembersWith, without: teamMembersWithout } = metricData;
    const isTargetMet = teamMembersWith.length > 0;
    const isTargetNotMet = teamMembersWithout.length > 0;
    const [isListShowing, setIsListShowing] = useState(false);
    function getPercentByName(name: string) {
        for (let i = 0; i < allMetrics.length; i++) {
            if (allMetrics[i].name == name) {
                return allMetrics[i].percentage_of_target;
            }
        }
        return null; // Return null if the specified 'name' is not found in the array
    }
    return (
        <>
            {getPercentByName(metricName) == 100 && teamMembersWithout.length > 0 ? (
                <Box
                    style={{
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#F0F0F0",
                        borderRadius: "1em",
                        border: ".01px solid rgba(184, 210, 2,0.1)",
                        position: "relative",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        width="100%"
                    >
                        <Box display="flex" alignItems="center" p={1}>
                            <Avatar src={bot} style={{ width: 50, height: 50 }} />
                        </Box>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: ".85rem",
                                fontWeight: "bold",
                            }}
                        >
                            We Noticed You are doing well with <span style={{ color: "#30D843" }}>{metricName}</span>.
                            Consider reaching out to help the following:
                        </Typography>
                        {isListShowing && (
                            <span
                                style={{
                                    position: "absolute",
                                    top: 3,
                                    right: 5,
                                    border: ".8px solid lightgray",
                                    borderRadius: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ExpandLessOutlined
                                    onClick={() => setIsListShowing(false)}
                                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                                />
                            </span>
                        )}
                    </Box>
                    {isListShowing ? (
                        <List style={{ paddingLeft: 50 }}>
                            {teamMembersWithout.map((item: any, index: number) => (
                                <ListItem
                                    key={index}
                                    style={{
                                        fontSize: ".85rem",
                                        paddingBottom: 0,
                                        cursor: "pointer",
                                    }}
                                >
                                    {item.name}
                                    <span
                                        style={{
                                            marginLeft: "auto",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.score}/{item.target}
                                    </span>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <span
                            style={{
                                position: "absolute",
                                bottom: 3,
                                right: 5,
                                border: ".8px solid lightgray",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <ExpandMoreOutlined
                                onClick={() => setIsListShowing(true)}
                                style={{ cursor: "pointer", width: "15px", height: "15px" }}
                            />
                        </span>
                    )}
                </Box>
            ) : null}
            {getPercentByName(metricName) != 100 && teamMembersWith.length > 0 ? (
                <Box
                    style={{
                        paddingBottom: "10px",
                        paddingTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#F0F0F0",
                        borderRadius: "1em",
                        border: ".01px solid rgba(184, 210, 2,0.1)",
                        position: "relative",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        width="100%"
                    >
                        <Box display="flex" alignItems="center" p={1}>
                            <Avatar src={bot} style={{ width: 50, height: 50 }} />
                        </Box>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: ".85rem",
                                fontWeight: "bold",
                            }}
                        >
                            We Noticed You are not doing well with{" "}
                            <span style={{ color: "#FF4040" }}>{metricName}</span>. Consider reaching out to the
                            following for help:
                        </Typography>
                        {isListShowing && (
                            <span
                                style={{
                                    position: "absolute",
                                    top: 3,
                                    right: 5,
                                    border: ".8px solid lightgray",
                                    borderRadius: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ExpandLessOutlined
                                    onClick={() => setIsListShowing(false)}
                                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                                />
                            </span>
                        )}
                    </Box>
                    {isListShowing ? (
                        <List style={{ paddingLeft: 50 }}>
                            {teamMembersWith.map((item: any, index: number) => (
                                <ListItem
                                    key={index}
                                    style={{
                                        fontSize: ".85rem",
                                        paddingBottom: 0,
                                    }}
                                >
                                    {item.name}
                                    <span
                                        style={{
                                            marginLeft: "auto",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.score}/{item.target}
                                    </span>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <span
                            style={{
                                position: "absolute",
                                bottom: 3,
                                right: 5,
                                border: ".8px solid lightgray",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <ExpandMoreOutlined
                                onClick={() => setIsListShowing(true)}
                                style={{ cursor: "pointer", width: "15px", height: "15px" }}
                            />
                        </span>
                    )}
                </Box>
            ) : null}
        </>
    );
};

export default CoachTipsNotifications;
