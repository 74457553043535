import { Box, Grid } from "@mui/material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { useRequestQueue } from "../../../../context/notifications/requestQueue.ctx";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import { useUserState } from "../../../../context/user/user.ctx";
import SurveyMetricRecord from "../../../Surveys/components/SurveyMetricRecord/SurveyMetricRecord";
import { t_MetricSurveyRecord } from "../../../Surveys/tables/MetricSurveyRecords/MetricSurveyRecords.types";
import LsaComments from "../../components/LsaComments/LsaComments";
import LsaRecord from "../../components/LsaRecord/LsaRecord";
import LsaStepper from "../../components/LsaStepper/LsaStepper";
import { t_lsaStepperActions } from "../../components/LsaStepper/LsaStepper.types";
import { createLowScoreAnalysis } from "./LsaDialogContent.api";
import lsaDialogContentStyles from "./LsaDialogContent.styles";

interface LsaDialogProps {
	lsa_id?: number;
	record: t_MetricSurveyRecord;
	lsa: {
		DataID: number;
		StatusID?: number;
	};
	metric_id: number;
	dispatch?: (reason?: string) => void;
}
enum ForButton {
	EDIT = 1,
	COMMENT = 2,
}
const LsaDialogContent: React.FC<LsaDialogProps> = ({
	lsa_id,
	record,
	lsa,
	metric_id,
	dispatch,
}) => {
	const classes = lsaDialogContentStyles();
	const currentUser = useUserState();
	const permissions = usePermissions();

	const [lsaID, setLsaID] = React.useState<number | undefined>(lsa_id);
	const [detailsTab, setDetailTab] = React.useState<number>(0);
	const [loadingFor, setLoadingFor] = React.useState<number>(0);
	const { pushRequestToQueue } = useRequestQueue();

	const handleStepperDispatch = (action: t_lsaStepperActions) => {
		if (dispatch != null) {
			switch (action.type) {
				case "save":
					dispatch("update");
					break;
			}
		}
	};

	const handleCreateLSA = (forButton: ForButton) => {
		setLoadingFor(forButton);
	};

	// using effects to run requests is recommended;
	React.useEffect(() => {
		var isActive = true;
		if (loadingFor > 0) {
			pushRequestToQueue({
				type: "only_error",
				request: createLowScoreAnalysis({
					data_id: record.DataID,
					metric_id,
				}),
				callback: (data: any) => {
					if (data.lsa.LsaID != null) {
						setLsaID(data.lsa.LsaID);
						if (dispatch != null) {
							dispatch("update");
						}
					}
				},
				finally: () => {
					setLoadingFor(0);
				},
			});
		}
		return () => {
			isActive = false;
		};
	}, [loadingFor]);

	return (
		<Grid container spacing={2} className={classes.container}>
			{/* <Grid item sm={5} className={classes.item}> */}
			<Grid item className={classes.item}>
				{permissions.lsa_create && lsaID != null ? (
					<LsaStepper
						lsa_id={lsaID}
						metric_id={metric_id}
						data_id={lsa.DataID}
						dispatch={handleStepperDispatch}
					/>
				) : (
					<SectionCard
						hasBackground
						noHeader
						scrolling
						bottomActions={
							permissions.lsa_create && (
								<Box
									width='100%'
									display='flex'
									justifyContent='flex-end'
								>
									<SubmitButton
										loading={loadingFor === ForButton.EDIT}
										disabled={loadingFor > 0}
										onClick={() =>
											handleCreateLSA(ForButton.EDIT)
										}
									>
										Edit LSA
									</SubmitButton>
								</Box>
							)
						}
					>
						<LsaRecord lsa_id={lsaID} />
					</SectionCard>
				)}
			</Grid>
			<Grid item sm className={classes.item}>
				<SectionCard
					fullWidth={detailsTab === 1}
					// noHeader
					hasBackground
					primaryAction={
						<TabGroup
							tabValue={detailsTab}
							tabChange={(value) => setDetailTab(value)}
							tabList={[
								{ _id: 0, label: "Details" },
								{ _id: 1, label: "Comments" },
							]}
						/>
					}
					bottomActions={
						detailsTab === 1 &&
						!(lsaID != null) && (
							<Box
								width='100%'
								display='flex'
								justifyContent='flex-end'
							>
								<SubmitButton
									loading={loadingFor === ForButton.COMMENT}
									disabled={loadingFor > 0}
									onClick={() =>
										handleCreateLSA(ForButton.COMMENT)
									}
								>
									Leave Comment
								</SubmitButton>
							</Box>
						)
					}
				>
					{detailsTab === 0 && (
						<Box width={400}>
							<SurveyMetricRecord record={record} />
						</Box>
					)}
					{detailsTab === 1 && (
						<LsaComments
							lsa_id={lsaID}
							canComment={
								currentUser.profile?.entity_no ==
									record.EntityNo || permissions.lsa_create
							}
						/>
					)}
				</SectionCard>
			</Grid>
		</Grid>
	);
};

export default LsaDialogContent;
