import { httpClient } from "../../../clients/http.client";

const getProductionTeamPools = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post("admin/production/team/pools", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const respData = data.data.pools;
            res({
              data: respData,
              page: data.pagination.page - 1,
              totalCount: data.total_results,
            });
            break;
          case "error":
            rej(data.message);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const saveProductionTeamPool = (payload: { pool: any }) => {
  return new Promise<void>((res, rej) => {
    const body = {
      team_id: payload.pool.team_id,
      id: payload.pool.id,
      pool_id: payload.pool.pool_id,
      expected_handling_time: payload.pool.handling_time,
      entity_no: payload.pool.entity_no,
    };

    httpClient
      .post("admin/production/team/pools/save", body)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res(data.message);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const deleteProductionTeamPool = (payload: { pool: any }) => {
  return new Promise<void>((res, rej) => {
    const body = {
      team_id: payload.pool.pool_id,
      pool_id: payload.pool.pool_id,
      expected_handling_time: payload.pool.handling_time,
      entity_no: payload.pool.entity_no,
    };
    httpClient
      .post("/admin/production/team/pools/delete", body)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res(data.message);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

export {
  getProductionTeamPools,
  saveProductionTeamPool,
  deleteProductionTeamPool,
};
