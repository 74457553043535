import { VerifiedOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Backdrop, Box, Button, Dialog, Grid, MenuItem, Select, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TeamSummary from "../../../components/ChatBot/TeamSummary";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import { useUserState } from "../../../context/user/user.ctx";
import useWindowDimensions from "../../../misc/screenSizes";
import { setUserInfo } from "../../../redux/slices/UserSlice";
import { setViewUserInfo } from "../../../redux/slices/ViewUserSlice";
import { RootState } from "../../../redux/store";
import AwardBadge from "../../../sections/Badges/AwardBadge";
import NewBadgeList from "../../../sections/Badges/NewBadgeList";
import DetailedBreakDown from "../../../sections/DetailedBreakDown/DetailedBreakDown";
import NewsFeed from "../../../sections/NewsFeed/NewsFeed";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";
import TeamPerformance from "../../../sections/Performance/sections/TeamPerformance/TeamPerformance";
import NewProfileCard from "../../../sections/Profile/components/CoachProfileCard/NewProfileCard";
import StrenghtsWeaknessesCard from "../../../sections/SWcard/SWcard";
import BeYouLoading from "./BeYouLoading";

const BeYouCoach = () => {
    const currentUser = useUserState();
    const [awardBadge, setAwardBadge] = useState(false);
    const [sectionTab, setSectionTab] = React.useState(0);
    const [{ month, comparison_month }, performanceDispatch] = usePerformanceContext();
    const metric_values = useSelector((state: RootState) => state.metrics.allmetrics);
    const [selectedMetric, setSelectedMetric] = useState<any>(
        metric_values.find((attendanceId: any) => attendanceId?.id == 7) || metric_values[0],
    );
    const { height: screenHeight } = useWindowDimensions();

    const user = useSelector((state: RootState) => state.user.userInfo);
    const team = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const viewTeamMember = useSelector((state: RootState) => state.view.viewUserInfo);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date(month));
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [isViewTeamOpen, setIsViewTeamOpen] = useState(false);
    const metricValueArr = useMemo(() => {
        const arr = [{ id: 100, name: "Overall Ranking", value: 100 }];
        return [...arr, ...metric_values];
    }, [metric_values]);
    const loading = useSelector((state: RootState) => state.beYou.beYouLoading);

    useEffect(() => {
        if (currentUser.entity_no) {
            if (currentUser.entity_no === viewTeamMember?.user?.entity_no) {
                dispatch(setUserInfo(currentUser.profile));
                dispatch(setViewUserInfo({}));
            } else if (viewTeamMember?.user?.entity_no) {
                dispatch(setUserInfo(viewTeamMember.user));
            } else {
                return;
            }
        }
    }, [viewTeamMember, currentUser]);

    const members: any = [];

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    if (team.length > 0) {
        team.map((member: any) => {
            const memberDetails = {
                id: member.user.entity_no,
                label: member.user.name,
            };

            return members.push(memberDetails);
        });
    }

    useEffect(() => {
        if (metric_values != null && metric_values?.length > 0) {
            setSelectedMetric(metric_values.find((attendanceId: any) => attendanceId?.id == 7) || metric_values[0]);
        }
    }, [metric_values, selectedDate]);

    const insightsPermissions =
        currentUser.profile?.permissions.find((perm) => perm.id === 13) ||
        currentUser?.profile?.entity_no === user?.entity_no;

    const viewingProfile = currentUser.entity_no === user?.entity_no;
    return (
        <>
            {loading ? (
                <BeYouLoading />
            ) : (
                <Grid>
                    <SectionCard
                        title="BeYou"
                        subtitle={currentUser.profile?.name}
                        fullWidth
                        className="beYouCoach"
                        primaryAction={
                            <Box
                                display="flex"
                                flexDirection={{ xs: "column", sm: "row" }}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <TabGroup
                                    tabList={[
                                        {
                                            _id: 0,
                                            label: `${user.name}'s PERFORMANCE`,
                                            options: members,
                                        },
                                    ]}
                                    tabChange={(newValue) => setSectionTab(newValue)}
                                    tabValue={sectionTab}
                                />
                                {!viewingProfile && (
                                    <Button
                                        onClick={() => {
                                            dispatch(setUserInfo(currentUser.profile));
                                            dispatch(setViewUserInfo({}));
                                        }}
                                        variant="contained"
                                        sx={{
                                            boxShadow: "none",
                                            marginTop: { xs: 1, sm: 0 },
                                            marginLeft: 2,
                                            padding: "10px 12px",
                                        }}
                                    >
                                        Reset
                                    </Button>
                                )}
                                {currentUser.profile?.permissions.find((perm) => perm.id === 13) && (
                                    <Button
                                        onClick={() => {
                                            setIsViewTeamOpen(true);
                                        }}
                                        variant="contained"
                                        className="view-team"
                                        sx={{
                                            boxShadow: "none",
                                            marginTop: { xs: 1, sm: 0 },
                                            marginLeft: 2,
                                            padding: "10px 12px",
                                        }}
                                    >
                                        View Team
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    className="view-team"
                                    sx={{
                                        boxShadow: "none",
                                        marginTop: { xs: 1, sm: 0 },
                                        marginLeft: 2,
                                        padding: "10px 12px",
                                    }}
                                    onClick={() => navigate("/friends")}
                                >
                                    Friends
                                </Button>
                            </Box>
                        }
                        secondaryAction={
                            <>
                                <DatePicker
                                    openTo="month"
                                    views={["year", "month"]}
                                    value={new Date(month)}
                                    onChange={handleDateChange}
                                    closeOnSelect={false}
                                    className="date-picker-beYouCoach"
                                    onOpen={() => setIsDateOpen(true)}
                                    onClose={() => {
                                        performanceDispatch({
                                            type: "update_dates",
                                            month: selectedDate,
                                        });
                                        setIsDateOpen(false);
                                    }}
                                    slotProps={{ textField: { variant: "standard" } }}
                                />
                                <Backdrop sx={{ zIndex: 2 }} open={isDateOpen}></Backdrop>
                            </>
                        }
                    ></SectionCard>

                    <Grid container spacing={2} style={{ padding: 16, position: "relative" }}>
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                backgroundSize: "cover",
                                backgroundRepeat: "repeat-x",
                                backgroundImage: "url(/images/background-wave.png)",
                                opacity: 0.05,
                            }}
                        />
                        <Grid item xs={12} sm={12} lg={8} md={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    {/* <CoachProfileCard /> */}
                                    <NewProfileCard />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SectionCard
                                        title="News Feed"
                                        fontSize={20}
                                        fullWidth
                                        hasBackground
                                        hasDivider={false}
                                    >
                                        <div
                                            style={{
                                                height: screenHeight > 900 ? "320px" : "250px",
                                                width: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            <NewsFeed />
                                        </div>
                                    </SectionCard>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ position: "relative" }}>
                                    <SectionCard
                                        title="Badges"
                                        hasBackground
                                        fontSize={20}
                                        className="badges-area"
                                        secondaryAction={
                                            <>
                                                <Button
                                                    onClick={() => navigate("/beyoucoach/badges")}
                                                    variant="contained"
                                                    style={{ boxShadow: "none" }}
                                                    color="secondary"
                                                    className="badges-area-view-all"
                                                >
                                                    <VisibilityOutlined />
                                                </Button>
                                                {currentUser?.profile?.entity_no !== user?.entity_no ? (
                                                    <Button
                                                        onClick={() => setAwardBadge(true)}
                                                        variant="contained"
                                                        style={{
                                                            boxShadow: "none",
                                                            marginLeft: 10,
                                                        }}
                                                        color="secondary"
                                                    >
                                                        <VerifiedOutlined />
                                                    </Button>
                                                ) : null}
                                            </>
                                        }
                                    >
                                        <NewBadgeList />
                                    </SectionCard>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="strengths-area"
                                            style={{ position: "relative" }}
                                        >
                                            <SectionCard hasBackground title="Strength" fontSize={20}>
                                                <StrenghtsWeaknessesCard strengths />
                                            </SectionCard>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            className="focus-area"
                                            style={{ position: "relative" }}
                                        >
                                            <SectionCard hasBackground title="Focus Areas" fontSize={20}>
                                                <StrenghtsWeaknessesCard />
                                            </SectionCard>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4} className="metric-rankings">
                            <SectionCard
                                title="Metric Ranking"
                                fontSize={20}
                                className="metric-rankings"
                                secondaryAction={
                                    <Select
                                        value={selectedMetric?.id}
                                        label="Metric Ranking"
                                        variant="standard"
                                        className="filter-metric-rankings"
                                    >
                                        {metricValueArr?.map((metric: any) => (
                                            <MenuItem
                                                value={metric?.id}
                                                key={metric?.id}
                                                onClick={() => {
                                                    setSelectedMetric(metric);
                                                }}
                                            >
                                                <Typography>{metric?.name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                                fullWidth
                                hasBackground
                                scrolling
                                hasDivider={false}
                            >
                                <div
                                    style={{
                                        height: "200px",
                                        width: "100%",
                                        position: "relative",
                                        padding: "1em",
                                    }}
                                >
                                    <TeamSummary viewMetric={selectedMetric} />
                                </div>
                            </SectionCard>
                        </Grid>

                        <Grid item xs={12}>
                            <SectionCard
                                title="Detailed Break Down"
                                hasBackground
                                fontSize={20}
                                className="detailed-break-down-area"
                                secondaryAction={
                                    <>
                                        {insightsPermissions && (
                                            <Button
                                                onClick={() => {
                                                    const location = {
                                                        pathname: `/beyoucoach/insights/`,
                                                        state: {
                                                            entity_no: user.entity_no,
                                                            name: user.display_name,
                                                        },
                                                    };
                                                    navigate(location);
                                                }}
                                                variant="contained"
                                                style={{ boxShadow: "none" }}
                                                color="secondary"
                                                className="detailed-break-down-area-view-insights"
                                            >
                                                <VisibilityOutlined />
                                            </Button>
                                        )}
                                    </>
                                }
                            >
                                <DetailedBreakDown
                                    strokeColor={{
                                        strokeColorOne: "#2ECA40",
                                        strokeColorTwo: "#FF4040",
                                    }}
                                    entity_no={currentUser.entity_no}
                                    month={month}
                                    comparison_month={comparison_month}
                                    insightsPermissions={insightsPermissions}
                                />
                            </SectionCard>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Dialog open={awardBadge} onClose={() => setAwardBadge(false)} scroll={"body"}>
                <AwardBadge entity_no={user.entity_no} onBadgeAwarded={() => setAwardBadge(false)} />
            </Dialog>
            <Dialog
                open={isViewTeamOpen}
                onClose={() => setIsViewTeamOpen(false)}
                scroll={"body"}
                fullWidth
                maxWidth="xl"
            >
                <TeamPerformance setIsViewTeamOpen={setIsViewTeamOpen} />
            </Dialog>
            {/* <AvatarCreator subdomain="demo" config={config} style={style} onAvatarExported={handleOnAvatarExported} /> */}
        </>
    );
};

export default BeYouCoach;
