import { httpClient } from "../../../clients/http.client";
import { t_UserAttributesForm } from "./UserAttributes.types";

const getUserAttributes = (payload: { entity_no: number }) =>
	new Promise<t_UserAttributesForm>((resolve, reject) => {
		httpClient
			.post("/users/attributes", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.attributes);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const saveUserAttributes = (
	payload: {
		entity_no?: number;
	} & t_UserAttributesForm
) =>
	new Promise((resolve, reject) => {
		const body = {
			entity_no: payload.entity_no,
			service_team_id: payload.service_team?.id,
			skill_id: payload.skill?.id,
			second_in_command: payload.second_in_command,
		};

		httpClient
			.post("/users/attributes/save", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
export { getUserAttributes, saveUserAttributes };
