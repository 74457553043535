import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const currentChallengeStyles = makeStyles((theme: Theme) =>
	createStyles({
		sectionTitle: {
			width: "100%",
			padding: theme.spacing(0, 2),
			display: "grid",
		},
	})
);

export default currentChallengeStyles;
