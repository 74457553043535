import { httpClient } from "../../../../clients/http.client";
import { MiscBody } from "../../../../types/api.types";

const getUserBadges = async (payload: { entity_no: number } & MiscBody) => {
	const response = await httpClient.post("/profile/badges/user", payload);
	return response.data;
};

const getAwardedBadges = async (payload: { badge_id: number }) => {
	const response = await httpClient.post("/badges/get", payload);
	return response.data;

};
export { getUserBadges, getAwardedBadges };
