import React, { ReactNode, createContext, useState } from "react";

type ToastStateType = {
    showWeeklyNotification: boolean;
    setShowWeeklyNotification: (value: boolean) => void;
};

const initialState: ToastStateType = {
    showWeeklyNotification: false,
    setShowWeeklyNotification: () => {},
};

export const ToastAction = createContext(initialState);

export const ToastActionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [showWeeklyNotification, setShowWeeklyNotification] = useState(false);

    const state: ToastStateType = {
        showWeeklyNotification,
        setShowWeeklyNotification,
    };

    return <ToastAction.Provider value={state}>{children}</ToastAction.Provider>;
};
