import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { MiscBody } from "../../../../types/api.types";
import { getJobLevel } from "../../../User/User.api";
import {
	getAccessUsers,
	getPermissions,
	getRoleJobLevels,
	getRolePermissions,
	getRoleUsers,
} from "../../Access.api";
import { t_Role } from "../../Access.types";
import ManageAccess from "../ManageAccess/ManageAccess";
import {
	updateRolePermissions,
	updateRolesJobLevels,
	updateRoleUsers,
} from "./MaintainRole.api";

interface Props {
	role: t_Role;
}

const MaintainRole: React.FC<Props> = ({ role }) => {
	const [tab, setTab] = React.useState(0);

	return (
		<SectionCard
			title={`Role: ${role.name}`}
			titleProps={{ variant: "h6" }}
			primaryAction={
				<TabGroup
					tabValue={tab}
					tabChange={(value) => setTab(value)}
					tabList={[
						{ _id: 0, label: "Permissions" },
						{ _id: 1, label: "Users" },
						{ _id: 2, label: "Job Level" },
					]}
				/>
			}
			hasBackground
		>
			{tab === 0 && (
				<ManageAccess
					term='Permissions'
					accessItem={role}
					searchForItems={(params) =>
						getPermissions({
							filter: [
								{
									column_name: "Name",
									search_term: params.search,
								},
							],
						})
					}
					fetchItems={(params) =>
						getRolePermissions({
							role_id: params.id,
							...(params as MiscBody),
						})
					}
					updateItems={(params) =>
						updateRolePermissions({
							role_id: params.id,
							grant_permissions: params.grant_access,
							revoke_permissions: params.revoke_access,
						})
					}
				/>
			)}
			{tab === 1 && (
				<ManageAccess
					term='User'
					accessItem={role}
					searchForItems={getAccessUsers}
					fetchItems={(params) =>
						getRoleUsers({
							role_id: params.id,
							...(params as MiscBody),
						})
					}
					updateItems={(params) =>
						updateRoleUsers({
							role_id: params.id,
							grant_for_users: params.grant_access,
							revoke_for_users: params.revoke_access,
						})
					}
				/>
			)}
			{tab === 2 && (
				<ManageAccess
					term='Job'
					accessItem={role}
					searchForItems={(params) =>
						getJobLevel({
							filter: [
								{
									column_name: "Name",
									search_term: params.search,
								},
							],
						})
					}
					fetchItems={(params) =>
						getRoleJobLevels({
							role_id: params.id,
							...(params as MiscBody),
						})
					}
					updateItems={(params) =>
						updateRolesJobLevels({
							role_id: params.id,
							grant_for_job_levels: params.grant_access,
							revoke_for_job_levels: params.revoke_access,
						})
					}
				/>
			)}
		</SectionCard>
	);
};

export default MaintainRole;
