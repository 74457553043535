import { CircularProgress, TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import React from "react";
import useAutocomplete, {
	useAutocompleteProps,
} from "../../hooks/component/useAutocomplete";

type Option = {
	id: string;
	name: string;
};
type Props<Options, Params> = {
	value?: Options;
	onChange: (value: Options | null) => void;
	label: string;
	inputClassName: string;
} & useAutocompleteProps<Options, Params>;

function BUAutocomplete<Params extends object>({
	value,
	onChange,
	dataSource,
	initialParams = undefined,
	label,
	inputClassName,
}: Props<Option, Params>) {
	const { search, options, loading } = useAutocomplete({
		dataSource,
		initialParams,
	});

	return (
        <Autocomplete
			value={value}
			onChange={(_, value) => onChange(value)}
			onInputChange={(_, value) => search(value)}
			options={[
				...options,
				{ id: "empty", name: "Refine search to see more results..." },
			]}
			loading={loading}
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(option) => option.id === value?.id}
			getOptionDisabled={(option) => option.id === "empty"}
			renderInput={(params) => (
				<TextField
					{...params}
					className={inputClassName}
					label={label}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? (
									<CircularProgress
										color='inherit'
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
    );
}

export default BUAutocomplete;
