import React, { useEffect, useState } from "react";
import { Grid, Avatar, Typography, Box, Chip } from "@mui/material";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import CoachProfileStyles from "./CoachProfileCard.style";
import CoachProfilePositionPill from "./CoachProfilePositionPill";
import CoachProfileRank from "./CoachProfileRank";
interface CoachProfileStatsCard {
    image?: any;
    title?: string;
    rank: string | undefined;
    currentRank?: number;
    wins?: number;
    losses?: number;
    fullWidth?: any;
    halfWidth?: any;
    isRow?: any;
    bgCardColor?: any;
    largeImg?: boolean;
    place: string | undefined;
}

const CoachProfileStatsCard = ({
    image,
    title,
    rank,
    wins,
    losses,
    fullWidth,
    halfWidth,
    isRow,
    currentRank,
    bgCardColor,
    largeImg,
    place,
}: CoachProfileStatsCard) => {
    const [rankChange, setRankChange] = useState("up");
    const [suffix, setSuffix] = useState("");
    const classes = CoachProfileStyles();

    const addSuffix = (ranking: any) => {
        let a = ranking % 10;
        let b = ranking % 100;

        if (a == 1 && b != 11) {
            setSuffix("st");
        } else if (a == 2 && b != 12) {
            setSuffix("nd");
        } else if (a == 3 && b != 13) {
            setSuffix("rd");
        } else {
            setSuffix("th");
        }
    };

    useEffect(() => {
        addSuffix(rank);
    }, [rank]);

    return (
        <Grid spacing={0} container direction={isRow ? "row" : "column"}>
            <Grid item xs={fullWidth ? 12 : halfWidth} sm={fullWidth ? 12 : halfWidth}>
                <SectionCard noHeader hasBackground hasDivider={false} className={classes.card}>
                    <Grid
                        container
                        direction={isRow ? "row" : "column"}
                        item
                        xs={12}
                        sm={12}
                        style={{
                            height: isRow ? "auto" : 100,
                            alignItems: isRow ? "center" : "flex-start",
                            justifyContent: isRow ? "space-between" : "space-between",
                        }}
                    >
                        <Grid item>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                    alignItems: "center",
                                    flexFlow: "row",
                                    gap: 5,
                                }}
                            >
                                <img
                                    src={image}
                                    alt={title}
                                    className={largeImg ? classes.largeAvatar : classes.smallAvatar}
                                />
                                <Typography variant="body2">{title}</Typography>
                            </Box>
                        </Grid>
                        {isRow ? (
                            <Grid item>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        width: "100%",
                                        alignItems: "center",
                                        flexFlow: isRow ? "row" : "column",
                                        gap: "1.5rem",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "",
                                            alignItems: "center",
                                            flexFlow: "row",
                                        }}
                                    >
                                        <CoachProfileRank rank={rank} />
                                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                            {suffix}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ) : null}
                        <Grid item>
                            <CoachProfilePositionPill
                                currentRank={currentRank}
                                rank={rank}
                                wins={wins}
                                losses={losses}
                                place={place}
                            />
                        </Grid>
                    </Grid>
                </SectionCard>
            </Grid>
        </Grid>
    );
};
export default CoachProfileStatsCard;
