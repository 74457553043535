import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
    Avatar,
    Grid,
    Typography,
    CircularProgress,
    Box,
    IconButton,
    Badge,
    Dialog,
    TextField,
    Button,
    CardContent,
    CardActions,
    TextareaAutosize,
    InputBase,
    Card,
    Tooltip,
} from "@mui/material";
import CoachProfileStyles from "./CoachProfileCard.style";
import { getImageUrl } from "../../../../misc/helpers";
import CoachProfileStatsCard from "./CoachProfileStatCards";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Edit, Groups2, Work } from "@mui/icons-material";
import CreateAvatar from "../CreateAvatar/CreateAvatar";
import { enqueueSnackbar } from "notistack";
import { useUpdateBioMutation, useUpdateProfilePicureMutation } from "../../../../redux/api/beYouCore";
import FriendSearch from "../../../User/sections/FriendSearch/FriendSearch";
import { useNavigate } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../../../context/user/user.ctx";
import { setUserInfo } from "../../../../redux/slices/UserSlice";
import { useDispatch } from "react-redux";

interface CoachProfileCard {
    entity_no?: number;
}
const CoachProfileCard = ({ entity_no }: CoachProfileCard) => {
    const loggedInUser = useUserState();
    const classes = CoachProfileStyles();
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<any>({ user: {}, rank: 0 || undefined, score: 0 || undefined });
    const [isAvatarOpen, setIsAvatarOpen] = useState(false);
    const [isBioOpen, setIsBioOpen] = useState(false);
    const [isBioUpdating, setIsBioUpdating] = useState(false);
    const [isFriend, setIsFriend] = useState(false);
    const [userPlace, setUserPlace] = useState<string>("");
    const [showProfileImageEdit, setShowProfileImageEdit] = useState(false);

    const user = useSelector((state: RootState) => state.user.userInfo);
    const viewTeamMember = useSelector((state: RootState) => state.view.viewUserInfo);
    const [updateProfilePicture] = useUpdateProfilePicureMutation();
    const [updateBio] = useUpdateBioMutation();

    const [textContent, setTextContent] = useState(user.bio);
    const inputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDispatch = useUserDispatch();

    const handleTextContentChange = (event: any) => {
        setTextContent(event.target.value);
    };

    const handleProfilePictureChange = (base64: string) =>
        new Promise<boolean>((resolve, reject) => {
            const updatedUserInfo = { ...user };

            if (base64 != "") {
                updateProfilePicture({ base64 })
                    .unwrap()
                    .then((response) => {
                        updatedUserInfo.profile_img = response?.data?.profile_img;
                        dispatch(setUserInfo(updatedUserInfo));
                        userDispatch({ type: "PROFILE_IMG" });
                        setIsAvatarOpen(false);
                    })
                    .catch(() => {
                        enqueueSnackbar("An error occurred saving your profile picture.", { variant: "error" });
                        setIsAvatarOpen(false);
                    });
            }
        });

    const handleSaveBio = () => {
        setIsBioUpdating(true);
        const updatedUserInfo = { ...user };

        if (textContent !== "" && textContent !== user.bio) {
            updatedUserInfo.bio = textContent;

            updateBio({ bio: textContent })
                .unwrap()
                .then((response) => {
                    dispatch(setUserInfo(updatedUserInfo));
                })
                .catch((error) => {
                    console.error("🚀 ~ file: CoachProfileCard.tsx:71 ~ updateBio ~ error:", error);
                })
                .finally(() => {
                    setIsBioOpen(false);
                    setIsBioUpdating(false);
                });
        } else {
            console.error("something went wrong");
            setIsBioUpdating(false);
        }
    };

    useEffect(() => {
        if (Object.keys(viewTeamMember).length) {
            setCurrentUser({
                user: viewTeamMember.user,
                rank: viewTeamMember.rank,
                score: viewTeamMember.score,
            });
        } else {
            setCurrentUser({
                user: user,
                rank: undefined,
                score: undefined,
            });
        }
        setTextContent(user.bio);
    }, [viewTeamMember, user]);

    const editProfile = loggedInUser.entity_no === user.entity_no;

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2} alignItems="center" className="profile-card">
                    <Grid item xs={12} sm={7}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid
                                item
                                onMouseOver={() => setShowProfileImageEdit(true)}
                                onMouseLeave={() => setShowProfileImageEdit(false)}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    badgeContent={
                                        <>
                                            {editProfile && showProfileImageEdit && (
                                                <Tooltip title="Edit Profile Picture">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="change avatar"
                                                        component="span"
                                                        className={classes.editIcon}
                                                        onClick={() => setIsAvatarOpen(true)}
                                                    >
                                                        <Edit fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    }
                                >
                                    <Avatar
                                        src={getImageUrl("profile", user?.profile_img)}
                                        className={classes.largeAvatar}
                                    />
                                </Badge>
                            </Grid>
                            <Grid item style={{ marginBottom: 10 }}>
                                <Typography variant="h4">
                                    {currentUser.user?.name || currentUser.user.display_name}{" "}
                                    {editProfile && (
                                        <Tooltip title="Friends">
                                            <IconButton
                                                className={classes.editIcon}
                                                onClick={() => navigate("/friends")}
                                            >
                                                <Groups2 />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography
                                    style={{
                                        color: "lightgrey",
                                        fontWeight: 700,
                                    }}
                                    noWrap
                                    variant="subtitle2"
                                >
                                    {currentUser.user?.job_title}
                                </Typography>
                                {/* <Typography variant="subtitle2">
                                    *{currentUser.user?.job_level} Day Change
                                </Typography> */}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" style={{ marginBottom: 10 }}>
                                Bio
                                {editProfile && (
                                    <Tooltip title="Edit Bio">
                                        <Edit
                                            fontSize="inherit"
                                            onClick={() => setIsBioOpen(true)}
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                )}
                            </Typography>

                            <Typography variant="body2" style={{ marginBottom: 10 }}>
                                {textContent}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Grid container spacing={2}>
                            {currentUser.rank ? (
                                <CoachProfileStatsCard
                                    title="Team Rank"
                                    image={"/images/OverallRank.png"}
                                    fullWidth
                                    isRow
                                    rank={currentUser?.rank}
                                    place={currentUser?.rank}
                                    bgCardColor={"red"}
                                />
                            ) : (
                                ""
                            )}
                            {/* <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                                <CoachProfileStatsCard
                                    title="Wins"
                                    image={"/images/positionIncrease.png"}
                                    rank={currentUser?.rank}
                                    wins={currentUser.user?.wins ? currentUser.user?.wins : 0}
                                    place={userPlace}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                                <CoachProfileStatsCard
                                    title="Losses"
                                    image={"/images/positionDecrease.png"}
                                    rank={currentUser?.rank}
                                    losses={currentUser.user?.losses ? currentUser.user?.losses : 0}
                                    place={userPlace}
                                />
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Dialog open={isAvatarOpen} onClose={() => setIsAvatarOpen(false)}>
                <CreateAvatar onAvatarCreated={handleProfilePictureChange} />
            </Dialog>
            <Dialog open={isBioOpen} onClose={() => setIsBioOpen(false)}>
                <Card sx={{ width: 400, padding: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Update your bio below
                        </Typography>

                        <TextField
                            fullWidth
                            multiline
                            rows={8}
                            value={textContent}
                            inputRef={inputRef}
                            onChange={handleTextContentChange}
                            color="secondary"
                            variant="outlined"
                        />
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" fullWidth onClick={handleSaveBio}>
                            {isBioUpdating ? <CircularProgress color="secondary" /> : "Update Bio"}
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
            <Dialog open={isFriend} onClose={() => setIsFriend(false)}>
                <FriendSearch />
            </Dialog>
        </>
    );
};

export default CoachProfileCard;
