import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Contacts from "../../../components/Inbox/Contacts";
import Messages from "../../../components/Inbox/Messages";
import { dummyContacts, dummyMessages } from "./DummyData";
import { useGetThreadsMutation } from "../../../redux/api/beYouCore";
type Messaging = {
    messageCount?: number;
    latestChat?: boolean;
};

export type ContactsType = {
    id?: number;
    name?: string;
    avatar?: string;
    unReadCount?: number;
};
export type MessagesType = {
    id?: number;
    senderId: number;
    recipientId: number;
    message: string;
    timestamp: Date;
};
type MessagesDataType = {
    messages: MessagesType[];
};
type ContactsDataType = {
    contacts: ContactsType[];
};

//data assignment and data type checking
const contactsData: ContactsDataType = {
    contacts: dummyContacts,
};
const messagesData: MessagesDataType = {
    messages: dummyMessages,
};

const Messaging = (props: Messaging) => {
    const [updateContactsList, setUpdateContactsList] = useState<any>(null);
    const lastActiveChatId = localStorage.getItem("lastActiveChatId");
    const [activeChat, setActiveChat] = useState<number>(lastActiveChatId ? parseInt(lastActiveChatId, 10) : 1);
    // const activeUserId = activeChat !== null ? contactsData.contacts[activeChat].id : null;
    // const filteredMessages = activeUserId
    //     ? messagesData.messages.filter(
    //           (message) => message.senderId === activeUserId || message.recipientId === activeUserId,
    //       )
    //     : [];
    const [getThread] = useGetThreadsMutation();

    const [availbleThreads, setAvailbleThreads] = useState([]);

    useEffect(() => {
        getThread({ thread_id: null })
            .unwrap()
            .then((resp) => {
                setAvailbleThreads(resp.data.threads);
            });
    }, [activeChat, updateContactsList]);

    useEffect(() => {
        if (activeChat) {
            localStorage.setItem("lastActiveChatId", activeChat.toString());
        }
    }, [activeChat]);

    useEffect(() => {
        const lastActiveChatId = localStorage.getItem("lastActiveChatId");

        if (lastActiveChatId !== null) {
            const parsedChatId = parseInt(lastActiveChatId, 10);

            if (!isNaN(parsedChatId)) {
                setActiveChat(parsedChatId);
            }
        }
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={4}>
                <Contacts
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    contacts={contactsData.contacts}
                    availbleThreads={availbleThreads}
                    setUpdateContactsList={setUpdateContactsList}
                />
            </Grid>
            <Grid item xs={12} sm={8} lg={8}>
                <Messages activeChat={activeChat} setActiveChat={setActiveChat} />
            </Grid>
        </Grid>
    );
};

export default Messaging;
