import React from "react";

const UpDownNatural = () => {
    return (
        <g id="Eyebrow/Natural/Up-Down-Natural" fillOpacity="0.599999964">
            <path
                d="M22.7663531,1.57844898 L23.6772984,1.17582144 C28.9190996,-0.905265751 36.8645466,-0.0328729562 41.7227321,2.29911638 C42.2897848,2.57148957 41.9021563,3.4519421 41.3211012,3.40711006 C26.4021788,2.25602197 16.3582869,11.5525942 12.9460869,17.8470939 C12.8449215,18.0337142 12.5391523,18.05489 12.4635344,17.8808353 C10.156283,12.5620676 16.9134476,3.89614725 22.7663531,1.57844898 Z"
                id="Eye-Browse-Reddit"
            />
            <path
                d="M86.535177,12.0246305 C92.3421916,12.2928751 101.730304,16.5124899 101.488432,22.3684172 C101.480419,22.5600881 101.1989,22.6442368 101.06135,22.496811 C98.306449,19.5374968 78.7459953,16.5471364 71.8564209,18.1317995 C71.2258949,18.2770375 70.7468448,17.6550104 71.1462176,17.2056651 C74.5683263,13.3574126 81.3327077,11.7792465 86.535177,12.0246305 Z"
                id="Eyebrow"
                transform="translate(86.246508, 17.285912) rotate(5.000000) translate(-86.246508, -17.285912) "
            />
        </g>
    );
};

export default UpDownNatural;
