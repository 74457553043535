import { httpClient } from "../../../../clients/http.client";

const getUserChallengeHistory = async () => {
	const body = {
		pagination: {
			page: 1,
			rows_per_page: 3,
		},
	};
	const response = await httpClient.post("/challenge/history", body);
	return response.data;
};

const getUserBadges = async () => {
	const body = {
		pagination: {
			page: 1,
			rows_per_page: 10,
		},
	};
	const response = await httpClient.post("/profile/badges/me", body);
	return response.data;
};

const getUserAttributes = async () => {
	const response = await httpClient.post("/metrics/summary/me");
	return response.data.data.metric_values.map((metric: any) => ({
		id: metric.id,
		label: metric.name,
		value: metric.value,
		progress: (metric.value / metric.details.target) * 100,
		target: metric.details.target,
	}));
};

export { getUserChallengeHistory, getUserBadges, getUserAttributes };
