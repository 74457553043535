import { Card, CardContent } from "@mui/material";
import SubmittedMetricReviewTable from "./SubmittedTables/SubmittedMetricReviewTable";
import SubmittedPipPerformanceTable from "./SubmittedTables/SubmittedPipPerformanceTable";
import SubmittedPipOptionsTable from "./SubmittedTables/SubmittedPipOptionsTable";
import SubmittedToImproveTable from "./SubmittedTables/SubmittedToImproveTable";
import SubmittedMetricRatingTable from "./SubmittedTables/SubmittedMetricRatingTable";
import SubmittedMetricsTable from "./SubmittedTables/SubmittedMetricsTable";

const CoachingSubmittedFormTables = ({ tableType, selectedForm }: any) => {
    switch (tableType) {
        case "metricTable":
            return <SubmittedMetricsTable data={selectedForm} />;
        case "metricRatingTable":
            return <SubmittedMetricRatingTable data={selectedForm} />;
        case "toImproveTable":
            return <SubmittedToImproveTable data={selectedForm} />;
        case "pipOptionsTable":
            return <SubmittedPipOptionsTable data={selectedForm} />;
        case "pipPerformanceTable":
            return <SubmittedPipPerformanceTable data={selectedForm} />;
        case "metricReviewTable":
            return <SubmittedMetricReviewTable data={selectedForm} />;
        default:
            return (
                <Card>
                    <CardContent>We struggled to find data for this metric.</CardContent>
                </Card>
            );
    }
};

export default CoachingSubmittedFormTables;
