import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { saveServiceName } from "../../twtService.api";
import { useSnackbar } from "notistack";
import muiStyles from "../EditServiceEnvironment/EditServiveEnvironment.styles";
import styles from "./styles.module.css";

interface NewServiceEnvironmentProps {
    handleClose: () => void;
}

const NewServiceEnvironment = ({ handleClose }: NewServiceEnvironmentProps) => {
    const [name, setName] = React.useState<any>();
    const [loadingName, setLoadingName] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const muiStyle = muiStyles();

    const onUpdateServiceName = () => {
        setLoadingName(true);

        saveServiceName({ id: name.id, name: name.Name }).then(() => {
            enqueueSnackbar("Service Enironment updated successfully", {
                className: muiStyle.snack,
            });
            setLoadingName(false);
        });
    };

    return (
        <SectionCard
            title={`Create Service`}
            titleProps={{
                variant: "h6",
            }}
            className={styles.newGroup}
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <Box>
                <Box display="grid" gridTemplateColumns="2fr 4fr">
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography>Name</Typography>
                    </Box>
                    <TextField
                        value={name ? name.Name : ""}
                        onChange={(e) =>
                            setName({
                                ...name,
                                Name: e.target.value,
                            })
                        }
                        style={{ padding: "2em" }}
                        error={name && name.Name.length === 0}
                        helperText={
                            name && !name.Name.length ? "name is required" : ""
                        }
                        placeholder="New Service Name"
                    ></TextField>
                </Box>
                <Box width="100%" textAlign="center" margin="1em 0.5em">
                    <SubmitButton
                        variant="outlined"
                        onClick={onUpdateServiceName}
                        loading={loadingName}
                        disabled={loadingName}
                    >
                        Save Service Details
                    </SubmitButton>
                </Box>
            </Box>
        </SectionCard>
    );
};

export default NewServiceEnvironment;
