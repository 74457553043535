const darkPalette = {
    common: {
        black: "#454545",
        white: "#a4a6b4",
    },
    primary: {
        main: "#535353",
        contrastText: "#D2D6E8",
    },
    secondary: {
        main: "#454545",
        contrastText: "#BBBECE",
    },
    info: {
        main: "#c6db34",
        contrastText: "#4d4f40",
    },
    text: {
        primary: "#b4b4c1",
        secondary: "#a4a6b4",
    },
    background: {
        paper: "#535353",
        default: "#454545",
    },
};

const lightPalette = {
    common: {
        black: "#454545",
        white: "#a4a6b4",
    },
    primary: {
        main: "#ffffff",
        contrastText: "#4d4f40",
    },
    secondary: {
        main: "#f0f0f0",
        contrastText: "#5c5c5c",
    },
    info: {
        main: "#c6db34",
        contrastText: "#4d4f40",
    },
    text: {
        primary: "#4d4f40",
        secondary: "#a4a6b4",
    },
    background: {
        paper: "#ffffff",
        default: "#f0f0f0",
    },
};

export { lightPalette, darkPalette };
