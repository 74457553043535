export const columns = [
    {
        title: "Service Group",
        field: "name",
        cellStyle: {
            width: "188px",
        },
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    { title: "Measure", field: "measure" },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "% in TWT",
        field: "percentage_in_twt",
    },
    {
        title: "Total",
        field: "twt_total",
    },
    {
        title: "In TWT",
        field: "in_twt_total",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Effort",
        field: "in_out_report_processes_in",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Total",
        field: "pending_process_total",
    },
    {
        title: "In TWT",
        field: "pending_process_in_twt_total",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Total",
        field: "closed_process_total",
    },

    {
        title: "In TWT",
        field: "closed_process_in_twt_total",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "% in TWT",
        field: "mtd_percentage_in_twt",
    },
    {
        title: "Total",
        field: "mtd_twt_total",
    },
    {
        title: "In TWT",
        field: "mtd_in_twt_total",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Effort",
        field: "mtd_in_out_report_processes_in",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Total",
        field: "mtd_pending_process_total",
    },
    {
        title: "In TWT",
        field: "mtd_pending_process_in_twt_total",
    },
    {
        headerStyle: {
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
        cellStyle: {
            minWidth: "1px",
            maxWidth: "1px",
            padding: "0 1px",
            backgroundColor: "#ccc",
        },
    },
    {
        title: "Total",
        field: "mtd_closed_process_total",
    },
    {
        title: "In TWT",
        field: "mtd_closed_process_in_twt_total",
    },
    {},
];
