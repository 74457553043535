import { Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";
import React from "react";
import BadgeList from "../../../../components/BadgeList/BadgeList";
import ProgressList from "../../../../components/ProgressList/ProgressList";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SummaryBadges, { SummaryBadge } from "../../../../components/SummaryBadges/SummaryBadges";
import GameMetricProgress from "../../../Metric/sections/GameMetricProgress/GameMetricProgress";
import ProfileBadges from "../../../Profile/sections/ProfileBadges/ProfileBadges";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import currentChallengeStyles from "./CurrentChallenge.styles";
import { t_CurrentChallenge } from "./CurrentChallenge.types";
import { useGetCurrentChallengeMutation } from "../../../../redux/api/beYouCore";

export type DetailedChallengeUser = {
    name: string;
    profile_img: string;
    tier: {
        _id: string;
        label: string;
        colour: string;
    };
    office: {
        _id: string;
        label: string;
    };
    progress: number;
    summary: [
        {
            _id: string;
            label: string;
            value: number;
        },
        {
            _id: string;
            label: string;
            value: number;
        },
        {
            _id: string;
            label: string;
            value: number;
        },
    ];
    metrics: {
        _id: string;
        acronym: string;
        label: string;
        value: number;
    }[];
    badges: {
        _id: string;
        name: string;
        description: string;
        badge_img: string;
    }[];
};

export type DetailedChallenge = {
    user: DetailedChallengeUser;
    challenger: DetailedChallengeUser;
    timestamp: number;
    metric_baseline: number;
    metric_total: number;
};

const challengerContentStyle = makeStyles((theme: Theme) =>
    createStyles({
        badgeList: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const ChallengerContent = (props: {
    challenger: DetailedChallengeUser;
    side: "lhs" | "rhs";
    metric_baseline: number;
    metric_total: number;
}) => {
    const { challenger, side, metric_baseline, metric_total } = props;
    const classes = challengerContentStyle();

    return (
        <Grid item xs container spacing={2} direction="column">
            <Grid item sm>
                <SummaryBadges
                    justify="center"
                    badges={
                        challenger.summary.map(
                            (item): SummaryBadge => ({
                                ...item,
                                value: item.value.toString(),
                            }),
                        ) as [SummaryBadge, SummaryBadge, SummaryBadge]
                    }
                />
            </Grid>
            <Grid item sm>
                <ProgressList
                    rhs={side === "rhs"}
                    items={challenger.metrics.map((attr) => ({
                        ...attr,
                        colour:
                            attr.value > metric_baseline
                                ? "green"
                                : attr.value / metric_baseline > 0.5
                                ? "yellow"
                                : "red",
                    }))}
                    total={metric_total}
                    baseline={metric_baseline}
                />
            </Grid>
            <Grid item sm container spacing={2} direction="column">
                <Typography variant="subtitle1">Badges</Typography>
                <BadgeList
                    {...(side === "rhs" && { className: classes.badgeList })}
                    badges={challenger.badges.map((badge) => ({
                        badge_img: badge.badge_img,
                        title: badge.name,
                        subtitle: badge.description,
                    }))}
                    variant="mini"
                />
            </Grid>
        </Grid>
    );
};

interface Props {
    /** @MM Later to add
     *  - view_challenge: t_Challenge (allow a challenge to be viewed)
     *  OR
     *  - entity_no: number (show active challenge for a user?)
     */
}
const CurrentChallenge: React.FC<Props> = () => {
    const classes = currentChallengeStyles();
    const [challenge, setChallenge] = React.useState<t_CurrentChallenge>();

    const [visualFeedback, setVisualFeedback] = React.useState<{
        loading: boolean;
        status?: "no_active" | undefined /** Other potential component status's */;
    }>({ loading: false });
    const [getCurrentChallenge] = useGetCurrentChallengeMutation();

    React.useEffect(() => {
        var isActive = true;

        getCurrentChallenge()
            .unwrap()
            .then((data) => {})
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <SectionCard
            title="Current Challenge Breakdown"
            titleProps={{
                variant: "h6",
            }}
            fullWidth
        >
            {visualFeedback.loading ? (
                // <Grid
                // 	container
                // 	spacing={2}
                // 	style={{ height: "100%" }}
                // 	justify='center'
                // 	alignItems='center'
                // >
                // 	<CircularProgress />
                // </Grid>
                <Skeleton width="50%" />
            ) : challenge ? (
                <ChallengeCard challenge={challenge}>
                    <Grid container spacing={0}>
                        <Grid item sm={12} container spacing={0}>
                            <Grid item sm={6}>
                                <GameMetricProgress metric_values={challenge.user_one.metric_values} />
                            </Grid>
                            <Grid item sm={6}>
                                <GameMetricProgress metric_values={challenge.user_two.metric_values} />
                            </Grid>
                        </Grid>
                        <Grid item sm={12} container spacing={0}>
                            <Grid item sm={6}>
                                <ProfileBadges
                                    entity_no={challenge.user_one?.entity_no}
                                    sectionCardProps={{
                                        noHeader: true,
                                        hasBackground: false,
                                    }}
                                    badgeVariant="mini"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <ProfileBadges
                                    entity_no={challenge.user_two?.entity_no}
                                    sectionCardProps={{
                                        noHeader: true,
                                        hasBackground: false,
                                    }}
                                    badgeVariant="mini"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ChallengeCard>
            ) : (
                <>
                    {/* {visualFeedback.status === "no_active" && (
                        <Grid container spacing={2} justifyContent="center">
                            <Typography variant="subtitle1">
                                You have no challenge this week and have been awarded a bye.
                            </Typography>
                        </Grid>
                    )} */}
                </>
            )}
        </SectionCard>
    );
};
export default CurrentChallenge;
