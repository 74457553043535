import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

interface AnimatedTextProps {
    text: string;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text }) => {
    const [isMerged, setIsMerged] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsMerged(true);
        }, 4000); // Adjust the delay as needed

        return () => clearTimeout(timer);
    }, []);

    const container = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
        }),
        merged: { opacity: 1 },
    };

    const child = {
        visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: (direction: "top" | "bottom") => ({
            opacity: 0,
            x: direction === "top" ? -20 : 20,
            y: direction === "top" ? 10 : -10,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        }),
        merged: {
            opacity: 1,
        },
    };

    const sliderVariants = {
        initial: {
            x: 0,
        },
        animate: {
            x: "-220%",
            transition: {
                repeat: Infinity,
                duration: 25,
                repeatType: "mirror" as const,
                staggerChildren: 0.3,
            },
        },
    };

    const sliderReverse = {
        initial: {
            x: 0,
        },
        animate: {
            x: "220%",
            transition: {
                repeat: Infinity,
                duration: 30,
                repeatType: "mirror" as const,
                staggerChildren: 0.7,
            },
        },
    };
    return (
        <>
            <motion.div
                style={{
                    overflow: "hidden",
                    fontSize: "3rem",
                    color: "#FFFFFF",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                }}
                variants={container}
                initial="hidden"
                animate={isMerged ? "merged" : "visible"}
            >
                {!isMerged ? (
                    <div>
                        <motion.div className="fonted">
                            {text.split("").map((letter: string, index: number) => (
                                <motion.span variants={child} custom="top" key={index}>
                                    {letter === " " ? "\u00A0" : letter}
                                </motion.span>
                            ))}
                        </motion.div>
                        <motion.div className="fonted">
                            {text.split("").map((letter: string, index: number) => (
                                <motion.span variants={child} custom="top" key={index}>
                                    {letter === " " ? "\u00A0" : letter}
                                </motion.span>
                            ))}
                        </motion.div>
                        <motion.div>
                            {text.split("").map((letter: string, index: number) => (
                                <motion.span variants={child} custom="bottom" key={index}>
                                    {letter === " " ? "\u00A0" : letter}
                                </motion.span>
                            ))}
                        </motion.div>
                        <motion.div>
                            {text.split("").map((letter: string, index: number) => (
                                <motion.span variants={child} custom="bottom" key={index}>
                                    {letter === " " ? "\u00A0" : letter}
                                </motion.span>
                            ))}
                        </motion.div>
                    </div>
                ) : (
                    <motion.div>
                        {text.split("").map((letter: string, index: number) => (
                            <motion.span variants={child} key={index} style={{ fontSize: "55px" }}>
                                {letter === " " ? "\u00A0" : letter}
                            </motion.span>
                        ))}
                    </motion.div>
                )}
            </motion.div>
            {isMerged && (
                <>
                    <motion.div
                        variants={sliderReverse}
                        initial="initial"
                        animate="animate"
                        className="fonted"
                        style={{
                            fontSize: "180px",
                            opacity: 0.1,
                            position: "absolute",
                            top: -170,
                            width: "100%",
                            height: "100%",
                            color: "#FFFFFF",
                        }}
                    >
                        {text.repeat(8)}
                    </motion.div>
                    <motion.div
                        variants={sliderVariants}
                        initial="initial"
                        animate="animate"
                        className="fonted"
                        style={{
                            fontSize: "180px",
                            opacity: 0.1,
                            position: "absolute",
                            top: -5,
                            width: "100%",
                            height: "100%",
                            color: "#FFFFFF",
                        }}
                    >
                        {text.repeat(8)}
                    </motion.div>
                </>
            )}
        </>
    );
};

export default AnimatedText;
