import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const maintainUserStyles = makeStyles((theme: Theme) =>
	createStyles({
		userSearchContainer: {
			width: "100%",
			display: "flex",
			alignItems: "baseline",
			justifyContent: "space-between",
		},
		grid: {
			height: "100%",
		},
	})
);

export default maintainUserStyles;
