import {
	Button,
	ButtonGroup,
	ButtonProps,
	CircularProgress,
} from "@mui/material";
import { Cancel, Check, PersonAdd, Send } from "@mui/icons-material";
import React from "react";
import { useUserState } from "../../../../context/user/user.ctx";
import { t_User } from "../../../../types/user.types";
import {
	getFriendStatus,
	removeFriend,
	respondToRequest,
	sendFriendRequest,
	t_FriendStatus,
} from "./FriendButton.api";

const c_ButtonProps: ButtonProps = {
	variant: "contained",
	color: "primary",
};
interface Props {
	entity_no: t_User.Profile["entity_no"];
}

const FriendButton: React.FC<Props> = ({ entity_no }) => {
	const [hover, setHover] = React.useState(false);
	const [friends, setFriends] = React.useState<t_FriendStatus>();
	const userProfile = useUserState();

	const handleAddFriend = () => {
		sendFriendRequest({ entity_no }).then(() =>
			getFriendStatus({ entity_no }).then((data) => setFriends(data))
		);
	};
	const handleUnfriend = () => {
		if (friends?.record.FriendID) {
			removeFriend({
				friend_request_id: friends?.record.FriendID,
			}).then(() =>
				getFriendStatus({ entity_no }).then((data) => setFriends(data))
			);
		}
	};
	const handleRespond = (accept: boolean) => {
		if (friends?.record.FriendID) {
			respondToRequest({
				friend_request_id: friends?.record.FriendID,
				accepted: accept,
			}).then(() =>
				getFriendStatus({ entity_no }).then((data) => setFriends(data))
			);
		}
	};

	React.useEffect(() => {
		var isActive = true;
		setFriends(undefined);
		if (entity_no) {
			getFriendStatus({ entity_no }).then(
				(data) => isActive && setFriends(data)
			);
		}
		return () => {
			isActive = false;
		};
	}, [entity_no]);

	if (userProfile.entity_no === entity_no) {
		return null;
	}
	return (
		<div
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{friends != null ? (
				friends.status == null || friends.status.StatusID > 3 ? (
					/** @MM No friend record (or record is of type "Team" /deprecated/ ): */
					<Button
						{...c_ButtonProps}
						endIcon={<PersonAdd />}
						onClick={handleAddFriend}
					>
						Add Friend
					</Button>
				) : (
					/** @MM Friend record exists: */
					<>
						{
							/** @MM Are friends; */
							friends.status.StatusID === 2 &&
								(hover ? (
									<Button
										{...c_ButtonProps}
										endIcon={<Cancel />}
										onClick={handleUnfriend}
									>
										Unfriend
									</Button>
								) : (
									<Button
										{...c_ButtonProps}
										endIcon={<Check />}
									>
										Friends
									</Button>
								))
						}
						{
							/** @MM Request pending;  */
							friends.status.StatusID === 1 && (
								<>
									{friends.type === "incoming" &&
										(hover ? (
											<ButtonGroup
												variant='contained'
												color='primary'
											>
												<Button
													endIcon={<Check />}
													onClick={() =>
														handleRespond(true)
													}
												>
													Accept
												</Button>
												<Button
													endIcon={<Cancel />}
													onClick={() =>
														handleRespond(false)
													}
												>
													Decline
												</Button>
											</ButtonGroup>
										) : (
											<Button {...c_ButtonProps}>
												Wants to Be Friends
											</Button>
										))}
									{friends.type === "outgoing" &&
										(hover && friends.can_request ? (
											<Button
												{...c_ButtonProps}
												endIcon={<Send />}
												onClick={handleAddFriend}
											>
												Resend Request
											</Button>
										) : (
											<Button
												{...c_ButtonProps}
												endIcon={<Send />}
											>
												Request Sent
											</Button>
										))}
								</>
							)
						}
						{
							/** @MM Request declined; */
							friends.status.StatusID === 3 && (
								<>
									{friends.type === "incoming" && (
										<Button
											{...c_ButtonProps}
											onClick={handleAddFriend}
										>
											Add Friend
										</Button>
									)}
									{friends.type === "outgoing" &&
										(hover && friends.can_request ? (
											<Button
												{...c_ButtonProps}
												onClick={handleAddFriend}
											>
												Resend Friend Request
											</Button>
										) : (
											<Button {...c_ButtonProps}>
												Friend Request Declined
											</Button>
										))}
								</>
							)
						}
					</>
				)
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

export default FriendButton;
