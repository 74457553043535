import { Backdrop, Box, CircularProgress, SelectChangeEvent, Tab, Tabs, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";

import ExportButton from "../../../components/ExportButton/ExportButton";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { useUserState } from "../../../context/user/user.ctx";
import { useReportExport } from "../../../hooks/export/useReportExport";
import { getApiUrl } from "../../../misc/helpers";
import { useGetSubmittedFormsQuery } from "../../../redux/api/beYouCore";
import AllSubmittedCoachingForms from "../../../sections/CoachingForm/AllSubmittedCoachingForms";
import FormsTable from "../../../sections/CoachingForm/SubmittedForm/FormsTable";
import FormsView from "../../../sections/CoachingForm/SubmittedForm/FormsView";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";

export interface FormData {
    "Membership Number": string;
    "Call reference or PID": string;
    "Details of defect(s)": string;
}

const SubmittedForms = () => {
    const currentUser = useUserState();
    const { loading: exportLoading, downloadExportFile } = useReportExport();
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [formsFilter, setFormsFilter] = useState("all");
    const [formId, setFormId] = useState<number | null>(null);
    const [formFields, setFormFields] = useState<any>([]);
    const [selectedForm, setSelectedForm] = useState<any>();
    const coachingForms = AllSubmittedCoachingForms(selectedForm);
    const [metricID, setMetricID] = useState<number | null>(null);

    const {
        data: { data: submittedCoachForms } = {},
        refetch: refetchSubmitted,
        isLoading: submittedFormsLoading,
        isError: submittedFormsError,
    } = useGetSubmittedFormsQuery();

    const handleExport = (file_type: string) => {
        const coachform_url = formId
            ? new URL(`${getApiUrl()}/coach/submissions/export?id=${formId}`)
            : new URL(`${getApiUrl()}/coach/submissions/export`);

        // appeal_url.searchParams.append("month", selectedDate.toDateString());
        coachform_url.searchParams.append("file_type", file_type);

        downloadExportFile(coachform_url.toString(), "Coach Form Export");
    };

    const filteredSubmittedFormsList =
        formsFilter == "all"
            ? submittedCoachForms?.submission_data
            : submittedCoachForms?.submission_data?.filter((form: any) => form.type.toLowerCase() == formsFilter);

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    const formSelectChange = (event: SelectChangeEvent) => {
        setFormsFilter(event.target.value as string);
    };

    useEffect(() => {
        refetchSubmitted();
    }, [submittedCoachForms]);

    useEffect(() => {
        if (coachingForms) {
            const {
                absenteeism,
                adhoc,
                dpmo,
                expectation,
                grow_coaching,
                handover,
                ops_losses,
                perception,
                pip_follow_up,
                pip_initiation,
                written_warning,
            } = coachingForms;

            switch (selectedForm?.type?.toLowerCase()) {
                case "dpmo":
                    setMetricID(2);
                    setFormFields(dpmo);
                    break;
                case "adhoc":
                    setFormFields(adhoc);
                    break;
                case "absence":
                    setMetricID(7);
                    setFormFields(absenteeism);
                    break;
                case "perception":
                    setMetricID(3);
                    setFormFields(perception);
                    break;
                case "grow":
                    setMetricID(14);
                    setFormFields(grow_coaching);
                    break;
                case "pip_initiation":
                    setMetricID(14);
                    setFormFields(pip_initiation);
                    break;
                case "pip_follow_up":
                    setMetricID(14);
                    setFormFields(pip_follow_up);
                    break;
                case "written_warning":
                    setFormFields(written_warning);
                    break;
                case "expectations":
                    setFormFields(expectation);
                    break;
                case "handover":
                    setFormFields(handover);
                    break;
                case "ops_loss":
                    setFormFields(ops_losses);
                    break;

                default:
                    setFormFields([]);
            }
        } else {
            setFormFields([]);
        }
    }, [selectedForm]);

    if (submittedFormsLoading || submittedFormsError) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100vh",
                    margin: "auto",
                    padding: 5,
                }}
            >
                <Typography color="textSecondary"> No data to display currently.</Typography>
            </Box>
        );
    }

    return (
        <SectionCard
            title={"Submitted Forms"}
            scrolling
            fullWidth
            primaryAction={
                <Tabs
                    value={tabValue}
                    onChange={(_, value) => setTabValue(value)}
                    aria-label="notifications section"
                    style={{ padding: 16 }}
                >
                    <Tab label="Team View" />
                    <Tab label="Forms View" />
                </Tabs>
            }
            secondaryAction={
                <>
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={new Date(month)}
                        onChange={handleDateChange}
                        closeOnSelect={false}
                        className="date-picker-beYouCoach"
                        onOpen={() => setIsDateOpen(true)}
                        onClose={() => {
                            performanceDispatch({
                                type: "update_dates",
                                month: selectedDate,
                            });
                            setIsDateOpen(false);
                        }}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                    <Backdrop sx={{ zIndex: 1 }} open={isDateOpen}></Backdrop>
                </>
            }
        >
            {tabValue == 0 && (
                <>
                    {submittedFormsLoading ? (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 2 }}
                            >
                                <ExportButton dispatch={handleExport} loading={exportLoading} disabled={exportLoading}>
                                    Export
                                </ExportButton>
                            </Box>
                            <FormsTable
                                data={submittedCoachForms?.submission_data}
                                formFields={formFields}
                                metricID={metricID}
                                setMetricID={setMetricID}
                                selectedForm={selectedForm}
                                setSelectedForm={setSelectedForm}
                            />
                        </>
                    )}
                </>
            )}
            {tabValue == 1 && (
                <>
                    {submittedFormsLoading ? (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {/* <Box
                                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 2 }}
                            >
                                <ExportButton dispatch={handleExport} loading={exportLoading} disabled={exportLoading}>
                                    Export
                                </ExportButton>
                            </Box> */}
                            <FormsView
                                filteredSubmittedFormsList={filteredSubmittedFormsList}
                                formSelectChange={formSelectChange}
                                formsFilter={formsFilter}
                                selectedForm={selectedForm}
                                setSelectedForm={setSelectedForm}
                                setFormId={setFormId}
                                formFields={formFields}
                                metricID={metricID}
                                setMetricID={setMetricID}
                            />
                        </>
                    )}
                </>
            )}
        </SectionCard>
    );
};

export default SubmittedForms;
