import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { themeColors } from "../../context/theme/theme.ctx";
import { RootState } from "../../redux/store";

interface StrenghtsWeaknesses {
    strengths?: boolean;
    month?: string;
    entity_no?: number;
    comparison_month?: string;
}

const StrenghtsWeaknessesCard = ({ strengths }: StrenghtsWeaknesses) => {
    const theme = useTheme();
    const colors = themeColors(theme.palette.mode);
    const [metrics, setMetrics] = useState<any>([]);

    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const userMetrics = useSelector((state: RootState) => state.view.viewUserInfo.metrics);

    useEffect(() => {
        if (userMetrics) {
            const entries = Object.values(userMetrics);
            setMetrics(entries);
        } else {
            setMetrics(allMetrics);
        }
    }, [userMetrics, allMetrics]);

    let metricSW;

    if (strengths) {
        metricSW = [...metrics]
            .sort((a, b) => b.percentage_of_target - a.percentage_of_target)
            .filter((item) => item.percentage_of_target > 80);
    } else {
        metricSW = [...metrics]
            .sort((a, b) => a.percentage_of_target - b.percentage_of_target)
            .filter((item) => item.percentage_of_target < 80);
    }

    return (
        <>
            {metricSW?.slice(0, 2).length > 0 ? (
                metricSW?.slice(0, 2).map((metric, index) => (
                    <Box
                        key={index}
                        padding={1}
                        borderRadius="10px"
                        bgcolor={colors.swBg}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginY={1}
                    >
                        <Box>
                            <Typography variant="subtitle1" style={{ fontSize: 12, lineHeight: 1.1 }}>
                                {metric ? metric.name : ""}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    fontSize: 12,
                                    lineHeight: 1,
                                    fontWeight: 700,
                                }}
                            >
                                {metric.value ? metric.value : 0}
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"column-reverse"} alignItems={"center"}>
                            <Typography
                                align="center"
                                variant="caption"
                                style={{
                                    fontSize: 10,
                                    fontWeight: "normal",
                                    color: `rgb(160, 160, 160)`,
                                }}
                            >
                                % of Target
                            </Typography>

                            <Chip
                                label={`${metric.percentage_of_target || 0}%*`}
                                size="small"
                                avatar={
                                    <img
                                        src={
                                            metric.percentage_of_target < 100
                                                ? "/images/downArrow.png"
                                                : "/images/upArrow.png"
                                        }
                                        style={{
                                            width: ".8em",
                                            height: ".8em",
                                            position: "absolute",
                                            top: "30%",
                                            right: "15%",
                                        }}
                                    />
                                }
                                style={{
                                    background: metric.percentage_of_target < 100 ? "#FF4040" : "#2ECA40",
                                    color: "white",
                                    fontSize: ".7rem",
                                    padding: "0 0.6em",
                                    height: "1.1rem",
                                    position: "relative",
                                }}
                            />
                        </Box>
                    </Box>
                ))
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> No data to display currently.</Typography>
                </Box>
            )}
        </>
    );
};
export default StrenghtsWeaknessesCard;
