import { TableCell, TableRow } from "@mui/material";
import { MTableBody } from "material-table";
import React from "react";
import BUMaterialTable, { BUTableFooter } from "../../../../components/BUMaterialTable/BUMaterialTable";
import { t_User } from "../../../../types/user.types";
import { SurveyReports } from "../../../PerceptionReport/PerceptionReport";
import AppealButton from "../../components/AppealButton/AppealButton";
import LsaButton from "../../components/LsaButton/LsaButton";
import {
  getSurveyRecordsAny,
  getSurveyTotals,
  SurveyColumns,
  t_SurveyTotals,
} from "../../Surveys.api";
import { t_SurveyPeriod } from "../../Surveys.types";
import { actions_AgentSurveyRecord } from "./AgentSurveyRecords.api";
import agentSurveyRecordsStyles from "./AgentSurveyRecords.style";
interface Props {
  survey: SurveyReports;
  periods: {
    [key: string]: t_SurveyPeriod;
  };
  entity_no: t_User.Profile["entity_no"];
  dispatch: React.Dispatch<actions_AgentSurveyRecord>;
  survey_type_only?: "mbr" | "fcr";
}
const AgentSurveyRecords: React.FC<Props> = ({ survey, periods, entity_no, dispatch, survey_type_only }) => {
  const classes = agentSurveyRecordsStyles();
  const [totals, setTotals] = React.useState<t_SurveyTotals>();

  React.useEffect(() => {
    setTotals(undefined);
    getSurveyTotals({
      report: survey,
      entity_no,
      periods,
    }).then((result) => setTotals(result));
  }, [survey, entity_no, periods]);
  return (
    <BUMaterialTable
      components={{
        Body: (props) => {
          return (
            <React.Fragment>
              <MTableBody {...props} />
              <BUTableFooter>
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                  <TableCell align="right">Average Score:</TableCell>
                  <TableCell>
                    {totals?.AvgScore != null ? Math.round(Number(totals.AvgScore) * 100) + "%" : "-"}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </BUTableFooter>
            </React.Fragment>
          );
        },
      }}
      columns={SurveyColumns}
      data={(query) =>
        getSurveyRecordsAny({
          query,
          report: survey,
          entity_no,
          periods,
          survey_type_only,
        })
      }
      localization={{
        header: {
          actions: "Details",
        },
      }}
      options={{
        toolbar: false,
        padding: "dense",
        pageSize: 10,
        actionsColumnIndex: -1,
      }}
      actions={[
        (rowData) => ({
          icon: () => <LsaButton status_id={rowData["lsa.StatusID"]} status_label={rowData["lsa.StatusText"]} />,
          onClick: () => dispatch({ action: "lsa", rowData }),
          tooltip: rowData["lsa.StatusText"] ?? "No low score analysis.",
          disabled: rowData["lsa.StatusID"] === null,
        }),
        (rowData) => ({
          icon: () => (
            <AppealButton status_id={rowData["appeal.StatusID"]} status_label={rowData["appeal.StatusText"]} />
          ),
          onClick: () => dispatch({ action: "appeal", rowData }),
          tooltip: rowData["appeal.StatusText"] ?? "No appeal.",
          disabled: rowData["appeal.StatusID"] === null,
        }),
        (rowData) => ({
          icon: "comment",
          onClick: () => dispatch({ action: "comment", rowData }),
          tooltip: "View Comment",
          disabled: !rowData.Comments,
        }),
      ]}
    />
  );
};

export default AgentSurveyRecords;
