import { ArrowBack, Cancel, Menu as MenuIcon } from "@mui/icons-material";
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Toolbar,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import imWebsocket from "../../api/websockets.api";
import bot from "../../beYou_bot.png";
import NotificationsSummary from "../../components/ChatBot/NotificationsSummary";
import ContentSideBar from "../../components/ContentSideBar/ContentSideBar";
import PillList from "../../components/PillList/PillList";
import { NotificationsContext } from "../../components/Toasts/NotificationsProvider.ctx";
import { useUserMetrics } from "../../context/user/metrics.ctx";
import { useUserDispatch, useUserState } from "../../context/user/user.ctx";
import useDataFetching from "../../hooks/useDataFetching/useDataFetching";
import { getImageUrl } from "../../misc/helpers";
import Links from "../../navigation/links";
import { useUserAvatarQuery } from "../../redux/api/beYouCore";
import { setBeYouAvatar } from "../../redux/slices/BeYouAvatarSlice";
import {
    setNotificationsWalkThrough,
    setPerformanceReflectionWalkThrough,
    setTipsWalkThrough,
} from "../../redux/slices/WalkThroughSlice";
import { RootState } from "../../redux/store";
import ThemeToggle from "../../theme/Theme";
import Feedback from "../Feedback/Feedback";
import OvationAvatarSvg from "../Ovation/OvationAvatarSvg";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import ProfileSummary from "../Profile/sections/ProfileSummary/ProfileSummary";
import navigationStyles from "./style";

interface Props {
    drawerRef?: React.Ref<HTMLDivElement>;
    appBarRef?: React.Ref<HTMLDivElement>;
}

const Navigation: React.FC<Props> = ({ drawerRef, appBarRef }) => {
    const classes = navigationStyles();
    const userObject = useUserState();
    const [userMetrics] = useUserMetrics();
    const userDispatch = useUserDispatch();

    const [open, setOpen] = useState(false);
    const [sidePanelOpen, setOpenSidePanel] = useState(false);
    const { showSidePanelChatBot, setSidePanelChatBot, setShowFeedBackModal, showFeedBackModal, currentStep } =
        useContext(NotificationsContext);

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleMenuToggle = () => {
        setOpen((state) => !state);
    };
    const toggleSidePanelOpen = () => {
        setOpenSidePanel(!sidePanelOpen);
        setSidePanelChatBot(false);
    };
    const handleSidePanelClose = () => {
        setOpenSidePanel(false);
    };
    const handleSidePanelChatBot = () => {
        dispatch(setNotificationsWalkThrough(false));
        dispatch(setPerformanceReflectionWalkThrough(false));
        dispatch(setTipsWalkThrough(false));
        setSidePanelChatBot(!showSidePanelChatBot);
        setOpenSidePanel(false);
    };

    const dispatch = useDispatch();

    const currentUser = useUserState();
    const entity_no = currentUser.entity_no;
    const pagination = useSelector((state: RootState) => state.summary.pagination);
    const sort = useSelector((state: RootState) => state.summary.sort);
    const [{ month, comparison_month }] = usePerformanceContext();

    const { fetchData, fetchDataWalkThrough } = useDataFetching(entity_no, month, comparison_month, pagination, sort);
    const beYouWalkThrough = useSelector((state: RootState) => state.walkThrough.beYouWalkThrough);
    const { notificationsCount } = useContext(NotificationsContext);
    const { data: userAvatar } = useUserAvatarQuery();
    const userAvatarData = useSelector((state: RootState) => state.beYouAvatar.beYouAvatar);
    const isAvatarProfilePicture = Boolean(Number(userAvatarData?.is_profile));

    useEffect(() => {
        if (userObject.entity_no) {
            imWebsocket(userObject.entity_no, dispatch);
        }
    }, [userObject]);

    useEffect(() => {
        if (beYouWalkThrough) {
            fetchDataWalkThrough();
        } else {
            fetchData();
            if (userAvatar?.status === "success") {
                dispatch(
                    setBeYouAvatar({
                        avatar: userAvatar?.data.user_avatar.avatar,
                        image: userAvatar?.data.user_avatar.image,
                        is_profile: userAvatar?.data.user_avatar.is_profile,
                        unlocks: userAvatar?.data.unlocks,
                        items: userAvatar?.data.items,
                    }),
                );
            }
        }
    }, [fetchData, beYouWalkThrough, entity_no, month]);

    return (
        <React.Fragment>
            <AppBar position="sticky" color="primary" className={classes.appBar} ref={appBarRef}>
                <Toolbar className={classes.appBarControls}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleMenuToggle}
                        className={classes.menuOpen}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <Toolbar className={classes.appBarContent}>
                    {userMetrics.status === "loading" ? (
                        <CircularProgress style={{ color: "#c6db34" }} />
                    ) : userMetrics.status === "error" ? (
                        "We ran into an issue loading this data."
                    ) : (
                        <PillList metrics={userMetrics.metrics} />
                    )}
                </Toolbar>
                <Toolbar className={classes.appBarActions}>
                    {userObject.masquerade && (
                        <Button onClick={() => userDispatch({ type: "END_MASQUERADE" })} startIcon={<Cancel />}>
                            Stop Masquerade
                        </Button>
                    )}
                    {!isAvatarProfilePicture && (
                        <IconButton aria-label="Profile" color="inherit" onClick={toggleSidePanelOpen} size="large">
                            <Avatar src={getImageUrl("profile", currentUser?.profile?.profile_img)} />
                            {/* <AccountCircle /> */}
                        </IconButton>
                    )}
                    {isAvatarProfilePicture && (
                        <IconButton aria-label="Profile" color="inherit" onClick={toggleSidePanelOpen} size="large">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center ",
                                    backgroundColor: userAvatarData.avatar.backgroundColor,
                                    borderRadius: 100,
                                    overflow: "hidden",
                                    width: 40,
                                    height: 40,
                                }}
                            >
                                <OvationAvatarSvg />
                            </Box>
                        </IconButton>
                    )}
                    <IconButton aria-label="beYou Bot" color="inherit" onClick={handleSidePanelChatBot} size="large">
                        <Badge badgeContent={notificationsCount.all} color="primary">
                            <Avatar src={bot} />
                            {/* <AccountCircle /> */}
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={open ? "temporary" : "permanent"}
                open={true}
                onClose={handleDrawerClose}
                style={{
                    zIndex: currentStep == 7 ? 1301 : "unset",
                    position: "relative",
                }}
            >
                <div
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                    })}
                    ref={drawerRef}
                >
                    <div className={classes.drawerToggle}>
                        <div className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleMenuToggle}
                                className={classes.menuClose}
                                size="large"
                            >
                                <ArrowBack />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.drawerLinks}>
                        <Links isDrawerOpen={open} setOpen={setOpen} />
                    </div>
                    <div className={classes.drawerActions}>
                        <Divider variant={open ? "fullWidth" : "middle"} />
                        <List>
                            <ListItem
                                className={classes.themeToggle}
                                style={{
                                    justifyContent: open ? "center" : "space-evenly",
                                }}
                            >
                                {open ? (
                                    <FormControlLabel
                                        className={classes.switchLabel}
                                        label="Dark Mode"
                                        labelPlacement="start"
                                        control={<ThemeToggle />}
                                    ></FormControlLabel>
                                ) : (
                                    <ThemeToggle />
                                )}
                            </ListItem>
                        </List>
                    </div>
                </div>
            </Drawer>
            <ContentSideBar
                open={Boolean(userObject.profile) && sidePanelOpen}
                pull="left"
                onClose={handleSidePanelClose}
            >
                <ProfileSummary dispatch={handleSidePanelClose} />
            </ContentSideBar>
            <ContentSideBar open={showSidePanelChatBot} pull="left" onClose={handleSidePanelChatBot}>
                <NotificationsSummary setSidePanelChatBot={setSidePanelChatBot} />
            </ContentSideBar>
            {/* <Toasts prompts /> */}
            <Feedback setFeedbackOpen={setShowFeedBackModal} feedbackOpen={showFeedBackModal} />
        </React.Fragment>
    );
};

export default Navigation;
