import { Avatar, Box, Dialog, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import { getImageUrl, stringAvatar } from "../../misc/helpers";
import OvationQuotesModal from "./OvationQuotesModal";

const OvationCard = ({ ovation }: any) => {
    const theme = useTheme();
    const [openModal, setOpenModal] = useState(false);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                width: 300,
                height: 320,
                background: theme.palette.primary.main,
                borderRadius: 2,
                padding: 2,
            }}
            onClick={() => setOpenModal(!openModal)}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    border: `1px solid ${theme.palette.info.main}`,
                    borderRadius: 2,
                    height: "75%",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        background: theme.palette.info.main,
                        width: 30,
                        height: 30,
                        position: "absolute",
                        right: 10,
                        top: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 20,
                        border: `1px solid ${theme.palette.primary.main}`,
                        zIndex: 2,
                    }}
                >
                    {ovation?.ovations?.length}x
                </Box>
                <Box
                    sx={{
                        filter: "blur(8px)",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        background: theme.palette.grey[300],
                    }}
                />
                <Avatar
                    style={{ width: 200, height: 200, fontSize: 100 }}
                    src={getImageUrl("profile", ovation.profile_img)}
                    {...stringAvatar(ovation.name)}
                />
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: 20 }}>{ovation.name}</Typography>
            </Box>
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth={"md"} fullWidth>
                <OvationQuotesModal ovation={ovation} closeModal={setOpenModal} />
            </Dialog>
        </Box>
    );
};

export default OvationCard;
