import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { Icons, toast } from "react-toastify";

import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { BadgesProps } from "./NotificationTypes";

const Badges = ({ badge, removeNotification }: BadgesProps) => {
    return (
        <Box key={badge?.id}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {badge?.badge_image ? (
                        <img src={badge.badge_image} style={{ width: 35, height: 30 }} />
                    ) : (
                        Icons.info({
                            theme: "light",
                            type: toast.TYPE.INFO,
                            width: 30,
                            height: 30,
                        })
                    )}

                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        Badge
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {badge?.badge_name}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={Number(badge?.date)} />
                            </Typography>
                        </Typography>
                        <Button
                            onClick={() => {
                                removeNotification(badge.id);
                            }}
                        >
                            <Close color="error" fontSize="small" />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Badges;
