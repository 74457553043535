import { GroupedHeaderTableProps } from "../../../../components/GroupedHeaderTable/GroupedHeaderTable";

type t_LsaSummary = {
    for_survey: string;
    lsa_n: number;
    completed_ratio: number;
    partial_ratio: number;
    incomplete_ratio: number;
};

//@ts-ignore
const LsaSummaryColumns: GroupedHeaderTableProps<t_LsaSummary>["columns"] = [
    {
        header: "n",
        field: "lsa_n",
    },
    {
        header: "Completed",
        field: "completed_ratio",
        render: (rowData) => `${rowData.completed_ratio}%`,
    },
    {
        header: "Partially Completed",
        field: "partial_ratio",
        render: (rowData) => `${rowData.partial_ratio}%`,
    },
    {
        header: "Not completed",
        field: "incomplete_ratio",
        render: (rowData) => `${rowData.incomplete_ratio}%`,
    },
];

export { LsaSummaryColumns };
export type { t_LsaSummary };
