import { Grid } from "@mui/material";
import { Column } from "material-table";
import React, { ReactNode } from "react";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import useMultiDateSelect from "../../../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { useReportExport } from "../../../../hooks/export/useReportExport";
import { getApiUrl } from "../../../../misc/helpers";
import { SurveyReports } from "../../PerceptionReport";
import SurveyPeriodsMulti from "../../tables/SurveyPeriods/SurveyPeriodsMulti";
import TeamLeaderSummary from "../../tables/TeamLeaderSummary/TeamLeaderSummary";
import TeamLeaderSummaryMulti from "../../tables/TeamLeaderSummary/TeamLeaderSummaryMulti";

const SurveyReport: React.FC<{
    survey: SurveyReports;
    columns: (base: Column<any>[]) => Column<any>[];
    children?: ReactNode;
}> = ({ survey, columns, children }) => {
    const { loading: exportLoading, downloadExportFile, downloadExportFileMulti } = useReportExport();

    const { datePills, periodsArr, selectedDate, yearAndMonthSelector } = useMultiDateSelect();

    // const handleExport = (file_type: string) => {
    //   const survey_url = new URL(`${getApiUrl()}/reports/survey/export`);

    //   survey_url.searchParams.append("month", selectedDate.toDateString());
    //   survey_url.searchParams.append("file_type", file_type);
    //   survey_url.searchParams.append("survey_type", survey);

    //   downloadExportFile(survey_url.toString(), `${survey.toUpperCase()} Export`);
    // };

    const handleExportMulti = (file_type: string) => {
        let reqPeriods: string[] = [];
        reqPeriods = periodsArr[Object.keys(periodsArr)[0]].map((period) => {
            if (period && period.month) {
                const [y, m, _] = period.month.split("-");
                return [y, m, "05"].join("-");
            } else return "";
        });
        const survey_url = new URL(`${getApiUrl()}/reports/survey/export/multi`);

        survey_url.searchParams.append("months", reqPeriods.toString());
        survey_url.searchParams.append("file_type", file_type);
        survey_url.searchParams.append("survey_type", survey);

        downloadExportFileMulti(survey_url.toString(), `${survey.toUpperCase()} Export`);
    };

    return (
        <SectionCard
            title={`${survey.length > 3 ? survey : survey.toUpperCase()} Report`}
            secondaryAction={yearAndMonthSelector}
            scrolling
            fullWidth
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {datePills}
                </Grid>

                <Grid item sm={8}>
                    <SurveyPeriodsMulti periods={{ [survey]: periodsArr[survey] || [] }} />
                </Grid>
                {periodsArr && (
                    <Grid item sm={12}>
                        <TeamLeaderSummaryMulti
                            periods={periodsArr}
                            report={survey}
                            columns={columns}
                            sectionCardProps={{
                                secondaryAction: (
                                    <ExportButton
                                        dispatch={handleExportMulti}
                                        loading={exportLoading}
                                        disabled={exportLoading}
                                    >
                                        Export
                                    </ExportButton>
                                ),
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </SectionCard>
    );
};

export default SurveyReport;
