import { httpClient } from "../../../../clients/http.client";
import { t_LsaDetails } from "../../LowScoreAnalysis.type";

const getLsaStreams = async () => {
    const response = await httpClient.get("/lsa/streams");
    return response.data.data.streams;
};
const getLsaResponsibleScopes = async () => {
    const response = await httpClient.get("/lsa/responsibleScopes");
    return response.data.data.scopes;
};
const getCustomLsaTypes = async (payload: { stream_id?: number }) => {
    const response = await httpClient.post("/lsa/customTypes", payload);
    return response.data.data.custom_types;
};
const getLsaActions = async () => {
    const response = await httpClient.get("/lsa/actions");
    return response.data.data.actions;
};
const getLsaGrouping = async (payload: { stream_id?: number }) => {
    const response = await httpClient.post("/lsa/lowScoreGrouping", payload);
    return response.data.data.groups;
};
const getLsaPrimaryTypes = async (payload: { stream_id?: number }): Promise<any[]> => {
    const response = await httpClient.post("/lsa/primaryQueryType", payload);
    return response.data.data.types;
};
const getLsaPrimaryCauses = async (payload: { stream_id?: number; group_id?: number }) => {
    const response = await httpClient.post("/lsa/primaryLowScoreCause", payload);
    return response.data.data.causes;
};
const getLsaSecondaryTypes = async (payload: { stream_id?: number; primary_query_id?: number }) => {
    const response = await httpClient.post("/lsa/secondaryQueryType", payload);
    return response.data.data.types;
};
const getLsaSecondaryCauses = async (payload: { stream_id?: number; primary_cause_id?: number }) => {
    const response = await httpClient.post("/lsa/secondaryLowScoreCause", payload);
    return response.data.data.causes;
};
const saveLowScoreAnalysis = async (
    payload: {
        lsa_id?: number;
    } & t_LsaDetails,
) => {
    const body = {
        lsa_id: payload.lsa_id,
        landing: payload.landing,
        stream: payload.stream?.id,
        scope: payload.scope?.id,
        action: payload.action?.id,
        group: payload.group?.id,
        primary_type: payload.primary_type?.id,
        primary_cause: payload.primary_cause?.id,
        secondary_type: payload.secondary_type?.id,
        secondary_cause: payload.secondary_cause?.id,
        practice_type: payload.practice_type?.id,
        appeal_type: payload.appeal_type?.id,
        missing_information_type: payload.missing_information_type?.id,
        inquiry_opened_or_ca_ticket_logged: payload.inquiry_opened_or_ca_ticket_logged?.id,
    };
    const response = await httpClient.post("/lsa/save", body);
    return response.data.data;
};

export {
    getLsaStreams,
    getLsaResponsibleScopes,
    getCustomLsaTypes,
    getLsaActions,
    getLsaGrouping,
    getLsaPrimaryTypes,
    getLsaPrimaryCauses,
    getLsaSecondaryTypes,
    getLsaSecondaryCauses,
    saveLowScoreAnalysis,
};
