import { httpClient } from "../../../../clients/http.client";

const saveHelpFile = (payload: {
	id?: number;
	title: string;
	content: string;
}) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/helpFile/save", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export { saveHelpFile };
