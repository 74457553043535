import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const profileCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		hiddenInput: {
			display: "none",
		},
		card: {
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: "transparent",
			display: "flex",
		},
		cardMedia: {
			padding: theme.spacing(2),
		},
		avatar: {
			height: 120,
			width: 120,
		},
		editIcon: {
			color: theme.palette.info.main,
			backgroundColor: theme.palette.secondary.main,
		},
		profileBadges: {
			marginTop: theme.spacing(2),
		},
		highlight: {
			borderColor: theme.palette.info.main,
			cursor: "pointer",
		},
	})
);

export default profileCardStyles;
