import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

const PipOptionsTable = ({
    data,
    setCombinedData,
    viewMetrics,
}: {
    data: any[];
    setCombinedData: any;
    viewMetrics: any;
}) => {
    const columns = [
        {
            id: "name",
            label: "Options",
            type: "dropdown",
        },
        {
            id: "set",
            label: "Option Set",
            type: "emojiThumb",
        },
        {
            id: "followed",
            label: "Option Followed",
            type: "emojiThumb",
        },
        {
            id: "review_date",
            label: "Review Date",
            type: "date",
        },
    ];

    const emojiThumb = [
        {
            id: 1,
            label: "",
            icon: "\u{1F44D}",
        },
        {
            id: 2,
            label: "",
            icon: "\u{1F44E}",
        },
    ];

    const options = [
        "Wellness Referral",
        "Degreed Courses",
        "SME Buddy",
        "PIP Service Guides",
        "Audit Own Work - DPMO",
        "Audit Own Work - Perception",
        "PIP Call Library",
        "Join Appeals Meetings",
        "Daily Member Trackers",
        "Speechminer Trackers",
        "Evolve Callback Tracker",
    ];

    const createData = (name: any, set: any, followed: any, review_date: any) => {
        return { name, set, followed, review_date };
    };

    const [rows, setRows] = useState([
        createData("", "", "", ""),
        createData("", "", "", ""),
        createData("", "", "", ""),
        createData("", "", "", ""),
    ]);
    const handleInputChange = (rowIndex: number, columnId: string, value: string) => {
        const updatedRows = rows.map((row, index) => (index === rowIndex ? { ...row, [columnId]: value } : row));
        setRows(updatedRows);
        setCombinedData((prevState: any) => ({
            ...prevState,
            pipOptions: updatedRows,
        }));
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} sx={{ fontWeight: "bold", width: "25%" }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row, rowIndex) => {
                        return (
                            <TableRow>
                                {columns.map((column) => {
                                    //@ts-ignore
                                    const value = row[column.id];

                                    return (
                                        <TableCell key={column.id}>
                                            {column.type == "dropdown" ? (
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel>Options</InputLabel>

                                                    <Select
                                                        sx={{ width: "100%" }}
                                                        variant="outlined"
                                                        displayEmpty
                                                        value={value}
                                                        onChange={(e) =>
                                                            handleInputChange(rowIndex, column.id, e.target.value)
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            {/* <em>Options</em> */}
                                                        </MenuItem>
                                                        {options.map((listItem: any) => (
                                                            <MenuItem
                                                                value={listItem}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {listItem}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : column.type == "date" ? (
                                                <DatePicker
                                                    value={value}
                                                    onChange={(newValue) =>
                                                        handleInputChange(rowIndex, column.id, newValue)
                                                    }
                                                    format="dd/MM/yyyy"
                                                />
                                            ) : column.type == "emojiThumb" ? (
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel>Reaction Options</InputLabel>
                                                    <Select
                                                        sx={{
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor:
                                                                value == "👍"
                                                                    ? "#D4EFDF"
                                                                    : value == "👎"
                                                                      ? "#F5B7B1 "
                                                                      : "",
                                                        }}
                                                        variant="outlined"
                                                        displayEmpty
                                                        value={value}
                                                        onChange={(e) =>
                                                            handleInputChange(rowIndex, column.id, e.target.value)
                                                        }
                                                    >
                                                        <MenuItem disabled value="">
                                                            {/* <em>Reaction Options</em> */}
                                                        </MenuItem>
                                                        {emojiThumb.map((listItem: any) => (
                                                            <MenuItem
                                                                value={listItem?.icon}
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {listItem.icon}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    sx={{ width: "100%" }}
                                                    value={value}
                                                    onChange={(e) =>
                                                        handleInputChange(rowIndex, column.id, e.target.value)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PipOptionsTable;
