import React, { ReactNode } from "react";

import BlazerShirt from "./BlazerShirt";
import BlazerSweater from "./BlazerSweater";
import CollarSweater from "./CollarSweater";
import { ClotheColorOption } from "./Colors";
import GraphicShirt from "./GraphicShirt";
import Hoodie from "./Hoodie";
import Overall from "./Overall";
import ShirtCrewNeck from "./ShirtCrewNeck";
import ShirtScoopNeck from "./ShirtScoopNeck";
import ShirtVNeck from "./ShirtVNeck";

export type ClothesOption =
    | "BlazerShirt"
    | "BlazerSweater"
    | "CollarSweater"
    | "GraphicShirt"
    | "Hoodie"
    | "Overall"
    | "ShirtCrewNeck"
    | "ShirtScoopNeck"
    | "ShirtVNeck"
    | "default";

const componentsMap: Record<
    ClothesOption,
    React.ComponentType<{ children?: ReactNode; clothesColor?: ClotheColorOption }>
> = {
    BlazerShirt: BlazerShirt,
    BlazerSweater: BlazerSweater,
    CollarSweater: CollarSweater,
    GraphicShirt: GraphicShirt,
    Hoodie: Hoodie,
    Overall: Overall,
    ShirtCrewNeck: ShirtCrewNeck,
    ShirtScoopNeck: ShirtScoopNeck,
    ShirtVNeck: ShirtVNeck,
    default: ShirtCrewNeck,
};

interface ClothesProps {
    option: ClothesOption;
    clothesColor?: ClotheColorOption;
    children?: ReactNode;
}

const Clothes = ({ option = "default", children, clothesColor = "default" }: ClothesProps) => {
    const SelectedComponent = componentsMap[option] || componentsMap["default"];
    return <SelectedComponent clothesColor={clothesColor}>{children}</SelectedComponent>;
};

export default Clothes;
