import { Button, ButtonProps, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import surveyButtonStyles from "../Surveys.components.style";

interface Props {
	status_id?: number;
	status_label?: string;
	can_appeal?: boolean;
	appeal_cutoff?: string;
}

const AppealButton: React.FC<Props & ButtonProps> = ({
	status_id,
	status_label,
	can_appeal,
	appeal_cutoff,
	...props
}) => {
	const classes = surveyButtonStyles();

	return (
		<Tooltip
			title={
				status_label || can_appeal !== true
					? `Expired${
							appeal_cutoff != null ? ": " + appeal_cutoff : ""
					  }`
					: "Appeal"
			}
		>
			<Button
				{...props}
				className={clsx({
					[classes.incomplete]: status_id === 3,
					[classes.partial]: status_id === 1,
					[classes.valid]: status_id === 2,
				})}
				disabled={status_id == null && !can_appeal}
			>
				APPEAL
			</Button>
		</Tooltip>
	);
};

export default AppealButton;
