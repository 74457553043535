import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";
import { t_InitiativeType } from "../../forms/AdditionalProduction/AdditionalProduction.types";
import { t_TotalAdditionalVolumeItem } from "./TotalAdditionalVolumeItems.types";

const getTotalAdditionalVolumeItemsGraphData = (payload: {
	incentive_id: t_IncentiveType["id"];
	initiative_id: t_InitiativeType["id"];
}) => {
	return new Promise<t_TotalAdditionalVolumeItem[]>((resolve, reject) => {
		resolve([
			{ month: "Jan-21", volume: 55 },
			{ month: "Feb-21", volume: 52 },
			{ month: "Apr-21", volume: 51 },
			{ month: "May-21", volume: 51 },
			{ month: "Jun-21", volume: 52 },
			{ month: "Jul-21", volume: 53 },
		]);
		// httpClient.post("/production/graphs/total_additional_volume_items", payload).then(({data}) => {
		//     switch (data.status) {
		//         case "success":
		//             resolve(data.data.items)
		//             break;

		//         default:
		//             reject(data.status)
		//             break;
		//     }
		// })
	});
};

export { getTotalAdditionalVolumeItemsGraphData };
