import React, { ReactNode, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

type ERROR_TYPES = "NOT_LOGGED_IN";
class InternalError {
    type: ERROR_TYPES;
    message: string;

    constructor(error: ERROR_TYPES) {
        this.type = error;
        this.message = `Internal error (${error}).`;
    }
}
const errorHandlerContext = createContext<((error: ERROR_TYPES) => void) | undefined>(undefined);

const useErrorHandler = () => {
    const errorHandler = useContext(errorHandlerContext);

    if (errorHandler === undefined) throw new Error("errorHandler must be used inside a provider.");

    return errorHandler;
};

const ErrorHandlerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const handler = (error: any) => {
        if (error instanceof InternalError) {
            switch (error.type) {
                case "NOT_LOGGED_IN":
                    navigate("/login");
                    break;

                default:
                    throw new Error(`Error Type not handled (${error}).`);
            }
            return;
        }
        // @MM Network Errors
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    navigate("/login");
                    break;
                default:
                    console.error(error.response);
                    throw new Error(`Status code not covered (${error.response.status}).`);
            }
        }
    };
    return <errorHandlerContext.Provider value={handler}>{children}</errorHandlerContext.Provider>;
};

export { ErrorHandlerProvider, useErrorHandler, InternalError };
export type { ERROR_TYPES };
