import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider, createTheme } from "@mui/material";
import React, { ReactNode, useMemo, useState } from "react";
import { DarkTheme, LightTheme } from "../../theme";
import { useSelector } from "react-redux";
import customOverrides from "../../theme/overrides/overrides";
import { darkPalette, lightPalette, shape } from "../../theme/variables/variables";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

type t_Dispatch = React.Dispatch<React.SetStateAction<boolean>>;
type t_State = [boolean, t_Dispatch];
const BUThemeToggle = React.createContext<t_State>([false, () => {}]);

const themes = [DarkTheme, LightTheme];

const getTheme = (mode: boolean) => {
    return themes[mode ? 0 : 1];
};

const useBUThemeToggle = () => {
    const context = React.useContext(BUThemeToggle);

    if (context === undefined) throw new Error("BUThemeToggle must be used inside a provider.");
    return context;
};
interface DesignTokensProps {
    mode: any; // Update the type to PaletteMode
}

export const themeColors = (mode: any) => ({
    ...(mode === "dark"
        ? {
              swBg: "#292929",
              swText: "#EAEAEA",
              //              ' #0F0F0F',
              // '#C3D830',
              // '#3288F4',
              // '#434343',
              // '#292929',
              // '#B8D200',
              // '#EAEAEA',
              // '#A3A6B4',
              // '#30D843',
              // '#3288F447',
              // '#C3D83048',
              // '#0086FC',
              // '#FF4040',
              // '#0000000A',
              // '#0000001A',
              // '#A3A2A2',
              // '#FFFFFF',
              // '#A4B911',
              // '#F0F0F0',
          }
        : {
              swBg: "#ECF1F5",
              swText: "#B7B7C4",
          }),
});

const getDesignTokens = ({ mode }: DesignTokensProps) => ({
    shape,
    components: customOverrides(mode === "light" ? lightPalette : darkPalette),
    palette: {
        mode,
        ...(mode === "light"
            ? {
                  // palette values for light mode
                  common: {
                      black: "#454545",
                      white: "#a4a6b4",
                  },
                  primary: {
                      main: "#ffffff",
                      contrastText: "#4d4f40",
                  },
                  secondary: {
                      main: "#f0f0f0",
                      contrastText: "#5c5c5c",
                  },
                  info: {
                      main: "#c6db34",
                      contrastText: "#4d4f40",
                  },
                  text: {
                      primary: "#4d4f40",
                      secondary: "#a4a6b4",
                  },
                  background: {
                      paper: "#ffffff",
                      default: "#f0f0f0",
                  },
              }
            : {
                  // palette values for dark mode
                  common: {
                      black: "#454545",
                      white: "#a4a6b4",
                  },
                  primary: {
                      main: "#454545",
                      contrastText: "#D2D6E8",
                  },
                  secondary: {
                      main: "#454545",
                      contrastText: "#BBBECE",
                  },
                  info: {
                      main: "#B8D200",
                      contrastText: "#0F0F0F",
                  },
                  text: {
                      primary: "#EAEAEA",
                      secondary: "#a4a6b4",
                  },
                  background: {
                      paper: "#535353",
                      default: "#454545",
                  },
              }),
    },
});

const BUThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // const [mode, setMode] = React.useState<boolean>(false);

    // const theme = getTheme(mode);
    // const _setMode: t_Dispatch = (setState) => {
    //     if (typeof setState !== "boolean") {
    //         const newMode = setState(mode);
    //         setMode(newMode);
    //     }
    // };
    const [mode, setMode] = useState("light");
    const darkMode = useSelector((state: any) => state.theme.darkMode);

    useMemo(() => {
        if (darkMode) {
            setMode("dark");
        } else {
            setMode("light");
        }
    }, [darkMode]);

    const theme = useMemo(() => createTheme(getDesignTokens({ mode })), [mode]);
    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                {children}
            </StyledEngineProvider>
        </ThemeProvider>
    );
};
export { BUThemeToggle, useBUThemeToggle };
export default BUThemeProvider;
