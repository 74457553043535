import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useGetTipMutation } from "../../redux/api/beYouCore";
import { CoachTip } from "../../redux/types";
import { Close } from "@mui/icons-material";
import CoachTipsCard from "./CoachTipsCard";

const useStyles = makeStyles((theme) => ({
    gridScoll: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
            width: "0.4em",
            borderRadiud: ".8em",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
            boxShadow: "inset 0 0 5px #dddddd",
            borderLeft: "2.5px solid transparent",
            borderRight: "2.5px solid transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#888",
        },
    },
}));

const CoachTips = ({ setIsCoachTips }: any) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [selectedTips, setSelectedTips] = useState<CoachTip[]>();
    const [metricId, setMetricId] = useState(1);
    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);

    const [getTip, { isLoading }] = useGetTipMutation();

    const handleChange = (e: any, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const metric_id = metricId;
        getTip({ metric_id })
            .unwrap()
            .then((data) => {
                setSelectedTips(data.data.tips);
            });
    }, [value]);

    if (!selectedTips || isLoading) {
        <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>;
    }

    return (
        <Box p={2} width="100%" maxHeight="60vh" minHeight="300px" overflow="hidden">
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography>My Coach Tips</Typography>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                        // backgroundColor: "#F0F0F0",
                        width: "50em",
                    }}
                >
                    {allMetrics.map((metric: any) => (
                        <Tab
                            label={metric.name}
                            style={{ width: 150 }}
                            key={metric.id}
                            onClick={() => {
                                setMetricId(metric.id);
                            }}
                        />
                    ))}
                </Tabs>
                <Close onClick={() => setIsCoachTips(false)} style={{ cursor: "pointer" }} />
            </Box>

            <Grid container spacing={2} className={classes.gridScoll} style={{ marginTop: 2, height: "50vh" }}>
                {selectedTips && selectedTips?.length > 0 ? (
                    selectedTips?.map((tip: any, index) => (
                        <Grid item xs={4} key={`${metricId}-${index}`}>
                            <CoachTipsCard tip={tip} id={metricId} />
                        </Grid>
                    ))
                ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" p={4} width="100%">
                        <Typography>No tips currently available</Typography>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default CoachTips;
