import React, { ReactNode, createContext, useState } from "react";

type ToastStateType = {
    weeklySummary: boolean;
    setWeeklySummary: (value: boolean) => void;
};

const initialState: ToastStateType = {
    weeklySummary: false,
    setWeeklySummary: () => {},
};

export const ToastsDisplay = createContext(initialState);

export const ToastsDisplayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [weeklySummary, setWeeklySummary] = useState(false);

    const state: ToastStateType = {
        weeklySummary,
        setWeeklySummary,
    };

    return <ToastsDisplay.Provider value={state}>{children}</ToastsDisplay.Provider>;
};
