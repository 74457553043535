import { format } from "date-fns";
import { findLastDayOfMonth } from "../../../../misc/helpers";
import { t_ProductionPeriodNew } from "../../tables/ProductionPeriods/ProductionPeriods.types";
import { t_ProductionPeriod } from "../../tables/ProductionPeriods/ProductionPeriodsEditor";

const now = new Date();
const thisYear = now.getFullYear();
const thisMonth = now.getMonth();
const lastDay = findLastDayOfMonth(thisYear, thisMonth);

export const defaultPeriod: t_ProductionPeriodNew = {
  start_date: format(new Date(thisYear, thisMonth), "yyyy-MM-dd"),
  end_date: format(new Date(thisYear, thisMonth, lastDay), "yyyy-MM-dd"),
  last_capture: format(new Date(thisYear, thisMonth, lastDay), "yyyy-MM-dd"),
  month: format(new Date(thisYear, thisMonth), "yyyy-MM-dd"),
  all_incentive_categories: false,
  incentive_categories: [],
};

export const base_ProductionOption: t_ProductionPeriod = {
  id: 0,
  start_date: "",
  end_date: "",
  last_capture: "",
  month: "",
  all_incentive_categories: false,
  incentive_categories: [],
};
