import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const progressListStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        baseline: {
            height: "100%",
            top: 0,
            left: (props: { baseline: number; total: number }) => `${(props.baseline / props.total) * 100}%`,
            // backgroundColor: theme.palette.secondary.main,
            // width: "3px",
        },
        target: {
            //@ts-ignore
            color: theme.palette.text.hint,
        },
        value: {
            fontWeight: "bold", // theme.typography.fontWeightMedium,
        },
        infoWindowButton: {
            padding: 0,
            margin: theme.spacing(0, 1),
        },
        infoWindowIcon: {
            height: "0.7em",
            width: "0.7em",
        },
    }),
);

export default progressListStyles;
