import { Box, Button, Dialog, useTheme } from "@mui/material";
import { Column } from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { t_ServiceData } from "../TwtGroup.types";
import styles from "./styles.module.css";
import { getTWTservices } from "../twtService.api";
import EditServiceEnvironment from "../sections/EditServiceEnvironment/EditServiceEnvironment";
import NewServiceEnvironment from "../sections/NewServiceEnvironment/NewServiceEnvironment";

const MaintainTWTservice = () => {
    const [dialogState, setDialogState] = React.useState<{
        open: boolean;
        row?: t_ServiceData;
    }>({
        open: false,
    });

    const [newServiceDialogOpen, setNewServiceDialogOpen] =
        React.useState(false);

    const [columns] = React.useState<Column<any>[]>([
        {
            title: "Name",
            field: "Name",
        },
        {
            title: "Actions",
            render: (data: t_ServiceData) => {
                return (
                    <Box>
                        <div
                            className={styles.actionIcon}
                            onClick={() =>
                                setDialogState({ open: true, row: data })
                            }
                        >
                            <EditIcon />
                        </div>
                    </Box>
                );
            },
        },
    ]);

    const tableRef = React.useRef<any>();

    const theme = useTheme();

    return (
        <>
            <div className={styles.container}>
                <SectionCard title={"Service Environments"} fullWidth scrolling>
                    <div className={styles.mainBtnGroup}>
                        <Button
                            className={styles.mainBtn}
                            onClick={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                            }
                            style={{
                                border: `solid ${theme.palette.info.dark} 1px`,
                                color: theme.palette.info.contrastText,
                            }}
                        >
                            Refresh Service List
                        </Button>

                        <Button
                            className={styles.mainBtn}
                            onClick={() => setNewServiceDialogOpen(true)}
                            style={{
                                backgroundColor: theme.palette.info.main,
                                color: theme.palette.info.contrastText,
                                marginLeft: "1em",
                            }}
                        >
                            Create New Service
                        </Button>
                    </div>

                    <BUMaterialTable
                        title={"Service Environments"}
                        tableRef={tableRef}
                        columns={columns}
                        data={(query) =>
                            getTWTservices({
                                ...query,
                                pagination: {
                                    page: query.page + 1,
                                    rows_per_page: query.pageSize,
                                },
                                filter: [
                                    ...query.filters,
                                    {
                                        column_name: "Name",
                                        search_term: query.search,
                                    },
                                ],
                                sort: {
                                    column_name: "Name",
                                    order: "ASC",
                                },
                            })
                        }
                        options={{
                            search: true,
                            sorting: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20, 50],
                            paging: true,
                        }}
                    />
                </SectionCard>
            </div>

            <Dialog
                open={dialogState.open}
                onClose={() =>
                    setDialogState((state) => ({ ...state, open: false }))
                }
                scroll="paper"
            >
                {dialogState.row && (
                    <EditServiceEnvironment
                        tableRef={tableRef}
                        handleClose={() =>
                            setDialogState((state) => ({
                                ...state,
                                open: false,
                            }))
                        }
                        group={dialogState.row}
                    />
                )}
            </Dialog>

            <Dialog
                open={newServiceDialogOpen}
                onClose={() => setNewServiceDialogOpen(false)}
                scroll="paper"
            >
                <NewServiceEnvironment
                    handleClose={() => setNewServiceDialogOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default MaintainTWTservice;
