import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { motion, useMotionTemplate, useMotionValue, useSpring } from "framer-motion";
import { useRef } from "react";

import { getImageUrl, stringAvatar } from "../../../misc/helpers";

type Props = {
    name: string;
    rank: number;
    score: string;
    profilePicture: string;
    position: number;
};

const PodiumCard = ({ name, rank, score, profilePicture, position }: Props) => {
    const theme = useTheme();
    const ROTATION_RANGE = 32.5;
    const HALF_ROTATION_RANGE = 32.5 / 2;
    const ref = useRef<HTMLDivElement>(null);

    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const xSpring = useSpring(x);
    const ySpring = useSpring(y);

    const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!ref.current) return [0, 0];

        const rect = ref.current.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;

        const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
        const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

        const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
        const rY = mouseX / width - HALF_ROTATION_RANGE;

        x.set(rX);
        y.set(rY);
    };

    const handleMouseLeave = () => {
        x.set(0);
        y.set(0);
    };

    return (
        <motion.div
            ref={ref}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
                height: position === 1 ? "24rem" : "22rem",
                width: "100%",
                maxWidth: "18rem",
                borderRadius: "1rem",
                background: "linear-gradient(to bottom right, #B8D200, #B8D200)",
                position: "relative",
                transformStyle: "preserve-3d",
                transform,
            }}
        >
            <Box
                style={{
                    position: "absolute",
                    inset: "1rem",
                    borderRadius: "1rem",
                    backgroundColor: "white",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transform: "translateZ(75px)",
                    transformStyle: "preserve-3d",
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        height: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            filter: "blur(8px)",
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            backgroundColor:
                                position === 1
                                    ? "#FFD70040"
                                    : position === 2
                                      ? "#C0C0C040"
                                      : position === 3
                                        ? "#CD7F3240"
                                        : theme.palette.secondary.main,
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                        }}
                    />
                    <Avatar
                        style={{ width: 200, height: 200, fontSize: 100 }}
                        src={getImageUrl("profile", profilePicture)}
                        {...stringAvatar(name)}
                    />
                </Box>
                <Box sx={{ height: "30%" }}>
                    <Typography sx={{ textAlign: "center", fontWeight: "bold", fontSize: { xs: 16, md: 20 } }}>
                        {name}
                    </Typography>
                    <Typography
                        color="HighlightText"
                        sx={{ textAlign: "center", fontWeight: "bold", fontSize: { xs: 24, md: 30 } }}
                    >
                        #{rank}
                    </Typography>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: 2 }}>
                        <Typography color="GrayText" variant="caption">
                            {score}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};

export default PodiumCard;
