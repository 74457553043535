import { AddBox, AddCircle, Delete } from "@mui/icons-material";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import "date-fns";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import BUMaterialTable from "../../../../../components/BUMaterialTable/BUMaterialTable";
import { useDeleteIncentiveMetricMutation } from "../../../../../redux/api/beYouCore";
import { t_IncentiveType } from "../MaintainIncentive.types";
import { getIncentiveMetrics } from "./IncentiveDetails.api";
import IncentiveMetricDialog from "./IncentiveMetricDialog/IncentiveMetricDialog";

const metricStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlRow: {
            display: "flex",
            alignItems: "center",
        },
        metricActions: {
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
        },
        chipList: {
            display: "flex",
            gap: 4,
            paddingRight: 6,
            marginRight: 6,
            borderRight: `solid 1px ${theme.palette.divider}`,
        },
    }),
);

type MetricBand = {
    id: number;
    band_date: string;
    from: number;
    to: number;
    multiplier: number;
    from_date: string;
    to_date: string;
};
type MetricIncentive = {
    metric_id: number;
    metric_name: string;
    game_metric: any;
    incentive_metric: any;
    metric_bands: { from_date: string; metric_bands: MetricBand[] }[];
};

const MetricBandColumns = [
    {
        title: "From",
        field: "from",
    },
    {
        title: "To",
        field: "to",
    },
    {
        title: "Multiplier",
        field: "multiplier",
    },
];
type t_IncentiveMetricEvents = {
    type: "duplicate";
    payload: {
        metric: MetricIncentive;
        band_index: number;
    };
};
const IncentiveMetric: React.FC<{
    metric: MetricIncentive;
    onMetricEvent: (props: t_IncentiveMetricEvents) => void;
    categoryId: string;
    setLoading: (value: boolean) => void;
}> = ({ metric, onMetricEvent, categoryId, setLoading }) => {
    const { metric_name, metric_bands, incentive_metric, game_metric } = metric;
    const classes = metricStyles();
    const [activeBand, setActiveBand] = useState<number>(0);
    const [deleteMetricModal, setDeleteMetricModal] = useState(false);
    const [deleteIncentiveMetric] = useDeleteIncentiveMetricMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleActiveBandChange = (event: any) => {
        if (event.target.value != null) {
            setActiveBand(event.target.value);
        }
    };

    const handleMetricDuplicate = () => {
        onMetricEvent({
            type: "duplicate",
            payload: {
                metric: { ...metric },
                band_index: activeBand,
            },
        });
    };

    const deleteMetric = async () => {
        deleteIncentiveMetric({ category_id: categoryId, metric_id: metric.metric_id })
            .unwrap()
            .then((response) => {
                if (response.status === "success") {
                    setDeleteMetricModal(false);
                    setLoading(true);
                    enqueueSnackbar(response.message, { variant: "success" });
                } else {
                    throw Error("Could not delete metric");
                }
            })
            .catch((error) => console.error(error));
    };

    return (
        <Grid container>
            <Grid item xs={12} className={classes.controlRow}>
                <Typography>{metric_name}</Typography>
                <div className={classes.metricActions}>
                    <div className={classes.chipList}>
                        {Boolean(incentive_metric) && <Chip size="small" label="Incentive" />}
                        {Boolean(game_metric) && <Chip size="small" label="Game" />}
                    </div>
                    <IconButton onClick={handleMetricDuplicate} size="large">
                        <AddBox />
                    </IconButton>
                    <IconButton onClick={() => setDeleteMetricModal(!deleteMetricModal)}>
                        <Delete />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Select label="Effective From Date" value={activeBand} onChange={handleActiveBandChange} fullWidth>
                    {metric_bands.map((metric_band, index) => (
                        <MenuItem key={`eff-option${metric_band.from_date}`} value={index}>
                            {metric_band.from_date}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            {metric_bands[activeBand] && (
                <React.Fragment key={`display-${metric_bands[activeBand].from_date}`}>
                    <Grid item xs={12}>
                        <BUMaterialTable
                            columns={MetricBandColumns}
                            data={metric_bands[activeBand].metric_bands}
                            options={{
                                search: false,
                                paging: false,
                                toolbar: false,
                                padding: "dense",
                            }}
                        />
                    </Grid>
                </React.Fragment>
            )}
            <Dialog open={deleteMetricModal} onClose={() => setDeleteMetricModal(false)}>
                <DialogTitle id="alert-dialog-title">{"Delete Metric"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this metric?{" "}
                        <Typography color="error">{metric.metric_name}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteMetricModal(false)}>Disagree</Button>
                    <Button onClick={deleteMetric} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlRow: {
            display: "flex",
            alignItems: "center",
        },
        addMetric: {
            // marginLeft: "auto",
            // backgroundColor: theme.palette.info.main,
            // color: theme.palette.info.contrastText,
            // borderRadius: theme.shape.borderRadius,
        },
        container: {
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: theme.spacing(2),
        },
    }),
);
interface IncentiveDetailsProps {
    incentiveCategoryID: t_IncentiveType["id"];
}

const IncentiveDetails: React.FC<IncentiveDetailsProps> = ({ incentiveCategoryID }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [incentiveMetrics, setIncentiveMetrics] = useState<MetricIncentive[]>([]);

    const [editMetric, setEditMetric] = useState<MetricIncentive>();
    const [editBands, setEditBands] = React.useState<MetricBand[]>([]);

    const handleNewMetric = () => {
        setEditMetric({
            metric_id: -1,
            metric_name: "",
            game_metric: false,
            incentive_metric: false,
            metric_bands: [],
        });
        setEditBands([]);
    };

    const handleMetricEvent = (props: t_IncentiveMetricEvents) => {
        switch (props.type) {
            case "duplicate":
                setEditMetric(props.payload.metric);
                if (props.payload.metric.metric_bands[props.payload.band_index]) {
                    setEditBands(props.payload.metric.metric_bands[props.payload.band_index].metric_bands);
                }
                break;
        }
    };

    const handleDialogClose = () => {
        setLoading(true);
        setEditMetric(undefined);
    };

    React.useEffect(() => {
        if (!incentiveCategoryID) {
            setIncentiveMetrics([]);
        } else {
            setLoading(true);
        }
    }, [incentiveCategoryID]);

    React.useEffect(() => {
        let isActive = true;
        if (!incentiveCategoryID) {
            setIncentiveMetrics([]);
        }
        if (incentiveCategoryID) {
            getIncentiveMetrics({ incentiveCategoryID })
                .then((data) => {
                    isActive && setIncentiveMetrics(data);
                })
                .finally(() => isActive && setLoading(false));
        } else {
            setLoading(false);
        }
        return () => {
            isActive = false;
        };
    }, [loading]);
    return (
        <div className={classes.container}>
            <Grid container spacing={2}>
                <Grid className={classes.controlRow} item sm={12}>
                    <Button className={classes.addMetric} startIcon={<AddCircle />} onClick={handleNewMetric}>
                        Add Metric
                    </Button>
                </Grid>
                {!loading ? (
                    incentiveMetrics.map((metric) => (
                        <Grid key={metric.metric_name} item sm={4}>
                            <IncentiveMetric
                                metric={metric}
                                onMetricEvent={handleMetricEvent}
                                categoryId={incentiveCategoryID}
                                setLoading={setLoading}
                            />
                        </Grid>
                    ))
                ) : (
                    <>
                        <Grid item sm={4}>
                            <Skeleton width="40%" height={30} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                        </Grid>
                        <Grid item sm={4}>
                            <Skeleton width="40%" height={30} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                        </Grid>
                        <Grid item sm={4}>
                            <Skeleton width="40%" height={30} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                            <Skeleton width="100%" height={50} />
                        </Grid>
                    </>
                )}
            </Grid>
            {editMetric && (
                <IncentiveMetricDialog
                    category_id={incentiveCategoryID}
                    metric={editMetric}
                    bands={editBands}
                    onClose={handleDialogClose}
                />
            )}
        </div>
    );
};

export { MetricBandColumns };
export type { MetricBand, MetricIncentive };
export default IncentiveDetails;
