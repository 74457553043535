import { ReactNode } from "react";

import BeardLight from "./BeardLight";
import BeardMajestic from "./BeardMajestic";
import BeardMedium from "./BeardMedium";
import Blank from "./Blank";
import { FacialHairColorOption } from "./FacialHairColors";
import MoustacheFancy from "./MoustacheFancy";
import MoustacheMagnum from "./MoustacheMagnum";

export type FacialHairOption =
    | "Blank"
    | "BeardMedium"
    | "BeardLight"
    | "BeardMajestic"
    | "MoustacheFancy"
    | "MoustacheMagnum"
    | "default";

const componentsMap: Record<
    FacialHairOption,
    React.ComponentType<{ children?: ReactNode; color?: FacialHairColorOption }>
> = {
    Blank: Blank,
    BeardMedium: BeardMedium,
    BeardLight: BeardLight,
    BeardMajestic: BeardMajestic,
    MoustacheFancy: MoustacheFancy,
    MoustacheMagnum: MoustacheMagnum,
    default: Blank,
};

interface FacialHairProps {
    option?: FacialHairOption;
    color?: FacialHairColorOption;
    children?: ReactNode;
}

const FacialHair = ({ option = "default", color = "default", children }: FacialHairProps) => {
    const SelectedComponent = componentsMap[option] || componentsMap["default"];
    return <SelectedComponent color={color}>{children}</SelectedComponent>;
};

export default FacialHair;
