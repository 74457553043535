import { MaterialTableProps } from "material-table";
import React, { createRef, useEffect } from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { t_AppealRecord } from "../../Appeals.types";
import { AppealRecordColumns, getAppealRecords } from "./AppealRecords.api";

interface Props {
	month?: string;
	actions?: MaterialTableProps<t_AppealRecord>["actions"];
}
const AppealRecords: React.FC<Props> = ({ month, actions }) => {
	const tableRef = createRef<any>();

	useEffect(() => {
		tableRef.current && tableRef.current.onQueryChange();
	}, [month]);
	return (
		<BUMaterialTable
			tableRef={tableRef}
			columns={AppealRecordColumns}
			data={
				month
					? (query) =>
							getAppealRecords({
								query,
								month,
							})
					: []
			}
			options={{
				toolbar: false,
				padding: "dense",
				actionsColumnIndex: -1,
				grouping: true,
			}}
			actions={actions}
		/>
	);
};

export default AppealRecords;
