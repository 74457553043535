import { Column } from "material-table";
import { formatApiDate } from "../../../../misc/helpers";
import moment from "moment";

const MetricGeneralColumns: {
    [metric_id: number]: Column<any>[];
} = {
    1: [
        // AHT
        {
            field: "DataDate",
            title: "Date",
            render: (data) => formatApiDate(data?.DataDate),
        },
        { field: "TotalInbound", title: "Total Inbound" },
        {
            title: "Average Handle Time",
            render: (rowData) => {
                let value = 0;
                if (rowData?.TotalHandleTime != null && rowData?.TotalInbound != null) {
                    const total_handle_time = Number(rowData.TotalHandleTime);
                    const total_inbound = Number(rowData.TotalInbound);

                    if (total_inbound > 0) {
                        value = Math.round(total_handle_time / total_inbound);
                    }
                }
                return value;
            },
        },
        { field: "TransfersMade", title: "Transfers Made" },
        {
            title: "Transfer Ratio",

            render: (rowData) => {
                let value = "";
                if (rowData?.TransfersMade != null && rowData?.TotalInbound != null) {
                    const transfers_made = Number(rowData.TransfersMade);
                    const total_inbound = Number(rowData.TotalInbound);

                    if (total_inbound > 0) {
                        value = `${Math.round((transfers_made / total_inbound) * 100)}%`;
                    }
                }
                return value;
            },
        },
        {
            title: "Average Hold Time",
            render: (rowData) => {
                let value = 0;
                if (rowData?.TotalHoldTime != null && rowData?.HoldCalls != null) {
                    const total_hold_time = Number(rowData.TotalHoldTime);
                    const hold_calls = Number(rowData.HoldCalls);

                    if (hold_calls > 0) {
                        value = Math.round(total_hold_time / hold_calls);
                    }
                }
                return value;
            },
        },
        {
            title: "Holds Calls",
            field: "HoldCalls",
        },
    ],
    2: [
        //DPMO
        {
            field: "InteractionDate",
            title: "Interaction Date",
            render: (data) => formatApiDate(data?.InteractionDate, "dd-MMM-yyyy"),
        },
        { field: "CallID", title: "Call ID" },
        { field: "TotalOpportunities", title: "Total Opportunities" },
        { field: "TotalDefects", title: "Total Defects" },
    ],
    5: [
        // Production
        {
            field: "date",
            title: "Date",
            render: (data) =>
                data?.isSummary === true
                    ? formatApiDate(data?.date, "MMM yyyy")
                    : formatApiDate(data?.date, "eee, dd MMM yyyy"),
        },
        {
            title: "Daily Average",
            render: (data) => {
                const total = Number(data.total_production);
                const days = Number(data.employee_work_days);
                if (days > 0) {
                    return Number(total / days).toFixed(2);
                }
                return "-";
            },
        },
        {
            field: "downtime",
            title: "Downtime Loaded",
        },
        {
            field: "additional_volume",
            title: "Additional Volume",
        },
    ],
    7: [
        // Attendance
        {
            field: "start_date",
            title: "Start Date",
            render: (data) => formatApiDate(data?.start_date),
        },
        {
            field: "end_date",
            title: "End Date",
            render: (data) => formatApiDate(data?.end_date),
        },
        {
            field: "leave_type.Name",
            title: "Leave Type",
            sorting: false,
        },
        { field: "days", title: "Days" },
    ],
    8: [
        //TWT
        {
            title: "Agent",
            field: "display_name",
            defaultSort: "asc",
        },
        {
            title: "Pool Name",
            field: "pool_name",
            sorting: false,
            render: (data) => `${data.pool_name} (${data.pool_number})`,
        },

        {
            title: "Measure",
            field: "measure",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "% in TWT",
            field: "percentage_in_twt",
            sorting: false,
        },
        {
            title: "Total",
            field: "twt_total",
            sorting: false,
        },
        {
            title: "In TWT",
            field: "in_twt_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Effort",
            field: "pending_process_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Total",
            field: "pending_process_total",
            sorting: false,
        },
        {
            title: "In TWT",
            field: "pending_process_in_twt_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Total",
            field: "closed_process_total",
            sorting: false,
        },

        {
            title: "In TWT",
            field: "closed_process_in_twt_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "% in TWT",
            field: "mtd_percentage_in_twt",
            sorting: false,
        },
        {
            title: "Total",
            field: "mtd_twt_total",
            sorting: false,
        },
        {
            title: "In TWT",
            field: "mtd_in_twt_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Effort",
            field: "mtd_pending_process_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Total",
            field: "mtd_pending_process_total",
            sorting: false,
        },
        {
            title: "In TWT",
            field: "mtd_pending_process_in_twt_total",
            sorting: false,
            cellStyle: {
                borderRight: "1px solid #ccc",
            },
        },

        {
            title: "Total",
            field: "mtd_closed_process_total",
            sorting: false,
        },
        {
            title: "In TWT",
            field: "mtd_closed_process_in_twt_total",
            sorting: false,
        },
    ],
    9: [
        // Adherence
        { field: "Adherence", title: "Adherence" },
        {
            field: "DataDate",
            title: "DataDate",
            render: (data) => formatApiDate(data?.DataDate),
        },
        { field: "Comment", title: "Comment" },
    ],
    10: [
        // Knowledge
        { field: "Knowledge", title: "Knowledge" },
        {
            field: "DataDate",
            title: "DataDate",
            render: (data) => formatApiDate(data?.DataDate),
        },
        { field: "Comment", title: "Comment" },
    ],
    12: [
        // Engagement
        {
            field: "DataDate",
            title: "Date",
            render: (data) => formatApiDate(data?.DataDate),
        },
        {
            field: "DateDateTime",
            title: "Time",
            render: (data) => formatApiDate(data.DateDateTime, "HH:ii"),
        },
    ],
    14: [
        // SSM
        {
            field: "IncentiveCategory",
            title: "Incentive Category",
        },
        {
            field: "Target",
            title: "Target",
        },
        {
            field: "Month",
            title: "Month",
            render: (data) => moment(data.Month).format("DD-MM-YYYY"),
        },
    ],
    16: [
        //TMBR
        {
            field: "user",
            title: "User",
            render: (data) => data.user.DisplayName,
        },
        {
            field: "report_month",
            title: "Report Month",
            render: (data) => moment(data.Month).format("DD-MM-YYYY"),
        },
        {
            field: "day",
            title: "Day",
            render: (data) => moment(data.Month).format("DD-MM-YYYY"),
        },
        {
            field: "team_leader",
            title: "Team Leader",
        },
        {
            field: "service_team",
            title: "Service Team",
        },
        {
            field: "service_team_specialisation",
            title: "Service TeamcSpecialisation",
        },
        {
            field: "type_of_client",
            title: "Type Of Client",
        },
        {
            field: "member_entity",
            title: "Member Entity",
        },
        {
            field: "caller_name",
            title: "Caller Name",
        },
        {
            field: "score",
            title: "Score",
        },
        {
            field: "comment",
            title: "Comment",
        },
        {
            field: "comment_2",
            title: "Comment 2",
        },
    ],
};

export { MetricGeneralColumns };
