import React from "react";
import LineBarTrend, {
	LineBarTrendData,
} from "../../../../components/LineBarTrend/LineBarTrend";
import { getMetricTrend, t_MetricTrend } from "./UserMetricTrends.api";

interface UserMetricTrendsProps {
	metric_id?: number;
	entity_no?: number;
	month: string;
}

const UserMetricTrends = ({
	metric_id,
	entity_no,
	month,
}: UserMetricTrendsProps) => {
	const [trendData, setTrendData] = React.useState<LineBarTrendData[]>([]);

	React.useEffect(() => {
		var isActive = true;
		if (entity_no != null && metric_id != null) {
			setTrendData([]);
			getMetricTrend({
				entity_no: entity_no,
				metric_id: metric_id,
				month: month,
			}).then((data: t_MetricTrend[]) => {
				if (isActive) {
					setTrendData(
						data
							.map((trend) => ({
								line: trend.target,
								bar: trend.score,
								interval: trend.month,
							}))
							.reverse()
					);
				}
			});
		}
		return () => {
			isActive = false;
		};
	}, [metric_id, entity_no, month]);
	return (
		<LineBarTrend
			data={trendData}
			legend={{ bar: "Score", line: "Target" }}
		/>
	);
};

export default UserMetricTrends;
