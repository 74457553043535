import { Cancel } from "@mui/icons-material";
import { Box, FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React from "react";

import DropdownSelectMulti from "../../../components/DropdownSelect/DropdownSelectMulti";
import { getSurveyPeriodsMultipleMonths } from "../../../sections/PerceptionReport/PerceptionReport.api";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";
import { t_SurveyPeriodArr } from "../../../sections/Surveys/Surveys.types";
import { useStyles } from "./useMultiDateSelect.styles";

const Years: number[] = [];
for (let y = new Date().getFullYear(); y >= 2010; y--) Years.push(y);

const formatDate = (date: Date) => date.toLocaleString("en-GB", { month: "long", year: "numeric" });

const useMultiDateSelect = () => {
    const [{ month: defaultMonth }] = usePerformanceContext();
    const initialDate = moment(defaultMonth).format("YYYY-MM-DD");
    const [datesPeriodsSelected, setDatesPeriodsSelected] = React.useState<Date[]>(
        window.location.href.includes("leaderboards")
            ? [new Date(new Date(initialDate).getFullYear(), new Date(initialDate).getMonth() - 1, 2)]
            : [new Date(new Date(initialDate).getFullYear(), new Date(initialDate).getMonth() + 1, 2)],
    );
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
    const [currentYearSelectedMonths, setCurrentYearSelectedMonths] = React.useState<number[]>(
        datesPeriodsSelected.map((d) => d.getMonth()),
    );
    const [periodsArr, setPeriodsArr] = React.useState<{
        [key: string]: t_SurveyPeriodArr;
    }>({});

    const handleYearSelect = React.useCallback((date: any) => {
        if (date) {
            setSelectedYear(date.getFullYear());
        }
    }, []);

    const handleSelectDateMulti = (monthNumbers: string[]) => {
        const selectedMonthDates = monthNumbers.map((month) => new Date(selectedYear, +month, 2));

        // If no months are selected, remove all dates for the selected year
        if (monthNumbers.length === 0) {
            setDatesPeriodsSelected((dates) => dates.filter((d) => d.getFullYear() !== selectedYear));
            return;
        }

        // Determine which months are newly selected and which are deselected
        const selectedDates = datesPeriodsSelected.filter((d) => d.getFullYear() === selectedYear);
        const newDates = datesPeriodsSelected.filter((d) => d.getFullYear() !== selectedYear);

        // Determine newly added and removed months
        const addedDates = selectedMonthDates.filter(
            (date) => !selectedDates.some((selected) => selected.getMonth() === date.getMonth()),
        );
        const removedDates = selectedDates.filter(
            (date) => !selectedMonthDates.some((selected) => selected.getMonth() === date.getMonth()),
        );

        // Update the dates array to add the newly selected months and remove the deselected months
        const updatedDates = newDates
            .concat(selectedDates.filter((date) => !removedDates.includes(date)))
            .concat(addedDates);

        setDatesPeriodsSelected(updatedDates);
        setCurrentYearSelectedMonths(monthNumbers.map((m) => +m));
        setSelectedDate(selectedMonthDates[selectedMonthDates.length - 1]);
    };

    const handleDateDeselect = (_date: Date) => {
        if (datesPeriodsSelected.includes(_date)) setDatesPeriodsSelected((dates) => dates.filter((d) => d !== _date));
    };

    const updateSelectedMonths = () => {
        const monthList = datesPeriodsSelected.filter((date) => date.getFullYear() === selectedYear);
        setCurrentYearSelectedMonths(monthList.map((date) => date.getMonth()));
    };

    React.useEffect(() => {
        setSelectedDate(new Date(initialDate));
        setDatesPeriodsSelected([new Date(initialDate)]);
    }, [defaultMonth]);

    React.useEffect(() => {
        if (selectedDate !== null) {
            getSurveyPeriodsMultipleMonths({
                for_months: datesPeriodsSelected,
                surveys: ["mbr", "fcr"],
            }).then((data) => {
                setPeriodsArr(data);
            });

            updateSelectedMonths();
        }
    }, [selectedDate, datesPeriodsSelected.length]);

    React.useEffect(() => {
        updateSelectedMonths();
    }, [selectedYear]);

    const classes = useStyles();

    const yearAndMonthSelector = (
        <Box display="flex">
            <FormControl className={classes.yearSelect}>
                <DatePicker
                    openTo="year"
                    views={["year"]}
                    label="Year"
                    disableFuture
                    value={new Date(selectedYear, 0)}
                    onChange={handleYearSelect}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            </FormControl>

            <DropdownSelectMulti
                name="months"
                selectedArray={currentYearSelectedMonths}
                selectHandler={handleSelectDateMulti}
            />
        </Box>
    );

    const datePills = (
        <Box display="flex">
            {datesPeriodsSelected.map((date, index) => (
                <Box
                    key={index}
                    m="10px 10px 0"
                    bgcolor="info.main"
                    p="10px"
                    borderRadius="8px"
                    display="flex"
                    alignItems="center"
                    style={{ gap: "10px" }}
                >
                    {formatDate(date)}
                    {
                        <div onClick={() => handleDateDeselect(date)} style={{ cursor: "pointer" }}>
                            <Cancel />
                        </div>
                    }
                </Box>
            ))}
        </Box>
    );

    return {
        yearAndMonthSelector,
        datePills,
        periodsArr,
        formattedPeriods: datesPeriodsSelected.map((date) => date.toDateString()),
        datesPeriodsSelected,
        selectedDate,
    };
};

export default useMultiDateSelect;
