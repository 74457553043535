import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Snackbar,
    Typography,
} from "@mui/material";
import { CloudDownload, ExpandLess } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import React, { ReactNode } from "react";
import SubmitButton from "../../components/SubmitButton/SubmitButton";

type t_QueueTypes = "view" | "redirect";

type t_QueueRequest<Response extends object> =
    | {
          type: "only_error";
          request: Promise<Response>;
          callback?: (response: Response) => void;
          finally?: () => void;
      }
    | {
          type: "download";
          title: string;
          request: Promise<Response>;
          callback: (response: Response) => boolean;
      };

const requestQueueContext = React.createContext<{
    items: t_QueueRequest<any>[];
    last_update: number;
    pushRequestToQueue: <T extends object>(request: t_QueueRequest<T>) => void;
    removeRequestFromQueue: (index: number) => void;
    clearQueue: () => void;
}>({
    items: [],
    last_update: Date.now(),
    pushRequestToQueue: () => {},
    removeRequestFromQueue: () => {},
    clearQueue: () => {},
});

const useRequestQueue = () => {
    const context = React.useContext(requestQueueContext);
    if (context === undefined) {
        throw new Error("RequestQueue context must be used within a Provider.");
    }

    return context;
};

const RequestQueueProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = React.useState<{
        last_update: number;
        items: t_QueueRequest<any>[];
    }>({
        last_update: Date.now(),
        items: [],
    });

    const pushRequestToQueue: <T extends object>(item: t_QueueRequest<T>) => void = React.useCallback(
        (item) => {
            switch (item.type) {
                case "download":
                    setState((prev_state) => {
                        let new_items = prev_state.items;
                        new_items.push(item);
                        return {
                            last_update: Date.now(),
                            items: new_items,
                        };
                    });
                    break;
                case "only_error":
                    item.request
                        .then(item.callback)
                        .catch((reason: string) =>
                            enqueueSnackbar(typeof reason === "string" ? reason : "We ran into an error.", {
                                variant: "error",
                            }),
                        )
                        .finally(item.finally);
                    break;
                default:
                    break;
            }
        },
        [setState],
    );
    const removeRequestFromQueue = React.useCallback(
        (index: number) => {
            setState((prev_state) => {
                if (prev_state.items.hasOwnProperty(index)) {
                    let new_items = prev_state.items;
                    new_items.splice(index, 1);
                    return {
                        last_update: Date.now(),
                        items: new_items,
                    };
                }
                return prev_state;
            });
        },
        [setState],
    );

    const clearQueue = React.useCallback(() => {
        setState({
            last_update: Date.now(),
            items: [],
        });
    }, [setState]);
    return (
        <requestQueueContext.Provider
            value={{
                ...state,
                pushRequestToQueue,
                removeRequestFromQueue,
                clearQueue,
            }}
        >
            {children}
        </requestQueueContext.Provider>
    );
};

const RequestQueueConsumer = requestQueueContext.Consumer;

const RequestQueueSnackbar: React.FC<{
    item: t_QueueRequest<any>;
    dispatch: (reason: string) => void;
}> = ({ item, dispatch }) => {
    const [open, setOpen] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState<AxiosResponse>();

    const handleCallback = () => {
        if (response != null && item.callback != null) {
            const complete = item.callback(response);
            setOpen(false);
            dispatch("remove");
        }
    };

    React.useEffect(() => {
        setLoading(true);
        item.request.then((response: AxiosResponse) => {
            if (setResponse != null) {
                setResponse(response);
                dispatch("complete");
                setLoading(false);
            }
        });
    }, [setResponse]);

    switch (item.type) {
        case "download":
            return (
                <ListItem style={{ width: 300 }}>
                    <ListItemText>{item.title}</ListItemText>
                    <ListItemSecondaryAction>
                        <SubmitButton
                            loading={loading}
                            disabled={loading}
                            startIcon={<CloudDownload />}
                            onClick={handleCallback}
                        >
                            Download
                        </SubmitButton>
                    </ListItemSecondaryAction>
                </ListItem>
            );
        default:
            return null;
    }
};
const RequestQueue: React.FC = () => {
    const { items, last_update, pushRequestToQueue, removeRequestFromQueue, clearQueue } = useRequestQueue();
    const [itemsComplete, setItemsComplete] = React.useState<number[]>([]);

    const dispatch = (reason: string, index: number) => {
        switch (reason) {
            case "complete":
                setItemsComplete((state) => {
                    if (!state.indexOf(index)) {
                        return [...state, index];
                    }
                    return state;
                });
                break;
            case "remove":
                removeRequestFromQueue(index);
                break;
        }
    };
    return (
        <React.Fragment key={last_update}>
            <Snackbar open={Boolean(items.length)} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandLess />} aria-controls="panel1a-content" id="panel1a-header">
                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography>Downloads</Typography>
                            <Typography variant="caption" component="div" color="textSecondary">
                                {items.length}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column">
                            <List>
                                {items.map((item, index) => (
                                    <>
                                        <RequestQueueSnackbar
                                            key={`${item.type}-${index}`}
                                            item={item}
                                            dispatch={(r) => dispatch(r, index)}
                                        />
                                        <Divider />
                                    </>
                                ))}
                                <ListItem></ListItem>
                            </List>
                            <Box width="100%" display="flex" justifyContent="flex-end">
                                <Button variant="text" onClick={clearQueue}>
                                    Clear
                                </Button>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Snackbar>
        </React.Fragment>
    );
};

export { useRequestQueue, RequestQueueConsumer, RequestQueueProvider, RequestQueue };
