import { httpClient } from "../../../../clients/http.client";

const createLowScoreAnalysis = (payload: {
	data_id: number;
	metric_id: number;
}) =>
	new Promise<any>((resolve, reject) => {
		const body = {
			data_id: payload.data_id,
			metric_id: payload.metric_id,
		};
		httpClient
			.post("/lsa/create", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
						break;
				}
			})
			.catch((reason) => {
				reject(reason);
			});
	});

export { createLowScoreAnalysis };
