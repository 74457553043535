import {
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	TextField,
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import { useFormik } from "formik";
import React from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import { getServiceTeams, getSkills } from "../User.api";
import { t_LookupField } from "../User.types";
import { getUserAttributes, saveUserAttributes } from "./UserAttributes.api";
import userAttributesStyles from "./UserAttributes.style";
import { t_UserAttributesForm } from "./UserAttributes.types";

interface Props {
	entity_no?: number;
}

const base_UserAttributesForm: t_UserAttributesForm = {
	service_team: null,
	skill: null,
	second_in_command: false,
};

const UserAttributes: React.FC<Props> = ({ entity_no }) => {
	const classes = userAttributesStyles();
	const [serviceTeams, setServiceTeams] = React.useState<t_LookupField[]>([]);
	const [skills, setSkills] = React.useState<t_LookupField[]>([]);

	const formik = useFormik({
		initialValues: base_UserAttributesForm,
		onSubmit: (formData: t_UserAttributesForm) =>
			saveUserAttributes({
				entity_no,
				...formData,
			}),
	});

	const handleSelectChange = (name: string, value: any) => {
		formik.setFieldValue(name, value);
	};

	const handleSubmit = () => {
		formik.submitForm();
	};

	React.useEffect(() => {
		var isActive = true;

		getServiceTeams().then((data) => {
			if (isActive) {
				setServiceTeams(data);
			}
		});
		getSkills().then((data) => {
			if (isActive) {
				setSkills(data);
			}
		});
		return () => {
			isActive = false;
		};
	}, []);

	React.useEffect(() => {
		var isActive = true;

		if (entity_no != null) {
			getUserAttributes({ entity_no }).then((data) => {
				if (isActive) {
					formik.setValues(data);
				}
			});
		}

		return () => {
			isActive = false;
		};
	}, [entity_no]);
	return (
        <SectionCard
			noHeader
			bottomActions={
				<div className={classes.formActions}>
					<SubmitButton
						loading={formik.isSubmitting}
						onClick={handleSubmit}
						disabled={entity_no == null || !formik.dirty}
					>
						Save
					</SubmitButton>
				</div>
			}
			hasBackground
		>
			<List className={classes.formList}>
				<ListItem>
					<Autocomplete
						className={classes.autocomplete}
						value={formik.values.service_team}
						options={serviceTeams}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) =>
							option.id === value.id
						}
						onChange={(_, value) =>
							handleSelectChange("service_team", value)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select Service Team'
								variant='outlined'
							/>
						)}
					/>
				</ListItem>
				<ListItem>
					<Autocomplete
						className={classes.autocomplete}
						value={formik.values.skill}
						options={skills}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) =>
							option.id === value.id
						}
						onChange={(_, value) =>
							handleSelectChange("skill", value)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select Skill'
								variant='outlined'
							/>
						)}
					/>
				</ListItem>
				<ListItem>
					<FormControlLabel
						control={
							<Checkbox
								checked={formik.values.second_in_command}
								onChange={formik.handleChange}
								color='default'
								name='second_in_command'
							/>
						}
						label='Second In Command'
					/>
				</ListItem>
			</List>
		</SectionCard>
    );
};

export default UserAttributes;
