const customOverrides = (palette: any) => {
    const scrollbarBG = palette.background.default;
    // const thumbBG = palette.grey[900];
    const thumbBG = "lightgray";

    return {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    "*": {
                        scrollbarWidth: "thin",
                        scrollbarColor: "#c6db347 transparent",
                        "&::-webkit-scrollbar": {
                            width: 2,
                            height: 2,
                            backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: 20,
                            backgroundColor: "#c6db347",
                            minHeight: 10,
                            minWidth: 10,
                        },
                        "&::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: "#adadad",
                        },
                        "&::-webkit-scrollbar-thumb:active": {
                            backgroundColor: "#adadad",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#adadad",
                        },
                        "&::-webkit-scrollbar-corner": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: "4px",
                },
                colorPrimary: {
                    color: palette.info.main,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    "&.Mui-completed": {
                        color: `${palette.info.main} !important`,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    "&.Mui-selected": {
                        backgroundColor: palette.info.main,
                        color: palette.info.contrastText,
                    },
                    width: "max-content",
                    padding: "8px 12px",
                    minWidth: "0 !important",
                    minHeight: "42px",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                stickyHeader: {
                    backgroundColor: palette.background.paper,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: palette.info.dark,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&$focused": {
                        "&:after": {
                            borderBottomColor: palette.info.dark,
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    "&$focused $notchedOutline": {
                        borderColor: palette.info.dark,
                    },
                },
                input: {
                    padding: "15px 12px",
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: palette.info.main,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    "&.Mui-checked": {
                        color: palette.info.main,
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: palette.info.main,
                    color: palette.info.contrastText,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                current: {
                    color: palette.info.main,
                },
                daySelected: {
                    color: palette.info.contrastText,
                    backgroundColor: palette.info.main,
                },
            },
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: {
                    "&.Mui-selected": {
                        color: palette.info.contrastText,
                        backgroundColor: palette.info.main,
                    },
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    "&.Mui-selected": {
                        color: palette.info.contrastText,
                        backgroundColor: palette.info.main,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                colorPrimary: {
                    color: palette.info.main,
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backdropFilter: "blur(4px)",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: palette.text.primary,
                    "&.MuiButton-contained": {
                        color: palette.info.contrastText,
                        backgroundColor: palette.info.main,
                        "&:hover": {
                            background: palette.info.main,
                            color: palette.secondary.main,
                        },
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: palette.info.main,
                    color: "#FFFFFF",
                    fontSize: "1rem",
                    "& .MuiTooltip-arrow": {
                        color: palette.info.main,
                    },
                },
            },
        },
    };
};

export default customOverrides;
