import { httpClient } from "../../clients/http.client";
import { t_TWTserviceResponse } from "./TwtGroup.types";

const getTWTservices = (payload: any) => {
    return new Promise<any>((res, rej) => {
        httpClient
            .post<t_TWTserviceResponse>(
                "admin/twt/grouping/serviceenvironments",
                payload
            )
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        const services = data.data.records.data;
                        const retData = services.map((service) => {
                            return {
                                id: service.TwtServiceEnvironmentID,
                                Name: service.Name,
                            };
                        });

                        res({
                            data: retData,
                            page: data.pagination.page - 1,
                            totalCount: data.total_results,
                        });
                        break;
                    default:
                        rej(data.message);
                }
            })
            .catch((err) => rej(err.message));
    });
};

const saveServiceName = (payload: { id?: number; name: string }) => {
    return new Promise<void>((res, rej) => {
        httpClient
            .post("admin/twt/grouping/serviceenvironments/save", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        res();
                        break;
                    default:
                        rej(data.message);
                }
            })
            .catch((err) => rej(err.message));
    });
};

export { getTWTservices, saveServiceName };
