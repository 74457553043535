import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme) =>
  createStyles({
    modalContainer: {
      maxWidth: "900px",
      overflowY: "scroll",
    },
    groupEdit: { textAlign: "right", maxWidth: "2em", marginLeft: "1em" },
    addPool: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "end",
      marginBottom: "1em",
    },
    errorMssg: { display: "block", textAlign: "center", fontSize: "1em" },
    autoCompl: { minWidth: "300px" },
    datePick: {
      width: "400px",
      margin: "0 auto 2em",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    poolField: {
      minWidth: "250px",
    },
    poolPick: {
      width: "400px",
      margin: "0 auto 2em",
    },
    snack: {
      backgroundColor: theme.palette.info.dark,
    },
  })
);

export default styles;
