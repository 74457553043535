import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { getProductionTreeDataMulti } from "./ProductionTree.api";
import { t_ProductionTreeRecord } from "./ProductionTree.types";

interface Props {
  months: string[];
}
const ProductionTree: React.FC<Props> = ({ months }) => {
  const [treeData, setTreeData] = React.useState<t_ProductionTreeRecord[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    var isActive = true;
    setLoading(true);
    getProductionTreeDataMulti({ months })
      .then((data) => {
        if (isActive) {
          setTreeData(data);
        }
      })
      .finally(() => {
        if (isActive) {
          setLoading(false);
        }
      });
    return () => {
      isActive = false;
    };
  }, [months?.length]);

  return (
    <BUMaterialTable
      title="Detailed Production"
      data={treeData}
      isLoading={loading}
      columns={[
        {
          title: "Service Team",
          field: "service_team.name",
        },
        { title: "Team", field: "team.name" },
        { title: "Job Title", field: "job_title.name" },
        { title: "Line Manager", field: "line_manager.name" },
        { title: "Individual", field: "individual.name" },
        { title: "n", field: "n" },
        { title: "Daily Target", field: "target" },
        { title: "Avg % of Target", field: "target_percent" },
        { title: "Avg Weighted Production", field: "weighted" },
        { title: "Avg Actual Production", field: "actual" },
        { title: "Downtime (min)", field: "downtime" },
        { title: "Avg Additional Volume", field: "additional_volume" },
      ]}
      options={{
        padding: "dense",
        grouping: false,
        toolbar: false,
      }}
      parentChildData={(row, rows) =>
        rows.find((item) => item.row_ref === row.parent_ref)
      }
    />
  );
};

export default ProductionTree;
