import { Box, Dialog, Typography } from "@mui/material";
import Wheel from "@uiw/react-color-wheel";
import { motion } from "framer-motion";
import { useState } from "react";

import { ClothesOption } from "./avatar/clothes/Clothes";
import { EyebrowOption } from "./avatar/face/eyebrow/Eyebrow";
import { EyesOption } from "./avatar/face/eyes/Eyes";
import { MouthOption } from "./avatar/face/mouth/Mouth";
import { SkinOption } from "./avatar/Skin";
import { AccessoriesOption } from "./avatar/top/accessories/Accessories";
import { FacialHairOption } from "./avatar/top/facialHair/FacialHair";
import { TopOption } from "./avatar/top/Top";
import lockIcon from "./ovationImages/locked.svg";
import UnlockItem from "./UnlockItem";

type MiniBoxProps = {
    icon?: string;
    title: string;
    id: number;
    type: string;
    color?: string;
    isAdmin?: boolean;
    handleSelectedAvatarData: (data: any) => void;
    setAccessories: (text: AccessoriesOption) => void;
    setClothes: (text: ClothesOption) => void;
    setEyes: (text: EyesOption) => void;
    setEyeBrows: (text: EyebrowOption) => void;
    setMouth: (text: MouthOption) => void;
    setSkin: (text: SkinOption) => void;
    setTop: (text: TopOption) => void;
    setFacialHair: (text: FacialHairOption) => void;
    setBackgroundColor: (text: string) => void;
    backgroundColor: string;
    unlocked?: boolean;
    numberOfOvations: number;
};

const MiniBox = ({
    icon,
    title,
    id,
    type,
    color,
    isAdmin,
    handleSelectedAvatarData,
    setAccessories,
    setClothes,
    setEyes,
    setEyeBrows,
    setMouth,
    setSkin,
    setTop,
    setFacialHair,
    setBackgroundColor,
    backgroundColor,
    unlocked,
    numberOfOvations,
}: MiniBoxProps) => {
    const avatarData = { id, title, icon, type, color };
    const [openUnlockModal, setOpenUnlockModal] = useState(false);

    const closeModal = () => {
        setOpenUnlockModal(false);
    };

    const handleMiniBoxClick = () => {
        if (isAdmin) {
            handleSelectedAvatarData(avatarData);
        } else if (!unlocked) {
            setOpenUnlockModal(!openUnlockModal);
        } else {
            switch (type.toLowerCase()) {
                case "accessories":
                    setAccessories(title as AccessoriesOption);
                    break;
                case "shirt":
                    setClothes(title as ClothesOption);
                    break;
                case "eyes":
                    setEyes(title as EyesOption);
                    break;
                case "eyebrows":
                    setEyeBrows(title as EyebrowOption);
                    break;
                case "mouth":
                    setMouth(title as MouthOption);
                    break;
                case "skintone":
                    setSkin(title as SkinOption);
                    break;
                case "top":
                    setTop(title as TopOption);
                    break;
                case "hat":
                    setTop(title as TopOption);
                    break;
                case "facialhair":
                    setFacialHair(title as FacialHairOption);
                    break;
                case "background":
                    if (color) {
                        setBackgroundColor(color);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <>
            {type === "backgroundColor" ? (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Wheel color={backgroundColor} onChange={(color) => setBackgroundColor(color.hex)} />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        width: 100,
                        height: 120,
                    }}
                    onClick={handleMiniBoxClick}
                >
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E3ED9D",
                            borderRadius: "20px",
                            boxShadow: "0px 3px 6px #00000029",
                            width: 100,
                            height: 100,
                            marginBottom: "10px",
                            backgroundColor: color ? color : undefined,
                            overflow: "hidden",
                            padding: 10,
                            position: "relative",
                        }}
                    >
                        {!unlocked && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "rgba(0,0,0,0.3)",
                                }}
                            >
                                <img src={lockIcon} style={{ width: "80%", height: "80%" }} />
                            </div>
                        )}
                        {icon && <img src={icon} style={{ width: "100%", height: "100%" }} />}
                    </motion.div>
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}
                    >
                        {truncateText(title, 14)}
                    </Typography>
                    <Dialog open={openUnlockModal} onClose={closeModal}>
                        <UnlockItem
                            closeModal={closeModal}
                            icon={icon ? icon : ""}
                            color={color ? color : ""}
                            type={type}
                            id={id}
                            numberOfOvations={numberOfOvations}
                            title={title}
                        />
                    </Dialog>
                </Box>
            )}
        </>
    );
};

export default MiniBox;
