import React from "react";
import { Box, Icon, IconButton, styled } from "@mui/material";
import { Rating } from '@mui/material';
import { IconContainerProps } from '@mui/lab';
import {
    SentimentDissatisfied,
    SentimentSatisfiedAlt,
    SentimentSatisfied,
    SentimentVeryDissatisfied,
    SentimentVerySatisfied,
} from "@mui/icons-material";
import { type } from "os";

const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
        color: theme.palette.action.disabled,
    },
}));

type RatingsComponentProps = {
    value: number;
    onChange?: any;
};

const labels: { [index: string]: string } = {
    1: "Very Dissatisfied",
    2: "Dissatisfied",
    3: "Neutral",
    4: "Satisfied",
    5: "Very Satisfied",
};

const RatingsComponent = ({ value, onChange }: RatingsComponentProps) => {
    const [hover, setHover] = React.useState(0);

    const customIcons: {
        [index: string]: {
            icon: React.ReactElement;
            label: string;
            value: number;
        };
    } = {
        1: {
            icon: <SentimentVeryDissatisfied style={{ color: `${value == 1 ? "#FF4040" : "grey"}` }} />,
            label: "Very Dissatisfied",
            value: 1,
        },
        2: {
            icon: <SentimentDissatisfied style={{ color: `${value == 2 ? "#FF4040" : "grey"}` }} />,
            label: "Dissatisfied",
            value: 2,
        },
        3: {
            icon: <SentimentSatisfied style={{ color: `${value == 3 ? "orange" : "grey"}` }} />,
            label: "Neutral",
            value: 3,
        },
        4: {
            icon: <SentimentSatisfiedAlt style={{ color: `${value == 4 ? "#30D843" : "grey"}` }} />,
            label: "Satisfied",
            value: 4,
        },
        5: {
            icon: <SentimentVerySatisfied style={{ color: `${value == 5 ? "#30D843" : "grey"}` }} />,
            label: "Very Satisfied",
            value: 5,
        },
    };

    function IconContainer(props: IconContainerProps) {
        const { value, ...other } = props;
        return (
            <span {...other} style={{ marginRight: ".6em" }}>
                {customIcons[value].icon}
            </span>
        );
    }

    const textColor =
        value == 1
            ? "#FF4040"
            : value == 2
            ? "#FF4040"
            : value == 3
            ? "orange"
            : value == 4
            ? "#30D843"
            : value == 5
            ? "#30D843"
            : "grey";

    return (
        <Box
        // sx={{
        //     display: "flex",
        //     alignItems: "center",
        // }}
        >
            <StyledRating
                // name="highlight-selected-only"
                IconContainerComponent={IconContainer}
                value={value}
                onChange={onChange}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
                getLabelText={(value: number) => customIcons[value].label}
            />
            {value !== null && <Box style={{ color: `${textColor}` }}>{labels[hover !== -1 ? hover : value]}</Box>}
        </Box>
    );
};

export default RatingsComponent;
