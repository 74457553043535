import React, { useContext, useEffect, useState } from "react";
import { Grid, Box, Typography, Button, Divider, TextField, CircularProgress, useTheme } from "@mui/material";
import RatingsComponent from "../../components/RatingsComponent/RatingsComponent";
import { Close } from "@mui/icons-material";
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from "react-toastify";
import { gsap } from "gsap";
import ToastsSuccess from "../../components/ChatBot/ChatBot";
import ToastsError from "../../components/ChatBot/ToastsError";
import { NotificationsContext } from "../../components/Toasts/NotificationsProvider.ctx";
import { useStyles } from "./Styles";
import { useAllMetricsMutation, useSaveSummaryMutation } from "../../redux/api/beYouCore";
import { useUserState } from "../../context/user/user.ctx";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Feedback = ({}: any) => {
    const theme = useTheme();
    const classes = useStyles();
    const [isVisible, setIsVisible] = useState(false);
    const [animationFinished, setAnimationFinished] = useState(true);
    const currentUser = useUserState();
    const [{ month, comparison_month }, performanceDispatch] = usePerformanceContext();
    const [ratingsValue, setRatingsValue] = useState<number | null>(0);
    const [summary, setSummary] = useState("");
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [weeklyMetrics, setWeeklyMetrics] = useState<any[]>([]);
    const [allMetrics, { isLoading }] = useAllMetricsMutation();
    const { setShowFeedBackModal, showFeedBackModal, setWeeklyPrompt } = useContext(NotificationsContext);
    const [success, setSucces] = useState(false);
    const [error, setError] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [timeoutId, setTimeoutId] = useState<any>();

    const entity_no = currentUser.entity_no;
    const weekDate = moment(useSelector((state: RootState) => state.metrics.promptDate)).format("YYYY/MM/DD");
    const [saveSummary] = useSaveSummaryMutation();

    const metrics: any = [];

    const sortedMetrics = [...weeklyMetrics].sort((a, b) => {
        return b.percentage_of_target - a.percentage_of_target;
    });

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        if (weekDate !== "Invalid date") {
            allMetrics({
                entity_no,
                month: weekDate,
                comparison_month,
                structure_type: "weekly",
            })
                .unwrap()
                .then((data: any) => {
                    setWeeklyMetrics(data.data.metric_values);
                })
                .catch((error) => {
                    console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
                });
        }
    }, [month, entity_no, weekDate]);

    useEffect(() => {
        const handleAnimationEnd = () => {
            setAnimationFinished(true);
        };
        const imageElement = document.getElementById("overlay-image");
        imageElement?.addEventListener("animationend", handleAnimationEnd);
        return () => {
            imageElement?.removeEventListener("animationend", handleAnimationEnd);
        };
    }, [showFeedBackModal]);

    const summaryMetric = () => {
        weeklyMetrics.map((metric) => {
            const { name, target, value, ...metricRest } = metric;
            const newMericList = {
                metric_name: name,
                target,
                value,
            };

            metrics.push(newMericList);
        });
    };

    summaryMetric();

    const handleSaveSummary = () => {
        setSummaryLoading(true);

        if (ratingsValue === 0 || summary === "") {
            setError(true);
            setSucces(false);
            setToastMessage("A rating and summary are required");
            setSummaryLoading(false);
            return;
        }

        if (ratingsValue !== null && summary !== "")
            saveSummary({ summary, rating: ratingsValue, metrics, weekDate })
                .then((response: any) => {
                    toast.success(response.data.message, {
                        position: "bottom-right",
                    });
                })
                .catch((error) => {
                    console.error("🚀 ~ file: Feedback.tsx:146 ~ handleSaveSummary ~ error:", error);
                })

                .finally(() => {
                    clearTimeout(timeoutId);
                    const newTimeoutId = setTimeout(() => {
                        setWeeklyPrompt(true);
                    }, 900000);
                    setTimeoutId(newTimeoutId);
                    setSummaryLoading(false);
                    setShowFeedBackModal(false);
                    setWeeklyPrompt(false);
                });
    };

    useEffect(() => {
        if (showFeedBackModal) {
            gsap.fromTo(
                "#feedback-review-image",
                { x: "5000", visibility: "hidden" },
                {
                    x: 15,
                    duration: 1,
                    opacity: 1,
                    visibility: "visible",
                    ease: "power1.out",
                },
            );

            gsap.fromTo(
                ".feedBack",
                { x: 0, visibility: "hidden", opacity: 0 },
                {
                    x: 10,
                    duration: 0.5,
                    opacity: 1,
                    ease: "power1.out",
                    delay: 1.2,
                    visibility: "visible",
                },
            );
        }
    }, [showFeedBackModal]);
    const handleDismiss = () => {
        setShowFeedBackModal(false);
        setWeeklyPrompt(false);
        clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
            setWeeklyPrompt(true);
        }, 900000);
        setTimeoutId(newTimeoutId);
    };
    return (
        <>
            {showFeedBackModal ? (
                <div className={classes.overlay}>
                    <div
                        style={{
                            background: "transparent",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            inset: 0,
                        }}
                        onClick={handleDismiss}
                    ></div>
                    <Grid container spacing={2} style={{ width: "auto" }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "90%",
                                alignItems: "center",
                                gap: "1rem",
                                margin: "auto",
                                position: "relative",
                            }}
                        >
                            <Box className={`${classes.textContainer} feedBack`} style={{ minHeight: "50em" }}>
                                <Box mb={2} display="flex" justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h6" style={{ fontWeight: "bold" }} gutterBottom>
                                            Perfomance Reflection
                                        </Typography>
                                        <Typography variant="caption" color="textPrimary">
                                            provide a summary of your perfomance from this past week below.
                                        </Typography>
                                    </Box>
                                    <Close
                                        onClick={handleDismiss}
                                        style={{
                                            cursor: "pointer",
                                            color: "gray",
                                        }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="h6" gutterBottom>
                                        Weekly stats for {weekDate}
                                    </Typography>

                                    <Divider />
                                    <Box className={classes.statsBox}>
                                        {sortedMetrics.length < 1 ? (
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            sortedMetrics.map((metric, i) => (
                                                <Box
                                                    key={metric.id}
                                                    sx={{
                                                        color: `${
                                                            // i === 0 ||
                                                            // i === 1 ||
                                                            i === sortedMetrics.length - 1 ||
                                                            i === sortedMetrics.length - 2
                                                                ? theme.palette.common.black
                                                                : ""
                                                        }`,
                                                    }}
                                                    mb={2}
                                                    borderRadius="10px"
                                                    p={1}
                                                    bgcolor={`${
                                                        // i === 0 ||
                                                        // i === 1 ||
                                                        i === sortedMetrics.length - 1 || i === sortedMetrics.length - 2
                                                            ? "#e5ecf2"
                                                            : ""
                                                    }`}
                                                    className={`${i === 0 || i === 1 ? classes.borderRotate : ""}`}
                                                >
                                                    <Box display="flex" justifyContent="space-between" px={1}>
                                                        <Typography variant="body2" gutterBottom>
                                                            {metric.name}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {" "}
                                                            {metric.value}{" "}
                                                            {metric.value && metric.target ? `/ ${metric.target}` : ""}
                                                        </Typography>
                                                    </Box>
                                                    <ProgressBar
                                                        completed={metric.percentage_of_target}
                                                        height="5px"
                                                        maxCompleted={100}
                                                        isLabelVisible={false}
                                                        bgColor={`${
                                                            i === 0 || i === 1
                                                                ? "#30D843"
                                                                : i === sortedMetrics.length - 1 ||
                                                                  i === sortedMetrics.length - 2
                                                                ? "#FF4040"
                                                                : "#0086FC"
                                                        }`}
                                                    />
                                                </Box>
                                            ))
                                        )}
                                    </Box>
                                    <Divider />
                                </Box>
                                <Box mb={2} minHeight="6.5em">
                                    <Typography variant="h6" gutterBottom>
                                        Give your rating of this week
                                    </Typography>

                                    <RatingsComponent
                                        value={ratingsValue ? ratingsValue : 0}
                                        onChange={(e: any) => setRatingsValue(e.target.value)}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="h6" gutterBottom>
                                        Give your summary of this week
                                    </Typography>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={4}
                                        placeholder="What went well this week and what could I have done better..."
                                        variant="outlined"
                                        fullWidth
                                        value={summary}
                                        onChange={(e) => setSummary(e.target.value)}
                                        required
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{ background: "#C3D830" }}
                                    onClick={handleSaveSummary}
                                >
                                    <Typography variant="button" color="textPrimary">
                                        {summaryLoading ? <CircularProgress /> : "Submit"}
                                    </Typography>
                                </Button>
                            </Box>
                            <div
                                style={{
                                    display: "flex",
                                    position: "relative",
                                    top: -170,
                                }}
                            >
                                <img
                                    id="feedback-review-image"
                                    src="/images/Group 602.svg"
                                    style={{ width: "20em" }}
                                    className={classes.image}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
            <ToastsSuccess success={success} message={toastMessage} />

            <ToastsError error={error} message={toastMessage} />
        </>
    );
};
export default Feedback;
