import { Grid } from "@mui/material";
import { Skeleton } from '@mui/material';
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { usePerformanceContext } from "../../Performance.ctx";
import { t_MetricPerformance } from "../../Performance.types";
import UserMetricRecords from "../UserMetricRecords/UserMetricRecords";
import UserMetricTrends from "../UserMetricTrends/UserMetricTrends";

interface Props {
	metric: t_MetricPerformance;
}

const MetricPerformance: React.FC<Props> = ({ metric }) => {
	const [{ viewUser, month }] = usePerformanceContext();
	const [sectionTab, setSectionTab] = React.useState<number>(0);

	return (
		<Grid container spacing={2}>
			{viewUser ? (
				<Grid item xs={12}>
					<SectionCard
						title={
							metric ? `${metric.name} Details` : "Metric Details"
						}
						primaryAction={
							<TabGroup
								tabValue={sectionTab}
								tabList={[
									{ label: "Trend", _id: 0 },
									{ label: "Records", _id: 1 },
								]}
								tabChange={(newValue) =>
									setSectionTab(newValue)
								}
							/>
						}
						fullWidth
					>
						{sectionTab === 0 && (
							<SectionCard
								title={`${viewUser.user.name}'s Trends`}
								titleProps={{
									variant: "h6",
								}}
								fullWidth
								hasBackground
							>
								<UserMetricTrends
									metric_id={metric.id}
									entity_no={viewUser.user.entity_no}
									month={month}
								/>
							</SectionCard>
						)}
						{sectionTab === 1 && (
							<UserMetricRecords
								viewMetric={metric}
								month={month}
								viewUser={viewUser}
							/>
						)}
					</SectionCard>
				</Grid>
			) : (
				<>
					<Grid item sm={6}>
						<Skeleton width={"100%"} height={300} />
					</Grid>
					<Grid item sm={6}>
						<Skeleton width={"100%"} height={300} />
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default MetricPerformance;
