import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const createAvatarStyles = makeStyles((theme: Theme) =>
	createStyles({
		hiddenInput: {
			display: "none",
		},
		borderCircle: {
			borderRadius: "50%",
			border: `1px solid ${theme.palette.divider}`,
		},
	})
);

export default createAvatarStyles;
