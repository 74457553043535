import { Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Navigate, Outlet, Route, Routes, useResolvedPath } from "react-router-dom";
import { usePermissions } from "../../../context/user/permissions.ctx";
import ProtectedRoute from "../../../routers/ProtectedRoute";
import PerceptionReport from "../../../sections/PerceptionReport/PerceptionReport";
import AppealReport from "../../../sections/PerceptionReport/reports/AppealReport/AppealReport";
import LsaReport from "../../../sections/PerceptionReport/reports/LsaReport/LsaReport";
import SurveyReport from "../../../sections/PerceptionReport/reports/SurveyReport/SurveyReport";
import { WithSurveyColumns } from "../../../sections/PerceptionReport/tables/TeamLeaderSummary/TeamLeaderSummary.api";
import AdditionalVolumeReport from "../../../sections/Production/sections/AdditionalVolumeReport/AdditionalVolumeReport";
import ProductionReport from "../../../sections/Production/sections/ProductionReport/ProductionReport";
import TWTReport from "../../../sections/TWT/TWTReport";
import UsageReport from "../../../sections/UsageReport/UsageReport";
import SubmittedForms from "../CoachingForms/SubmittedForms";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const Reports = () => {
    const path = useResolvedPath("").pathname;
    const permissions = usePermissions();

    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path={`/perception`} element={<PerceptionReport />} />
                    <Route
                        path={`/perception/mbr`}
                        element={<SurveyReport key={"mbr-survey-report"} survey={"mbr"} columns={WithSurveyColumns} />}
                    />
                    <Route
                        path={`/perception/fcr`}
                        element={<SurveyReport key={"fcr-survey-report"} survey={"fcr"} columns={WithSurveyColumns} />}
                    />
                    <Route path={`/perception/appeals`} element={<AppealReport />} />
                    <Route path={`/perception/lsa`} element={<LsaReport />} />
                    <Route path={`/usage`} element={<UsageReport />} />
                    <Route path={`/production`} element={<ProductionReport />} />
                    <Route path={`/twt-report`} element={<TWTReport />} />
                    <Route path={`/production/additional_volume`} element={<AdditionalVolumeReport />} />
                    <Route path={`/`} element={<Navigate to={`/perception`} />} />
                </Route>
            </Routes>
            <Outlet />
        </>
    );
};

export default Reports;
