import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import React, { useEffect } from "react";

type RefreshLoaderProps = {
	parentRef: React.RefObject<HTMLElement>;
	buttonContent?: any;
	onRefresh: () => void;
};

const RefreshLoader: React.FC<RefreshLoaderProps> = ({
	parentRef,
	buttonContent,
	onRefresh,
}: RefreshLoaderProps) => {
	useEffect(() => {}, [parentRef]);

	const handleRefreshClick = () => {
		onRefresh();
	};
	return (
		<Button fullWidth onClick={handleRefreshClick}>
			{buttonContent ?? <Refresh />}
		</Button>
	);
};

export default RefreshLoader;
