import { httpClient } from "../../clients/http.client";

const getAppeal = async (payload: { appeal_id: number }) => {
	const response = await httpClient.post("/appeal", payload);
	return response.data.data.appeal;
};

const getAppealDecision = async (payload: { appeal_id: number }) => {
	const response = await httpClient.post("/appeal/decision", payload);
	return response.data.data.decision;
};

export { getAppeal, getAppealDecision };
