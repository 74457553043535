import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BadgeCard, { BadgeCardProps } from "../../components/BadgeCard/BadgeCard";
import badgeCardStyles from "./Badges.styles";
import { gsap } from "gsap";
type BadgeVariant = "block" | "mini" | undefined;

type BadgeListProps = {
    badges: BadgeCardProps[];
    variant?: BadgeVariant;
    [propName: string]: any;
    onBadgeClick?: any;
    selectedBadge?: any;
    selectedBadgeId?: any;
};
const badgeVariantSize: { [key: string]: 1 | 2 } = {
    mini: 2,
    block: 2,
    default: 2,
};

const BadgeList = (props: BadgeListProps) => {
    const { badges, variant, onBadgeClick, selectedBadge, selectedBadgeId, ...other } = props;
    const classes = badgeCardStyles();
    return (
        <Grid container spacing={1} {...other}>
            {badges?.length > 0 ? (
                badges.map((badge, index) => (
                    <Grid
                        className="badge-hover"
                        item
                        sm={5}
                        style={{
                            border: selectedBadgeId?.id == badge?.id ? "1px #c6db34 solid" : "1px solid transparent",
                            borderRadius: 23,
                            flexBasis: 0,
                            flex: " 1 1 0",
                            minWidth: 280,
                            maxWidth: 250,
                            justifyContent: "center",
                        }}
                        key={`${badge.title}-${index}`}
                    >
                        <BadgeCard
                            onBad
                            avatarClicked={() => {
                                onBadgeClick(badge?.id);
                                gsap.fromTo(".badgeContent", { x: 600, ease: "power1.out" }, { x: 0, duration: 1 });
                            }}
                            avatarSize={classes.largeAvatar}
                            {...badge}
                            variant={variant == "mini" ? variant : "flat"}
                        />
                    </Grid>
                ))
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> No badges yet.</Typography>
                </Box>
            )}
        </Grid>
    );
};

export default BadgeList;
