import { httpClient } from "../../../../clients/http.client";

const updatePermissionUsers = (payload: {
	permission_id: number;
	grant_for_users: number[];
	revoke_for_users: number[];
}) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/permissions/users/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export { updatePermissionUsers };
