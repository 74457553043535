import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_SurveyPeriod } from "../../../Surveys/Surveys.types";

type Props = {
	periods: { [key: string]: t_SurveyPeriod };
};
const SurveyPeriods: React.FC<Props> = ({ periods }) => {
	return (
		<div>
		<SectionCard
			title='Survey Period'
			titleProps={{ variant: "subtitle1" }}
			fullWidth
		>
			<TableContainer component={Paper}>
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Survey Type</TableCell>
							<TableCell>Start Date</TableCell>
							<TableCell>End Date</TableCell>
							<TableCell>Last Capture</TableCell>
							<TableCell>Appeal Cut-Off</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.keys(periods).map((key: string) => (
							<TableRow key={`survey_period-${key}}`}>
								<TableCell>{key.toUpperCase()}</TableCell>
								<TableCell>{periods[key].start_date}</TableCell>
								<TableCell>{periods[key].end_date}</TableCell>
								<TableCell>
									{periods[key].last_capture}
								</TableCell>
								<TableCell>
									{periods[key].appeal_cutoff}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</SectionCard>
		<div>
			<form>

			</form>
		</div>
		</div>

	);
};
export default SurveyPeriods;
