import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import { useGetTeamMembersNotInThreadMutation, useUpdateUsersInThreadMutation } from "../../redux/api/beYouCore";

const AddMembers = ({ participants, setIsAddMember, activeChat, threadName }: any) => {
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [newMembers, setNewMembers] = useState<any>([]);

    const handleChange = (event: SelectChangeEvent<typeof selectedMembers>) => {
        const {
            target: { value },
        } = event;
        setSelectedMembers(typeof value === "string" ? value.split(",") : value);
    };

    const oldParticipants = participants.map((participant: any) => participant.EntityNo);

    const [getTeamMembersNotInThread] = useGetTeamMembersNotInThreadMutation();
    const [updateUsersInThread] = useUpdateUsersInThreadMutation();

    useEffect(() => {
        getTeamMembersNotInThread({ thread_id: activeChat })
            .unwrap()
            .then((response) => {
                const newMembersArray = Object.values(response.data.teamMembersNotInThread);
                setNewMembers(newMembersArray);
            });
    }, [activeChat]);

    const handleAddMembers = () => {
        const newParticipants = [...oldParticipants, ...selectedMembers];
        updateUsersInThread({
            participants: newParticipants,
            thread_id: activeChat,
        })
            .unwrap()
            .then((response) => {})
            .catch((error) => {
                console.error("🚀 ~ file: AddMembers.tsx:49 ~ updateUsersInThread ~ error:", error);
            })
            .finally(() => {
                setIsAddMember(false);
            });
    };
    return (
        <Grid container spacing={2} sx={{}}>
            <Grid item xs={12} mb={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <Typography fontWeight="bold" variant="h5">
                        New Members
                    </Typography>
                    <IconButton onClick={() => setIsAddMember(false)}>
                        <Close />
                    </IconButton>
                </Box>
                {/* <Divider /> */}
                <Box p={2}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <Select
                            value={selectedMembers}
                            multiple
                            input={<OutlinedInput label="Group Members" />}
                            onChange={handleChange}
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {newMembers.map((member: any) => (
                                <MenuItem key={member.EntityNo} value={member.EntityNo}>
                                    <Checkbox checked={selectedMembers.indexOf(member.EntityNo) > -1} />
                                    <ListItemText primary={member.Firstname + member.Surname} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={handleAddMembers}>
                        Add Members
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AddMembers;
