import { Avatar, Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { Icons, toast } from "react-toastify";
import { TimeTracker } from "../../components/Toasts/ToastTimeTracker";
import { Close, ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import bot from "../../beYou_bot.png";
import { gsap } from "gsap";
import { useDispatch } from "react-redux";
import { setRemoveTips } from "../../redux/slices/TipsSlice";

const useStyles = makeStyles((theme) => ({
    hover: {
        "&:hover": {
            backgroundColor: "#B8D200",
            color: "#fff",
        },
    },
}));

const CoachTipsCard = ({ tip, id }: any) => {
    const tipName = tip?.metrics?.filter((item: any) => item.MetricID === id);
    const [trancute, setTrancute] = useState<number | undefined>(60);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <Box
            style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
                paddingTop: "10px",
                marginBottom: "10px",
                backgroundColor: theme.palette.background.default,
                borderRadius: "1em",
                border: ".01px solid rgba(184, 210, 2,0.1)",
                position: "relative",
            }}
            width="100%"
            className={`text-${tip.CoachTipID}`}
        >
            <Box display="flex" alignItems="center" p={1}>
                <Avatar src={bot} style={{ width: 50, height: 50 }} className={`pic-${tip.CoachTipID}`} />
            </Box>
            <IconButton
                sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,

                    color: "#FF4040",
                    cursor: "pointer",
                }}
                onClick={() => dispatch(setRemoveTips(tipName[0].MetricID))}
            >
                <Close sx={{ fontSize: ".8rem" }} />
            </IconButton>

            <Box>
                <Typography variant="h6" style={{ fontSize: ".85rem", fontWeight: "bold" }}>
                    {tipName ? tipName[0]?.Name : "Metric"} Tip
                </Typography>
                {tip?.Description.length > 60 ? (
                    <Typography color="textSecondary" variant="body2" style={{ marginBottom: 4 }}>
                        {tip?.Description.slice(0, trancute)}
                        <>{trancute ? "..." : ""}</>{" "}
                        <span
                            style={{
                                position: "absolute",
                                bottom: 3,
                                right: 5,
                                border: ".8px solid lightgray",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: 2,
                            }}
                            className={classes.hover}
                        >
                            {trancute ? (
                                <ExpandMoreOutlined
                                    onClick={() => {
                                        setTrancute(undefined);
                                        gsap.to(`.text-${tip.CoachTipID}`, {
                                            height: "180px",
                                            ease: "power1.out",
                                            repeat: 1,
                                            yoyo: true,
                                        });
                                        gsap.fromTo(
                                            `.pic-${tip.CoachTipID}`,
                                            {
                                                rotation: 30,
                                                duration: 2,
                                                ease: "bounce.out",
                                            },
                                            {
                                                rotation: 0,
                                                duration: 2,
                                                ease: "bounce.out",
                                            },
                                        );
                                    }}
                                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                                />
                            ) : (
                                <ExpandLessOutlined
                                    onClick={() => {
                                        setTrancute(60);
                                        gsap.to(`.text-${tip.CoachTipID}`, { height: "auto", ease: "power1.out" });
                                    }}
                                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                                />
                            )}
                        </span>
                    </Typography>
                ) : (
                    <Typography color="textSecondary" variant="body2" style={{ marginBottom: 4 }}>
                        {tip?.Description}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default CoachTipsCard;
