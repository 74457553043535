import { Card, CardContent } from "@mui/material";

import MetricRatingTable from "./Tables/MetricRatingTable";
import MetricReviewTable from "./Tables/MetricReviewTable";
import MetricsTable from "./Tables/MetricsTable";
import PipOptionsTable from "./Tables/PipOptionsTable";
import PipPerformanceTable from "./Tables/PipPerformanceTable";
import ToImproveTable from "./Tables/ToImproveTable";

const CoachingFormTables = ({ tableType, combinedData, setCombinedData, viewMetrics }: any) => {
    switch (tableType) {
        case "metricTable":
            return (
                <MetricsTable data={combinedData.metrics} setCombinedData={setCombinedData} viewMetrics={viewMetrics} />
            );
        case "metricRatingTable":
            return (
                <MetricRatingTable
                    data={combinedData.metricRating}
                    setCombinedData={setCombinedData}
                    viewMetrics={viewMetrics}
                />
            );
        case "toImproveTable":
            return (
                <ToImproveTable
                    data={combinedData.toImprove}
                    setCombinedData={setCombinedData}
                    viewMetrics={viewMetrics}
                />
            );
        case "pipOptionsTable":
            return (
                <PipOptionsTable
                    data={combinedData.pipOptions}
                    setCombinedData={setCombinedData}
                    viewMetrics={viewMetrics}
                />
            );
        case "pipPerformanceTable":
            return (
                <PipPerformanceTable
                    data={combinedData.pipPerformance}
                    setCombinedData={setCombinedData}
                    viewMetrics={viewMetrics}
                />
            );
        case "metricReviewTable":
            return (
                <MetricReviewTable
                    data={combinedData.metricReview}
                    setCombinedData={setCombinedData}
                    viewMetrics={viewMetrics}
                />
            );
        default:
            return (
                <Card>
                    <CardContent>We struggled to find data for this metric.</CardContent>
                </Card>
            );
    }
};

export default CoachingFormTables;
