import { Grid } from "@mui/material";
import React from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import ChallengeHistory from "../../../sections/Challenge/sections/ChallengeHistory/ChallengeHistory";
import CurrentChallenge from "../../../sections/Challenge/sections/CurrentChallenge/CurrentChallenge";
import challengeStyles from "./Challenge.styles";

const Challenge: React.FC = () => {
	const classes = challengeStyles();
	const [sectionTab, setSectionTab] = React.useState(0);
	const [viewChallenge, setViewChallenge] = React.useState();

	return (
		<SectionCard
			title='Challenges'
			// primaryAction={
			// 	<TabGroup
			// 		tabValue={sectionTab}
			// 		tabChange={( newValue) => setSectionTab(newValue)}
			// 		tabList={[
			// 			{ _id: 0, label: "Weekly" },
			// 			{ _id: 1, label: "Friendly" },
			// 		]}
			// 	/>
			// }
			scrolling
		>
			<Grid container spacing={2} className={classes.gridItem}>
				<Grid item sm={7} className={classes.gridItem}>
					<CurrentChallenge />
				</Grid>
				<Grid item sm={5} className={classes.gridItem}>
					<ChallengeHistory
						challenge_type={
							(sectionTab === 0 && "weekly") ||
							(sectionTab === 1 && "friendly") ||
							undefined
						}
					/>
				</Grid>
			</Grid>
		</SectionCard>
	);
};

export default Challenge;
