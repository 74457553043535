import { Button, Grid } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { Options } from "material-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GroupedHeaderTable from "../../../../../components/GroupedHeaderTable/GroupedHeaderTable";
import SectionCard from "../../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../../components/TabGroup/TabGroup";
import { t_SurveyPeriod } from "../../../../Surveys/Surveys.types";
import SurveyTrendGraph from "../../../graphs/SurveyTrendGraph/SurveyTrendGraph";
import { SurveySummaryColumns } from "../../../PerceptionReport.api";
import { getSurveySummaryRecords } from "./PerceptionSurveySummery.api";

interface SurveySummaryProps {
  survey: "mbr" | "fcr";
  period: t_SurveyPeriod;
  month: string;
}

type SurveyRecord = {
  for_survey: string;
  survey_n: number;
  score_avg: number;
  lsa_n: number;
  approved_ratio: number;
  appeals_n: number;
  capture_ratio: number;
};

type SurveyRecordData = {
  survey: SurveyRecord[];
  service_team: Array<{ service_team: string } & SurveyRecord>;
  team: Array<{ team: string } & SurveyRecord>;
  job_title: Array<{ job_title: string } & SurveyRecord>;
};

const TableOptions: Options<any> = {
  padding: "dense",
  toolbar: false,
  pageSizeOptions: [5, 10, 20, 50, 100],
};

const getDefaultPageRows = (
  surveyRecords: SurveyRecordData,
  field: "service_team" | "team" | "job_title"
) => {
  let pgSize = 5;
  if (surveyRecords && surveyRecords[field] && surveyRecords[field].length > 50)
    pgSize = 20;

  return pgSize;
};

const PerceptionSurveySummary: React.FC<SurveySummaryProps> = ({
  survey,
  period,
  month,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [surveyRecords, setSurveyRecords] = useState<SurveyRecordData>({
    survey: [],
    service_team: [],
    team: [],
    job_title: [],
  });

  useEffect(() => {
    let isActive = true;
    getSurveySummaryRecords({ survey, period }).then(
      (data) => isActive && setSurveyRecords(data)
    );

    return () => {
      isActive = false;
    };
  }, [period]);
  return (
    <SectionCard
      title={survey.toUpperCase()}
      titleProps={{ variant: "subtitle1" }}
      primaryAction={
        <TabGroup
          tabValue={tabValue}
          tabChange={(value) => setTabValue(value)}
          tabList={[
            { _id: 0, label: "Summary" },
            { _id: 1, label: "Details" },
          ]}
        />
      }
      secondaryAction={
        <Button
          component={Link}
          to={`/reports/perception/${survey.toLowerCase()}`}
          variant="text"
          endIcon={<OpenInNew />}
        >
          Report
        </Button>
      }
      fullWidth
    >
      <Grid container spacing={2} style={{ paddingBottom: 16 }}>
        {tabValue == 0 && (
          <>
            <Grid item sm={12}>
              <SectionCard
                title={`DH ${survey.toUpperCase()}`}
                titleProps={{ variant: "subtitle2" }}
                fullWidth
                hasBackground
              >
                <SurveyTrendGraph survey={survey} month={month} />
              </SectionCard>
            </Grid>
            <Grid item sm={12}>
              <SectionCard
                title={`Summary`}
                titleProps={{ variant: "subtitle2" }}
                fullWidth
                hasBackground
              >
                <GroupedHeaderTable
                  columns={SurveySummaryColumns}
                  data={surveyRecords.survey}
                  options={{
                    ...TableOptions,
                    search: false,
                    paging: false,
                    sorting: false,
                    padding: "default",
                  }}
                />
              </SectionCard>
            </Grid>
          </>
        )}
        {tabValue == 1 && (
          <>
            <Grid item sm={12}>
              <SectionCard
                title={"Service Team"}
                titleProps={{ variant: "subtitle2" }}
                fullWidth
                hasBackground
              >
                <GroupedHeaderTable
                  columns={[
                    {
                      header: "Service Team",
                      field: "service_team",
                    },
                    ...SurveySummaryColumns,
                  ]}
                  data={surveyRecords.service_team}
                  options={{
                    ...TableOptions,
                    pageSize: getDefaultPageRows(surveyRecords, "service_team"),
                  }}
                />
              </SectionCard>
            </Grid>
            <Grid item sm={12}>
              <SectionCard
                title={"Team"}
                titleProps={{ variant: "subtitle2" }}
                fullWidth
                hasBackground
              >
                <GroupedHeaderTable
                  columns={[
                    {
                      header: "Team",
                      field: "team",
                    },
                    ...SurveySummaryColumns,
                  ]}
                  data={surveyRecords.team}
                  options={{
                    ...TableOptions,
                    pageSize: getDefaultPageRows(surveyRecords, "team"),
                  }}
                />
              </SectionCard>
            </Grid>
            <Grid item sm={12}>
              <SectionCard
                title={"Job Title"}
                titleProps={{ variant: "subtitle2" }}
                fullWidth
                hasBackground
              >
                <GroupedHeaderTable
                  columns={[
                    {
                      header: "Job Title",
                      field: "job_title",
                    },
                    ...SurveySummaryColumns,
                  ]}
                  data={surveyRecords.job_title}
                  options={{
                    ...TableOptions,
                    pageSize: getDefaultPageRows(surveyRecords, "job_title"),
                  }}
                />
              </SectionCard>
            </Grid>
          </>
        )}
      </Grid>
    </SectionCard>
  );
};

export default PerceptionSurveySummary;
