import { Grid } from "@mui/material";
import React from "react";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { useReportExport } from "../../../../hooks/export/useReportExport";
import { getApiUrl } from "../../../../misc/helpers";
import SurveyPeriodsMulti from "../../tables/SurveyPeriods/SurveyPeriodsMulti";
import { WithLsaColumns } from "../../tables/TeamLeaderSummary/TeamLeaderSummary.api";
import LsaSurveyGraph from "./graphs/LsaSurveyGraph/LsaSurveyGraph";
import LsaSummaryMulti from "./sections/LsaSummary/LsaSummaryMulti";
import useMultiDateSelect from "../../../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import TeamLeaderSummaryMulti, {
  secondaryTabOptions,
} from "../../tables/TeamLeaderSummary/TeamLeaderSummaryMulti";
import LsaFilterProvider, { filterCtx } from "./LsaFilterProvider";

const LsaReport: React.FC = () => {
  const {
    loading: exportLoading,
    downloadExportFile,
    downloadExportFileMulti,
  } = useReportExport();
  const [tab, setTab] = React.useState(0);
  const [secondaryTab, setSecondaryTab] = React.useState(0);

  const {
    datePills,
    yearAndMonthSelector,
    formattedPeriods,
    periodsArr,
    selectedDate,
  } = useMultiDateSelect();

  const { setFilterParam } = React.useContext(filterCtx);

  React.useEffect(() => setFilterParam({}), [periodsArr.length]);

  // const handleExport = (file_type: string) => {
  //   const lsa_url = new URL(`${getApiUrl()}/reports/lsa/export`);
  //   lsa_url.searchParams.append("month", selectedDate.toDateString());
  //   lsa_url.searchParams.append("file_type", file_type);

  //   downloadExportFile(lsa_url.toString(), "LSA Export");
  // };

  const handleExportMulti = (file_type: string) => {
    let reqPeriods: string[] = [];
    reqPeriods = periodsArr[Object.keys(periodsArr)[0]].map((period) => {
      if (period && period.month) {
        const [y, m, _] = period.month.split("-");
        return [y, m, "05"].join("-");
      } else return "";
    });

    const lsa_exp_url = new URL(`${getApiUrl()}/reports/lsa/export/multi`);
    lsa_exp_url.searchParams.append("months", reqPeriods.toString());
    lsa_exp_url.searchParams.append("file_type", file_type);
    lsa_exp_url.searchParams.append(
      "filter",
      secondaryTabOptions[secondaryTab]
    );

    downloadExportFileMulti(lsa_exp_url.toString(), "LSA Export");
  };

  return (
    <SectionCard
      title="LSA Report"
      primaryAction={
        <TabGroup
          tabValue={tab}
          tabChange={(value) => setTab(value)}
          tabList={[
            {
              _id: 0,
              label: "Summary",
            },
            {
              _id: 1,
              label: "Detailed",
            },
          ]}
        />
      }
      secondaryAction={yearAndMonthSelector}
      scrolling
      fullWidth
    >
      <Grid item xs={12}>
        {datePills}
      </Grid>

      <LsaFilterProvider>
        <Grid container spacing={2}>
          {tab === 0 && (
            <>
              <Grid item sm={8}>
                <SurveyPeriodsMulti periods={periodsArr} />
              </Grid>

              <Grid item sm={6}>
                <SectionCard
                  title="Primary Query"
                  titleProps={{ variant: "subtitle2" }}
                  hasBackground
                >
                  <LsaSurveyGraph
                    group_by="primary_query"
                    periods={periodsArr}
                  />
                </SectionCard>
              </Grid>
              <Grid item sm={6}>
                <SectionCard
                  title="Low Score Grouping"
                  titleProps={{ variant: "subtitle2" }}
                  hasBackground
                >
                  <LsaSurveyGraph
                    group_by="low_score_grouping"
                    periods={periodsArr}
                  />
                </SectionCard>
              </Grid>
              <Grid item sm={6}>
                <SectionCard
                  title="Action Taken"
                  titleProps={{ variant: "subtitle2" }}
                  hasBackground
                >
                  <LsaSurveyGraph
                    group_by="action_taken"
                    periods={periodsArr}
                  />
                </SectionCard>
              </Grid>
              <Grid item sm={12}>
                <LsaSummaryMulti
                  periods={periodsArr}
                  months={formattedPeriods}
                />
              </Grid>
            </>
          )}
          {tab === 1 && (
            <Grid item sm={12}>
              <TeamLeaderSummaryMulti
                periods={periodsArr}
                report="lsa"
                columns={WithLsaColumns}
                isLsa={true}
                secondaryTab={secondaryTab}
                setSecondaryTab={setSecondaryTab}
                sectionCardProps={{
                  secondaryAction: (
                    <ExportButton
                      dispatch={handleExportMulti}
                      loading={exportLoading}
                      disabled={exportLoading}
                    >
                      Export
                    </ExportButton>
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>
      </LsaFilterProvider>
    </SectionCard>
  );
};

export default LsaReport;
