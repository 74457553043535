import React, { ReactNode, createContext, useState } from "react";

type NotificationsCountType = {
    all: number;
    additional_volume: number;
    appeal: number;
    badges: number;
    downtime: number;
    friend_requests: number;
    lsa: number;
    prompts: number;
    summaries: number;
    [key: string]: number;
};

type NotificationsContextType = {
    notificationsCount: NotificationsCountType;
    setNotificationsCount: React.Dispatch<React.SetStateAction<NotificationsCountType>>;
    showNotification: boolean;
    setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
    showWeeklyPrompt: boolean;
    setWeeklyPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    showUnread: boolean;
    setUnread: React.Dispatch<React.SetStateAction<boolean>>;
    showUnreadMessage: boolean;
    setShowUnreadMessage: React.Dispatch<React.SetStateAction<boolean>>;
    showFeedBackModal: boolean;
    setShowFeedBackModal: React.Dispatch<React.SetStateAction<boolean>>;
    showSidePanelChatBot: boolean;
    setSidePanelChatBot: React.Dispatch<React.SetStateAction<boolean>>;
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    currentStepSideBar: number;
    setCurrentStepSideBar: React.Dispatch<React.SetStateAction<number>>;
    sideBarTab: number;
    setSideBarTab: React.Dispatch<React.SetStateAction<number>>;
    isOnBoardingOpen: boolean;
    setIsOnBoarding: React.Dispatch<React.SetStateAction<boolean>>;
    currentStepProduction: number;
    setCurrentStepProduction: React.Dispatch<React.SetStateAction<number>>;
    currentStepMaintainTWT: number;
    setCurrentStepMaintainTWT: React.Dispatch<React.SetStateAction<number>>;
    currentStepMaintainTWTMember: number;

    setCurrentStepMaintainTWTMember: React.Dispatch<React.SetStateAction<number>>;
    isOnBoardingOpenSideBar: boolean;
    setIsOnBoardingOpenSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    isTWTWalkthroughActive: boolean;
    setIsTWTWalkthroughActive: React.Dispatch<React.SetStateAction<boolean>>;
    isProductionActive: boolean;
    setIsProductionActive: React.Dispatch<React.SetStateAction<boolean>>;
    isSideTabWalkthroughFirstTab: boolean;
    setIsSideTabWalkthroughFirstTab: React.Dispatch<React.SetStateAction<boolean>>;
    isSideTabWalkthroughSecondTab: boolean;
    setIsSideTabWalkthroughSecondTab: React.Dispatch<React.SetStateAction<boolean>>;
    isSideTabWalkthroughThirdTab: boolean;
    setIsSideTabWalkthroughThirdTab: React.Dispatch<React.SetStateAction<boolean>>;
    isDowntimeModalActive: boolean;
    setIsDowntimeModalActive: React.Dispatch<React.SetStateAction<boolean>>;
    isAdditionalVolumeModalActive: boolean;
    setIsAdditionalVolumeeModalActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsContext = createContext<NotificationsContextType>({
    notificationsCount: {
        all: 0,
        additional_volume: 0,
        appeal: 0,
        badges: 0,
        downtime: 0,
        friend_requests: 0,
        lsa: 0,
        prompts: 0,
        summaries: 0,
    },
    setNotificationsCount: () => {},
    showNotification: false,
    setShowNotification: () => {},
    showWeeklyPrompt: false,
    setWeeklyPrompt: () => {},
    showUnread: true,
    setUnread: () => {},
    showFeedBackModal: false,
    setShowFeedBackModal: () => {},
    showSidePanelChatBot: false,
    setSidePanelChatBot: () => {},
    showUnreadMessage: false,
    setShowUnreadMessage: () => {},
    currentStep: 0,
    setCurrentStep: () => {},
    currentStepSideBar: 0,
    setCurrentStepSideBar: () => {},
    sideBarTab: 0,
    setSideBarTab: () => {},
    isOnBoardingOpen: false,
    setIsOnBoarding: () => {},
    currentStepProduction: 0,
    setCurrentStepProduction: () => {},
    currentStepMaintainTWT: 0,
    setCurrentStepMaintainTWT: () => {},
    currentStepMaintainTWTMember: 0,
    setCurrentStepMaintainTWTMember: () => {},
    isOnBoardingOpenSideBar: false,
    setIsOnBoardingOpenSideBar: () => {},
    isTWTWalkthroughActive: false,
    setIsTWTWalkthroughActive: () => {},
    isProductionActive: false,
    setIsProductionActive: () => {},
    isSideTabWalkthroughFirstTab: false,
    setIsSideTabWalkthroughFirstTab: () => {},
    isSideTabWalkthroughSecondTab: false,
    setIsSideTabWalkthroughSecondTab: () => {},
    isSideTabWalkthroughThirdTab: false,
    setIsSideTabWalkthroughThirdTab: () => {},
    isDowntimeModalActive: false,
    setIsDowntimeModalActive: () => {},
    isAdditionalVolumeModalActive: false,
    setIsAdditionalVolumeeModalActive: () => {},
});

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [notificationsCount, setNotificationsCount] = useState<NotificationsCountType>({
        all: 0,
        additional_volume: 0,
        appeal: 0,
        badges: 0,
        downtime: 0,
        friend_requests: 0,
        lsa: 0,
        prompts: 0,
        summaries: 0,
    });

    const [showNotification, setShowNotification] = useState(false);
    const [showWeeklyPrompt, setWeeklyPrompt] = useState(false);
    const [showUnread, setUnread] = useState(false);
    const [showFeedBackModal, setShowFeedBackModal] = useState(false);
    const [showSidePanelChatBot, setSidePanelChatBot] = useState(false);
    const [showUnreadMessage, setShowUnreadMessage] = useState(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [currentStepSideBar, setCurrentStepSideBar] = useState<number>(0);
    const [sideBarTab, setSideBarTab] = useState<number>(0);
    const [isOnBoardingOpen, setIsOnBoarding] = useState(false);
    const [currentStepProduction, setCurrentStepProduction] = useState<number>(0);
    const [currentStepMaintainTWT, setCurrentStepMaintainTWT] = useState<number>(0);
    const [currentStepMaintainTWTMember, setCurrentStepMaintainTWTMember] = useState<number>(0);
    const [isOnBoardingOpenSideBar, setIsOnBoardingOpenSideBar] = useState<boolean>(false);
    const [isTWTWalkthroughActive, setIsTWTWalkthroughActive] = useState<boolean>(false);
    const [isProductionActive, setIsProductionActive] = useState<boolean>(false);
    const [isSideTabWalkthroughFirstTab, setIsSideTabWalkthroughFirstTab] = useState<boolean>(false);
    const [isSideTabWalkthroughSecondTab, setIsSideTabWalkthroughSecondTab] = useState<boolean>(false);
    const [isSideTabWalkthroughThirdTab, setIsSideTabWalkthroughThirdTab] = useState<boolean>(false);
    const [isDowntimeModalActive, setIsDowntimeModalActive] = useState<boolean>(false);
    const [isAdditionalVolumeModalActive, setIsAdditionalVolumeeModalActive] = useState<boolean>(false);
    return (
        <NotificationsContext.Provider
            value={{
                notificationsCount,
                setNotificationsCount,
                showNotification,
                setShowNotification,
                showWeeklyPrompt,
                setWeeklyPrompt,
                showUnread,
                setUnread,
                setShowFeedBackModal,
                showFeedBackModal,
                showSidePanelChatBot,
                setSidePanelChatBot,
                showUnreadMessage,
                setShowUnreadMessage,
                currentStep,
                setCurrentStep,
                currentStepSideBar,
                setCurrentStepSideBar,
                sideBarTab,
                setSideBarTab,
                isOnBoardingOpen,
                setIsOnBoarding,
                currentStepProduction,
                setCurrentStepProduction,
                currentStepMaintainTWT,
                setCurrentStepMaintainTWT,
                currentStepMaintainTWTMember,
                setCurrentStepMaintainTWTMember,
                isOnBoardingOpenSideBar,
                setIsOnBoardingOpenSideBar,
                isTWTWalkthroughActive,
                setIsTWTWalkthroughActive,
                isProductionActive,
                setIsProductionActive,
                isSideTabWalkthroughFirstTab,
                setIsSideTabWalkthroughFirstTab,
                isSideTabWalkthroughSecondTab,
                setIsSideTabWalkthroughSecondTab,
                isSideTabWalkthroughThirdTab,
                setIsSideTabWalkthroughThirdTab,
                isDowntimeModalActive,
                setIsDowntimeModalActive,
                isAdditionalVolumeModalActive,
                setIsAdditionalVolumeeModalActive,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};
