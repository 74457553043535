import { Box, Grid, List, ListItemText, Typography } from "@mui/material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import { formatApiDate } from "../../../../misc/helpers";
import SurveyMetricRecord from "../../../Surveys/components/SurveyMetricRecord/SurveyMetricRecord";
import { t_MetricSurveyRecord } from "../../../Surveys/tables/MetricSurveyRecords/MetricSurveyRecords.types";
import AppealDecision from "../../components/AppealDecision/AppealDecision";
import { t_AppealDecisionActions } from "../../components/AppealDecision/AppealDecision.types";
import AppealRecord from "../../components/AppealRecord/AppealRecord";
import AppealStepper from "../../components/AppealStepper/AppeallStepper";
import { t_AppealStepperActions } from "../../components/AppealStepper/AppealStepper.types";
import appealDialogContentStyles from "./AppealDialogContent.styles";
type MetricAppeal = {
	appeal_data_id: number;
	data_id: number;
	expire_date: string;
	hasExpired: true;
	status_id: number;
};
interface AppealDialogProps {
	appeal_id?: number;
	metric_id: number;
	record?: t_MetricSurveyRecord;
	data_id: number;
	can_submit?: boolean;
	appeal_date?: string;
	expire_date?: string;
	dispatch: (reason?: string) => void;
}
const AppealDialogContent: React.FC<AppealDialogProps> = ({
	appeal_id,
	metric_id,
	record,
	data_id,
	can_submit,
	appeal_date,
	expire_date,
	dispatch,
}) => {
	const permissions = usePermissions();
	const classes = appealDialogContentStyles();
	const [appealID, setAppealID] = React.useState(appeal_id);
	const [detailsTab, setDetailTab] = React.useState<number>(0);

	const handleAppealDispatch = (
		action?: t_AppealStepperActions | t_AppealDecisionActions
	) => {
		switch (action?.type) {
			case "appeal-created":
				setAppealID(action.appeal_id);
				dispatch("update");
				break;
			case "appeal-updated":
			case "decision-updated":
				dispatch("update");
				break;
		}
	};

	return (
		<Grid container spacing={2} className={classes.container}>
			{permissions.appeal_create && can_submit === true ? (
				<Grid item sm className={classes.item}>
					<AppealStepper
						appeal_id={appealID}
						metric_id={metric_id}
						data_id={data_id}
						dispatch={handleAppealDispatch}
					/>
				</Grid>
			) : (
				<Grid item sm className={classes.item}>
					<SectionCard
						noHeader
						hasBackground
						scrolling
						bottomActions={
							expire_date != null && (
								<Typography
									variant='caption'
									style={{ paddingLeft: 20 }}
								>
									Expired: {expire_date}
								</Typography>
							)
						}
					>
						<AppealRecord appeal_id={appealID} />
					</SectionCard>
				</Grid>
			)}

			<Grid item sm className={classes.item}>
				<SectionCard
					fullWidth={detailsTab === 1}
					hasBackground
					title={!permissions.appeal_approve && "Details"}
					primaryAction={
						permissions.appeal_approve && (
							<TabGroup
								tabValue={detailsTab}
								tabChange={(value) => setDetailTab(value)}
								tabList={[
									{ _id: 0, label: "Details" },
									{ _id: 1, label: "Decision" },
								]}
							/>
						)
					}
				>
					{detailsTab === 0 && (
						<Box width={400}>
							{record != null && (
								<SurveyMetricRecord record={record} />
							)}
							<List>
								<ListItemText
									secondary={formatApiDate(appeal_date)}
									primary='Appeal Capture Date'
								/>
								<ListItemText
									secondary={formatApiDate(expire_date)}
									primary='Appeal Expiry Date'
								/>
							</List>
						</Box>
					)}
					{detailsTab === 1 && (
						<AppealDecision
							appeal_id={appealID}
							dispatch={handleAppealDispatch}
						/>
					)}
				</SectionCard>
			</Grid>
		</Grid>
	);
};

export type { MetricAppeal };
export default AppealDialogContent;
