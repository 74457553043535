import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const lsaStepperStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: "100%",
		},
		actionsContainer: {
			justifyContent: "space-around",
			display: "flex",
			gap: "15px",
			width: "100%",
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
		formControl: {
			width: 300,
		},
		autoComplete: {
			width: 300,
		},
	})
);

const lsaAutocompleteStyles = makeStyles((theme: Theme) =>
	createStyles({
		autoComplete: {
			width: 300,
		},
	})
);

export { lsaAutocompleteStyles };
export default lsaStepperStyles;
