import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { NotificationsContext } from "../../components/Toasts/NotificationsProvider.ctx";
import { badges, metric_values, performance_summary, team_metrics } from "../../pages/Home/BeYouCoach/WalkThroughData";
import {
    useAllBadgesMutation,
    useAllMetricsMutation,
    useGetNotificationsMutation,
    useGetSummaryMutation,
    useGetTeamPerformanceMutation,
} from "../../redux/api/beYouCore";
import { setAllBadges } from "../../redux/slices/BadgesSlice";
import { setBeYouLoading } from "../../redux/slices/BeYouSlice";
import { setAllMetrics } from "../../redux/slices/MetricsSlice";
import { setAllSummaries, setPagination } from "../../redux/slices/SummarySlice";
import { setAllTeamMembers } from "../../redux/slices/TeamMemberSlice";

const useDataFetching = (entity_no: number, month: string, comparison_month: string, pagination: any, sort: any) => {
    const dispatch = useDispatch();
    const { setNotificationsCount, notificationsCount } = useContext(NotificationsContext);

    //Badges
    const [allBadges] = useAllBadgesMutation();
    //Metrics
    const [allMetrics] = useAllMetricsMutation();
    //Summaries
    const [getSummary] = useGetSummaryMutation();
    //Team Members
    const [getTeamPerformance] = useGetTeamPerformanceMutation();

    const [getNotifications] = useGetNotificationsMutation();

    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        dispatch(setBeYouLoading(true));
        if (entity_no && month) {
            const promises = [
                allMetrics({ month, comparison_month, entity_no, incentive: true })
                    .unwrap()
                    .then((data) => {
                        dispatch(setAllMetrics(data.data.metric_values));
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    }),
                getSummary({ entity_no, pagination, sort })
                    .unwrap()
                    .then((data) => {
                        const summaries = data.data.response.data;
                        dispatch(setAllSummaries(summaries));
                        const newPagination = {
                            page: data.data.response.current_page,
                            rows_per_page: data.data.response.per_page,
                            count: data.data.response.last_page,
                        };

                        dispatch(setPagination(newPagination));
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    }),
                allBadges({ entity_no })
                    .unwrap()
                    .then((data) => {
                        dispatch(setAllBadges(data.data.badges));
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    }),
                getTeamPerformance({ month, comparison_month, entity_no, incentive: false })
                    .unwrap()
                    .then((data) => {
                        dispatch(setAllTeamMembers(data.data.team_metrics));
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    }),
                getNotifications()
                    .unwrap()
                    .then((response) => {
                        const notifications = response.data.notifications;
                        const prompts = notifications.prompts;

                        const newCount = Number(
                            Object.values(notifications).reduce((acc, val: any) => acc + val.length, 0),
                        );

                        setNotificationsCount({
                            ...notificationsCount,
                            all: newCount,
                            additional_volume: notifications.additional_volume.length,
                            appeal: notifications.appeal.length,
                            badges: notifications.badges.length,
                            downtime: notifications.downtime.length,
                            friend_requests: notifications.friend_requests.length,
                            lsa: notifications.lsa.length,
                            prompts: prompts.length,
                            summaries: notifications.summaries.length,
                        });
                    })
                    .catch((error) => {
                        console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
                    }),
            ];

            Promise.all(promises)
                .then(() => {})
                .finally(() => {
                    setLoading(false);
                    dispatch(setBeYouLoading(false));
                });
        }
    }, [entity_no, month]);

    const fetchDataWalkThrough = useCallback(() => {
        setLoading(true);
        if (entity_no && month) {
            dispatch(setAllMetrics(metric_values));
            dispatch(setAllSummaries(performance_summary));
            //@ts-ignore
            dispatch(setAllBadges(badges));
            //@ts-ignore
            dispatch(setAllTeamMembers(team_metrics));
            setLoading(false);
        }
    }, [entity_no, month]);

    return { loading, fetchData, fetchDataWalkThrough };
};

export default useDataFetching;
