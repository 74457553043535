import { ClotheColorOption } from "./avatar/clothes/Colors";
import { FacialHairColorOption } from "./avatar/top/facialHair/FacialHairColors";
import { HairColorOptionType } from "./avatar/top/HairColor";
import { HatColorOptionType } from "./avatar/top/HatColor";
import Eyepatch from "./ovationImages/accessories/Eyepatch.svg";
import Blank from "./ovationImages/accessories/glasses/Blank.svg";
import Kurt from "./ovationImages/accessories/glasses/Kurt.svg";
import Prescription01 from "./ovationImages/accessories/glasses/Prescription-01.svg";
import Prescription02 from "./ovationImages/accessories/glasses/Prescription-02.svg";
import Round from "./ovationImages/accessories/glasses/Round.svg";
import Sunglasses from "./ovationImages/accessories/glasses/Sunglasses.svg";
import Wayfarers from "./ovationImages/accessories/glasses/Wayfarers.svg";
import Hat from "./ovationImages/accessories/Hat.svg";
import Hijab from "./ovationImages/accessories/Hijab.svg";
import Turban from "./ovationImages/accessories/Turban.svg";
import WinterHat1 from "./ovationImages/accessories/Winter-Hat-1.svg";
import WinterHat2 from "./ovationImages/accessories/Winter-Hat-2.svg";
import WinterHat3 from "./ovationImages/accessories/Winter-Hat-3.svg";
import WinterHat4 from "./ovationImages/accessories/Winter-Hat-4.svg";
import BlazerShirt from "./ovationImages/clothing/Blazer-Shirt.svg";
import BlazerSweater from "./ovationImages/clothing/Blazer-Sweater.svg";
import CollarSweater from "./ovationImages/clothing/Collar-Sweater.svg";
import GraphicShirt from "./ovationImages/clothing/Graphic-Shirt.svg";
import Hoodie from "./ovationImages/clothing/Hoodie.svg";
import Overall from "./ovationImages/clothing/Overall.svg";
import ShirtCrewNeck from "./ovationImages/clothing/Shirt-Crew-Neck.svg";
import ShirtSCoopNeck from "./ovationImages/clothing/Shirt-Scoop-Neck.svg";
import ShirtVNeck from "./ovationImages/clothing/Shirt-V-Neck.svg";
import AngryNatural from "./ovationImages/eyebrows/Angry-Natural.svg";
import Angry from "./ovationImages/eyebrows/Angry.svg";
import DefaultNatural from "./ovationImages/eyebrows/Default-Natural.svg";
import EyebrowDefault from "./ovationImages/eyebrows/Default.svg";
import FlatNatural from "./ovationImages/eyebrows/Flat-Natural.svg";
import FrownNatural from "./ovationImages/eyebrows/Frown-Natural.svg";
import RaisedExcitedNatural from "./ovationImages/eyebrows/Raised-Excited-Natural.svg";
import RaisedExcited from "./ovationImages/eyebrows/Raised-Excited.svg";
import SadConcernedNatural from "./ovationImages/eyebrows/Sad-Concerned-Natural.svg";
import SadConcerned from "./ovationImages/eyebrows/Sad-Concerned.svg";
import UnibrowNatural from "./ovationImages/eyebrows/Unibrow-Natural.svg";
import UpDownNatural from "./ovationImages/eyebrows/Up-Down-Natural.svg";
import UpDown from "./ovationImages/eyebrows/Up-Down.svg";
import Closed from "./ovationImages/eyes/Closed.svg";
import Cry from "./ovationImages/eyes/Cry.svg";
import EyesDefault from "./ovationImages/eyes/Default.svg";
import EyeRoll from "./ovationImages/eyes/Eye-Roll.svg";
import Happy from "./ovationImages/eyes/Happy.svg";
import Hearts from "./ovationImages/eyes/Hearts.svg";
import Side from "./ovationImages/eyes/Side.svg";
import Squint from "./ovationImages/eyes/Squint.svg";
import Surprised from "./ovationImages/eyes/Surprised.svg";
import WinkWacky from "./ovationImages/eyes/Wink-Wacky.svg";
import Wink from "./ovationImages/eyes/Wink.svg";
import XDizzy from "./ovationImages/eyes/X-Dizzy.svg";
import BigHair from "./ovationImages/hair/Big-Hair.svg";
import Bob from "./ovationImages/hair/Bob.svg";
import Bun from "./ovationImages/hair/Bun.svg";
import Curly from "./ovationImages/hair/Curly.svg";
import Curvy from "./ovationImages/hair/Curvy.svg";
import Dreads01 from "./ovationImages/hair/Dreads-01.svg";
import Dreads02 from "./ovationImages/hair/Dreads-02.svg";
import Dreads from "./ovationImages/hair/Dreads.svg";
import Frida from "./ovationImages/hair/Frida.svg";
import Frizzle from "./ovationImages/hair/Frizzle.svg";
import FroBand from "./ovationImages/hair/Fro-Band.svg";
import Fro from "./ovationImages/hair/Fro.svg";
import LongButNotTooLong from "./ovationImages/hair/Long-but-not-too-long.svg";
import MiaWallace from "./ovationImages/hair/Mia-Wallace.svg";
import ShaggyMullet from "./ovationImages/hair/Shaggy-Mullet.svg";
import ShavedSides from "./ovationImages/hair/Shaved-Sides.svg";
import ShortCurly from "./ovationImages/hair/Short-Curly.svg";
import ShortFlat from "./ovationImages/hair/Short-Flat.svg";
import ShortRound from "./ovationImages/hair/Short-Round.svg";
import ShortWaved from "./ovationImages/hair/Short-Waved.svg";
import Sides from "./ovationImages/hair/Sides.svg";
import Straight1 from "./ovationImages/hair/Straight-1.svg";
import StraightStrand from "./ovationImages/hair/Straight-Strand.svg";
import Straight from "./ovationImages/hair/Straight.svg";
import TheCaesarSidePart from "./ovationImages/hair/The-Caesar-Side-Part.svg";
import TheCaesar from "./ovationImages/hair/The-Caesar.svg";
import Concerned from "./ovationImages/mouths/Concerned.svg";
import MouthDefault from "./ovationImages/mouths/Default.svg";
import Disbelief from "./ovationImages/mouths/Disbelief.svg";
import Eating from "./ovationImages/mouths/Eating.svg";
import Grimace from "./ovationImages/mouths/Grimace.svg";
import Sad from "./ovationImages/mouths/Sad.svg";
import Scream from "./ovationImages/mouths/Scream-Open.svg";
import Serious from "./ovationImages/mouths/Serious.svg";
import Smile from "./ovationImages/mouths/Smile.svg";
import Tongue from "./ovationImages/mouths/Tongue.svg";
import Twinkle from "./ovationImages/mouths/Twinkle.svg";
import Vomit from "./ovationImages/mouths/Vomit.svg";
import Accessories from "./ovationImages/ovation-avatar-accessories-menu.svg";
import Background from "./ovationImages/ovation-avatar-background-menu.svg";
import Clothes from "./ovationImages/ovation-avatar-clothes-menu.svg";
import Eye from "./ovationImages/ovation-avatar-eye-menu.svg";
import Eyebrow from "./ovationImages/ovation-avatar-eyebrow-menu.svg";
import FacialHair from "./ovationImages/ovation-avatar-facialhair-menu.svg";
import Hair from "./ovationImages/ovation-avatar-hair-menu.svg";
import Mouth from "./ovationImages/ovation-avatar-mouth-menu.svg";
import Skin from "./ovationImages/ovation-avatar-skintone-menu.svg";

export interface CustomizationOption {
    id: number;
    title: string;
    icon?: string;
    color?: string;
    type: string;
    isAdmin?: boolean;
    unlocked?: boolean;
}

export const background: CustomizationOption[] = [
    { id: 1, title: "Tanned", color: "#FD9841", type: "backgroundColor" },
    // { id: 1, title: "Ocean Blue", color: "#1E90FF", type: "background" },
    // { id: 2, title: "Forest Green", color: "#228B22", type: "background" },
    // { id: 3, title: "Sunset Orange", color: "#FF4500", type: "background" },
    // { id: 4, title: "Lavender Purple", color: "#9370DB", type: "background" },
    // { id: 5, title: "Rose Red", color: "#FF007F", type: "background" },
    // { id: 6, title: "Midnight Black", color: "#2F4F4F", type: "background" },
    // { id: 7, title: "Sunshine Yellow", color: "#FFD700", type: "background" },
    // { id: 8, title: "Mint Green", color: "#98FF98", type: "background" },
    // { id: 9, title: "Coral Pink", color: "#FF7F50", type: "background" },
    // { id: 10, title: "Sky Blue", color: "#87CEEB", type: "background" },
    // { id: 11, title: "Slate Gray", color: "#708090", type: "background" },
    // { id: 12, title: "Cream White", color: "#FFFDD0", type: "background" },
];

export const skintone: CustomizationOption[] = [
    { id: 1, title: "Tanned", color: "#FD9841", type: "skintone" },
    { id: 2, title: "Yellow", color: "#F8D25C", type: "skintone" },
    { id: 3, title: "Pale", color: "#FFDBB4", type: "skintone" },
    { id: 4, title: "Light", color: "#EDB98A", type: "skintone" },
    { id: 5, title: "Brown", color: "#D08B5B", type: "skintone" },
    { id: 6, title: "DarkBrown", color: "#AE5D29", type: "skintone" },
    { id: 7, title: "Black", color: "#614335", type: "skintone" },
    { id: 8, title: "Ivory", color: "#FFDFC4", type: "skintone" },
    { id: 9, title: "Beige", color: "#F0D5BE", type: "skintone" },
    { id: 10, title: "Peach", color: "#E1A95F", type: "skintone" },
    { id: 11, title: "Sand", color: "#E4B98E", type: "skintone" },
    { id: 12, title: "Honey", color: "#D9A877", type: "skintone" },
    { id: 13, title: "Amber", color: "#D2A065", type: "skintone" },
    { id: 14, title: "Bronze", color: "#B97A56", type: "skintone" },
    { id: 15, title: "Caramel", color: "#A5694F", type: "skintone" },
    { id: 16, title: "Cocoa", color: "#8D5524", type: "skintone" },
    { id: 17, title: "Chestnut", color: "#5D4037", type: "skintone" },
    { id: 18, title: "Mocha", color: "#4E342E", type: "skintone" },
    { id: 19, title: "Espresso", color: "#3B2C2C", type: "skintone" },
    { id: 20, title: "Ebony", color: "#1C0D0D", type: "skintone" },
];

export const hat: CustomizationOption[] = [
    { id: 1, title: "Hat", icon: Hat, type: "hat" },
    { id: 2, title: "Hijab", icon: Hijab, type: "hat" },
    { id: 3, title: "Turban", icon: Turban, type: "hat" },
    { id: 4, title: "WinterHat1", icon: WinterHat1, type: "hat" },
    { id: 5, title: "WinterHat2", icon: WinterHat2, type: "hat" },
    { id: 6, title: "WinterHat3", icon: WinterHat3, type: "hat" },
    { id: 7, title: "WinterHat4", icon: WinterHat4, type: "hat" },
    { id: 8, title: "Eyepatch", icon: Eyepatch, type: "top" },
];

export const hair: CustomizationOption[] = [
    { id: 1, title: "NoHair", icon: "", type: "top" },
    { id: 2, title: "LongHairBigHair", icon: BigHair, type: "top" },
    { id: 3, title: "LongHairBob", icon: Bob, type: "top" },
    { id: 4, title: "LongHairBun", icon: Bun, type: "top" },
    { id: 5, title: "LongHairCurly", icon: Curly, type: "top" },
    { id: 6, title: "LongHairCurvy", icon: Curvy, type: "top" },
    { id: 7, title: "LongHairDreads", icon: Dreads, type: "top" },
    { id: 8, title: "LongHairFrida", icon: Frida, type: "top" },
    { id: 9, title: "LongHairFro", icon: Fro, type: "top" },
    { id: 10, title: "LongHairFroBand", icon: FroBand, type: "top" },
    { id: 11, title: "LongHairNotTooLong", icon: LongButNotTooLong, type: "top" },
    { id: 12, title: "LongHairShavedSides", icon: ShavedSides, type: "top" },
    { id: 13, title: "LongHairMiaWallace", icon: MiaWallace, type: "top" },
    { id: 14, title: "LongHairStraight", icon: Straight, type: "top" },
    { id: 15, title: "LongHairStraight2", icon: Straight1, type: "top" },
    { id: 16, title: "LongHairStraightStrand", icon: StraightStrand, type: "top" },
    { id: 17, title: "ShortHairDreads01", icon: Dreads01, type: "top" },
    { id: 18, title: "ShortHairDreads02", icon: Dreads02, type: "top" },
    { id: 19, title: "ShortHairFrizzle", icon: Frizzle, type: "top" },
    { id: 20, title: "ShortHairShaggyMullet", icon: ShaggyMullet, type: "top" },
    { id: 21, title: "ShortHairShortCurly", icon: ShortCurly, type: "top" },
    { id: 22, title: "ShortHairShortFlat", icon: ShortFlat, type: "top" },
    { id: 23, title: "ShortHairShortRound", icon: ShortRound, type: "top" },
    { id: 24, title: "ShortHairShortWaved", icon: ShortWaved, type: "top" },
    { id: 25, title: "ShortHairSides", icon: Sides, type: "top" },
    { id: 26, title: "ShortHairTheCaesar", icon: TheCaesar, type: "top" },
    { id: 27, title: "ShortHairTheCaesarSidePart", icon: TheCaesarSidePart, type: "top" },
];

export const accessories: CustomizationOption[] = [
    { id: 1, title: "Blank", icon: Blank, type: "accessories" },
    { id: 2, title: "Kurt", icon: Kurt, type: "accessories" },
    { id: 3, title: "Prescription01", icon: Prescription01, type: "accessories" },
    { id: 4, title: "Prescription02", icon: Prescription02, type: "accessories" },
    { id: 5, title: "Round", icon: Round, type: "accessories" },
    { id: 6, title: "Sunglasses", icon: Sunglasses, type: "accessories" },
    { id: 7, title: "Wayfarers", icon: Wayfarers, type: "accessories" },
];

export const facialhair: CustomizationOption[] = [
    { id: 1, title: "Blank", icon: "", type: "facialHair" },
    { id: 2, title: "BeardLight", icon: "", type: "facialHair" },
    { id: 3, title: "BeardMajestic", icon: "", type: "facialHair" },
    { id: 4, title: "BeardMedium", icon: "", type: "facialHair" },
    { id: 5, title: "MoustacheFancy", icon: "", type: "facialHair" },
    { id: 6, title: "MoustacheMagnum", icon: "", type: "facialHair" },
];

export const mouth: CustomizationOption[] = [
    { id: 1, title: "Concerned", icon: Concerned, type: "mouth" },
    { id: 2, title: "Default", icon: MouthDefault, type: "mouth" },
    { id: 3, title: "Disbelief", icon: Disbelief, type: "mouth" },
    { id: 4, title: "Eating", icon: Eating, type: "mouth" },
    { id: 5, title: "Grimace", icon: Grimace, type: "mouth" },
    { id: 6, title: "Sad", icon: Sad, type: "mouth" },
    { id: 7, title: "ScreamOpen", icon: Scream, type: "mouth" },
    { id: 8, title: "Serious", icon: Serious, type: "mouth" },
    { id: 9, title: "Smile", icon: Smile, type: "mouth" },
    { id: 10, title: "Tongue", icon: Tongue, type: "mouth" },
    { id: 11, title: "Twinkle", icon: Twinkle, type: "mouth" },
    { id: 12, title: "Vomit", icon: Vomit, type: "mouth" },
];

export const eyes: CustomizationOption[] = [
    { id: 1, title: "Close", icon: Closed, type: "eyes" },
    { id: 2, title: "Cry", icon: Cry, type: "eyes" },
    { id: 3, title: "Default", icon: EyesDefault, type: "eyes" },
    { id: 4, title: "Dizzy", icon: XDizzy, type: "eyes" },
    { id: 5, title: "EyeRoll", icon: EyeRoll, type: "eyes" },
    { id: 6, title: "Happy", icon: Happy, type: "eyes" },
    { id: 7, title: "Hearts", icon: Hearts, type: "eyes" },
    { id: 8, title: "Side", icon: Side, type: "eyes" },
    { id: 9, title: "Squint", icon: Squint, type: "eyes" },
    { id: 10, title: "Surprised", icon: Surprised, type: "eyes" },
    { id: 11, title: "Wink", icon: Wink, type: "eyes" },
    { id: 12, title: "WinkWacky", icon: WinkWacky, type: "eyes" },
];

export const eyebrows: CustomizationOption[] = [
    { id: 1, title: "Angry", icon: Angry, type: "eyebrows" },
    { id: 2, title: "AngryNatural", icon: AngryNatural, type: "eyebrows" },
    { id: 3, title: "Default", icon: EyebrowDefault, type: "eyebrows" },
    { id: 4, title: "DefaultNatural", icon: DefaultNatural, type: "eyebrows" },
    { id: 5, title: "FlatNatural", icon: FlatNatural, type: "eyebrows" },
    { id: 6, title: "RaisedExcited", icon: RaisedExcited, type: "eyebrows" },
    { id: 7, title: "RaisedExcitedNatural", icon: RaisedExcitedNatural, type: "eyebrows" },
    { id: 8, title: "SadConcerned", icon: SadConcerned, type: "eyebrows" },
    { id: 9, title: "SadConcernedNatural", icon: SadConcernedNatural, type: "eyebrows" },
    { id: 10, title: "UnibrowNatural", icon: UnibrowNatural, type: "eyebrows" },
    { id: 11, title: "UpDown", icon: UpDown, type: "eyebrows" },
    { id: 12, title: "UpDownNatural", icon: UpDownNatural, type: "eyebrows" },
    { id: 13, title: "FrownNatural", icon: FrownNatural, type: "eyebrows" },
];

export const shirt: CustomizationOption[] = [
    { id: 1, title: "BlazerShirt", icon: BlazerShirt, type: "shirt" },
    { id: 2, title: "BlazerSweater", icon: BlazerSweater, type: "shirt" },
    { id: 3, title: "CollarSweater", icon: CollarSweater, type: "shirt" },
    { id: 4, title: "GraphicShirt", icon: GraphicShirt, type: "shirt" },
    { id: 5, title: "Hoodie", icon: Hoodie, type: "shirt" },
    { id: 6, title: "Overall", icon: Overall, type: "shirt" },
    { id: 7, title: "ShirtCrewNeck", icon: ShirtCrewNeck, type: "shirt" },
    { id: 8, title: "ShirtScoopNeck", icon: ShirtSCoopNeck, type: "shirt" },
    { id: 9, title: "ShirtVNeck", icon: ShirtVNeck, type: "shirt" },
];

export const customiseAvatarData: CustomizationOption[] = [
    {
        id: 1,
        title: "Background",
        icon: "Background",
        type: "background",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 2,
        title: "Skintone",
        icon: "Skin",
        type: "skintone",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 3,
        title: "Hair",
        icon: "Hair",
        type: "top",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 4,
        title: "Accessories",
        icon: "Accessories",
        type: "accessories",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 5,
        title: "Mouth",
        icon: "Mouth",
        type: "mouth",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 6,
        title: "Eyes",
        icon: "Eye",
        type: "eyes",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 7,
        title: "Eyebrows",
        icon: "Eyebrow",
        type: "eyebrows",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 8,
        title: "Shirt",
        icon: "Clothes",
        type: "shirt",
        isAdmin: true,
        unlocked: true,
    },
    {
        id: 9,
        title: "FacialHair",
        icon: "FacialHair",
        type: "facialhair",
        isAdmin: true,
        unlocked: true,
    },

    {
        id: 10,
        title: "Hat",
        icon: "Hat",
        type: "hat",
        isAdmin: true,
        unlocked: true,
    },
];

export const hairColorOptions: HairColorOptionType[] = [
    "Auburn",
    "Black",
    "Blonde",
    "BlondeGolden",
    "Brown",
    "BrownDark",
    "PastelPink",
    "Blue",
    "Platinum",
    "Red",
    "SilverGray",
    "default",
];

export const facialHairColorOptions: FacialHairColorOption[] = [
    "Auburn",
    "Black",
    "Blonde",
    "BlondeGolden",
    "Brown",
    "BrownDark",
    "Platinum",
    "Red",
    "default",
];

export const hatColorOptions: HatColorOptionType[] = [
    "Black",
    "Blue01",
    "Blue02",
    "Blue03",
    "Gray01",
    "Gray02",
    "Heather",
    "PastelBlue",
    "PastelGreen",
    "PastelOrange",
    "PastelRed",
    "PastelYellow",
    "Pink",
    "Red",
    "White",
    "default",
];

export const clotheColorOptions: ClotheColorOption[] = [
    "Black",
    "Blue01",
    "Blue02",
    "Blue03",
    "Gray01",
    "Gray02",
    "Heather",
    "PastelBlue",
    "PastelGreen",
    "PastelOrange",
    "PastelRed",
    "PastelYellow",
    "Pink",
    "Red",
    "White",
    "default",
];

export const OvationLeaderboardColumns = [
    {
        title: "Name",
        field: "name",
    },
    {
        title: "Job Title",
        field: "role",
    },
    {
        title: "Ovations",
        field: "ovations",
    },

    {
        title: "Rank",
        field: "rank",
    },
];

export const iconMapping: Record<string, string> = {
    Eyepatch,
    Blank,
    Kurt,
    Prescription01,
    Prescription02,
    Round,
    Sunglasses,
    Wayfarers,
    Hat,
    Hijab,
    Turban,
    WinterHat1,
    WinterHat2,
    WinterHat3,
    WinterHat4,
    BlazerShirt,
    BlazerSweater,
    CollarSweater,
    GraphicShirt,
    Hoodie,
    Overall,
    ShirtCrewNeck,
    ShirtSCoopNeck,
    ShirtVNeck,
    AngryNatural,
    Angry,
    DefaultNatural,
    EyebrowDefault,
    FlatNatural,
    FrownNatural,
    RaisedExcitedNatural,
    RaisedExcited,
    SadConcernedNatural,
    SadConcerned,
    UnibrowNatural,
    UpDownNatural,
    UpDown,
    Closed,
    Cry,
    EyesDefault,
    EyeRoll,
    Happy,
    Hearts,
    Side,
    Squint,
    Surprised,
    WinkWacky,
    Wink,
    XDizzy,
    BigHair,
    Bob,
    Bun,
    Curly,
    Curvy,
    Dreads01,
    Dreads02,
    Dreads,
    Frida,
    Frizzle,
    FroBand,
    Fro,
    LongButNotTooLong,
    MiaWallace,
    ShaggyMullet,
    ShavedSides,
    ShortCurly,
    ShortFlat,
    ShortRound,
    ShortWaved,
    Sides,
    Straight1,
    StraightStrand,
    Straight,
    TheCaesarSidePart,
    TheCaesar,
    Concerned,
    MouthDefault,
    Disbelief,
    Eating,
    Grimace,
    Sad,
    Scream,
    Serious,
    Smile,
    Tongue,
    Twinkle,
    Vomit,
    Accessories,
    Background,
    Clothes,
    Eye,
    Eyebrow,
    FacialHair,
    Hair,
    Mouth,
    Skin,
};
