import { httpClient } from "../../clients/http.client";
import { t_TWTstreamResponse } from "./TwtGroup.types";

const getTWTstreams = (payload: any) => {
    return new Promise<any>((res, rej) => {
        httpClient
            .post<t_TWTstreamResponse>("admin/twt/grouping/regions", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        const streams = data.data.records.data;
                        const retData = streams.map((stream) => {
                            return {
                                id: stream.TwtRegionID,
                                Name: stream.Name,
                            };
                        });

                        res({
                            data: retData,
                            page: data.pagination.page - 1,
                            totalCount: data.total_results,
                        });
                        break;
                    default:
                        rej(data.message);
                }
            })
            .catch((err) => rej(err.message));
    });
};

const saveStreamName = (payload: { id?: number; name: string }) => {
    return new Promise<void>((res, rej) => {
        httpClient
            .post("admin/twt/grouping/regions/save", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        res();
                        break;
                    default:
                        rej(data.message);
                }
            })
            .catch((err) => rej(err.message));
    });
};

export { getTWTstreams, saveStreamName };
