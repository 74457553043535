import { Box, Button, Card, CardActions, CardContent, IconButton, Slider } from "@mui/material";
import { CloudUpload, RotateLeft, RotateRight } from "@mui/icons-material";
import React from "react";
import AvatarEditor from "react-avatar-editor";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import createAvatarStyles from "./CreateAvatar.styles";

type Props = {
    onAvatarCreated: (base64: string) => Promise<boolean>;
};

const CreateAvatar: React.FC<Props> = ({ onAvatarCreated }) => {
    const classes = createAvatarStyles();
    const avatarRef = React.createRef<AvatarEditor>();
    const [file, setFile] = React.useState<File>();
    const [config, setConfig] = React.useState<{
        scale: number;
        rotate: number;
    }>({
        scale: 1,
        rotate: 0,
    });
    const [saving, setSaving] = React.useState<boolean>(false);

    const handleImageSelected: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target?.files != null) {
            setFile(e.target.files[0]);
        }
    };
    const handleSliderChange = (value: number) => {
        setConfig((state) => ({
            ...state,
            scale: value,
        }));
    };
    const toggleRotation = (direction: "left" | "right") => {
        setConfig((state) => {
            var diff = direction === "left" ? -90 : 90;
            var rotation = state.rotate + diff;

            if (rotation < 0) {
                rotation = 270;
            }
            if (rotation > 270) {
                rotation = 0;
            }

            return {
                ...state,
                rotate: rotation,
            };
        });
    };
    const handleSubmit = () => {
        const canvasScaled = avatarRef.current?.getImageScaledToCanvas();

        if (canvasScaled != null) {
            const base64 = canvasScaled.toDataURL();

            setSaving(true);
            onAvatarCreated(base64).finally(() => {
                setSaving(false);
            });
        }
    };
    return (
        <Card>
            <CardContent>
                {file != null ? (
                    <AvatarEditor
                        ref={avatarRef}
                        image={file}
                        width={250}
                        height={250}
                        border={50}
                        borderRadius={125}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={config.scale}
                        rotate={config.rotate}
                    />
                ) : (
                    <Box
                        width={300}
                        height={300}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.borderCircle}
                    >
                        <input
                            accept="image/*"
                            className={classes.hiddenInput}
                            id="icon-button-file"
                            type="file"
                            onChange={handleImageSelected}
                        />
                        <label htmlFor="icon-button-file">
                            <Button
                                size="large"
                                aria-label="change avatar"
                                component="span"
                                // className={classes.editIcon}
                                endIcon={<CloudUpload />}
                            >
                                Upload
                            </Button>
                        </label>
                    </Box>
                )}
                <Box display="flex" justifyContent="space-between" gap="20px">
                    <Box display="flex">
                        <IconButton disabled={file == null} onClick={() => toggleRotation("left")} size="large">
                            <RotateLeft />
                        </IconButton>
                        <IconButton disabled={file == null} onClick={() => toggleRotation("right")} size="large">
                            <RotateRight />
                        </IconButton>
                    </Box>
                    <Slider
                        defaultValue={100}
                        aria-labelledby="discrete-slider-custom"
                        step={10}
                        min={100}
                        max={200}
                        value={config.scale * 100}
                        disabled={file == null}
                        // marks={[
                        // 	{
                        // 		value: 100,
                        // 		label: "Original",
                        // 	},
                        // ]}
                        color="primary"
                        onChange={(_, value) => handleSliderChange((value as number) / 100)}
                    />
                </Box>
            </CardContent>
            <CardActions>
                <Box display="flex" justifyContent="end" width="100%">
                    <SubmitButton disabled={saving} loading={saving} onClick={handleSubmit}>
                        Save
                    </SubmitButton>
                </Box>
            </CardActions>
        </Card>
    );
};

export default CreateAvatar;
