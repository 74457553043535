import { Avatar, Box, Button, Dialog, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import SectionCard from "../SectionCard/SectionCard";
import SendMessage from "./SendMessage";
import Bot from "../../beYou_bot.png";
import RecipientProfile from "./RecipientProfile";
import type { MessagesType } from "../../pages/Home/Messaging/Messaging";
import { Height } from "@material-ui/icons";
import { useGetThreadsMutation, useUpdateUsersInThreadMutation } from "../../redux/api/beYouCore";
import { useUserState } from "../../context/user/user.ctx";
import { AvatarGroup } from "@mui/material";
import DateDivider from "./DateDivider";
import { Edit, MoreVert } from "@mui/icons-material";
import ViewMembers from "./ViewMembers";
import AddMembers from "./AddMembers";
import { error } from "console";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type MessagesProps = {
    messages?: MessagesType[];
    activeChat?: number;
    setActiveChat: (e: number) => void;
};
const Messages = (props: MessagesProps) => {
    const classes = messageStyles();
    const [getThread] = useGetThreadsMutation();
    const [thread, setThread] = useState<any>([]);
    const [participants, setParticipants] = useState<any>([]);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isViewMembers, setIsViewMembers] = useState(false);
    const [isAddMember, setIsAddMember] = useState(false);
    const [threadName, setThreadName] = useState("");
    const [isEditName, setIsEditName] = useState(false);
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [updateUsersInThread] = useUpdateUsersInThreadMutation();
    const threadCount = useSelector((state: RootState) => state.thread.threadUpdate.data?.threads);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRenameThread = () => {
        updateUsersInThread({
            thread_id: activeChat,
            name: newGroupName != "" ? newGroupName : threadName,
        })
            .unwrap()
            .then((response) => {})
            .catch((error) => {
                console.error("🚀 ~ file: AddMembers.tsx:49 ~ updateUsersInThread ~ error:", error);
            })
            .finally(() => {
                setIsEditName(false);
            });
    };

    const sortedMessage = [...thread]?.sort((a: any, b: any) => {
        return Number(new Date(a.created_at)) - Number(new Date(b.created_at));
    });

    const { activeChat, setActiveChat, messages } = props;

    useEffect(() => {
        getThread({ thread_id: activeChat })
            .unwrap()
            .then((resp) => {
                const activeThread = resp.data.threads.find((item: any) => item.id === activeChat);

                setThread(activeThread ? activeThread?.messages : resp.data.threads);
                setParticipants(activeThread ? activeThread.participants : []);
                setThreadName(activeThread ? activeThread.Name : "");
            })
            .catch((error) => {
                console.error("🚀 ~ file: Messages.tsx:81 ~ .then ~ error:", error);
            })
            .finally(() => {
                setIsEditName(false);
                setNewGroupName("");
            });
    }, [activeChat, isMessageSent, threadCount]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [thread]);

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }

    return (
        <Box className={classes.messages}>
            <SectionCard noHeader className={classes.noPadding}>
                <Box className={classes.activeChatNav}>
                    <Box className={classes.activeChatNavLeft}>
                        <Avatar sizes="large" src={Bot} className={classes.avatar} />
                        <Box ml={2}>
                            {isEditName ? (
                                <Box>
                                    <TextField
                                        placeholder="New Thread Name"
                                        sx={{ mb: 2 }}
                                        onChange={(e) => setNewGroupName(e.target.value)}
                                    />
                                    <Button onClick={handleRenameThread} variant="contained" sx={{ ml: 2 }}>
                                        Save
                                    </Button>
                                </Box>
                            ) : (
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h5" style={{ fontWeight: "bold", fontSize: 20 }}>
                                        {newGroupName != "" ? newGroupName : threadName}
                                    </Typography>
                                    <IconButton onClick={() => setIsEditName(true)}>
                                        <Edit sx={{ fontSize: "4px" }} />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <AvatarGroup max={4}>
                        {participants.map((participant: any) => (
                            <Avatar {...stringAvatar(participant.DisplayName)} key={participant.EntityNo} />
                        ))}
                    </AvatarGroup>
                    <IconButton onClick={handleClick}>
                        <MoreVert />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        {participants.length > 2 && (
                            <MenuItem
                                onClick={() => {
                                    setIsAddMember(true);
                                    setAnchorEl(null);
                                }}
                            >
                                Add Member
                            </MenuItem>
                        )}
                        <MenuItem
                            onClick={() => {
                                setIsViewMembers(true);
                                setAnchorEl(null);
                            }}
                        >
                            View Members
                        </MenuItem>
                    </Menu>
                </Box>

                <div className={classes.chatWindow} ref={chatContainerRef}>
                    {sortedMessage?.map((message: any, index: number) => {
                        const messageDate = new Date(message.created_at).toDateString();
                        const prevMessage = sortedMessage[index - 1];
                        const prevMessageDate = prevMessage ? new Date(prevMessage.created_at).toDateString() : "";
                        const content = message && message.content;

                        return (
                            <>
                                {messageDate !== prevMessageDate && <DateDivider date={messageDate} />}
                                <Message
                                    activeChat={props.activeChat}
                                    key={message.id}
                                    message={content}
                                    userId={message.EntityNo}
                                    messageId={message.id}
                                    timeStamp={message.created_at}
                                    participants={participants}
                                />
                            </>
                        );
                    })}
                </div>
                <Box className={classes.sendMessage}>
                    <SendMessage id={activeChat} setIsMessageSent={setIsMessageSent} />
                </Box>
            </SectionCard>
            <Dialog open={isViewMembers} onClose={() => setIsViewMembers(false)}>
                <ViewMembers setIsViewMembers={setIsViewMembers} participants={participants} />
            </Dialog>

            <Dialog open={isAddMember} onClose={() => setIsAddMember(false)}>
                <AddMembers
                    setIsAddMember={setIsAddMember}
                    participants={participants}
                    activeChat={activeChat}
                    threadName={threadName}
                />
            </Dialog>
        </Box>
    );
};
const messageStyles = makeStyles((theme) => ({
    messages: {
        // height: "calc(95vh - 64px)",
        background: theme.palette.background.paper,
        borderRadius: 23,
        boxShadow:
            " 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
    activeChatNav: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: " 0px 28px 15px -8px rgba(0,0,0,0.2)",
        zIndex: 1001,
        position: "relative",
        height: 70,
        padding: 10,
    },
    activeChatNavLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    avatar: {
        width: 70,
        objectFit: "contain",
        gap: 4,
        height: 70,
    },
    chatWindow: {
        height: 530,
        width: "100%",
        background: theme.palette.background.default,
        overflowY: "scroll",
        zIndex: 1000,
        position: "relative",
        padding: 10,
    },
    sendMessage: {
        // height: "100%",
        width: "100%",
        background: theme.palette.background.paper,
        borderBottomLeftRadius: 23,
        borderBottomRightRadius: 23,
        boxShadow: "0px -25px 15px -8px rgba(0,0,0,0.1)",
        position: "relative",
        zIndex: 1001,
    },
    noPadding: {
        padding: "0 !important",
    },
}));
export default Messages;
