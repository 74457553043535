import { AxiosResponse } from "axios";
import React from "react";
import { httpClient } from "../../clients/http.client";
import { useRequestQueue } from "../../context/notifications/requestQueue.ctx";
import { t_SurveyPeriodArr } from "../../sections/Surveys/Surveys.types";

type t_ExportMeta = {
    file_name: string;
};
const useReportExport = () => {
    const { pushRequestToQueue } = useRequestQueue();
    const [loading, setLoading] = React.useState(false);

    const downloadExportFile = React.useCallback((url: string, title: string) => {
        setLoading(true);
        setTimeout(() => {
            if (setLoading != null) {
                setLoading(false);
            }
        }, 300);
        const request = httpClient.get(url, {
            responseType: "blob",
            /** @MM this will be useful in the future - leaving it here */
            // onDownloadProgress: (e) => {
            // 	console.log({ e });
            // },
        });
        const callback = (response: AxiosResponse<any>): boolean => {
            var a = document.createElement("a");
            let matches = /filename=\"?(.*)\"?$/g.exec(response.headers["content-disposition"]);
            var file_name = `export (${new Date(Date.now()).toDateString()})`;
            if (matches != null && matches.length > 0) {
                file_name = matches[0];
                file_name = file_name.replace(/filename=/g, "");
                file_name = file_name.replace(/\"/g, "");
                file_name = file_name.replace(/\\/g, "");
            }

            const download_url = window.URL.createObjectURL(new Blob([response.data]));
            if (setLoading != null) {
                setLoading(false);
            }

            a.href = download_url;
            a.setAttribute("download", file_name);
            a.setAttribute("target", "_blank");
            a.click();
            return true;
        };

        pushRequestToQueue({
            type: "download",
            title,
            request,
            callback,
        });
    }, []);

    const downloadExportFileMulti = React.useCallback((url: string, title: string) => {
        setLoading(true);
        setTimeout(() => {
            if (setLoading != null) {
                setLoading(false);
            }
        }, 300);
        const request = httpClient.get(url, {
            responseType: "blob",
            /** @MM this will be useful in the future - leaving it here */
            // onDownloadProgress: (e) => {
            // 	console.log({ e });
            // },
        });
        const callback = (response: AxiosResponse<any>): boolean => {
            var a = document.createElement("a");
            let matches = /filename=\"?(.*)\"?$/g.exec(response.headers["content-disposition"]);
            var file_name = `export (${new Date(Date.now()).toDateString()})`;
            if (matches != null && matches.length > 0) {
                file_name = matches[0];
                file_name = file_name.replace(/filename=/g, "");
                file_name = file_name.replace(/\"/g, "");
                file_name = file_name.replace(/\\/g, "");
            }

            const download_url = window.URL.createObjectURL(new Blob([response.data]));
            if (setLoading != null) {
                setLoading(false);
            }

            a.href = download_url;
            a.setAttribute("download", file_name);
            a.setAttribute("target", "_blank");
            a.click();
            return true;
        };

        pushRequestToQueue({
            type: "download",
            title,
            request,
            callback,
        });
    }, []);

    return { loading, downloadExportFile, downloadExportFileMulti };
};

export { useReportExport };
