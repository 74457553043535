import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import {
	deleteLeaveOption,
	getLeaveOptions,
	updateLeaveOption,
} from "./LeaveOptions.api";
import { t_LeaveOption } from "./LeaveOptions.types";

interface Props {}

const LeaveOptions: React.FC<Props> = ({}) => {
	const [columns] = React.useState<Column<t_LeaveOption>[]>([
		{ title: "Name", field: "name" },
	]);

	return (
		<BUMaterialTable
			title='Leave Types'
			data={(query) => getLeaveOptions({ query })}
			columns={columns}
			editable={{
				onRowAdd: (newData) => updateLeaveOption({ option: newData }),
				onRowUpdate: (newData) =>
					updateLeaveOption({ option: newData }),
				onRowDelete: (oldData) =>
					deleteLeaveOption({ option_id: oldData.id }),
			}}
		/>
	);
};

export default LeaveOptions;
