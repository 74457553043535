import { useTheme } from "@mui/material";
import React from "react";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { t_SurveyPeriodArr } from "../../../../../Surveys/Surveys.types";
import { filterCtx } from "../../LsaFilterProvider";
import { getLsaSurveyGraphDataMulti } from "./LsaSurveyGraph.api";
import { t_LsaSurveyGraphData, t_LsaSurveyGraphGroups } from "./LsaSurveyGraph.types";

type Props = {
    group_by: t_LsaSurveyGraphGroups;
    periods: {
        [key: string]: t_SurveyPeriodArr;
    };
    entity_no?: string;
    domain?: any;
    barSize?: number;
    radius?: number;
};

const LsaSurveyGraph: React.FC<Props> = ({ group_by, periods, entity_no, domain, barSize, radius }) => {
    const theme = useTheme();

    const [graphData, setGraphData] = React.useState<t_LsaSurveyGraphData[]>([]);

    const { filterParam, setFilterParam } = React.useContext(filterCtx);

    React.useEffect(() => setFilterParam({}), [periods.mbr?.length]);

    React.useEffect(() => {
        let isActive = true;
        if (group_by != null && periods != null) {
            getLsaSurveyGraphDataMulti({
                group_by,
                periods,
                filter: entity_no ? { entity_no: entity_no } : filterParam,
            }).then((data) => {
                if (isActive) {
                    setGraphData(data);
                }
            });
        }
        return () => {
            isActive = false;
        };
    }, [group_by, filterParam, periods, entity_no]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={graphData} layout="vertical">
                <XAxis type="number" domain={domain} />
                <YAxis type="category" dataKey="group" />
                <Tooltip />
                <Legend />
                <Bar dataKey="mbr" name="MBR" fill={theme.palette.info.light} barSize={barSize} radius={radius} />
                <Bar dataKey="fcr" name="FCR" fill={theme.palette.info.dark} barSize={barSize} radius={radius} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default LsaSurveyGraph;
