import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Icon,
    IconButton,
    Input,
    InputLabel,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AttachFile, Clear, Send } from "@mui/icons-material";
import { InsertEmoticonOutlined } from "@material-ui/icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useReadThreadMutation, useSendMessageMutation } from "../../redux/api/beYouCore";
type SendMessage = {
    id?: number;
    submittedMessage?: string;
    setIsMessageSent: (value: boolean) => void;
};
const SendMessage = (props: SendMessage) => {
    const classes = sendMessageStyles();
    const [showEmoticonMenu, setShowEmoticonMenu] = useState(false);
    const [input, setInput] = useState("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [documentPreview, setDocumentPreview] = useState(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const handleEmoticonMenuClick = () => {
        setShowEmoticonMenu(!showEmoticonMenu);
    };
    const addEmoji = (e: { unified: string }) => {
        let sym = e.unified.split("-");
        let codesArray: number[] = [];
        sym.forEach((el) => codesArray.push(parseInt(el, 16)));
        let emoji = String.fromCodePoint(...codesArray);
        setInput(input + emoji);
    };
    const [sendMessage, { isLoading }] = useSendMessageMutation();
    const [readThread] = useReadThreadMutation();
    const { id, setIsMessageSent } = props;

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (input != "") {
            setIsMessageSent(true);
            sendMessage({ content: input, thread_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: SendMessage.tsx:39 ~ .then ~ error:", error);
                })
                .finally(() => {
                    setInput("");
                    setIsMessageSent(false);
                });
        }
    };

    const emoticonMenuRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onFileSelected = (file: File) => {};

    const handleFileInputChange = (event: any) => {
        const files = event.target.files;
        if (files.length > 0) {
            onFileSelected(files[0]);
            setSelectedFile(files[0]);
        }
    };
    const handleButtonClick = () => {
        // Check if fileInputRef.current is not null before triggering the click event
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (emoticonMenuRef.current) {
                // Use type assertion to assert that event.target is not null and is an HTMLElement
                const target = event.target as HTMLElement | null;

                // Check if the clicked element is not the emoji tab or its descendants
                if (
                    target &&
                    !emoticonMenuRef.current.contains(target) &&
                    !target.closest("em-emoji-picker") // Replace '.emoji-tab' with the actual class or selector of your emoji tab
                ) {
                    setShowEmoticonMenu(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [emoticonMenuRef]);

    const formatFileSize = (sizeInBytes: number) => {
        if (sizeInBytes < 1024) {
            return sizeInBytes + " B";
        } else if (sizeInBytes < 1024 * 1024) {
            return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else if (sizeInBytes < 1024 * 1024 * 1024) {
            return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        } else {
            return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
        }
    };

    return (
        <Box className={classes.sendMessage}>
            <FormControl className={classes.form}>
                <Box ref={emoticonMenuRef} className={classes.emoticonMenu}>
                    <InsertEmoticonOutlined className={classes.emoticon} onClick={handleEmoticonMenuClick} />
                </Box>
                <Input
                    type="text"
                    className={classes.input}
                    placeholder="Start typing..."
                    aria-describedby="type your message"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                {/* <Box sx={{ position: "absolute", right: 50, top: 12, zIndex: 99 }}>
                    <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileInputChange}
                    />
                    <IconButton onClick={handleButtonClick}>
                        <AttachFile />
                    </IconButton>
                </Box> */}
                {selectedFile && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            bgcolor: "#c6db34",
                            position: "absolute",
                            top: -50,
                            left: 50,
                            paddingLeft: 2,
                            borderRadius: 5,
                            width: "30%",
                        }}
                    >
                        <Box>
                            <Typography>{selectedFile.name}</Typography>
                            <Typography fontSize={10}>{formatFileSize(selectedFile.size)}</Typography>
                        </Box>
                        <IconButton onClick={() => setSelectedFile(null)}>
                            <Clear color="error" />
                        </IconButton>
                    </Box>
                )}
                <Box className={classes.submit}>
                    {isLoading ? (
                        <CircularProgress color="info" />
                    ) : (
                        <Send className={classes.sendIcon} onClick={handleSendMessage} />
                    )}
                    {/* <Input type="submit" value={""} className={classes.inputSubmit} /> */}
                </Box>
            </FormControl>
            <Box className={classes.emoticonWrapper}>
                {showEmoticonMenu && (
                    <Box>
                        <Picker
                            onSelect={addEmoji}
                            data={data}
                            style={{ position: "fixed", top: 0 }}
                            onEmojiSelect={addEmoji}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
const sendMessageStyles = makeStyles((theme) => ({
    sendMessage: {
        width: "100%",
        position: "relative",
        boxShadow: "0px -25px 15px -8px rgba(0,0,0,0.1)",
    },
    emoticonWrapper: {
        position: "fixed",
        top: "25%",
    },
    form: {
        width: "100%",
        position: "relative",
        background: theme.palette.background.default,
        borderRadius: 23,
        margin: "20px 0 20px 0",
        border: "none",
    },
    input: {
        width: "85%",
        padding: 13,
        borderRadius: 23,
        margin: "0 auto",
        border: "none",
    },
    menu: {
        position: "fixed",
        top: 20,
    },
    emoticonMenu: {
        width: 50,
        height: "80%",
        position: "absolute",
        cursor: "pointer",
        borderTopLeftRadius: 23,
        borderBottomLeftRadius: 23,
        left: 0,
        top: 3,
        marginBottom: "0 auto",
        borderRight: "2px solid rgba(0, 0, 0, 0.42)",
    },
    emoticon: {
        width: 30,
        height: 30,
        position: "absolute",
        right: 10,
        zIndex: 1,
        // fill: "#f3f4f4",
        bottom: 0,
        top: 15,
        cursor: "pointer",
    },
    submit: {
        position: "absolute",
        width: 50,
        height: "100%",
        zIndex: 0,
        right: 0,
        border: "none",
        background: "#c6db34",
        borderBottomRightRadius: 23,
        borderTopRightRadius: 23,
    },
    inputSubmit: {
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        background: "#c6db34",
        border: "none",
    },
    sendIcon: {
        width: 30,
        height: 30,
        position: "absolute",
        right: 10,
        zIndex: 1,
        fill: "#fff",
        bottom: 0,
        top: 15,
        cursor: "pointer",
    },
}));

export default SendMessage;
