import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_InitiativeOption } from "./InitiativeOptions.types";

const getInitiativeOptions = (payload: {
	query: Query<t_InitiativeOption>;
}) => {
	return new Promise<QueryResult<t_InitiativeOption>>((resolve, reject) => {
		const body = {
			...convertQuery(payload.query),
		};
		httpClient
			.post("/admin/production/initiative", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve({
							data: data.data.initiative_types,
							page: data.pagination.page - 1,
							totalCount: data.total_results,
						});
						break;

					default:
						break;
				}
			});
	});
};

export { getInitiativeOptions };
