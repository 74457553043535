import {
    Avatar,
    Badge,
    Card,
    CardHeader,
    CardMedia,
    Dialog,
    IconButton,
    Typography
} from "@mui/material";
import { Edit, Room } from "@mui/icons-material";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React from "react";
import SummaryBadges from "../../../../components/SummaryBadges/SummaryBadges";
import { getImageUrl } from "../../../../misc/helpers";
import CreateAvatar from "../CreateAvatar/CreateAvatar";
import { updateProfilePicture } from "./ProfileCard.api";
import profileCardStyles from "./ProfileCard.styles";
import { t_ProfileCardUser } from "./ProfileCard.types";

interface Props {
	user: t_ProfileCardUser;
	editable?: boolean;
	onClick?: () => void;
}

const ProfileCard: React.FC<Props> = ({ user, editable = false, onClick }) => {
	const classes = profileCardStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [profileImg, setProfileImg] = React.useState<string>(
		user.profile_img
	);
	const [hover, setHover] = React.useState(false);
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const handleProfilePictureChange = (base64: string) =>
		new Promise<boolean>((resolve, reject) => {
			if (editable) {
				updateProfilePicture({ base64 })
					.then((response) => {
						setProfileImg(response.profile_img);
						resolve(true);
					})
					.catch(() => {
						enqueueSnackbar(
							"An error occurred saving your profile picture.",
							{ variant: "error" }
						);
						reject(false);
					});
			} else {
				reject(false);
			}
		});

	return (
        <Card
			onMouseEnter={() => onClick && setHover(true)}
			onMouseLeave={() => setHover(false)}
			className={clsx(classes.card, { [classes.highlight]: hover })}
			onClick={onClick}
		>
			<CardMedia className={classes.cardMedia}>
				<Badge
					overlap="circular"
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					badgeContent={
						editable && (
							<React.Fragment>
								<IconButton
									size='small'
									aria-label='change avatar'
									component='span'
									className={classes.editIcon}
									onClick={() => setDialogOpen(true)}
								>
									<Edit fontSize='inherit' />
								</IconButton>
							</React.Fragment>
						)
					}
				>
					<Avatar
						aria-label='profile picture'
						className={classes.avatar}
						src={getImageUrl("profile", profileImg)}
					/>
				</Badge>
			</CardMedia>
			<CardHeader
				title={
					<React.Fragment>
						<Typography variant='h6'>
							{user.display_name}
						</Typography>

						<Typography variant='subtitle1'>
							{user.job_title}
						</Typography>
						<Typography variant='subtitle2'>
							<Room fontSize='inherit' color='action' />
							{user.region}
						</Typography>
					</React.Fragment>
				}
				subheader={
					<React.Fragment>
						<SummaryBadges
							className={classes.profileBadges}
							justify='flex-start'
							badges={[
								{
									label: "Wins",
									value: user.wins?.toString(),
								},
								{
									label: "Badges",
									value: user.badges?.toString(),
								},
								{
									label: "Losses",
									value: user.losses?.toString(),
								},
							]}
						/>
					</React.Fragment>
				}
			/>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<CreateAvatar onAvatarCreated={handleProfilePictureChange} />
			</Dialog>
		</Card>
    );
};

export default ProfileCard;
