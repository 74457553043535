
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import CoachProfileRank from "./CoachProfileRank";

interface CoachProfilePositionPill {
    currentRank?: number;
    rank?: string | undefined;
    rankChange?: string;
    wins?: number;
    losses?: number;
    place: string | undefined;

}

const CoachProfilePositionPill = ({ currentRank, rankChange, rank, wins, losses, place }: CoachProfilePositionPill) => {

    if (rank && rank > rank) {
        rankChange = "up";
    } else {
        rankChange = "down";
    }

    return (

        <Box display="flex" alignItems="center" justifyContent="center" >
            {wins || losses ?
                <>
                    <Typography variant="h5" style={{ fontWeight: "bold", marginRight: 10 }}>{wins}{losses}</Typography>
                    {/* <Chip
                        label={`${place ? place : 0} PLACES*`} size="small"
                        style={{
                            background: rankChange === "up" ? "#c3d831" : "rgb(255, 64, 64)",
                            color: "white",
                            fontSize: ".6rem",
                            padding: "0 0.5em",
                            fontWeight: "bold",
                            flexFlow: "row-reverse"


                        }}
                        avatar={<img src={rankChange === "up" ? "/images/upArrow.png" : "/images/downArrow.png"} alt={rankChange === "up" ? "Rank Increase" : "Rank Decrease"} style={{
                            objectFit: 'contain', width: 12, height: 12, marginRight: 0,
                        }} />}
                    /> */}

                </> :
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" style={{ fontWeight: "bold", marginRight: 10 }}>{wins}{losses}</Typography>
                    {/* <Chip
                        label={`${place ? place : 0} PLACES*`} size="small"
                        style={{
                            background: rankChange === "up" ? "#c3d831" : "rgb(255, 64, 64)",
                            color: "white",
                            fontSize: ".6rem",
                            padding: "0 0.5em",
                            fontWeight: "bold",
                            flexFlow: "row-reverse",


                        }}
                        avatar={<img src={rankChange === "up" ? "/images/upArrow.png" : "/images/downArrow.png"} alt={rankChange === "up" ? "Rank Increase" : "Rank Decrease"} style={{
                            objectFit: 'contain', width: 12, height: 12, marginRight: 0,
                        }} />}
                    /> */}

                </Box>}

        </Box>

    )
}

export default CoachProfilePositionPill;