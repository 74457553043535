import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const initiativeOptionsStyles = makeStyles((theme: Theme) =>
	createStyles({
		categoryGrid: {
			gap: 2,
			maxHeight: 38,
			overflowY: "auto",
			flexWrap: "nowrap",
		},
		activeButton: {
			width: "100%",
			height: "100%",
		},
		isActive: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,
		},
	})
);

export default initiativeOptionsStyles;
