import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import AppealRecords from "../../tables/AppealRecords/AppealRecords";

const MaintainAppeals: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(Date.now()));

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    return (
        <SectionCard
            title={`Appeals`}
            secondaryAction={
                <DatePicker
                    openTo="month"
                    views={["year", "month"]}
                    // label="Month and Year"
                    value={selectedDate}
                    onChange={handleDateChange}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
            fullWidth
        >
            <AppealRecords month={selectedDate?.toDateString()} />
        </SectionCard>
    );
};

export default MaintainAppeals;
