import { Button, FormControl, FormHelperText, List, ListItem, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getAppeal } from "../../Appeals.api";
import { t_AppealDetailedRecord } from "../../Appeals.types";
import appealStepperStyles from "./AppeallStepper.styles";
import { getCategories, getRemovalLevels, saveAppeal } from "./AppealStepper.api";
import {
    base_AppealForm,
    t_AppealCategory,
    t_AppealForm,
    t_AppealRemovalLevel,
    t_AppealStepperActions,
} from "./AppealStepper.types";

const appealShema: yup.SchemaOf<t_AppealForm> = yup
    .object({
        appeal_id: yup.number().defined().nullable(),
        category: yup
            .object()
            .shape({
                category_id: yup.number(),
                name: yup.string(),
            })
            .required()
            .typeError("Category is a required field.")
            .label("Category"),
        level: yup
            .object()
            .shape({
                level_id: yup.number(),
                name: yup.string(),
            })
            .required()
            .typeError("Removal level is a required field.")
            .label("Removal level"),
        transfer_to: yup
            .string()
            .label("Transfer to")
            .when("level", {
                is: (value?: t_AppealRemovalLevel) => {
                    return value != null && Number(value.level_id) === 4;
                },
                then: yup.string().required().label("Transfer to"),
                otherwise: yup.string(),
            }),
        comment: yup.string().required("A comment is required for a survey appeal."),
    })
    .defined();

type Props = {
    appeal_id?: number;
    metric_id: number;
    data_id: number;
    dispatch: (reason?: t_AppealStepperActions) => void;
};
const AppealStepper: React.FC<Props> = ({ appeal_id, metric_id, data_id, dispatch }) => {
    const classes = appealStepperStyles();
    const { enqueueSnackbar } = useSnackbar();
    const formik = useFormik({
        initialValues: base_AppealForm,
        validationSchema: appealShema,
        onSubmit: (formData: t_AppealForm) =>
            new Promise((resolve, reject) => {
                saveAppeal({
                    category_id: formData.category?.category_id,
                    removal_level_id: formData.level?.level_id,
                    transfer_to: formData.transfer_to,
                    comment: formData.comment,
                    data_id: data_id,
                    metric_id: metric_id,
                    appeal_id: appeal_id,
                })
                    .then((response) => {
                        if (appeal_id != null) {
                            dispatch({ type: "appeal-updated" });
                        } else {
                            dispatch({
                                type: "appeal-created",
                                appeal_id: response.appeal.appeal_id,
                            });
                        }
                        enqueueSnackbar("Appeal saved.");
                        resolve(true);
                    })
                    .catch((reason) => {
                        enqueueSnackbar("We ran into an error saving the Appeal.", { variant: "error" });
                        reject(reason);
                    });
            }),
    });
    const [appealRecord, setAppealRecord] = React.useState<t_AppealDetailedRecord>();
    const [options, setOptions] = React.useState({
        categories: [] as t_AppealCategory[],
        removal_levels: [] as t_AppealRemovalLevel[],
    });

    const handleSelectChange = (name: string, value: any) => {
        formik.setFieldValue(name, value);
    };

    const handleReset = () => {
        formik.setValues(appealRecord ?? base_AppealForm);
    };

    const handleClear = () => {
        formik.resetForm();
    };

    const handleSubmit = () => {
        formik.submitForm();
    };

    /** Hooks */
    React.useEffect(() => {
        var isActive = true;
        if (metric_id != null) {
            Promise.all([getCategories({ metric_id }), getRemovalLevels()])
                .then((data) => {
                    if (!isActive || data.length !== 2) return;
                    setOptions({
                        categories: data[0],
                        removal_levels: data[1],
                    });
                })
                .catch((error) => console.error(error));
            return () => {
                isActive = false;
            };
        }
    }, [metric_id]);
    React.useEffect(() => {
        var isActive = true;
        if (appeal_id != null) {
            getAppeal({ appeal_id }).then((data) => {
                if (isActive) {
                    setAppealRecord(data);
                    if (data != null) {
                        formik.setValues(data);
                    }
                }
            });
        }
    }, [appeal_id]);
    return (
        <SectionCard
            noHeader
            hasBackground
            bottomActions={
                <div className={classes.actionsContainer}>
                    <Button onClick={handleClear}>Clear</Button>
                    <Button onClick={handleReset}>Reset</Button>
                    <SubmitButton
                        loading={formik.isSubmitting}
                        onClick={handleSubmit}
                        disabled={(appealRecord?.status?.ApprovalStatusID ?? 0) > 1}
                    >
                        {appeal_id != null ? "Update" : "Submit"}
                    </SubmitButton>
                </div>
            }
        >
            <List>
                <ListItem>
                    <Autocomplete
                        className={classes.inputField}
                        getOptionLabel={(option: any) => option.name}
                        value={formik.values.category}
                        options={options.categories}
                        onChange={(_, value) => handleSelectChange("category", value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={formik.touched.category && Boolean(formik.errors.category)}
                                helperText={Boolean(formik.errors.category) && formik.errors.category}
                                label="Select Category"
                            />
                        )}
                    />
                </ListItem>
                <ListItem>
                    <Autocomplete
                        className={classes.inputField}
                        getOptionLabel={(option: any) => option.name}
                        value={formik.values.level}
                        options={options.removal_levels}
                        onChange={(_, value) => handleSelectChange("level", value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={formik.touched.level && Boolean(formik.errors.level)}
                                helperText={Boolean(formik.errors.level) && formik.errors.level}
                                label="Select Removal Level"
                            />
                        )}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        className={classes.inputField}
                        value={formik.values.transfer_to || ""}
                        error={formik.touched.transfer_to && Boolean(formik.errors.transfer_to)}
                        label="Transfer to Entity"
                        name="transfer_to"
                        onChange={formik.handleChange}
                        helperText={Boolean(formik.errors.transfer_to) && formik.errors.transfer_to}
                    />
                </ListItem>
                <ListItem>
                    <FormControl>
                        <FormHelperText error={formik.touched.comment && Boolean(formik.errors.comment)}>
                            Appeal Comment
                        </FormHelperText>
                        <TextField
                            className={classes.inputField}
                            value={formik.values.comment}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            name="comment"
                            onChange={formik.handleChange}
                            helperText={formik.touched.comment && formik.errors.comment}
                            multiline
                            rows={5}
                            maxRows={5}
                            variant="outlined"
                        />
                    </FormControl>
                </ListItem>
            </List>
        </SectionCard>
    );
};

export default AppealStepper;
