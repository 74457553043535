import { httpClient } from "../../../../clients/http.client";
import {
  t_SurveyPeriod,
  t_SurveyPeriodArr,
} from "../../../Surveys/Surveys.types";
import { t_PerceptionSummaryData } from "./PerceptionSummary.types";

function getPerceptionSummary<RowData extends object>(payload: {
  survey: string;
  period: t_SurveyPeriod;
  report: "survey" | "appeal" | "lsa";
}) {
  return new Promise<t_PerceptionSummaryData<RowData>>((resolve, reject) => {
    const { survey, report, period } = payload;
    const body = {
      for_survey: survey,
      for_report: report,
      group_by: ["survey", "service_team", "team", "job_title"],
      from_date: period.start_date,
      to_date: period.end_date,
    };
    httpClient.post("/reports/survey/summary", body).then(({ data }) => {
      switch (data.status) {
        case "success":
          resolve(data.data.summaries);
          break;
        default:
          reject(data.message);
      }
    });
  });
}

function getPerceptionSummaryMulti<RowData extends object>(payload: {
  survey: string;
  periods: t_SurveyPeriodArr;
  report: "survey" | "appeal" | "lsa";
}) {
  return new Promise<t_PerceptionSummaryData<RowData>>((resolve, reject) => {
    const { survey, report, periods } = payload;
    const body = {
      for_survey: survey,
      for_report: report,
      group_by: ["survey", "service_team", "team", "job_title"],
      periods: periods,
    };
    httpClient.post("/reports/survey/summary/multi", body).then(({ data }) => {
      switch (data.status) {
        case "success":
          resolve(data.data.summaries);
          break;
        default:
          reject(data.message);
      }
    });
  });
}

function getPerceptionSummaryFiltered<RowData extends object>(payload: {
  survey: string;
  periods: t_SurveyPeriodArr;
  report: "survey" | "appeal" | "lsa";
  filter: {
    [key: string]: string[];
  };
}) {
  return new Promise<t_PerceptionSummaryData<RowData>>((resolve, reject) => {
    const { survey, report, periods, filter } = payload;
    const body = {
      for_survey: survey,
      for_report: report,
      group_by: ["survey", "service_team", "team", "job_title"],
      periods: periods,
      filter,
    };
    httpClient.post("/reports/survey/summary/multi", body).then(({ data }) => {
      switch (data.status) {
        case "success":
          resolve(data.data.summaries);
          break;
        default:
          reject(data.message);
      }
    });
  });
}

export {
  getPerceptionSummary,
  getPerceptionSummaryMulti,
  getPerceptionSummaryFiltered,
};
