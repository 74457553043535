import { ReactNode } from "react";

import Blank from "./Blank";
import Kurt from "./Kurt";
import Prescription01 from "./Prescription01";
import Prescription02 from "./Prescription02";
import Round from "./Round";
import Sunglasses from "./Sunglasses";
import Wayfarers from "./Wayfarers";

export type AccessoriesOption =
    | "Blank"
    | "Kurt"
    | "Prescription01"
    | "Prescription02"
    | "Round"
    | "Sunglasses"
    | "Wayfarers"
    | "default";

const componentsMap: Record<AccessoriesOption, React.ComponentType<{ children?: ReactNode }>> = {
    Blank: Blank,
    Kurt: Kurt,
    Prescription01: Prescription01,
    Prescription02: Prescription02,
    Round: Round,
    Sunglasses: Sunglasses,
    Wayfarers: Wayfarers,
    default: Blank,
};

interface AccessoriesProps {
    option: AccessoriesOption;
    children?: ReactNode;
}

const Accessories = ({ option = "default", children }: AccessoriesProps) => {
    const SelectedComponent = componentsMap[option] || componentsMap["default"];
    return <SelectedComponent>{children}</SelectedComponent>;
};

export default Accessories;
