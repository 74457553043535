import { FormControl, FormHelperText, List, ListItem, ListItemText, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import { formatApiDate } from "../../../../misc/helpers";
import { getAppealDecision } from "../../Appeals.api";
import { saveAppealDecision } from "./AppealDecision.api";
import appealDecisionStyles from "./AppealDecision.styles";
import { base_DecisionForm, t_AppealDecisionActions, t_DecisionForm, t_DecisionRecord } from "./AppealDecision.types";

const decisionSchema: yup.SchemaOf<t_DecisionForm> = yup
    .object({
        approval: yup.boolean(),
        comment: yup.string().required(),
    })
    .defined();

interface Props {
    appeal_id?: number;
    dispatch: (action: t_AppealDecisionActions) => void;
}

const AppealDecision: React.FC<Props> = ({ appeal_id, dispatch }) => {
    const permissions = usePermissions();
    const classes = appealDecisionStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [decision, setDecision] = React.useState<t_DecisionRecord>();

    const formik = useFormik({
        initialValues: base_DecisionForm,
        validationSchema: decisionSchema,
        onSubmit: (formData: t_DecisionForm) =>
            new Promise((resolve, reject) => {
                saveAppealDecision({ ...formData, appeal_id })
                    .then(() => {
                        enqueueSnackbar("Decision saved.");
                        dispatch({ type: "decision-updated" });
                        resolve(true);
                    })
                    .catch(() => {
                        enqueueSnackbar("We ran into an error submitting this decision.", { variant: "error" });
                    });
            }),
    });

    const handleDecision = (approve: boolean) => {
        formik.setFieldValue("approval", approve).then(() => {
            formik.submitForm();
        });
    };

    React.useEffect(() => {
        var isActive = true;
        if (appeal_id != null) {
            getAppealDecision({ appeal_id }).then((data) => {
                if (isActive) {
                    setDecision(data);
                    if (data != null) {
                        formik.setValues(
                            {
                                comment: data.decision_comment,
                            },
                            false,
                        );
                    }
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [appeal_id]);

    return (
        <SectionCard
            title="Appeal Decision"
            titleProps={{ variant: "h6" }}
            hasBackground
            fullWidth
            bottomActions={
                permissions.appeal_approve && (
                    <div className={classes.teamLeaderActions}>
                        <SubmitButton
                            loading={formik.isSubmitting && !formik.values.approval}
                            disabled={
                                appeal_id == null ||
                                (decision && decision.status.ApprovalStatusID > 1) ||
                                (formik.isSubmitting && formik.values.approval)
                            }
                            onClick={() => handleDecision(false)}
                        >
                            Decline
                        </SubmitButton>
                        <SubmitButton
                            loading={formik.isSubmitting && formik.values.approval}
                            disabled={
                                appeal_id == null ||
                                (decision && decision.status.ApprovalStatusID > 1) ||
                                (formik.isSubmitting && !formik.values.approval)
                            }
                            onClick={() => handleDecision(true)}
                        >
                            Approve
                        </SubmitButton>
                    </div>
                )
            }
        >
            <List>
                {!decision || decision?.status.ApprovalStatusID === 1 ? (
                    <ListItem>
                        <FormControl className={classes.input} fullWidth>
                            <FormHelperText error={Boolean(formik.errors.comment)}>Decision Comment</FormHelperText>
                            <TextField
                                name="comment"
                                value={formik.values.comment}
                                InputProps={{
                                    readOnly: decision && decision.status.ApprovalStatusID > 1,
                                }}
                                onChange={formik.handleChange}
                                multiline
                                rows={5}
                                maxRows={5}
                                variant="outlined"
                                error={Boolean(formik.errors.comment)}
                                helperText={Boolean(formik.errors.comment) && "Please leave a decision comment."}
                            />
                        </FormControl>
                    </ListItem>
                ) : (
                    <>
                        <ListItem>
                            <ListItemText
                                className={classes.input}
                                primary={decision.status.Status}
                                secondary={`${decision.decision_by && decision.decision_by.name + " - "}${formatApiDate(
                                    decision.decision_date,
                                )}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className={classes.input}
                                primary="Decision comment"
                                secondary={decision?.decision_comment}
                            />
                        </ListItem>
                    </>
                )}
            </List>
        </SectionCard>
    );
};

export default AppealDecision;
