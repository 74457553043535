import { httpClient } from "../../../../clients/http.client";
import {
	t_InitiativeType,
	t_ProductionAdditionalVolume,
	t_ProductionAdditionalVolumeRecord,
} from "./AdditionalProduction.types";

const getInitiativeTypes = (payload: { entity_no: number }) =>
	new Promise<t_InitiativeType[]>((resolve, reject) => {
		httpClient
			.post("/production/initiative/options", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.initiative_types);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const saveAdditionalVolume = (
	payload: t_ProductionAdditionalVolume & {
		entity_no: number;
		capture_date: string;
	}
) => {
	return new Promise((resolve, reject) => {
		httpClient
			.post("/production/additional_volume/save", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
};

const deleteAdditionalVolume = (payload: t_ProductionAdditionalVolume) => {
	return new Promise((resolve, reject) => {
		const body = {
			additional_volume_id: payload.id,
		};

		httpClient
			.post("/production/additional_volume/delete", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
};

const getAdditionalVolumeRecords = (payload: {
	entity_no: number;
	capture_date: string;
}) => {
	return new Promise<t_ProductionAdditionalVolumeRecord[]>(
		(resolve, reject) => {
			httpClient
				.post("/production/additional_volume", payload)
				.then(({ data }) => {
					switch (data.status) {
						case "success":
							resolve(data.data.additional_volumes);
							break;
						default:
							reject(data.message);
					}
				})
				.catch(reject);
		}
	);
};

const approveAdditionalVolume = (payload: { id: number; approval: boolean }) =>
	new Promise((resolve, reject) => {
		httpClient
			.post("/production/additional_volume/approve", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export {
	getInitiativeTypes,
	getAdditionalVolumeRecords,
	saveAdditionalVolume,
	deleteAdditionalVolume,
	approveAdditionalVolume,
};
