import React from "react";
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { t_SurveyPeriod } from "../../../../../Surveys/Surveys.types";
import { getAppealCategoryData } from "./AppealCategoryGraph.api";
import { t_AppealCategoryGraphData } from "./AppealCategoryGraph.types";

type Props = {
	survey: "mbr" | "fcr";
	period: t_SurveyPeriod;
};
const AppealCategoryGraph: React.FC<Props> = ({ survey, period }) => {
	const [graphData, setGraphData] = React.useState<
		t_AppealCategoryGraphData[]
	>([]);

	React.useEffect(() => {
		var isActive = true;
		if (survey != null && period != null) {
			getAppealCategoryData({
				survey,
				period,
			}).then((data) => {
				if (isActive) {
					setGraphData(data);
				}
			});
		}
		return () => {
			isActive = false;
		};
	}, [survey, period]);
	return (
		<ResponsiveContainer width='100%' height={400}>
			<BarChart data={graphData} layout='vertical'>
				<XAxis type='number' />
				<YAxis type='category' dataKey='category' width={250} />
				<Tooltip />
				<Bar dataKey='appeal_count' name='Appeals' fill='#8884d8' />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default AppealCategoryGraph;
