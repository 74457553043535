import {
    Avatar,
    Badge,
    Card,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TableCell,
    TableRow,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../../misc/helpers";
import AppealDialogContent from "../../sections/Appeals/dialogs/AppealDialogContent/AppealDialogContent";
import LsaDialogContent from "../../sections/LowScoreAnalysis/dialogs/LsaDialogContent/LsaDialogContent";
import { getNotifications, postNotificationClear } from "../../sections/NewsFeed/newsFeed.api";
import FriendButton from "../../sections/User/components/FriendButton/FriendButton";
import { t_UserBase } from "../../sections/User/User.types";
import SectionedDialog from "../SectionedDialog/SectionedDialog";
import CustomToolTip from "../Tooltip/Tooltip";
import notificationStyles from "./Notifications.styles";
import {
    t_NotificationAppeal,
    t_NotificationLsa,
    t_NotificationProduction,
    t_Notifications,
} from "./Notifications.types";

type t_NotificationControl = {
    title?: string;
    isOpen: boolean;
} & (
    | {
          notificationType: "none";
      }
    | {
          notificationType: "friend";
          user: t_UserBase;
      }
    | {
          notificationType: "lsa";
          lsa: t_NotificationLsa;
      }
    | {
          notificationType: "appeal";
          appeal: t_NotificationAppeal;
      }
    | {
          notificationType: "production";
          production: t_NotificationProduction;
      }
);

const NotificationPill = (label: string, value: number) => {
    const warnColour = "#ff9800";
    return (
        <Chip
            label={label}
            color="primary"
            style={{
                border: warnColour + " 1px solid",
            }}
            avatar={
                <Avatar
                    style={{
                        backgroundColor: warnColour,
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    {value}
                </Avatar>
            }
        />
    );
};

interface Props {}

const Notifications: React.FC<Props> = () => {
    const classes = notificationStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [notifications, setNotifications] = React.useState<t_Notifications>();
    const [dialogControl, setDialogControl] = useState<t_NotificationControl>({
        notificationType: "none",
        isOpen: false,
    });

    const handleClose = (state: t_NotificationControl) => {
        setLoading(true);
        setDialogControl(state);
    };

    const clearNotification = (id: number) => {
        postNotificationClear({
            notification_id: id,
        }).then(() => {
            setNotifications((state) => {
                return {
                    friend_requests: state?.friend_requests.filter((item) => item.id !== id) ?? [],
                    lsa: state?.lsa.filter((item) => item.id !== id) ?? [],
                    appeal: state?.appeal.filter((item) => item.id !== id) ?? [],
                    production: state?.production.filter((item) => item.id !== id) ?? [],
                };
            });
        });
    };

    React.useEffect(() => {
        var isActive = true;
        if (loading) {
            getNotifications()
                .then((response) => {
                    if (isActive) {
                        const notifications = response.data?.notifications;

                        if (notifications == null) {
                            setNotifications(undefined);
                        } else {
                            const friend_requests = notifications.friend_requests;
                            const lsa = notifications.lsa;
                            const appeal = notifications.appeal;
                            const downtime = notifications.downtime;
                            const additional_volume = notifications.additional_volume;

                            let production = [
                                ...(downtime?.map((item: any) => ({
                                    ...item,
                                    type: "downtime",
                                })) ?? []),
                                ...(additional_volume?.map((item: any) => ({
                                    ...item,
                                    type: "additional_volume",
                                })) ?? []),
                            ];
                            setNotifications({
                                friend_requests,
                                lsa,
                                appeal,
                                production,
                            });
                        }
                    }
                })
                .finally(() => setLoading(false));
        }

        return () => {
            isActive = false;
        };
    }, [loading]);

    return (
        <React.Fragment>
            {notifications && (
                <TableRow>
                    {notifications.lsa.length > 0 && (
                        <CustomToolTip
                            content={
                                <List>
                                    {notifications.lsa.map((lsa) => (
                                        <ListItem
                                            key={lsa.lsa.LsaID}
                                            button
                                            onClick={() =>
                                                setDialogControl({
                                                    title: "Low Score Analysis",
                                                    notificationType: "lsa",
                                                    lsa,
                                                    isOpen: true,
                                                })
                                            }
                                        >
                                            <ListItemText primary={`LSA: ${lsa.user.DisplayName}`} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => clearNotification(lsa.id)} size="large">
                                                    <Close />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            }
                        >
                            <TableCell className={classes.activityItem} padding={"none"}>
                                {NotificationPill("Action required for LSA", notifications.lsa.length)}
                            </TableCell>
                        </CustomToolTip>
                    )}
                    {notifications.friend_requests.length > 0 && (
                        <CustomToolTip
                            content={
                                <List disablePadding>
                                    {notifications.friend_requests.map((friend) => (
                                        <ListItem
                                            key={`friend-notification-${friend.friend_id}`}
                                            button
                                            onClick={() =>
                                                setDialogControl({
                                                    title: "Friend Request",
                                                    notificationType: "friend",
                                                    user: friend.user,
                                                    isOpen: true,
                                                })
                                            }
                                        >
                                            <ListItemText>{friend.user.name}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => clearNotification(friend.id)} size="large">
                                                    <Close />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            }
                        >
                            <TableCell className={classes.activityItem} padding={"none"}>
                                {NotificationPill("Friend Requests", notifications.friend_requests.length)}
                            </TableCell>
                        </CustomToolTip>
                    )}
                    {notifications.appeal.length > 0 && (
                        <CustomToolTip
                            content={
                                <List>
                                    {notifications.appeal.map((appeal) => (
                                        <ListItem
                                            key={appeal.appeal.AppealID}
                                            button
                                            onClick={() =>
                                                setDialogControl({
                                                    title: "Appeal",
                                                    notificationType: "appeal",
                                                    appeal,
                                                    isOpen: true,
                                                })
                                            }
                                        >
                                            <ListItemText primary={`Appeal: ${appeal.user.DisplayName}`} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => clearNotification(appeal.id)} size="large">
                                                    <Close />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            }
                        >
                            <TableCell className={classes.activityItem} padding={"none"}>
                                {NotificationPill("Action for Appeals", notifications.appeal.length)}
                            </TableCell>
                        </CustomToolTip>
                    )}
                    {notifications.production.length > 0 && (
                        <CustomToolTip
                            content={
                                <List>
                                    {notifications.production.map((production) => (
                                        <ListItem
                                            key={production.id}
                                            button
                                            onClick={() => {
                                                const location = {
                                                    pathname: "/performance/production",
                                                    state: {
                                                        entity_no: production.user.entity_no,
                                                        date: production.date,
                                                    },
                                                };
                                                navigate(location);
                                            }}
                                        >
                                            <ListItemText
                                                primary={`${(() => {
                                                    switch (production.type) {
                                                        case "downtime":
                                                            return "Downtime";
                                                        case "additional_volume":
                                                            return "Additional Volume";
                                                        default:
                                                            return "Production";
                                                    }
                                                })()}: ${production.user.name}`}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    onClick={() => clearNotification(production.id)}
                                                    size="large"
                                                >
                                                    <Close />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            }
                        >
                            <TableCell className={classes.activityItem} padding={"none"}>
                                {NotificationPill("Action for Production", notifications.production.length)}
                            </TableCell>
                        </CustomToolTip>
                    )}
                </TableRow>
            )}
            <SectionedDialog
                open={Boolean(dialogControl.isOpen)}
                title={dialogControl.title}
                maxWidth="md"
                // fullWidth
                onClose={() =>
                    handleClose({
                        isOpen: false,
                        notificationType: "none",
                    })
                }
            >
                {dialogControl.notificationType === "friend" && (
                    <Card>
                        <CardHeader
                            title={dialogControl.user.name}
                            subheader={dialogControl.user.job_title}
                            avatar={<Avatar src={dialogControl.user.profile_img} />}
                        />
                        <CardContent>
                            <div className={classes.friendDiv}>
                                <FriendButton entity_no={dialogControl.user.entity_no} />
                            </div>
                        </CardContent>
                    </Card>
                )}
                {dialogControl.notificationType === "lsa" && (
                    <LsaDialogContent
                        lsa_id={dialogControl.lsa.lsa.LsaID}
                        metric_id={dialogControl.lsa.lsa.LsaDataMetricID}
                        record={dialogControl.lsa.lsa.survey_record}
                        lsa={{
                            DataID: dialogControl.lsa.lsa.survey_record.DataID,
                            StatusID: dialogControl.lsa.lsa.StatusID,
                        }}
                    />
                )}
                {dialogControl.notificationType === "appeal" && (
                    <AppealDialogContent
                        data_id={dialogControl.appeal.appeal.survey_record.DataID}
                        metric_id={dialogControl.appeal.appeal.MetricID}
                        record={dialogControl.appeal.appeal.survey_record}
                        appeal_id={dialogControl.appeal.appeal.AppealID}
                        can_submit={dialogControl.appeal.can_appeal}
                        expire_date={dialogControl.appeal.appeal_cutoff}
                        appeal_date={dialogControl.appeal.appeal.AppealDate}
                        dispatch={() =>
                            handleClose({
                                isOpen: false,
                                notificationType: "none",
                            })
                        }
                    />
                )}
            </SectionedDialog>
        </React.Fragment>
    );
};

export default Notifications;
