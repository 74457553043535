import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface EmojiThumb {
    id: number;
    label: string;
    icon: string;
}

interface Column {
    id: string;
    label: string;
    type: string;
}

const SubmittedPipPerformanceTable = ({ data }: any) => {
    const columns: Column[] = [
        {
            id: "options",
            label: "Option",
            type: "input",
        },
        {
            id: "realistic",
            label: "Realistic",
            type: "emojiThumb",
        },
    ];

    const metrics = [
        {
            id: "mbr_metric",
            metric: "MBR",
        },
        {
            id: "fcr_metric",
            metric: "FCR",
        },
        {
            id: "dpmo_metric",
            metric: "DPMO",
        },
        {
            id: "production_metric",
            metric: "Production",
        },
    ];

    const createData = (metric: string, data: any) => {
        const metricData = data[metric];
        return {
            metric,
            option: metricData?.option || "",
            measure: metricData?.measure || "",
            by_when: metricData?.by_when || "",
            realistic: metricData?.realistic || "",
        };
    };

    const initialRowsState = metrics.reduce((acc: any, metric) => {
        acc[metric.id] = createData(metric.id, data);
        return acc;
    }, {});

    const [rows, setRows] = useState<{ [key: string]: { metric: string; options: string; realistic: string } }>(
        initialRowsState,
    );

    useEffect(() => {
        setRows(initialRowsState);
    }, [data]);

    const metricsWithData = metrics.filter((metric) => {
        const metricData = data[metric.id];

        if (!metricData) {
            return;
        }

        return (
            metricData.from != null ||
            metricData.my_thoughts != null ||
            metricData.options != null ||
            metricData.realistic != null ||
            metricData.review_date != null ||
            metricData.tl_performance_concerns != null ||
            metricData.to != null
        );
    });

    return (
        <TableContainer>
            {metricsWithData.length > 0 ? (
                metricsWithData.map((metric, metricIndex) => (
                    <Table stickyHeader key={metric.id} sx={{ marginBottom: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }} colSpan={2} align="center">
                                    {metric.metric}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>TL Performance Concerns</TableCell>
                                <TableCell> {data[metric.id]?.tl_performance_concerns}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>My Thoughts and findings</TableCell>
                                <TableCell> {data[metric.id]?.my_thoughts}</TableCell>
                            </TableRow>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell align="center" key={column.id} sx={{ fontWeight: "bold" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Row
                                row={rows[metric.id]}
                                metricIndex={metric.id}
                                columns={columns}
                                //@ts-ignore
                            />
                        </TableBody>
                    </Table>
                ))
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> No data to display currently.</Typography>
                </Box>
            )}
        </TableContainer>
    );
};

interface RowProps {
    row: { metric: string; options: string; realistic: string };
    metricIndex: any;
    // rowIndex: number;
    columns: Column[];
}

const Row: React.FC<RowProps> = ({ row, metricIndex, columns }) => {
    return (
        <TableRow>
            {columns?.map((column) => {
                //@ts-ignore
                const value = row[column.id];

                return (
                    <TableCell
                        key={column.id}
                        align="center"
                        sx={{
                            backgroundColor:
                                value == "😀" || value == "👍" || value == "✔"
                                    ? "#D4EFDF"
                                    : value == "😐"
                                    ? "#FCF3CF"
                                    : value == "🙁" || value == "👎" || value == "❌"
                                    ? "#F5B7B1 "
                                    : "",
                        }}
                    >
                        {column.type == "date"
                            ? moment(value, true).isValid()
                                ? moment(value).format("DD-MM-YYYY")
                                : ""
                            : value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default SubmittedPipPerformanceTable;
