import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { themeColors } from "../../../../context/theme/theme.ctx";

const CoachProfileStyles = makeStyles((theme: Theme) =>
    createStyles({
        editIcon: {
            color: theme.palette.info.main,
            backgroundColor: theme.palette.secondary.main,
        },
        profileStats: {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-around",

            "& img": {
                width: 30,
                height: 30,
                objectFit: "contain",
            },
            "& span": {
                background: "#c6db34",
                borderRadius: 20,
                padding: 3,
                color: "#fff",
                fontWeight: "bold",
            },
        },
        largeAvatar: {
            height: 80,
            width: 80,
            objectFit: "contain",
        },
        smallAvatar: {
            height: 30,
            width: 30,
            objectFit: "contain",
        },
        placePosition: {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        rankPositionIncreasePill: {
            background: "#c6db34",
        },
        rankPositionDecreasePill: {
            background: "#c6db34",
        },
        card: {
            paddingBottom: 0,
            width: "100%",
            backgroundColor: `${themeColors(theme.palette.mode).swBg}`,
        },
    }),
);

export default CoachProfileStyles;
