import { Column, Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_AppealRecord } from "../../Appeals.types";

const AppealRecordColumns: Column<t_AppealRecord>[] = [
	{ title: "AppealID", field: "AppealID" },
	{ title: "EntityNo", field: "EntityNo" },
	{ title: "CallID", field: "CallID" },
	{ title: "SurveyTypeID", field: "SurveyTypeID" },
	{ title: "SurveyDate", field: "SurveyDate" },
	{ title: "SurveyScore", field: "SurveyScore" },
	{ title: "ExpireDate", field: "ExpireDate" },
	{ title: "AppealComment", field: "AppealComment" },
	{ title: "Status", field: "Status", defaultGroupOrder: 0 },
];

const getAppealRecords = (payload: {
	query: Query<t_AppealRecord>;
	month: string;
}) =>
	new Promise<QueryResult<t_AppealRecord>>((resolve, reject) => {
		const body = {
			month: payload.month,
			...convertQuery(payload.query),
		};

		httpClient.post("/appeal/records", body).then(({ data }) =>
			resolve({
				data: data.data.appeals.data,
				page: data.pagination.page - 1,
				totalCount: data.total_results,
			})
		);
	});

export { AppealRecordColumns, getAppealRecords };
