import { Card, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import { getMetricFormat } from "../../../../misc/helpers";
import { t_PerformanceUser } from "../../Performance.ctx";
import userPerformanceSummaryStyles from "./UserPerformanceSummary.style";

interface Props {
	viewUser?: t_PerformanceUser;
	viewMetric?: any;
	onMetricSelected?: (metric: any) => void;
}

const UserPerformanceSummary: React.FC<Props> = ({
	viewUser,
	viewMetric,
	onMetricSelected,
}) => {
	const classes = userPerformanceSummaryStyles();

	const handleMetricSelect = (metric: any) => {
		onMetricSelected && onMetricSelected(metric);
	};

	return (
		<div className={classes.metricContainer}>
			{viewUser?.metrics.map((metric) => (
				<Card
					key={`metric-cell-${metric.id}`}
					onClick={() => handleMetricSelect(metric)}
					className={clsx(classes.metricDetails, {
						[classes.activeMetric]: viewMetric?.id === metric.id,
					})}
				>
					<Typography
						variant='caption'
						className={classes.metricLabel}
					>
						{metric.name}
					</Typography>
					<span className={classes.metricValue}>
						{metric.details?.comparison != null && (
							<div className={classes.metricComparison}>
								{metric.value > metric.details.comparison && (
									<ArrowDropUp />
								)}
								{metric.value < metric.details.comparison && (
									<ArrowDropDown />
								)}
							</div>
						)}
						{getMetricFormat(metric.id, metric.value)}
					</span>
					<div className={classes.metricTarget}>
						<Typography variant='caption'>
							{metric.target != null && `/${metric.target}`}
						</Typography>
					</div>
				</Card>
			))}
		</div>
	);
};

export default UserPerformanceSummary;
