import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_LeaveOption } from "./LeaveOptions.types";

const getLeaveOptions = (payload: { query: Query<t_LeaveOption> }) => {
	return new Promise<QueryResult<t_LeaveOption>>((resolve, reject) => {
		const body = {
			...convertQuery(payload.query),
		};

		httpClient
			.post("/admin/production/external_leave", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve({
							data: data.data.leave_types,
							page: data.pagination.page - 1,
							totalCount: data.total_results,
						});
						break;

					default:
						reject(data.status);
						break;
				}
			});
	});
};

const updateLeaveOption = (payload: { option: t_LeaveOption }) => {
	return new Promise((resolve, reject) => {
		const body = {
			leave_type_id: payload.option.id,
			leave_name: payload.option.name,
		};
		httpClient
			.post("/admin/production/external_leave/update", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

const deleteLeaveOption = (payload: { option_id: t_LeaveOption["id"] }) => {
	return new Promise((resolve, reject) => {
		const body = {
			leave_type_id: payload.option_id,
		};
		httpClient
			.post("/admin/production/external_leave/delete", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

export { getLeaveOptions, updateLeaveOption, deleteLeaveOption };
