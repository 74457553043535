import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

const emojiThumb = [
    {
        id: 1,
        label: "",
        icon: "\u{1F44D}",
    },
    {
        id: 2,
        label: "",
        icon: "\u{1F44E}",
    },
];

interface Column {
    id: string;
    label: string;
    type: string;
}

const SubmittedMetricRatingTable = ({
    setCombinedData,
    viewMetrics,
}: {
    data: any[];
    setCombinedData: any;
    viewMetrics: any;
}) => {
    const columns = [
        {
            id: "option",
            label: "Option",
            type: "input",
        },
        {
            id: "measure",
            label: "Measure",
            type: "input",
        },
        {
            id: "by_when",
            label: "By When",
            type: "date",
        },
        {
            id: "realistic",
            label: "Realistic",
            type: "emojiThumb",
        },
    ];

    const createData = (metricId: string, metric: string) => {
        return { metricId, metric, option: "", measure: "", by_when: "", realistic: "" };
    };

    const initialRowsState = viewMetrics.reduce((acc: any, metric: any) => {
        acc[metric.id] = createData(metric.id, metric.name);
        return acc;
    }, {});

    const [rows, setRows] = useState<{
        [key: string]: {
            metricId: string;
            metric: string;
            option: string;
            measure: string;
            by_when: string;
            realistic: string;
        };
    }>(initialRowsState);

    const handleInputChange = (metricId: number, columnId: string, value: string) => {
        setRows((prevRows) => ({
            ...prevRows,
            [metricId]: {
                ...prevRows[metricId],
                [columnId]: value,
            },
        }));
    };

    useEffect(() => {
        setCombinedData((prevState: any) => ({
            ...prevState,
            metricRatings: Object.values(rows),
        }));
    }, [rows, setCombinedData]);

    useEffect(() => {
        setRows(initialRowsState);
    }, [viewMetrics]);

    return (
        <TableContainer>
            {viewMetrics.length > 0 ? (
                viewMetrics?.map((metric: any) => (
                    <Table stickyHeader key={metric.id} sx={{ marginBottom: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                                    {metric.name}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Target</TableCell>
                                <TextField
                                    sx={{ width: "100%" }}
                                    onChange={(e) => handleInputChange(metric.id, "new_target", e.target.value)}
                                />
                            </TableRow>
                            <TableRow>
                                {columns?.map((column) => (
                                    <TableCell key={column.id} sx={{ fontWeight: "bold" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <Row
                                row={rows[metric.id]}
                                metricIndex={metric.id}
                                columns={columns}
                                //@ts-ignore
                                handleInputChange={handleInputChange}
                            />
                        </TableBody>
                    </Table>
                ))
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> Please select metrics to display</Typography>
                </Box>
            )}
        </TableContainer>
    );
};

interface RowProps {
    row: { metric: string; option: string; realistic: string };
    metricIndex: number;
    // rowIndex: number;
    columns: Column[];
    handleInputChange: (metricIndex: number, columnId: string, value: string) => void;
}

const Row: React.FC<RowProps> = ({ row, metricIndex, columns, handleInputChange }) => {
    if (!row) {
        return;
    }
    return (
        <TableRow>
            {columns?.map((column) => {
                //@ts-ignore
                const value = row[column.id];

                return (
                    <TableCell key={column.id}>
                        {column.type === "name" ? (
                            value
                        ) : column.type === "date" ? (
                            <DatePicker
                                value={value}
                                onChange={(newValue: any) => handleInputChange(metricIndex, column.id, newValue)}
                                format="dd/MM/yyyy"
                            />
                        ) : column.type === "emojiThumb" ? (
                            <SelectComponent
                                value={value}
                                onChange={(newValue: any) => handleInputChange(metricIndex, column.id, newValue)}
                            />
                        ) : (
                            <TextField
                                sx={{ width: "100%" }}
                                value={value}
                                onChange={(e) => handleInputChange(metricIndex, column.id, e.target.value)}
                            />
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

const SelectComponent = ({ value, onChange }: any) => {
    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <FormControl sx={{ width: "100%" }}>
            <InputLabel>Reaction Options</InputLabel>
            <Select
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: value == "👍" ? "#D4EFDF" : value == "👎" ? "#F5B7B1 " : "",
                }}
                variant="outlined"
                displayEmpty
                value={value}
                onChange={handleChange}
            >
                {emojiThumb?.map((listItem) => (
                    <MenuItem
                        key={listItem.id}
                        value={listItem.icon}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {listItem.icon}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SubmittedMetricRatingTable;
