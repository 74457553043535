import { httpClient } from "../../../../../../clients/http.client";
import { t_SurveyPeriod } from "../../../../../Surveys/Surveys.types";
import { t_AppealCategoryGraphData } from "./AppealCategoryGraph.types";

const getAppealCategoryData = (payload: {
	survey: "mbr" | "fcr";
	period: t_SurveyPeriod;
}) => {
	return new Promise<t_AppealCategoryGraphData[]>((resolve, reject) => {
		const body = {
			start_date: payload.period.start_date,
			end_date: payload.period.end_date,
			survey: payload.survey,
		};
		httpClient
			.post("/reports/appeal/graphs/category", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.graph_data);
						break;
					default:
						reject(data.message);
						break;
				}
			});
	});
};
export { getAppealCategoryData };
