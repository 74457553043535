import React from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import { useUserState } from "../../../context/user/user.ctx";
import FriendSearch from "../../../sections/User/sections/FriendSearch/FriendSearch";
import FullProfile from "../../../sections/User/sections/FullProfile/FullProfile";

const Profile: React.FC = () => {
    const user = useUserState();
    const [sectionTab, setSectionTab] = React.useState(0);

    return (
        <SectionCard
            title="View Profile"
            primaryAction={
                <TabGroup
                    tabValue={sectionTab}
                    tabChange={(newValue) => setSectionTab(newValue)}
                    tabList={[
                        { _id: 0, label: "My Profile" },
                        { _id: 1, label: "Friends" },
                    ]}
                />
            }
            fullWidth={sectionTab !== 0}
            scrolling={sectionTab === 0}
        >
            {sectionTab === 0 && <FullProfile entity_no={user.entity_no} editable />}
            {sectionTab === 1 && <FriendSearch />}
        </SectionCard>
    );
};

export default Profile;
