import { Grid } from "@mui/material";
import React from "react";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import useMultiDateSelect from "../../../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { useReportExport } from "../../../../hooks/export/useReportExport";
import { getApiUrl } from "../../../../misc/helpers";
import AverageActualProduction from "../../graphs/AverageActualProduction/AverageActualProduction";
import AverageAdditionalVolume from "../../graphs/AverageAdditionalVolume/AverageAdditionalVolume";
import AverageCategoryDowntime from "../../graphs/AverageCategoryDowntime/AverageCategoryDowntime";
import AverageMonthlyDowntime from "../../graphs/AverageMonthlyDowntime/AverageMonthlyDowntime";
import ProductionTree from "../../tables/ProductionTree/ProductionTree";

const ProductionReport: React.FC = () => {
    const { loading: exportLoading, downloadExportFile } = useReportExport();
    const [, setSelectedDate] = React.useState<Date>(new Date(Date.now()));
    const [sectionTab, setSectionTab] = React.useState<number>(0);

    const { datePills, yearAndMonthSelector, formattedPeriods, selectedDate } = useMultiDateSelect();

    const handleExport = (file_type: string) => {
        const production_url = new URL(`${getApiUrl()}/reports/production/export/multi`);

        production_url.searchParams.append("months", formattedPeriods.toString());
        production_url.searchParams.append("file_type", file_type);

        downloadExportFile(production_url.toString(), "Production Export");
    };

    return (
        <SectionCard
            title="Production Report"
            primaryAction={
                <TabGroup
                    tabValue={sectionTab}
                    tabChange={(newValue) => setSectionTab(newValue)}
                    tabList={[
                        { _id: 0, label: "Dashboard" },
                        { _id: 1, label: "Details" },
                    ]}
                />
            }
            secondaryAction={yearAndMonthSelector}
            scrolling
        >
            <Grid item xs={12} style={{ marginBottom: "2em" }}>
                {datePills}
            </Grid>

            {sectionTab === 0 && (
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <AverageActualProduction dates={formattedPeriods} />
                    </Grid>
                    <Grid item sm={6}>
                        <AverageMonthlyDowntime dates={formattedPeriods} />
                    </Grid>
                    <Grid item sm={6}>
                        <AverageAdditionalVolume dates={formattedPeriods} />
                    </Grid>

                    <Grid item sm={6}>
                        <AverageCategoryDowntime dates={formattedPeriods} />
                    </Grid>
                </Grid>
            )}
            {sectionTab === 1 && selectedDate && (
                <SectionCard
                    title="Detailed Production"
                    titleProps={{ variant: "subtitle1" }}
                    fullWidth
                    secondaryAction={
                        <ExportButton dispatch={handleExport} loading={exportLoading} disabled={exportLoading}>
                            Export
                        </ExportButton>
                    }
                    scrolling
                >
                    <ProductionTree months={formattedPeriods} />
                </SectionCard>
            )}
        </SectionCard>
    );
};

export default ProductionReport;
