import { format } from "date-fns";
import { httpClient } from "../../clients/http.client";
import { t_PoolResponse, t_TWTgroupResponse } from "./TwtGroup.types";

const getTWTgroups = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post<t_TWTgroupResponse>("/admin/twt/grouping", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const groups = data.data.records.data;
            const retData = groups
              .filter((group) => group.Active === 1)
              .map((group) => {
                return {
                  id: group.GroupID,
                  Name: group.Name,
                  service_team: group.TwtServiceEnvironmentName,
                  stream: group.TwtRegionName,
                  pool_count: 0,
                };
              });

            res({
              data: retData,
              page: data.pagination.page - 1,
              totalCount: data.total_results,
            });
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const saveGroupName = (payload: { id?: number; name: string, twt_service_environment_id? : number, twt_region_id?: number }) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/grouping/savegroup", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res();
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const getPoolsInGroup = (payload: { group_id: number }) => {
  return new Promise<t_PoolResponse[]>((res, rej) => {
    httpClient
      .post("/admin/twt/grouping/getindividualpoolgroup", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res(data.data.records);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const addPoolToGroup = (payload: { pools: string[]; group_id: number }) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/grouping/addtogroup", {
        ...payload,
        date_from: format(new Date(), "yyyy-MM-dd"),
      })
      .then((resp) => {
        switch (resp.data.status) {
          case "success":
            res(resp.data);
            break;
          default:
            rej(resp.data.message);
        }
      })
      .catch((err) => {
        rej(err.response.data.message);
      });
  });
};

const deletePoolInGroup = (payload: { id: string }) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/grouping/deleteindividualpoolgroup", payload)
      .then(({ data }) => {
        const respStr = data.toString();
        const resp = JSON.parse(respStr.slice(respStr.indexOf("{")));
        switch (resp.status) {
          case "success":
            res();
            break;
          default:
            rej(resp.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const deletePoolGroup = (payload: { id: number }) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/grouping/delete", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res();
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

export {
  getTWTgroups,
  getPoolsInGroup,
  deletePoolInGroup,
  saveGroupName,
  deletePoolGroup,
  addPoolToGroup,
};
