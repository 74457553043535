import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const surveyButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        valid: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.info.dark,
            },
        },
        partial: {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.warning.dark,
            },
        },
        incomplete: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            },
        },
        default: {
            backgroundColor: "initial",
            color: "initial",
            "&:hover": {
                backgroundColor: "initial",
            },
        },
    }),
);

export default surveyButtonStyles;
