import { Box, Button, Chip, Dialog, Grid } from "@mui/material";
import clsx from "clsx";
import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import InitiativeOption from "../../forms/InitiativeOption/InitiativeOption";
import { toggleOptionActive } from "../../forms/InitiativeOption/InitiativeOption.api";
import { getInitiativeOptions } from "./InitiativeOptions.api";
import initiativeOptionsStyles from "./InitiativeOptions.styles";
import { t_InitiativeOption } from "./InitiativeOptions.types";

const InitiativeOptions: React.FC = () => {
    const classes = initiativeOptionsStyles();
    const tableRef = React.createRef<any>();

    const [dialogState, setDialogState] = React.useState<{
        open: boolean;
        row?: t_InitiativeOption;
    }>({
        open: false,
    });
    const [columns] = React.useState<Column<t_InitiativeOption>[]>([
        {
            title: "Active",
            field: "active",
            render: (data, type) => (
                <Button
                    variant={!data.active ? "text" : undefined}
                    className={clsx(classes.activeButton, {
                        [classes.isActive]: data.active,
                    })}
                    onClick={() => handleActiveToggle(data)}
                    sx={{ margin: 1 }}
                >
                    {data.active ? "Active" : "Inactive"}
                </Button>
            ),
            align: "center",
            // cellStyle: {
            //     padding: 0,
            // },
        },
        {
            title: "Initiative name",
            field: "name",
        },
        {
            title: "Incentive categories",
            render: (data, type) =>
                data.all_incentive_categories ? (
                    "All"
                ) : (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="flex-start"
                        className={classes.categoryGrid}
                    >
                        {data.incentive_categories?.map((category) => (
                            <Chip size="small" label={category.name} />
                        ))}
                    </Grid>
                ),
        },
        {
            title: "Expected handling time (min)",
            field: "handling_time",
        },
        {
            title: "Weight",
            field: "weight",
        },
    ]);

    const handleActiveToggle = (row: t_InitiativeOption) => {
        toggleOptionActive({ option: row }).then((success) => {
            if (success) {
                tableRef.current && tableRef.current.onQueryChange();
            }
        });
    };
    const handleDialogClose = (reason?: string) => {
        switch (reason) {
            case "submitted":
                tableRef.current && tableRef.current.onQueryChange();
                break;

            default:
                break;
        }
        setDialogState({ open: false });
    };

    return (
        <>
            <BUMaterialTable
                title="Initiatives"
                tableRef={tableRef}
                columns={columns}
                data={(query) => getInitiativeOptions({ query })}
                options={{
                    actionsColumnIndex: -1,
                    padding: "dense",
                }}
                actions={[
                    {
                        icon: "edit",
                        tooltip: "Edit",
                        onClick: (_, data) =>
                            setDialogState({
                                open: true,
                                row: data as t_InitiativeOption,
                            }),
                    },

                    /** @MM - Free actions (appear in header / toolbar) */
                    {
                        icon: "add_box",
                        tooltip: "Add",
                        onClick: () => setDialogState({ open: true }),
                        isFreeAction: true,
                        disabled: false,
                    },
                ]}
            />
            <Dialog open={dialogState.open} onClose={() => handleDialogClose()}>
                <InitiativeOption option={dialogState.row} onClose={handleDialogClose} />
            </Dialog>
        </>
    );
};

export default InitiativeOptions;
