import { Close, Delete } from "@mui/icons-material";
import { Box, Grid, IconButton, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import React from "react";

const ViewMembers = ({ setIsViewMembers, participants }: any) => {
    return (
        <Grid container spacing={2} sx={{}}>
            <Grid item xs={12} mb={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <Typography fontWeight="bold" variant="h5">
                        Members in thread
                    </Typography>
                    <IconButton onClick={() => setIsViewMembers(false)}>
                        <Close />
                    </IconButton>
                </Box>
                {/* <Divider /> */}
                <Box p={2}>
                    {participants?.map((participant: any) => (
                        <ListItem>
                            <ListItemText>{participant.DisplayName}</ListItemText>
                            <ListItemIcon>
                                <IconButton>
                                    <Delete sx={{ color: "#DC143C" }} />
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ViewMembers;
