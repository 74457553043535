import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import React from "react";
// import Help from "../../pages/Home/Help/Help";
import helpFileStyles from "./HelpFile.style";

type Props = {
	title: string;
	content: string;
};

const HelpFile: React.FC<Props> = ({ title, content }) => {
	const classes = helpFileStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
        <Card variant='outlined' className={classes.card}>
			<CardHeader
				title={title}
				action={
					<IconButton
                        className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label='show more'
                        size="large">
						<ExpandMoreIcon />
					</IconButton>
				}
			></CardHeader>

			<Collapse in={expanded} timeout='auto' unmountOnExit>
				<CardContent>
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</CardContent>
			</Collapse>
		</Card>
    );
};

export default HelpFile;
