import { Typography } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Column, MaterialTableProps } from "material-table";
import React, { useState } from "react";
import BUMaterialTable from "../BUMaterialTable/BUMaterialTable";
import { groupedHeaderTableStyle } from "./GroupedHeaderTable.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DarkTooltip from "../Tooltip/DarkTooltip";
import { toolTips } from "../../sections/PerceptionReport/reports/LsaReport/LsaReport.constants";

type GroupedHeaderTableFilterProps<RowData extends object> = {
    forField: "service_team" | "team" | "job_title";
    columns: Array<
        Column<RowData> & {
            header?: string;
            render?: Column<RowData>["render"];
            columns?: Column<RowData>[];
        }
    >;
} & MaterialTableProps<RowData>;

const GroupedHeaderTableFilter: React.FC<GroupedHeaderTableFilterProps<any>> = ({
    forField,
    columns,
    ...tableProps
}) => {
    const classes = groupedHeaderTableStyle();
    const [activeColumns, setActiveColumns] = useState(columns.map(() => 0));

    const handleColumnSelect = (event: any, groupIndex: number, colIndex: number) => {
        event.stopPropagation();

        if (groupIndex !== null && colIndex != null) {
            setActiveColumns((state) => {
                let newState = Array.from(state);
                newState[groupIndex] = colIndex;
                return newState;
            });
        }
    };
    return (
        <BUMaterialTable
            {...tableProps}
            columns={columns.map((group, groupIndex) => ({
                ...group,
                title: (
                    <div className={classes.groupContainer}>
                        {group.field && toolTips[forField] && toolTips[forField][group.field.toString()] ? (
                            <DarkTooltip title={toolTips[forField][group.field.toString()]} placement="top" arrow>
                                <span>
                                    {group.header} <InfoOutlinedIcon style={{ fontSize: "12px", display: "inline" }} />
                                </span>
                            </DarkTooltip>
                        ) : (
                            <span>{group.header}</span>
                        )}
                        {group.columns && group.columns.length > 1 && (
                            <ToggleButtonGroup
                                value={activeColumns[groupIndex]}
                                exclusive
                                size="small"
                                onChange={(e, value) => handleColumnSelect(e, groupIndex, value)}
                            >
                                {group.columns.map((column, colIndex) => (
                                    <ToggleButton
                                        key={`${group.header}-${column.title}`}
                                        classes={{
                                            root: classes.toggleButton,
                                            selected: classes.activeButton,
                                        }}
                                        value={colIndex}
                                    >
                                        <Typography variant="caption">{column.title}</Typography>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        )}
                    </div>
                ),
                field: group.columns ? group.columns[activeColumns[groupIndex]].field : group.field,
                render: group.render,
            }))}
        />
    );
};

export type { GroupedHeaderTableFilterProps };
export default GroupedHeaderTableFilter;
