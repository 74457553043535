import React from "react";
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";
import { t_InitiativeType } from "../../forms/AdditionalProduction/AdditionalProduction.types";
import { getTotalAdditionalVolumeItemsGraphData } from "./TotalAdditionalVolumeItems.api";
import { t_TotalAdditionalVolumeItem } from "./TotalAdditionalVolumeItems.types";

interface Props {
	incentive_category?: t_IncentiveType;
	initiative?: t_InitiativeType;
}

const TotalAdditionalVolumeItems: React.FC<Props> = ({
	incentive_category,
	initiative,
}) => {
	const [chartData, setChartData] =
		React.useState<t_TotalAdditionalVolumeItem[]>();

	React.useEffect(() => {
		var isActive = true;
		if (incentive_category && initiative) {
			getTotalAdditionalVolumeItemsGraphData({
				incentive_id: incentive_category.id,
				initiative_id: initiative?.id ?? 0,
			}).then((data) => {
				isActive && setChartData(data);
			});
		}

		return () => {
			isActive = false;
		};
	}, [incentive_category, initiative]);

	return (
		<SectionCard
			title='Total Items per Month'
			titleProps={{ variant: "h6" }}
			hasBackground
		>
			<ResponsiveContainer height={400}>
				<ComposedChart data={chartData}>
					<XAxis dataKey='month' />
					<YAxis />
					<Tooltip />
					<CartesianGrid />
					<Bar dataKey='volume' fill='#8884d8' />
				</ComposedChart>
			</ResponsiveContainer>
		</SectionCard>
	);
};

export default TotalAdditionalVolumeItems;
