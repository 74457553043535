import { Box, Button, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { Add, Cancel, Edit, Save } from "@mui/icons-material";
import { Pagination } from "@mui/material";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import MUIRichTextEditor, { TMUIRichTextEditorRef } from "mui-rte";
import { useSnackbar } from "notistack";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { sectionCardInnerStyles } from "../../../../components/SectionCard/SectionCard.style";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { MiscBody } from "../../../../types/api.types";
import { t_HelpFile } from "../../components/HelpFile/HelpFile.types";
import { getHelpFiles } from "../HelpFileSearch/HelpFileSearch.api";
import { saveHelpFile } from "./MaintainHelpFiles.api";
import maintainHelpFilesStyles from "./MaintainHelpFiles.styles";

const MaintainHelpFiles: React.FC = () => {
    // state stuff
    const gridClasses = sectionCardInnerStyles();
    const editorClasses = maintainHelpFilesStyles();

    const { enqueueSnackbar } = useSnackbar();
    // files
    const [search, setSearch] = React.useState<string>();
    const [helpFiles, setHelpFiles] = React.useState<t_HelpFile[]>();
    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>({
        page: 1,
        rows_per_page: 10,
    });
    // edit
    const editorRef = React.createRef<TMUIRichTextEditorRef>();
    const [selectedFile, setSelectedFile] = React.useState<
        t_HelpFile & { editorState?: EditorState; converted_content?: string }
    >();
    const [editing, setEditing] = React.useState<boolean>(false);
    const [saving, setSaving] = React.useState<boolean>(false);

    // methods
    const handleFileSelect = (file: t_HelpFile) => {
        setSelectedFile(file);
        setEditing(false);
    };
    const handleCancel = () => {
        setSelectedFile(undefined);
        setEditing(false);
    };
    const handleSave = () => {
        const contentState = selectedFile?.editorState?.getCurrentContent();

        if (selectedFile?.title && contentState != null) {
            setSaving(true);
            const content = stateToHTML(contentState);

            saveHelpFile({
                id: selectedFile.id,
                title: selectedFile.title,
                content,
            })
                .then(() => {
                    enqueueSnackbar("Help file saved successfully.");
                })
                .catch(() => {
                    enqueueSnackbar("We ran into an error saving the help file.", { variant: "error" });
                })
                .finally(() => {
                    setSaving(false);
                    setSearch("");
                    setEditing(false);
                });
        }
    };
    const handleEdit = () => {
        if (selectedFile != null) {
            setEditing(true);
        }
    };
    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(
            (state) =>
                state && {
                    ...state,
                    title: event.target.value,
                },
        );
    };
    const handleNewHelpFile = () => {
        setSelectedFile({
            title: "",
            content: "",
        });
        setEditing(true);
    };

    const onEditorChange = (editorState: EditorState) => {
        setSelectedFile(
            (state) =>
                state && {
                    ...state,
                    editorState,
                },
        );
    };

    React.useEffect(() => {
        var isActive = true;
        getHelpFiles({
            search,
            pagination,
        }).then((data) => {
            if (isActive) {
                setHelpFiles(data.data.help_files);
                if (data.total_results != null && data.pagination != null) {
                    setPagination({
                        ...data.pagination,
                    });
                }
            }
        });
        return () => {
            isActive = false;
        };
    }, [search, pagination?.page]);

    React.useEffect(() => {
        if (selectedFile != null) {
            // https://github.com/niuware/mui-rte/issues/25#issuecomment-537736297

            // 1. Convert the HTML
            const contentHTML = convertFromHTML(selectedFile.content);

            // 2. Create the ContentState object
            const contentState = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);

            const editorState = EditorState.createWithContent(contentState);

            const converted_content = JSON.stringify(convertToRaw(contentState));

            setSelectedFile({
                ...selectedFile,
                editorState,
                converted_content,
            });
        }
    }, [selectedFile?.id]);

    return (
        <SectionCard title="Maintain Help Files">
            <Grid container spacing={2} className={gridClasses.gridContainer}>
                <Grid item sm={4} className={gridClasses.gridItem}>
                    <SectionCard
                        hasBackground
                        primaryAction={
                            <TextField label="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                        }
                        bottomActions={
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Pagination
                                    page={pagination?.page}
                                    count={pagination?.count}
                                    onChange={(_, page) => setPagination((state) => state && { ...state, page })}
                                />
                            </div>
                        }
                    >
                        <List>
                            {helpFiles?.map((file) => (
                                <ListItem key={`helpfile-${file.id}`} button onClick={() => handleFileSelect(file)}>
                                    <ListItemText primary={file.title} />
                                </ListItem>
                            ))}
                        </List>
                    </SectionCard>
                </Grid>
                <Grid item sm={8} className={gridClasses.gridItem}>
                    <SectionCard
                        hasBackground
                        title="Editor"
                        primaryAction={
                            !editing ? (
                                <Typography>{selectedFile?.title}</Typography>
                            ) : (
                                <TextField
                                    value={selectedFile?.title}
                                    placeholder="Help file title..."
                                    onChange={onTitleChange}
                                />
                            )
                        }
                        secondaryAction={
                            selectedFile != null ? (
                                <Box display="flex">
                                    {!editing ? (
                                        <Button
                                            startIcon={<Edit />}
                                            disabled={selectedFile == null}
                                            onClick={handleEdit}
                                        >
                                            Edit
                                        </Button>
                                    ) : (
                                        <React.Fragment>
                                            <SubmitButton
                                                startIcon={<Save />}
                                                disabled={selectedFile == null}
                                                onClick={handleSave}
                                                loading={saving}
                                            >
                                                Save
                                            </SubmitButton>
                                            <Button
                                                startIcon={<Cancel />}
                                                disabled={selectedFile == null}
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Box>
                            ) : (
                                <Button variant="contained" startIcon={<Add />} onClick={handleNewHelpFile}>
                                    Create New
                                </Button>
                            )
                        }
                    >
                        <MUIRichTextEditor
                            ref={editorRef}
                            readOnly={!editing || selectedFile == null}
                            defaultValue={selectedFile?.converted_content}
                            classes={{
                                root: editorClasses.root,
                                container: editorClasses.container,
                                editor: editorClasses.editor,
                                toolbar: editorClasses.toolbar,
                                placeHolder: editorClasses.placeHolder,
                                anchorLink: editorClasses.anchorLink,
                                editorContainer: editorClasses.editorContainer,
                            }}
                            controls={[
                                "title",
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                // "highlight",
                                "undo",
                                "redo",
                                "link",
                                // "media",
                                "numberList",
                                "bulletList",
                                "quote",
                                // "code",
                                "clear",
                                // "save",
                            ]}
                            onChange={onEditorChange}
                        />
                    </SectionCard>
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default MaintainHelpFiles;
