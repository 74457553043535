import React from "react";

export interface Props {
    maskID: string;
}

// Helper function to create color components
function makeColor(name: string, color: string) {
    const ColorComponent: React.FC<Props> = ({ maskID }) => (
        <g id={`Skin/${name}`} mask={`url(#${maskID})`} fill={color}>
            <g transform="translate(0.000000, 0.000000)" id="Color">
                <rect x="0" y="0" width="264" height="280" />
            </g>
        </g>
    );

    ColorComponent.displayName = name;
    return ColorComponent;
}

const Tanned = makeColor("Tanned", "#FD9841");
const Yellow = makeColor("Yellow", "#F8D25C");
const Pale = makeColor("Pale", "#FFDBB4");
const Light = makeColor("Light", "#EDB98A");
const Brown = makeColor("Brown", "#D08B5B");
const DarkBrown = makeColor("DarkBrown", "#AE5D29");
const Black = makeColor("Black", "#614335");

// Additional skin tones
const Ivory = makeColor("Ivory", "#FFDFC4");
const Beige = makeColor("Beige", "#F0D5BE");
const Peach = makeColor("Peach", "#E1A95F");
const Sand = makeColor("Sand", "#E4B98E");
const Honey = makeColor("Honey", "#D9A877");
const Amber = makeColor("Amber", "#D2A065");
const Bronze = makeColor("Bronze", "#B97A56");
const Caramel = makeColor("Caramel", "#A5694F");
const Cocoa = makeColor("Cocoa", "#8D5524");
const Chestnut = makeColor("Chestnut", "#5D4037");
const Mocha = makeColor("Mocha", "#4E342E");
const Espresso = makeColor("Espresso", "#3B2C2C");
const Ebony = makeColor("Ebony", "#1C0D0D");

export type SkinOption =
    | "Tanned"
    | "Yellow"
    | "Pale"
    | "Light"
    | "Brown"
    | "DarkBrown"
    | "Black"
    | "Ivory"
    | "Beige"
    | "Peach"
    | "Sand"
    | "Honey"
    | "Amber"
    | "Bronze"
    | "Caramel"
    | "Cocoa"
    | "Chestnut"
    | "Mocha"
    | "Espresso"
    | "Ebony"
    | "default";

const skinComponentsMap: Record<SkinOption, React.ComponentType<Props>> = {
    Tanned,
    Yellow,
    Pale,
    Light,
    Brown,
    DarkBrown,
    Black,
    Ivory,
    Beige,
    Peach,
    Sand,
    Honey,
    Amber,
    Bronze,
    Caramel,
    Cocoa,
    Chestnut,
    Mocha,
    Espresso,
    Ebony,
    default: Light,
};

interface SkinProps extends Props {
    option: SkinOption;
}

const Skin: React.FC<SkinProps> = ({ maskID, option = "default" }) => {
    const SelectedSkin = skinComponentsMap[option] || skinComponentsMap["default"];
    return <SelectedSkin maskID={maskID} />;
};

export default Skin;
