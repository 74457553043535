type t_SurveyPeriod = {
	id: string;
	start_date: string;
	end_date: string;
	month: string;
	appeal_expire: string;
	last_capture: string;
	survey_type: {
		id: string;
		name: string;
	};
};

const c_SurveyPeriod: { [key: string]: string } = {
	start_date: "StartDate",
	end_date: "EndDate",
	last_capture: "LastCaptureDate",
	appeal_cutoff: "ExpireDate",
	month: "SurveyPeriodDate",
	survey_type: "SurveyTypeID",
};

type t_SurveyPeriodTypes = {
	id: string;
	name: string;
	description: string;
};

export type { t_SurveyPeriod, t_SurveyPeriodTypes };
export { c_SurveyPeriod };
