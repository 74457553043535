import { Tooltip } from "@mui/material";
import { Done, Edit, Error, Save } from "@mui/icons-material";
import React, { useState } from "react";
import tooltipStyles from "./Tooltip.style";

interface Props {
    content: any;
    children: any;
}

const CustomToolTip: React.FC<Props> = ({ content, children }) => {
    const classes = tooltipStyles();

    return (
        <Tooltip arrow classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={content}>
            {children}
        </Tooltip>
    );
};

export default CustomToolTip;
