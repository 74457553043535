import {
    Avatar,
    Box,
    Card,
    CardHeader,
    CardMedia,
    Divider,
    List,
    ListItem,
    TextField,
    Typography,
} from "@mui/material";
import { Room, Star } from "@mui/icons-material";
import { Pagination } from '@mui/material';
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React from "react";
import BadgeCard from "../../../../components/BadgeCard/BadgeCard";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getImageUrl } from "../../../../misc/helpers";
import { MiscBody } from "../../../../types/api.types";
import { t_UserBase } from "../../User.types";
import { awardUserBadge, getAwardableBadges, t_Badge } from "./AwardBadge.api";
import awardBadgesStyles from "./AwardBadges.styles";

type t_AwardBadge = {
    badge?: t_Badge;
    comment?: string;
};

type Props = {
    user: t_UserBase;
};
const MAX_LENGTH = 125;
const AwardBadge: React.FC<Props> = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = awardBadgesStyles();
    const [badges, setBadges] = React.useState<t_Badge[]>();
    const [awardBadge, setAwardBadge] = React.useState<t_AwardBadge>();
    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>({
        page: 1,
        rows_per_page: 9,
    });
    const [awarding, setAwarding] = React.useState<boolean>(false);

    const handleBadgeSelected = (badge: t_Badge) => {
        setAwardBadge((state) => ({
            ...state,
            badge,
        }));
    };
    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const comment = event.target.value;

        if (comment.length < MAX_LENGTH) {
            setAwardBadge((state) => ({
                ...state,
                comment,
            }));
        }
    };
    const handleAwardBadge = () => {
        if (awardBadge?.badge != null) {
            setAwarding(true);

            awardUserBadge({
                entity_no: user.entity_no,
                badge_id: awardBadge?.badge.id,
                comment: awardBadge.comment,
            })
                .then(
                    () => {
                        enqueueSnackbar("Awarded!");
                    },
                    () => {
                        enqueueSnackbar("We ran into an error!", {
                            variant: "error",
                        });
                    },
                )
                .finally(() => {
                    setAwarding(false);
                });
        }
    };

    React.useEffect(() => {
        var isActive = true;

        getAwardableBadges({ pagination }).then((awardables) => {
            isActive && setBadges(awardables);
        });

        return () => {
            isActive = false;
        };
    }, [pagination]);
    return (
        <SectionCard
            title="Award Badge"
            bottomActions={
                <Box marginLeft="auto">
                    <SubmitButton
                        className={classes.awardButton}
                        variant="outlined"
                        startIcon={<Star />}
                        loading={awarding}
                        onClick={handleAwardBadge}
                    >
                        Award
                    </SubmitButton>
                </Box>
            }
        >
            <List>
                <ListItem>
                    <Box display="flex">
                        <Card className={classes.card} elevation={0}>
                            <CardMedia className={classes.cardMedia}>
                                <Avatar
                                    aria-label="profile picture"
                                    className={classes.avatar}
                                    src={getImageUrl("profile", user.profile_img)}
                                />
                            </CardMedia>
                            <CardHeader
                                title={
                                    <React.Fragment>
                                        <Typography variant="h6">{user.name}</Typography>

                                        <Typography variant="subtitle1">{user.job_title}</Typography>
                                        <Typography variant="subtitle2">
                                            <Room fontSize="inherit" color="action" />
                                            {user.region}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </Card>
                    </Box>
                </ListItem>
                <Divider />
                <ListItem>
                    <Box display="flex" justifyContent="center" width="100%" padding="30px">
                        {awardBadge?.badge != null ? (
                            <BadgeCard
                                title={awardBadge.badge.name}
                                badge_img={awardBadge.badge.badge_img}
                                variant="flat"
                                innerProps={{
                                    card: {
                                        className: classes.awardedBadgeCard,
                                        elevation: 0,
                                    },
                                }}
                            />
                        ) : (
                            <Card className={classes.awardedBadgeCard} elevation={0}>
                                <Box className={classes.emptyBadgeContent}>
                                    <Typography>Select Badge</Typography>
                                </Box>
                            </Card>
                        )}
                    </Box>
                </ListItem>
                <ListItem>
                    <Box
                        display="grid"
                        // gridTemplateColumns='1fr 1fr 1fr'
                        gridAutoColumns="auto"
                        gridTemplateRows="1fr"
                        gridTemplateColumns="repeat(9, 1fr)"
                        width="100%"
                    >
                        {badges?.map((badge) => (
                            <BadgeCard
                                key={`badge-${badge.id}`}
                                title={badge.name}
                                badge_img={badge.badge_img}
                                variant="mini"
                                avatarClicked={() => handleBadgeSelected(badge)}
                                customClass={{
                                    avatarButton: clsx({
                                        [classes.selectedBadge]: badge.id === awardBadge?.badge?.id,
                                    }),
                                }}
                            />
                        ))}
                    </Box>
                </ListItem>
                <ListItem>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Pagination
                            page={pagination?.page}
                            count={pagination?.count}
                            onChange={(_, page) => setPagination((state) => state && { ...state, page })}
                        />
                    </Box>
                </ListItem>
                <ListItem>
                    <TextField
                        name="comment"
                        variant="outlined"
                        fullWidth
                        label="Leave a comment"
                        value={awardBadge?.comment}
                        onChange={handleCommentChange}
                        InputProps={{
                            endAdornment: (
                                <Box display="inline-flex">
                                    <Typography>{String(awardBadge?.comment ?? "").length}</Typography>
                                    <Typography>/{MAX_LENGTH}</Typography>
                                </Box>
                            ),
                        }}
                    />
                </ListItem>
            </List>
        </SectionCard>
    );
};

export default AwardBadge;
