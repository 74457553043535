import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";

interface CoachProfileRank {
    rank?: string | undefined;
    children?: any,
}

const CoachProfileRank = ({ rank, children }: CoachProfileRank) => {
    return (
        <div>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>{rank}</Typography>
            {children}
        </div>)
};

export default CoachProfileRank;