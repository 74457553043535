import { httpClient } from "../../../../clients/http.client";
import { t_DowntimeType, t_ProductionDowntime, t_ProductionDowntimeRecord } from "./Downtime.types";

const getDowntimeTypes = () => {
    return new Promise<t_DowntimeType[]>((resolve, reject) => {
        httpClient
            .post("/admin/production/downtime")
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.downtime_types);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const saveDowntime = (payload: t_ProductionDowntime & { entity_no: number; capture_date: string }) => {
    return new Promise((resolve, reject) => {
        httpClient
            .post("/production/downtime/save", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const approveDowntime = (payload: { id: number; approval: boolean }) =>
    new Promise((resolve, reject) => {
        httpClient
            .post("/production/downtime/approve", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });

const getDowntimeRecords = (payload: { entity_no: number; capture_date: string }) => {
    return new Promise<t_ProductionDowntimeRecord[]>((resolve, reject) => {
        httpClient
            .post("/production/downtime", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.downtimes);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

const deleteDowntime = (payload: t_ProductionDowntime) => {
    return new Promise((resolve, reject) => {
        const body = {
            downtime_id: payload.id,
        };

        httpClient
            .post("/production/downtime/delete", body)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
};

export { getDowntimeTypes, getDowntimeRecords, saveDowntime, approveDowntime, deleteDowntime };
