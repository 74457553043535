import { allMetrics } from "../metrics";
import { Metric, MetricReview, PIPPerformance, TableData, ToImprove } from "../types";
import { buildMetricData, buildMetricReviewData, buildPIPMetricData, buildPIPOptionData } from "../utils";

const CoachTableSubmissionData = (tableData: TableData) => {
    const { metricRatings, metricReviews, metrics, pipOptions, pipPerformance, toImprove } = tableData;

    const grow_coaching_table_data = allMetrics.reduce(
        (acc, metric) => {
            acc[metric.id] = buildMetricData(metric.id, metrics, metricRatings);

            return acc;
        },
        {} as Record<string, Metric>,
    );

    const pip_initiation_metrics = allMetrics.slice(1, 5).reduce(
        (acc, metric) => {
            acc[metric.id] = buildPIPMetricData(metric.id, toImprove, metrics, pipPerformance);
            return acc;
        },
        {} as Record<string, Partial<Metric & ToImprove & PIPPerformance>>,
    );

    const pip_initiation_options = buildPIPOptionData(pipOptions);
    const pip_follow_up_metrics = allMetrics.slice(1, 5).reduce(
        (acc, metric) => {
            acc[metric.id] = buildMetricReviewData(metric.id, metricReviews, toImprove, pipPerformance);
            return acc;
        },
        {} as Record<string, MetricReview>,
    );

    const pip_follow_up_options = buildPIPOptionData(pipOptions);

    const pip_initiation_table_data = {
        ...pip_initiation_metrics,
        options_object: pip_initiation_options,
    };

    const pip_follow_up_table_data = {
        ...pip_follow_up_metrics,
        options_object: pip_follow_up_options,
    };

    return { grow_coaching_table_data, pip_initiation_table_data, pip_follow_up_table_data };
};

export default CoachTableSubmissionData;
