import { httpClient } from "../../../../clients/http.client";
import { t_User } from "../../../../types/user.types";
import { t_MetricStructureType } from "../../Metric.types";

const getUserMetricProgress = async (payload: {
	entity_no: number;
	structure_type?: t_MetricStructureType;
	date?: string;
}) =>
	new Promise<t_User.Metric[]>((resolve, reject) => {
		let body;
		let url;
		switch (payload.structure_type) {
			case "game":
				url = "metrics/summary/game";
				body = {
					entity_no: payload.entity_no,
					date: payload.date,
				};
				break;
			default:
				url = "metrics/summary/user";

				// setting date to first of month for consistency
				let month = new Date(payload.date ?? Date.now());
				month.setDate(1);
				body = {
					entity_no: payload.entity_no,
					date: month,
				};
				break;
		}
		httpClient
			.post(url, body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.metric_values);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export { getUserMetricProgress };
