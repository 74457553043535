import { Close } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";

type NegativeOvationNoteModalProps = {
    onclick: () => void;
};

const NegativeOvationNoteModal = ({ onclick }: NegativeOvationNoteModalProps) => {
    const theme = useTheme();
    return (
        <Box sx={{ minWidth: 500, padding: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 2 }}>
                <Typography variant="h5">Team Leader Note</Typography>
                <Button variant="text" onClick={onclick}>
                    <Close sx={{ cursor: "pointer" }} />
                </Button>
            </Box>
            <Typography sx={{ fontSize: 20, fontWeight: "bold", marginBottom: 2 }}>Dear Team Leaders</Typography>
            <Typography color="GrayText" sx={{ marginBottom: 2 }}>
                We are pleased to inform you that we have developed a new set of reports using our negative sentiment
                model. These reports are designed to help us better identify and categorize calls where our members have
                expressed any form of negative sentiment.
            </Typography>
            <Typography color="GrayText" sx={{ marginBottom: 2 }}>
                We believe that by providing visibility into all the calls your team has received that display any
                negative sentiments, you will be better equipped to pinpoint the types of issues your team is facing.
                This insight will not only help you address concerns more effectively but will also allow you to create
                more meaningful, tailored coaching journeys for each team member.
            </Typography>
            <Typography color="GrayText" sx={{ marginBottom: 2 }}>
                We are confident that these reports will be a valuable tool in enhancing your team's performance and
                overall member satisfaction.
            </Typography>
            <Typography color="GrayText" sx={{ marginBottom: 2 }}>
                Thank you for your continued dedication to excellence.
            </Typography>
            <Typography>Best regards,</Typography>
            <Typography>
                <span style={{ color: theme.palette.info.main }}>BeYou</span> Coach
            </Typography>
        </Box>
    );
};

export default NegativeOvationNoteModal;
