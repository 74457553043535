import { Grid } from "@mui/material";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import ProductionGeneralForm from "../../forms/ProductionGeneralForm/ProductionGeneralForm";
import LeaveOptions from "../../tables/LeaveOptions/LeaveOptions";

interface Props {}

const ProductionGeneral: React.FC<Props> = ({}) => {
	return (
		<Grid container spacing={2}>
			<Grid item sm={4}>
				<div>
					<SectionCard hasBackground noHeader>
						<ProductionGeneralForm />
					</SectionCard>
				</div>
			</Grid>
			<Grid item sm={8}>
				<LeaveOptions />
			</Grid>
		</Grid>
	);
};

export default ProductionGeneral;
