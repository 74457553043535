import Pusher from "pusher-js";
import { setThreadUpdate } from "../redux/slices/ThreadSlice";

const imWebsocket = async (entity_no: number, dispatch: any) => {
    const api_access_token = sessionStorage.getItem("api_access_token");
    const pusher = new Pusher("1234", {
        //staging settings
        // wsHost: "197.189.207.90",
        // wsPort: 4006,
        // wssPort: 4006,
        // authEndpoint: "https://api.discovery.fusebox-staging.co.za/chat/auth",

        //prod settings
        wsHost: "172.30.4.39",
        wsPort: 6001,
        wssPort: 6001,
        authEndpoint: "http://dhpgamweb01:81/chat/auth",

        //common settings
        disableStats: true,
        cluster: "mt1",
        forceTLS: false,

        auth: {
            headers: {
                Authorization: `Bearer ${api_access_token}`,
            },
        },

        enabledTransports: ["ws"],
    });

    const channel = pusher.subscribe(`private-user.${entity_no}`);
    channel.bind("App\\Events\\ThreadMessagePosted", (data: any) => {
        dispatch(setThreadUpdate(data));
    });
};

export default imWebsocket;
