import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { getMetricRecords } from "../../Performance.api";
import { t_PerformanceUser } from "../../Performance.ctx";
import { MetricGeneralColumns } from "./MetricGeneralRecords.types";
import TwtMetricsHeader from "./TwtMetricsHeader";

interface Props {
	viewUser: t_PerformanceUser;
	month: string;
	metric_id: number;
}

const MetricGeneralRecords: React.FC<Props> = ({
	viewUser,
	month,
	metric_id,
}) => {
	const tableRef = React.createRef<any>();
	const [records, setRecords] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setLoading(true);
	}, [viewUser.user.entity_no, month, metric_id]);

	React.useEffect(() => {
		var isActive = true;
		if (loading) {
			setRecords([]);
			getMetricRecords({
				metric_id,
				entity_no: viewUser.user.entity_no,
				month,
			})
				.then((data) => {
					if (isActive) {
						setRecords(data);
					}
				})
				.catch((error) => {
					if (isActive) {
						setRecords([]);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
		return () => {
			isActive = false;
		};
	}, [loading]);

	return (
        <>
            {metric_id === 8 ? (
                <BUMaterialTable
                    title={"TWT Records"}
                    key={`metric-table-${metric_id}`}
                    tableRef={tableRef}
                    columns={[...MetricGeneralColumns[8]]}
                    isLoading={loading}
                    data={records}
                    options={{
                        toolbar: false,
                        padding: "dense",
                        pageSize: 10,
                        maxBodyHeight: "600px",
                    }}
                    components={{
                        Header: () => <TwtMetricsHeader />,
                    }}
                    style={{ position: "relative" }}
                />
            ) : (
                <BUMaterialTable
                    key={`metric-table-${metric_id}`}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Agent",
                            field: "user.DisplayName",
                            hidden:
                                viewUser.user.job_level == 1 || metric_id === 8,
                        },
                        ...MetricGeneralColumns[metric_id],
                    ]}
                    isLoading={loading}
                    data={records}
                    options={{
                        toolbar: false,
                        padding: "dense",
                        pageSize: 10,
                    }}
                />
            )}
        </>
    );
};


export default MetricGeneralRecords;
