import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const notificationStyles = makeStyles((theme: Theme) =>
	createStyles({
		activityItem: {
			borderBottom: "none",
			paddingRight: 20,
		},
		viewIcon: {
			marginRight: 10,
		},
		friendDiv: {
			display: "grid",
			placeItems: "center",
			minWidth: 200,
			height: 100,
		},
	})
);

export default notificationStyles;
