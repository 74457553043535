import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    removeTip: [] as string[],
};

const tipsSlice = createSlice({
    name: "tips",
    initialState,
    reducers: {
        setRemoveTips: (state, action) => {
            state.removeTip.push(action.payload);
        },
    },
});

export const { setRemoveTips } = tipsSlice.actions;

export default tipsSlice.reducer;
