import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const leaderboardStyles = makeStyles((theme: Theme) =>
	createStyles({
		noFormatRow: {
			"& td": {
				padding: 0,
			},
		},
		divider: {
			height: 2,
			backgroundColor: theme.palette.info.main,
		},
		card: {
			background: "none",
		},
		rounded: {
			borderRadius: "10px 10px 10px 10px",
			"& td": {
				"&:first-child": {
					borderRadius: "10px 0 0 10px",
				},
				"&:last-child": {
					borderRadius: "0 10px 10px 0",
				},
			},
		},
	})
);

export default leaderboardStyles;
