import {
	Avatar,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import React from "react";
import { getImageUrl } from "../../../../misc/helpers";
import useFriendStatus from "../../hooks/useFriendStatus/useFriendStatus";
import FullProfile from "../../sections/FullProfile/FullProfile";
import { t_UserBase } from "../../User.types";
import FriendButton from "../FriendButton/FriendButton";
import userCardStyles from "./UserCard.style";

enum STATUS {
	"loading",
	"complete",
}
interface Props {
	user: t_UserBase;
}

const UserCard: React.FC<Props> = ({ user }) => {
	const classes = userCardStyles();
	const [status, setStatus] = React.useState<STATUS>(STATUS.loading);

	const isFriends = useFriendStatus({ entity_no: user.entity_no });

	React.useEffect(() => {
		if (isFriends != null && status === STATUS.loading) {
			setStatus(STATUS.complete);
		}
	}, [isFriends]);
	return {
		[STATUS.loading]: (
			<Card>
				<div className={classes.container}>
					<CircularProgress />
				</div>
			</Card>
		),
		[STATUS.complete]: isFriends ? (
			<FullProfile entity_no={user.entity_no} />
		) : (
			<Card className={classes.card}>
				<CardHeader
					title={user.name}
					subheader={user.job_title}
					avatar={
						<Avatar
							src={getImageUrl("profile", user.profile_img)}
						/>
					}
				/>
				<CardContent>
					<List>
						<ListItem>
							<ListItemText
								primary={user.job_title ?? "-"}
								secondary={"Job Title"}
							/>
							<ListItemText
								primary={user.region ?? "-"}
								secondary={"Region"}
							/>
						</ListItem>
					</List>
				</CardContent>
				<CardActions>
					<div className={classes.actionsDiv}>
						<FriendButton entity_no={user.entity_no} />
					</div>
				</CardActions>
			</Card>
		),
	}[status];
};

export default UserCard;
