import { Avatar, Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getImageUrl, stringAvatar } from "../../misc/helpers";
import { setViewUserInfo } from "../../redux/slices/ViewUserSlice";
import { RootState } from "../../redux/store";
import notificationMessages from "./NotificationMessages.styles";

interface TeamSummary {
    data?: any;
    viewMetric?: any;
}

const TeamSummary = ({ viewMetric }: TeamSummary) => {
    const teamMembers = useSelector((state: RootState) => state.teamMembers.allTeamMembers);

    const teamSize = teamMembers.length;
    const dispatch = useDispatch();

    const metricFilter = [...teamMembers].sort((a: any, b: any) => {
        const rankA = a.metrics[viewMetric?.name]?.rank;
        const rankB = b.metrics[viewMetric?.name]?.rank;

        if (typeof rankA === "undefined" && typeof rankB === "undefined") {
            return 0;
        } else if (typeof rankA === "undefined") {
            return 1;
        } else if (typeof rankB === "undefined") {
            return -1;
        } else {
            return rankA - rankB;
        }
    });

    const top10 = metricFilter.slice(0, teamSize);
    const overallRank = teamMembers.slice(0, teamSize);
    const classes = notificationMessages();
    let backgroundColor = "";

    const [ranking, setRanking] = useState<any>([]);

    const viewProfile = (entityValue: number) => {
        teamMembers.map((item: any) => {
            if (item.user.entity_no == entityValue) {
                dispatch(setViewUserInfo(item));
            }
        });
    };

    useEffect(() => {
        if (viewMetric?.name === "Overall Ranking") {
            setRanking(overallRank);
        } else {
            setRanking(top10);
        }
    }, [teamMembers, viewMetric]);

    // if (ranking.length <= 0) {
    //     return (
    //         <Box display="flex" alignItems="center" justifyContent="center">
    //             <CircularProgress />
    //         </Box>
    //     );
    // }

    return (
        <>
            {viewMetric?.name === "Overall Ranking" ? (
                <Box style={{ display: "flex", gap: "1rem" }}>
                    <Box
                        style={{
                            paddingTop: 0,
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                        }}
                    >
                        <Typography variant="body2">Top Performer</Typography>
                        <Chip
                            size="small"
                            style={{
                                background: "#2ECA40",
                                color: "white",
                                fontSize: ".5rem",
                                padding: "0 0.5em",
                                height: ".5rem",
                                position: "relative",
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            paddingTop: 0,
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                        }}
                    >
                        <Typography variant="body2">Bottom Performer</Typography>
                        <Chip
                            size="small"
                            style={{
                                background: "#FF4040",
                                color: "white",
                                fontSize: ".5rem",
                                padding: "0 0.5em",
                                height: ".5rem",
                                position: "relative",
                            }}
                        />
                    </Box>
                </Box>
            ) : (
                <Box style={{ display: "flex", gap: "1rem" }}>
                    <Box
                        style={{
                            paddingTop: 0,
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                        }}
                    >
                        <Typography variant="body2">Target Reached</Typography>
                        <Chip
                            size="small"
                            style={{
                                background: "#2ECA40",
                                color: "white",
                                fontSize: ".5rem",
                                padding: "0 0.5em",
                                height: ".5rem",
                                position: "relative",
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            paddingTop: 0,
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                        }}
                    >
                        <Typography variant="body2">Close To Target</Typography>
                        <Chip
                            size="small"
                            style={{
                                background: "#FFAC1C",
                                color: "white",
                                fontSize: ".5rem",
                                padding: "0 0.5em",
                                height: ".5rem",
                                position: "relative",
                            }}
                        />
                    </Box>
                    <Box
                        style={{
                            paddingTop: 0,
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                        }}
                    >
                        <Typography variant="body2">Target Not Reached</Typography>
                        <Chip
                            size="small"
                            style={{
                                background: "#FF4040",
                                color: "white",
                                fontSize: ".5rem",
                                padding: "0 0.5em",
                                height: ".5rem",
                                position: "relative",
                            }}
                        />
                    </Box>
                </Box>
            )}
            {ranking.map((ten: any, index: any) => {
                if (ten.metrics[viewMetric?.name]?.percentage_of_target == 100) {
                    backgroundColor = "#2ECA40";
                } else if (ten.metrics[viewMetric?.name]?.percentage_of_target >= 80) {
                    backgroundColor = "#FFAC1C";
                } else if (viewMetric?.name === "Overall Ranking" && ten.rank === 1) {
                    backgroundColor = "#2ECA40";
                } else if (viewMetric?.name === "Overall Ranking" && ten.rank === teamSize) {
                    backgroundColor = "#FF4040";
                } else if (viewMetric?.name === "Overall Ranking" && ten.rank > 1 && ten.rank < teamSize) {
                    backgroundColor = "transparent";
                } else {
                    backgroundColor = "#FF4040";
                }

                return (
                    <Box
                        style={{
                            borderTop: "solid 1px #F0F0F0",
                            borderBottom: "solid 1px #F0F0F0",
                            marginTop: 10,
                            padding: 8,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            const entityValue = ten.user.entity_no;
                            viewProfile(entityValue);
                        }}
                        key={index}
                    >
                        <Box
                            key={index}
                            style={{
                                border: ` 2px solid ${backgroundColor}`,
                                borderRadius: 23,
                            }}
                            className={classes.hover}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "95%",
                                    borderRadius: 23,
                                    margin: "auto",
                                    padding: 5,
                                    gap: ".5rem",
                                }}
                            >
                                <Avatar
                                    src={getImageUrl("profile", ten?.user?.profile_img)}
                                    {...stringAvatar(ten?.user?.name)}
                                    style={{ width: 40, height: 40 }}
                                />
                                <Typography className={classes.clampTeam} style={{ width: 100 }}>
                                    {ten?.user?.name}
                                </Typography>
                                <Typography className={classes.clampTeam} style={{ width: 150 }}>
                                    {ten?.user?.job_title}
                                </Typography>

                                <Typography
                                    className={classes.clampTeam}
                                    style={{ width: 150, display: "flex", flexDirection: "column" }}
                                >
                                    <span style={{ fontSize: ".8rem" }}>
                                        {viewMetric?.name !== "Overall Ranking" ? "score" : ""}
                                    </span>{" "}
                                    {ten.metrics[viewMetric?.name]?.value}
                                </Typography>

                                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                                    {"#"}
                                    {viewMetric?.name === "Overall Ranking"
                                        ? ten.rank
                                        : ten.metrics[viewMetric?.name]?.rank}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </>
    );
};

export default TeamSummary;
