import {
	Divider,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from "react";
import { formatApiDate } from "../../../../misc/helpers";
import { getAppeal } from "../../Appeals.api";
import { t_AppealDetailedRecord } from "../../Appeals.types";
import appealRecordStyles from "./AppealRecord.style";

interface Props {
	appeal_id?: number;
}

const AppealRecord: React.FC<Props> = ({ appeal_id }) => {
	const classes = appealRecordStyles();
	const [appeal, setAppeal] = useState<t_AppealDetailedRecord>();

	useEffect(() => {
		var isActive = true;
		if (appeal_id != null) {
			getAppeal({ appeal_id }).then((data) => {
				isActive && setAppeal(data);
			});
		}

		return () => {
			isActive = false;
		};
	}, [appeal_id]);

	return appeal_id != null ? (
		appeal ? (
			<List>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Appeal ID'
						secondary={appeal.appeal_id ?? "Not completed."}
					/>
					<ListItemText
						className={classes.textField}
						primary='Response ID'
						secondary={appeal.response_id ?? "Not completed."}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Appeal Date'
						secondary={
							formatApiDate(appeal.appeal_date) ??
							"Not completed."
						}
					/>
					<ListItemText
						className={classes.textField}
						primary='Expiry Date'
						secondary={
							formatApiDate(appeal.expire_date) ??
							"Not completed."
						}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Appealed By'
						secondary={appeal.appealed_by?.name ?? "Not completed."}
					/>

					<ListItemText
						className={classes.textField}
						primary='Transfer To'
						secondary={appeal.transfer_to ?? "Not completed."}
					/>
				</ListItem>
				<ListSubheader>Appeal Details</ListSubheader>
				<Divider />
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Removal Level'
						secondary={appeal.level.name ?? "Not completed."}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Appeal Category'
						secondary={appeal.category?.name ?? "Not completed."}
					/>
				</ListItem>

				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Cost Center'
						secondary={appeal.cost_center?.name ?? "Not completed."}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Comment'
						secondary={appeal.comment ?? "Not completed."}
					/>
				</ListItem>
				<ListSubheader>Decision Details</ListSubheader>
				<Divider />
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Decision Date'
						secondary={
							formatApiDate(appeal.decision_date) ??
							"Not completed."
						}
					/>
					<ListItemText
						className={classes.textField}
						primary='Decision By'
						secondary={appeal.decision_by?.name ?? "Not completed."}
					/>
				</ListItem>
				<ListItem className={classes.listItem}>
					<ListItemText
						className={classes.textField}
						primary='Decision Comment'
						secondary={appeal.decision_comment ?? "Not completed."}
					/>
				</ListItem>
			</List>
		) : (
			<List>
				<ListItem>
					<Skeleton width={154} height={60} />
					<Skeleton width={154} height={60} />
				</ListItem>
				<ListItem>
					<Skeleton width={154} height={60} />
					<Skeleton width={154} height={60} />
				</ListItem>
				<ListItem>
					<Skeleton width={154} height={60} />
					<Skeleton width={154} height={60} />
				</ListItem>
				<ListItem>
					<Skeleton width={154} height={60} />
					<Skeleton width={154} height={60} />
				</ListItem>
				<ListItem>
					<Skeleton width={154} height={60} />
					<Skeleton width={154} height={60} />
				</ListItem>
			</List>
		)
	) : (
		<List>
			<ListItem>
				<ListItemText primary='Not available' />
			</ListItem>
		</List>
	);
};

export default AppealRecord;
