import React from "react";
import SectionCard from "../../../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../../../components/TabGroup/TabGroup";
import { t_SurveyPeriodArr } from "../../../../../Surveys/Surveys.types";
import PerceptionSummaryMulti from "../../../../tables/PerceptionSummary/PerceptionSummaryMulti";
import { filterCtx } from "../../LsaFilterProvider";
import { LsaSummaryColumns } from "../../LsaReport.types";

type Props = {
  periods: {
    [key: string]: t_SurveyPeriodArr;
  };
  months: string[];
};
const LsaSummaryMulti: React.FC<Props> = ({ periods, months }) => {
  const [tab, setTab] = React.useState(0);

  const { setFilterParam } = React.useContext(filterCtx);

  return (
    <SectionCard
      title="Survey Low Scores"
      titleProps={{ variant: "h6" }}
      primaryAction={
        <TabGroup
          tabValue={tab}
          tabChange={(value) => {
            setTab(value);
            setFilterParam({});
          }}
          tabList={[
            {
              _id: 0,
              label: "MBR",
            },
            {
              _id: 0,
              label: "FCR",
            },
          ]}
        />
      }
    >
      {tab === 0 && (
        <PerceptionSummaryMulti
          survey="mbr"
          report="lsa"
          periods={periods["mbr"]}
          months={months}
          columns={LsaSummaryColumns}
        />
      )}
      {tab === 1 && (
        <PerceptionSummaryMulti
          survey="fcr"
          report="lsa"
          periods={periods["fcr"]}
          months={months}
          columns={LsaSummaryColumns}
        />
      )}
    </SectionCard>
  );
};
export default LsaSummaryMulti;
