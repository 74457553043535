import { httpClient } from "../../../../clients/http.client";

const saveAppeal = async (payload: {
	category_id?: number;
	removal_level_id?: number;
	transfer_to?: string;
	comment: string | null;
	data_id?: number;
	metric_id?: number;
	appeal_id?: number;
}) => {
	const response = await httpClient.post("/appeal/save", payload);
	return response.data.data;
};
const getCategories = async (payload: { metric_id: number }) => {
	const response = await httpClient.post("/appeal/categories", payload);
	return response.data.data.categories;
};
const getRemovalLevels = async () => {
	const response = await httpClient.post("/appeal/levels");
	return response.data.data.levels;
};

export { saveAppeal, getCategories, getRemovalLevels };
