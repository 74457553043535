import { Autocomplete, Backdrop, Box, Button, CircularProgress, Grid, Tab, Tabs, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";

import SectionCard from "../../components/SectionCard/SectionCard";
import PodiumCard from "../../pages/Home/Leaderboards/PodiumCard";
import {
    useGetDepartmentMutation,
    useGetDiscoveryTeamsMutation,
    useGetJobTitlesMutation,
    useGetServiceTeamsMutation,
} from "../../redux/api/beYouCore";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import { OvationLeaderboardColumns } from "./OvationAvatarData";
import OvationLeaderboardTable from "./OvationLeaderboardTable";
import useOvation from "./useOvation";

const OvationLeaderboard = () => {
    const [tabValue, setTabValue] = useState<0 | 1>(0);
    const [department, setDepartment] = useState<string[]>([]);
    const [jobTitles, setJobTitles] = useState<string[]>([]);
    const [costCenterName, setCostCenterName] = useState<string[]>([]);
    const [discoveryTeams, setDiscoveryTeams] = useState<string[]>([]);
    // const [ovation, setOvattion] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>();
    const [selectedJobTitle, setSelectedJobTitle] = useState<any>();
    const [selectedCostCenterName, setSelectedCostCenterName] = useState<any>();
    const [selectedDiscoveryTeam, setSelectedDiscoveryTeam] = useState<any>();
    const [selectedOvation, setSelectedOvation] = useState<any>();
    const { getOvationLeaderboard, getOvationGlobalLeaderboard, isLoading } = useOvation();
    const [ovationLeaderboardData, setOvationLeaderboardData] = useState<any>([]);
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(month));
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [dateLoading, setDataLoading] = useState(false);

    const [getDepartment] = useGetDepartmentMutation();
    const [getJobTitles] = useGetJobTitlesMutation();
    const [getServiceTeam] = useGetServiceTeamsMutation();
    const [getDiscoveryTeam] = useGetDiscoveryTeamsMutation();

    const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
        setTabValue(newValue);
    };

    const tableData = useMemo(() => JSON.parse(JSON.stringify(ovationLeaderboardData)), [ovationLeaderboardData]);
    const podiumWinners = tableData?.slice(0, 3);

    const handleOvationLeaderboard = async (filter: any) => {
        let response;
        if (tabValue === 0) {
            response = await getOvationLeaderboard(filter);
        } else {
            response = await getOvationGlobalLeaderboard(filter);
        }

        if (response?.success) {
            setOvationLeaderboardData(response.data);
        }
    };

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    useEffect(() => {
        const oavtionLeaderFilter = {
            month: selectedDate,
            job_title_id: selectedJobTitle,
            service_team_id: selectedCostCenterName,
            department_id: selectedDepartment,
            team_id: selectedDiscoveryTeam,
        };

        handleOvationLeaderboard(oavtionLeaderFilter);
    }, [
        selectedDepartment,
        selectedJobTitle,
        selectedDepartment,
        selectedDiscoveryTeam,
        tabValue,
        selectedOvation,
        selectedDate,
    ]);

    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);
            try {
                const [departmentData, jobTitlesData, serviceTeamsData, discoveryTeamsData] = await Promise.all([
                    getDepartment().unwrap(),
                    getJobTitles().unwrap(),
                    getServiceTeam().unwrap(),
                    getDiscoveryTeam().unwrap(),
                ]);

                setDepartment(departmentData?.data.departments);
                setJobTitles(jobTitlesData?.data?.job_titles);
                setCostCenterName(serviceTeamsData?.data?.service_teams);
                setDiscoveryTeams(discoveryTeamsData?.data?.discovery_teams);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setDataLoading(false);
            }
        };

        fetchData();
    }, []);

    const renderAutocomplete = (label: string, options: any[], selected: any, setSelected: any) => (
        <Autocomplete
            style={{ marginLeft: 15, width: 200 }}
            value={options.find((option) => option.id === selected) || null}
            onChange={(event, newValue) => {
                setSelected(newValue ? newValue.id : null);
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
        />
    );

    const variants = {
        flyIn: () => ({
            opacity: 1,
            transition: { duration: 0.5, ease: "easeOut" },
        }),
        bobbing: () => ({
            bottom: ["0px", "20px", "0px"],
            transition: { duration: 5, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
        twisting: () => ({
            transform: ["rotate(0deg)", "rotate(5deg)", "rotate(0deg)"],
            transition: { duration: 7, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
    };

    return (
        <SectionCard
            title="Leaderboards"
            fullWidth
            scrolling
            secondaryAction={
                <>
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={new Date(month)}
                        onChange={handleDateChange}
                        closeOnSelect={false}
                        className="date-picker-beYouCoach"
                        onOpen={() => setIsDateOpen(true)}
                        onClose={() => {
                            performanceDispatch({
                                type: "update_dates",
                                month: selectedDate,
                            });
                            setIsDateOpen(false);
                        }}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                    <Backdrop sx={{ zIndex: 2 }} open={isDateOpen}></Backdrop>
                </>
            }
        >
            <Grid container spacing={2} style={{ padding: 16 }} width="100%" alignSelf="center">
                <Grid item xs={12}>
                    <SectionCard
                        fullWidth
                        scrolling
                        primaryAction={
                            <Tabs value={tabValue} onChange={handleTabChange} style={{ padding: 16 }}>
                                <Tab label="Team Leaderboard" />
                                <Tab label="Global Leaderboard" />
                            </Tabs>
                        }
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mt: 2,
                                overflowX: "scroll",
                            }}
                        >
                            {renderAutocomplete("Department", department, selectedDepartment, setSelectedDepartment)}
                            {renderAutocomplete("Job Title", jobTitles, selectedJobTitle, setSelectedJobTitle)}
                            {renderAutocomplete(
                                "Cost Center Name",
                                costCenterName,
                                selectedCostCenterName,
                                setSelectedCostCenterName,
                            )}
                            {renderAutocomplete(
                                "Team",
                                discoveryTeams,
                                selectedDiscoveryTeam,
                                setSelectedDiscoveryTeam,
                            )}
                            {/* {renderAutocomplete("Ovation", ovation, selectedOvation, setSelectedOvation)} */}
                            <Button
                                variant="contained"
                                style={{
                                    boxShadow: "none",
                                    marginLeft: 10,
                                    padding: "5px 5px",
                                }}
                                onClick={() => {
                                    setSelectedDepartment(undefined);
                                    setSelectedJobTitle(undefined);
                                    setSelectedCostCenterName(undefined);
                                    setSelectedDiscoveryTeam(undefined);
                                    setSelectedOvation(undefined);
                                }}
                            >
                                Reset
                            </Button>
                        </Box>
                        {podiumWinners.length > 0 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: { xs: 2, md: 8 },
                                    alignItems: { xs: "center", md: "flex-end" },
                                    justifyContent: "center",
                                    flexDirection: { xs: "column", md: "row" },
                                    marginTop: 10,
                                }}
                            >
                                {podiumWinners.length >= 2 && (
                                    <PodiumCard
                                        name={podiumWinners[1].name}
                                        rank={podiumWinners[1].rank}
                                        score={podiumWinners[1].ovations}
                                        profilePicture={podiumWinners[1].profile_img}
                                        position={2}
                                    />
                                )}

                                <PodiumCard
                                    name={podiumWinners[0].name}
                                    rank={podiumWinners[0].rank}
                                    score={podiumWinners[0].ovations}
                                    profilePicture={podiumWinners[0].profile_img}
                                    position={1}
                                />

                                {podiumWinners.length === 3 && (
                                    <PodiumCard
                                        name={podiumWinners[2].name}
                                        rank={podiumWinners[2].rank}
                                        score={podiumWinners[2].ovations}
                                        profilePicture={podiumWinners[2].profile_img}
                                        position={3}
                                    />
                                )}
                            </Box>
                        )}
                        {isLoading || dateLoading ? (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "auto",
                                    padding: 5,
                                    height: "100%",
                                }}
                            >
                                <CircularProgress size={150} />
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ position: "relative", flex: 0.2 }}>
                                    <img src="/images/medal.svg" style={{ position: "fixed", width: "3%" }} />
                                </Box>
                                <Box sx={{ flex: 2.3 }}>
                                    {tabValue === 0 && (
                                        <OvationLeaderboardTable
                                            columns={OvationLeaderboardColumns}
                                            data={tableData}
                                            title={"Team Leaderboard"}
                                        />
                                    )}
                                    {tabValue === 1 && (
                                        <OvationLeaderboardTable
                                            columns={OvationLeaderboardColumns}
                                            data={tableData}
                                            title={"Global Leaderboard"}
                                        />
                                    )}
                                </Box>
                                <Box sx={{ position: "relative", flex: 0.2 }}>
                                    <AnimatePresence>
                                        <motion.img
                                            src="/images/beyou-leaderboard.svg"
                                            style={{ position: "fixed", width: "10%", bottom: 2 }}
                                            initial={{ opacity: 0 }}
                                            animate={["flyIn", "bobbing", "twisting"]}
                                            variants={variants}
                                        />
                                    </AnimatePresence>
                                </Box>
                            </Box>
                        )}
                    </SectionCard>
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default OvationLeaderboard;
