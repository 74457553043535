import { Box, Tab, Tabs, TextField } from "@mui/material";
import { Close, Dashboard, List } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { usePerformanceContext } from "../../Performance.ctx";
import TeamMembers from "../../tables/TeamMembers/TeamMembers";
import { getTeamPerformance, t_TeamMember } from "../../tables/TeamMembers/TeamMembers.api";
import UserPerformance from "../UserPerformance/UserPerformance";
import teamPerformanceStyles from "./TeamPerformance.style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface Props {
    setIsViewTeamOpen?: (value: boolean) => void;
}
const TeamPerformance: React.FC<Props> = ({ setIsViewTeamOpen }) => {
    const classes = teamPerformanceStyles();

    const [tabValue, setTabValue] = useState<number>(0);
    const [{ viewUser, month, comparison_month }, performanceDispatch] = usePerformanceContext();
    const teamMembers = useSelector((state: RootState) => state.teamMembers.allTeamMembers);

    const handleTeamMemberSelect = (member: t_TeamMember) => {
        handleTeamMemberShow(member);
        setTabValue(1);
    };

    const handleTeamMemberShow = (member?: t_TeamMember) => {
        if (member != null) {
            performanceDispatch({
                type: "view",
                viewUser: {
                    user: member.user,
                    metrics: Object.values(member.metrics),
                },
            });
        }
    };

    const tableData = JSON.parse(JSON.stringify(teamMembers));

    return (
        <SectionCard
            title={tabValue === 1 ? "View Member" : undefined}
            titleProps={{ variant: "h6" }}
            primaryAction={
                tabValue === 1 && (
                    <Autocomplete
                        className={classes.autoComplete}
                        getOptionLabel={(option) => option?.name ?? "-"}
                        isOptionEqualToValue={(option, value) => option?.entity_no === value?.entity_no}
                        value={viewUser?.user}
                        options={teamMembers.map((member: any) => member.user)}
                        onChange={(_, value) =>
                            value &&
                            handleTeamMemberShow(
                                teamMembers.find((member: any) => member.user.entity_no === value.entity_no),
                            )
                        }
                        renderInput={(params) => <TextField {...params} label="Member" />}
                    />
                )
            }
            secondaryAction={
                <>
                    {setIsViewTeamOpen && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            width="100%"
                            mb={2}
                            sx={{ cursor: "pointer" }}
                        >
                            <Close onClick={() => setIsViewTeamOpen(false)} />
                        </Box>
                    )}
                    <Tabs value={tabValue} onChange={(_, value) => setTabValue(value)}>
                        <Tab value={0} label={<List />} />
                        <Tab
                            value={1}
                            label={<Dashboard />}
                            disabled={teamMembers.length === 0}
                            onClick={() => teamMembers.length && handleTeamMemberShow(teamMembers[0])}
                        />
                    </Tabs>
                </>
            }
            fullWidth
        >
            {tabValue === 0 && <TeamMembers teamMembers={tableData} selectUser={handleTeamMemberSelect} />}
            {tabValue === 1 && <UserPerformance />}
        </SectionCard>
    );
};

export default TeamPerformance;
