import { GroupedHeaderTableProps } from "../../../../components/GroupedHeaderTable/GroupedHeaderTable";

type t_AppealSummary = {
    for_survey: string;
    appeal_n: number;
    approved_ratio: number;
    declined_ratio: number;
    pending_ratio: number;
};

//@ts-ignore
const AppealSummaryColumns: GroupedHeaderTableProps<t_AppealSummary>["columns"] = [
    {
        header: "n",
        field: "appeal_n",
    },
    {
        header: "Approved",
        field: "approved_ratio",
        render: (rowData) => `${rowData.approved_ratio}%`,
    },
    {
        header: "Declined",
        field: "declined_ratio",
        render: (rowData) => `${rowData.declined_ratio}%`,
    },
    {
        header: "Pending",
        field: "pending_ratio",
        render: (rowData) => `${rowData.pending_ratio}%`,
    },
];

export { AppealSummaryColumns };
export type { t_AppealSummary };
