import { httpClient } from "../../../../clients/http.client";
import { t_SurveyTrendData } from "./SurveyTrendGraph.types";

const getSurveySummaryGraph = (payload: {
	for_survey: string;
	for_month: string;
}) => {
	return new Promise<t_SurveyTrendData[]>((resolve, reject) => {
		httpClient.post("/reports/survey/trend", payload).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve(data.data.survey_trend);
					break;
				default:
					reject(data.message);
					break;
			}
		});
	});
};

export { getSurveySummaryGraph };
