import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const challengeHistoryStyles = makeStyles((theme: Theme) =>
	createStyles({
		challengeCard: {
			width: "100%",
		},
		dateListItem: {
			display: "flex",
			justifyContent: "flex-end",
		},
		pagination: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
	})
);

export default challengeHistoryStyles;
