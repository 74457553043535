import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputBase,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useCreateThreadMutation, useGetTeamMembersMutation } from "../../redux/api/beYouCore";
import { Close } from "@mui/icons-material";

const CreateMessage = ({ setIsCreateMessage, messageType, setUpdateContactsList }: any) => {
    const classes = createMessageStyles();
    const [team, setTeam] = useState<any>([]);
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [threadName, setThreadName] = useState("");
    const [createThread] = useCreateThreadMutation();
    const [getTeamMembers] = useGetTeamMembersMutation();

    const handleChange = (event: SelectChangeEvent<typeof selectedMembers>) => {
        const {
            target: { value },
        } = event;
        setSelectedMembers(typeof value === "string" ? value.split(",") : value);
    };

    const handleCreate = () => {
        if (threadName != "") {
            createThread({ participants: selectedMembers, name: threadName })
                .unwrap()
                .then((response) => {
                    setUpdateContactsList(response);
                })
                .catch((error) => {
                    console.error("🚀 ~ file: CreateMessage.tsx:24 ~ .then ~ error:", error);
                })
                .finally(() => {
                    setIsCreateMessage(false);
                });
        }
    };

    useEffect(() => {
        getTeamMembers()
            .unwrap()
            .then((response) => {
                const manager = [response.data.manager];
                const teamMembers = response.data.teamMembers;
                const allMembers = [...manager, ...teamMembers];
                setTeam(allMembers);
            })
            .catch((error) => {
                console.error("🚀 ~ file: CreateMessage.tsx:64 ~ getTeamMembers ~ error:", error);
            });
    }, [messageType]);

    useEffect(() => {
        if (messageType === "direct") {
            const newThreadName = team.filter((name: any) => name.EntityNo == selectedMembers);
            setThreadName(newThreadName[0]?.DisplayName);
        }
    }, [selectedMembers]);

    return (
        <Grid container spacing={2} sx={{}}>
            <Grid item xs={12} mb={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                    <Typography fontWeight="bold" variant="h5">
                        New Message
                    </Typography>
                    <IconButton onClick={() => setIsCreateMessage(false)}>
                        <Close />
                    </IconButton>
                </Box>
                {/* <Divider /> */}
                <Box className={classes.container}>
                    {messageType === "channel" && (
                        <Box mb={2}>
                            <Typography gutterBottom>Group Name</Typography>
                            <TextField
                                placeholder="Group Name"
                                onChange={(e) => setThreadName(e.target.value)}
                                fullWidth
                            />
                        </Box>
                    )}
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <Typography gutterBottom>Add Team Members</Typography>
                        <Select
                            value={selectedMembers}
                            multiple={messageType === "channel" ? true : false}
                            input={<OutlinedInput label="Group Members" />}
                            onChange={handleChange}
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {team.map((member: any) => (
                                <MenuItem key={member.EntityNo} value={member.EntityNo}>
                                    <Checkbox checked={selectedMembers.indexOf(member.EntityNo) > -1} />
                                    <ListItemText primary={member.DisplayName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={handleCreate}>
                        Create
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

const createMessageStyles = makeStyles((theme: any) => ({
    container: {
        padding: 20,
    },
}));

export default CreateMessage;
