import { List, ListItem, ListItemText } from "@mui/material";
import { Skeleton } from '@mui/material';
import React from "react";
import { getLsaRecord } from "../../LowScoreAnalysis.api";
import { t_LsaRecord } from "../../LowScoreAnalysis.type";
import lsaRecordStyles from "./LsaRecord.style";

interface Props {
	lsa_id?: number;
}

const LsaRecord: React.FC<Props> = ({ lsa_id }) => {
	const classes = lsaRecordStyles();
	const [lsaRecord, setLsaRecord] = React.useState<t_LsaRecord>();
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		var isActive = true;
		if (lsa_id != null) {
			setLoading(true);
			getLsaRecord({ lsa_id }).then((data) => {
				if (isActive) {
					setLsaRecord(data);
					setLoading(false);
				}
			});
		} else {
			if (isActive) {
				setLsaRecord(undefined);
				setLoading(false);
			}
		}
		return () => {
			isActive = false;
		};
	}, [lsa_id]);
	return (
		<div className={classes.root}>
			{!loading ? (
				<List>
					<ListItem>
						<ListItemText
							primary='Stream'
							secondary={
								lsaRecord?.stream?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Primary Query Type'
							secondary={
								lsaRecord?.primary_type?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Secondary Query Type'
							secondary={
								lsaRecord?.primary_type?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Low Score Grouping'
							secondary={
								lsaRecord?.group?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Primary Low Score Cause'
							secondary={
								lsaRecord?.primary_cause?.name ||
								"Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Secondary Low Score Cause'
							secondary={
								lsaRecord?.primary_cause?.name ||
								"Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Responsible Department'
							secondary={
								lsaRecord?.scope?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Action Taken'
							secondary={
								lsaRecord?.action?.name || "Not Completed"
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							secondary={
								lsaRecord?.landing != null
									? lsaRecord?.landing
										? "True"
										: "False"
									: "Not Completed"
							}
							primary='Soft Landing'
						/>
					</ListItem>
				</List>
			) : (
				<List>
					{Array(5).map((key) => (
						<ListItem key={key}>
							<ListItemText
								primary={<Skeleton />}
								secondary={<Skeleton />}
							/>
						</ListItem>
					))}
				</List>
			)}
		</div>
	);
};

export default LsaRecord;
