import { httpClient } from "../../../../clients/http.client";

const getComments = async (payload: { lsa_id: number }) => {
	const body = {
		lsa_id: payload.lsa_id,
		sort: {
			column_name: "DateCreated",
			order: "DESC",
		},
	};
	const response = await httpClient.post("/lsa/comments", body);
	return response.data.data.comments;
};

const postComment = async (payload: { lsa_id: number; comment: string }) => {
	const response = await httpClient.post("/lsa/comments/create", payload);
	return response.data.data.comment;
};

export { getComments, postComment };
