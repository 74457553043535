import { httpClient } from "../../../../clients/http.client";
import { t_ApiResponse } from "../../../../hooks/material-table/useMaterialTable";
import { MiscBody } from "../../../../types/api.types";
import { t_HelpFile } from "../../components/HelpFile/HelpFile.types";

const getHelpFiles = (payload: {
	search?: string;
	pagination: MiscBody["pagination"];
}) => {
	return new Promise<t_ApiResponse<t_HelpFile>>((resolve, reject) => {
		const body: MiscBody = {
			pagination: payload.pagination,
			filter:
				payload.search != null
					? [
							{
								column_name: "Title",
								search_term: payload.search,
							},
					  ]
					: undefined,
		};
		httpClient.post("/helpFile", body).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve(data);
					break;
				default:
					reject(data.message);
					break;
			}
		});
	});
};

export { getHelpFiles };
