import {
	t_PoolColumn,
	t_PoolTableValues,
	t_ProductionTotalsBase,
} from "../Production.types";
import {
	t_ProductionByDate,
	t_ProductionByDate_Totals,
} from "./ProductionByDate/ProductionByDate.types";
import { t_ProductionByUser } from "./ProductionByUser/ProductionByUser.types";

type t_GenericProduction = t_ProductionByDate | t_ProductionByUser;
type t_GenericProductionTotals =
	| t_ProductionByDate_Totals
	| t_ProductionTotalsBase;

const productionPoolColumnTransform: (
	cols: t_PoolColumn[]
) => (t_PoolColumn & { field: string })[] = (cols) => {
	return cols.map((col) => ({
		...col,
		field: `pool-${col.id}`,
	}));
};
const productionPoolRowTransform: <T extends t_GenericProduction>(
	rows: T[]
) => (T & t_PoolTableValues)[] = (rows) => {
	return rows.map((row) => ({
		...row,
		pool_values: Object.assign(
			{},
			...row.pool_values.map((pool) => ({
				[`pool-${pool.id}`]: pool,
			}))
		),
	}));
};
const productionPoolTotalTransform: <T extends t_GenericProductionTotals>(
	totals: T
) => T & t_PoolTableValues = (totals) => {
	return {
		...totals,
		pool_values: Object.assign(
			{},
			...totals.pool_values.map((pool) => ({
				[`pool-${pool.id}`]: pool,
			}))
		),
	};
};

export {
	productionPoolRowTransform,
	productionPoolColumnTransform,
	productionPoolTotalTransform,
};
