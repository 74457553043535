import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const dataTableStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderCollapse: "separate",
			borderSpacing: theme.spacing(0, 2),
		},
		tableContainer: {
			height: "100%",
			width: "auto",
		},
		row: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			borderRadius: theme.shape.borderRadius,
			marginBottom: theme.spacing(2),
			"& td, & th": {
				backgroundColor: theme.palette.primary.main,
				border: "none",
				padding: theme.spacing(1),
				minWidth: 160,
			},
			"& th": {
				"&:first-child": {
					paddingLeft: theme.spacing(2),
				},
				"&:last-child": {
					paddingRight: theme.spacing(2),
				},
			},
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
		},
	})
);

export default dataTableStyles;
