import { httpClient } from "../../../../clients/http.client";
import { t_InitiativeOption } from "../../tables/InitiativeOptions/InitiativeOptions.types";

const updateInitiativeOption = (payload: { option: t_InitiativeOption }) => {
	return new Promise((resolve, reject) => {
		const body = {
			...payload.option,
			incentive_category_ids: payload.option.incentive_categories?.map(
				(category) => category.id
			),
		};
		httpClient
			.post("/admin/production/initiative/update", body)
			.then((response) => {
				switch (response.data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

const toggleOptionActive = (payload: { option: t_InitiativeOption }) => {
	return new Promise((resolve, reject) => {
		const body = {
			initiative_type_id: payload.option.id,
			active: !payload.option.active,
		};
		httpClient
			.post("/admin/production/initiative/active", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

export { updateInitiativeOption, toggleOptionActive };
