import React from "react";

type Props<T extends Element> = {
    targetRef: React.RefObject<T>;
    onScrollToBottom?: () => void;
    lazyLoad?: boolean;
};
const useScrollEvent = <T extends HTMLDivElement>(props: Props<T>) => {
    const { targetRef, onScrollToBottom, lazyLoad = true } = props;

    const handleScroll = () => {
        const el = targetRef.current;
        if (el != null && onScrollToBottom != null) {
            if (el.scrollHeight - el.scrollTop === el.clientHeight) {
                onScrollToBottom();
            }
        }
    };
    React.useEffect(() => {
        if (targetRef?.current != null) {
            targetRef.current.addEventListener("onscroll", handleScroll);
        }

        return () => {
            targetRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [targetRef]);
};

export default useScrollEvent;
