import { useCallback, useState } from "react";

import {
    useAvatarItemUnlockMutation,
    useOvationGlobalLeaderboardMutation,
    useOvationLeaderboardMutation,
    useOvationNegativeRecordMutation,
    useOvationNegativeTrendMutation,
    useOvationNegativeWordsMutation,
    useOvationRecordMutation,
    useOvationTrendMutation,
    useOvationWordsMutation,
    useSaveAvatarImageMutation,
    useSaveAvatarMutation,
} from "../../redux/api/beYouCore";

const useOvation = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [ovationRecords] = useOvationRecordMutation();
    const [ovationTrend] = useOvationTrendMutation();
    const [ovationWords] = useOvationWordsMutation();
    const [ovationNegativeRecords] = useOvationNegativeRecordMutation();
    const [ovationNegativeTrend] = useOvationNegativeTrendMutation();
    const [ovationNegativeWords] = useOvationNegativeWordsMutation();

    const [ovationAvatarUnlockItem] = useAvatarItemUnlockMutation();

    const [ovationAvatarSave] = useSaveAvatarMutation();
    const [ovationAvatarImageSave] = useSaveAvatarImageMutation();
    const [ovationLeaderboard] = useOvationLeaderboardMutation();
    const [ovationGlobalLeaderboard] = useOvationGlobalLeaderboardMutation();

    // Positive Ovation

    const getOvationRecords = useCallback(
        async (payload: API.Payload.OvationRecordPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationRecords({
                    month: payload.month,
                    survey: payload.survey,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.ovations };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationRecords],
    );

    const getOvationTrend = useCallback(
        async (payload: API.Payload.OvationTrendPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationTrend({
                    month: payload.month,
                    survey: payload.survey,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.trend };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationTrend],
    );

    const getOvationWords = useCallback(
        async (payload: API.Payload.OvationWordPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationWords({
                    month: payload.month,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.words };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationWords],
    );

    // Negative Ovation

    const getOvationNegativeRecords = useCallback(
        async (payload: API.Payload.OvationRecordPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationNegativeRecords({
                    month: payload.month,
                    survey: payload.survey,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.ovations };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationRecords],
    );

    const getOvationNegativeTrend = useCallback(
        async (payload: API.Payload.OvationTrendPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationNegativeTrend({
                    month: payload.month,
                    survey: payload.survey,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.trend };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationTrend],
    );

    const getOvationNegativeWords = useCallback(
        async (payload: API.Payload.OvationWordPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationNegativeWords({
                    month: payload.month,
                    agent_entity_no: payload.agent_entity_no,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.words };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationWords],
    );

    //Avatar
    const unlockAvatarItem = useCallback(
        async (payload: API.Payload.BeYouAvatarItemUnlockPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationAvatarUnlockItem({
                    item_id: payload.item_id,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.unlocks };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationAvatarUnlockItem],
    );

    const saveAvatar = useCallback(
        async (payload: API.Payload.BeYouAvatarSavePayload) => {
            setIsLoading(true);
            try {
                const response = await ovationAvatarSave({
                    avatar: payload.avatar,
                    is_profile: payload.is_profile,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.avatar };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationAvatarSave],
    );

    const saveAvatarImage = useCallback(
        async (payload: API.Payload.BeYouAvatarImageSavePayload) => {
            setIsLoading(true);
            try {
                const response = await ovationAvatarImageSave({
                    svg: payload.svg,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.image };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationAvatarImageSave],
    );

    // Leaderboard
    const getOvationLeaderboard = useCallback(
        async (payload: API.Payload.OvationLeaderboardPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationLeaderboard({
                    month: payload.month,
                    department_id: payload.department_id,
                    job_title_id: payload.job_title_id,
                    ovation_type: payload.ovation_type,
                    service_team_id: payload.service_team_id,
                    team_id: payload.team_id,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.rank };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationLeaderboard],
    );

    const getOvationGlobalLeaderboard = useCallback(
        async (payload: API.Payload.OvationLeaderboardPayload) => {
            setIsLoading(true);
            try {
                const response = await ovationGlobalLeaderboard({
                    month: payload.month,
                    department_id: payload.department_id,
                    job_title_id: payload.job_title_id,
                    ovation_type: payload.ovation_type,
                    service_team_id: payload.service_team_id,
                    team_id: payload.team_id,
                }).unwrap();

                if (response.status === "success") {
                    return { success: true, data: response.data.rank };
                } else {
                    throw new Error(response?.message || "Unexpected error occurred");
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [ovationGlobalLeaderboard],
    );

    return {
        getOvationRecords,
        getOvationTrend,
        getOvationWords,
        getOvationNegativeRecords,
        getOvationNegativeTrend,
        getOvationNegativeWords,
        unlockAvatarItem,
        saveAvatar,
        saveAvatarImage,
        getOvationLeaderboard,
        getOvationGlobalLeaderboard,
        isLoading,
    };
};

export default useOvation;
