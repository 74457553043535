import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const teamMembersStyles = makeStyles((theme: Theme) =>
	createStyles({
		condensedAvatar: {
			width: theme.spacing(3),
			height: theme.spacing(3),
		},
		paddedAvatar: {
			width: theme.spacing(5),
			height: theme.spacing(5),
		},
		container: {
			maxHeight: "100%",
		},
		item: {
			maxHeight: "inherit",
		},
		profileCard: {
			cursor: "pointer",
			padding: 0,
		},
	})
);

export default teamMembersStyles;
