import { FilterList } from "@mui/icons-material";
import {
  MaterialTableProps,
  Options,
  Query,
  QueryResult,
} from "material-table";
import React from "react";
import { MiscBody } from "../../types/api.types";

// defaults
const options_default: Options<any> = {
  padding: "dense",
  pageSize: 10,
};

const options_actions: Options<any> = {
  filtering: false,
};

function queryToApi<RowData extends object>(
  query: Query<RowData>,
  searchColumn?: string
): MiscBody {
  let sort = undefined;
  if (query.orderBy != null) {
    sort = {
      column_name: String(query.orderBy?.field),
      order: String(query.orderDirection).toUpperCase(),
    };
  }

  let filter = undefined;
  if (query.filters != null) {
    filter = [];
    query.filters.forEach((_filter) => {
      filter.push({
        column_name: String(_filter.column.field),
        search_term: _filter.value,
      });
    });

    if (query.search && searchColumn)
      filter.push({
        column_name: searchColumn,
        search_term: query.search,
      });
  }

  let pagination = undefined;
  if (query.page != null && query.pageSize != null) {
    pagination = {
      page: query.page + 1,
      rows_per_page: query.pageSize,
    };
  }
  return {
    sort,
    filter,
    pagination,
  };
}
// concept
function apiToQuery<RowData extends object>(query: Query<RowData>) {
  return {
    sort: query.orderBy
      ? {
          column_name: String(query.orderBy?.field),
          order: String(query.orderDirection).toLowerCase(),
        }
      : null,
    filter: query.filters.map((filter) => ({
      column_name: String(filter.column.field),
      search_term: filter.value,
    })),
    pagination: {
      page: query.page + 1,
      rows_per_page: query.pageSize,
    },
  };
}

export type t_ApiResponse<RowData extends object> = {
  data: {
    [key: string]: RowData[];
  };
  message: string;
  status: "success" | "error";
} & MiscBody;

export function useMaterialTable<
  RowData extends object,
  SourceArgs extends object = {}
>(config: {
  options?: Options<RowData>;
  data_source?: (
    args: SourceArgs & MiscBody
  ) => Promise<t_ApiResponse<RowData>>;
}) {
  /** @TABLE */
  // we need to figure out how to use the correct ref type for this
  const tableRef = React.createRef<any>();
  /** @OPTIONS */
  const [options, setOptions] = React.useState<
    MaterialTableProps<RowData>["options"]
  >({
    ...options_default,
    ...config,
    ...options_actions,
  });
  const [actions, setActions] = React.useState<
    MaterialTableProps<RowData>["actions"]
  >([]);

  const toggleFilter = React.useCallback(() => {
    setOptions((state) => ({
      ...state,
      filtering: !state?.filtering,
    }));
  }, [setOptions]);

  React.useEffect(() => {
    // on init fill actions with applicable
    let init_actions: MaterialTableProps<RowData>["actions"] = [];
    if (config.options?.filtering === true) {
      init_actions.push({
        icon: FilterList,
        tooltip: "Filter",
        isFreeAction: true,
        onClick: toggleFilter,
      });
    }

    setActions(init_actions);
  }, []);

  /** @DATA_SOURCE */

  const [dataSourceArgs, setDataSourceArgs] = React.useState<SourceArgs>();

  const dataSource = React.useCallback(
    (
      query: Query<RowData>,
      searchColumn?: string
    ): Promise<QueryResult<RowData>> => {
      return new Promise((resolve, reject) => {
        if (config.data_source != null && dataSourceArgs != null) {
          const misc_body = queryToApi(query, searchColumn);
          config
            .data_source({
              ...dataSourceArgs,
              ...misc_body,
            })
            .then((response) => {
              if (response.status !== "success") {
                reject(response.message);
              }

              // the api returns pagination slightly differently to what we need
              let tableData: RowData[] = [];
              if (response.data != null) {
                switch (typeof response.data) {
                  case "object":
                    if (Object.keys(response.data).length > 0) {
                      tableData = response.data[Object.keys(response.data)[0]];
                    }
                    break;
                  default:
                    if (Array.isArray(response.data)) {
                      tableData = response.data as RowData[];
                    }
                }
              }
              resolve({
                data: tableData,
                page:
                  response.pagination != null
                    ? response.pagination.page - 1
                    : 0,
                totalCount: response.total_results ?? 0,
              });
            });
        }
      });
    },
    [dataSourceArgs]
  );

  const dataSourceConfig = (searchColumn?: string) => (query: Query<RowData>) =>
    dataSource(query, searchColumn);

  /** @RENDER */
  React.useEffect(() => {
    if (dataSourceArgs != null && tableRef.current != null) {
      tableRef.current.onQueryChange();
    }
  }, [dataSourceArgs]);
  return {
    tableRef,
    options,
    actions,
    dataSource,
    dataSourceConfig,
    setDataSourceArgs,
  } as const;
}
