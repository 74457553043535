import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, Menu, MenuItem, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SectionCard from "../../components/SectionCard/SectionCard";
import { getImageUrl, stringAvatar } from "../../misc/helpers";
import { RootState } from "../../redux/store";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import OvationSlider from "./OvationSlider";
import OvationTable from "./OvationTable";
import OvationsReceived from "./OvationsReceived";

type TeamMember = {
    entity_no: string;
    id: number;
    name: string;
    profile_img: string;
};

type Props = {
    reportOption: "FCR" | "MBR";
};

const OvationReport = ({ reportOption }: Props) => {
    const [tabValuePrimary, setTabValuePrimary] = useState<0 | 1>(0);
    const [teamMember, setTeamMember] = useState<TeamMember | null>(null);
    const [ovationType, setOvationType] = useState<"positive" | "negative">("positive");
    const [receivedOvations, setReceivedOvations] = useState([]);

    const [{ month }] = usePerformanceContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const subMenuOpen = Boolean(subMenuAnchorEl);
    const theme = useTheme();
    const teamMembers = useSelector((state: RootState) => state.teamMembers.allTeamMembers);

    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setTabValuePrimary(1);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
        if (!teamMember) {
            setTabValuePrimary(0);
        }
    };

    const handleTeamMemberSelect = (member: TeamMember) => {
        setTeamMember(member);
        setTabValuePrimary(1);
        setSubMenuAnchorEl(null);
    };

    const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    // const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    //     setTabValuePrimary(newValue);
    //     if (newValue === 0) {
    //         handleClose();
    //         setTeamMember(null);
    //     }
    // };

    useEffect(() => {
        if (tabValuePrimary === 0) {
            setTeamMember(null);
        }
    }, [tabValuePrimary]);

    useEffect(() => {
        if (teamMember) {
            handleClose();
        }
    }, [teamMember]);

    useEffect(() => {
        if (location.pathname === "/coaching-forms/team-development") {
            setOvationType("negative");
        } else {
            setOvationType("positive");
        }
    }, [location.pathname]);

    return (
        <SectionCard
            fullWidth
            scrolling
            primaryAction={
                <Box sx={{ ml: 2 }}>
                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? "demo-customized-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        variant="outlined"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownOutlined />}
                        sx={{
                            backgroundColor: tabValuePrimary === 1 ? theme.palette.info.main : theme.palette.info.dark,
                            color: tabValuePrimary === 1 ? theme.palette.info.contrastText : theme.palette.info.light,
                            p: 1,
                        }}
                    >
                        {teamMember ? teamMember.name : "Team Overview"}
                    </Button>
                    <Menu
                        elevation={0}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => setTabValuePrimary(0)}>Team Overview</MenuItem>
                        <MenuItem onClick={handleSubMenuOpen}>
                            Team Member
                            <KeyboardArrowDownOutlined />
                        </MenuItem>
                        <Menu
                            elevation={0}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={subMenuOpen}
                            anchorEl={subMenuAnchorEl}
                            onClose={() => setSubMenuAnchorEl(null)}
                        >
                            {teamMembers.map((member: any, index: number) => (
                                <MenuItem
                                    onClick={() => handleTeamMemberSelect(member.user)}
                                    key={`${member.user.id}-${index}`}
                                >
                                    <Avatar
                                        style={{ width: 20, height: 20, marginRight: 10 }}
                                        src={getImageUrl("profile", member.user.profile_img)}
                                        {...stringAvatar(member.user.name)}
                                    />
                                    {member.user.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Menu>
                </Box>
            }
        >
            <Grid container spacing={5} sx={{ padding: 5 }}>
                {ovationType === "positive" && (
                    <Grid item sm={12}>
                        <OvationSlider receivedOvations={receivedOvations} />
                    </Grid>
                )}
                <Grid item sm={12}>
                    <SectionCard hasBackground title={ovationType === "positive" ? "Ovations received" : ""} scrolling>
                        <OvationsReceived
                            month={month}
                            survey={reportOption!}
                            agent_entity_no={teamMember?.entity_no}
                            ovationType={ovationType}
                        />
                    </SectionCard>
                </Grid>
                {/* <Grid item sm={12} md={6}>
                    <SectionCard
                        hasBackground
                        title={ovationType === "positive" ? "What good looks like" : "Focus Area"}
                        scrolling
                    >
                        <WordCountTable
                            month={month}
                            agent_entity_no={teamMember?.entity_no}
                            ovationType={ovationType}
                        />
                    </SectionCard>
                </Grid> */}
                <Grid item sm={12}>
                    <OvationTable
                        month={month}
                        survey={reportOption!}
                        agent_entity_no={teamMember?.entity_no}
                        ovationType={ovationType}
                        setReceivedOvations={setReceivedOvations}
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default OvationReport;
