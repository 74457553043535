import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Grid, IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";

import { httpClient } from "../../clients/http.client";
import { useUserState } from "../../context/user/user.ctx";
import { t_User } from "../../types/user.types";
import Pill from "../Pill/Pill";

interface Props {
    metrics: t_User.Metric[];
    metricState?: {
        metrics: any[];
    };
    [propName: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "hidden",
            scrollBehavior: "smooth",
            // "&:hover": {
            //  overflowX: "auto",
            // },
        },
        wrapperDiv: {
            width: "100%",
        },
        leftArrow: {
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translate(50%, -50%)",
            backgroundColor: `${theme.palette.background.paper} !important`,
            boxShadow: theme.shadows[2],
            zIndex: 10000,
        },
        rightArrow: {
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: `${theme.palette.background.paper} !important`,
            boxShadow: theme.shadows[2],
            zIndex: 10000,
        },
    }),
);

const PillList: React.FC<Props> = ({ metricState }) => {
    const classes = useStyles();
    const containerRef = React.createRef<HTMLDivElement>();
    const [hover, setHover] = React.useState(false);
    const [metrics, setMetrics] = React.useState<any>();
    const currentUser = useUserState();
    const entity_no = currentUser.entity_no;

    const handleRightClick = () => {
        if (containerRef?.current != null) {
            containerRef.current.scrollLeft += 200;
        }
    };
    const handleLeftClick = () => {
        if (containerRef?.current != null) {
            containerRef.current.scrollLeft -= 200;
        }
    };

    const getAllMetrics = async (payload: { month: any; entity_no: number; incentive: boolean }) => {
        try {
            if (entity_no) {
                const response = await httpClient.post("/metrics/summary/user", payload);
                setMetrics(response.data.data.metric_values);
            }
        } catch (error) {
            console.error("An error occurred while fetching metrics:", error);
        }
    };

    useEffect(() => {
        getAllMetrics({ month: new Date(), entity_no, incentive: false });
    }, [entity_no]);

    return (
        <div className={classes.wrapperDiv} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Grid ref={containerRef} container spacing={2} wrap="nowrap" className={classes.root}>
                {hover && (
                    <IconButton className={classes.leftArrow} onClick={handleLeftClick} size="large">
                        <ArrowLeft />
                    </IconButton>
                )}
                {metrics &&
                    metrics.map((attr: any, index: any) => (
                        <Grid item key={index}>
                            <Pill {...attr} />
                        </Grid>
                    ))}
                {hover && (
                    <IconButton className={classes.rightArrow} onClick={handleRightClick} size="large">
                        <ArrowRight />
                    </IconButton>
                )}
            </Grid>
        </div>
    );
};

export default PillList;
