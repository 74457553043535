import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    darkMode: JSON.parse(localStorage.getItem("darkMode") || "false"),
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        toggleTheme: (state, action) => {
            const darkModeSetting = action.payload == 1 ? true : false;
            state.darkMode = darkModeSetting;
            localStorage.setItem("darkMode", JSON.stringify(darkModeSetting));
        },
    },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
