import { httpClient } from "../../../../../../clients/http.client";
import { t_IncentiveType } from "../../MaintainIncentive.types";

const getMetrics = async () => {
	const response = await httpClient.get("/metrics");
	return response.data.data.metrics;
};
const createIncentiveStructures = async (payload: {
	category_id: t_IncentiveType["id"];
	metric_id: number;
	from_date: string;
	game_metric: boolean;
	incentive_metric: boolean;
	metric_bands: { from: number; to: number; multiplier: number }[];
}) => {
	const response = await httpClient.post(
		"/admin/incentive/metrics/create",
		payload
	);
	return response.data.data.result;
};

export { getMetrics, createIncentiveStructures };
