import Dialog from "@mui/material/Dialog";
import React, { ReactNode } from "react";
import SectionedDialog from "../../components/SectionedDialog/SectionedDialog";

type t_Modal =
    | {
          type: "sectioned";
          title: string;
          content: React.ReactNode;
          onClose?: () => void;
      }
    | {
          type: "standard";
          content: React.ReactNode;
          onClose?: () => void;
      };

const ModalContext = React.createContext<
    [t_Modal | undefined, React.Dispatch<React.SetStateAction<t_Modal | undefined>>] | undefined
>(undefined);

const useModal = () => {
    const context = React.useContext(ModalContext);

    if (context === undefined) {
        throw new Error("Context must be used within it's provider [modal].");
    }

    return context;
};

const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [modal, setModal] = React.useState<t_Modal>();

    const handleClose = () => {
        if (modal?.onClose != null) {
            modal.onClose();
        }

        setModal(undefined);
    };
    return (
        <ModalContext.Provider value={[modal, setModal]}>
            {modal?.type === "sectioned" && (
                <SectionedDialog open={!!modal} onClose={handleClose} title={modal.title}>
                    {modal.content}
                </SectionedDialog>
            )}
            {modal?.type === "standard" && (
                <Dialog open={!!modal} onClose={handleClose}>
                    {modal.content}
                </Dialog>
            )}
            {children}
        </ModalContext.Provider>
    );
};

const ModalConsumer = ModalContext.Consumer;

export { useModal, ModalConsumer };
export default ModalProvider;
