import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import OvatationTableComponent from "./OvationTableComponent";
import useOvation from "./useOvation";

type OvationsTableProps = {
    month: string;
    survey: "FCR" | "MBR";
    agent_entity_no?: string;
    ovationType: "positive" | "negative";
    setReceivedOvations: (value: any) => void;
};

type Ovation = {
    ResponseID: string;
    SurveyType: string;
    CallID: string;
    CallDateAnswered: string;
    SurveyDate: string;
    SurveyScore: string;
    OvationType: string;
    Comments: string;
};

type UserOvations = {
    name: string;
    profile_img: string;
    entity_no: string;
    ovations: Ovation[];
};

const OvationTable = ({ month, survey, agent_entity_no, ovationType, setReceivedOvations }: OvationsTableProps) => {
    const [ovationRecordsData, setOvationRecordsData] = useState<DiscoveryBeYou.OvationRecord[] | []>([]);
    const { getOvationRecords, getOvationNegativeRecords, isLoading } = useOvation();

    const handleOvationRecords = async () => {
        const response = await getOvationRecords({ month, survey, agent_entity_no });

        if (response?.success) {
            setOvationRecordsData(response.data);
        }
    };

    const handleOvationNegativeRecords = async () => {
        const response = await getOvationNegativeRecords({ month, survey, agent_entity_no });

        if (response?.success) {
            setOvationRecordsData(response.data);
        }
    };

    const combinedOvations = ovationRecordsData.reduce<Record<string, UserOvations>>((acc, ovation) => {
        const { name, entity_no } = ovation;

        if (!acc[entity_no]) {
            acc[entity_no] = {
                name,
                profile_img: ovation.profile_img,
                entity_no,
                ovations: [],
            };
        }

        acc[entity_no].ovations.push({
            ResponseID: ovation.ResponseID,
            SurveyType: ovation.SurveyType,
            CallID: ovation.CallID,
            CallDateAnswered: ovation.CallDateAnswered,
            SurveyDate: ovation.SurveyDate,
            SurveyScore: ovation.SurveyScore,
            OvationType: ovation.OvationType,
            Comments: ovation.Comments,
        });

        return acc;
    }, {});

    useEffect(() => {
        if (ovationType === "positive") {
            handleOvationRecords();
        } else {
            handleOvationNegativeRecords();
        }
    }, [month, survey, agent_entity_no, ovationType]);

    useEffect(() => {
        if (combinedOvations) {
            setReceivedOvations(Object.values(combinedOvations));
        }
    }, [ovationRecordsData, ovationType]);

    const tableData = JSON.parse(JSON.stringify(ovationRecordsData));

    const ovationColumns = useMemo(
        () => [
            {
                title: "Agent",
                field: "role",
            },
            {
                title: "Response ID",
                field: "ResponseID",
            },
            {
                title: "Survey Type",
                field: "SurveyType",
            },
            {
                title: "Call ID",
                field: "CallID",
            },
            {
                title: "Call Date Answered",
                field: "CallDateAnswered",
            },
            {
                title: "Survey Date",
                field: "SurveyDate",
            },
            {
                title: "Survey Score",
                field: "SurveyScore",
            },
            {
                title: "Ovation Type",
                field: "OvationType",
            },
            {
                title: "Member Comment",
                field: "Comments",
            },
        ],
        [],
    );

    return (
        <Box>
            <OvatationTableComponent columns={ovationColumns} data={tableData} title="" isLoading={isLoading} />
        </Box>
    );
};

export default OvationTable;
