import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const styles = makeStyles((theme) =>
    createStyles({
        userProdContainer: {
            overflow: "scroll",
            height: "100%",
            paddingBottom: "10px",
            paddingRight: "5px",
        },
        userProdSection: {
            height: "unset",
        },
        exportBtn: {
            display: "flex",
            margin: "0.5em 1em 0 auto",
        },
        toolTip: {
            boxShadow: `${theme.shadows[10]}`,
            color: `${theme.palette.primary}`,
            background: `${theme.palette.background.paper}`,
        },
    }),
);
