import { SportsScore } from "@mui/icons-material";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { httpClient } from "../../../clients/http.client";
import SectionCard from "../../../components/SectionCard/SectionCard";
import useMultiDateSelect from "../../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { getImageUrl } from "../../../misc/helpers";
import {
    useGetDiscoveryTeamsMutation,
    useGetGlobalLeaderBoarsMutation,
    useGetJobTitlesMutation,
    useGetRegionsMutation,
    useGetServiceTeamsMutation,
} from "../../../redux/api/beYouCore";
import { setLeaderBoard } from "../../../redux/slices/GlobalLeaderBoardsSlice";
import { RootState } from "../../../redux/store";
import LeaderboardTable from "./LeaderboardTable";
import PodiumCard from "./PodiumCard";

const Leaderboards = () => {
    const globalLeaderBoard = useSelector((state: RootState) => state.globalLeaderBoard);
    const dispatch = useDispatch();
    const [setGlobalLeaderBoard] = useGetGlobalLeaderBoarsMutation();
    const [tabValue, setTabValue] = useState<number>(0);
    const [regions, setRegions] = useState<string[]>([]);
    const [jobTitles, setJobTitles] = useState<string[]>([]);
    const [serviceTeams, setServiceTeams] = useState<string[]>([]);
    const [discoveryTeams, setDiscoveryTeams] = useState<string[]>([]);
    const [allMetrics, setAllMetrics] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState<any>();
    const [selectedRegion, setSelectedRegion] = useState<any>();
    const [selectedJobTitle, setSelectedJobTitle] = useState<any>();
    const [selectedServiceTeam, setSelectedServiceTeam] = useState<any>();
    const [selectedDiscoveryTeam, setSelectedDiscoveryTeam] = useState<any>();
    const [selectedMetric, setSelectedMetric] = useState<any>("Attendance");
    const [getRegion] = useGetRegionsMutation();
    const [getJobTitles] = useGetJobTitlesMutation();
    const [getServiceTeam] = useGetServiceTeamsMutation();
    const [getDiscoveryTeam] = useGetDiscoveryTeamsMutation();
    const table1ref = useRef<any>();
    const [dataLoading, setDataLoading] = useState(false);
    const { yearAndMonthSelector, formattedPeriods, datePills, selectedDate, datesPeriodsSelected } =
        useMultiDateSelect();

    const leaderBoardColumns = useMemo(
        () => [
            {
                title: "Name",
                field: "DisplayName",
                render: (rowData: any) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={getImageUrl("profile", rowData.profile_img)} />
                        <Typography sx={{ ml: 1 }}>{rowData.DisplayName}</Typography>
                    </Box>
                ),
            },
            {
                title: "Job Title",
                field: "role",
            },
            {
                title: "Team",
                field: "team_name",
            },
            {
                title: "Score",
                field: "total_sum",
                align: "right" as const,
                render: (rowData: any) => {
                    const score = rowData.total_sum;

                    if (score == null) {
                        return (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <SportsScore sx={{ color: "#FF4040", fontSize: 15, rotate: "-45deg" }} />
                                <Typography>No Score</Typography>
                            </Box>
                        );
                    }

                    return (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <Typography>{Number.parseFloat(score).toFixed(2)}</Typography>
                        </Box>
                    );
                },
            },
            {
                title: "Rank",
                field: "rank",
            },
        ],
        [],
    );

    const tableData = useMemo(
        () => JSON.parse(JSON.stringify(globalLeaderBoard.leaderBoard.records)),
        [globalLeaderBoard],
    );

    const podiumWinners = tableData.slice(0, 3);

    useEffect(() => {
        setDataLoading(true);
        const globalLeaderFilter = {
            month: formattedPeriods,
            metric: selectedMetric,
            skill_id: selectedSkill,
            region_id: selectedRegion,
            job_title_id: selectedJobTitle,
            service_team_id: selectedServiceTeam,
            discovery_team_id: selectedDiscoveryTeam,
            team_leader: tabValue === 0,
        };

        if (formattedPeriods.length === 0) {
            return;
        }

        setGlobalLeaderBoard(globalLeaderFilter)
            .unwrap()
            .then((response: any) => {
                dispatch(setLeaderBoard(response?.data));
            })
            .catch((error) => {
                console.error("Error fetching global leaderboard:", error);
            })
            .finally(() => {
                resetPagination();
                setDataLoading(false);
            });
    }, [
        selectedSkill,
        selectedRegion,
        selectedJobTitle,
        selectedServiceTeam,
        selectedDiscoveryTeam,
        tabValue,
        selectedMetric,
        selectedDate,
        datesPeriodsSelected,
    ]);

    const getAllMetrics = async () => {
        try {
            const response = await httpClient.get("metrics");
            const metricsWithIds = response?.data?.data?.metrics.map((metric: any, index: number) => ({
                id: index, // Generate a unique id based on the index
                name: metric.name,
            }));
            setAllMetrics(metricsWithIds);
        } catch (error) {
            console.error("An error occurred while fetching metrics:", error);
        }
    };

    const resetPagination = () => {
        table1ref.current?.onChangePage(null, 0);
    };

    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);
            try {
                const [regionsData, jobTitlesData, serviceTeamsData, discoveryTeamsData] = await Promise.all([
                    getRegion().unwrap(),
                    getJobTitles().unwrap(),
                    getServiceTeam().unwrap(),
                    getDiscoveryTeam().unwrap(),
                ]);

                setRegions(regionsData?.data?.regions);
                setJobTitles(jobTitlesData?.data?.job_titles);
                setServiceTeams(serviceTeamsData?.data?.service_teams);
                setDiscoveryTeams(discoveryTeamsData?.data?.discovery_teams);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setDataLoading(false);
            }
        };

        fetchData();
        getAllMetrics();
    }, []);

    useEffect(() => {
        setSelectedSkill(undefined);
        setSelectedRegion(undefined);
        setSelectedJobTitle(undefined);
        setSelectedServiceTeam(undefined);
        setSelectedDiscoveryTeam(undefined);
        setSelectedMetric("Attendance");
    }, [tabValue]);

    const variants = {
        flyIn: () => ({
            opacity: 1,
            transition: { duration: 0.5, ease: "easeOut" },
        }),
        bobbing: () => ({
            bottom: ["0px", "20px", "0px"],
            transition: { duration: 5, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
        twisting: () => ({
            transform: ["rotate(0deg)", "rotate(5deg)", "rotate(0deg)"],
            transition: { duration: 7, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
    };

    const renderAutocomplete = (label: string, options: any[], selected: any, setSelected: any) => (
        <Autocomplete
            style={{ marginLeft: 15, width: 200 }}
            value={options.find((option) => option.id === selected) || null}
            onChange={(event, newValue) => {
                setSelected(newValue ? newValue.id : null);
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
        />
    );

    return (
        <>
            <SectionCard title="Leaderboards" fullWidth scrolling secondaryAction={yearAndMonthSelector}>
                <Grid container spacing={2} style={{ padding: 16 }} width="100%" alignSelf="center">
                    <Grid item xs={12}>
                        <SectionCard
                            fullWidth
                            primaryAction={
                                <Tabs
                                    value={tabValue}
                                    onChange={(_, value) => setTabValue(value)}
                                    aria-label="notifications section"
                                    style={{ padding: 16 }}
                                >
                                    <Tab label="Team Leaderboard" />
                                    <Tab label="Global Leaderboard" />
                                </Tabs>
                            }
                        >
                            <Box pb="2em">{datePills}</Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mt: 2,
                                    overflowX: "scroll",
                                }}
                            >
                                {renderAutocomplete("Region", regions, selectedRegion, setSelectedRegion)}
                                {renderAutocomplete("Job Title", jobTitles, selectedJobTitle, setSelectedJobTitle)}
                                {renderAutocomplete(
                                    "Service Team",
                                    serviceTeams,
                                    selectedServiceTeam,
                                    setSelectedServiceTeam,
                                )}
                                {renderAutocomplete(
                                    "Team",
                                    discoveryTeams,
                                    selectedDiscoveryTeam,
                                    setSelectedDiscoveryTeam,
                                )}
                                <Autocomplete
                                    style={{ marginLeft: 15, width: 200 }}
                                    value={selectedMetric}
                                    onChange={(event: any, newValue: any) => {
                                        setSelectedMetric(newValue.name);
                                    }}
                                    options={allMetrics}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Metric"
                                            variant="standard"
                                            className="filter-metric-rankings"
                                            placeholder="Metric"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.name}
                                        </Box>
                                    )}
                                />
                                <Button
                                    variant="contained"
                                    style={{
                                        boxShadow: "none",
                                        marginLeft: 10,
                                        padding: "5px 5px",
                                    }}
                                    onClick={() => {
                                        setSelectedSkill(undefined);
                                        setSelectedRegion(undefined);
                                        setSelectedJobTitle(undefined);
                                        setSelectedServiceTeam(undefined);
                                        setSelectedDiscoveryTeam(undefined);
                                        setSelectedMetric("Attendance");
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>

                            {podiumWinners.length > 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: { xs: 2, md: 8 },
                                        alignItems: { xs: "center", md: "flex-end" },
                                        justifyContent: "center",
                                        flexDirection: { xs: "column", md: "row" },
                                        marginTop: 10,
                                    }}
                                >
                                    {podiumWinners.length >= 2 && (
                                        <PodiumCard
                                            name={podiumWinners[1].DisplayName}
                                            rank={podiumWinners[1].rank}
                                            score={podiumWinners[1].total_sum}
                                            profilePicture={podiumWinners[1].profile_img}
                                            position={2}
                                        />
                                    )}

                                    <PodiumCard
                                        name={podiumWinners[0].DisplayName}
                                        rank={podiumWinners[0].rank}
                                        score={podiumWinners[0].total_sum}
                                        profilePicture={podiumWinners[0].profile_img}
                                        position={1}
                                    />

                                    {podiumWinners.length === 3 && (
                                        <PodiumCard
                                            name={podiumWinners[2].DisplayName}
                                            rank={podiumWinners[2].rank}
                                            score={podiumWinners[2].total_sum}
                                            profilePicture={podiumWinners[2].profile_img}
                                            position={3}
                                        />
                                    )}
                                </Box>
                            )}

                            {dataLoading ? (
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "auto",
                                        padding: 5,
                                        height: "100%",
                                    }}
                                >
                                    <CircularProgress size={150} />
                                </Box>
                            ) : (
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ position: "relative", flex: 0.2 }}>
                                        <img src="/images/medal.svg" style={{ position: "fixed", width: "3%" }} />
                                    </Box>
                                    <Box sx={{ flex: 2.3 }}>
                                        {tabValue === 0 && (
                                            <LeaderboardTable
                                                columns={leaderBoardColumns}
                                                data={tableData}
                                                title={"Team Leaderboard"}
                                            />
                                        )}
                                        {tabValue === 1 && (
                                            <LeaderboardTable
                                                columns={leaderBoardColumns}
                                                data={tableData}
                                                title={"Global Leaderboard"}
                                            />
                                        )}
                                    </Box>
                                    <Box sx={{ position: "relative", flex: 0.2 }}>
                                        <AnimatePresence>
                                            <motion.img
                                                src="/images/beyou-leaderboard.svg"
                                                style={{ position: "fixed", width: "10%", bottom: 2 }}
                                                initial={{ opacity: 0 }}
                                                animate={["flyIn", "bobbing", "twisting"]}
                                                variants={variants}
                                            />
                                        </AnimatePresence>
                                    </Box>
                                </Box>
                            )}
                        </SectionCard>
                    </Grid>
                </Grid>
            </SectionCard>
        </>
    );
};

export default Leaderboards;
