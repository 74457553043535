import { httpClient } from "../../../../clients/http.client";
import { t_ApiResponse } from "../../../../hooks/material-table/useMaterialTable";
import { MiscBody } from "../../../../types/api.types";
import { t_UserBase } from "../../User.types";

const getUserBy = (payload: {
	search?: string;
	pagination: MiscBody["pagination"];
}) => {
	return new Promise<t_ApiResponse<t_UserBase>>((resolve, reject) => {
		httpClient.post("/users/search", payload).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve(data);
					break;
				default:
					reject(data.message);
					break;
			}
		});
	});
};

export { getUserBy };
