import { format } from "date-fns";
import React, { createContext, ReactNode, useContext, useReducer } from "react";

import { useUserState } from "../../context/user/user.ctx";
import { t_User } from "../../types/user.types";
import { getUserMetrics } from "./Performance.api";
import { t_MetricPerformance } from "./Performance.types";

type t_PerformanceUser = {
    user: t_User.Profile;
    metrics: t_MetricPerformance[];
    metric_fetch_timestamp?: number;
};

type t_PerformanceState = {
    viewUser?: t_PerformanceUser;
    month: string;
    comparison_month: string;
};

type t_PerformanceActions =
    | {
          type: "refresh";
      }
    | {
          type: "view";
          viewUser: t_PerformanceUser;
      }
    | {
          type: "update_dates";
          month: Date;
      }
    | {
          type: "update_metrics";
          metrics: t_MetricPerformance[];
      };

const now = new Date(Date.now());

// first of the month
const init_date = {
    month: format(new Date(now.getFullYear(), now.getMonth(), 1), "yyyy/MM/dd"),
    comparison_month: format(new Date(now.getFullYear(), now.getMonth() - 1, 1), "yyyy/MM/dd"),
};

const performanceContext = createContext<[t_PerformanceState, React.Dispatch<t_PerformanceActions>]>([
    { ...init_date },
    () => {},
]);

const usePerformanceContext = () => {
    const context = useContext(performanceContext);
    if (context === undefined) {
        throw "Performance Context must be used withing a provider.";
    }

    return context;
};

const PerformanceContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const currentUser = useUserState();

    const performanceReducer = (state: t_PerformanceState, action: t_PerformanceActions): t_PerformanceState => {
        switch (action.type) {
            case "refresh":
                if (currentUser.profile !== undefined) {
                    /** @MM Potentially move this to state, if needed by other components */
                    getUserMetrics({
                        month: state.month,
                        comparison_month: state.comparison_month,
                        entity_no: currentUser.entity_no,
                        incentive: false,
                    }).then((metrics) => {
                        currentUser.profile &&
                            dispatch({
                                type: "view",
                                viewUser: {
                                    user: currentUser.profile,
                                    metrics,
                                    metric_fetch_timestamp: Date.now(),
                                },
                            });
                    });
                }
                return {
                    ...state,
                    viewUser: undefined,
                };
            case "view":
                return {
                    ...state,
                    viewUser: action.viewUser,
                };
            case "update_dates":
                const comparison_month = new Date(action.month);
                comparison_month.setMonth(comparison_month.getMonth() - 1);
                return {
                    ...state,
                    month: format(action.month, "yyyy/MM/dd"),
                    comparison_month: format(comparison_month, "yyyy/MM/dd"),
                };
            case "update_metrics":
                return {
                    ...state,
                    viewUser: state.viewUser
                        ? {
                              ...state.viewUser,
                              metrics: action.metrics,
                              metric_fetch_timestamp: Date.now(),
                          }
                        : undefined,
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(performanceReducer, {
        ...init_date,
    });

    React.useEffect(() => {
        let isActive = true;

        if (state.viewUser?.user.entity_no) {
            getUserMetrics({
                month: state.month,
                comparison_month: state.comparison_month,
                entity_no: state.viewUser.user.entity_no,
                incentive: false,
            }).then((metrics) => {
                if (isActive) {
                    dispatch({
                        type: "update_metrics",
                        metrics,
                    });
                }
            });
        }
        return () => {
            isActive = false;
        };
    }, [state.month]);

    return <performanceContext.Provider value={[state, dispatch]}>{children}</performanceContext.Provider>;
};

const PerformanceContextConsumer = performanceContext.Consumer;

export { PerformanceContextConsumer, PerformanceContextProvider, usePerformanceContext };
export type { t_PerformanceUser };
