import { AppBar, MenuItem, Tab, Tabs, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { useUserDispatch } from "../../context/user/user.ctx";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setViewUserInfo } from "../../redux/slices/ViewUserSlice";
import { RootState } from "../../redux/store";

type t_Tab = {
    _id: any;
    label: string;
    options?: { id: number; label: string }[];
};

interface TabGroupProps {
    tabValue: number;
    tabChange: (newValue: number, option?: number) => any;
    tabList: t_Tab[];
    [propName: string]: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: theme.spacing(1),
            width: "min-content",
        },
        tabs: {
            borderRadius: theme.spacing(1),
            height: "100%",
            alignItems: "center",
        },
        tab: {
            borderRadius: theme.spacing(1),
            "&.Mui-selected": {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
            },
            width: "max-content",
            padding: "8px 12px",
            minWidth: 0,
        },
        tabIndicator: {
            display: "none",
        },
        dropdownTab: {},
        dropdownRoot: {
            color: theme.palette.info.contrastText,
            backgroundColor: "transparent",
            paddingRight: "36px !important",
            textTransform: "uppercase",
            boxShadow: "none !important",
            opacity: "0.6",
            "&:focus": {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
                opacity: 1,
            },
        },
        dropdownActive: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            opacity: 1,
        },
    }),
);
const TabGroup: React.FC<TabGroupProps> = ({ tabValue, tabChange, tabList, optionChange, children }) => {
    const classes = useStyles();

    const [selectedOption, setSelectedOption] = React.useState<string>("");
    const userObjectDispatch = useUserDispatch();
    const teamMembers = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const user = useSelector((state: RootState) => state.user.userInfo);
    const dispatch = useDispatch();

    const _tabValue = tabList[tabValue] != null && tabList[tabValue].options == null && tabValue; // or false
    // we have to trigger this on click due to the "Tabs" appended functionality for each Tab
    const handleDropdown: React.MouseEventHandler<HTMLDivElement> = (event) => {
        const target = event.target as any;
        if (target.name != null && target.value != null) {
            const name = String(target.name);
            const value = String(target.value);

            if (name.includes("tab-select")) {
                const tab = Number(name.substring(11, name.length));
                const option = Number(value);

                setSelectedOption(value);
                tabChange(tab, option);
            }

            const newEntityNo = target.value;
            // {
            //     teamMembers.map((item: any) => {
            //         if (item.user.entity_no == target.value) {
            //             dispatch(setViewUserInfo(item));
            //         }
            //     });
            // }

            // userObjectDispatch({
            //     type: "DASHBOARD_CHANGE",
            //     data: { entity_no: newEntityNo },
            // });
        }
    };
    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        // if it's not a native tab then leave handling for correct function
        if (typeof newValue === "number") {
            setSelectedOption("");
            tabChange(newValue);
        }

        {
            teamMembers.map((item: any) => {
                if (item.user.entity_no == newValue?.props?.value) {
                    dispatch(setViewUserInfo(item));
                }
            });
        }
    };
    return (
        <AppBar className={classes.root} color="secondary" position="relative" elevation={0}>
            <Tabs
                classes={{ root: classes.tabs }}
                value={_tabValue}
                onChange={handleChange}
                aria-label="Tab group."
                TabIndicatorProps={{ className: classes.tabIndicator }}
            >
                {tabList ? (
                    tabList.map((tab, index) =>
                        tab.options != null ? (
                            <DropdownSelect
                                className={classes.dropdownTab}
                                // classes={{
                                //     root: clsx(classes.dropdownRoot, {
                                //         [classes.dropdownActive]: tabValue === index,
                                //     }),
                                // }}
                                displayEmpty
                                value={tabValue === index ? selectedOption : ""}
                                onClick={handleDropdown}
                                name={`tab-select-${index}`}
                                key={`tab-select-${tab._id}`}
                            >
                                <MenuItem value="" disabled>
                                    {tab.label}
                                </MenuItem>
                                {tab.options.map((option) => (
                                    <MenuItem key={`tab-select-option-${option.id}`} value={String(option.id)}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </DropdownSelect>
                        ) : (
                            <Tab
                                key={tab._id}
                                className={classes.tab}
                                classes={{
                                    root: classes.tab,
                                }}
                                label={tab.label}
                            />
                        ),
                    )
                ) : (
                    <Tab className={classes.tab} label="We Running Empty" />
                )}
                {children}
            </Tabs>
        </AppBar>
    );
};

export type { TabGroupProps, t_Tab };
export default TabGroup;
