import { httpClient } from "../../../../../clients/http.client";
import { t_IncentiveType } from "../MaintainIncentive.types";

const getIncentiveMetrics = async (payload: {
	incentiveCategoryID: t_IncentiveType["id"];
}) => {
	const response = await httpClient.post("/admin/incentive/metrics", payload);
	return response.data.data.metrics;
};

export { getIncentiveMetrics };
