type t_InitiativeType = {
	id: number;
	name: string;
	handling_time: number;
	weight: number;
};

type t_ProductionAdditionalVolume = {
	id?: number;
	initiative_type_id?: number;
	volume: number;
	comment: string;
};

type t_ProductionAdditionalVolumeRecord = t_ProductionAdditionalVolume & {
	initiative_type_name: string;
	aht: number;
	weighted_volume: number;
	verified: boolean;
};

const base_ProductionAdditionalVolume: t_ProductionAdditionalVolume = {
	id: undefined,
	initiative_type_id: undefined,
	volume: 0,
	comment: "",
};

export { base_ProductionAdditionalVolume };
export type {
	t_InitiativeType,
	t_ProductionAdditionalVolume,
	t_ProductionAdditionalVolumeRecord,
};
