import { format } from "date-fns";
import { httpClient, rawHttpClient } from "../clients/http.client";
import { t_User } from "../types/user.types";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/slices/UserSlice";

const userAuthEndpoints = {
    login: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/login`,
    discovery_login: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/discovery-login`,
};
type LoginPayload = {};
const login = async (payload: {
    data: {
        entity_no: string;
    };
}) => {
    const url = userAuthEndpoints.login;
    const data = payload.data;

    const response = await rawHttpClient.post(url, data, {
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
        },
    });
    return response.data;
};
const masqueradeLogin = (payload: { entity_no: string }) => {
    return new Promise<{
        api_access_token: string;
        token_type: string;
    }>((resolve, reject) => {
        httpClient.post("/masquerade", payload).then(({ data }) => {
            switch (data.status) {
                case "success":
                    resolve(data.data);
                    break;
                default:
                    reject(data.message);
                    break;
            }
        });
    });
};
const discoveryLogin = (payload: { code: string }) =>
    new Promise<{
        api_access_token: string;
        token_type: string;
    }>((resolve, reject) => {
        const url = userAuthEndpoints.discovery_login;

        rawHttpClient
            .post(url, payload, {
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                },
            })
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data);
                        break;
                    default:
                        reject(data.message);
                        break;
                }
            });
    });

const getMyMetricTypes = () => {
    return new Promise<string[]>((resolve, reject) => {
        const now = new Date();
        const month = format(
            new Date(now.getFullYear(), now.getMonth(), 1),
            "yyy-MM-dd"
        );
        const comparison_month = format(
            new Date(now.getFullYear(), now.getMonth() - 1, 1),
            "yyy-MM-dd"
        );

        httpClient
            .post("/metrics/summary/me/new", { month, comparison_month })
            .then((resp) => {
                const value = resp.data.data.metrics;
                switch (resp.data.status) {
                    case "success":
                        resolve(
                            value
                                ? value.map((m: { metric: string }) => m.metric)
                                : []
                        );
                        break;
                    default:
                        reject();
                }
            })
            .catch((err) => reject(err));
    });
};

const getMyMetrics = () => {
    return new Promise<t_User.Metric[]>((resolve, reject) => {
        // @MM adding "current" dates + comparison for standardisation (api calls)
        let month = new Date();

        // setting date to first of month for consistency
        month.setDate(1);
        let comparison_month = new Date(month);
        comparison_month.setMonth(comparison_month.getMonth() - 1);

        httpClient
            .post("/metrics/summary/me", {
                month: format(month, "yyyy/MM/dd"),
                comparison_month: format(comparison_month, "yyyy/MM/dd"),
            })
            .then((response) => {
                switch (response.data.status) {
                    case "success":
                        resolve(response.data.data.metric_values);
                        break;
                    default:
                        reject();
                }
            })
            .catch((reason) => {
                reject();
            });
    });
};
const getMyProfile = async () => {
    const response = await httpClient.get("/profile");
    return response.data.data.profile;
};

export {
    login,
    masqueradeLogin,
    discoveryLogin,
    getMyMetrics,
    getMyProfile,
    getMyMetricTypes,
};
