import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { saveGroupName } from "../../twtGroups.api";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import styles from "./styles.module.css";
import {
    getWeightedRegionsSearch,
    getWeightedServiceEnviromentsSearch,
} from "../../../Production/tables/PoolWeights/PoolWeights.api";
import BUAutocomplete from "../../../../components/BUAutocomplete/BUAutocomplete";
import muiStyles from "../EditPoolGroup/EditPoolGroup.styles";
import { MiscBody } from "../../../../types/api.types";

type t_GroupSpecs = {
    service_team: string;
    stream: string;
    skill: string;
};

interface NewPoolGroupProps {
    handleClose: () => void;
}

const NewPoolGroup = ({ handleClose }: NewPoolGroupProps) => {
    const muiStyle = muiStyles();
    const [groupName, setGroupName] = React.useState("");
    const [activeGroup, setActiveGroup] = React.useState<any>();
    const [groupSpecs, setGroupSpecs] = React.useState<t_GroupSpecs>({
        service_team: "",
        stream: "",
        skill: "",
    });
    const [submitState, setSubmitState] = React.useState<
        "not submitted" | "pending" | "success" | "error"
    >("not submitted");

    const onUpdateGroupName = () => {
        setSubmitState("pending");
        saveGroupName({
            name: activeGroup.name,
            twt_region_id: activeGroup.twt_region_id,
            twt_service_environment_id: activeGroup.twt_service_environment_id,
        })
            .then(() => setSubmitState("success"))
            .catch((err) => {
                setSubmitState("error");
            });
    };

    const selectSE = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedServiceEnviromentsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const serviceEnviroments = resp.data.records.data.map(
                        (item: any) => ({
                            id: item.TwtServiceEnvironmentID,
                            name: item.Name,
                        })
                    );

                    res(
                        serviceEnviroments.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        }))
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const selectRegion = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedRegionsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const serviceEnviroments = resp.data.records.data.map(
                        (item: any) => ({
                            id: item.TwtRegionID,
                            name: item.Name,
                        })
                    );

                    res(
                        serviceEnviroments.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        }))
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const handleSelecRegion = (twt_region_id: any) => {
        setActiveGroup(
            twt_region_id
                ? {
                      ...activeGroup,
                      twt_region_id: twt_region_id.id,
                  }
                : {
                      ...activeGroup,
                      twt_region_id: "",
                  }
        );
    };
    const handleSelecServiceEnviroment = (twt_service_environment_id: any) => {
        setActiveGroup(
            twt_service_environment_id
                ? {
                      ...activeGroup,
                      twt_service_environment_id: twt_service_environment_id.id,
                  }
                : { ...activeGroup, twt_service_environment_id: "" }
        );
    };

    return (
        <SectionCard
            title={`Create group`}
            titleProps={{
                variant: "h6",
            }}
            className={styles.newGroup}
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <Box width="clamp(30em, 50%, 40em)" margin="0 auto 1em">
                <Box display="grid" gridTemplateColumns="2fr 4fr">
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography>Name</Typography>
                    </Box>
                    <TextField
                        value={activeGroup ? activeGroup.name : ""}
                        onChange={(e) =>
                            setActiveGroup({
                                ...activeGroup,
                                name: e.target.value,
                            })
                        }
                        style={{ marginBottom: "1em" }}
                        error={activeGroup && activeGroup.name.length === 0}
                        helperText={
                            activeGroup && !activeGroup.name.length
                                ? "name is required"
                                : ""
                        }
                        placeholder="New Pool Name"
                    ></TextField>

                    <Box display={"flex"} alignItems={"center"}>
                        <Typography>Service team</Typography>
                    </Box>
                    <div className={muiStyle.addPool}>
                        <div className={muiStyle.autoCompl}>
                            <BUAutocomplete
                                label="Select Service Team"
                                inputClassName={muiStyle.poolField}
                                dataSource={selectSE}
                                onChange={handleSelecServiceEnviroment}
                            />
                        </div>
                    </div>
                    {/* <Typography>{groupSpecs.service_team}</Typography> */}
                    <Box display={"flex"} alignItems={"center"} mb={"1rem"}>
                        <Typography>Region</Typography>
                    </Box>
                    <div
                        className={muiStyle.addPool}
                        style={{ marginBottom: "1.5em" }}
                    >
                        <div className={muiStyle.autoCompl}>
                            <BUAutocomplete
                                label="Select Region"
                                inputClassName={muiStyle.poolField}
                                dataSource={selectRegion}
                                onChange={handleSelecRegion}
                            />
                        </div>
                    </div>
                </Box>

                <Box width="100%" textAlign="center" margin="3em 0.5em 0">
                    <SubmitButton
                        variant="outlined"
                        onClick={onUpdateGroupName}
                        loading={submitState === "pending"}
                        disabled={
                            submitState === "success" ||
                            submitState === "pending"
                        }
                    >
                        {submitState === "not submitted" &&
                            "Save Group Details"}
                        {submitState === "success" && "Saved"}
                        {submitState === "pending" && "Saving"}
                        {submitState === "error" && "Please try again"}
                    </SubmitButton>
                </Box>
            </Box>
        </SectionCard>
    );
};

export default NewPoolGroup;
