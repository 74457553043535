import {
    Avatar,
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import Send from "@mui/icons-material/Send";
import { awardUserBadge, getAwardableBadges, t_Badge } from "../User/components/AwardBadge/AwardBadge.api";
import { toast } from "react-toastify";
import { useUserState } from "../../context/user/user.ctx";

const AwardBadge = ({ entity_no, onBadgeAwarded }: any) => {
    const [badges, setBadges] = useState<t_Badge[]>();
    const currentUser = useUserState();
    useEffect(() => {
        getAwardableBadges({})
            .then((data) => {
                setBadges(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const awardBadge = async (badge_id: number) => {
        awardUserBadge({ entity_no, badge_id })
            .then((data) => {
                toast.success("The badge has been awarded!", {
                    position: "bottom-right",
                });
                onBadgeAwarded();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <SectionCard fullWidth hasBackground title="Award a badge" fontSize={20} scrolling={true}>
            <Box width={600} height={600}>
                <List>
                    {badges?.map((badge) => {
                        return (
                            <>
                                <ListItem>
                                    <ListItemAvatar>
                                        <img
                                            className="square"
                                            style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: 50,
                                                marginRight: 20,
                                                objectFit: "contain",
                                            }}
                                            src={badge.badge_img}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography
                                            style={{ fontSize: 18, fontWeight: 600, width: "80%", marginBottom: 10 }}
                                        >
                                            {badge?.name}
                                        </Typography>
                                        <Typography style={{ fontSize: 15, fontWeight: 400, width: "80%" }}>
                                            {badge?.description}
                                        </Typography>
                                    </ListItemText>
                                    <ListItemIcon
                                        onClick={() => {
                                            awardBadge(badge.id);
                                        }}
                                    >
                                        <Send />
                                    </ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <Divider
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </ListItem>
                            </>
                        );
                    })}
                </List>
            </Box>
        </SectionCard>
    );
};

export default AwardBadge;
