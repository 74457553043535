import { httpClient } from "../../clients/http.client";
import { t_ApiResponse } from "../../hooks/material-table/useMaterialTable";
import { MiscBody } from "../../types/api.types";
import { t_UsageStats, UsageRecord } from "./UsageReport.types";

const getUsageRecords = async (
  payload: {
    from_date: string;
    to_date: string;
  } & MiscBody
) =>
  new Promise<t_ApiResponse<UsageRecord>>((resolve) => {
    httpClient.post("/reports/usage/records", payload).then(({ data }) => {
      resolve(data);
    });
  });

const getUsageRecordsMulti = (
  payload: {
    months: string[];
    filter?: MiscBody["filter"];
  } & MiscBody
) =>
  new Promise<t_ApiResponse<UsageRecord>>((resolve, reject) => {
    httpClient
      .post("/reports/usage/records/multi", payload)
      .then((resp) => {
        if (resp.data.status === "success") resolve(resp.data);
        reject(resp.data.message);
      })
      .catch((err) => reject(err.message));
  });

const getUsageStats = async (payload: {
  from_date: string;
  to_date: string;
}) => {
  const response = await httpClient.post("/reports/usage/stats", payload);
  return response.data.data.stats;
};

const getUsageStatsMulti = (payload: { months: string[] }) =>
  new Promise<t_UsageStats>((resolve, reject) => {
    httpClient
      .post("/reports/usage/stats/multi", {
        months: payload.months,
      })
      .then((resp) => {
        if (resp.data.status === "success") resolve(resp.data.data.stats);
        reject(resp.data.message);
      })
      .catch((err) => reject(err.message));
  });

export {
  getUsageRecords,
  getUsageStats,
  getUsageRecordsMulti,
  getUsageStatsMulti,
};
