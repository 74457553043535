import axios, { AxiosRequestConfig } from "axios";

import { InternalError } from "../context/error/error.ctx";

const headers: AxiosRequestConfig["headers"] = {
    "Content-Type": "application/json",
};
const httpClient = axios.create({
    headers,
});

const getUserSession = () => {
    const token_type = sessionStorage.getItem("token_type");
    const api_access_token = sessionStorage.getItem("api_access_token");
    const masquerade_access_token = sessionStorage.getItem("masquerade_access_token");

    if (!(token_type && api_access_token)) {
        return undefined;
    }

    return {
        token_type,
        api_access_token: masquerade_access_token ?? api_access_token,
    };
};
const authInterceptor = (config: AxiosRequestConfig): any => {
    const session = getUserSession();

    if (!session) {
        throw new InternalError("NOT_LOGGED_IN");
    }
    config.headers = {
        ...config.headers,
        Authorization: `${session.token_type} ${session.api_access_token}`,
    };

    config.baseURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
    return config;
};

httpClient.interceptors.request.use(authInterceptor);

export { getUserSession, httpClient, axios as rawHttpClient };
