import { httpClient } from "../../../../clients/http.client";
import { MiscBody } from "../../../../types/api.types";

const getMyFriends = async (payload?: { search?: string } & MiscBody) => {
	var body = {};
	if (payload != null) {
		const { search, ...misc } = payload;
		body = {
			filter: [
				{
					column_name: "DisplayName",
					search_term: payload.search,
				},
			],
			...misc,
		};
	}

	const response = await httpClient.post("/friends/my_friends", body);

	if (response.data.status == "success") {
		return response.data;
	}
	throw response.data.message;
};

export { getMyFriends };
