import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TeamMetric } from "../types";

type TeamState = {
    allTeamMembers: TeamMetric[];
};

const initialState: TeamState = {
    allTeamMembers: [],
};

const teamMembersSlice = createSlice({
    name: "teamMembers",
    initialState,
    reducers: {
        setAllTeamMembers: (state, action: PayloadAction<TeamMetric[]>) => {
            state.allTeamMembers = action.payload;
        },
    },
});

export const { setAllTeamMembers } = teamMembersSlice.actions;

export default teamMembersSlice.reducer;
