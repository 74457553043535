import React, { useContext, useEffect, useState } from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    List,
    ListItem,
    Menu,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import notifications from "./Notifications.styles";
import { t_User } from "../../types/user.types";
import { useNavigate } from "react-router-dom";
import ChatBotSteps from "./ChatBot";
import WeeklyFeedback from "./WeeklyFeedback";
import { t_TeamMember } from "../../sections/TWT/TwtGroup.types";
import { useUserState } from "../../context/user/user.ctx";
import SectionCard from "../SectionCard/SectionCard";
import bot from "../../beYou_bot.png";
import CreateNotifications from "./CreateNotifications";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useClearNotificationsMutation, useGetSummaryMutation } from "../../redux/api/beYouCore";
import CoachTips from "../../sections/CoachTips/CoachTips";
import CoachTipsNotifications from "../../sections/CoachTips/CoachTipsNotifications";
import { NotificationsContext } from "../Toasts/NotificationsProvider.ctx";
import { useDispatch } from "react-redux";
import { setAllSummaries, setPagination } from "../../redux/slices/SummarySlice";
import { performance_summary } from "../../pages/Home/BeYouCoach/WalkThroughData";
import { Steps } from "intro.js-react";
import {
    setNotificationsWalkThrough,
    setPerformanceReflectionWalkThrough,
    setTipsWalkThrough,
} from "../../redux/slices/WalkThroughSlice";

interface NotificationsProps {
    currentTab?: any;
    entity_no: number;
    setSidePanelChatBot?: any;
}
const Notifications = ({ currentTab, entity_no, setSidePanelChatBot }: NotificationsProps) => {
    const [metricValues, setMetricValues] = useState<t_User.Metric[]>();
    const [metricFetch, setMetricFetch] = useState<string>();
    const [initialStep, setInitialStep] = React.useState(0);

    const [usersData, setUsersData] = useState<any>();

    const currentUser = useUserState();
    const [anchorEl, setAnchorEl] = useState(null);
    const stepsRef = React.useRef<any>(null);
    const classes = notifications();
    const navigate = useNavigate();
    const [showOverlay, setShowOverlay] = useState(Boolean);
    const [comparedMember, setComparedMember] = useState<any>();
    const [viewMetric, setViewMetric] = React.useState<t_User.Metric>();
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [reviewFeedbackOpen, setReviewFeedbackOpen] = useState(false);
    const [isCoachTips, setIsCoachTips] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState({
        id: 1,
        name: "All",
        value: "all",
    });
    const { setSideBarTab } = useContext(NotificationsContext);
    const notificationTypes = [
        { id: 1, name: "All", value: "all" },
        { id: 2, name: "Additional Volume", value: "additional_volume" },
        { id: 3, name: "Appeal", value: "appeal" },
        { id: 4, name: "Badges", value: "badges" },
        { id: 5, name: "Downtime", value: "downtime" },
        { id: 6, name: "Friend Request", value: "friend_requests" },
        { id: 7, name: "LSA", value: "lsa" },
        { id: 8, name: "Prompts", value: "prompts" },
        { id: 9, name: "Summaries", value: "summaries" },
    ];
    const team = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const metric_values = useSelector((state: RootState) => state.metrics.allmetrics);
    const {
        notificationsCount,
        setNotificationsCount,
        isSideTabWalkthroughFirstTab,
        setIsSideTabWalkthroughFirstTab,
        isSideTabWalkthroughSecondTab,
        setIsSideTabWalkthroughSecondTab,
        isSideTabWalkthroughThirdTab,
        setIsSideTabWalkthroughThirdTab,
    } = useContext(NotificationsContext);
    const pagination = useSelector((state: RootState) => state.summary.pagination);
    const sort = useSelector((state: RootState) => state.summary.sort);
    const dispatch = useDispatch();
    const [getSummary, { isLoading }] = useGetSummaryMutation();
    const performanceReflectionWalkThrough = useSelector(
        (state: RootState) => state.walkThrough.performanceReflectionWalkThrough,
    );

    const promptID = useSelector((state: RootState) => state.metrics.promptId);
    const [clearNotifications] = useClearNotificationsMutation();

    const removeNotification = (id: number) => {
        if (id) {
            clearNotifications({ notification_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: OnBoarding.tsx:164 ~ .then ~ error:", error);
                });
        }
    };

    useEffect(() => {
        team.map((member: any) => {
            setUsersData({
                user: member.user,
                metrics: member.metrics,
            });
        });

        return () => {};
    }, [team]);

    const getSelectedMemberSummaries = () => {
        const newPage = comparedMember ? null : { ...pagination, page: 1 };

        getSummary({ pagination: newPage, sort })
            .unwrap()
            .then((data) => {
                const summaries = comparedMember ? data.data.response : data.data.response.data;
                if (performanceReflectionWalkThrough) {
                    dispatch(setAllSummaries(performance_summary));
                } else {
                    dispatch(setAllSummaries(summaries));
                }
            })
            .catch((error) => {
                console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
            });
    };

    useEffect(() => {
        if (metric_values != null && metric_values?.length > 0) {
            const allMetrics = { id: 100, name: "Overall Ranking", value: 100 };
            const arr = [allMetrics];
            const metricValueArr = [...arr, ...metric_values];
            setMetricValues(currentTab === 1 ? metric_values : metricValueArr);
            setViewMetric(currentTab === 1 ? metric_values[0] : metricValueArr[0]);
            setMetricFetch(new Date(Date.now()).toString());
        }
    }, [currentTab]);

    useEffect(() => {
        getSelectedMemberSummaries();
    }, [comparedMember, performanceReflectionWalkThrough]);

    const switchSteps = (nextStepIndex: number, nextElement: any) => {
        switch (nextStepIndex) {
            case 0:
                dispatch(setPerformanceReflectionWalkThrough(true));
                break;
        }
    };

    const stepsFirstTab = [
        {
            element: ".notifications-area",
            title: "Notifications",
            intro: "This section contains all your notifications.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".filter-area",
            title: "Filter ",
            intro: "You can filter your notifications by selecting from the dropdown.",
            position: "right",
            disableInteraction: true,
        },
    ];
    const stepsSecondTab = [
        {
            element: ".performance-reflection-area",
            title: "Performance Reflection",
            intro: "Here you will see your weekly feedback you submitted, and the feedback from your team leader. Team leaders will see feedback for their entire team.",
            position: "right",
            disableInteraction: true,
        },
    ];
    const stepsThirdTab = [
        {
            element: ".coach-tips-area",
            title: "Coach Tips",
            intro: "Here you will find tips from your Coach, where you will see tips for metrics you aren't doing well in, and prompts to reach out to others for help, and also who you can help.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".coach-tips-view-all-area",
            title: "View all tips",
            intro: "By clicking this button you can view all of the coach tips.",
            position: "right",
            disableInteraction: true,
        },
    ];
    const introOptions = {
        showStepNumbers: true,
        showBullets: false,
        hideSkip: true,
        hidePrev: true,
        hideNext: false,
        doneLabel: "NEXT",
        keyboardNavigation: false,
        exitOnOverlayClick: false,
    };
    const onBeforeChange = (nextStepIndex: number) => {
        if (nextStepIndex === 0 || (nextStepIndex === 1 && stepsRef.current)) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    };
    return (
        <>
            <List className={`${classes.tabContent} notifications-area`}>
                {currentTab <= 0 && (
                    <>
                        <ListItem style={{ width: "100%" }}>
                            <Card style={{ width: "100%" }}>
                                <CardHeader
                                    title={
                                        <Box style={{ position: "relative" }}>
                                            <Typography variant="h6">What You've Missed</Typography>
                                        </Box>
                                    }
                                    avatar={<Avatar src={bot} className={classes.largeAvatar} />}
                                    action={
                                        notificationsCount.all > 0 ? (
                                            <Select
                                                // className={classes.insightsCard}
                                                key={selectedNotification.value}
                                                value={selectedNotification.id}
                                                variant="standard"
                                                className={`${classes.filter} filter-area`}
                                            >
                                                {notificationTypes?.map((notification) => (
                                                    <MenuItem
                                                        value={notification.id}
                                                        onClick={() => setSelectedNotification(notification)}
                                                        key={notification.id}
                                                    >
                                                        <Badge
                                                            badgeContent={
                                                                notificationsCount && notification.name
                                                                    ? notificationsCount[notification?.value]
                                                                    : 0
                                                            }
                                                            color="primary"
                                                            overlap="circular"
                                                        >
                                                            <Typography
                                                                style={{
                                                                    padding: ".4em",
                                                                }}
                                                            >
                                                                {notification.name}
                                                            </Typography>
                                                        </Badge>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        ) : null
                                    }
                                />

                                <CardContent style={{ overflow: "auto" }}>
                                    {Number(notificationsCount.all) <= 0 && (
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                margin: "auto",
                                                padding: 5,
                                            }}
                                        >
                                            <Typography color="textSecondary">No notifications currently</Typography>
                                        </Box>
                                    )}

                                    <CreateNotifications
                                        setFeedbackOpen={setFeedbackOpen}
                                        selectedNotification={selectedNotification}
                                        notificationsCount={notificationsCount}
                                        setNotificationsCount={setNotificationsCount}
                                        setSidePanelChatBot={setSidePanelChatBot}
                                        reviewFeedbackOpen={reviewFeedbackOpen}
                                        setReviewFeedbackOpen={setReviewFeedbackOpen}
                                    />
                                </CardContent>
                            </Card>
                        </ListItem>
                    </>
                )}

                {currentTab === 1 && (
                    <>
                        <ListItem
                            style={{
                                display: "flex",
                                flexFlow: "column",
                                alignSelf: "center",
                                width: "100%",
                            }}
                        >
                            <SectionCard
                                className={`${classes.fullWidth} performance-reflection-area`}
                                title="Performance Reflection"
                                fontSize={20}
                                secondaryAction={
                                    <>
                                        {currentUser?.profile?.permissions.some(
                                            (permissions) => permissions.slug == "team-manage",
                                        ) && (
                                            <>
                                                <Box display="flex" flexDirection="column">
                                                    <Button
                                                        className="compare-team-members"
                                                        aria-controls="compare-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event: any) => setAnchorEl(event.currentTarget)}
                                                        variant="contained"
                                                        style={{
                                                            boxShadow: "none",
                                                        }}
                                                        color="secondary"
                                                        size="small"
                                                    >
                                                        {comparedMember
                                                            ? `Selected ${comparedMember.user.name}`
                                                            : ` All`}
                                                    </Button>
                                                    {comparedMember && (
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ borderColor: "#333", marginTop: 1 }}
                                                            onClick={() => setComparedMember(null)}
                                                            size="small"
                                                        >
                                                            reset
                                                        </Button>
                                                    )}
                                                </Box>
                                                <Menu
                                                    id="compare-menu-weekly-feedback"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => setAnchorEl(null)}
                                                >
                                                    {team.map((member: any, index: any) => (
                                                        <MenuItem
                                                            style={{
                                                                paddingRight: 6,
                                                            }}
                                                            key={index}
                                                            onClick={(item: any) => {
                                                                setComparedMember(member);
                                                                setAnchorEl(null);
                                                                setUsersData({
                                                                    user: member.user,
                                                                    metrics: member.metrics,
                                                                });
                                                            }}
                                                        >
                                                            {member.user.name}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </>
                                        )}
                                    </>
                                }
                                hasBackground
                                scrolling
                                hasDivider={false}
                            >
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexFlow: "column",
                                        justifyContent: "space-evenly",
                                        alignItems: "flex-start",
                                        padding: 0,

                                        position: "relative",
                                        transition: "all 0.5s ease-in-out",
                                    }}
                                    className={classes.fullWidth}
                                >
                                    <WeeklyFeedback
                                        comparedMember={comparedMember}
                                        setReviewFeedbackOpen={setReviewFeedbackOpen}
                                        reviewFeedbackOpen={reviewFeedbackOpen}
                                        setSidePanelChatBot={setSidePanelChatBot}
                                    />
                                </CardContent>
                            </SectionCard>
                        </ListItem>
                    </>
                )}
                {currentTab === 2 && (
                    <>
                        <ListItem
                            style={{
                                display: "flex",
                                flexFlow: "column",
                                alignSelf: "center",
                                width: "100%",
                            }}
                            className="coach-tips-area"
                        >
                            <SectionCard
                                className={`${classes.fullWidth} `}
                                title="My Coach Tips"
                                fontSize={20}
                                secondaryAction={<></>}
                                hasBackground
                                scrolling
                                hasDivider={false}
                            >
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexFlow: "column",
                                        justifyContent: "space-evenly",
                                        alignItems: "flex-start",
                                        padding: 1,
                                    }}
                                >
                                    <CoachTipsNotifications />
                                </CardContent>
                            </SectionCard>
                            <Button
                                className={`${classes.button} coach-tips-view-all-area`}
                                onClick={() => {
                                    setIsCoachTips(true);
                                }}
                                style={{ marginBottom: 100 }}
                            >
                                View All Tips
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
            {showOverlay && <ChatBotSteps moveDirection="right" />}
            <Dialog open={isCoachTips} onClose={() => setIsCoachTips(false)} maxWidth={"md"} fullWidth>
                <CoachTips setIsCoachTips={setIsCoachTips} />
            </Dialog>
            <Steps
                enabled={isSideTabWalkthroughFirstTab}
                steps={stepsFirstTab}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsSideTabWalkthroughFirstTab(false);
                    dispatch(setNotificationsWalkThrough(false));
                    setIsSideTabWalkthroughSecondTab(true);
                    setSideBarTab(1);
                }}
                onExit={() => {
                    setIsSideTabWalkthroughFirstTab(false);
                    dispatch(setNotificationsWalkThrough(false));
                    removeNotification(promptID);
                }}
                onChange={(nextStepIndex, nextElement) => switchSteps(nextStepIndex, nextElement)}
            />
            <Steps
                enabled={isSideTabWalkthroughSecondTab}
                steps={stepsSecondTab}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsSideTabWalkthroughSecondTab(false);
                    setIsSideTabWalkthroughThirdTab(true);
                    setSideBarTab(2);
                }}
                onExit={() => {
                    setIsSideTabWalkthroughSecondTab(false);
                    dispatch(setPerformanceReflectionWalkThrough(false));
                    removeNotification(promptID);
                }}
            />
            <Steps
                enabled={isSideTabWalkthroughThirdTab}
                steps={stepsThirdTab}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsSideTabWalkthroughThirdTab(false);
                    setSidePanelChatBot(false);
                    navigate(`/performance/production?production_walkthrough=${true}&tab=${1}`);
                }}
                onExit={() => {
                    setIsSideTabWalkthroughThirdTab(false);
                    dispatch(setTipsWalkThrough(false));
                    removeNotification(promptID);
                }}
                onBeforeChange={onBeforeChange}
                ref={stepsRef}
            />
        </>
    );
};
export default Notifications;
