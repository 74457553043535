import { Grid } from "@mui/material";
import React from "react";
import BUMaterialTable from "../../../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../../../../components/SectionedDialog/SectionedDialog";
import { useMaterialTable } from "../../../../../../hooks/material-table/useMaterialTable";
import { formatApiDate } from "../../../../../../misc/helpers";
import AppealDialogContent from "../../../../../Appeals/dialogs/AppealDialogContent/AppealDialogContent";
import AppealButton from "../../../../../Surveys/components/AppealButton/AppealButton";
import { t_SurveyPeriod } from "../../../../../Surveys/Surveys.types";
import { getSurveyPeriods } from "../../../../PerceptionReport.api";
import SurveyPeriods from "../../../../tables/SurveyPeriods/SurveyPeriods";
import { getPendingAppeals } from "./PendingAppeals.api";
import { t_PendingAppeal } from "./PendingAppeals.types";

type Props = {
	month: string;
};
const PendingAppeals: React.FC<Props> = ({ month }) => {
	const { tableRef, dataSource, setDataSourceArgs } = useMaterialTable<
		t_PendingAppeal,
		{
			month: string;
		}
	>({
		data_source: getPendingAppeals,
	});
	const [periods, setPeriods] = React.useState<{
		[key: string]: t_SurveyPeriod;
	}>({});
	const [dialogState, setDialogState] = React.useState<{
		appeal?: t_PendingAppeal;
	}>();

	const handleDialogClose = (reason?: string) => {
		if (reason != null) {
			setDataSourceArgs({ month });
		}
		setDialogState(undefined);
	};

	React.useEffect(() => {
		if (month != null) {
			setDataSourceArgs({ month });
			getSurveyPeriods({
				for_month: formatApiDate(month),
				surveys: ["mbr", "fcr"],
			}).then((data) => {
				setPeriods(data);
			});
		}
	}, [month]);

	return (
		<SectionCard noHeader fullWidth>
			<Grid container spacing={2}>
				<Grid item sm={8}>
					<SurveyPeriods periods={periods} />
				</Grid>
				<Grid item sm={12}>
					<BUMaterialTable
						tableRef={tableRef}
						title='Pending Appeals'
						columns={[
							{
								title: "Line Manager",
								field: "LineManager",
							},
							{
								title: "Team Leader",
								field: "TeamLeader",
							},
							{
								title: "Agent",
								field: "DisplayName",
							},
							{
								title: "Service Team",
								field: "ServiceTeam",
							},
							{
								title: "Skill",
								field: "Skill",
							},
							{
								title: "Appeal Date",
								render: (rowData) =>
									formatApiDate(rowData.AppealDate),
							},
							{
								title: "Comment",
								field: "AppealComment",
							},
							{
								title: "Score",
								field: "SurveyScore",
							},
							{
								render: (rowData) => (
									<AppealButton
										status_id={rowData.ApprovalStatusID}
										status_label={rowData.Status}
										appeal_cutoff={
											rowData.record?.appeal_cutoff
										}
										onClick={() =>
											setDialogState({
												appeal: rowData,
											})
										}
									/>
								),
								title: "Action",
								field: "ApprovalStatusID",
							},
						]}
						options={{
							tableLayout: "fixed",
						}}
						data={dataSource}
					/>
				</Grid>
			</Grid>
			<SectionedDialog
				open={Boolean(dialogState?.appeal)}
				title='Appeal'
				maxWidth='md'
				// fullWidth
				onClose={() => setDialogState(undefined)}
			>
				{dialogState?.appeal != null && (
					<AppealDialogContent
						data_id={dialogState.appeal.AppealDataID}
						metric_id={dialogState.appeal.MetricID}
						appeal_id={dialogState.appeal.AppealID}
						appeal_date={dialogState.appeal.AppealDate}
						expire_date={dialogState.appeal.record?.appeal_cutoff}
						record={dialogState.appeal.record}
						dispatch={handleDialogClose}
					/>
				)}
			</SectionedDialog>
		</SectionCard>
	);
};

export default PendingAppeals;
