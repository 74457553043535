import { Box, Button, Dialog, Divider, TablePagination, TextField, Typography } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { useUserState } from "../../../../context/user/user.ctx";
import { getTeamMembers } from "../../../User/User.api";
import { t_UserBase } from "../../../User/User.types";
import ProductionByDate from "../../tables/ProductionByDate/ProductionByDate";
import ProductionByUser from "../../tables/ProductionByUser/ProductionByUser";
import { getApiUrl } from "../../../../misc/helpers";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import { useReportExport } from "../../../../hooks/export/useReportExport";
import { getUserPeriodsEntity } from "../../tables/ProductionByDate/ProductionByDate.api";
import ProductionSurveyPeriods, { t_ProductionPeriod } from "../../tables/ProductionByDate/ProductionSurveyPeriods";
import { styles } from "./LeaderProduction.styles";
import { defaultPeriod } from "./LeaderProduction.constants";
import { format, lastDayOfMonth } from "date-fns";
import { ContactSupportOutlined, SignalCellularConnectedNoInternet0BarOutlined } from "@mui/icons-material";
import OnBoarding from "../../../../components/OnBoarding/OnBoarding";
import { NotificationsContext } from "../../../../components/Toasts/NotificationsProvider.ctx";
import { Steps } from "intro.js-react";
import { setProductionWalkThrough } from "../../../../redux/slices/WalkThroughSlice";
import { useDispatch } from "react-redux";
import { useClearNotificationsMutation } from "../../../../redux/api/beYouCore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { team_members_production } from "../../../../pages/Home/BeYouCoach/WalkThroughData";
import OtherLeave from "../../forms/OtherLeave/OtherLeave";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import PoolList from "./PoolList";
interface Props {}

const LeaderProduction: React.FC<Props> = () => {
    const currentUser = useUserState();
    const { loading: exportLoading, downloadExportFile } = useReportExport();
    const [teamMembers, setTeamMembers] = React.useState<t_UserBase[]>([]);
    const [selectedMember, setSelectedMember] = React.useState<t_UserBase | null>(null);
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = React.useState<any>(new Date(Date.now()));
    const [period, setPeriod] = React.useState<t_ProductionPeriod>(defaultPeriod);
    const { state: locationState } = useLocation();
    const [isOnBoardingOpen, setIsOnBoardOpen] = useState<boolean>(false);
    const [clearNotifications] = useClearNotificationsMutation();
    const { isProductionActive, setIsDowntimeModalActive, setIsProductionActive, setIsAdditionalVolumeeModalActive } =
        useContext(NotificationsContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const productionWalkthroughParam = queryParams.get("production_walkthrough");
    const tabParam = queryParams.get("tab");
    const tabParamNum = tabParam ? parseInt(tabParam, 10) : 0;
    const [tabValue, setTabValue] = React.useState(tabParamNum || 1);
    const [maintainTabValue, setMaintainTabValue] = React.useState(0);
    const [poolTabValue, setPoolTabValue] = React.useState(0);
    const [prodWalkthrough, setProdWalkthrough] = React.useState(productionWalkthroughParam === "true");
    const productionWalkThrough = useSelector((state: RootState) => state.walkThrough.productionWalkThrough);
    const [initialStep, setInitialStep] = React.useState(0);
    const navigate = useNavigate();
    const stepsRef = React.useRef<any>(null);
    const promptID = useSelector((state: RootState) => state.metrics.promptId);
    const [openLeaveModal, setOpenLeaveModal] = useState(false);
    const tableRef = useRef<any>();

    const handleExport = (file_type: string) => {
        if (tabValue == 0) {
            const production_url = new URL(`${getApiUrl()}/production/export-team`);
            if (selectedDate != null) {
                production_url.searchParams.append("month", selectedDate.toDateString());
            }

            production_url.searchParams.append("file_type", file_type);

            downloadExportFile(production_url.toString(), "Production Export");

            return;
        }

        const production_url = new URL(`${getApiUrl()}/production/export-individual`);
        if (selectedMember?.entity_no != null) {
            production_url.searchParams.append("entity_no", selectedMember?.entity_no.toString());
        }

        production_url.searchParams.append("start_date", period.start_date);
        production_url.searchParams.append("end_date", period.end_date);
        production_url.searchParams.append("file_type", file_type);

        downloadExportFile(production_url.toString(), "Production Export");
    };

    React.useEffect(() => {
        let isActive = true;

        if (currentUser.profile?.entity_no) {
            if (productionWalkThrough) {
                setIsProductionActive(true);
                //@ts-ignore
                setTeamMembers(team_members_production);
            } else {
                getTeamMembers({ entity_no: currentUser.profile.entity_no }).then((data) => {
                    if (isActive) {
                        setTeamMembers(data);

                        if (locationState?.date != null) {
                            setSelectedDate(new Date(locationState.date));
                        }
                        if (locationState?.entity_no != null) {
                            const member = data.find((user) => user.entity_no == locationState.entity_no);

                            if (member != null) {
                                setSelectedMember(member);
                                setTabValue(1);
                            }
                        }
                    }
                });
            }
        }

        return () => {
            isActive = false;
        };
    }, [currentUser.profile?.entity_no, productionWalkThrough]);

    React.useEffect(() => {
        const now = new Date();
        const relevantDate = selectedDate ? selectedDate : now;
        const fomDate = new Date(relevantDate.getFullYear(), relevantDate.getMonth());
        const firstOfMonth = format(fomDate, "yyyy-MM-dd");
        let pgPeriod: t_ProductionPeriod = {
            id: 0,
            month: firstOfMonth,
            start_date: firstOfMonth,
            end_date: format(lastDayOfMonth(relevantDate), "yyyy-MM-dd"),
        };

        const monthEntry = selectedDate
            ? format(selectedDate, "yyyy-MM-dd")
            : format(new Date(now.getFullYear(), now.getMonth()), "yyyy-MM-dd");

        getUserPeriodsEntity({
            month: monthEntry,
            entity_no: selectedMember ? selectedMember.entity_no : 0,
        })
            .then((periods) => {
                if (periods) {
                    const currMonth = selectedDate ? selectedDate.getMonth() : now.getMonth();
                    const periodsMonths: number[] =
                        periods.length > 0 ? periods.map((p: t_ProductionPeriod) => new Date(p.month).getMonth()) : [];
                    const inPeriodsIdx = periodsMonths.findIndex((perNum) => perNum === currMonth);
                    if (inPeriodsIdx !== -1) {
                        pgPeriod = periods[inPeriodsIdx];
                    }
                    setPeriod(pgPeriod);
                }
            })
            .catch((err) => {
                setPeriod(pgPeriod);
            });
    }, [selectedMember?.entity_no, selectedDate]);

    React.useEffect(() => {
        if (queryParams.get("production_walkthrough")) {
            setIsProductionActive(true);
            dispatch(setProductionWalkThrough(true));
        } else {
            setIsProductionActive(false);
            dispatch(setProductionWalkThrough(false));
        }
    }, [location, productionWalkThrough]);

    const classes = styles();
    const handleAutocompleteChange = (event: React.ChangeEvent<{}>, value: t_UserBase | null) => {
        setSelectedMember(value);
    };

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [totalResults, setTotalResults] = useState(0);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // React.useEffect(() => {
    //     setIsOnBoardOpen(true);
    // }, [currentStepProduction, prodWalkthrough]);

    const steps = [
        {
            element: ".user-prod-area",
            title: "Production Capture",
            intro: "Here you can capture your teams production.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".select-team-member-area",
            title: "Team Member",
            intro: "Select a team member from the dropdown to get the data associated with that team member.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".select-date-area",
            title: "Select a date",
            intro: "Here you can switch to view and edit your teams production for different dates.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".export-table",
            title: "Export Table Data",
            intro: "You can export the table and its data into an excel file.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".prod-by-date-area",
            title: "User Production",
            intro: "The table will populate with the data for the given date, where you will be able to view, edit and submit production data.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-area",
            title: "Downtime Capture",
            intro: "By clicking here, you can capture a downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-capture-area",
            title: "Additional Volume Capture",
            intro: "By clicking here, you can capture an additional volume capture.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".downtime-capture-modal",
            title: "Downtime Capture Form",
            intro: "Lets take a look at capturing a downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-type",
            title: "Report Type",
            intro: "Select a downtime type to capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-minutes",
            title: "Minutes",
            intro: "Enter the amount of minutes you want to add to this downtime capture.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".downtime-capture-comment",
            title: "Comment",
            intro: "Enter a comment you want to add to this downtime capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-clear",
            title: "Clear",
            intro: "You can clear the current entries by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-add",
            title: "Add",
            intro: "Once you are ready, you can capture the report by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".downtime-capture-actions",
            title: "Actions",
            intro: "Once you have added a capture, you will see it appear here, this is where Team Leaders can verify captures and where captures can be edited.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-capture-modal",
            title: "Additional Volumne Capture Form",
            intro: "Lets take a look at capturing an additional volume report.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-initative",
            title: "Initiative Type",
            intro: "Select an initiative to capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-handing-time",
            title: "Handling Time",
            intro: "Enter a time(some initiative's time's are pre-configured with values).",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-weight",
            title: "Weight",
            intro: "Enter a weight(some initiative's weights are pre-configured with values).",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-actual-volume",
            title: "Actual Volume",
            intro: "Enter a value for the actual production volume.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-comment",
            title: "Comment",
            intro: "You can add a comment here about the capture.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-clear",
            title: "Clear",
            intro: "You can clear the current entries by clicking this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".additional-volume-add",
            title: "Add",
            intro: "Once you are ready, you can capture the report by clicking this button.",
            position: "right",
            disableInteraction: true,
        },

        {
            element: ".additional-volume-actions",
            title: "Actions",
            intro: "Once you have added a capture, you will see it appear here, this is where Team Leaders can verify captures and where captures can be edited.",
            position: "right",
            disableInteraction: true,
        },
    ];
    const introOptions = {
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true,
        doneLabel: "NEXT",
        keyboardNavigation: false,
        exitOnOverlayClick: false,
    };

    const removeNotification = (id: number) => {
        if (id) {
            clearNotifications({ notification_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: leaderProduction.tsx:164 ~ .then ~ error:", error);
                });
        }
    };
    const switchSteps = (nextStepIndex: number, nextElement: any) => {
        switch (nextStepIndex) {
            case 0:
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.delete("production_walkthrough");
                const newURL = `${window.location.pathname}?${queryParams.toString()}`;
                window.history.replaceState(null, "", newURL);
                break;
            case 1:
                const randomIndex = Math.floor(Math.random() * teamMembers.length);
                const randomTeamMember = teamMembers[randomIndex];
                if (randomTeamMember) {
                    setSelectedMember(randomTeamMember);
                }
                break;
            case 7:
                setIsDowntimeModalActive(true);
                break;
            case 14:
                setIsDowntimeModalActive(false);
                setIsAdditionalVolumeeModalActive(true);
                break;
            default:
        }
    };
    const onBeforeChange = (nextStepIndex: number) => {
        if (nextStepIndex >= 1 && nextStepIndex <= 22 && stepsRef.current) {
            stepsRef.current.updateStepElement(nextStepIndex);
        }
    };

    return (
        <>
            <SectionCard
                title="Production"
                primaryAction={
                    <>
                        <TabGroup
                            tabValue={tabValue}
                            tabList={[
                                { _id: 0, label: "Team Capture" },
                                { _id: 1, label: "Individual Capture" },
                                ...(currentUser.profile?.permissions.find((perm) => perm.id === 13)
                                    ? [{ _id: 2, label: "Maintain" }]
                                    : []),
                            ]}
                            tabChange={(newValue) => setTabValue(newValue)}
                            className="tab-captures"
                        />

                        {/* {tabValue === 1 && (
                            <ContactSupportOutlined
                                onClick={() => {
                                    setIsOnBoardOpen(true);
                                }}
                            />
                        )} */}
                    </>
                }
            >
                {tabValue === 0 && (
                    <SectionCard
                        title="Team Production"
                        titleProps={{ variant: "h6" }}
                        fullWidth
                        hasBackground
                        secondaryAction={
                            <DatePicker
                                views={["year", "month", "day"]}
                                openTo="day"
                                format="eee, dd MMM yyyy"
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                slotProps={{ textField: { variant: "standard" } }}
                            />
                        }
                    >
                        <ExportButton
                            dispatch={handleExport}
                            loading={exportLoading}
                            disabled={exportLoading}
                            className={`${classes.exportBtn}`}
                        >
                            Export
                        </ExportButton>
                        <ProductionByUser
                            entity_no={currentUser.profile?.entity_no}
                            capture_date={selectedDate?.toDateString() ?? new Date().toDateString()}
                        />
                    </SectionCard>
                )}
                {tabValue === 1 && (
                    <Box className={classes.userProdContainer}>
                        {selectedMember && period.incentive_categories && <ProductionSurveyPeriods period={period} />}

                        <SectionCard
                            title="User Production"
                            titleProps={{ variant: "h6" }}
                            fullWidth
                            hasBackground
                            className={`${classes.userProdSection} user-prod-area`}
                            primaryAction={
                                <Autocomplete
                                    style={{
                                        width: 300,
                                    }}
                                    value={selectedMember}
                                    options={teamMembers}
                                    // onChange={(_, value) => setSelectedMember(value)}
                                    onChange={handleAutocompleteChange}
                                    isOptionEqualToValue={(option, value) => option.entity_no == value?.entity_no}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            className="select-team-member-area"
                                            {...params}
                                            label="Select Team Member"
                                        />
                                    )}
                                />
                            }
                            secondaryAction={
                                <DatePicker
                                    openTo="month"
                                    views={["year", "month"]}
                                    value={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    slotProps={{ textField: { variant: "standard" } }}
                                    className="select-date-area"
                                />
                            }
                        >
                            <ExportButton
                                dispatch={handleExport}
                                loading={exportLoading}
                                disabled={exportLoading}
                                className={`${classes.exportBtn} export-table`}
                            >
                                Export
                            </ExportButton>
                            {period && (
                                <>
                                    <ProductionByDate
                                        styles={{
                                            maxHeight: "50vh",
                                            width: "100%",
                                        }}
                                        className="prod-by-date-area"
                                        entity_no={selectedMember?.entity_no}
                                        date={selectedDate}
                                        start_date={period.start_date}
                                        end_date={period.end_date}
                                    />
                                    <Dialog
                                        open={openLeaveModal}
                                        onClose={() => setOpenLeaveModal(false)}
                                        scroll={"body"}
                                    ></Dialog>
                                </>
                            )}
                        </SectionCard>
                    </Box>
                )}
                {tabValue === 2 && (
                    <SectionCard
                        title="Maintain Production"
                        titleProps={{ variant: "h6" }}
                        fullWidth
                        hasBackground
                        scrolling
                        primaryAction={
                            maintainTabValue == 1 && (
                                <Autocomplete
                                    style={{
                                        width: 300,
                                    }}
                                    value={selectedMember}
                                    options={teamMembers}
                                    // onChange={(_, value) => setSelectedMember(value)}
                                    onChange={handleAutocompleteChange}
                                    isOptionEqualToValue={(option, value) => option.entity_no == value?.entity_no}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            className="select-team-member-area"
                                            {...params}
                                            label="Select Team Member"
                                        />
                                    )}
                                />
                            )
                        }
                    >
                        <Box paddingLeft={2}>
                            <TabGroup
                                tabValue={maintainTabValue}
                                tabList={[
                                    { _id: 0, label: "Production Pools" },
                                    { _id: 1, label: "Leave" },
                                ]}
                                tabChange={(newValue) => setMaintainTabValue(newValue)}
                            />
                        </Box>
                        {maintainTabValue == 0 && (
                            <>
                                <Divider sx={{ mt: 2 }} />
                                <Box sx={{ p: 2 }}>
                                    <TabGroup
                                        tabValue={poolTabValue}
                                        tabList={[
                                            { _id: 0, label: "Available Pools" },
                                            { _id: 1, label: "Excluded Pools" },
                                        ]}
                                        tabChange={(newValue) => setPoolTabValue(newValue)}
                                    />
                                    {poolTabValue == 0 && (
                                        <PoolList
                                            poolType={"all"}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            setTotalResults={setTotalResults}
                                            date={selectedDate}
                                        />
                                    )}
                                    {poolTabValue == 1 && (
                                        <PoolList
                                            poolType={"excluded"}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            setTotalResults={setTotalResults}
                                        />
                                    )}
                                </Box>
                                {/* <TablePagination
                                    component="div"
                                    count={totalResults}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}
                            </>
                        )}
                        {maintainTabValue == 1 && (
                            <>
                                {selectedMember ? (
                                    <Box
                                        sx={{
                                            width: "50%",
                                        }}
                                    >
                                        <OtherLeave
                                            entity_no={selectedMember?.entity_no || 0}
                                            capture_date={new Date().toISOString()}
                                            onClose={() => {
                                                setOpenLeaveModal(false);
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Typography color="textSecondary">Please select a team member</Typography>
                                    </Box>
                                )}
                            </>
                        )}
                    </SectionCard>
                )}
            </SectionCard>
            <Steps
                onStart={() => dispatch(setProductionWalkThrough(true))}
                enabled={isProductionActive}
                steps={steps}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsProductionActive(false);
                    setIsDowntimeModalActive(false);
                    setIsAdditionalVolumeeModalActive(false);
                    navigate(`/admin/twt-teams?twt_walkthrough=${true}`);
                }}
                onBeforeExit={() => {
                    location.search = "";
                }}
                onExit={() => {
                    setIsProductionActive(false);
                    setIsDowntimeModalActive(false);
                    setIsAdditionalVolumeeModalActive(false);
                    dispatch(setProductionWalkThrough(false));
                    removeNotification(promptID);
                }}
                onChange={(nextStepIndex, nextElement) => switchSteps(nextStepIndex, nextElement)}
                onBeforeChange={onBeforeChange}
                ref={stepsRef}
            />
        </>
    );
};

export default LeaderProduction;
