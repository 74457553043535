import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Close, Delete, Edit, Publish } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { Formik, FormikProps } from "formik";
import React from "react";
import * as yup from "yup";
import ActiveButton from "../../../../components/ActiveButton/ActiveButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import {
    approveAdditionalVolume,
    deleteAdditionalVolume,
    getAdditionalVolumeRecords,
    getInitiativeTypes,
    saveAdditionalVolume,
} from "./AdditionalProduction.api";
import { additionalProductionFormStyles, additionalProductionStyles } from "./AdditionalProduction.style";
import {
    base_ProductionAdditionalVolume,
    t_InitiativeType,
    t_ProductionAdditionalVolume,
    t_ProductionAdditionalVolumeRecord,
} from "./AdditionalProduction.types";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

const additionalVolumeSchema: yup.SchemaOf<t_ProductionAdditionalVolume> = yup
    .object({
        id: yup.number(),
        initiative_type_id: yup.number().required("Please select an Initiative type."),
        volume: yup.number().positive().min(1).defined("Please enter the volume."),
        comment: yup.string().defined("Please enter a comment."),
    })
    .defined();

type t_InitiativeFormActions = "submitted" | "clear";

const AdditionalProductionForm: React.FC<{
    intitialValues: t_ProductionAdditionalVolume;
    additionalProductionDetails: {
        entity_no: number;
        capture_date: string;
    };
    formRef?: React.RefObject<FormikProps<t_ProductionAdditionalVolume>>;
    dispatch: (action: t_InitiativeFormActions) => void;
}> = ({ intitialValues, additionalProductionDetails, formRef, dispatch }) => {
    const classes = additionalProductionFormStyles();
    const [initiativeTypes, setInitiativeTypes] = React.useState<t_InitiativeType[]>();

    function additionalProductionSubmit(values: t_ProductionAdditionalVolume) {
        return new Promise((resolve, reject) => {
            saveAdditionalVolume({
                ...additionalProductionDetails,
                ...values,
            }).then(
                () => {
                    dispatch("submitted");
                    resolve(true);
                },
                (reason) => reject(reason),
            );
        });
    }

    React.useEffect(() => {
        var isActive = true;

        if (additionalProductionDetails.entity_no != null) {
            getInitiativeTypes({
                entity_no: additionalProductionDetails.entity_no,
            }).then((data) => {
                if (isActive) {
                    setInitiativeTypes(data);
                }
            });
        }
        return () => {
            isActive = false;
        };
    }, [additionalProductionDetails.entity_no]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={intitialValues}
            validationSchema={additionalVolumeSchema}
            onSubmit={additionalProductionSubmit}
        >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <List>
                        <ListItem>
                            <FormControl
                                className={classes.inputField}
                                required
                                error={touched.initiative_type_id && Boolean(errors.initiative_type_id)}
                            >
                                <InputLabel id="initiative_type_label">Initiative</InputLabel>
                                <Select
                                    className="additional-volume-initative"
                                    labelId="initiative_type_label"
                                    name="initiative_type_id"
                                    value={values.initiative_type_id || ""}
                                    onChange={handleChange}
                                    error={touched.initiative_type_id && Boolean(errors.initiative_type_id)}
                                >
                                    {initiativeTypes ? (
                                        initiativeTypes.map((initiative_type) => (
                                            <MenuItem
                                                key={`initiative-type-${initiative_type.id}`}
                                                value={initiative_type.id}
                                            >
                                                {initiative_type.name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>
                                            <Skeleton width="100%" />
                                        </MenuItem>
                                    )}
                                </Select>
                                <FormHelperText>
                                    {touched.initiative_type_id && errors.initiative_type_id}
                                </FormHelperText>
                            </FormControl>
                        </ListItem>
                        <ListItem className={classes.initiativeValues}>
                            <TextField
                                id="handling_time"
                                value={
                                    initiativeTypes?.find((item) => item.id === values.initiative_type_id)
                                        ?.handling_time ?? "-"
                                }
                                label="Handling Time"
                                className="additional-volume-handing-time"
                                disabled
                            />
                            <TextField
                                id="weight"
                                className="additional-volume-weight"
                                value={
                                    initiativeTypes?.find((item) => item.id === values.initiative_type_id)?.weight ??
                                    "-"
                                }
                                label="Weight"
                                disabled
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                id="actual_volume"
                                className={`${classes.inputField} additional-volume-actual-volume`}
                                name="volume"
                                type="number"
                                label="Actual Production Volume"
                                value={values.volume}
                                onChange={handleChange}
                                error={touched.volume && Boolean(errors.volume)}
                                helperText={touched.volume && errors.volume}
                                required
                            />
                        </ListItem>
                        <ListItem>
                            <FormControl className={classes.inputField}>
                                <FormHelperText>Comments*</FormHelperText>
                                <TextField
                                    id="additional_production_comments"
                                    className="additional-volume-comment"
                                    name="comment"
                                    variant="outlined"
                                    multiline
                                    rows={5}
                                    value={values.comment}
                                    onChange={handleChange}
                                    error={touched.comment && Boolean(errors.comment)}
                                    helperText={touched.comment && errors.comment}
                                    required
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem className={classes.actionListItem}>
                            <Button className="additional-volume-clear" onClick={() => dispatch("clear")}>
                                Clear
                            </Button>
                            <SubmitButton
                                className="additional-volume-add"
                                type="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            >
                                {values.id != null ? "Save" : "Add"}
                            </SubmitButton>
                        </ListItem>
                    </List>
                </form>
            )}
        </Formik>
    );
};

interface Props {
    entity_no: number;
    capture_date: string;
    onClose: (reason?: string) => void;
}

const AdditionalProduction: React.FC<Props> = ({ entity_no, capture_date, onClose }) => {
    const classes = additionalProductionStyles();
    const [editing, setEditing] = React.useState<number>();
    const formikRef = React.createRef<FormikProps<t_ProductionAdditionalVolume>>();
    const [existingAdditionalProduction, setAdditionalProduction] =
        React.useState<t_ProductionAdditionalVolumeRecord[]>();
    const [loading, setLoading] = React.useState(true);

    const permissions = usePermissions();

    const productionWalkThrough = useSelector((state: RootState) => state.walkThrough.productionWalkThrough);

    const handleFormEvent = (action: t_InitiativeFormActions) => {
        switch (action) {
            case "submitted":
                formikRef.current?.resetForm();
                setLoading(true);
                break;
            case "clear":
                formikRef.current?.resetForm();
                break;
        }
        setEditing(undefined);
    };

    const handleRowEvents = (
        action: "edit" | "copy" | "delete" | "approve" | "decline",
        row: t_ProductionAdditionalVolumeRecord,
    ) => {
        switch (action) {
            case "edit":
                formikRef.current?.setValues(row);
                setEditing(row.id);
                break;
            case "copy":
                formikRef.current?.setValues({ ...row, id: 0 });
                break;
            case "delete":
                deleteAdditionalVolume(row).then(() => setLoading(true));
                break;
            case "approve":
            case "decline":
                if (row.id != null) {
                    approveAdditionalVolume({
                        id: row.id,
                        approval: action === "approve",
                    }).finally(() => {
                        setLoading(true);
                    });
                } else {
                    setLoading(true);
                }
                break;

            default:
                break;
        }
    };

    React.useEffect(() => {
        var isActive = true;

        if (!!loading) {
            getAdditionalVolumeRecords({ entity_no, capture_date }).then((data) => {
                if (!!isActive) {
                    setAdditionalProduction(data);
                    setLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [loading]);

    // React.useEffect(() => {
    //     if (!productionWalkThrough) {
    //         onClose();
    //     }
    // }, [productionWalkThrough]);

    return (
        <SectionCard
            className="additional-volume-capture-modal"
            title="Additional Production Capture"
            titleProps={{ variant: "h6" }}
            secondaryAction={
                <IconButton onClick={() => onClose()} size="large">
                    <Close />
                </IconButton>
            }
        >
            <AdditionalProductionForm
                formRef={formikRef}
                additionalProductionDetails={{
                    entity_no,
                    capture_date,
                }}
                intitialValues={base_ProductionAdditionalVolume}
                dispatch={handleFormEvent}
            />
            <TableContainer className={classes.tableBody}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow className="additional-volume-actions">
                            <TableCell>Initiative</TableCell>
                            <TableCell align="center">AHT</TableCell>
                            <TableCell align="center">Volume</TableCell>
                            <TableCell align="center">Weighted Volume</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell align="center">Actions</TableCell>
                            {permissions.additional_volume_approve && <TableCell align="center">Verify</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && existingAdditionalProduction ? (
                            existingAdditionalProduction.map((row) => (
                                <TableRow
                                    key={`existing-additional-production-${row.id}`}
                                    className={clsx({
                                        [classes.greyRow]: editing != null && editing !== row.id,
                                    })}
                                >
                                    <TableCell>{row.initiative_type_name}</TableCell>
                                    <TableCell>{row.aht}</TableCell>
                                    <TableCell>{row.volume}</TableCell>
                                    <TableCell>{row.weighted_volume}</TableCell>
                                    <TableCell>{row.comment}</TableCell>
                                    <TableCell align="center">
                                        {editing != null && editing === row.id ? (
                                            <Typography variant="button" className={classes.editing}>
                                                Editing
                                            </Typography>
                                        ) : (
                                            <div className={classes.tableCellActions}>
                                                <IconButton
                                                    disabled={Boolean(editing) && editing !== row.id}
                                                    onClick={() => handleRowEvents("edit", row)}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    disabled={Boolean(editing) && editing !== row.id}
                                                    onClick={() => handleRowEvents("copy", row)}
                                                    size="large"
                                                >
                                                    <Publish />
                                                </IconButton>
                                                <IconButton
                                                    disabled={Boolean(editing) && editing !== row.id}
                                                    onClick={() => handleRowEvents("delete", row)}
                                                    size="large"
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        )}
                                    </TableCell>
                                    {permissions.additional_volume_approve && (
                                        <TableCell>
                                            <ActiveButton
                                                active={row.verified}
                                                onClick={() =>
                                                    handleRowEvents(!row.verified ? "approve" : "decline", row)
                                                }
                                                disabled={editing != null}
                                            >
                                                {row.verified ? "Verified" : "Verify"}
                                            </ActiveButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    {loading ? <CircularProgress /> : "No records found."}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </SectionCard>
    );
};

export default AdditionalProduction;
