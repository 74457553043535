import { Box, Button, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Column } from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_GroupData, t_PoolResponse } from "../../TwtGroup.types";
import { addPoolToGroup, deletePoolInGroup, getPoolsInGroup, saveGroupName } from "../../twtGroups.api";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { MiscBody } from "../../../../types/api.types";
import {
    getWeightedPoolsSearch,
    getWeightedRegionsSearch,
    getWeightedServiceEnviromentsSearch,
    saveWeightedPool,
} from "../../../Production/tables/PoolWeights/PoolWeights.api";
import { t_WeightedPool } from "../../../Production/tables/PoolWeights/PoolWeights.types";
import BUAutocomplete from "../../../../components/BUAutocomplete/BUAutocomplete";
import { httpClient } from "../../../../clients/http.client";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import muiStyles from "./EditPoolGroup.styles";

const EditCellSecondary = () => <div style={{ textAlign: "right", maxWidth: "2em", marginLeft: "1em" }}> </div>;

const defaultOption = {
    id: "",
    name: "",
};

type t_GroupSpecs = {
    service_team: string;
    stream: string;
    skill: string;
};

interface EditPoolGroupProps {
    handleClose: () => void;
    group: t_GroupData;
    tableRef: any;
}

const EditPoolGroup = ({ handleClose, group, tableRef }: EditPoolGroupProps) => {
    const [poolList, setPoolList] = React.useState<t_PoolResponse[]>([]);
    const [groupName, setGroupName] = React.useState(group.Name ?? "");
    const [activeGroup, setActiveGroup] = React.useState<any>(group);
    const [groupSpecs, setGroupSpecs] = React.useState<t_GroupSpecs>({
        service_team: "",
        stream: "",
        skill: "",
    });
    const [loadingName, setLoadingName] = React.useState(false);
    const [disableGroupEdit, setDisableGroupEdit] = React.useState(true);
    const [latestDate, setLatestDate] = React.useState<any>(new Date());
    const [activeOption, setActiveOption] = React.useState<{
        id: string;
        name: string;
    }>({
        id: "0",
        name: "",
    });
    const [activeService, setActiveService] = React.useState<{
        id: string;
        name: string;
    }>({
        id: activeGroup.id.toString(),
        name: activeGroup.service_team,
    });
    const [activeStream, setActiveStream] = React.useState<{
        id: string;
        name: string;
    }>({
        id: activeGroup.id.toString(),
        name: activeGroup.stream,
    });
    const [activePool, setActivePool] = React.useState<t_WeightedPool | undefined>();
    const [errorMsg, setErrorMsg] = React.useState("");

    const updateGroupSpecs = (groupName: string) => {
        const oneGroup = groupName.trim();

        if (!oneGroup.includes(" ST ") && !oneGroup.includes(" SERVICE ") && !oneGroup.includes(" - ")) {
            setGroupSpecs({
                service_team: "",
                stream: "",
                skill: "",
            });

            return;
        }

        const [h1, skill] = oneGroup.split(" - ");
        const svcSplitter = h1.includes("ST") ? " ST " : h1.includes("SERVICE TEAM") ? " SERVICE TEAM " : " SERVICE ";
        const [stName, stream] = h1.split(svcSplitter);
        const svcTeam = stName.includes("ST") ? stName.slice(0, -3) : stName;

        setGroupSpecs({ service_team: svcTeam, stream, skill });
    };

    const { enqueueSnackbar } = useSnackbar();

    const refreshPools = () =>
        getPoolsInGroup({ group_id: group.id })
            .then((pools) => {
                setPoolList(pools);
            })
            .catch((err) => console.error(err));

    const onDeletePool = async (poolId: number) => {
        deletePoolInGroup({ id: poolId.toString() })
            .then(() => refreshPools())
            .catch((err) => console.error(err));
    };

    const onUpdateGroupName = () => {
        setLoadingName(true);

        saveGroupName({
            id: group.id,
            name: activeGroup.Name,
            twt_region_id: activeGroup.twt_region_id,
            twt_service_environment_id: activeGroup.twt_service_environment_id,
        })
            .then(() => {
                enqueueSnackbar("Group updated successfully", {
                    className: muiStyle.snack,
                });
                setLoadingName(false);
            })
            .catch((err) => {
                enqueueSnackbar("Error updating group", {
                    variant: "error",
                });
                setLoadingName(false);
            });
    };

    const selectPools = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedPoolsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const poolOpts = resp.data.pools.map((pool: any) => ({
                        id: pool.id,
                        name: pool.name,
                    }));

                    res(
                        poolOpts.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        })),
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const handleSelectPool = (pool: any) => {
        setActivePool(pool ?? { name: "" });
    };

    const selectPool = (poolName: string) => {
        setErrorMsg("");
        httpClient
            .post("/admin/production/pools", {
                filter: [
                    {
                        column_name: "name",
                        search_term: poolName,
                    },
                ],
            })
            .then((resp) => {
                return addPoolToGroup({
                    pools: [resp.data.data.pools[0].entity_no],
                    group_id: group.id,
                });
            })
            .then((data) => {
                refreshPools();
            })
            .catch((err) => {
                typeof err === "string" && err.includes("This pool is already")
                    ? setErrorMsg((msg) => err.slice(0, -5) + "group")
                    : enqueueSnackbar("Error adding pool", {
                          variant: "error",
                      });
            });
    };

    const selectSE = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedServiceEnviromentsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const serviceEnviroments = resp.data.records.data.map((item: any) => ({
                        id: item.TwtServiceEnvironmentID,
                        name: item.Name,
                    }));

                    res(
                        serviceEnviroments.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        })),
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const selectRegion = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedRegionsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const serviceEnviroments = resp.data.records.data.map((item: any) => ({
                        id: item.TwtRegionID,
                        name: item.Name,
                    }));

                    res(
                        serviceEnviroments.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        })),
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const handleSelecRegion = (twt_region_id: any) => {
        setActiveGroup(
            twt_region_id
                ? {
                      ...activeGroup,
                      twt_region_id: twt_region_id.id,
                  }
                : {
                      ...activeGroup,
                      twt_region_id: "",
                  },
        );
        setActiveStream(twt_region_id);
    };
    const handleSelecServiceEnviroment = (twt_service_environment_id: any) => {
        setActiveGroup(
            twt_service_environment_id
                ? {
                      ...activeGroup,
                      twt_service_environment_id: twt_service_environment_id.id,
                  }
                : {
                      ...activeGroup,
                      twt_service_environment_id: "",
                  },
        );
        setActiveService(twt_service_environment_id);
    };

    const onDateUpdate = (date: any) => {
        const normalDate = date?.toISOString();
        setLatestDate(date);

        refreshPools();
    };

    const muiStyle = muiStyles();
    const theme = useTheme();

    const modalColumns: Column<any>[] = [
        {
            title: "Pool Entity",
            field: "PoolNumber",
            defaultSort: "asc",
            editable: "never",
        },
        {
            title: "Pool Name",
            field: "Name",
            editable: "never",
        },
        {
            title: "SLA",
            field: "ExpectedHandlingTime",
            render: (data) => data.ExpectedHandlingTime + " day(s)",
            cellStyle: {
                minWidth: "100px",
            },
        },
        {
            title: "Weight",
            field: "Weight",
            editable: "never",
        },
    ];

    // React.useEffect(() => {
    //     refreshPools();
    // }, []);

    React.useEffect(() => {
        updateGroupSpecs(groupName);
    }, [groupName]);

    return (
        <SectionCard
            title={`Edit group`}
            titleProps={{
                variant: "h6",
            }}
            className={muiStyle.modalContainer}
            fullWidth
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <Box width="clamp(30em, 50%, 40em)" margin="0 auto 2em">
                <Box display="grid" gridTemplateColumns="2fr 4fr 1fr">
                    <Typography>Name</Typography>
                    <TextField
                        value={activeGroup ? activeGroup.Name : ""}
                        onChange={(e) =>
                            setActiveGroup({
                                ...activeGroup,
                                Name: e.target.value,
                            })
                        }
                        error={activeGroup.Name.length === 0}
                        helperText={!activeGroup.Name.length ? "name is required" : ""}
                    ></TextField>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography>Service team</Typography>
                </Box>
                <div className={muiStyle.addPool}>
                    <div className={muiStyle.autoCompl}>
                        <BUAutocomplete
                            label="Select Service Team"
                            inputClassName={muiStyle.poolField}
                            dataSource={selectSE}
                            onChange={handleSelecServiceEnviroment}
                            value={activeService}
                        />
                    </div>
                </div>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography>Region</Typography>
                </Box>
                <div className={muiStyle.addPool}>
                    <div className={muiStyle.autoCompl}>
                        <BUAutocomplete
                            label="Select Region"
                            inputClassName={muiStyle.poolField}
                            dataSource={selectRegion}
                            onChange={handleSelecRegion}
                            value={activeStream}
                        />
                    </div>
                </div>
                <Box width="100%" textAlign="center" margin="1em 0.5em">
                    <SubmitButton
                        variant="outlined"
                        onClick={onUpdateGroupName}
                        loading={loadingName}
                        disabled={loadingName}
                    >
                        Save Group Details
                    </SubmitButton>
                </Box>
            </Box>

            <div className={muiStyle.poolPick}>
                <Typography align="center">Add Pool to Group</Typography>
                <div className={muiStyle.addPool}>
                    <div className={muiStyle.autoCompl}>
                        <BUAutocomplete
                            label="Select Pool"
                            inputClassName={muiStyle.poolField}
                            dataSource={selectPools}
                            value={defaultOption}
                            onChange={handleSelectPool}
                        />
                    </div>
                    <Button onClick={() => selectPool(activePool?.name || "")}>Add</Button>
                </div>

                {errorMsg && (
                    <Typography color="error" className={muiStyle.errorMssg}>
                        {errorMsg}
                    </Typography>
                )}
            </div>

            <div className={muiStyle.datePick}>
                <Typography align="center">Show pools from:</Typography>
                <DatePicker
                    slotProps={{
                        textField: {
                            variant: "standard",
                        },
                    }}
                    views={["year", "month", "day"]}
                    value={latestDate}
                    onChange={(date) => onDateUpdate(date)}
                />
            </div>

            <BUMaterialTable
                title={`Pools for: ${group.Name}`}
                columns={modalColumns}
                data={poolList}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((res, rej) => {
                            saveWeightedPool({
                                pool: {
                                    id: oldData.PoolID,
                                    entity_no: oldData.PoolNumber,
                                    name: oldData.Name,
                                    handling_time: +newData.ExpectedHandlingTime,
                                    weight: +newData.Weight,
                                },
                            })
                                .then(() => {
                                    refreshPools();
                                    res();
                                })
                                .catch(() => rej());
                        }),
                    onRowDelete: (oldData) => onDeletePool(oldData.ID),
                }}
                options={{
                    search: false,
                    sorting: false,
                    pageSize: 5,
                    pageSizeOptions: [5],
                    actionsColumnIndex: 4,
                    toolbar: false,
                    padding: "dense",
                }}
            />
        </SectionCard>
    );
};

export default EditPoolGroup;
