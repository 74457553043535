import React from "react";
import { Navigate, Route, Routes, useResolvedPath } from "react-router-dom";
import { usePermissions } from "../../../context/user/permissions.ctx";
import ProtectedRoute from "../../../routers/ProtectedRoute";
import MaintainAccess from "../../../sections/Access/components/MaintainAccess/MaintainAccess";
import MaintainAppeals from "../../../sections/Appeals/components/MaintainAppeals/MaintainAppeals";
import MaintainHelpFiles from "../../../sections/HelpFiles/sections/MaintainHelpFiles/MaintainHelpFiles";
import MaintainUser from "../../../sections/MaintainUser/MaintainUser";
import MaintainIncentive from "../../../sections/Metric/sections/MaintainIncentive/MaintainIncentive";
import ProductionPoolWeight from "../../../sections/Production/ProductionPoolWeight";
import MaintainHolidays from "../../../sections/Production/sections/MaintainHolidays/MaintainHolidays";
import MaintainProduction from "../../../sections/Production/sections/MaintainProduction/MaintainProduction";
import MaintainPeriods from "../../../sections/Surveys/sections/MaintainPeriods/MaintainPeriods";
import MaintainProdPeriods from "../../../sections/Surveys/sections/MaintainPeriods/MaintainProdPeriods";
import MaintainTWTgroups from "../../../sections/TWT/MaintainTWTgroups/index";
import MaintainTWTteams from "../../../sections/TWT/MaintainTWTteams";
import FileUpload from "../FileUpload/FileUpload";

const Admin: React.FC = () => {
    const path = useResolvedPath("").pathname;
    const permissions = usePermissions();

    return (
        <Routes>
            <Route element={<ProtectedRoute permissions={[permissions.admin_incentives]} />}>
                <Route path={`/incentive`} element={<MaintainIncentive />} />
                <Route path={`/periods`} element={<MaintainPeriods />} />
                <Route path={`/prod-periods`} element={<MaintainProdPeriods />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path={`/user`} element={<MaintainUser />} />
                <Route path={`/access`} element={<MaintainAccess />} />

                <Route path={`/twt-teams`} element={<MaintainTWTteams />} />
                <Route path={`/prod-pool`} element={<ProductionPoolWeight />} />
                <Route path={`/`} element={<Navigate to={`/user`} />} />
            </Route>

            <Route element={<ProtectedRoute permissions={[permissions.admin_appeal]} />}>
                <Route path={`/appeals`} element={<MaintainAppeals />} />
            </Route>

            <Route element={<ProtectedRoute permissions={[permissions.admin_production]} />}>
                <Route path={`/production`} element={<MaintainProduction />} />
                <Route path={`/twt`} element={<MaintainTWTgroups />} />
                <Route path={`/public_holidays`} element={<MaintainHolidays />} />
                <Route path={`/help_files`} element={<MaintainHelpFiles />} />
                <Route path={`/data-file-upload`} element={<FileUpload />} />
            </Route>
        </Routes>
    );
};

export default Admin;
