import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";

import SectionCard from "../../components/SectionCard/SectionCard";

const CoachingAuditForm = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                width: "80%",
                height: "70vh",
                margin: "auto",
                marginTop: 5,
            }}
        >
            <SectionCard
                hasBackground
                scrolling
                title="iQS Audit Rubric"
                subtitle="message to TL – please take note of the iQS Audit rubric , this should guide you on any coaching that takes place"
            >
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                >
                                    Topic
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                    align="center"
                                >
                                    Achieved
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell width="50%">Is the topic relevant to the coaching?</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                    align="right"
                                >
                                    Score
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                >
                                    Goal
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                    align="center"
                                >
                                    Achieved
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                >
                                    Is the goal SMART
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Specific</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Measurable</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Attainable</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Relevant?</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Timebound</TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                    align="right"
                                >
                                    Score
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                >
                                    Reality
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                    align="center"
                                >
                                    Achieved
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                >
                                    Business Reality
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">Is the current performance noted?</TableCell>
                                <TableCell width="20%">10%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">
                                    Is the business reality noted and how the current performance is impacting business?
                                </TableCell>
                                <TableCell width="20%">10%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                >
                                    Coachee Reality
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">
                                    Was the root cause analysis done to explore coachee's reality and possible reasons
                                    for current performance?
                                </TableCell>
                                <TableCell width="20%">10%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                    align="right"
                                >
                                    Score
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                >
                                    Options
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                    align="center"
                                >
                                    Achieved
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Does the options address the root cause of the problem as discussed in reality?
                                </TableCell>
                                <TableCell>10%</TableCell>
                                <TableCell align="center">No</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">
                                    Are the options SMART (Specific, Measureable, Attainable, Realistic, Timebound)?
                                </TableCell>
                                <TableCell width="20%">10%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">
                                    Are you able to track and hold the coachee accountable for these opetions in the
                                    follow up session?
                                </TableCell>
                                <TableCell width="20%">10%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                    align="right"
                                >
                                    Score
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={2}
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                >
                                    Wrap Up
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                    }}
                                    align="center"
                                >
                                    Achieved
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell width={"50%"}>
                                    Was there agreement on when the next follow up will be?
                                </TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%">
                                    Has the WWHWW (who,what,how,when,why) been stipulated?
                                </TableCell>
                                <TableCell width="20%">5%</TableCell>
                                <TableCell width="30%" align="center">
                                    Yes
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={3}
                                    sx={{ fontWeight: "bold", backgroundColor: theme.palette.background.default }}
                                    align="right"
                                >
                                    Score
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={3} align="right" sx={{ fontWeight: "bold" }}>
                                    Final Score
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </SectionCard>
        </Box>
    );
};

export default CoachingAuditForm;
