import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const challengeUserStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardHeader: {
			padding: theme.spacing(1.5),
		},
		rightCardHeader: {
			flexDirection: "row-reverse",
			textAlign: "right",
		},
		rightAvatar: {
			marginRight: 0,
			marginLeft: theme.spacing(2),
		},
		pointsSuccess: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,
		},
		pointsFail: {
			backgroundColor: theme.palette.warning.main,
			color: theme.palette.warning.contrastText,
		},
		pointsBadge: {
			color: theme.palette.info.contrastText,
			backgroundColor: theme.palette.info.main,
		},
		fadedCard: {
			color: theme.palette.text.disabled,
		},
	})
);

const challengeCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: "relative",
		},
		divider: {
			position: "absolute",
			left: "50%",
			top: 30,
			height: "calc(100% - 50px)",
		},
		chipSuccess: {
			borderColor: theme.palette.success.main,
			color: theme.palette.success.contrastText,
		},
		points: {
			whiteSpace: "nowrap",
		},
		resultContainer: {
			display: "flex",
			alignItems: "center",
			width: "100%",
			gap: theme.spacing(1),
			justifyContent: "space-between",
		},
		leftSideResult: {
			flexDirection: "row-reverse",
		},
		winnerText: {
			color: theme.palette.info.dark,
		},
		loserText: {
			color: theme.palette.text.disabled,
		},
	})
);

export { challengeUserStyles, challengeCardStyles };
