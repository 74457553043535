import { httpClient } from "../../../../../../clients/http.client";
import { t_ApiResponse } from "../../../../../../hooks/material-table/useMaterialTable";
import { t_PendingAppeal } from "./PendingAppeals.types";

const getPendingAppeals = (payload: { month: string }) => {
	return new Promise<t_ApiResponse<t_PendingAppeal>>((resolve) => {
		httpClient.post("/reports/appeal/pending", payload).then(({ data }) => {
			resolve(data);
		});
	});
};

export { getPendingAppeals };
