import { Avatar, Button, Card, CardContent, CardHeader, List, ListItem, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BadgeCard from "../../../../components/BadgeCard/BadgeCard";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { useUserMetrics } from "../../../../context/user/metrics.ctx";
import { useUserDispatch, useUserState } from "../../../../context/user/user.ctx";
import { getImageUrl } from "../../../../misc/helpers";
import { t_Challenge } from "../../../Challenge/Challenge.types";
import ChallengeCard from "../../../Challenge/components/ChallengeCard/ChallengeCard";
import MetricProgressList from "../../../Metric/sections/MetricProgressList/MetricProgressList";
import { t_UserBadge } from "../ProfileBadges/ProfileBadges.types";
import { getUserBadges, getUserChallengeHistory } from "./ProfileSummary.api";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileCard: {
            width: "100%",
        },
        challengeCard: {
            width: "100%",
        },
        badgeCard: {
            width: "100%",
        },
        largeAvatar: {
            height: theme.spacing(12),
            width: theme.spacing(12),
        },
        tabCard: {
            width: "100%",
        },
        tabContent: {
            height: "100%",
            width: "100%",
            overflow: "scroll",
        },
        logoutButton: {
            padding: theme.spacing(1, 2),
            marginLeft: "auto",
        },
    }),
);

const ProfileSummaryCategories = [
    {
        _id: "your_profile",
        label: "Profile",
    },
    {
        _id: "your_badges",
        label: "Badges",
    },
];
type Challenge = {
    _id: any;
    users: UserDetails[];
    winner_index: number;
    user_index: number;
    timestamp: number;
    active: number;
    health: number;
};
type Badge = {
    _id: string;
    badge_img: string;
    name: string;
    description: string;
};

type UserDetails = {
    name: string;
    job_title: string;
    tier: {
        colour: string;
        label: string;
    };
    profile_img: string;
    location: string;
    progress: number;
};
interface Props {
    dispatch?: (string: any) => void;
}
const ProfileSummary: React.FC<Props> = ({ dispatch = () => {} }) => {
    const classes = useStyles();

    const user = useUserState();
    const [userMetrics] = useUserMetrics();
    const userDispatch = useUserDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<number>(0);
    const [userChallenges, setUserChallenges] = useState<t_Challenge[]>();
    const [userBadges, setUserBadges] = useState<t_UserBadge[]>([]);

    const tabChange = (newValue: number) => {
        setTabValue(newValue);
    };

    const handleLogout = () => {
        userDispatch({ type: "LOGOUT" });
        navigate("/login");
        dispatch("close");
    };

    useEffect(() => {
        let isActive = true;
        if (user.profile) {
            switch (tabValue) {
                case 0:
                    getUserChallengeHistory().then((response) => {
                        if (isActive) {
                            switch (response.status) {
                                case "success":
                                    setUserChallenges(response.data.challenge_history);
                                    break;

                                default:
                                    setUserChallenges(undefined);
                                    break;
                            }
                        }
                    });
                    break;
                case 1:
                    getUserBadges().then((response) => {
                        if (isActive) {
                            switch (response.status) {
                                case "success":
                                    setUserBadges(response.data.badges);
                                    break;

                                default:
                                    break;
                            }
                        }
                    });
                    break;
                default:
                    break;
            }
        }

        return () => {
            isActive = false;
        };
    }, [tabValue, user.profile]);

    return (
        <SectionCard
            title="Your Profile"
            fullWidth
            primaryAction={<TabGroup tabValue={tabValue} tabChange={tabChange} tabList={ProfileSummaryCategories} />}
            bottomActions={
                <Button className={classes.logoutButton} onClick={handleLogout}>
                    Logout
                </Button>
            }
            className={classes.tabCard}
        >
            <List className={classes.tabContent}>
                {tabValue <= 0 && (
                    <>
                        <ListItem>
                            <Card className={classes.profileCard}>
                                <CardHeader
                                    title={<Typography variant="h5">{user.profile?.name}</Typography>}
                                    subheader={
                                        <React.Fragment>
                                            <Typography variant="subtitle2">{user.profile?.job_title}</Typography>
                                            <Typography variant="subtitle1">{user.profile?.tier.label}</Typography>
                                        </React.Fragment>
                                    }
                                    avatar={<Avatar src={user.profile?.profile_img} className={classes.largeAvatar} />}
                                />
                                <CardContent>
                                    <MetricProgressList metric_values={userMetrics.metrics} />
                                </CardContent>
                            </Card>
                        </ListItem>
                        <ListItem>
                            <List>
                                <ListItem>
                                    <Typography variant="subtitle1">Challenge History</Typography>
                                </ListItem>
                                {userChallenges?.map((challenge) => (
                                    <ListItem disableGutters key={challenge.challenge_id}>
                                        <ChallengeCard
                                            challenge={challenge}
                                            customClasses={{
                                                card: classes.challengeCard,
                                            }}
                                        />
                                    </ListItem>
                                ))}
                                {userChallenges && (
                                    <ListItem button component={Link} to="/challenge" onClick={() => dispatch("close")}>
                                        <Typography variant="button">View full challenge history</Typography>
                                    </ListItem>
                                )}
                            </List>
                        </ListItem>
                    </>
                )}
                {tabValue === 1 && (
                    <>
                        {userBadges.map((badge) => (
                            <ListItem key={badge.id} disableGutters>
                                <BadgeCard
                                    customClass={{
                                        box: classes.badgeCard,
                                    }}
                                    badge_img={badge.badge_img}
                                    title={badge.name}
                                    subtitle={badge.description}
                                />
                            </ListItem>
                        ))}
                        {userBadges && (
                            <ListItem button component={Link} to="/profile" onClick={() => dispatch("close")}>
                                <Typography variant="button">View all badges</Typography>
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </SectionCard>
    );
};

export default ProfileSummary;
