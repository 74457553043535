import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Box,
    Collapse,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Tooltip,
    useTheme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import navItemStyles from "./NavItem.styles";

interface NavItemProps {
    route?: string;
    icon?: React.ReactNode;
    label: string;
    open: boolean;
    children?: ReactNode;
    target?: boolean;
    setOpen?: (value: boolean) => void;
}

const NavItem: React.FC<NavItemProps> = ({ route, icon, label, open, children, target, setOpen }) => {
    const classes = navItemStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);

    const handleRouteClick = (event: any) => {
        if (!open && children) {
            event.preventDefault();
            setSubMenuOpen((state) => !state);
            setAnchorEl(event.currentTarget);
        }
        if (setOpen) {
            setOpen(false);
        }
    };
    const handleRouteOpen = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setSubMenuOpen((state) => !state);
    };
    return (
        <>
            <NavLink
                to={route ? route : ""}
                target={target ? "_blank" : ""}
                className={({ isActive, isPending }) => (isActive ? classes.listItemActive : classes.listItem)}
                onClick={handleRouteClick}
            >
                <Tooltip title={label} aria-label={label} placement="right" arrow>
                    <ListItemButton>
                        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
                        <ListItemText
                            primary={label}
                            className={open ? classes.listItemText : classes.listItemTextHidden}
                        />
                        {open && children && (
                            <IconButton onClick={handleRouteOpen} size="large">
                                {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        )}
                    </ListItemButton>
                </Tooltip>
            </NavLink>
            {children &&
                (open ? (
                    <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                        {children}
                    </Collapse>
                ) : (
                    <Menu
                        open={subMenuOpen}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setSubMenuOpen(false);
                            setAnchorEl(null);
                        }}
                    >
                        <Box
                            onClick={() => {
                                setSubMenuOpen(false);
                                setAnchorEl(null);
                            }}
                        >
                            {children}
                        </Box>
                    </Menu>
                ))}
        </>
    );
};

export { NavItem };
export type { NavItemProps };
