import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import LeaderBoard from "../../../sections/LeaderBoard/LeaderBoard";
import NewsFeed from "../../../sections/NewsFeed/NewsFeed";

const useStyles = makeStyles({
    dashboardContainer: {
        height: "100%",
    },
    dashboardItems: {
        height: "100%",
    },
});
const Dashboard = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.dashboardContainer}>
            <Grid item sm={6} className={classes.dashboardItems}>
                <LeaderBoard />
            </Grid>
            <Grid item sm={6} className={classes.dashboardItems}>
                <NewsFeed />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
