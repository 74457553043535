import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getLeaveData, getLeaveTypes, submitLeaveData, deleteLeave } from "./OtherLeave.api";
import { otherLeaveFormStyles, otherLeaveStyles } from "./OtherLeave.style";
import { base_ProductionLeave, t_LeaveType, t_ProductionLeave } from "./OtherLeave.types";
import moment from "moment";
import { Delete } from "@mui/icons-material";

const productionLeaveSchema: yup.SchemaOf<t_ProductionLeave> = yup
    .object({
        id: yup.number(),
        leave_type_id: yup.number().required("Please select a leave type."),
        start_date: yup.string().defined("Please select a Start date."),
        end_date: yup.string().defined("Please select an End date."),
        comment: yup.string().defined("Please enter a comment."),
        half_day: yup.boolean(),
    })
    .defined();
type t_LeaveFormActions = "submitted" | "cancel";
const OtherLeaveForm: React.FC<{
    initialValues: t_ProductionLeave;
    leaveDetails: {
        entity_no: number;
        capture_date: string;
    };
    dispatch: (action: t_LeaveFormActions) => void;
    onDelete: () => void;
    leaveID: number;
}> = ({ initialValues, leaveDetails, dispatch, onDelete, leaveID }) => {
    const classes = otherLeaveFormStyles();
    const { values, errors, touched, isSubmitting, isValid, handleChange, handleSubmit, setFieldValue, resetForm } =
        useFormik({
            initialValues: initialValues,
            validationSchema: productionLeaveSchema,
            onSubmit: (leave_data: t_ProductionLeave) => {
                return new Promise((resolve, reject) => {
                    submitLeaveData({
                        ...leaveDetails,
                        ...leave_data,
                    })
                        .then((data) => {
                            if (data) {
                                dispatch("submitted");
                                resolve(data);
                            } else {
                                reject();
                            }
                        })
                        .catch(reject)
                        .finally(() => {
                            resetForm();
                        });
                });
            },
        });
    const [leaveTypes, setLeaveTypes] = React.useState<t_LeaveType[]>();

    React.useEffect(() => {
        var isActive = true;

        getLeaveTypes().then((data) => !!isActive && setLeaveTypes(data));
        return () => {
            isActive = false;
        };
    }, []);
    return (
        <form onSubmit={handleSubmit} noValidate>
            <List>
                <ListItem>
                    <FormControl
                        className={classes.inputField}
                        required
                        error={touched.leave_type_id && Boolean(errors.leave_type_id)}
                    >
                        <InputLabel id="leave_type_label">Leave</InputLabel>
                        <Select
                            labelId="leave_type_label"
                            name="leave_type_id"
                            value={values.leave_type_id || ""}
                            onChange={handleChange}
                            error={touched.leave_type_id && Boolean(errors.leave_type_id)}
                        >
                            {leaveTypes ? (
                                leaveTypes.map((leave_type) => (
                                    <MenuItem key={`leave-type-${leave_type.id}`} value={leave_type.id}>
                                        {leave_type.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>
                                    <Skeleton width="100%" />
                                </MenuItem>
                            )}
                        </Select>
                        <FormHelperText>{touched.leave_type_id && errors.leave_type_id}</FormHelperText>
                    </FormControl>
                </ListItem>
                <ListItem className={classes.dateListItem}>
                    <DatePicker
                        // variant="inline"
                        label="Start Date"
                        // views={["month", "date"]}
                        format="d/M/y"
                        // openTo="date"
                        // autoOk
                        value={new Date(values.start_date)}
                        onChange={(date) => setFieldValue("start_date", moment(date).format("YYYY-MM-DD"))}
                        // error={touched.start_date && Boolean(errors.start_date)}
                        // helperText={touched.start_date && errors.start_date}
                        // required
                    />
                    <DatePicker
                        // variant="inline"
                        label="End Date"
                        // views={["month", "date"]}
                        format="d/M/y"
                        // openTo="date"
                        // autoOk
                        value={new Date(values.end_date)}
                        onChange={(date) => setFieldValue("end_date", moment(date).format("YYYY-MM-DD"))}
                        // error={touched.end_date && Boolean(errors.end_date)}
                        // helperText={touched.end_date && errors.end_date}
                        // required
                    />
                </ListItem>
                <ListItem>
                    <FormControl disabled={values.start_date !== values.end_date}>
                        <FormHelperText>Duration</FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                className={classes.inputField}
                                control={<Checkbox checked={values.half_day} onChange={handleChange} name="half_day" />}
                                label="Half Day"
                            />
                            {values.start_date !== values.end_date && (
                                <FormHelperText>Select a single date to set as half day.</FormHelperText>
                            )}
                        </FormGroup>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <FormControl className={classes.inputField}>
                        <FormHelperText>Comments*</FormHelperText>
                        <TextField
                            id="leave_comments"
                            variant="outlined"
                            name="comment"
                            multiline
                            rows={5}
                            value={values.comment}
                            onChange={handleChange}
                            error={touched.comment && Boolean(errors.comment)}
                            helperText={touched.comment && errors.comment}
                            required
                        />
                    </FormControl>
                </ListItem>
                <ListItem className={classes.actionListItem}>
                    <Button onClick={() => dispatch("cancel")}>Cancel</Button>

                    {leaveID > 0 && (
                        <IconButton onClick={onDelete}>
                            <Delete />
                        </IconButton>
                    )}

                    <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting}>
                        Save
                    </SubmitButton>
                </ListItem>
            </List>
        </form>
    );
};

interface Props {
    entity_no: number;
    capture_date: string;
    onClose: (reason?: string) => void;
}

const OtherLeave: React.FC<Props> = ({ entity_no, capture_date, onClose }) => {
    const classes = otherLeaveStyles();
    const [formData, setFormData] = React.useState<t_ProductionLeave>();

    const handleFormEvent = (action: t_LeaveFormActions) => {
        switch (action) {
            case "submitted":
                onClose("data_submitted");
                break;
            case "cancel":
                onClose();
                break;
        }
    };

    const deleteLeaveData = async () => {
        const leaveID = formData?.id;

        try {
            if (leaveID) {
                const resp = await deleteLeave({ id: leaveID });

                if (resp) {
                    onClose();
                } else {
                    throw Error("Error deleting leave");
                }
            } else {
                throw Error("Error deleting leave");
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        var isActive = true;
        if (entity_no && capture_date) {
            getLeaveData({ entity_no, capture_date }).then((data) => {
                if (isActive) {
                    if (data != null) {
                        setFormData(data);
                    } else {
                        setFormData({
                            ...base_ProductionLeave,
                            start_date: capture_date,
                            end_date: capture_date,
                        });
                    }
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [entity_no, capture_date]);
    return (
        <SectionCard title="Other Leave Capture" titleProps={{ variant: "h6" }}>
            {formData != null ? (
                <OtherLeaveForm
                    initialValues={formData}
                    leaveDetails={{
                        entity_no,
                        capture_date,
                    }}
                    dispatch={handleFormEvent}
                    onDelete={deleteLeaveData}
                    leaveID={formData?.id ?? 0}
                />
            ) : (
                <List>
                    <ListItem>
                        <Skeleton width={350} />
                    </ListItem>
                    <ListItem className={classes.skeletonDateListItem}>
                        <Skeleton width={171} />
                        <Skeleton width={171} />
                    </ListItem>
                    <ListItem>
                        <Skeleton width={15} />
                    </ListItem>
                    <ListItem>
                        <Skeleton width={350} height={200} />
                    </ListItem>
                </List>
            )}
        </SectionCard>
    );
};

export default OtherLeave;
