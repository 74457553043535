import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const appealDecisionStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			minWidth: 300,
		},
		teamLeaderActions: {
			width: "100%",
			display: "flex",
			justifyContent: "space-evenly",
		},
	})
);

export default appealDecisionStyles;
