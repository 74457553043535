import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Backdrop, Box, Button, Menu, MenuItem, Tab, Tabs, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

import SectionCard from "../../components/SectionCard/SectionCard";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import OvationAvatar from "./OvationAvatar";
import OvationLandingPage from "./OvationLandingPage";
import OvationReport from "./OvationReport";

const Ovation = () => {
    const [tabValue, setTabValue] = useState<number>(0);
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(month));
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [reportOption, setReportOption] = useState<"FCR" | "MBR" | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setTabValue(0);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (!reportOption) {
            setTabValue(1);
        }
    };

    const handleReportSelect = (report: "FCR" | "MBR") => {
        setReportOption(report);
        setTabValue(0);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
        setTabValue(newValue);
        if (newValue === 0) {
            handleClose();
            setReportOption(null);
        }
    };

    useEffect(() => {
        if (tabValue === 1) {
            setReportOption(null);
        }
    }, [tabValue]);

    useEffect(() => {
        if (reportOption) {
            handleClose();
        }
    }, [reportOption]);

    const reportOptions = [
        {
            id: 1,
            name: "MBR",
        },
        {
            id: 2,
            name: "FCR",
        },
    ];

    return (
        <SectionCard
            title="Ovation"
            scrolling
            fullWidth
            primaryAction={
                <Tabs value={tabValue} onChange={handleTabChange} style={{ padding: 16 }}>
                    <Box sx={{ mr: 2 }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? "demo-customized-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            variant={reportOption ? "contained" : "outlined"}
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownOutlined />}
                            sx={{
                                backgroundColor: tabValue === 0 ? theme.palette.info.main : undefined,
                                color: tabValue === 0 ? theme.palette.info.contrastText : undefined,
                                p: 1,
                            }}
                        >
                            {reportOption ? reportOption : "Report"}
                        </Button>
                        <Menu
                            elevation={0}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            {reportOptions.map((report: any, index: number) => (
                                <MenuItem onClick={() => handleReportSelect(report.name)} key={`${report.id}-${index}`}>
                                    {report.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Tab label="Avatar" />
                </Tabs>
            }
            secondaryAction={
                <>
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={new Date(month)}
                        onChange={handleDateChange}
                        closeOnSelect={false}
                        className="date-picker-beYouCoach"
                        onOpen={() => setIsDateOpen(true)}
                        onClose={() => {
                            performanceDispatch({
                                type: "update_dates",
                                month: selectedDate,
                            });
                            setIsDateOpen(false);
                        }}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                    <Backdrop sx={{ zIndex: 2 }} open={isDateOpen}></Backdrop>
                </>
            }
        >
            {tabValue == 0 && reportOption && <OvationReport reportOption={reportOption} />}
            {tabValue == 0 && !reportOption && <OvationLandingPage />}
            {tabValue == 1 && <OvationAvatar />}
        </SectionCard>
    );
};

export default Ovation;
