import { Box, Dialog, Grid, Skeleton } from "@mui/material";

import SectionCard from "../../../components/SectionCard/SectionCard";

const BeYouLoading = () => {
    return (
        <>
            <Grid>
                <SectionCard
                    title="BeYou Coach"
                    fullWidth
                    primaryAction={<Skeleton width={300} height={60} />}
                    secondaryAction={<Skeleton width={300} height={60} />}
                ></SectionCard>
                <Grid container spacing={2} style={{ padding: 16 }}>
                    <Grid item xs={12} sm={12} lg={8} md={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SectionCard hasBackground>
                                    <Box display="flex" width="100%">
                                        <Box width="100%">
                                            <Box display="flex" width="100%">
                                                <Skeleton
                                                    variant="circular"
                                                    width={80}
                                                    height={80}
                                                    style={{ marginRight: 5 }}
                                                />
                                                <Skeleton variant="text" width={100} height={20} />
                                            </Box>
                                            <Skeleton variant="text" width={100} />
                                            <Skeleton variant="rectangular" height={40} />
                                        </Box>
                                    </Box>
                                </SectionCard>
                            </Grid>
                            <Grid item xs={6}>
                                <SectionCard hasBackground title="News Feed" fontSize={20}>
                                    <Skeleton height={60} />
                                    <Skeleton height={60} />
                                </SectionCard>
                            </Grid>
                            <Grid item xs={6}>
                                <SectionCard title="Badges" hasBackground fontSize={20}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        alignItems={"flex-start"}
                                        alignContent={"center"}
                                        height={"100%"}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            width="100%"
                                        >
                                            <Skeleton variant="circular" width={60} height={60} />
                                            <Skeleton variant="circular" width={60} height={60} />
                                            <Skeleton variant="circular" width={60} height={60} />
                                            <Skeleton variant="circular" width={60} height={60} />
                                            <Skeleton variant="circular" width={60} height={60} />
                                        </Box>
                                    </Box>
                                </SectionCard>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <SectionCard hasBackground title="Strength" fontSize={20}>
                                            <Skeleton height={60} />
                                            <Skeleton height={60} />
                                        </SectionCard>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SectionCard hasBackground title="Focus Areas" fontSize={20}>
                                            <Skeleton height={60} />
                                            <Skeleton height={60} />
                                        </SectionCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} md={12} lg={4}>
                        <SectionCard
                            title="Metric Ranking"
                            fontSize={20}
                            fullWidth
                            hasBackground
                            scrolling
                            hasDivider={false}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    width: "100%",
                                    position: "relative",
                                    padding: 2,
                                }}
                            >
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                                <Skeleton width={350} height={50} />
                            </div>
                        </SectionCard>
                    </Grid>
                    <Dialog open={false}>
                        <Skeleton />
                    </Dialog>

                    <Grid item xs={12}>
                        <SectionCard title="Detailed Break Down" hasBackground fontSize={20}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                                <Skeleton variant="circular" width={80} height={80} />
                            </Box>
                        </SectionCard>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default BeYouLoading;
