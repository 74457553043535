import { Avatar, Badge, Card, CardActions, CardHeader, CardProps, Divider, Grid, Skeleton, Typography } from "@mui/material";
import clsx from "clsx";
import React, { ReactNode } from "react";

import { getImageUrl } from "../../../../misc/helpers";
import { t_Challenge, t_ChallengeCardUser } from "../../Challenge.types";
import { challengeCardStyles, challengeUserStyles } from "./ChallengeCard.styles";

const ChallengeUser: React.FC<{
    user?: t_ChallengeCardUser;
    active?: boolean;
    winningUser?: boolean;
    rightSide?: boolean;
}> = ({ user, active = false, rightSide = false, winningUser }) => {
    const classes = challengeUserStyles();

    return user ? (
        <CardHeader
            className={clsx(classes.cardHeader, {
                [classes.rightCardHeader]: rightSide,
                [classes.fadedCard]: winningUser === false,
            })}
            classes={{
                avatar: clsx({ [classes.rightAvatar]: rightSide }),
            }}
            avatar={
                <Badge
                    badgeContent={user.rank != null ? `#${user.rank}` : 0}
                    classes={{
                        badge: classes.pointsBadge,
                    }}
                    anchorOrigin={{
                        horizontal: rightSide ? "left" : "right",
                        vertical: "top",
                    }}
                >
                    <Avatar src={getImageUrl("profile", user.profile_img)} />
                </Badge>
            }
            title={
                <React.Fragment>
                    <Badge>
                        <Typography variant="body1">{user.display_name}</Typography>
                    </Badge>

                    <Typography variant="body2">{user.region}</Typography>
                    {user.score != null && <Typography variant="caption">{user.score} pts</Typography>}
                </React.Fragment>
            }
        />
    ) : (
        <CardHeader
            className={clsx(classes.cardHeader, {
                [classes.rightCardHeader]: rightSide,
            })}
            classes={{
                avatar: clsx({ [classes.rightAvatar]: rightSide }),
            }}
            avatar={
                <Avatar>
                    <Skeleton variant="circular" />
                </Avatar>
            }
            title={
                <React.Fragment>
                    <Typography variant="body1">
                        <Skeleton width="80%" />
                    </Typography>

                    <Typography variant="body2">
                        <Skeleton />
                    </Typography>
                    <Typography variant="caption">
                        <Skeleton width="30%" />
                    </Typography>
                </React.Fragment>
            }
        />
    );
};

interface Props {
    challenge: t_Challenge;
    onClick?: (challenge: t_Challenge) => void;
    customClasses?: {
        card?: string;
    };
    innerProps?: {
        card?: CardProps;
    };
    children?: ReactNode;
}

const ChallengeCard: React.FC<Props> = ({ challenge, children, customClasses, innerProps }) => {
    const classes = challengeCardStyles();
    return (
        <Card className={customClasses?.card} elevation={challenge.active ? 3 : 1} {...innerProps?.card}>
            <Grid container spacing={1} justifyContent="space-between" className={classes.wrapper}>
                <Divider orientation="vertical" light className={classes.divider} />
                <Grid item sm={12} container justifyContent="center">
                    {challenge.active ? (
                        <Typography variant="subtitle2">Active</Typography>
                    ) : (
                        <Typography variant="caption">{challenge.date}</Typography>
                    )}
                </Grid>
                <Grid item sm={12} container spacing={0}>
                    <Grid item xs={6}>
                        <ChallengeUser
                            user={challenge.user_one}
                            winningUser={challenge.victor != null ? challenge.victor === 1 : undefined}
                            active={challenge.active}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ChallengeUser
                            user={challenge.user_two}
                            rightSide
                            winningUser={challenge.victor != null ? challenge.victor === 2 : undefined}
                            active={challenge.active}
                        />
                    </Grid>
                </Grid>
                {!challenge.active && challenge.victor != null && (
                    <Grid item sm={12} container spacing={0}>
                        <Grid item xs={6}>
                            <CardActions>
                                <div className={clsx(classes.resultContainer, classes.leftSideResult)}>
                                    {challenge.victor == 1 && (
                                        <>
                                            <Typography variant="button" className={classes.winnerText}>
                                                Victory
                                            </Typography>
                                            {challenge.points_change != null && (
                                                <Typography variant="caption">
                                                    + {challenge.points_change} pts
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {challenge.victor == 2 && (
                                        <>
                                            <Typography variant="button" className={classes.loserText}>
                                                Defeat
                                            </Typography>
                                            {challenge.points_change != null && (
                                                <Typography variant="caption" className={classes.loserText}>
                                                    - {challenge.points_change} pts
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </div>
                            </CardActions>
                        </Grid>
                        <Grid item xs={6}>
                            <CardActions>
                                <div className={classes.resultContainer}>
                                    {challenge.victor == 2 && (
                                        <>
                                            <Typography variant="button" className={classes.winnerText}>
                                                Victory
                                            </Typography>
                                            {challenge.points_change != null && (
                                                <Typography variant="caption">
                                                    + {challenge.points_change} pts
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {challenge.victor == 1 && (
                                        <>
                                            <Typography variant="button" className={classes.loserText}>
                                                Defeat
                                            </Typography>
                                            {challenge.points_change != null && (
                                                <Typography variant="caption" className={classes.loserText}>
                                                    - {challenge.points_change} pts
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </div>
                            </CardActions>
                        </Grid>
                    </Grid>
                )}
                {children}
            </Grid>
        </Card>
    );
};

export default ChallengeCard;
