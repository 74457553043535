import {
    BarChart,
    ChatOutlined,
    ContactSupportOutlined,
    ExitToApp,
    FormatListBulleted,
    HomeOutlined,
    Link,
    MoreTime,
    ReceiptOutlined,
    Search,
    SettingsOutlined,
} from "@mui/icons-material";
import { Badge, Divider, List } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { usePermissions } from "../../context/user/permissions.ctx";
import { useUserState } from "../../context/user/user.ctx";
import { RootState } from "../../redux/store";
import { NavItem } from "../components/NavItem/NavItem";
import { ReactComponent as OvationIcon } from "./ovation.svg";

type Props = {
    isDrawerOpen: boolean;
    setOpen?: (value: boolean) => void;
};
const Links: React.FC<Props> = ({ isDrawerOpen: open, setOpen }) => {
    const user = useUserState();
    const permissions = usePermissions();
    const threadCount = useSelector((state: RootState) => state.thread.threadUpdate.data?.threads);

    return (
        <List>
            {user.profile == null ? (
                <NavItem open={open} label="Login" icon={<ExitToApp />} route="/login" />
            ) : (
                <React.Fragment>
                    <NavItem
                        open={open}
                        label="Dashboard"
                        icon={<HomeOutlined />}
                        route="/beyoucoach/dashboard"
                        setOpen={setOpen}
                    />
                    {/* <NavItem open={open} label="Dashboard" icon={<HomeOutlined />} route="/dashboard" /> */}
                    <NavItem
                        open={open}
                        label="Production"
                        icon={<MoreTime />}
                        route="/performance/production"
                        setOpen={setOpen}
                    />
                    <NavItem
                        open={open}
                        label="Leaderboards"
                        icon={<BarChart />}
                        route="/leaderboards"
                        setOpen={setOpen}
                    />
                    {/* <NavItem open={open} label="Performance" icon={<SpaceDashboardOutlined />} route="/performance">
                        <NavItem open={true} label="Dashboard" route="/performance/dashboard" />
                        <NavItem open={true} label="Production" route="/performance/production" />
                    </NavItem> */}
                    {/* <NavItem open={open} label="Profile" icon={<PermIdentityOutlined />} route="/profile" /> */}

                    {/* <NavItem open={open} label="Challenge" icon={<SportsEsportsOutlined />} route="/challenge" /> */}
                    <NavItem open={open} label="Search" icon={<Search />} route="/search" setOpen={setOpen} />

                    <Divider variant={open ? "fullWidth" : "middle"} />
                    {permissions.reports_view && (
                        <NavItem open={open} label="Reports" icon={<ReceiptOutlined />} route="/reports">
                            <NavItem open={true} label="Perception Reports" route="/reports/perception">
                                <NavItem open={true} label="MBR" route="/reports/perception/mbr" setOpen={setOpen} />
                                <NavItem open={true} label="FCR" route="/reports/perception/fcr" setOpen={setOpen} />
                                {/* <NavItem
                                    open={true}
                                    label="Appeals"
                                    route="/reports/perception/appeals"
                                    setOpen={setOpen}
                                /> */}
                                <NavItem open={true} label="LSA" route="/reports/perception/lsa" setOpen={setOpen} />
                            </NavItem>
                            <NavItem open={true} label="Usage Report" route="/reports/usage" setOpen={setOpen} />
                            <NavItem
                                open={true}
                                label="Production Report"
                                route="/reports/production"
                                setOpen={setOpen}
                            >
                                {/* <NavItem
                                open={true}
                                label='Additional Volume'
                                route='/reports/production/additional_volume'
                            /> */}
                            </NavItem>
                            {/* <NavItem open={true} label="TWT Report" route="/reports/twt-report" setOpen={setOpen} /> */}
                        </NavItem>
                    )}
                    {!permissions.admin_view && permissions.team_manage && (
                        <NavItem open={open} label="Admin Settings" icon={<SettingsOutlined />} route="/admin">
                            {/* <NavItem
                                open={true}
                                label="Maintain TWT Teams"
                                route="/admin/twt-teams"
                                setOpen={setOpen}
                            /> */}
                            <NavItem
                                open={true}
                                label="Maintain Production Pool"
                                route="/admin/prod-pool"
                                setOpen={setOpen}
                            />
                        </NavItem>
                    )}
                    {permissions.admin_view && (
                        <NavItem open={open} label="Admin Settings" icon={<SettingsOutlined />} route="/admin">
                            <NavItem open={true} label="Maintain User" route="/admin/user" setOpen={setOpen} />

                            {permissions.admin_incentives && (
                                <NavItem open={true} label="Admin Incentive" route="/admin/incentive">
                                    <NavItem
                                        open={true}
                                        label="Maintain Incentive"
                                        route="/admin/incentive"
                                        setOpen={setOpen}
                                    />
                                    <NavItem
                                        open={true}
                                        label="Maintain Survey Periods"
                                        route="/admin/periods"
                                        setOpen={setOpen}
                                    />
                                    <NavItem
                                        open={true}
                                        label="Maintain Production Periods"
                                        route="/admin/prod-periods"
                                        setOpen={setOpen}
                                    />
                                </NavItem>
                            )}
                            {/* {permissions.admin_appeal && (
                                <NavItem
                                    open={true}
                                    label="Maintain Appeals"
                                    route="/admin/appeals"
                                    setOpen={setOpen}
                                />
                            )} */}
                            {permissions.admin_production && (
                                <NavItem
                                    open={true}
                                    label="Maintain Production"
                                    route="/admin/production"
                                    setOpen={setOpen}
                                />
                            )}
                            {permissions.admin_production && (
                                <NavItem
                                    open={true}
                                    label="Maintain Production Pool"
                                    route="/admin/prod-pool"
                                    setOpen={setOpen}
                                />
                            )}

                            {/* {permissions.admin_production && (
                                <NavItem label="Maintain TWT" open={true} route={"/admin/twt"}>
                                    <NavItem
                                        open={true}
                                        label="Maintain TWT Groups"
                                        route="/admin/twt"
                                        setOpen={setOpen}
                                    />

                                    <NavItem
                                        open={true}
                                        label="Maintain TWT Service Environment"
                                        route="/admin/twt-service-environment"
                                        setOpen={setOpen}
                                    />

                                    <NavItem
                                        open={true}
                                        label="Maintain TWT Stream"
                                        route="/admin/twt-streams"
                                        setOpen={setOpen}
                                    />

                                    <NavItem
                                        open={true}
                                        label="Maintain TWT Teams"
                                        route="/admin/twt-teams"
                                        setOpen={setOpen}
                                    />

                                    <Divider />
                                </NavItem>
                            )} */}
                            {permissions.admin_access && (
                                <NavItem open={true} label="Maintain Access" route="/admin/access" setOpen={setOpen} />
                            )}
                            {permissions.admin_content && (
                                <NavItem
                                    open={true}
                                    label="Maintain Help Files"
                                    route="/admin/help_files"
                                    setOpen={setOpen}
                                />
                            )}
                            {permissions.admin_production && (
                                <NavItem
                                    open={true}
                                    label="Public Holidays"
                                    route="/admin/public_holidays"
                                    setOpen={setOpen}
                                />
                            )}

                            {permissions.admin_production && (
                                <NavItem
                                    open={true}
                                    label="Data File Upload"
                                    route="/admin/data-file-upload"
                                    setOpen={setOpen}
                                />
                            )}
                        </NavItem>
                    )}
                    <NavItem open={open} label="Links" icon={<Link />}>
                        <NavItem
                            open={true}
                            label="Smart People"
                            target={true}
                            route="https://performancemanager.successfactors.eu/sf/home?bplte_company=discoveryhP"
                            setOpen={setOpen}
                        />
                        <NavItem
                            open={true}
                            label="DNA"
                            target={true}
                            route="https://dna.discovery.co.za"
                            setOpen={setOpen}
                        />
                        <NavItem
                            open={true}
                            label="Exact"
                            target={true}
                            route="http://dhptslpbsql0001/Reports/powerbi/TSL%20Operations/DH%20-%20TSL%20-%20QMC%20-%20EXACT%20-%20TEAM%20LEADER%20POWERBI%20REPORT"
                            setOpen={setOpen}
                        />
                    </NavItem>
                    <NavItem open={open} label="Ovation" icon={<OvationIcon />} route="/ovation" setOpen={setOpen}>
                        <NavItem open={true} label="Ovation" route="/ovation/report" setOpen={setOpen} />
                        <NavItem
                            open={true}
                            label="Ovation Leaderboard"
                            route="/ovation/leaderboard"
                            setOpen={setOpen}
                        />
                    </NavItem>

                    <NavItem
                        open={open}
                        label="Coaching Forms"
                        icon={<FormatListBulleted />}
                        route="/coaching-forms"
                        setOpen={setOpen}
                    >
                        {permissions.team_manage && (
                            <NavItem
                                open={true}
                                label="Coaching Forms Templates"
                                route="/coaching-forms/templates"
                                setOpen={setOpen}
                            />
                        )}
                        <NavItem
                            open={true}
                            label="Submitted Coaching Forms"
                            route="/coaching-forms/submitted"
                            setOpen={setOpen}
                        />
                        <NavItem
                            open={true}
                            label="Team Development"
                            route="/coaching-forms/team-development"
                            setOpen={setOpen}
                        />
                    </NavItem>

                    <NavItem
                        open={open}
                        label="Inbox"
                        icon={
                            threadCount ? (
                                <Badge color="error" variant="dot">
                                    <ChatOutlined />
                                </Badge>
                            ) : (
                                <ChatOutlined />
                            )
                        }
                        route="/inbox"
                        setOpen={setOpen}
                    />
                </React.Fragment>
            )}

            <NavItem open={open} label="Help" icon={<ContactSupportOutlined />} route="/help" setOpen={setOpen} />
        </List>
    );
};

export default Links;
