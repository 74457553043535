import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_DowntimeOption } from "./DowntimeOptions.types";

const getDowntimeOptions = (payload: { query: Query<t_DowntimeOption> }) => {
	return new Promise<QueryResult<t_DowntimeOption>>((resolve, reject) => {
		const body = {
			...convertQuery(payload.query),
		};
		httpClient.post("/admin/production/downtime", body).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve({
						data: data.data.downtime_types,
						page: data.pagination.page - 1,
						totalCount: data.total_results,
					});
					break;

				default:
					break;
			}
		});
	});
};
const updateDowntimeOption = (payload: { option: t_DowntimeOption }) => {
	return new Promise((resolve, reject) => {
		const body = {
			downtime_type_id: payload.option.id,
			name: payload.option.name,
		};
		httpClient
			.post("/admin/production/downtime/update", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

const deleteDowntimeOption = (payload: { option: t_DowntimeOption }) => {
	return new Promise((resolve, reject) => {
		const body = {
			downtime_type_id: payload.option.id,
		};
		httpClient
			.post("/admin/production/downtime/delete", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;

					default:
						reject();
						break;
				}
			});
	});
};

export { getDowntimeOptions, updateDowntimeOption, deleteDowntimeOption };
