import React, { useEffect, useState } from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { CloudUpload, InsertDriveFile, Warning } from "@mui/icons-material";
import ProgressBar from "@ramonak/react-progress-bar";
import { useUploadDataFileMutation, useUploadSSMStatusQuery } from "../../../redux/api/beYouCore";

const FileUpload = () => {
    const [file, setFile] = useState<File | null>(null);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [error, setError] = useState<string>("");
    const [uploadError, setUploadError] = useState<string>("");
    const [uploadErrorOptions, setUploadErrorOptions] = useState<any>();
    const [uploadErrorSuggestion, setUploadErrorSuggestion] = useState<any>();
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadDataFile] = useUploadDataFileMutation();
    const {
        data: { data: ssmData } = {},
        refetch: refetchSSM,
        isLoading: ssmLoading,
        isError: ssmError,
    } = useUploadSSMStatusQuery();
    const [inputKey, setInputKey] = useState(0);

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const dataFiles = [
        {
            id: 1,
            value: "tmbr",
        },
        {
            id: 2,
            value: "ssm",
        },
    ];

    const handleFile = (selectedFile: File) => {
        const allowedTypes = [
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.oasis.opendocument.spreadsheet",
        ];

        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            setFile(selectedFile);
            setError("");
        } else {
            setFile(null);
            setError("Please select a valid spreadsheet file (CSV, XLS, XLSX, ODS).");
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];
        if (selectedFile) {
            handleFile(selectedFile);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleUpload = () => {
        // Simulating file upload progress
        if (file && selectedFile) {
            const formData = new FormData();
            formData.append("file", file);
            uploadDataFile({ file: formData, fileEndpoint: selectedFile })
                .then((response: any) => {
                    if (response.error) {
                        setUploadError(response?.error?.data?.message);
                        setUploadErrorOptions(response?.error?.data?.data);
                        return;
                    } else {
                        let progress = 0;
                        if (selectedFile.toLocaleLowerCase() != "ssm") {
                            const interval = setInterval(() => {
                                progress = progress + 10;
                                setUploadProgress(progress);
                                if (progress === 100) {
                                    clearInterval(interval);
                                }
                            }, 500);
                        } else {
                            handleClear();
                        }
                    }
                })
                .catch((error) => {
                    setUploadError(error.message);
                });
        }
    };

    const handleClear = () => {
        setUploadProgress(0);
        setFile(null);
        setSelectedFile(null);
        setError("");
        setUploadError("");
        setInputKey((prevKey) => prevKey + 1);
    };

    const bytesToMB = (bytes: number): string => {
        if (bytes < 1024 * 1024) {
            return `(${(bytes / 1024).toFixed(2)}KB)`;
        } else {
            return `(${(bytes / (1024 * 1024)).toFixed(2)}MB)`;
        }
    };

    useEffect(() => {
        refetchSSM();
    }, [file]);

    return (
        <SectionCard title={"Upload Files"}>
            <Grid container sx={{ dispaly: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
                <Grid item sm={12}>
                    <SectionCard noHeader hasBackground>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <div
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                    style={{
                                        border: "3px dotted #c6db34",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CloudUpload sx={{ color: "#007bff", fontSize: 100 }} />

                                    <>
                                        <Typography gutterBottom>Drag and drop file here</Typography>
                                        <Typography gutterBottom>or</Typography>
                                        <Box sx={{ marginBottom: 5, marginTop: 2 }}>
                                            <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                                                <span
                                                    style={{
                                                        backgroundColor: " #c6db34",
                                                        color: "white",
                                                        padding: "10px 20px",
                                                        borderRadius: "5px",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                        border: "none",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Browse Files
                                                </span>
                                                <input
                                                    key={inputKey}
                                                    id="file-input"
                                                    type="file"
                                                    accept=".csv, .xls, .xlsx, .ods"
                                                    onChange={handleChange}
                                                    style={{ display: "none" }}
                                                />
                                            </label>
                                        </Box>
                                    </>

                                    {error && <div style={{ color: "red" }}>{error}</div>}
                                </div>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="h6" gutterBottom sx={{ marginTop: 1 }}>
                                    Uploaded File
                                </Typography>

                                {uploadError?.length <= 0 ? (
                                    <Box sx={{ height: 160 }}>
                                        {file ? (
                                            <Box sx={{ padding: 2, border: "1px solid lightgray", marginBottom: 3 }}>
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <InsertDriveFile sx={{ color: "#30D843" }} />
                                                        <Typography variant="caption">{file.name}</Typography>
                                                        <Typography variant="caption">
                                                            {bytesToMB(Number(file.size))}
                                                        </Typography>
                                                    </Box>
                                                    {selectedFile?.toLocaleLowerCase() != "ssm" && (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <ProgressBar
                                                                completed={uploadProgress}
                                                                height="5px"
                                                                maxCompleted={100}
                                                                isLabelVisible={false}
                                                                bgColor="#007bff"
                                                            />
                                                            <Typography
                                                                variant="caption"
                                                                sx={{ textAlign: "right", alignSelf: "flex-end" }}
                                                            >
                                                                Upload Progress: {uploadProgress}%
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {selectedFile?.toLocaleLowerCase() == "ssm" && (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                // sx={{ textAlign: "right", alignSelf: "flex-end" }}
                                                            >
                                                                This file will take a while to upload, you will get a
                                                                notification once it is complete.
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    margin: "auto",
                                                    padding: 5,
                                                }}
                                            >
                                                <Typography color="textSecondary">No file selected</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            margin: "auto",
                                            padding: 5,
                                            height: 160,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: 1,
                                            }}
                                        >
                                            <Warning sx={{ color: "red", fontSize: 30 }} />
                                            <Typography sx={{ color: "red", marginLeft: 1 }} variant="h6">
                                                Upload Failed!
                                            </Typography>
                                        </Box>
                                        <Typography sx={{ color: "red" }} variant="body2">
                                            {uploadError}
                                        </Typography>
                                    </Box>
                                )}
                                {file && !selectedFile ? (
                                    <FormControl sx={{ minWidth: 200 }}>
                                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ paddingLeft: 1 }}>
                                            Choose file type
                                        </InputLabel>
                                        <Select
                                            style={{ marginLeft: 15 }}
                                            value={selectedFile}
                                            label="Choose file type"
                                            className="filter-metric-rankings"
                                            displayEmpty
                                            onChange={(e) => {
                                                setSelectedFile(e.target.value);
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Choose file type</em>
                                            </MenuItem>

                                            {dataFiles?.map((file: any) => (
                                                <MenuItem value={file?.value} key={file?.id}>
                                                    {file?.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    file &&
                                    uploadProgress !== 100 &&
                                    uploadError?.length <= 0 && (
                                        <Button variant="contained" onClick={handleUpload}>
                                            Upload
                                        </Button>
                                    )
                                )}

                                {file && (uploadProgress == 100 || uploadError?.length > 0) && (
                                    <Button variant="contained" onClick={handleClear}>
                                        Clear
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </SectionCard>
                </Grid>
            </Grid>
            {uploadError?.length > 0 && (
                <SectionCard noHeader hasBackground>
                    <Grid container>
                        <Grid item sx={{ marginTop: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Suggestions for fixing error
                            </Typography>
                            {uploadErrorOptions?.column_error && (
                                <Box>
                                    <Typography>
                                        The following column is invalid:
                                        <span style={{ color: "red" }}> {uploadErrorOptions?.column_error}</span>
                                    </Typography>
                                </Box>
                            )}
                            {uploadErrorOptions?.unexpected_column && (
                                <Box>
                                    <Typography>
                                        The following column is invalid:{" "}
                                        <span style={{ color: "red" }}>{uploadErrorOptions?.unexpected_column}</span>,
                                        these are the expected columns:
                                    </Typography>
                                    <ul>
                                        {uploadErrorOptions?.expected_columns?.map((column: string, index: number) => (
                                            <li key={index} style={{ color: "#007bff" }}>
                                                {column}
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </SectionCard>
            )}

            <Grid container>
                <Grid item sx={{ marginTop: 2 }}>
                    <SectionCard hasBackground>
                        <Typography variant="h5" gutterBottom>
                            SSM Upload Status
                        </Typography>
                        <Box>
                            <Typography gutterBottom variant="body1" sx={{ fontWeight: "bold" }}>
                                File Name:
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                {ssmData?.import?.file_name}
                            </Typography>
                            <Typography gutterBottom variant="body1" sx={{ fontWeight: "bold" }}>
                                Status: {ssmData?.import?.status == "started" && <CircularProgress size={20} />}
                            </Typography>
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    color:
                                        ssmData?.import?.status == "failure"
                                            ? "red"
                                            : ssmData?.import?.status == "success"
                                            ? "green"
                                            : "",
                                }}
                            >
                                {ssmData?.import?.status}
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                {ssmData?.import?.message}
                            </Typography>
                            <Typography gutterBottom variant="body1" sx={{ fontWeight: "bold" }}>
                                Started:
                            </Typography>
                            <Typography gutterBottom variant="body2">
                                {ssmData?.import?.created_at}
                            </Typography>
                        </Box>
                    </SectionCard>
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default FileUpload;
