import {
	Avatar,
	CardHeader,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
} from "@mui/material";
import React from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { getUserDetails } from "./UserDetails.api";
type DetailedUser = {
	entity_no: any;
	name: any;
	job_title: any;
	profile_img: any;
	region: any;
	job_level: any;
	team: any;
	incentive_category: any;
	skill: any;
	division: any;
	company: any;
	manager: any;
	service_team: any;
	agent_skill: any;
	department: any;
	discovery_team: any;
	cost_center: any;
	roles: any[];
};

const UserDetails: React.FC<{ entity_no?: number }> = ({ entity_no }) => {
	const [userDetails, setUserDetails] = React.useState<DetailedUser | null>(
		null
	);

	React.useEffect(() => {
		var isActive = true;

		if (entity_no != null) {
			getUserDetails({ entity_no }).then((data) => {
				if (isActive) {
					setUserDetails(data);
				}
			});
		}

		return () => {
			isActive = false;
		};
	}, [entity_no]);

	return (
		<SectionCard
			title={
				<CardHeader
					title={userDetails?.name}
					subheader={userDetails?.entity_no}
					avatar={<Avatar srcSet={userDetails?.profile_img} />}
				/>
			}
			scrolling
			hasBackground
		>
			<List>
				<ListItem>
					<ListItemText
						primary='Job Title'
						secondary={userDetails?.job_title ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Region'
						secondary={userDetails?.region ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Job Level'
						secondary={userDetails?.job_level ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Team'
						secondary={userDetails?.team ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Incentive Category'
						secondary={
							userDetails?.incentive_category ?? "Not defined."
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Skill'
						secondary={userDetails?.skill ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Division'
						secondary={userDetails?.division ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Company'
						secondary={userDetails?.company ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Manager'
						secondary={userDetails?.manager ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Service Team'
						secondary={userDetails?.service_team ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Agent Skill'
						secondary={userDetails?.agent_skill ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Department'
						secondary={userDetails?.department ?? "Not defined."}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Discovery Team'
						secondary={
							userDetails?.discovery_team ?? "Not defined."
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary='Cost Center'
						secondary={userDetails?.cost_center ?? "Not defined."}
					/>
				</ListItem>
			</List>
			<List>
				<ListSubheader>Roles</ListSubheader>
				{userDetails?.roles.map((role) => (
					<ListItem key={role}>{role}</ListItem>
				))}
			</List>
		</SectionCard>
	);
};

export default UserDetails;
