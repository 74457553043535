import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography, Box, Divider, Tab, Tabs } from "@mui/material";
import { t_UserBadge } from "../Profile/sections/ProfileBadges/ProfileBadges.types";
import BadgeList from "../../components/BadgeList/BadgeList";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import { useNavigate } from "react-router-dom";

type SingleBadgeProps = {
    badgeOnly?: boolean;
    badgeLimit?: boolean;
    entity_no?: number;
    rows_per_page?: number;
    withPagination?: boolean;
    onClick?: any;
    currentTab?: any;
};

const SingleBadge: React.FC<SingleBadgeProps> = ({
    badgeOnly,
    badgeLimit,
    entity_no,
    rows_per_page = 5,
    withPagination,
    onClick,
}) => {
    const [badgeTitles, setBadgeTitles] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [userAssignedBadges, setUserAssignedBadges] = useState<t_UserBadge[]>([]);
    const [systemAssignedBadges, setSystemAssignedBadges] = useState<t_UserBadge[]>([]);

    const beYouWalkThrough = useSelector((state: RootState) => state.walkThrough.beYouWalkThrough);
    const user = useSelector((state: RootState) => state.user.userInfo);
    const walkThroughBadges = useSelector((state: RootState) => state.badges.allBadges);
    const showBadges = beYouWalkThrough ? walkThroughBadges : user?.badge_collection;

    return (
        <Grid container>
            {loading ? (
                <div>
                    <CircularProgress />
                </div>
            ) : showBadges?.length ? (
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexFlow: !badgeOnly ? "column" : "row",
                        gap: "1rem",
                        padding: 16,
                    }}
                >
                    {!badgeOnly ? (
                        <React.Fragment>
                            <Tabs value={currentTab} onChange={(_, newValue) => setCurrentTab(newValue)}>
                                <Tab label="System Assigned Badges" />
                                <Tab label="User Assigned Badges" />
                            </Tabs>

                            {currentTab == 1 && userAssignedBadges.length > 0 && (
                                <>
                                    <BadgeList
                                        badges={userAssignedBadges
                                            .slice(0, badgeLimit ? 4 : undefined)
                                            .map((badge: any) => ({
                                                badge_id: badge.badge.id,
                                                badge_img: badge.badge_img,
                                                title: badge.name,
                                                subtitle: badge.description,
                                                count: badge.count,
                                                is_system: badge.is_system,
                                            }))}
                                        justify="center"
                                        style={{
                                            gap: "1.5rem",
                                            borderBottom: "1px solid lightgrey",
                                            paddingBottom: 20,
                                            textAlign: "center",
                                        }}
                                    />
                                </>
                            )}
                            {currentTab == 0 && systemAssignedBadges.length > 0 && (
                                <>
                                    <BadgeList
                                        badges={systemAssignedBadges
                                            .slice(0, badgeLimit ? 4 : undefined)
                                            .map((badge: any) => ({
                                                id: badge?.id,
                                                badge_img: badge.badge_img,
                                                title: badge.name,
                                                subtitle: "",
                                                count: badge.count,
                                                is_system: badge.is_system,
                                            }))}
                                        justify="center"
                                        style={{ gap: "1.5rem", textAlign: "center" }}
                                    />
                                </>
                            )}
                        </React.Fragment>
                    ) : (
                        <BadgeList
                            badges={showBadges?.slice(0, badgeLimit ? 4 : undefined).map((badge: any) => ({
                                badge_img: badge.badge_img,
                                title: "",
                                subtitle: "",
                                count: badge.count,
                                is_system: badge.is_system,
                                id: badge.id,
                            }))}
                            justify="center"
                            style={{ gap: "1.5rem", textAlign: "center" }}
                        />
                    )}
                    <Typography
                        style={{
                            fontSize: 15,
                            width: "15%",
                            color: "lightgrey",
                            fontWeight: 700,
                            whiteSpace: "nowrap",
                            // cursor: "pointer",
                        }}
                    >
                        {badgeLimit ? (showBadges?.length > 4 ? showBadges?.length - 4 + " More ..." : "") : null}
                    </Typography>
                </Box>
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> {showBadges?.length == 0 ? "No Badges yet." : null}</Typography>
                </Box>
            )}
        </Grid>
    );
};

export default SingleBadge;
