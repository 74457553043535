import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { motion, useAnimation, useInView, Variant } from "framer-motion";
import { useEffect, useRef } from "react";

type MovingTextProps = {
    text: string | string[];
    el?: keyof JSX.IntrinsicElements;
    className?: string;
    once?: boolean;
    repeatDelay?: number;
    animation?: {
        hidden: Variant;
        visible: Variant;
    };
};

const defaultAnimations = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.1,
        },
    },
};

const MovingText = ({
    text,
    el: Wrapper = "p",
    className,
    once,
    repeatDelay,
    animation = defaultAnimations,
}: MovingTextProps) => {
    const controls = useAnimation();
    const textArray = Array.isArray(text) ? text : [text];
    const ref = useRef(null);
    const isInView = useInView(ref, { amount: 0.5, once });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        const show = () => {
            controls.start("visible");
            if (repeatDelay) {
                timeout = setTimeout(async () => {
                    await controls.start("hidden");
                    controls.start("visible");
                }, repeatDelay);
            }
        };

        if (isInView) {
            show();
        } else {
            controls.start("hidden");
        }

        return () => clearTimeout(timeout);
    }, [isInView]);

    return (
        <Wrapper className={className}>
            <span
                style={{
                    position: "absolute",
                    width: "1px",
                    height: "1px",
                    padding: "0",
                    margin: "-1px",
                    overflow: "hidden",
                    clip: "rect(0, 0, 0, 0)",
                    border: "0",
                }}
            >
                {text}
            </span>
            <motion.span
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={{
                    visible: { transition: { staggerChildren: 0.1 } },
                    hidden: {},
                }}
                style={{
                    fontWeight: "bold",
                    fontSize: isMobile ? 50 : 100,
                    color: theme.palette.secondary.main,
                    textAlign: "center",
                    textShadow: "6px 16px 20px rgba(0,0,0,0.4)",
                }}
                aria-hidden
            >
                {textArray.map((line, lineIndex) => (
                    <span
                        key={`${line}-${lineIndex}`}
                        style={{ display: "block" }} // Replacing 'block'
                    >
                        {line.split(" ").map((word, wordIndex) => (
                            <span
                                key={`${word}-${wordIndex}`}
                                style={{ display: "inline-block" }} // Replacing 'inline-block'
                            >
                                {word.split("").map((char, charIndex) => (
                                    <motion.span
                                        key={`${char}-${charIndex}`}
                                        style={{ display: "inline-block" }} // Replacing 'inline-block'
                                        variants={animation}
                                    >
                                        {char}
                                    </motion.span>
                                ))}
                                <span style={{ display: "inline-block" }}>&nbsp;</span>
                            </span>
                        ))}
                    </span>
                ))}
            </motion.span>
        </Wrapper>
    );
};

export default MovingText;
