import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const badgeCardStyles = makeStyles((theme: Theme) =>
    createStyles({
        largeAvatar: {
            width: 250,
            height: 250,
            position: 'relative',
            overflow: 'hidden',
            alignSelf: "center",
            objectFit: 'contain',
        },
        badgeContent: {
            transition: " transform 1s ease-in",
            transform: "translateX(0)",
        },

        slideIn: {
            transform: " translateX(-100%)",
        },
        averageAvatar: {
            width: 250,
            height: 250,
            objectFit: 'contain',
            position: 'relative',
            overflow: 'hidden',

            '&:hover::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                opacity: 0,
                transition: 'opacity .8s',

            },
            '&:hover::after': {
                content: '"Click Here"',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                fontSize: 13,
                fontWeight: 'bold',
                opacity: 0,
                transition: 'opacity 0.3s',
            },
            '&:hover::before, &:hover::after': {
                opacity: 1,
            },
        },
        selectedBadgeBorder: {
            border: "1px solid #c6db34",
            borderRadius: 23,
            '&:hover': {
                border: "1px solid #c6db34 ",

            },

        },
        clampTeam: {
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

        },
    }));



export default badgeCardStyles;
