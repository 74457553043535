import React, { ReactNode } from "react";
import { useUserState } from "./user.ctx";

type t_UserPermissions = {
    admin_view: boolean;
    admin_lsa: boolean;
    admin_appeal: boolean;
    admin_incentives: boolean;
    admin_production: boolean;
    admin_access: boolean;
    admin_content: boolean;
    lsa_create: boolean;
    appeal_create: boolean;
    appeal_approve: boolean;
    reports_view: boolean;
    downtime_approve: boolean;
    additional_volume_approve: boolean;
    external_leave_create: boolean;
    team_manage: boolean;
};

const base_UserPermissions = {
    admin_view: false,
    admin_lsa: false,
    admin_appeal: false,
    admin_incentives: false,
    admin_production: false,
    admin_access: false,
    admin_content: false,
    lsa_create: false,
    appeal_create: false,
    appeal_approve: false,
    reports_view: false,
    downtime_approve: false,
    additional_volume_approve: false,
    external_leave_create: false,
    team_manage: false,
};

const UserPermissionsContext = React.createContext<t_UserPermissions>(base_UserPermissions);

const usePermissions = () => {
    const context = React.useContext(UserPermissionsContext);

    if (context === undefined) throw new Error("User permissions must be used inside a provider.");
    return context;
};

const PermissionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const currentUser = useUserState();
    const [roles, setRoles] = React.useState<t_UserPermissions>(base_UserPermissions);

    React.useEffect(() => {
        var isActive = true;

        if (currentUser.profile?.entity_no != null) {
            let newRoles = { ...base_UserPermissions };
            currentUser.profile.permissions?.forEach((role) => {
                switch (role.slug) {
                    case "admin-view":
                        newRoles.admin_view = true;
                        break;
                    case "admin-lsa":
                        newRoles.admin_lsa = true;
                        break;
                    case "admin-appeal":
                        newRoles.admin_appeal = true;
                        break;
                    case "admin-incentives":
                        newRoles.admin_incentives = true;
                        break;
                    case "admin-production":
                        newRoles.admin_production = true;
                        break;
                    case "admin-access":
                        newRoles.admin_access = true;
                        break;
                    case "admin-content":
                        newRoles.admin_content = true;
                        break;
                    case "lsa-create":
                        newRoles.lsa_create = true;
                        break;
                    case "appeal-create":
                        newRoles.appeal_create = true;
                        break;
                    case "appeal-approve":
                        newRoles.appeal_approve = true;
                        break;
                    case "reports-view":
                        newRoles.reports_view = true;
                        break;
                    case "downtime-approve":
                        newRoles.downtime_approve = true;
                        break;
                    case "additional-volume-approve":
                        newRoles.additional_volume_approve = true;
                        break;
                    case "external-leave-create":
                        newRoles.external_leave_create = true;
                        break;
                    case "team-manage":
                        newRoles.team_manage = true;
                        break;
                }
            });
            if (isActive) {
                setRoles({ ...newRoles });
            }
        }

        return () => {
            isActive = false;
        };
    }, [currentUser.profile?.entity_no]);

    return <UserPermissionsContext.Provider value={roles}>{children}</UserPermissionsContext.Provider>;
};

export type { t_UserPermissions };
export { UserPermissionsContext, usePermissions };
export default PermissionProvider;
