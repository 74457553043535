import { httpClient } from "../../../../clients/http.client";
import { t_PerformanceUser } from "../../Performance.ctx";

type t_MetricTrend = {
	score: number;
	month: string;
	target: number;
};
const getMetricTrend = async (payload: {
	entity_no: t_PerformanceUser["user"]["entity_no"];
	metric_id?: number;
	month?: string;
}) => {
	const response = await httpClient.post("/metrics/trend", payload);
	return response.data.data.trend;
};

export type { t_MetricTrend };
export { getMetricTrend };
