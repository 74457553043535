import { useContext, useEffect, useState } from "react";
import Prompts from "./notifications/Prompts";
import Summaries from "./notifications/Summaries";
import LSA from "./notifications/LSA";
import FriendRequest from "./notifications/FriendRequest";
import Badges from "./notifications/Badges";
import Appeal from "./notifications/Appeal";
import Downtime from "./notifications/Downtime";
import AdditionalVolume from "./notifications/AdditionalVolume";
import { CreateNotificationsProps } from "./notifications/NotificationTypes";
import { useUserState } from "../../context/user/user.ctx";
import {
    AdditionalVolumeNotification,
    BadgeNotification,
    DowntimeNotification,
    FriendRequestNotification,
    LsaItem,
    PromptNotification,
    SummaryNotification,
} from "../../redux/types";
import { useClearNotificationsMutation, useGetNotificationsMutation } from "../../redux/api/beYouCore";
import { NotificationsContext } from "../Toasts/NotificationsProvider.ctx";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { walkThroughNotifications } from "../../pages/Home/BeYouCoach/WalkThroughData";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Typography } from "@mui/material";

type NotificationProps = {
    badges?: BadgeNotification[];
    friend_requests?: FriendRequestNotification[];
    lsa?: LsaItem[];
    appeal?: any[];
    downtime?: DowntimeNotification[];
    additional_volume?: AdditionalVolumeNotification[];
    summaries?: SummaryNotification[];
    prompts?: PromptNotification[];
};

const CreateNotifications = ({
    setFeedbackOpen,
    selectedNotification,
    notificationsCount,
    setNotificationsCount,
    // setSidePanelChatBot,
    reviewFeedbackOpen,
    setReviewFeedbackOpen,
}: CreateNotificationsProps) => {
    const [notifications, setNotifications] = useState<NotificationProps>({});
    const [clearMessage, setClearMessage] = useState("");
    const { showSidePanelChatBot, setSidePanelChatBot } = useContext(NotificationsContext);
    const { enqueueSnackbar } = useSnackbar();

    const [getNotifications] = useGetNotificationsMutation();
    const [clearNotifications] = useClearNotificationsMutation();
    const notificationsWalkThrough = useSelector((state: RootState) => state.walkThrough.notificationsWalkThrough);

    const removeNotification = (id: number) => {
        clearNotifications({ notification_id: id })
            .unwrap()
            .then((response) => {
                setClearMessage(response.message + id);
                enqueueSnackbar(response.message, { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(error.data.message, { variant: "error" });
            });
    };

    useEffect(() => {
        if (showSidePanelChatBot) {
            if (notificationsWalkThrough) {
                const notifications = walkThroughNotifications;

                const friend_requests = notifications.friend_requests;
                const lsa = notifications.lsa;
                const appeal = notifications.appeal;
                const downtime = notifications.downtime;
                const additional_volume = notifications.additional_volume;
                const badges = notifications.badges;
                const summaries = notifications.summaries;

                const prompts = notifications.prompts;

                setNotifications({
                    friend_requests,
                    //@ts-ignore
                    lsa,
                    appeal,
                    badges,
                    summaries,
                    prompts,
                    downtime,
                    additional_volume,
                });
            } else {
                getNotifications()
                    .unwrap()
                    .then((response) => {
                        const notifications = notificationsWalkThrough
                            ? walkThroughNotifications
                            : response.data.notifications;

                        const friend_requests = notifications.friend_requests;
                        const lsa = notifications.lsa;
                        const appeal = notifications.appeal;
                        const downtime = notifications.downtime;
                        const additional_volume = notifications.additional_volume;
                        const badges = notifications.badges;
                        const summaries = notifications.summaries;
                        const prompts = notifications.prompts;

                        switch (selectedNotification.name) {
                            case "All":
                                setNotifications({
                                    friend_requests,
                                    //@ts-ignore
                                    lsa,
                                    appeal,
                                    badges,
                                    summaries,
                                    prompts,
                                    downtime,
                                    additional_volume,
                                });

                                const newCount = Number(
                                    Object.values(notifications).reduce((acc, val: any) => acc + val.length, 0),
                                );

                                setNotificationsCount({
                                    ...notificationsCount,
                                    all: newCount,
                                    additional_volume: notifications.additional_volume.length,
                                    appeal: notifications.appeal.length,
                                    badges: notifications.badges.length,
                                    downtime: notifications.downtime.length,
                                    friend_requests: notifications.friend_requests.length,
                                    lsa: notifications.lsa.length,
                                    prompts: prompts.length,
                                    summaries: notifications.summaries.length,
                                });

                                break;
                            case "Additional Volume":
                                setNotifications({
                                    additional_volume,
                                });
                                break;
                            case "Appeal":
                                setNotifications({
                                    appeal,
                                });
                                break;
                            case "Badges":
                                setNotifications({
                                    badges,
                                });
                                break;
                            case "Downtime":
                                setNotifications({
                                    downtime,
                                });
                                break;
                            case "Friend Request":
                                setNotifications({
                                    friend_requests,
                                });
                                break;
                            case "LSA":
                                setNotifications({
                                    //@ts-ignore
                                    lsa,
                                });
                                break;
                            case "Prompts":
                                setNotifications({
                                    prompts,
                                });
                                break;
                            case "Summaries":
                                setNotifications({
                                    summaries,
                                });
                                break;
                            default:
                                break;
                        }
                    })
                    .catch((error) => {
                        console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
                    });
            }
        }
    }, [selectedNotification, showSidePanelChatBot, clearMessage]);

    return (
        <div>
            {notifications?.additional_volume && (
                <>
                    {notifications.additional_volume.map((additionalVolume, index) => (
                        <AdditionalVolume
                            additionalVolume={additionalVolume}
                            setSidePanelChatBot={setSidePanelChatBot}
                            key={`${additionalVolume.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.appeal && (
                <>
                    {notifications.appeal.map((appeal: any, index) => (
                        <Appeal
                            appeal={appeal}
                            setSidePanelChatBot={setSidePanelChatBot}
                            removeNotification={removeNotification}
                            key={`${appeal.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.badges && (
                <>
                    {notifications.badges.map((badge, index) => (
                        <Badges
                            badge={badge}
                            setSidePanelChatBot={setSidePanelChatBot}
                            removeNotification={removeNotification}
                            key={`${badge.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.downtime && (
                <>
                    {notifications.downtime.map((dtime, index) => (
                        <Downtime
                            downtime={dtime}
                            setSidePanelChatBot={setSidePanelChatBot}
                            key={`${dtime.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.friend_requests && (
                <>
                    {notifications.friend_requests.map((request: any, index) => (
                        <FriendRequest
                            request={request}
                            removeNotification={removeNotification}
                            key={`${request.id}-${index}`}
                            setSidePanelChatBot={setSidePanelChatBot}
                        />
                    ))}
                </>
            )}
            {notifications?.lsa && (
                <>
                    {notifications.lsa.map((lsa: any, index) => (
                        <LSA
                            lsa={lsa}
                            setSidePanelChatBot={setSidePanelChatBot}
                            removeNotification={removeNotification}
                            key={`${lsa.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.prompts && (
                <>
                    {notifications.prompts.map((prompt, index) => (
                        <Prompts
                            prompt={prompt}
                            setFeedbackOpen={setFeedbackOpen}
                            setSidePanelChatBot={setSidePanelChatBot}
                            removeNotification={removeNotification}
                            key={`${prompt.id}-${index}`}
                        />
                    ))}
                </>
            )}
            {notifications?.summaries && (
                <>
                    {notifications.summaries.map((summary, index) => (
                        <Summaries
                            summary={summary}
                            setSidePanelChatBot={setSidePanelChatBot}
                            reviewFeedbackOpen={reviewFeedbackOpen}
                            setReviewFeedbackOpen={setReviewFeedbackOpen}
                            removeNotification={removeNotification}
                            key={`${summary.id}-${index}`}
                        />
                    ))}
                </>
            )}

            {notifications && notifications[selectedNotification.value as keyof typeof notifications]?.length === 0 && (
                <div>No New Notifications</div>
            )}
        </div>
    );
};

export default CreateNotifications;
