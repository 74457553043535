import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    teamPoolsContainer: {
      padding: "1em 2em",
    },
    tablesContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "2em",
    },
    secondaryTable: {
      width: "95%",
      margin: "0 0 0 auto",
    },
    MuiPaperRounded: {
      borderRadius: "unset",
    },
    MuiPaperElevation2: {
      boxShadow: "unset",
    },
    mainBtnGroup: {
      textAlign: "center",
      margin: "1em 0",
    },
    mainBtn: {
      padding: ".5em 1.5em",
    },
    actionIcon: {
      "&:hover": {
        cursor: "pointer",
      },
      
    },
    next: {
      borderRadius:10,
      padding:8,
          background: "#c6db34",
        },
        skip: {
        
          background: "transparent",
        },
  })
);
