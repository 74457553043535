import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    beYouAvatar: {
        avatar: {
            accessoriesOption: "default",
            clothesOption: "default",
            eyesOption: "default",
            eyebrowOption: "default",
            mouthOption: "default",
            skinOption: "default",
            topOption: "default",
            facialHair: "default",
            clothesColors: "default",
            facialHairColors: "default",
            hatColors: "default",
            hairColors: "default",
            backgroundColor: "#2c2ce6",
            avatarImageBase64: null,
        },
        image: null,
        is_profile: null,
        unlocks: null,
        items: [],
    },
};

const beYouAvatarSlice = createSlice({
    name: "beYouAvatar",
    initialState,
    reducers: {
        setBeYouAvatar: (state, action) => {
            state.beYouAvatar = action.payload;
        },
    },
});

export const { setBeYouAvatar } = beYouAvatarSlice.actions;

export default beYouAvatarSlice.reducer;
