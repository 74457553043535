import { httpClient } from "../../clients/http.client";
import { MiscBody } from "../../types/api.types";
import { t_LeaderBoardUser } from "./LeaderBoard.types";

const getLeaderBoardData = async (
	payload: { category_id: any; sub_category_id?: number } & MiscBody
) =>
	new Promise<
		{
			leaderboard: t_LeaderBoardUser[];
		} & MiscBody
	>((resolve, reject) => {
		httpClient
			.post("/leaderboard", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve({
							leaderboard: data.data.leaderboard,
							...data,
						});
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const getLeaderBoardCategories = async (): Promise<
	{
		label: string;
		_id: any;
	}[]
> => {
	const body = {
		section: "leaderboard",
	};

	const response = await httpClient.post("/categories", body);
	return response.data.data.categories?.map(
		(category: { id: number; Name: string }) => ({
			_id: category.id,
			label: category.Name,
		})
	);
};

export { getLeaderBoardData, getLeaderBoardCategories };
