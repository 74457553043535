import { ReactNode } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { usePermissions } from "../../context/user/permissions.ctx";
import Admin from "../../pages/Home/Admin/Admin";
import BeYouCoach from "../../pages/Home/BeYouCoach/BeYouCoach";
import Challenge from "../../pages/Home/Challenge/Challenge";
import CoachingForms from "../../pages/Home/CoachingForms/CoachingForms";
import SubmittedForms from "../../pages/Home/CoachingForms/SubmittedForms";
import Dashboard from "../../pages/Home/Dashboard/Dashboard";
import GlobalSearch from "../../pages/Home/GlobalSearch/GlobalSearch";
import Help from "../../pages/Home/Help/Help";
import Leaderboards from "../../pages/Home/Leaderboards/Leaderboards";
import Login from "../../pages/Home/Login/Login";
import Messaging from "../../pages/Home/Messaging/Messaging";
import Performance from "../../pages/Home/Performance/Performance";
import Production from "../../pages/Home/Production/Production";
import Profile from "../../pages/Home/Profile/Profile";
import Reports from "../../pages/Home/Reports/Reports";
import ProtectedRoute from "../../routers/ProtectedRoute";
import Badges from "../../sections/Badges/Badges";
import Insights from "../../sections/Insights/Insights";
import NegativeOvation from "../../sections/Ovation/NegativeOvation";
import Ovation from "../../sections/Ovation/Ovation";
import OvationLeaderboard from "../../sections/Ovation/OvationLeaderboard";
import ProductionPoolWeight from "../../sections/Production/ProductionPoolWeight";
import MaintainTWTservice from "../../sections/TWT/MaintainTWTservice";
import MaintainTWTstream from "../../sections/TWT/MaintainTWTstream";
import MaintainTWTteams from "../../sections/TWT/MaintainTWTteams";
import FriendSearch from "../../sections/User/sections/FriendSearch/FriendSearch";

const Routing: React.FC<{ children?: ReactNode }> = () => {
    const permissions = usePermissions();

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/beyoucoach/dashboard" replace />} />
            <Route path="/search" element={<GlobalSearch />} />
            <Route path="/help" element={<Help />} />
            <Route element={<ProtectedRoute />}>
                <Route path="/beyoucoach/badges" element={<Badges />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/performance/dashboard" element={<Performance />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/performance/production" element={<Production />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/profile" element={<Profile />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/friends" element={<FriendSearch />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/challenge" element={<Challenge />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route element={<Messaging />} path="/inbox" />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/beyoucoach/dashboard" element={<BeYouCoach />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/beyoucoach/insights" element={<Insights />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/beyoucoach/insights/:id" element={<Insights />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/leaderboards" element={<Leaderboards />} />
            </Route>
            {/* <Route element={<ProtectedRoute permissions={[permissions.reports_view]} />}> */}
            <Route path="/reports/*" element={<Reports />} />
            {/* </Route> */}
            <Route element={<ProtectedRoute permissions={[permissions.team_manage]} />}>
                <Route path="/admin/twt-teams" element={<MaintainTWTteams />} />
                <Route path="/admin/twt-service-environment" element={<MaintainTWTservice />} />
                <Route path="/admin/twt-streams" element={<MaintainTWTstream />} />
                <Route path="/admin/prod-pool" element={<ProductionPoolWeight />} />
            </Route>
            <Route element={<ProtectedRoute permissions={[permissions.admin_view]} />}>
                <Route path="/admin/*" element={<Admin />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path={`/coaching-forms/submitted`} element={<SubmittedForms />} />
                <Route path="/coaching-forms/templates" element={<CoachingForms />} />
                <Route path="/coaching-forms/team-development" element={<NegativeOvation />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/ovation/report" element={<Ovation />} />
                <Route path="/ovation/leaderboard" element={<OvationLeaderboard />} />
            </Route>
        </Routes>
    );
};

export default Routing;
