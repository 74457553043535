import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import OvationCard from "./OvationCard";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";

const OvationSlider = ({ receivedOvations }: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };
    return (
        <motion.div
            initial="hidden"
            viewport={{ once: true }}
            whileInView="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.3 }}
            style={{ position: "relative" }}
        >
            <div
                style={{
                    background: "linear-gradient(to right, #f5f5f5, transparent)",
                    height: "100%",
                    width: "5rem",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 2,
                }}
            />
            <div
                style={{
                    background: "linear-gradient(to right, transparent, #f5f5f5)",
                    height: "100%",
                    width: "5rem",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 10, // Replacing Tailwind's "z-10"
                }}
            />
            {receivedOvations.length === 0 ? (
                <Typography color="GrayText" textAlign="center">
                    There are no Ovations within the month you've selected.
                </Typography>
            ) : receivedOvations.length <= 4 ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 8 }}>
                    {" "}
                    {receivedOvations.map((ovation: any, index: number) => (
                        <OvationCard ovation={ovation} key={`${index}-${ovation}`} />
                    ))}
                </Box>
            ) : (
                <Swiper
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={isMobile ? 1.5 : receivedOvations.length >= 5 ? 5 : receivedOvations.length}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    autoplay={{
                        delay: 3500,
                    }}
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                >
                    {receivedOvations.map((ovation: any, index: number) => (
                        <SwiperSlide key={`${index}-${ovation}`}>
                            <OvationCard ovation={ovation} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </motion.div>
    );
};

export default OvationSlider;
