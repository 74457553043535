import { Menu, MenuItem, Typography } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import React from "react";
import SubmitButton, { SubmitButtonProps } from "../SubmitButton/SubmitButton";

type Props = SubmitButtonProps & {
	dispatch: (exportType: string) => void;
};

const ExportButton: React.FC<Props> = ({
	dispatch,
	children,
	...submitProps
}) => {
	const [anchorEl, setAnchorEl] = React.useState<Element>();

	const handleOpen: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const handleExport = (exportType: string) => {
		if (dispatch != null) {
			dispatch(exportType);
		}
		setAnchorEl(undefined);
	};
	return (
		<div>
			<SubmitButton
				{...submitProps}
				onClick={handleOpen}
				startIcon={<CloudDownload />}
			>
				{children}
			</SubmitButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				keepMounted
			>
				<MenuItem onClick={() => handleExport("excel")}>
					<Typography variant='inherit'>Export to Excel</Typography>
				</MenuItem>
				<MenuItem onClick={() => handleExport("csv")}>
					<Typography variant='inherit'>Export to CSV</Typography>
				</MenuItem>{" "}
			</Menu>
		</div>
	);
};

export default ExportButton;
