import { Grid } from "@mui/material";
import React from "react";
import BadgeCard, { BadgeCardProps } from "../BadgeCard/BadgeCard";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
type BadgeVariant = "block" | "mini" | undefined;
type BadgeListProps = {
    badges: BadgeCardProps[];
    variant?: BadgeVariant;
    [propName: string]: any;
    onClick?: any;
};
const badgeVariantSize: { [key: string]: 1 | 2 } = {
    mini: 2,
    block: 2,
    default: 2,
};
const BadgeList = (props: BadgeListProps) => {
    const { badges, variant, onClick, ...other } = props;
    const navigate = useNavigate();
    return (
        <Grid container spacing={1} {...other}>
            {badges.map((badge, index) => (
                <Grid item sm={badgeVariantSize[variant ?? "default"]} key={`${badge.title}-${index}`}>
                    <BadgeCard
                        {...badge}
                        avatarClicked={() => navigate(`/beyoucoach/badges?badge_id=${badge?.id}`)}
                        variant={variant === "mini" ? variant : "flat"}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
export default BadgeList;
