import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

import SectionCard from "../../../components/SectionCard/SectionCard";
import { formsList } from "../FormsList";
import FormViewer from "../FormViewer";
import CoachFormCard from "./CoachFormCard";

interface Props {
    formsFilter: string;
    formSelectChange: any;
    setFormId: any;
    filteredSubmittedFormsList: any;
    selectedForm: any;
    setSelectedForm: any;
    formFields: any;
    metricID: any;
    setMetricID: any;
}

const FormsView = ({
    formsFilter,
    formSelectChange,
    filteredSubmittedFormsList,
    selectedForm,
    setSelectedForm,
    setFormId,
    formFields,
    metricID,
    setMetricID,
}: Props) => {
    return (
        <Grid container spacing={2} style={{ padding: 16 }}>
            <Grid item sm={4}>
                <SectionCard
                    hasBackground
                    scrolling
                    primaryAction={
                        <Box sx={{ mt: 2 }}>
                            <Select
                                value={formsFilter}
                                label="Coaching Forms"
                                onChange={formSelectChange}
                                variant="standard"
                            >
                                {formsList?.map((form) => (
                                    <MenuItem value={form?.type} key={form?.id}>
                                        {form?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    }
                >
                    <div style={{ maxHeight: "65vh" }}>
                        {filteredSubmittedFormsList?.length <= 0 ? (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "auto",
                                    padding: 5,
                                }}
                            >
                                <Typography color="textSecondary"> No data to display currently.</Typography>
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                {filteredSubmittedFormsList.map((form: any, index: number) => (
                                    <React.Fragment key={index}>
                                        <Grid item sm={12} mb={1}>
                                            <CoachFormCard form={form} onCardClick={() => setSelectedForm(form)} />
                                        </Grid>
                                        {filteredSubmittedFormsList.length <= index + 1 && (
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    margin: "auto",
                                                    padding: 5,
                                                }}
                                            >
                                                <Typography color="textSecondary">End</Typography>
                                            </Box>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        )}
                    </div>
                </SectionCard>
            </Grid>
            <Grid item sm={8}>
                <SectionCard hasBackground scrolling>
                    <div style={{ maxHeight: "70vh" }}>
                        {selectedForm ? (
                            <>
                                {/* {" "}
                                <DownloadFormAsPDF
                                    form={selectedForm}
                                    formFields={formFields}
                                    metricID={metricID}
                                    setMetricID={setMetricID}
                                /> */}
                                <FormViewer
                                    formFields={formFields}
                                    form={selectedForm}
                                    metricID={metricID}
                                    setMetricID={setMetricID}
                                />
                            </>
                        ) : (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "auto",
                                    padding: 5,
                                }}
                            >
                                <Typography color="textSecondary"> please select a form</Typography>
                            </Box>
                        )}
                    </div>
                </SectionCard>
            </Grid>
        </Grid>
    );
};

export default FormsView;
