import { httpClient } from "../../../../clients/http.client";
import { t_PoolColumn } from "../../Production.types";
import {
	t_ProductionByUser,
	t_ProductionByUser_Totals,
} from "./ProductionByUser.types";

const getTeamProduction = (payload: {
	entity_no: number;
	capture_date: string;
}) => {
	return new Promise<{
		pools: t_PoolColumn[];
		rows: t_ProductionByUser[];
		totals: t_ProductionByUser_Totals;
	}>((resolve, reject) => {
		httpClient
			.post("/production/team_overview", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
};

export { getTeamProduction };
