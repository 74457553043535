import { Box, Button, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import { MTableBody, MTableBodyRow, MTableCell, MTableGroupRow, MTableHeader, MTableToolbar } from "material-table";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import { t_GroupData, t_Team } from "../TwtGroup.types";
import { getGlobalReport, getGlobalReportFilter } from "../twtReports.api";
import { useStyles } from "./TWTReport.styles";
import { columns } from "./columns";
import { twtHeader } from "./twtHeader";
import { Autocomplete } from "@mui/material";
import { MiscBody } from "../../../types/api.types";
import {
    getWeightedRegionsSearch,
    getWeightedServiceEnviromentsSearch,
} from "../../Production/tables/PoolWeights/PoolWeights.api";
import BUAutocomplete from "../../../components/BUAutocomplete/BUAutocomplete";
import muiStyles from "../sections/EditPoolGroup/EditPoolGroup.styles";
import ExportButton from "../../../components/ExportButton/ExportButton";
import { getApiUrl } from "../../../misc/helpers";
import { useReportExport } from "../../../hooks/export/useReportExport";
import { getIqsObjectives } from "../iqsObjectives.api";
import useMultiDateSelect from "../../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { Cancel } from "@mui/icons-material";
import IQSObjectivesTable from "./iqsObjectivesTable";

const TWTReport = () => {
    const theme = useTheme();
    const { loading: exportLoading, downloadExportFile } = useReportExport();
    const muiStyle = muiStyles();
    const [tab, setTab] = React.useState<number>(0);
    const [currDate, setCurrDate] = React.useState<any>(new Date());
    const [rawData, setRawData] = React.useState<any | null>(null);
    const [reportData, setReportData] = React.useState<t_Team[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [selectedTeam, setSelectedTeam] = React.useState("");
    const [seFilterArr, setSeFilterArr] = React.useState<string[]>([]);
    const [reFilterArr, setReFilterArr] = React.useState<number[]>([]);
    const [selTeamList, setSelTeamList] = React.useState([{ Name: "", TwtServiceEnvironmentID: 0 }]);
    const [selRegionList, setRegionList] = React.useState([{ Name: "", TwtRegionID: 0 }]);
    const [activeTeam, setActiveTeam] = React.useState<number[]>();
    const [activeGroup, setActiveGroup] = React.useState<t_GroupData>();
    const [temp, setTemp] = React.useState<any>({});

    const [iqsTeams, setIqsTeams] = React.useState<any[]>();
    const [iqsData, setIqsData] = React.useState<any[]>([{}]);
    const [iqsHeadings, setIqsHeadings] = React.useState<any[]>([{ title: "Target", field: "field" }]);
    const [iqsDates, setIqsDates] = React.useState<string[]>([]);
    const [iqsFilter, setIqsFilter] = React.useState<string[]>([]);
    const [refreshTable, setRefreshTable] = React.useState<boolean>();
    const [newHeadings, setNewHeadings] = React.useState<any[]>([{ title: "Target", field: "field" }]);
    const [change, setChange] = React.useState<boolean>(false);

    const tableRef = React.createRef<any>();

    const { datePills, yearAndMonthSelector, formattedPeriods, periodsArr, selectedDate, datesPeriodsSelected } =
        useMultiDateSelect();

    React.useEffect(() => {
        fetchTableData([]);
        iqsObjectives();
    }, [currDate]);

    const iqsObjectives = async () => {
        getIqsObjectives({
            periods: iqsFilter.length > 0 ? iqsFilter : [format(currDate, "yyyy-MM-dd")],
            pagination: {
                page: 1,
                rows_per_page: "all",
            },
            filter: [
                {
                    column_name: "lk_twt_service_environment.Name",
                    search_term: "",
                },
            ],
            sort: {
                column_name: "lk_twt_service_environment.Name",
                order: "DESC",
            },
        })
            .then((resp: any) => {
                if (resp.totalCount === 0) {
                    setChange(true);
                } else {
                    setChange(false);
                }
                let teams: any[] = [];
                let objs: any[] = [];
                Object.keys(resp.data).forEach((item, key) => {
                    teams.push(item);
                });
                setIqsTeams(teams);
                teams.forEach((item: any) => {
                    let temp = {
                        Team: item,
                        ...resp.data[item],
                    };
                    delete temp["pools"];
                    objs.push(temp);
                });
                setIqsData(objs);
                let head: any[] = [];
                Object.keys(objs[0]).forEach((item: any) => {
                    if (item !== "tableData") {
                        head.push({ field: item, title: item });
                    }
                });
                setIqsHeadings(head);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const formatDate = (date: any) => {
        let arr = [...iqsDates];
        let filterArr = [...iqsFilter];
        arr?.push(date.toLocaleString("en-GB", { month: "long", year: "numeric" }));
        filterArr?.push(format(new Date(date?.toUTCString() ?? new Date()), "yyyy-MM-dd"));
        setIqsDates(arr);
        setIqsFilter(filterArr);
        return;
    };

    const fetchTableData = async (searchArr: number[] | string, isRegion?: boolean) => {
        const payload = {
            date: format(new Date(currDate?.toUTCString() ?? new Date()), "yyyy-MM-dd"),
            entityno: "",
            filter: [
                seFilterArr!.length > 0
                    ? {
                          column_name: "service_team_name",
                          search_term: seFilterArr,
                      }
                    : {
                          column_name: "service_team_name",
                          search_term: "",
                      },
                reFilterArr!.length > 0
                    ? {
                          column_name: "region_name",
                          search_term: reFilterArr,
                      }
                    : {
                          column_name: "region_name",
                          search_term: "",
                      },
            ],
        };

        setLoading(true);
        selectSE();
        selectRE();
        getGlobalReportFilter(payload)
            .then((respData) => {
                setTemp(respData.data[respData.serviceTeams[0]]);
                if (Object.keys(respData.data).includes("none")) {
                    setRawData(null);
                    setReportData([]);
                } else {
                    setRawData(respData.data);
                    formatData(respData.data);
                    setSelectedTeam(respData.serviceTeams[0]);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const colorControl = (statement: any) => {
        switch (statement) {
            case "isGroup":
                return { backgroundColor: "#efefef", fontSize: 12 };
            case "isRegion":
                return { backgroundColor: "#dbdbdb", fontSize: 12 };
            case "isPool":
                return { backgroundColor: "#fffffff", fontSize: 12 };
            default:
                return { fontSize: 12, backgroundColor: "#bfbfbf" };
        }
    };

    const formatData = (data: any) => {
        let arr: any = [];
        Object.keys(data).forEach((item: any) => {
            let x = { ...data[item], name: item, measure: "" };
            arr.push(x);
            x.region.map((item: any) => {
                let newItem = { ...item, type: "isRegion", measure: "" };
                arr.push(newItem);
                item.group.map((group: any) => {
                    let newGroup = {
                        ...group,
                        type: "isGroup",
                        measure: "",
                    };
                    arr.push(newGroup);
                    group.pools.map((pool: any) => {
                        let newPool = {
                            ...pool,
                            name: pool.pool_name + ` (${pool.pool_number})`,
                            measure: pool.measure == 1 ? pool.measure + " day" : pool.measure + " days",
                            type: "isPool",
                        };
                        arr.push(newPool);
                    });
                });
            });
        });
        return arr;
    };

    const styles = useStyles();

    const selectRE = () => {
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedRegionsSearch({
                pagination: { page: 1, rows_per_page: 100 },
                filter: [{ column_name: "Name", search_term: "" }],
                sort: { column_name: "Name", order: "ASC" },
            }).then((resp) => {
                setRegionList(resp.data.records.data);
            });
        });
    };

    const selectSE = () => {
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedServiceEnviromentsSearch({
                pagination: { page: 1, rows_per_page: 100 },
                filter: [{ column_name: "Name", search_term: "" }],
                sort: { column_name: "Name", order: "ASC" },
            }).then((resp) => {
                setSelTeamList(resp.data.records.data);
            });
        });
    };

    const removeDate = (date: any) => {
        let dates = [...iqsDates];
        let filter = [...iqsFilter];
        let index = dates.indexOf(String(date));
        dates.splice(date, 1);
        filter.splice(date, 1);
        setIqsDates(dates);
        setIqsFilter(filter);
    };

    const handleExport = (file_type: string) => {
        const appeal_url = new URL(`${getApiUrl()}/admin/twt/reports/export`);
        let temp = currDate && String(currDate.toDateString());
        let date = temp!.substring(temp!.indexOf(" ") + 1);
        appeal_url.searchParams.append("date", date);
        appeal_url.searchParams.append("file_type", file_type);

        downloadExportFile(appeal_url.toString(), "TWT Export");
    };

    const seFilter = (value: { TwtServiceEnvironmentID: number; Name: string }[]) => {
        let arr: string[] = [];
        Object.keys(value).forEach((item: any) => {
            let x = value[item].Name;
            arr.push(x);
        });
        setSeFilterArr(arr);
    };

    const reFilter = (value: { TwtRegionID: number; Name: string }[]) => {
        let arr: number[] = [];
        Object.keys(value).forEach((item: any) => {
            let x = value[item].TwtRegionID;
            arr.push(x);
        });
        setReFilterArr(arr);
    };

    const filter = (arr: any) => {
        fetchTableData([]);
    };

    return (
        <Grid item xs={12}>
            <SectionCard
                title={"TWT Reports"}
                fullWidth
                primaryAction={
                    <TabGroup
                        tabList={[
                            {
                                _id: 0,
                                label: "Global TWT Report",
                            },
                            {
                                _id: 1,
                                label: "iQS Objectives",
                            },
                        ]}
                        tabValue={tab}
                        tabChange={(tab) => setTab(tab)}
                    />
                }
                secondaryAction={
                    tab === 0 ? (
                        <DatePicker
                            label="Select Date"
                            value={currDate}
                            onChange={(date) => setCurrDate(date)}
                            views={["year", "month", "day"]}
                        />
                    ) : (
                        <DatePicker
                            label="Select Date"
                            value={null}
                            slotProps={{
                                textField: {
                                    helperText: "YYYY-MM-DD",
                                    variant: "standard",
                                    placeholder: "Select A Date",
                                },
                            }}
                            onChange={(date) => formatDate(new Date(date ?? new Date()))}
                            views={["year", "month"]}
                            // autoOk
                        />
                    )
                }
            >
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    {tab === 0 && (
                        <>
                            <Box display="flex" alignItems={"center"}>
                                <Box flexGrow={1} maxWidth={"32em"} gap="0 1em" p="1em">
                                    <Autocomplete
                                        multiple
                                        options={selTeamList}
                                        getOptionLabel={(option) => option.Name}
                                        onChange={(event, value) => {
                                            seFilter(value);
                                        }}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Teams" />}
                                    />
                                </Box>
                                <Box flexGrow={1} maxWidth={"32em"} gap="0 1em" p="1em">
                                    <Autocomplete
                                        multiple
                                        options={selRegionList}
                                        getOptionLabel={(option) => option.Name}
                                        onChange={(event, value) => {
                                            reFilter(value);
                                        }}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Regions" />}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        style={{
                                            backgroundColor: theme.palette.info.main,
                                            color: theme.palette.info.contrastText,
                                        }}
                                        onClick={() => filter([])}
                                    >
                                        Filter
                                    </Button>
                                </Box>
                                <Box marginLeft={"auto"}>
                                    <ExportButton
                                        dispatch={handleExport}
                                        loading={exportLoading}
                                        disabled={exportLoading}
                                    >
                                        Export
                                    </ExportButton>
                                </Box>
                            </Box>
                            <Box m="1em .5em">
                                {reportData && (
                                    <BUMaterialTable
                                        title={"Global TWT Report"}
                                        columns={columns}
                                        data={rawData ? formatData(rawData) : []}
                                        options={{
                                            search: false,
                                            sorting: false,
                                            header: true,
                                            draggable: false,
                                            paging: false,
                                            maxBodyHeight: "600px",
                                        }}
                                        isLoading={loading}
                                        components={{
                                            // Header: (props) => <>{twtHeader()}</>,
                                            Header: (props) => <>{twtHeader()}</>,
                                            Row: (props) => (
                                                <MTableBodyRow
                                                    {...props}
                                                    options={{
                                                        rowStyle: colorControl(props.data.type),
                                                    }}
                                                />
                                            ),
                                            Body: (props) => (
                                                <>
                                                    <MTableBody
                                                        option={{
                                                            enableStickyHeader: true,
                                                        }}
                                                        {...props}
                                                    />
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    )}
                    {tab === 1 && (
                        <>
                            <Box display="flex" marginBottom={5}>
                                {iqsDates
                                    ? iqsDates.map((item, index) => (
                                          <Box
                                              key={index}
                                              m="10px 10px 0"
                                              bgcolor="info.main"
                                              p="10px"
                                              borderRadius="8px"
                                              display="flex"
                                              alignItems="center"
                                              alignContent={"center"}
                                              style={{ gap: "10px" }}
                                          >
                                              {item.toLocaleString()}
                                              {
                                                  <div onClick={() => removeDate(index)}>
                                                      <Cancel />
                                                  </div>
                                              }
                                          </Box>
                                      ))
                                    : null}
                                <Button
                                    style={{
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                        marginLeft: "auto",
                                        marginRight: 20,
                                        maxHeight: 37,
                                    }}
                                    onClick={() => iqsObjectives()}
                                >
                                    Filter
                                </Button>
                            </Box>
                            {change ? (
                                <p style={{ textAlign: "center" }}>No Data available for the selected period</p>
                            ) : (
                                <IQSObjectivesTable
                                    periodsArr={
                                        iqsFilter.length > 0
                                            ? iqsFilter
                                            : [format(new Date(currDate?.toUTCString() ?? new Date()), "yyyy-MM-dd")]
                                    }
                                    data={iqsData}
                                    headings={iqsHeadings}
                                    loading={loading}
                                />
                            )}
                        </>
                    )}
                </Grid>
            </SectionCard>
        </Grid>
    );
};

export default TWTReport;
