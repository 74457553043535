import { FormControl, Grid, IconButton, Input, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { useModal } from "../../../context/modal";
import HelpFileSearch from "../../../sections/HelpFiles/sections/HelpFileSearch/HelpFileSearch";
import UserCard from "../../../sections/User/components/UserCard/UserCard";
import UserSearch from "../../../sections/User/sections/UserSearch/UserSearch";
import { t_UserBase } from "../../../sections/User/User.types";
import globalSearchStyles from "./GlobalSearch.style";

const GlobalSearch: React.FC = () => {
    const classes = globalSearchStyles();
    const [_, setModal] = useModal();
    const [search, setSearch] = useState<string>(" ");

    const setSelection = (user: t_UserBase) => {
        setModal({
            type: "sectioned",
            title: user.name,
            content: <UserCard user={user} />,
        });
    };
    return (
        <>
            <SectionCard
                title="Search"
                primaryAction={
                    <FormControl>
                        <Input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton size="large">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                        ></Input>
                    </FormControl>
                }
                scrolling
            >
                <Grid container spacing={2} className={classes.sectionContainer}>
                    <Grid item sm={6} className={classes.subSectionContainer}>
                        <SectionCard title="Users" titleProps={{ variant: "h6" }} scrolling fullWidth>
                            <UserSearch searchTerm={search} onUserSelected={setSelection} />
                        </SectionCard>
                    </Grid>
                    {/* <Grid item sm={6} className={classes.subSectionContainer}>
                    <SectionCard
                        title='Helpfiles'
                        titleProps={{ variant: "h6" }}
                        scrolling
                        fullWidth
                    >
                        <HelpFileSearch search={search} />
                    </SectionCard>
                </Grid> */}
                </Grid>
            </SectionCard>
            {/* <SectionedDialog
            open={selection != null}
            onClose={() => setSelection(undefined)}
            title={selection?.name}
            maxWidth='md'
        >
            {selection != null && <UserCard user={selection} />}
        </SectionedDialog> */}
        </>
    );
};

export default GlobalSearch;
