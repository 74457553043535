import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import { formatApiDate } from "../../../../misc/helpers";
import AppealDialogContent from "../../../Appeals/dialogs/AppealDialogContent/AppealDialogContent";
import LsaDialogContent from "../../../LowScoreAnalysis/dialogs/LsaDialogContent/LsaDialogContent";
import { getMetricRecords } from "../../../Performance/Performance.api";
import { t_PerformanceUser } from "../../../Performance/Performance.ctx";
import { t_MetricPerformance } from "../../../Performance/Performance.types";
import AppealButton from "../../components/AppealButton/AppealButton";
import LsaButton from "../../components/LsaButton/LsaButton";
import { actions_MetricSurveyRecords } from "./MetricSurveyRecords.api";
import { t_MetricSurveyRecord } from "./MetricSurveyRecords.types";
import { Box, Pagination, Typography } from "@mui/material";

type t_DialogState = {
    title?: string;
    lsa?: {
        lsa_id?: number;
        survey_record: t_MetricSurveyRecord;
    };
    appeal?: {
        appeal_id?: number;
        appeal_date?: string;
        can_appeal?: boolean;
        appeal_cutoff?: string;
        survey_record: t_MetricSurveyRecord;
    };
};
const dialogDispatcher = (state: t_DialogState, { action, rowData }: actions_MetricSurveyRecords): t_DialogState => {
    if (!rowData) return {};

    switch (action) {
        case "lsa":
            return {
                title: "Low Score Analysis",
                lsa: {
                    lsa_id: rowData.lsa?.LsaID,
                    survey_record: rowData,
                },
            };
        case "appeal":
            return {
                title: "Appeal",
                appeal: {
                    appeal_id: rowData.appeal?.AppealID,
                    appeal_date: rowData.appeal?.AppealDate,
                    can_appeal: rowData.can_appeal,
                    appeal_cutoff: rowData.appeal_cutoff,
                    survey_record: rowData,
                },
            };
    }
    return {};
};
interface Props {
    viewMetric: t_MetricPerformance;
    month: string;
    viewUser: t_PerformanceUser;
}
const MetricSurveyRecords: React.FC<Props> = ({ viewMetric, month, viewUser }) => {
    const tableRef = React.createRef<any>();
    const [dialogState, dialogDispatch] = React.useReducer(dialogDispatcher, {});
    const [loading, setLoading] = React.useState<boolean>(false);

    const [records, setRecords] = React.useState<t_MetricSurveyRecord[]>([]);
    const [currentPage, setCurrentPage] = React.useState<number>(0); // State for the current page

    const handleDialogDispatch = (reason?: string) => {
        switch (reason) {
            case "update":
                setLoading(true);
                break;
            default:
                dialogDispatch({});
                break;
        }
    };

    React.useEffect(() => {
        setLoading(true);
    }, [viewMetric, viewUser.user.entity_no, month]);

    React.useEffect(() => {
        var isActive = true;

        if (loading) {
            setRecords([]);
            getMetricRecords<t_MetricSurveyRecord>({
                entity_no: viewUser.user.entity_no,
                metric_id: viewMetric.id,
                month,
            })
                .then((data) => {
                    if (isActive) {
                        setRecords(data);
                        if (tableRef.current) {
                            tableRef.current.onQueryChange({
                                page: currentPage,
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (isActive) {
                        setRecords([]);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return () => {
            isActive = false;
        };
    }, [loading]);

    return (
        <>
            <BUMaterialTable
                tableRef={tableRef}
                columns={
                    [
                        {
                            title: "Agent",
                            field: "user.DisplayName",
                            hidden: viewUser.user.job_level == 1,
                            filtering: false,
                        },
                        { title: "Response ID", field: "ResponseID", filtering: false },
                        {
                            title: "Survey Type",
                            field: "SurveyTypeID",
                            render: (rowData) => rowData?.type?.Description,
                            filtering: false,
                        },
                        { title: "Call ID", field: "CallID", filtering: false },
                        {
                            title: "Call Date Answered",
                            field: "CallDateAnswered",
                            render: (data) => formatApiDate(data?.CallDateAnswered),
                            filtering: false,
                        },
                        {
                            title: "Survey Date",
                            field: "SurveyDate",
                            render: (data) => formatApiDate(data?.SurveyDate),
                            filtering: false,
                        },
                        {
                            title: "Survey Score",
                            field: "SurveyScore",
                            render: (rowData) => {
                                switch (viewMetric.id) {
                                    case 3:
                                        return Number(rowData?.SurveyScore) > 0 ? "Yes" : "No";
                                    default:
                                        return rowData?.SurveyScore;
                                }
                            },
                            filtering: false,
                        },
                        {
                            render: (rowData) =>
                                Number(rowData?.SurveyScore) <= Number(rowData?.LowScoreParameter) ? (
                                    <LsaButton
                                        status_id={rowData?.lsa?.status?.StatusID}
                                        status_label={rowData?.lsa?.status?.Name}
                                        required={true}
                                        onClick={() =>
                                            dialogDispatch({
                                                action: "lsa",
                                                rowData,
                                            })
                                        }
                                    />
                                ) : undefined,
                            field: "lsa.StatusID",
                            title: "LSA",
                            lookup: {
                                1: "LSA Not Completed",
                                2: "LSA Partially Completed",
                                3: "LSA Completed",
                            },
                            filtering: true,
                        },

                        {
                            render: (rowData) => (
                                <AppealButton
                                    status_id={rowData?.appeal?.status?.ApprovalStatusID}
                                    can_appeal={rowData?.can_appeal}
                                    appeal_cutoff={rowData?.appeal_cutoff}
                                    status_label={rowData?.appeal?.status?.Status}
                                    onClick={() =>
                                        dialogDispatch({
                                            action: "appeal",
                                            rowData,
                                        })
                                    }
                                />
                            ),
                            title: "Appeal",
                            field: "appeal.StatusID",
                            filtering: false,
                        },
                    ] as Column<t_MetricSurveyRecord>[]
                }
                isLoading={loading}
                data={records}
                options={{
                    toolbar: false,
                    padding: "dense",
                    pageSize: 10,
                    paging: true,
                    filtering: true,
                }}
                components={{
                    Pagination: (props) => {
                        const { page, rowsPerPage, count, onPageChange } = props;
                        const [tablePage, setTablePage] = React.useState(currentPage);

                        const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
                            setTablePage(value - 1);
                            setCurrentPage(value - 1);
                            onPageChange(event, value - 1);
                        };

                        return (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 4,
                                }}
                            >
                                <Typography>Page: {tablePage + 1}</Typography>
                                <Pagination
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={tablePage + 1}
                                    onChange={handleChange}
                                />
                            </Box>
                        );
                    },
                }}
            />
            <SectionedDialog
                open={Boolean(dialogState.lsa || dialogState.appeal)}
                title={dialogState.title}
                maxWidth="md"
                onClose={() => dialogDispatch({})}
            >
                {dialogState.lsa && (
                    <LsaDialogContent
                        lsa_id={dialogState.lsa.lsa_id}
                        metric_id={viewMetric.id}
                        record={dialogState.lsa.survey_record}
                        lsa={{
                            DataID: dialogState.lsa.survey_record.DataID,
                            StatusID: dialogState.lsa.survey_record.lsa?.StatusID,
                        }}
                        dispatch={handleDialogDispatch}
                    />
                )}
                {dialogState.appeal && (
                    <AppealDialogContent
                        data_id={dialogState.appeal.survey_record.DataID}
                        metric_id={viewMetric.id}
                        record={dialogState.appeal.survey_record}
                        appeal_id={dialogState.appeal.appeal_id}
                        can_submit={dialogState.appeal.can_appeal}
                        appeal_date={dialogState.appeal.appeal_date}
                        expire_date={dialogState.appeal.appeal_cutoff}
                        dispatch={handleDialogDispatch}
                    />
                )}
            </SectionedDialog>
        </>
    );
};

export default MetricSurveyRecords;
