import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Badge } from "../types";

type BadgeState = {
    allBadges: Badge[];
};

const initialState: BadgeState = {
    allBadges: [],
};

const badgesSlice = createSlice({
    name: "badges",
    initialState,
    reducers: {
        setAllBadges: (state, action: PayloadAction<Badge[]>) => {
            state.allBadges = action.payload;
        },
    },
});

export const { setAllBadges } = badgesSlice.actions;

export default badgesSlice.reducer;
