import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useRef, useState } from "react";

import SectionCard from "../../components/SectionCard/SectionCard";
import { NavItem } from "../../navigation/components/NavItem/NavItem";
import { useGetSubmissionCountQuery } from "../../redux/api/beYouCore";
import CoachingFormGraph from "./CoachingFormGraph";
import CoachingFormUserDetails from "./CoachingFormUserDetails";
import SubmissionFrequency from "./SubmissionFrequency";
import CoachingSubmittedFormTables from "./SubmittedForm/CoachingSubmittedFormTables";

interface Field {
    label: string;
    value: string | string[];
    type: string;
    multiline?: boolean;
    showGraph?: boolean;
    error?: string;
    tableType?: string;
    setMetricID: any;
}

interface Section {
    title: string;
    [key: string]: Field | string;
}

const FormViewer = ({ form, formFields, metricID, setMetricID }: any) => {
    const month = moment(form.submittted_at).format("YYYY/MM/DD");
    const { data: { data: submissionCount } = {} } = useGetSubmissionCountQuery({
        type: form?.type.toLowerCase(),
        month,
        entity_no: form?.entity_no,
    });
    const sectionRef = useRef<HTMLDivElement>(null);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const handleDownload = () => {
        const section = sectionRef.current;

        if (section) {
            html2canvas(section, {
                scale: 2,
                useCORS: true,
            }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF({
                    orientation: "p",
                    unit: "mm",
                    format: "a4",
                    compress: true,
                });

                const imgWidth = 210;
                const pageHeight = 295;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save(`${form.name}-${form.type}-${form.submitted_at}.pdf`);
            });
        } else {
            console.error("Section reference is null");
        }
    };

    const renderField = (field: Field, sectionIndex: number) => {
        const renderTypography = (label: string, value: string, multiline: boolean | undefined) => {
            if (multiline) {
                return <Typography sx={{ width: "100%" }}>{value}</Typography>;
            } else {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", width: "30%" }}>{label}</Typography>
                        <Typography sx={{ width: "70%" }}>{value}</Typography>
                    </Box>
                );
            }
        };

        if (field.showGraph) {
            return (
                <CoachingFormGraph
                    entity_no={form?.entity_no}
                    month={form?.submitted_at}
                    metricID={metricID}
                    setMetricID={setMetricID}
                    formType={form?.type}
                    setShowDownloadButton={setShowDownloadButton}
                />
            );
        }

        switch (field.type) {
            case "input":
                return renderTypography(field.label, field.value as string, field.multiline);
            case "text":
                return <SubmissionFrequency data={submissionCount} entity_no={form?.entity_no} />;
            case "emojiface":
                return (
                    <Typography
                        sx={{
                            width: field?.multiline ? "100%" : "30%",
                            backgroundColor:
                                field?.value == "😀"
                                    ? "#D4EFDF"
                                    : field?.value == "😐"
                                      ? "#FCF3CF"
                                      : field?.value == "🙁"
                                        ? "#F5B7B1 "
                                        : "",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 0.8,
                        }}
                    >
                        {field?.value}
                    </Typography>
                );
            case "autocomplete":
            case "array":
                if (Array.isArray(field.value)) {
                    return (
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {field.value.map((value, index) => (
                                <Chip
                                    key={index}
                                    label={`${index + 1}. ${value}`}
                                    style={{
                                        margin: 5,
                                        backgroundColor: "#c6db34",
                                        color: "#FFFFFF",
                                    }}
                                />
                            ))}
                        </Box>
                    );
                }
                return null;
            case "link":
                if (typeof field.value === "string") {
                    return (
                        <Box sx={{ p: 2 }}>
                            <NavItem open={true} label={field.label} target={true} route={field.value} />
                        </Box>
                    );
                }
                return null;
            case "date":
            case "dropdown":
                return renderTypography(field.label, field.value as string, field.multiline);
            case "table":
                return <CoachingSubmittedFormTables selectedForm={form} tableType={field?.tableType} />;
            case "showGraph":
                return (
                    <CoachingFormGraph
                        entity_no={form?.entity_no}
                        month={form?.submitted_at}
                        metricID={metricID}
                        setMetricID={setMetricID}
                        formType={form?.type}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box>
            {showDownloadButton && (
                <Button variant="outlined" onClick={handleDownload}>
                    <Typography sx={{ color: "blue" }}>Download as PDF</Typography>
                </Button>
            )}

            <Grid container spacing={2} style={{ padding: 16 }} ref={sectionRef}>
                <Grid item xs={12}>
                    <Box sx={{ marginBottom: 3 }}>
                        <Typography gutterBottom variant="h6">
                            Hi {form?.name}
                        </Typography>
                        <Typography gutterBottom variant="body2">
                            Thank you for attending our coaching session.
                        </Typography>
                        <Typography gutterBottom variant="body2">
                            Please see below data regarding our coaching session. If you have any concerns please reach
                            out…
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <CoachingFormUserDetails name={form?.name} job_title={form?.job_title} subject={form?.subject} />
                </Grid>

                {formFields.map((section: Section, index: number) => (
                    <Grid item xs={12} key={index}>
                        <SectionCard key={index} title={section.title} hasBackground fullWidth>
                            {Object.entries(section).map(([key, value], sectionIndex: number) => {
                                if (key === "title") return null;
                                const field = value as Field;

                                return (
                                    <React.Fragment key={sectionIndex}>
                                        <Box className="shadow-box" sx={{ padding: 2 }}>
                                            {renderField(field, sectionIndex)}
                                        </Box>
                                        {sectionIndex < Object.entries(section).length - 1 && (
                                            <Divider
                                                sx={{
                                                    width: "95%",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    marginBottom: 1,
                                                }}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </SectionCard>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default FormViewer;
