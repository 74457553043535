import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { formatApiDate } from "../../../../misc/helpers";
import { deletePublicHoliday, getPublicHolidays, updatePublicHoliday } from "./PublicHolidays.api";
import { t_PublicHoliday } from "./PublicHolidays.types";
import moment from "moment";

interface Props {
    year: string;
}

const PublicHolidays: React.FC<Props> = ({ year }) => {
    const tableRef = React.createRef<any>();
    const [columns] = React.useState<Column<t_PublicHoliday>[]>([
        {
            title: "Date",
            field: "date",
            render: (rowData) => formatApiDate(rowData.date, "MMM dd yyyy"),
            editComponent: (props) => (
                <DatePicker
                    // {...props}
                    // variant='inline'
                    format="MMM dd yyyy"
                    onChange={(date) => {
                        date && props.onChange(date);
                    }}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            ),
            validate: (rowData) => Boolean(rowData.date),
        },
        {
            title: "Name",
            field: "name",
            validate: (rowData) => Boolean(rowData.name),
        },
    ]);

    React.useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [year]);

    return (
        <BUMaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) => getPublicHolidays({ query, year })}
            editable={{
                onRowAdd: (newData) => updatePublicHoliday({ holiday: newData }),
                onRowUpdate: (newData) =>
                    updatePublicHoliday({ holiday: { ...newData, date: moment(newData.date).format("MM/DD/YYYY") } }),
                onRowDelete: (rowData) => deletePublicHoliday({ holiday_id: rowData.id }),
            }}
            options={{
                actionsColumnIndex: -1,
                showTitle: false,
            }}
        />
    );
};

export default PublicHolidays;
