import {
    ArrowDownward,
    ArrowUpward,
    ChevronLeftOutlined,
    ChevronRightOutlined,
    FirstPageOutlined,
    LastPageOutlined,
    Search,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { useUserState } from "../../context/user/user.ctx";
import debounce from "../../misc/debounce";
import { getImageUrl, stringAvatar } from "../../misc/helpers";

type Column = {
    title: string;
    field: string;
    render?: (value: any, row: DataItem) => React.ReactNode;
};

type DataItem = {
    [key: string]: any;
};

interface OvatationTableComponentProps {
    columns: Column[];
    data: DataItem[];
    title: string;
    isLoading: boolean;
}

const OvatationTableComponent = ({ columns, data, title, isLoading }: OvatationTableComponentProps) => {
    const currentUser = useUserState();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [sortConfig, setSortConfig] = useState<{ field: string; direction: "asc" | "desc" } | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const debouncedSearch = useCallback(
        debounce(300, (text) => {
            setSearchQuery(text);
            setCurrentPage(1);
        }),
        [],
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value);
    };

    const handleRowsPerPageChange = (e: SelectChangeEvent<number>) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (field: string) => {
        let direction: "asc" | "desc" = "asc";
        if (sortConfig && sortConfig.field === field && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ field, direction });
    };

    const sortedData = useMemo(() => {
        const dataCopy = [...data];
        const loggedInUserIndex = dataCopy.findIndex((item) => item.entity_no === currentUser.entity_no);
        if (loggedInUserIndex !== -1) {
            const [user] = dataCopy.splice(loggedInUserIndex, 1);
            dataCopy.unshift(user);
        }

        if (sortConfig) {
            dataCopy.sort((a, b) => {
                const aValue = a[sortConfig.field];
                const bValue = b[sortConfig.field];

                if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
                return 0;
            });
        }

        return dataCopy;
    }, [data, currentUser, sortConfig]);

    const filteredData = useMemo(
        () =>
            sortedData.filter((item) =>
                columns.some(
                    (column) =>
                        item[column.field] != null &&
                        item[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase()),
                ),
            ),
        [searchQuery, sortedData, columns],
    );

    const paginatedData = useMemo(
        () => filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [filteredData, currentPage, rowsPerPage],
    );

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                background: theme.palette.primary.main,
                padding: 2,
                borderRadius: 2,
                maxHeight: "80vh",
                overflowY: "scroll",
                mt: 5,
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 4,
                    background: theme.palette.primary.main,
                    paddingY: 2,
                    zIndex: 10,
                }}
            >
                <Typography variant="h6">{title}</Typography>
                <TextField
                    placeholder="Search..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    onChange={handleSearchChange}
                />
            </Box>
            {data.length > 0 ? (
                <Box sx={{ width: "100%", marginTop: "16px" }}>
                    {/* <table style={{ width: "100%" }}> */}
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr",
                            gap: "8px",
                            alignItems: "center",
                            padding: "8px",
                            marginBottom: "16px",
                        }}
                    >
                        {columns.map((col) => (
                            <Typography
                                key={col.title}
                                sx={{ fontWeight: "bold", fontSize: "0.9em", cursor: "pointer", textAlign: "center" }}
                                onClick={() => handleSort(col.field)}
                                noWrap
                            >
                                {col.title}
                                {sortConfig?.field === col.field &&
                                    (sortConfig.direction === "asc" ? <ArrowUpward /> : <ArrowDownward />)}
                            </Typography>
                        ))}
                    </Box>
                    <Divider />
                    <Box>
                        {paginatedData.map((data, index) => (
                            <>
                                <Box
                                    key={data.entity_no}
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr",
                                        gap: "8px",
                                        padding: "8px",
                                        alignItems: "center",
                                        border: `2px solid ${theme.palette.info.main}`,
                                        borderRadius: 2,
                                        marginY: 3,
                                        minHeight: 150,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Avatar
                                            src={getImageUrl("profile", data.profile_img)}
                                            {...stringAvatar(data.name)}
                                        />

                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <Typography sx={{ mt: 1, fontSize: "0.8em", fontWeight: "bold" }} noWrap>
                                                {data.name}
                                            </Typography>
                                        </div>
                                    </Box>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.ResponseID}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.SurveyType}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.CallID}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.CallDateAnswered}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.SurveyDate}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.SurveyScore}
                                        </Typography>
                                    </div>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap sx={{ textAlign: "center" }}>
                                            {data.OvationType}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <Typography
                                            noWrap={hoveredIndex !== index}
                                            sx={{ textAlign: "center", transition: "opacity 0.5s ease" }}
                                        >
                                            {data.Comments}
                                        </Typography>
                                    </div>
                                </Box>
                                <Divider />
                            </>
                        ))}
                    </Box>
                    {/* </table> */}
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <FormControl>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                sx={{
                                    "& fieldset": {
                                        border: "none",
                                    },
                                }}
                            >
                                {[5, 10, 15, 20].map((num: any) => (
                                    <MenuItem key={num} value={num}>
                                        {num}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box>
                            <IconButton onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                                <FirstPageOutlined />
                            </IconButton>
                            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                <ChevronLeftOutlined />
                            </IconButton>
                            <small>
                                {currentPage} of {totalPages}
                            </small>
                            <IconButton
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <ChevronRightOutlined />
                            </IconButton>
                            <IconButton
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                <LastPageOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        background: theme.palette.primary.main,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Typography color="GrayText">No Data Found</Typography>
                </Box>
            )}
        </Box>
    );
};

export default OvatationTableComponent;
