import { httpClient } from "../../../../clients/http.client";

const endPointPoints = {
  actual_prod: "production",
  monthly_downt: "downtime",
  additional_vol: "additional_volume",
  category_downt: "downtime_by_category",
};

export function getProductionGraphData<T>(
  payload: { dates: string[] },
  graphType: keyof typeof endPointPoints
) {
  let endPoint: string =
    "/reports/production/graphs/" + endPointPoints[graphType];

  return new Promise<T>((resolve, reject) => {
    const body = { months: payload.dates };
    httpClient.post(endPoint, body).then((resp) => {
      switch (resp.data.status) {
        case "success":
          resolve(resp.data.data.graph_data);
          break;
        default:
          reject(resp.data.message);
      }
    });
  });
}
