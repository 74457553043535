import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 2000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    gridItem: {
        width: "100%",
        height: "100%",
        background: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontWeight: "bold",
        fontSize: 20,
    },
    image: {
        transition: "transform 1s ease",
        transform: "translateX(100%)",
        visibility: "hidden",
    },
    visible: {
        transform: "translateX(0)",
        visibility: "visible",
    },
    textContainer: {
        borderRadius: 30,
        background: theme.palette.background.paper,
        padding: 20,
        minWidth: "30vw",
    },
    typewriter: {
        overflow: "hidden",
        animation: "$typewriterEffect 4s steps(40)",
    },
    statsBox: {
        minHeight: "10em",
        maxHeight: "13em",
        overflowY: "scroll",
        scrollBehavior: "smooth",
        padding: ".8em",
        background: theme.palette.background.default,
    },
    borderRotate: {
        position: "relative",
        background: "#e5ecf2",
        overflow: "hidden",
        borderRadius: "10px",
        zIndex: 1,
        color: theme.palette.common.black,

        "&::before": {
            content: "''",
            position: "absolute",
            width: "150%",
            height: "20%",
            // background: "radial-gradient(circle at -1% 57.5%, rgb(19, 170, 82) 0%, rgb(0, 102, 43) 90%)",
            background: "linear-gradient(45deg,#30D843,white)",
            zIndex: -9,
            animation: "$borderRotateEffect 5s linear infinite",
        },

        "&::after": {
            content: "''",
            position: "absolute",
            background: "#e5ecf2",
            inset: "2px",
            zIndex: -7,
            borderRadius: "8px",
        },
    },

    "@keyframes typewriterEffect": {
        "0%": {
            width: 0,
        },
        "50%": {
            width: "100%",
        },
        "100%": {
            width: "100%",
        },
    },

    "@keyframes borderRotateEffect": {
        from: {
            transform: "rotate(0deg)",
        },
        to: {
            transform: "rotate(360deg)",
        },
    },
}));
