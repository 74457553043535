import {
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { MiscBody } from "../../../../types/api.types";
import manageAccessStyles from "./ManageAccess.styles";

type t_AccessItem = {
	id: number;
	name: string;
};

type t_AccessItemToggle = t_AccessItem & {
	hasAccess: boolean;
};
interface Props {
	term: string;
	accessItem: t_AccessItem;
	searchForItems: (
		params: { search: string } & MiscBody
	) => Promise<t_AccessItem[]>;
	fetchItems: (params: { id: number } & MiscBody) => Promise<t_AccessItem[]>;
	updateItems: (params: {
		id: number;
		grant_access: number[];
		revoke_access: number[];
	}) => Promise<boolean>;
}

const ManageAccess: React.FC<Props> = ({
	term,
	accessItem,
	searchForItems,
	fetchItems,
	updateItems,
}) => {
	const classes = manageAccessStyles();
	const [search, setSearch] = React.useState("");
	const [searchItems, setSearchItems] = React.useState<t_AccessItem[]>([]);
	const [accessItems, setAccessItems] = React.useState<t_AccessItem[]>();
	const [changeItems, setChangeItems] = React.useState<t_AccessItemToggle[]>(
		[]
	);
	const [visualFeedback, setVisualFeedback] = React.useState({
		submitting: false,
		submit_count: 0,
	});

	const handleUserSelected = (item: t_AccessItem | null) => {
		if (item != null) {
			toggleChecked({
				...item,
				hasAccess: true,
			});
		}
	};
	const toggleChecked = (access_item: t_AccessItemToggle) => {
		const changedIndex = changeItems.findIndex(
			(item) => access_item.id == item.id
		);
		const updatedChanges = [...changeItems];
		if (changedIndex >= 0) {
			updatedChanges.splice(changedIndex, 1);
		} else {
			updatedChanges.push(access_item);
		}
		setChangeItems(updatedChanges);
	};

	const handlePermissionUserUpdate = () => {
		setVisualFeedback((state) => ({
			...state,
			submitting: true,
		}));
		if (changeItems != null && changeItems.length > 0) {
			updateItems({
				id: accessItem.id,
				grant_access: changeItems
					.filter((item) => item.hasAccess)
					.map((item) => item.id),
				revoke_access: changeItems
					.filter((item) => !item.hasAccess)
					.map((item) => item.id),
			})
				.then(() => {})
				.catch((error) => console.error(error))
				.finally(() => {
					setVisualFeedback((state) => ({
						submitting: false,
						submit_count: state.submit_count++,
					}));
				});
		}
	};

	React.useEffect(() => {
		var isActive = true;

		setSearchItems([]);
		setSearch("");
		setChangeItems([]);

		fetchItems({
			id: accessItem.id,
		})
			.then((data) => {
				if (isActive) {
					setAccessItems(data);
				}
			})
			.catch((error) => console.error(error));
		return () => {
			isActive = false;
		};
	}, [accessItem.id, visualFeedback.submit_count]);

	React.useEffect(() => {
		var isActive = true;
		if (search != null) {
			searchForItems({
				search: search,
				pagination: {
					page: 1,
					rows_per_page: 20,
				},
			}).then((data) => {
				if (isActive) {
					setSearchItems(data);
				}
			});
		}
		return () => {
			isActive = false;
		};
	}, [search]);
	return (
		<div className={classes.splitContainer}>
			<SectionCard
				title='With Permission'
				titleProps={{ variant: "subtitle2" }}
				// add empty bottom actions to keep alignment the same
				bottomActions={<div />}
				scrolling
			>
				<List>
					{accessItems?.map((item, index) => {
						const labelId = `adjusted-searchItems-${item.id}`;

						return (
							<>
								<ListItem
									key={labelId}
									button
									onClick={() =>
										toggleChecked({
											...item,
											hasAccess: false,
										})
									}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={true}
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={item.name}
									/>
								</ListItem>
								{index !== accessItems.length - 1 && (
									<Divider component='li' />
								)}
							</>
						);
					})}
				</List>
			</SectionCard>
			<Divider orientation='vertical' flexItem />
			<SectionCard
				// title='Updates'
				// titleProps={{ variant: "subtitle2" }}
				scrolling
				secondaryAction={
					<Autocomplete
						className={classes.input}
						options={searchItems}
						getOptionLabel={(item) => item.name}
						clearOnBlur
						onChange={(_, value) => handleUserSelected(value)}
						inputValue={search}
						onInputChange={(_, value) => setSearch(value)}
						renderInput={(params) => (
							<TextField {...params} label={`Select ${term}`} />
						)}
					/>
				}
				bottomActions={
					<SubmitButton
						onClick={handlePermissionUserUpdate}
						loading={visualFeedback.submitting}
						disabled={
							visualFeedback.submitting ||
							!(changeItems.length > 0)
						}
					>
						Update Items
					</SubmitButton>
				}
			>
				<List>
					{changeItems?.map((item, index) => {
						const labelId = `adjusted-searchItems-${item.id}`;

						return (
							<>
								<ListItem
									key={labelId}
									button
									onClick={() => toggleChecked(item)}
								>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={item.hasAccess}
											tabIndex={-1}
											disableRipple
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={`${item.name} (${
											item.hasAccess ? "Grant" : "Revoke"
										})`}
									/>
								</ListItem>
								{index !== changeItems.length - 1 && (
									<Divider component='li' />
								)}
							</>
						);
					})}
				</List>
			</SectionCard>
		</div>
	);
};

export default ManageAccess;
