import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const notificationMessages = makeStyles((theme: Theme) =>
    createStyles({
        clamp: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        clampTeam: {
            display: "-webkit-box",
            "-webkit-line-clamp": 1,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        hover: {
            "&:hover": {
                backgroundColor: "#B8D200",
                color: "#fff",
                // borderRadius: 23,
            },
        },
    }),
);

export default notificationMessages;
