import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Box, Typography } from "@mui/material";
import { gsap } from "gsap";
import Bot from "../../beYou_bot.png";

interface ChatBotSteps {
    error?: boolean;
    success?: boolean;
    show?: any;
    message?: any;
    moveDirection?: any;
}
const useStyles = makeStyles((theme) => ({
    overlay: {
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 1300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    gridItem: {
        width: "100%",
        height: "100%",
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontWeight: "bold",
        fontSize: 20,
    },
    image: {
        opacity: 1,
        width: 80,
        height: 80,
    },
    visible: {
        transform: "translateX(0)",
        visibility: "visible",
        opacity: 1,
    },
    imageBottom: {
        transition: "transform 1s ease",
        transform: "translateY  (100%)",
    },
    visibleBottom: {
        transform: "translateY(0)",
        visibility: "visible",
    },
    textContainer: {
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
        opacity: 0,
        visibility: "hidden",
        borderRadius: 23,
        background: "white",
        display: "flex",
        alignItems: "center",
        padding: 20,
        flexFlow: "row",
        maxWidth: 600,
        minWidth: 450,
        marginBottom: 10,
        maxHeight: "auto",
        gap: "2rem",
        alignSelf: "flex-end",
    },
    close: {
        position: "absolute",
        top: -15,
        left: -5,
        width: 40,
        height: 40,
        objectFit: "contain",
    },
    forward: {
        width: 30,
        height: 30,
        objectFit: "contain",
    },
}));

const ToastsSuccess = ({ show, success, error, message }: ChatBotSteps) => {
    const classes = useStyles();
    const [removeNotif, setRemoveNotif] = useState(false);

    const handleClose = () => {
        if (error && !success) {
            setRemoveNotif(true);
        } else if (success && !error) {
            setRemoveNotif(true);
        } else {
            setRemoveNotif(false);
        }
        gsap.to(".summaryStatus", {
            x: "5000",
            duration: 5,
            ease: "power1.out",
            onStart: () => {
                gsap.set(".summaryStatus", { visibility: "visible", opacity: 1, duration: 1 });
            },
        });
    };
    useEffect(() => {
        if (!removeNotif) {
            gsap.fromTo(
                ".summaryStatus",
                { x: 5000, visibility: "hidden", opacity: 0 },
                { x: 0, duration: 1, opacity: 1, ease: "power1.out", delay: 1.1, visibility: "visible" },
            );
        }
    }, [success, error]);

    return (
        <>
            {success || error ? (
                <div className={classes.overlay}>
                    <Grid container spacing={2} style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "95%",
                                alignItems: "flex-end",
                                gap: "1rem",
                                height: "50%",
                            }}
                        >
                            <Box className={`${classes.textContainer} summaryStatus`}>
                                <img id="weekly-review" src={Bot} className={classes.image} />
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        height: "100%",
                                        flexFlow: "column",
                                    }}
                                >
                                    <Typography variant="h5" style={{ minHeight: 20, fontWeight: "bold" }}>
                                        {success ? "Success !" : "Error"}
                                    </Typography>
                                    <Box>
                                        <Typography variant="body1" align="left" style={{ textAlign: "left" }}>
                                            {message}
                                        </Typography>
                                    </Box>
                                </Box>

                                <img src="/images/Close.png" onClick={handleClose} className={classes.close} />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            ) : null}
        </>
    );
};

export default ToastsSuccess;
