import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

const MetricReviewTable = ({
    setCombinedData,
    viewMetrics,
}: {
    data: any[];
    setCombinedData: any;
    viewMetrics: any;
}) => {
    const columns = [
        {
            id: "metric",
            label: "Metric Reviewed",
            type: "name",
        },
        {
            id: "target",
            label: "Target",
            type: "input",
        },
        {
            id: "achieved",
            label: "Achieved",
            type: "input",
        },
        {
            id: "options_followed",
            label: "Option Followed",
            type: "emojiList",
        },
    ];

    const createData = (metricId: string, metric: any, target: any, achieved: any, options_followed: any) => {
        return { metricId, metric, target, achieved, options_followed };
    };

    const [rows, setRows] = useState(viewMetrics.map((metric: any) => createData(metric.id, metric.name, "", "", "")));

    useEffect(() => {
        setRows(viewMetrics.map((metric: any) => createData(metric.id, metric.name, "", "", "")));
    }, [viewMetrics]);

    const handleInputChange = (rowIndex: number, columnId: string, value: string) => {
        const updatedRows = rows.map((row: any, index: any) =>
            index === rowIndex ? { ...row, [columnId]: value } : row,
        );
        setRows(updatedRows);
        setCombinedData((prevState: any) => ({
            ...prevState,
            metricReviews: updatedRows,
        }));
    };

    const emojiList = [
        {
            id: 1,
            label: "",
            icon: "\u{2714}",
        },
        {
            id: 2,
            label: "",
            icon: "\u{274C}",
        },
    ];
    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} sx={{ fontWeight: "bold" }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {viewMetrics.length > 0 ? (
                    <TableBody>
                        {rows.map((row: any, rowIndex: number) => {
                            return (
                                <TableRow>
                                    {columns.map((column) => {
                                        //@ts-ignore
                                        const value = row[column.id];

                                        return (
                                            <TableCell key={column.id}>
                                                {column.type == "name" ? (
                                                    value
                                                ) : column.type == "date" ? (
                                                    <DatePicker
                                                        value={value}
                                                        onChange={(newValue: any) =>
                                                            handleInputChange(rowIndex, column.id, newValue)
                                                        }
                                                        format="dd/MM/yyyy"
                                                    />
                                                ) : column.type == "emojiList" ? (
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <InputLabel>Reaction Options</InputLabel>
                                                        <Select
                                                            value={value}
                                                            sx={{
                                                                width: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                backgroundColor:
                                                                    value == "✔"
                                                                        ? "#D4EFDF"
                                                                        : value == "❌"
                                                                          ? "#F5B7B1 "
                                                                          : "",
                                                            }}
                                                            variant="outlined"
                                                            displayEmpty
                                                            onChange={(e) =>
                                                                handleInputChange(rowIndex, column.id, e.target.value)
                                                            }
                                                        >
                                                            {emojiList.map((listItem: any) => (
                                                                <MenuItem
                                                                    value={listItem.icon}
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    {listItem.icon}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    <TextField
                                                        sx={{ width: "100%" }}
                                                        value={value}
                                                        onChange={(e) =>
                                                            handleInputChange(rowIndex, column.id, e.target.value)
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            margin: "auto",
                            padding: 5,
                        }}
                    >
                        <Typography color="textSecondary"> Please select metrics to display</Typography>
                    </Box>
                )}
            </Table>
        </TableContainer>
    );
};

export default MetricReviewTable;
