import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { MiscBody } from "../../../../types/api.types";
import { getAccessUsers, getPermissionUsers } from "../../Access.api";
import { t_Permission } from "../../Access.types";
import ManageAccess from "../ManageAccess/ManageAccess";
import { updatePermissionUsers } from "./MaintainPermission.api";

interface Props {
	permission: t_Permission;
}

const MaintainPermission: React.FC<Props> = ({ permission }) => {
	const [tab, setTab] = React.useState(0);

	return (
		<SectionCard
			title={`Permission: ${permission.name}`}
			titleProps={{ variant: "h6" }}
			primaryAction={
				<TabGroup
					tabValue={tab}
					tabChange={(value) => setTab(value)}
					tabList={[{ _id: 0, label: "Users" }]}
				/>
			}
			hasBackground
		>
			{tab === 0 && (
				<ManageAccess
					term='User'
					accessItem={permission}
					searchForItems={getAccessUsers}
					fetchItems={(params) =>
						getPermissionUsers({
							permission_id: params.id,
							...(params as MiscBody),
						})
					}
					updateItems={(params) =>
						updatePermissionUsers({
							permission_id: params.id,
							grant_for_users: params.grant_access,
							revoke_for_users: params.revoke_access,
						})
					}
				/>
			)}
		</SectionCard>
	);
};

export default MaintainPermission;
