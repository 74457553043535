import { Link } from "@mui/material";
import React from "react";
import { useModal } from "../../../../context/modal";
import { t_UserBase } from "../../User.types";
import UserCard from "../UserCard/UserCard";

type t_Props = {
	user: t_UserBase;
};

const UserLink: React.FC<t_Props> = ({ user }) => {
	const [_, setModal] = useModal();
	const showUser = () => {
		setModal({
			type: "sectioned",
			title: user.name,
			content: <UserCard user={user} />,
		});
	};
	return (
		<Link
			component='button'
			variant='body2'
			onClick={showUser}
			color='inherit'
		>
			{user.name}
		</Link>
	);
};

export default UserLink;
