import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Delete, Edit, Save, SyncDisabled } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import BUAutocomplete from "../../../../components/BUAutocomplete/BUAutocomplete";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { UserPermissionsContext } from "../../../../context/user/permissions.ctx";
import { MiscBody } from "../../../../types/api.types";
import IncentiveDetails from "./IncentiveDetails/IncentiveDetails";
import {
	createIncentiveCategory,
	deactivateIncentiveCategory,
	getIncentiveCategories,
	updateIncentiveCategory,
} from "./MaintainIncentive.api";
import { t_IncentiveType } from "./MaintainIncentive.types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: 350,
		},
		infoContainer: {
			width: "100%",
			height: "100%",
			display: "grid",
			placeContent: "center",
		},
	})
);

type IncentiveDialogs =
	| "add_category"
	| "edit_category"
	| "delete_category"
	| null;

const MaintainIncentive: React.FC = () => {
	const classes = useStyles();
	const newCategoryRef = useRef<HTMLInputElement>(null);
	const editCategoryRef = useRef<HTMLInputElement>(null);

	const [activeDialog, setActiveDialog] = useState<IncentiveDialogs>(null);
	const [activeCategory, setActiveCategory] = useState<
		t_IncentiveType | undefined
	>();
	const [categories, setCategories] = useState<t_IncentiveType[]>([]);

	const handleSelectCategory = (category: any) => {
		setActiveCategory(category ?? undefined);
	};

	const handleNewCategorySave = (event: any) => {
		event.preventDefault();
		if (newCategoryRef.current?.value) {
			const name = newCategoryRef.current?.value;
			createIncentiveCategory({ name })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => {
					setActiveDialog(null);
				});
		}
	};
	const handleCategoryUpdate = (event: any) => {
		event.preventDefault();
		if (activeCategory && editCategoryRef.current?.value) {
			const category_id = activeCategory.id;
			const name = editCategoryRef.current?.value;
			updateIncentiveCategory({ category_id, name })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => {
					setActiveDialog(null);
				});
		}
	};
	const handleCategoryDeactivate = (event: any) => {
		event.preventDefault();
		if (activeCategory) {
			const category_id = activeCategory.id;
			deactivateIncentiveCategory({ category_id })
				.then((data) => {
					refreshCategories();
				})
				.finally(() => setActiveDialog(null));
		}
	};

	const categorySource = (args: { search: string } & MiscBody) => {
		const { search, ...misc } = args;
		return getIncentiveCategories({
			...misc,
			filter: [
				{
					column_name: "Name",
					search_term: search,
				},
			],
		});
	};
	/** @MM Difficulty managing state of active category; Workaround for now; */
	const refreshCategories = () => {
		setActiveCategory(undefined);
		getIncentiveCategories().then((data) => {
			setCategories(data);
		});
	};

	useEffect(() => {
		let isActive = true;
		getIncentiveCategories().then((data) => {
			setCategories(data);
		});

		return () => {
			isActive = false;
		};
	}, []);

	return <>
        <SectionCard
            title='Maintain Incentive Category'
            fullWidth
            primaryAction={
                <BUAutocomplete
                    label='Select Category'
                    inputClassName={classes.formControl}
                    dataSource={categorySource}
                    value={activeCategory}
                    onChange={handleSelectCategory}
                />
            }
            secondaryAction={
                activeCategory && (
                    <ButtonGroup>
                        <IconButton onClick={() => setActiveDialog("edit_category")} size="large">
                            <Edit />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                setActiveDialog("delete_category")
                            }
                            size="large">
                            <Delete />
                        </IconButton>
                    </ButtonGroup>
                )
            }
        >
            {!activeCategory && (
                <div className={classes.infoContainer}>
                    <Typography>
                        Please select an incentive category.
                    </Typography>
                    <UserPermissionsContext.Consumer>
                        {({ admin_incentives }) => (
                            <Button
                                disabled={!admin_incentives}
                                startIcon={<Add />}
                                onClick={() =>
                                    setActiveDialog("add_category")
                                }
                            >
                                Create New
                            </Button>
                        )}
                    </UserPermissionsContext.Consumer>
                </div>
            )}
            {activeCategory && (
                <IncentiveDetails incentiveCategoryID={activeCategory.id} />
            )}
            <Dialog
                open={Boolean(activeDialog)}
                onClose={() => setActiveDialog(null)}
            >
                {activeDialog === "add_category" && (
                    <>
                        <DialogTitle>New Incentive Category</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter the new category's name below.
                            </DialogContentText>
                            <TextField
                                inputRef={newCategoryRef}
                                label='Name'
                                className={classes.formControl}
                                variant='outlined'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setActiveDialog(null)}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={<Add />}
                                onClick={handleNewCategorySave}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </>
                )}
                {activeCategory && activeDialog === "edit_category" && (
                    <>
                        <DialogTitle>Edit Incentive Category</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Enter the category's new name below.
                            </DialogContentText>
                            <TextField
                                inputRef={editCategoryRef}
                                defaultValue={activeCategory.name}
                                label='Name'
                                className={classes.formControl}
                                variant='outlined'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setActiveDialog(null)}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={<Save />}
                                onClick={handleCategoryUpdate}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </>
                )}
                {activeCategory && activeDialog === "delete_category" && (
                    <>
                        <DialogTitle>Delete Incentive Category</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this
                                category?
                            </DialogContentText>
                            <List>
                                <ListItem>
                                    <ListItemText>
                                        {activeCategory.name}
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setActiveDialog(null)}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={<SyncDisabled />}
                                onClick={handleCategoryDeactivate}
                            >
                                Deactivate
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </SectionCard>
    </>;
};

export default MaintainIncentive;
