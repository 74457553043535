import { CircularProgress, Divider, Grid } from "@mui/material";
import "date-fns";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import MetricPerformance from "../../components/MetricPerformance/MetricPerformance";
import { usePerformanceContext } from "../../Performance.ctx";
import UserPerformanceSummary from "../../tables/UserPerformanceSummary/UserPerformanceSummary";

interface UserPerformanceProps {}

const UserPerformance: React.FC<UserPerformanceProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [{ viewUser }] = usePerformanceContext();
    const [viewMetric, setViewMetric] = useState<any>();

    const handleMetricClicked = (metric: any) => {
        navigate(
            {
                search: "",
            },
            { replace: true },
        );
        setViewMetric(metric);
    };

    /** @MM - this process is overboard; don't have time to clean it up but I recommend we shift to just using the search params; */
    React.useEffect(() => {
        if (viewUser?.metrics && viewUser.metrics.length > 0) {
            let active_id = viewMetric?.id;
            const metric_param = new URLSearchParams(location.search).get("metric");
            if (metric_param != null) {
                active_id = Number(metric_param);
            }

            let exists = viewUser.metrics.find((metric) => metric.id === active_id);
            if (exists) {
                setViewMetric(exists);
            } else {
                setViewMetric(viewUser.metrics[0]);
            }
        }
    }, [viewUser?.metric_fetch_timestamp, viewUser?.user.entity_no, location.search]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} container>
                    <UserPerformanceSummary
                        viewUser={viewUser}
                        viewMetric={viewMetric}
                        onMetricSelected={handleMetricClicked}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <Divider />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {viewMetric ? (
                <MetricPerformance metric={viewMetric} />
            ) : (
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}
        </>
    );
};

export default UserPerformance;
