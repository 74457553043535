import React from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import { Box, Divider, Typography } from "@mui/material";

interface Props {
    name: string;
    job_title: string;
    subject: string;
}

const CoachingFormUserDetails = ({ name, job_title, subject }: Props) => {
    return (
        <SectionCard noHeader hasBackground>
            <Box sx={{ display: "flex", flexDirection: "row", py: 0.8 }}>
                <Typography sx={{ width: "30%", fontWeight: "bold", fontSize: "15px" }}>Name</Typography>
                <Typography sx={{ fontSize: "15px", width: "70%" }}>{name}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "row", py: 0.8 }}>
                <Typography sx={{ width: "30%", fontWeight: "bold", fontSize: "15px" }}>Job Title</Typography>
                <Typography sx={{ fontSize: "15px", width: "70%" }}>{job_title}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "row", py: 0.8 }}>
                <Typography sx={{ width: "30%", fontWeight: "bold", fontSize: "15px" }}>Subject</Typography>
                <Typography sx={{ fontSize: "15px", width: "70%" }}>{subject}</Typography>
            </Box>
        </SectionCard>
    );
};

export default CoachingFormUserDetails;
