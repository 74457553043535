import { Grid } from "@mui/material";
import { Options } from "material-table";
import React from "react";
import GroupedHeaderTable, {
	GroupedHeaderTableProps,
} from "../../../../components/GroupedHeaderTable/GroupedHeaderTable";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_SurveyPeriod } from "../../../Surveys/Surveys.types";
import { getPerceptionSummary } from "./PerceptionSummary.api";
import { t_PerceptionSummaryData } from "./PerceptionSummary.types";

interface Props<RowData extends object> {
	survey: "mbr" | "fcr";
	report: "survey" | "appeal" | "lsa";
	period: t_SurveyPeriod;
	month: string;
	columns: GroupedHeaderTableProps<RowData>["columns"];
}

function PerceptionSummary<RowData extends object>(
	props: React.PropsWithChildren<Props<RowData>>
) {
	const [summaryData, setSummaryData] = React.useState<
		t_PerceptionSummaryData<RowData>
	>({
		survey: [],
		service_team: [],
		team: [],
		job_title: [],
	});

	const TableOptions: Options<RowData> = {
		padding: "dense",
		toolbar: false,
	};

	React.useEffect(() => {
		let isActive = true;
		const { report, survey, period } = props;
		if (report != null && survey != null && period != null) {
			getPerceptionSummary<RowData>({ report, survey, period }).then(
				(data) => {
					if (isActive) {
						setSummaryData(data);
					}
				}
			);
		}

		return () => {
			isActive = false;
		};
	}, [props.period, props.report, props.survey]);

	return (
		<Grid container spacing={2} style={{ paddingBottom: 16 }}>
			<Grid item sm={12}>
				<SectionCard
					title={"Service Team"}
					titleProps={{ variant: "subtitle2" }}
					fullWidth
					hasBackground
				>
					<GroupedHeaderTable
						columns={[
							{
								header: "Service Team",
								field: "service_team",
							},
							...props.columns,
						]}
						data={summaryData.service_team}
						options={{
							...TableOptions,
						}}
					/>
				</SectionCard>
			</Grid>
			<Grid item sm={12}>
				<SectionCard
					title={"Team"}
					titleProps={{ variant: "subtitle2" }}
					fullWidth
					hasBackground
				>
					<GroupedHeaderTable
						columns={[
							{
								header: "Team",
								field: "team",
							},
							...props.columns,
						]}
						data={summaryData.team}
						options={{
							...TableOptions,
						}}
					/>
				</SectionCard>
			</Grid>
			<Grid item sm={12}>
				<SectionCard
					title={"Job Title"}
					titleProps={{ variant: "subtitle2" }}
					fullWidth
					hasBackground
				>
					<GroupedHeaderTable
						columns={[
							{
								header: "Job Title",
								field: "job_title",
							},
							...props.columns,
						]}
						data={summaryData.job_title}
						options={{
							...TableOptions,
						}}
					/>
				</SectionCard>
			</Grid>
		</Grid>
	);
}

export default PerceptionSummary;
