import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const manageAccessStyles = makeStyles((theme: Theme) =>
	createStyles({
		splitContainer: {
			height: "100%",
			display: "grid",
			gridAutoFlow: "column",
			gridTemplateColumns: "1fr auto 1fr",
			gap: theme.spacing(1),
		},
		input: {
			width: 300,
		},
	})
);

export default manageAccessStyles;
