import { Box, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Bar, Cell, ComposedChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import useOvation from "./useOvation";

type OvationsReceivedProps = {
    month: string;
    survey: "FCR" | "MBR";
    agent_entity_no?: string;
    ovationType: "positive" | "negative";
};

const OvationsReceived = ({ month, survey, agent_entity_no, ovationType }: OvationsReceivedProps) => {
    const theme = useTheme();
    const [ovationTrendData, setOvationTrendData] = useState<DiscoveryBeYou.OvationTrend[] | []>([]);
    const { getOvationTrend, getOvationNegativeTrend, isLoading } = useOvation();
    const ascendingData = useMemo(() => [...ovationTrendData]?.reverse(), [ovationTrendData, ovationType]);

    const handleOvationTrend = async () => {
        const response = await getOvationTrend({ month, survey, agent_entity_no });

        if (response?.success) {
            setOvationTrendData(response.data);
        }
    };

    const handleOvationNegativeTrend = async () => {
        const response = await getOvationNegativeTrend({ month, survey, agent_entity_no });

        if (response?.success) {
            setOvationTrendData(response.data);
        }
    };

    useEffect(() => {
        if (ovationType === "positive") {
            handleOvationTrend();
        } else {
            handleOvationNegativeTrend();
        }
    }, [month, survey, agent_entity_no, ovationType]);

    return (
        <ResponsiveContainer width="100%" height={400}>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <ComposedChart
                    width={500}
                    height={400}
                    data={ascendingData}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                >
                    <XAxis dataKey="month" stroke={theme.palette.primary.contrastText} />
                    <YAxis stroke={theme.palette.primary.contrastText} allowDecimals={false} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <defs>
                        {ascendingData?.map((trend: any, index: any) => {
                            return (
                                <linearGradient
                                    id={`insights${index}`}
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                    key={`insights-${index}`}
                                >
                                    <stop offset="5%" stopColor={"#0086FC"} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={"#98cfff"} stopOpacity={0.3} />
                                </linearGradient>
                            );
                        })}
                    </defs>
                    <Bar dataKey="score" fill="#0086FC" barSize={10} radius={10} isAnimationActive={false}>
                        {ascendingData?.map((trend: any, index: any) => (
                            <Cell fill={`url(#insights${index})`} key={`insights-cell-${index}`} />
                        ))}
                        <LabelList dataKey="score" position="top" />
                    </Bar>
                </ComposedChart>
            )}
        </ResponsiveContainer>
    );
};

export default OvationsReceived;
