import { Close } from "@mui/icons-material";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getImageUrl, stringAvatar } from "../../misc/helpers";

const OvationQuotesModal = ({ ovation, closeModal }: any) => {
    const theme = useTheme();
    return (
        <Box sx={{ maxHeight: 350, padding: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }}>
                <Close onClick={() => closeModal(false)} />
            </Box>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "30%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",

                            borderRadius: 2,
                            height: "75%",
                        }}
                    >
                        <Box
                            sx={{
                                filter: "blur(8px)",
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                            }}
                        />
                        <Avatar
                            style={{ width: 200, height: 200, fontSize: 100 }}
                            src={getImageUrl("profile", ovation.profile_img)}
                            {...stringAvatar(ovation.name)}
                        />
                    </Box>
                    <Typography sx={{ marginTop: 5, textAlign: "center", fontWeight: "bold", fontSize: 20 }}>
                        {ovation.name}
                    </Typography>
                </Box>
                <Box sx={{ padding: 2 }}>
                    <Swiper
                        direction={"vertical"}
                        // navigation
                        loop={true}
                        modules={[Navigation, Pagination, Autoplay]}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        spaceBetween={10}
                        style={{ maxHeight: 250 }}
                        className="swiper-container-vertical"
                    >
                        {ovation.ovations.map((quote: any, index: number) => (
                            <SwiperSlide>
                                <Box key={`${quote.ResponseID}-${index}`}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            marginBottom: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography color="GrayText" variant="caption">
                                            {index + 1}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            marginBottom: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography color="GrayText" sx={{ fontWeight: "bold", fontStyle: "italic" }}>
                                            Quote:
                                        </Typography>
                                        <Typography color="GrayText" variant="caption">
                                            {quote.CallDateAnswered}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="text-textColor">
                                            <span style={{ color: theme.palette.grey[500], fontSize: 25 }}>"</span>
                                            {quote.Comments}
                                            <span style={{ color: theme.palette.grey[500], fontSize: 25 }}> "</span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="caption" color="GrayText">
                                        Survey Type: <span style={{ fontWeight: "bold" }}>{quote.SurveyType}</span>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="GrayText">
                                        Survey Score: <span style={{ fontWeight: "bold" }}>{quote.SurveyScore}</span>
                                    </Typography>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            </Box>
        </Box>
    );
};

export default OvationQuotesModal;
