import { Badge, Box, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Bot from "../../beYou_bot.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setThreadUpdate } from "../../redux/slices/ThreadSlice";

const ThreadName = ({ thread, threadCount, activeChat, setActiveChat }: any) => {
    const user = useSelector((state: RootState) => state.user.userInfo);
    const classes = contactsStyles();
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [isMessageRead, setIsMessageRead] = useState(false);
    const dispatch = useDispatch();
    const count = threadCount?.filter((tc: any) => tc.ThreadID === thread.id) || [0];
    const threadName =
        thread.Name === user.name
            ? thread.participants
                  .filter((name: any) => name.DisplayName !== thread.Name)
                  .map((participant: any) => participant.DisplayName)
                  .join(", ")
            : thread.Name;
    useEffect(() => {
        let messageCount = 0;

        if (count[0]?.ThreadID === thread.id) {
            messageCount = count[0]?.Count || 0;
        }

        setUnreadMessages(Number(unreadMessages) + Number(messageCount));
    }, [threadCount]);

    return (
        <Box
            key={thread.id}
            className={`${classes.contactsTile} ${activeChat === thread.id ? classes.activeContact : ""}`}
            onClick={() => {
                setActiveChat(thread.id);
                setUnreadMessages(0);
                dispatch(setThreadUpdate({}));
            }}
        >
            <Badge badgeContent={isMessageRead ? 0 : unreadMessages} color="info">
                <img src={Bot} className={classes.avatar} alt="Avatar" />
            </Badge>

            <Typography style={{ fontWeight: "bold" }}>{threadName}</Typography>

            {thread.Count && thread.Count > 0 ? (
                <Chip label={`${thread.Count}`} size="medium" className={classes.messagesFromCount} />
            ) : null}
        </Box>
    );
};

const contactsStyles = makeStyles(() => ({
    contactsTile: {
        width: "100%",
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        borderTop: " 1px solid rgb(240, 240, 240)",
        borderBottom: " 1px solid rgb(240, 240, 240)",
        cursor: "pointer",
    },
    activeContact: {
        background: "rgb(240, 253, 137)",
        transistion: "all 0.5s ease-in-out",
        borderRadius: "25px",
    },
    avatar: {
        width: 50,
        height: 50,
        objectFit: "contain",
    },
    messagesFromCount: {
        borderRadius: "50%",
        background: "rgba(0,0,0,0.12)",
        textAlign: "center",
        alignSelf: "flex-end",
        width: 40,
        height: 40,
    },
}));

export default ThreadName;
