import { ArrowDropDown, ArrowLeft } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as OvationIcon } from "../../navigation/links/ovation.svg";
import { useGetAvatarItemsQuery } from "../../redux/api/beYouCore";
import { setUserInfo } from "../../redux/slices/UserSlice";
import { RootState } from "../../redux/store";
import { ClothesOption } from "./avatar/clothes/Clothes";
import { ClotheColorOption } from "./avatar/clothes/Colors";
import { EyebrowOption } from "./avatar/face/eyebrow/Eyebrow";
import { EyesOption } from "./avatar/face/eyes/Eyes";
import { MouthOption } from "./avatar/face/mouth/Mouth";
import { SkinOption } from "./avatar/Skin";
import { AccessoriesOption } from "./avatar/top/accessories/Accessories";
import { FacialHairOption } from "./avatar/top/facialHair/FacialHair";
import { FacialHairColorOption } from "./avatar/top/facialHair/FacialHairColors";
import { HairColorOptionType } from "./avatar/top/HairColor";
import { HatColorOptionType } from "./avatar/top/HatColor";
import { TopOption } from "./avatar/top/Top";
import MiniBox from "./MiniBox";
import {
    background,
    clotheColorOptions,
    customiseAvatarData,
    CustomizationOption,
    facialHairColorOptions,
    hairColorOptions,
    hatColorOptions,
    iconMapping,
} from "./OvationAvatarData";
import useOvation from "./useOvation";

type Props = {
    backgroundColor: string;
    setAccessories: (text: AccessoriesOption) => void;
    setClothes: (text: ClothesOption) => void;
    setEyes: (text: EyesOption) => void;
    setEyeBrows: (text: EyebrowOption) => void;
    setMouth: (text: MouthOption) => void;
    setSkin: (text: SkinOption) => void;
    setTop: (text: TopOption) => void;
    setFacialHair: (text: FacialHairOption) => void;
    setClothesColors: (text: ClotheColorOption) => void;
    setFacialHairColors: (text: FacialHairColorOption) => void;
    setHatColors: (text: HatColorOptionType) => void;
    setHairColors: (text: HairColorOptionType) => void;
    setBackgroundColor: (text: string) => void;
    customisedAvatarInfo: DiscoveryBeYou.BeYouAvatar;
    numberOfOvations: number;
    defaultSettings: () => void;
    handleSaveAsHtml: any;
};

const CustomiseAvatar = ({
    setAccessories,
    setClothes,
    setEyes,
    setEyeBrows,
    setMouth,
    setSkin,
    setTop,
    setClothesColors,
    setFacialHair,
    setFacialHairColors,
    setHairColors,
    setHatColors,
    setBackgroundColor,
    backgroundColor,
    customisedAvatarInfo,
    numberOfOvations,
    defaultSettings,
    handleSaveAsHtml,
}: Props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElColor, setAnchorElColor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const openColor = Boolean(anchorElColor);
    const [selectedAvatarData, setSelectedAvatarData] = useState<CustomizationOption | null>(null);
    const [miniBoxDisplayArray, setMiniBoxDisplayArray] = useState<CustomizationOption[] | []>([]);
    const [colors, setColors] = useState<
        HairColorOptionType[] | FacialHairColorOption[] | HatColorOptionType[] | ClotheColorOption[]
    >([]);
    const [avatarSelectedItem, setAvatarSelectedItem] = useState("top");
    const [avatarSvg, setAvatarSvg] = useState("");
    const [saveAvatarModal, setSaveAvatarModal] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.userInfo);

    const {
        data: avatarItemData,
        isLoading: avatarItemLoading,
        isError: avatarItemError,
        refetch,
    } = useGetAvatarItemsQuery(avatarSelectedItem, {
        skip: !avatarSelectedItem,
    });

    const { saveAvatar, saveAvatarImage } = useOvation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickColor = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElColor(event.currentTarget);
    };

    const handleCloseColor = () => {
        setAnchorElColor(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectedAvatarData = (avatarData: any) => {
        setSelectedAvatarData(avatarData);
        setAnchorEl(null);
    };

    const handleSaveAvatar = async (saveAsProfile: boolean) => {
        const updatedUserInfo = { ...user };
        if (saveAsProfile) {
            handleSaveAsHtml(setAvatarSvg);
        }
        try {
            const response = await saveAvatar({ avatar: customisedAvatarInfo, is_profile: saveAsProfile });

            if (response?.success) {
                if (avatarSvg.length > 0) {
                    const response = await saveAvatarImage({ svg: avatarSvg });

                    if (response?.success && saveAsProfile) {
                        updatedUserInfo.profile_img = response.data;
                        dispatch(setUserInfo(updatedUserInfo));
                    } else {
                        dispatch(setUserInfo(user.profile_img));
                    }
                }
            }
        } catch (error) {
            console.error(error, "Error");
        } finally {
            setSaveAvatarModal(false);
        }
    };

    const handleSelectedColor = (color: string) => {
        if (!selectedAvatarData) return;

        switch (selectedAvatarData.type) {
            case "hair":
            case "top":
                setHairColors(color as HairColorOptionType);
                setAnchorElColor(null);
                break;
            case "facialhair":
                setFacialHairColors(color as FacialHairColorOption);
                setAnchorElColor(null);
                break;
            case "hat":
                setHatColors(color as HatColorOptionType);
                setAnchorElColor(null);
                break;
            case "shirt":
            case "clothes":
                setClothesColors(color as ClotheColorOption);
                setAnchorElColor(null);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!selectedAvatarData) {
            setMiniBoxDisplayArray(customiseAvatarData);
            return;
        }
        setAvatarSelectedItem(selectedAvatarData.type?.toLocaleLowerCase());

        if (avatarSelectedItem === "background") {
            setMiniBoxDisplayArray(background);
            return;
        }

        if (avatarSelectedItem === "hair") {
            setAvatarSelectedItem("top");
            refetch();
        }

        if (!avatarItemLoading && !avatarItemError && avatarItemData) {
            setMiniBoxDisplayArray(avatarItemData?.data.items);

            switch (selectedAvatarData.type) {
                case "hair":
                case "top":
                    setColors(hairColorOptions);

                    break;
                case "facialhair":
                    setColors(facialHairColorOptions);

                    break;
                case "hat":
                    setColors(hatColorOptions);

                    break;
                case "shirt":
                case "clothes":
                    setColors(clotheColorOptions);
                    break;
                default:
                    break;
            }
        }
    }, [selectedAvatarData, avatarItemData, avatarSelectedItem, miniBoxDisplayArray]);

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h6">Customize your Avatar</Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 2,
                        background: theme.palette.primary.main,
                        borderRadius: 2,
                        boxShadow: "10px 10px 18px #6E6E6E29",
                        width: 100,
                    }}
                >
                    <Typography>{numberOfOvations}</Typography>
                    <OvationIcon />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: selectedAvatarData ? "space-between" : "end",
                    width: "100%",
                    my: 4,
                }}
            >
                {selectedAvatarData && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            startIcon={<ArrowLeft sx={{ color: theme.palette.info.main, fontSize: 2 }} />}
                            onClick={() => setSelectedAvatarData(null)}
                        >
                            Back
                        </Button>
                        <Box sx={{ ml: 2 }}>
                            <Button
                                id="demo-customized-button"
                                aria-controls={open ? "demo-customized-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleClick}
                                endIcon={<ArrowDropDown />}
                                sx={{
                                    backgroundColor: theme.palette.info.main,
                                    color: theme.palette.info.contrastText,
                                    p: 1,
                                }}
                            >
                                {selectedAvatarData ? selectedAvatarData.title : null}
                            </Button>
                            <Menu
                                elevation={0}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                            >
                                {customiseAvatarData.map((avatarData: any, index: number) => (
                                    <MenuItem
                                        key={`${avatarData.id}-${index}`}
                                        onClick={() => handleSelectedAvatarData(avatarData)}
                                    >
                                        {avatarData.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {(selectedAvatarData.type === "shirt" ||
                            selectedAvatarData.type === "top" ||
                            selectedAvatarData.type === "facialhair" ||
                            selectedAvatarData.type === "hat") && (
                            <Box sx={{ ml: 2 }}>
                                <Button
                                    id="demo-customized-button-color"
                                    aria-controls={openColor ? "demo-customized-menu-color" : undefined}
                                    aria-expanded={openColor ? "true" : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClickColor}
                                    endIcon={<ArrowDropDown />}
                                    sx={{
                                        backgroundColor: theme.palette.info.main,
                                        color: theme.palette.info.contrastText,
                                        p: 1,
                                    }}
                                >
                                    Color
                                </Button>
                                <Menu
                                    elevation={0}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={openColor}
                                    anchorEl={anchorElColor}
                                    onClose={handleCloseColor}
                                >
                                    {colors.map((color: string, index: number) => (
                                        <MenuItem key={`${color}-${index}`} onClick={() => handleSelectedColor(color)}>
                                            {color}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        )}
                    </Box>
                )}
                {!selectedAvatarData && numberOfOvations >= 2 && (
                    <Typography>You have {numberOfOvations} Ovations! You can unlock a new accessory</Typography>
                )}
            </Box>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 5,
                    background: theme.palette.primary.main,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 5,
                        overflowY: "scroll",
                        height: "40vh",
                        p: 3,
                    }}
                >
                    {miniBoxDisplayArray.map((data: CustomizationOption, index: number) => {
                        const IconComponent = data?.icon ? iconMapping[data?.icon] : "";

                        return (
                            <MiniBox
                                key={`${data.id}-index${index}`}
                                icon={IconComponent}
                                title={data.title}
                                id={data.id}
                                type={data.type}
                                color={data.color}
                                isAdmin={data.isAdmin}
                                unlocked={data.unlocked}
                                handleSelectedAvatarData={handleSelectedAvatarData}
                                setAccessories={setAccessories}
                                setClothes={setClothes}
                                setEyeBrows={setEyeBrows}
                                setEyes={setEyes}
                                setMouth={setMouth}
                                setSkin={setSkin}
                                setTop={setTop}
                                setFacialHair={setFacialHair}
                                setBackgroundColor={setBackgroundColor}
                                backgroundColor={backgroundColor}
                                numberOfOvations={numberOfOvations}
                            />
                        );
                    })}
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", width: "100%", my: 4 }}>
                <Button variant="outlined" onClick={defaultSettings}>
                    Cancel Changes
                </Button>
                <Button variant="contained" sx={{ ml: 3 }} onClick={() => setSaveAvatarModal(!saveAvatarModal)}>
                    Save Avatar
                </Button>
            </Box>
            <Dialog open={saveAvatarModal} onClose={() => setSaveAvatarModal(false)}>
                <DialogTitle>{"Set Avatar As Profile Picture "}</DialogTitle>
                <DialogContent>
                    <DialogContentText>would you like to set this avatar as your profile picture?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleSaveAvatar(false)}>No</Button>
                    <Button onClick={() => handleSaveAvatar(true)} variant="contained">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomiseAvatar;
