type t_AppealCategory = {
	category_id: number;
	name: string;
};
type t_AppealRemovalLevel = {
	level_id: number;
	name: string;
};
type t_AppealForm = {
	appeal_id: number | null;
	category: t_AppealCategory | null;
	level: t_AppealRemovalLevel | null;
	transfer_to?: string;
	comment: string | null;
};

type t_AppealStepperActions =
	| {
			type: "appeal-created";
			appeal_id: number;
	  }
	| {
			type: "appeal-updated";
	  };

const base_AppealForm: t_AppealForm = {
	appeal_id: null,
	category: null,
	level: null,
	transfer_to: "",
	comment: "",
};

export { base_AppealForm };
export type {
	t_AppealForm,
	t_AppealCategory,
	t_AppealRemovalLevel,
	t_AppealStepperActions,
};
