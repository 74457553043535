import React from "react";
import Home from "./pages/Home/Home";
import Providers from "./providers";

function App() {
	return (
		<Providers>
			<Home />
		</Providers>
	);
}

export default App;
