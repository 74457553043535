import React from "react";

type callback = () => void;

type t_SetStateCallback<T> = (
	value: T | ((prev: T) => T),
	cb?: callback
) => void;

const empty = () => {};

function useStateCallback<T>(
	initialState: T
): [state: T, setState: t_SetStateCallback<T>] {
	const [state, setState] = React.useState<T>(initialState);
	const cbRef = React.useRef<callback>(); // mutable ref to store current callback

	const setStateCallback: t_SetStateCallback<T> = React.useCallback(
		(value, cb = empty) => {
			cbRef.current = cb; // store passed callback to ref
			setState(value);
		},
		[]
	);

	React.useEffect(() => {
		// cb.current is `null` on initial render, so we only execute cb on state *updates*
		if (cbRef.current !== null) {
			const cb = cbRef.current ?? empty;
			cb();
			cbRef.current = undefined; // reset callback after execution
		}
	}, [state]);

	return [state, setStateCallback];
}

export { useStateCallback };
