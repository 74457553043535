import { Close } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, Typography } from "@mui/material";
import { useState } from "react";
import { Icons, toast } from "react-toastify";

import FriendButton from "../../../sections/User/components/FriendButton/FriendButton";
import { FriendRequestProps } from "./NotificationTypes";

const FriendRequest = ({ request, removeNotification, setSidePanelChatBot }: FriendRequestProps) => {
    const [openFriendDialog, setOpenFriendDialog] = useState(false);

    return (
        <Box key={request?.id}>
            <Dialog open={openFriendDialog} maxWidth="md" onClose={() => setOpenFriendDialog(false)}>
                <Card style={{ padding: 30 }}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <Close onClick={() => setOpenFriendDialog(false)} style={{ cursor: "pointer" }} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" gutterBottom>
                            Friend Request
                        </Typography>{" "}
                    </Box>
                    <CardHeader
                        title={request.user.name}
                        subheader={request.user.job_title}
                        avatar={<Avatar src={request.user.profile_img} />}
                    />
                    <CardContent>
                        {/* <div className={classes.friendDiv}> */}
                        <FriendButton entity_no={request.user.entity_no} />
                        {/* </div> */}
                    </CardContent>
                </Card>
            </Dialog>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
                onClick={() => {
                    setOpenFriendDialog(true);
                    setSidePanelChatBot(false);
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {" "}
                    {request?.user?.profile_img ? (
                        <Avatar src={request?.user?.profile_img} />
                    ) : (
                        Icons.info({
                            theme: "light",
                            type: toast.TYPE.INFO,
                            width: 30,
                            height: 30,
                        })
                    )}
                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        Friend Request
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {request?.user?.name}
                            {/* <Typography    component="span"
                                variant="body2" color="textSecondary" style={{ fontSize: 10, maxWidth: "90%" }}>
                                <TimeTracker createdAt={request?.date} />
                            </Typography> */}
                        </Typography>
                        {/* <ItemActions
                    notifications={request.id}
                    markAsRead={markAsRead}
                    remove={remove}
                /> */}
                        <Button
                            onClick={() => {
                                removeNotification(request.id);
                            }}
                        >
                            <Close color="error" fontSize="small" />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FriendRequest;
