import React from "react";
import SectionCard from "../../../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../../../components/TabGroup/TabGroup";
import { t_SurveyPeriod } from "../../../../../Surveys/Surveys.types";
import PerceptionSummary from "../../../../tables/PerceptionSummary/PerceptionSummary";
import { AppealSummaryColumns } from "../../AppealReport.types";

type Props = {
	periods: {
		[key: string]: t_SurveyPeriod;
	};
	month: string;
};
const AppealSummary: React.FC<Props> = ({ periods, month }) => {
	const [tab, setTab] = React.useState(0);

	return (
		<SectionCard
			title='Survey Appeals'
			titleProps={{ variant: "h6" }}
			primaryAction={
				<TabGroup
					tabValue={tab}
					tabChange={(value) => setTab(value)}
					tabList={[
						{
							_id: 0,
							label: "MBR",
						},
						{
							_id: 0,
							label: "FCR",
						},
					]}
				/>
			}
		>
			{tab === 0 && (
				<PerceptionSummary
					survey='mbr'
					report='appeal'
					period={periods["mbr"]}
					month={month}
					columns={AppealSummaryColumns}
				/>
			)}
			{tab === 1 && (
				<PerceptionSummary
					survey='fcr'
					report='appeal'
					period={periods["fcr"]}
					month={month}
					columns={AppealSummaryColumns}
				/>
			)}
		</SectionCard>
	);
};
export default AppealSummary;
