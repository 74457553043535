import {
	Dialog,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { sectionCardInnerStyles } from "../../../../components/SectionCard/SectionCard.style";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { getPermissions, getRoles, updateRole } from "../../Access.api";
import { t_Permission, t_Role } from "../../Access.types";
import MaintainPermission from "../MaintainPermission/MaintainPermission";
import MaintainRole from "../MaintainRole/MaintainRole";

const MaintainAccess: React.FC = () => {
	const [tab, setTab] = React.useState(0);
	const [items, setItems] = React.useState<(t_Role | t_Permission)[]>([]);
	const [activeItem, setActiveItem] = React.useState<t_Role | t_Permission>();
	const sectionInnerClasses = sectionCardInnerStyles();

	const [newRoleState, setNewRoleState] = React.useState<{
		open: boolean;
		id?: number;
		name: string;
		slug: string;
	}>({
		open: false,
		name: "",
		slug: "",
	});

	const handleAddRole = () => {
		setNewRoleState({
			open: true,
			name: "",
			slug: "",
		});
	};
	const handleEditRole = (role: t_Role) => {
		setNewRoleState({
			open: true,
			...role,
		});
	};

	const handleUpdateRole = () => {
		updateRole({
			...newRoleState,
		}).finally(() => {
			setTab(0);
		});
	};

	React.useEffect(() => {
		var isActive = true;
		setItems([]);
		setActiveItem(undefined);
		var request = null;
		if (tab === 0) {
			request = getRoles();
		}
		if (tab == 1) {
			request = getPermissions();
		}
		if (request != null) {
			request
				.then((data) => {
					if (isActive) {
						setItems(data);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
		return () => {
			isActive = false;
		};
	}, [tab]);

	return <>
        <SectionCard
            title='Maintain Access'
            primaryAction={
                <TabGroup
                    tabValue={tab}
                    tabChange={(value) => setTab(value)}
                    tabList={[
                        { _id: 0, label: "Roles" },
                        { _id: 1, label: "Permissions" },
                    ]}
                />
            }
        >
            <Grid
                container
                spacing={2}
                className={sectionInnerClasses.gridContainer}
            >
                <Grid item sm={4} className={sectionInnerClasses.gridItem}>
                    <SectionCard
                        title={tab === 0 ? "Roles" : "Permissions"}
                        titleProps={{ variant: "h6" }}
                        hasBackground
                        scrolling
                        secondaryAction={
                            tab === 0 && (
                                <IconButton onClick={handleAddRole} size="large">
                                    <Add />
                                </IconButton>
                            )
                        }
                    >
                        <List>
                            {items.map((item) => (
                                <ListItem
                                    key={`${
                                        tab === 0 ? "role" : "permission"
                                    }-${item.id}`}
                                    button
                                    selected={activeItem?.id === item.id}
                                    onClick={() =>
                                        setActiveItem(
                                            activeItem?.id === item.id
                                                ? undefined
                                                : item
                                        )
                                    }
                                >
                                    <ListItemText
                                        primary={item.name}
                                        secondary={item.slug}
                                    />
                                    {tab === 0 && (
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() =>
                                                    handleEditRole(item)
                                                }
                                                size="large">
                                                <Edit />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </SectionCard>
                </Grid>
                <Grid item sm={8} className={sectionInnerClasses.gridItem}>
                    {tab === 0 && activeItem != null && (
                        <MaintainRole role={activeItem} />
                    )}
                    {tab === 1 && activeItem != null && (
                        <MaintainPermission permission={activeItem} />
                    )}
                </Grid>
            </Grid>
        </SectionCard>
        <Dialog
            open={newRoleState.open}
            onClose={() =>
                setNewRoleState({
                    open: false,
                    name: "",
                    slug: "",
                })
            }
            style={{
                padding: 8,
            }}
        >
            <List>
                <ListItem>
                    <TextField
                        label='Name'
                        value={newRoleState.name}
                        onChange={(e) =>
                            setNewRoleState((state) => ({
                                ...state,
                                name: e.currentTarget.value,
                            }))
                        }
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label='Slug'
                        value={newRoleState.slug}
                        onChange={(e) =>
                            setNewRoleState((state) => ({
                                ...state,
                                slug: e.currentTarget.value,
                            }))
                        }
                    />
                </ListItem>
                <ListItem>
                    <SubmitButton onClick={handleUpdateRole}>
                        Save Role
                    </SubmitButton>
                </ListItem>
            </List>
        </Dialog>
    </>;
};

export default MaintainAccess;
