import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const profileBioStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "grid",
		},
		textCard: {},
	})
);

export default profileBioStyles;
