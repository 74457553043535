import { httpClient } from "../../clients/http.client";

const getLsaRecord = async (payload: { lsa_id: number }) => {
	const response = await httpClient.post("/lsa", payload);
	return response.data.data.lsa;
};

const confirmLowScoreAnalysis = (payload: { lsa_id: number }) =>
	new Promise((resolve, reject) => {
		httpClient
			.post("/lsa/confirm", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
						break;
				}
			})
			.catch(reject);
	});

export { getLsaRecord, confirmLowScoreAnalysis };

