import { MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { deleteSurveyPeriods, getSurveyPeriods, getSurveyPeriodTypes, saveSurveyPeriods } from "./SurveyPeriods.api";
import { t_SurveyPeriod, t_SurveyPeriodTypes } from "./SurveyPeriods.types";

type Props = {
    year?: Date;
};

const SurveyPeriods: React.FC<Props> = ({ year }) => {
    const tableRef = React.createRef<any>();
    const [surveyPeriodTypes, setSurveyPeriodTypes] = React.useState<t_SurveyPeriodTypes[]>([]);

    React.useEffect(() => {
        tableRef.current != null && tableRef.current.onQueryChange();
    }, [year]);

    React.useEffect(() => {
        getSurveyPeriodTypes().then((types) => {
            setSurveyPeriodTypes(types);
        });
    }, []);
    return (
        <BUMaterialTable
            tableRef={tableRef}
            title="Survey Periods"
            data={(query) => getSurveyPeriods({ query, year: year?.toDateString() })}
            columns={
                [
                    {
                        title: "Survey Type",
                        field: "survey_type",
                        render: (rowData) => rowData.survey_type?.name,
                        editComponent: (props) => (
                            <TextField
                                {...props}
                                select
                                value={props.value?.id}
                                onChange={(e) =>
                                    props.onChange(surveyPeriodTypes.find((type) => type.id === e.target.value))
                                }
                                helperText="Select survey type."
                            >
                                {surveyPeriodTypes.map((survey_type) => (
                                    <MenuItem value={survey_type.id}>{survey_type.name}</MenuItem>
                                ))}
                            </TextField>
                        ),
                    },
                    {
                        title: "Start Date",
                        field: "start_date",
                        editComponent: (props) => (
                            <DatePicker
                                {...props}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY-MM-DD",
                                        variant: "standard",
                                    },
                                }}
                            />
                        ),
                        initialEditValue: new Date(),
                    },
                    {
                        title: "End Date",
                        field: "end_date",
                        editComponent: (props) => (
                            <DatePicker
                                {...props}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY-MM-DD",
                                        variant: "standard",
                                    },
                                }}
                            />
                        ),
                        initialEditValue: new Date(),
                    },
                    {
                        title: "Period Month",
                        field: "month",
                        editComponent: (props) => (
                            <DatePicker
                                {...props}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY-MM-DD",
                                        variant: "standard",
                                    },
                                }}
                                onChange={(date) => {
                                    if (date != null) {
                                        var newDate = date;
                                        newDate.setDate(1);
                                        props.onChange(newDate);
                                    }
                                }}
                            />
                        ),
                        initialEditValue: new Date(),
                    },
                    {
                        title: "Appeal Cutoff",
                        field: "appeal_cutoff",
                        editComponent: (props) => (
                            <DatePicker
                                {...props}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY-MM-DD",
                                        variant: "standard",
                                    },
                                }}
                            />
                        ),
                        initialEditValue: new Date(),
                    },
                    {
                        title: "Last Capture",
                        field: "last_capture",
                        editComponent: (props) => (
                            <DatePicker
                                {...props}
                                format="yyyy-MM-dd"
                                slotProps={{
                                    textField: {
                                        helperText: "YYYY-MM-DD",
                                        variant: "standard",
                                    },
                                }}
                            />
                        ),
                        initialEditValue: new Date(),
                    },
                ] as Column<t_SurveyPeriod>[]
            }
            editable={{
                onRowAdd: (newRow) => saveSurveyPeriods({ period: newRow }),
                onRowUpdate: (newRow) => saveSurveyPeriods({ period: newRow }),
                onRowDelete: (oldRow) => deleteSurveyPeriods({ period_id: oldRow.id }),
            }}
            options={{
                search: false,
                actionsColumnIndex: -1,
            }}
        />
    );
};

export default SurveyPeriods;
