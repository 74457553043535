import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_SurveyPeriodArr } from "../../../Surveys/Surveys.types";

type Props = {
	periods: { [key: string]: t_SurveyPeriodArr };
};
const SurveyPeriodsMulti: React.FC<Props> = ({ periods }) => {
	return (
		<div>
		<SectionCard
			title='Survey Period'
			titleProps={{ variant: "subtitle1" }}
			fullWidth
		>
			<TableContainer component={Paper}>
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Survey Type</TableCell>
							<TableCell>Start Date</TableCell>
							<TableCell>End Date</TableCell>
							<TableCell>Last Capture</TableCell>
							<TableCell>Appeal Cut-Off</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.keys(periods).map((key: string) => (
							periods[key].map((period, index) => (
								<TableRow key={index}>
									<TableCell>{key.toUpperCase()}</TableCell>
									<TableCell>{period.start_date}</TableCell>
									<TableCell>{period.end_date}</TableCell>
									<TableCell>
										{period.last_capture}
									</TableCell>
									<TableCell>
										{period.appeal_cutoff}
									</TableCell>
								</TableRow>
							))
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</SectionCard>
		<div>
			<form>

			</form>
		</div>
		</div>

	);
};
export default SurveyPeriodsMulti;
