import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import { useReportExport } from "../../../../hooks/export/useReportExport";
import { formatApiDate, getApiUrl } from "../../../../misc/helpers";
import { t_SurveyPeriod } from "../../../Surveys/Surveys.types";
import { getSurveyPeriods } from "../../PerceptionReport.api";
import SurveyPeriods from "../../tables/SurveyPeriods/SurveyPeriods";
import TeamLeaderSummary from "../../tables/TeamLeaderSummary/TeamLeaderSummary";
import { WithAppealsColumns } from "../../tables/TeamLeaderSummary/TeamLeaderSummary.api";
import AppealCategoryGraph from "./sections/AppealCategoryGraph/AppealCategoryGraph";
import AppealSummary from "./sections/AppealSummary/AppealSummary";
import PendingAppeals from "./tables/PendingAppeals/PendingAppeals";

const AppealReport: React.FC = () => {
    const { loading: exportLoading, downloadExportFile } = useReportExport();
    const [tab, setTab] = React.useState(0);
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date(Date.now()));
    const [periods, setPeriods] = React.useState<{
        [key: string]: t_SurveyPeriod;
    }>({});

    const handleDateChange = (date: any) => {
        if (date != null) {
            setSelectedDate(date);
        }
    };
    const handleExport = (file_type: string) => {
        const appeal_url = new URL(`${getApiUrl()}/reports/appeal/export`);

        appeal_url.searchParams.append("month", selectedDate.toDateString());
        appeal_url.searchParams.append("file_type", file_type);

        downloadExportFile(appeal_url.toString(), "Appeal Export");
    };
    React.useEffect(() => {
        if (selectedDate !== null) {
            getSurveyPeriods({
                for_month: formatApiDate(selectedDate),
                surveys: ["mbr", "fcr"],
            }).then((data) => {
                setPeriods(data);
            });
        }
    }, [selectedDate]);

    return (
        <SectionCard
            title="Appeal Report"
            primaryAction={
                <TabGroup
                    tabValue={tab}
                    tabChange={(value) => setTab(value)}
                    tabList={[
                        {
                            _id: 0,
                            label: "Summary",
                        },
                        {
                            _id: 1,
                            label: "Detailed",
                        },
                        {
                            _id: 2,
                            label: "Pending",
                        },
                    ]}
                />
            }
            secondaryAction={
                <DatePicker
                    openTo="month"
                    views={["year", "month"]}
                    value={selectedDate}
                    onChange={handleDateChange}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
            fullWidth
        >
            <Grid container spacing={1}>
                {tab === 0 && (
                    <>
                        <Grid item sm={8}>
                            <SurveyPeriods periods={periods} />
                        </Grid>
                        <Grid item sm={6}>
                            <SectionCard
                                title="MBR Appeal Category"
                                titleProps={{ variant: "subtitle2" }}
                                // fullWidth
                                hasBackground
                            >
                                <AppealCategoryGraph survey="mbr" period={periods["mbr"]} />
                            </SectionCard>
                        </Grid>
                        <Grid item sm={6}>
                            <SectionCard
                                title="FCR Appeal Category"
                                titleProps={{ variant: "subtitle2" }}
                                // fullWidth
                                hasBackground
                            >
                                <AppealCategoryGraph survey="fcr" period={periods["fcr"]} />
                            </SectionCard>
                        </Grid>
                        <Grid item sm={12}>
                            <AppealSummary periods={periods} month={selectedDate?.toDateString()} />
                        </Grid>
                    </>
                )}
                {tab === 1 && (
                    <Grid item sm={12}>
                        <TeamLeaderSummary
                            periods={periods}
                            report="appeal"
                            columns={WithAppealsColumns}
                            sectionCardProps={{
                                secondaryAction: (
                                    <ExportButton
                                        dispatch={handleExport}
                                        loading={exportLoading}
                                        disabled={exportLoading}
                                    >
                                        Export
                                    </ExportButton>
                                ),
                            }}
                        />
                    </Grid>
                )}
                {tab === 2 && (
                    <Grid item sm={12}>
                        <PendingAppeals month={selectedDate.toDateString()} />
                    </Grid>
                )}
            </Grid>
        </SectionCard>
    );
};

export default AppealReport;
