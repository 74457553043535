import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const lsaRecordStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		formControl: {
			width: 300,
		},
	})
);

export default lsaRecordStyles;
