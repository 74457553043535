import React from "react";
import ProfileBadges from "../../../Profile/sections/ProfileBadges/ProfileBadges";
import ProfileBio from "../../../Profile/sections/ProfileBio/ProfileBio";
import ProfileStats from "../../../Profile/sections/ProfileStats/ProfileStats";
import fullProfileStyles from "./FullProfile.styles";
import { Grid } from "@mui/material";

interface Props {
    entity_no: number;
    editable?: boolean;
}

const FullProfile: React.FC<Props> = ({ entity_no, editable = false }) => {
    const classes = fullProfileStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <ProfileBio entity_no={entity_no} editable={editable} />
            </Grid>
            <Grid item xs={6} maxHeight={500}>
                <ProfileStats entity_no={entity_no} />
            </Grid>
            <Grid item xs={12}>
                <ProfileBadges
                    entity_no={entity_no}
                    sectionCardProps={{
                        title: "Badges",
                        hasBackground: true,
                    }}
                    withPagination
                />
            </Grid>
        </Grid>
    );
};

export default FullProfile;
