import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

import MovingText from "./MovingText";

const OvationLandingPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const variants = {
        flyIn: () => ({
            opacity: 1,
            transition: { duration: 0.5, ease: "easeOut" },
        }),
        bobbing: () => ({
            bottom: ["0px", "20px", "0px"],
            transition: { duration: 5, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
        twisting: () => ({
            transform: ["rotate(0deg)", "rotate(5deg)", "rotate(0deg)"],
            transition: { duration: 7, repeat: Infinity, ease: "easeInOut", delay: 0.5 },
        }),
    };

    return (
        <Box
            sx={{
                backgroundColor: "#0093E9",
                backgroundImage: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
                padding: isMobile ? 2 : 5,
                borderRadius: 3,
            }}
        >
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100vh",
                        }}
                    >
                        <MovingText
                            text={["Ovation", "Report"]}
                            className="text-4xl"
                            repeatDelay={10000}
                            animation={{
                                hidden: { opacity: 0, y: 20 },
                                visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.1 } },
                            }}
                        />

                        <div
                            style={{
                                background: theme.palette.secondary.main,
                                height: 1,
                                width: isMobile ? "80%" : "50%",
                                marginBottom: 20,
                            }}
                        />
                        <Typography sx={{ color: theme.palette.secondary.main, textAlign: "center" }}>
                            Click the Report dropdown to select a report
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: isMobile ? "50vh" : "100vh",
                        }}
                    >
                        <AnimatePresence>
                            <motion.img
                                src="/images/report.png"
                                style={{ width: isMobile ? "100%" : "80%" }}
                                initial={{ opacity: 0 }}
                                animate={["flyIn", "bobbing", "twisting"]}
                                variants={variants}
                            />
                            <motion.div
                                style={{
                                    marginTop: 10,
                                    width: isMobile ? "80%" : "60%",
                                    height: 10,
                                    background: "rgba(0,0,0,0.5)",
                                    filter: "blur(8px)",
                                }}
                                initial={{ opacity: 0 }}
                                animate={["flyIn", "bobbing", "twisting"]}
                                variants={variants}
                            />
                        </AnimatePresence>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OvationLandingPage;
