import { uniqueId } from "lodash";
import { useMemo } from "react";

import Skin, { SkinOption } from "./Skin";
import Clothes, { ClothesOption } from "./clothes/Clothes";
import { ClotheColorOption } from "./clothes/Colors";
import Face from "./face/Face";
import { EyebrowOption } from "./face/eyebrow/Eyebrow";
import { EyesOption } from "./face/eyes/Eyes";
import { MouthOption } from "./face/mouth/Mouth";
import { HairColorOptionType } from "./top/HairColor";
import { HatColorOptionType } from "./top/HatColor";
import Top, { TopOption } from "./top/Top";
import Accessories, { AccessoriesOption } from "./top/accessories/Accessories";
import FacialHair, { FacialHairOption } from "./top/facialHair/FacialHair";
import { FacialHairColorOption } from "./top/facialHair/FacialHairColors";

export interface Props {
    clothesOption: ClothesOption;
    topOption: TopOption;
    skinOption: SkinOption;
    accessoriesOption: AccessoriesOption;
    eyesOption: EyesOption;
    mouthOption: MouthOption;
    eyebrowOption: EyebrowOption;
    facialHair: FacialHairOption;
    clothesColors: ClotheColorOption;
    facialHairColors: FacialHairColorOption;
    hatColors: HatColorOptionType;
    hairColors: HairColorOptionType;
}

const Avatar = ({
    clothesOption,
    topOption,
    skinOption,
    accessoriesOption,
    eyesOption,
    mouthOption,
    eyebrowOption,
    facialHair,
    clothesColors,
    facialHairColors,
    hatColors,
    hairColors,
}: Props) => {
    const path1 = useMemo(() => uniqueId("react-path-"), []);
    const path2 = useMemo(() => uniqueId("react-path-"), []);
    const path3 = useMemo(() => uniqueId("react-path-"), []);
    const mask1 = useMemo(() => uniqueId("react-mask-"), []);
    const mask2 = useMemo(() => uniqueId("react-mask-"), []);
    const mask3 = useMemo(() => uniqueId("react-mask-"), []);

    return (
        <svg
            // width="568px"
            // height="602px"
            width="100%"
            height="100%"
            viewBox="0 0 264 280"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <circle id={path1} cx="120" cy="120" r="120" />
                <path
                    d="M12,160 C12,226.27417 65.72583,280 132,280 C198.27417,280 252,226.27417 252,160 L264,160 L264,-1.42108547e-14 L-3.19744231e-14,-1.42108547e-14 L-3.19744231e-14,160 L12,160 Z"
                    id={path2}
                />
                <path
                    d="M124,144.610951 L124,163 L128,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 L100,0 L100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z"
                    id={path3}
                />
            </defs>
            <g id="Avataaar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-825.000000, -1100.000000)" id="Avataaar/Circle">
                    <g transform="translate(825.000000, 1100.000000)">
                        <g id="Mask" />
                        <g id="Avataaar" strokeWidth="1" fillRule="evenodd" mask={`url(#${mask2})`}>
                            <g id="Body" transform="translate(32.000000, 36.000000)">
                                <mask id={mask3} fill="white">
                                    <use xlinkHref={`#${path3}`} />
                                </mask>
                                <use fill="#D0C6AC" xlinkHref={`#${path3}`} />
                                <Skin maskID={mask3} option={skinOption} />
                                <path
                                    d="M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z"
                                    id="Neck-Shadow"
                                    fillOpacity="0.100000001"
                                    fill="#000000"
                                    mask={`url(#${mask3})`}
                                />
                            </g>
                            <Clothes option={clothesOption} clothesColor={clothesColors} />
                            <Face eyesOption={eyesOption} mouthOption={mouthOption} eyebrowOption={eyebrowOption} />
                            <Top option={topOption} hatColor={hatColors} hairColor={hairColors}>
                                <Accessories option={accessoriesOption} />
                                <FacialHair option={facialHair} color={facialHairColors} />
                            </Top>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Avatar;
