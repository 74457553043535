import {
    Box,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import MaterialTable from "material-table";

const TwtMetricsHeader = () => {
    return (
        <TableHead
            style={{
                height: 30,
                top: 0,
                zIndex: 10,
                position: "sticky",
                backgroundColor: "#eee",
                fontSize: 12,
            }}
        >
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                ></TableCell>
                <TableCell
                    align="center"
                    colSpan={8}
                    width="200px"
                    style={{ borderRight: "1px solid #ccc" }}
                >
                    Daily Totals
                </TableCell>
                <TableCell align="center" colSpan={8} width="200px">
                    MTD Totals
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                ></TableCell>
                <TableCell
                    align="center"
                    colSpan={3}
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Overall TWT Score
                </TableCell>
                <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Process In
                </TableCell>
                <TableCell
                    align="center"
                    colSpan={2}
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Pending Inquiries
                </TableCell>
                <TableCell
                    align="center"
                    colSpan={2}
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Closed Inquires
                </TableCell>

                <TableCell
                    align="center"
                    colSpan={3}
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Overall TWT Score
                </TableCell>
                <TableCell
                    align="center"
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Process In
                </TableCell>
                <TableCell
                    align="center"
                    colSpan={2}
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Pending Inquiries
                </TableCell>
                <TableCell align="center" colSpan={2} sortDirection={false}>
                    Closed Inquiries
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sortDirection="asc">Agent</TableCell>
                <TableCell sortDirection={false}>Pool Name</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Measure
                </TableCell>
                <TableCell sortDirection={false}>% In TWT</TableCell>
                <TableCell sortDirection={false}>Total</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    In TWT
                </TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Effort
                </TableCell>
                <TableCell>Total</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    In TWT
                </TableCell>
                <TableCell sortDirection={false}>Total</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    In TWT
                </TableCell>
                <TableCell sortDirection={false}>% In TWT</TableCell>
                <TableCell sortDirection={false}>Total</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    In TWT
                </TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    Effort
                </TableCell>
                <TableCell sortDirection={false}>Total</TableCell>
                <TableCell
                    style={{ borderRight: "1px solid #ccc" }}
                    sortDirection={false}
                >
                    In TWT
                </TableCell>
                <TableCell sortDirection={false}>Total</TableCell>
                <TableCell sortDirection={false}>In TWT</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TwtMetricsHeader;
