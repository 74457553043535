import React from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_Response } from "../MultiDateGraphs/ProductionReportGraph.types";
import { getProductionGraphData } from "../MultiDateGraphs/ProductionReportGraphMulti.api";
import { t_ActualProductionByServiceTeam } from "./AverageActualProduction.types";

interface Props {
    dates: string[];
}
const AverageActualProduction: React.FC<Props> = ({ dates }) => {
    const [chartColumns, setChartColumns] = React.useState<string[]>();
    const [chartData, setChartData] = React.useState<t_ActualProductionByServiceTeam[]>([]);

    React.useEffect(() => {
        let isActive = true;
        if (dates) {
            getProductionGraphData<t_Response>({ dates }, "actual_prod").then((response) => {
                if (isActive) {
                    setChartColumns(response.months);
                    setChartData(response.data);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [dates?.length]);

    return (
        <SectionCard title="Average Actual Production" titleProps={{ variant: "h6" }} hasBackground>
            <ResponsiveContainer height={400}>
                <ComposedChart data={chartData}>
                    <XAxis dataKey="service_team" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid />
                    {chartColumns?.map((column, index) => (
                        <Bar dataKey={column} fill="#8884d8" key={index} />
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        </SectionCard>
    );
};

export default AverageActualProduction;
