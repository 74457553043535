import { Box, Dialog, Typography } from "@mui/material";
import { Column } from "material-table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { t_TeamMember, t_TeamMemberPool, t_TeamPool } from "../../TWT/TwtGroup.types";
import { t_PoolColumn } from "../Production.types";
import NewPoolGroup from "../../TWT/sections/NewPoolGroup/NewPoolGroup";
import { getTeamMemberPools, getTeamMembers, addPoolToTeam, removeTeamPool } from "../../TWT/twtTeams.api";
import { useStyles } from "./ProductionPoolWeight.styles";
import { useUserState } from "../../../context/user/user.ctx";
import TeamGroupPool from "../../TWT/sections/TeamGroupPool";
import { Add } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { format } from "date-fns";
import { getWeightedPoolsSearch } from "../../Production/tables/PoolWeights/PoolWeights.api";
import DeleteTeamPool from "../../TWT/sections/DeleteTeamPool";
import { deleteProductionTeamPool, getProductionTeamPools, saveProductionTeamPool } from "./ProductPoolWeight.api";
import ProductionPoolGroup from "../sections/ProductionPoolGroup";

const ProductionPoolWeight = () => {
    const [dialogState, setDialogState] = React.useState<{
        open: boolean;
        row?: t_TeamPool;
        userEntity?: number;
    }>({
        open: false,
    });
    const [editDialogState, setEditDialogState] = React.useState<{
        open: boolean;
        row?: t_PoolColumn;
        userEntity?: number;
    }>({ open: false });
    const [deleteDialogState, setDeleteDialogState] = React.useState<{
        open: boolean;
        data?: any;
    }>({
        open: false,
    });
    const [teamMemberList, setTeamMemberList] = React.useState<t_TeamMember[]>([]);
    const [poolState, setPoolState] = React.useState<"team pool" | "individual pool" | "no pool">("no pool");
    const [submitState, setSubmitState] = React.useState<"not submitted" | "pending" | "success" | "error">(
        "not submitted",
    );
    const [teamColumns] = React.useState<Column<any>[]>([
        {
            title: "Pool Number",
            field: "pool_number",
            editable: "never",
        },
        {
            title: "Pool Name",
            field: "pool_name",
            editable: "never",
        },
        {
            title: "Handling Time",
            field: "handling_time",
            editable: "always",
            validate: (rowData) => {
                let num = Number(rowData.handling_time ?? 0);
                if (num == null || num < 0 || num > 60) {
                    return "The entered time must be between 0-60 min.";
                }
                return true;
            },
        },
        {
            title: "Weight",
            field: "weight",
            editable: "never",
        },
    ]);
    const [memberColumns] = React.useState<Column<any>[]>([
        {
            title: "ID",
            field: "id",
            editable: "never",
        },
        {
            title: "User Name",
            field: "user_name",
            editable: "never",
        },
        {
            title: "Pool Number",
            field: "pool_number",
            editable: "never",
        },
        {
            title: "Pool Name",
            field: "pool_name",
            editable: "never",
        },
        {
            title: "Handling Time",
            field: "handling_time",
            editable: "always",
            validate: (rowData) => {
                let num = Number(rowData.handling_time ?? 0);
                if (num == null || num < 0 || num > 60) {
                    return "The entered time must be between 0-60 min.";
                }
                return true;
            },
        },
        {
            title: "Weight",
            field: "weight",
            editable: "never",
        },
    ]);
    const [memberListColumns] = React.useState<Column<any>[]>([
        {
            title: "Entity No",
            field: "entity_no",
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Job Title",
            field: "job_title",
        },
    ]);
    const styles = useStyles();
    const getTeamList = () => {
        getTeamMembers({ entity_no: user.entity_no })
            .then((resp) => {
                setTeamMemberList(resp);
            })
            .catch((err) => console.error(err));
    };
    const addTeamPool = (poolNumber: number, userEntity?: number) => {
        const today = format(new Date(), "yyyy-MM-dd");
        const payload = userEntity
            ? {
                  pool_numbers: [poolNumber],
                  entity_no: userEntity,
                  date_from: today,
              }
            : {
                  pool_numbers: [poolNumber],
                  date_from: today,
              };
        addPoolToTeam(payload)
            .then(() => {
                getTeamList();
                table1ref.current && table1ref.current.onQueryChange();
                userEntity && setDialogState({ open: false });
            })
            .catch((err) => console.error(err));
    };
    const deleteTeamPool = (poolNumber: number, entityNo?: number) => {
        const payload = entityNo ? { pool_numbers: [poolNumber], entity_no: entityNo } : { pool_numbers: [poolNumber] };
        removeTeamPool(payload)
            .then(() => {
                table1ref.current && table1ref.current.onQueryChange();
                setDeleteDialogState({ open: false });
                entityNo && table3ref.current && table3ref.current.onQueryChange();
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const removePoolFromTeam = (data: any) => {
        deleteTeamPool(data.PoolID);
    };

    React.useEffect(() => {
        getTeamList();
    }, []);
    const user = useUserState();
    const table1ref = React.useRef<any>();
    const table2ref = React.useRef<any>();
    const table3ref = React.useRef<any>();

    return (
        <>
            <div className={styles.teamPoolsContainer}>
                <SectionCard title={"Maintain Production Pool"} fullWidth>
                    <div className={styles.tablesContainer}>
                        <BUMaterialTable
                            title={"Pool Groups"}
                            columns={teamColumns}
                            tableRef={table1ref}
                            data={(query) => {
                                return getProductionTeamPools({
                                    ...query,
                                    pagination: {
                                        page: query.page + 1,
                                        rows_per_page: query.pageSize,
                                    },
                                    filter: [...query.filters],
                                    sort: query.orderBy
                                        ? {
                                              column_name: query.orderBy?.field || "",
                                              order: query.orderDirection,
                                          }
                                        : "",
                                });
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    saveProductionTeamPool({
                                        pool: {
                                            ...newData,
                                            team_id: oldData.pool_id,
                                        },
                                    }),
                                onRowDelete: (oldData) =>
                                    deleteProductionTeamPool({
                                        pool: { ...oldData },
                                    }),
                            }}
                            options={{
                                search: false,
                                sorting: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10],
                                actionsColumnIndex: -1,
                            }}
                            actions={[
                                {
                                    isFreeAction: true,
                                    icon: () => (
                                        <div style={{ display: "flex", gap: ".5em" }}>
                                            <Typography>Add Team Pool</Typography>
                                            <AddCircleIcon />
                                        </div>
                                    ),
                                    onClick: () => {
                                        setDialogState({ open: true });
                                        setPoolState("team pool");
                                    },
                                },
                            ]}
                        />
                        <BUMaterialTable
                            title={"Member Pools"}
                            columns={memberListColumns}
                            data={teamMemberList}
                            tableRef={table2ref}
                            detailPanel={(rowData) => {
                                return (
                                    <div className={styles.secondaryTable}>
                                        <BUMaterialTable
                                            title={rowData.name}
                                            columns={memberColumns}
                                            tableRef={table3ref}
                                            data={(query) => {
                                                return getProductionTeamPools({
                                                    ...query,
                                                    pagination: {
                                                        page: query.page + 1,
                                                        rows_per_page: query.pageSize,
                                                    },
                                                    filter: [...query.filters],
                                                    sort: query.orderBy
                                                        ? {
                                                              column_name: query.orderBy?.field || "",
                                                              order: query.orderDirection,
                                                          }
                                                        : "",
                                                    entity_no: Number(rowData.entity_no),
                                                });
                                            }}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    saveProductionTeamPool({
                                                        pool: {
                                                            ...newData,
                                                            entity_no: rowData.entity_no,
                                                        },
                                                    }),
                                                onRowDelete: (oldData) =>
                                                    deleteProductionTeamPool({
                                                        pool: {
                                                            ...oldData,
                                                            entity_no: rowData.entity_no,
                                                        },
                                                    }),
                                            }}
                                            options={{
                                                search: false,
                                                filtering: false,
                                                showTitle: false,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10],
                                                actionsColumnIndex: -1,
                                            }}
                                            actions={[
                                                {
                                                    isFreeAction: true,
                                                    icon: () => (
                                                        <div style={{ display: "flex", gap: ".5em" }}>
                                                            <Typography>Add Member Pool</Typography>
                                                            <AddCircleIcon />
                                                        </div>
                                                    ),
                                                    onClick: () => {
                                                        setDialogState({
                                                            open: true,
                                                            userEntity: rowData.entity_no,
                                                        });
                                                        setPoolState("individual pool");
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </SectionCard>
            </div>
            <Dialog
                open={dialogState.open}
                onClose={() => {
                    setDialogState({ open: false });
                }}
                scroll="paper"
            >
                <ProductionPoolGroup
                    handleClose={() => {
                        setDialogState({ open: false });

                        switch (poolState) {
                            case "team pool":
                                table1ref.current.onQueryChange();
                                break;
                            case "individual pool":
                                table3ref.current.onQueryChange();
                                break;
                            default:
                                break;
                        }
                    }}
                    addTeamPool={addTeamPool}
                    userEntityNo={dialogState.userEntity}
                />
            </Dialog>
            <Dialog open={deleteDialogState.open} onClose={() => setDeleteDialogState({ open: false })} scroll="paper">
                {deleteDialogState.data && (
                    <DeleteTeamPool
                        onClose={() => setDeleteDialogState({ open: false })}
                        deleteAction={deleteTeamPool}
                        submitState={submitState}
                        data={deleteDialogState.data}
                    />
                )}
            </Dialog>
        </>
    );
};

export default ProductionPoolWeight;
