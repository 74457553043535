import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_PublicHoliday } from "./PublicHolidays.types";

const getPublicHolidays = (payload: {
	query: Query<t_PublicHoliday>;
	year: string;
}) => {
	return new Promise<QueryResult<t_PublicHoliday>>((resolve, reject) => {
		const query = convertQuery(payload.query);
		var body = {
			...query,
			year: payload.year,
		};

		if (payload.query.search != null) {
			body.filter = [
				...query.filter,
				{
					column_name: "HolidayName",
					search_term: payload.query.search,
				},
			];
		}

		httpClient
			.post("/production/public_holidays", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve({
							data: data.data.public_holidays,
							page: data.pagination.page - 1,
							totalCount: data.total_results,
						});
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const updatePublicHoliday = (payload: { holiday: t_PublicHoliday }) => {
	return new Promise((resolve, reject) => {
		httpClient
			.post("/production/public_holidays/save", payload.holiday)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const deletePublicHoliday = (payload: {
	holiday_id: t_PublicHoliday["id"];
}) => {
	return new Promise((resolve, reject) => {
		httpClient
			.post("/production/public_holidays/delete", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export { getPublicHolidays, updatePublicHoliday, deletePublicHoliday };
