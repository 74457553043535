import React, { useEffect, useState } from "react";
import { getProductionTeamPools } from "../../ProductionPoolWeight/ProductPoolWeight.api";
import { Box, Button, Checkbox, Chip, Divider, ListItemText, MenuItem, Typography } from "@mui/material";
import { useUserState } from "../../../../context/user/user.ctx";
import {
    useExcludePoolsMutation,
    useGetExcludedPoolsQuery,
    useGetIncludedPoolsQuery,
    useIncludePoolsMutation,
} from "../../../../redux/api/beYouCore";
import { useSnackbar } from "notistack";
import { getUserProduction } from "../../tables/ProductionByDate/ProductionByDate.api";
import { format, lastDayOfMonth } from "date-fns";

const PoolList = ({ poolType, page, rowsPerPage, setTotalResults, date }: any) => {
    const currentUser = useUserState();
    const [selectedPools, setSelectedPools] = useState<any>([]);
    const [currentPools, setCurrentPools] = useState<any>([]);
    const [excludePools] = useExcludePoolsMutation();
    const [includePools] = useIncludePoolsMutation();
    const { data: excludedPoolsData, refetch: excludedPoolsRefetch } = useGetExcludedPoolsQuery();
    const { data: includedPoolsData, refetch: includedPoolsRefetch } = useGetIncludedPoolsQuery();
    const { enqueueSnackbar } = useSnackbar();
    const [reset, setReset] = useState(false);

    useEffect(() => {
        if (poolType == "all") {
            includedPoolsRefetch();
            setCurrentPools(includedPoolsData?.data?.pools);
        } else {
            excludedPoolsRefetch();
            setCurrentPools(excludedPoolsData?.data?.pools_excluded);
        }
    }, [poolType, excludedPoolsData, includedPoolsData, reset]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, pool: any) => {
        if (event.target.checked) {
            setSelectedPools([...selectedPools, pool]);
        } else {
            setSelectedPools(selectedPools.filter((selectedPool: any) => selectedPool.pool_id != pool.pool_id));
        }
    };

    useEffect(() => {
        setSelectedPools([]);
    }, [currentPools]);

    const handleSubmit = () => {
        const poolIdStringArray = selectedPools.map((pool: any) => pool.pool_id);
        const poolIds = poolIdStringArray.map(Number);
        setReset(false);

        if (poolType == "all") {
            excludePools({ pool_ids: poolIds })
                .unwrap()
                .then((response) => {
                    enqueueSnackbar(response.message, { variant: "success" });
                })
                .catch((error) => {
                    const failedPools = error.data.data.failed.map((pool: any) => pool.pool_id).join(", ");
                    enqueueSnackbar(error.data.message + ": " + failedPools, { variant: "error" });
                })
                .finally(() => {
                    setSelectedPools([]);
                    setCurrentPools([...currentPools]);
                    includedPoolsRefetch();
                    setReset(true);
                });
        } else {
            includePools({ pool_ids: poolIds })
                .unwrap()
                .then((response) => {
                    enqueueSnackbar(response.message, { variant: "success" });
                })
                .catch((error) => {
                    const failedPools = error.data.data.failed.map((pool: any) => pool.pool_id).join(", ");
                    enqueueSnackbar(error.data.message + ": " + failedPools, { variant: "error" });
                })
                .finally(() => {
                    setSelectedPools([]);
                    setCurrentPools([...currentPools]);
                    excludedPoolsRefetch();

                    setReset(true);
                });
        }
    };

    return (
        <Box sx={{ p: 4, mt: 2, mb: 5 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                    {selectedPools.map((pool: any) => (
                        <Chip key={pool.id} label={pool?.pool_name} sx={{ m: 1 }} />
                    ))}
                </Box>
                {selectedPools?.length > 0 && (
                    <Button variant="contained" onClick={handleSubmit}>
                        {poolType == "all" ? "Exclude Pools" : "Include Pools"}
                    </Button>
                )}
            </Box>
            {currentPools?.length > 0 &&
                currentPools?.map((pool: any) => (
                    <>
                        <MenuItem key={pool?.id} value={pool?.pool_id}>
                            <ListItemText primary={pool?.pool_name} />
                            <Checkbox
                                onChange={(event) => handleCheckboxChange(event, pool)}
                                checked={selectedPools.some(
                                    (selectedPool: any) => selectedPool.pool_id == pool.pool_id,
                                )}
                            />
                        </MenuItem>
                        <Divider />
                    </>
                ))}
            {currentPools?.length <= 0 && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Typography color="textSecondary">No pools to display</Typography>
                </Box>
            )}
        </Box>
    );
};

export default PoolList;
