export const formsList = [
    {
        id: 0,
        label: "ALL",
        type: "all",
    },
    {
        id: 1,
        label: "ADHOC",
        type: "adhoc",
    },
    {
        id: 2,
        label: "Absenteeism | Back to Work",
        type: "absence",
    },
    {
        id: 3,
        label: "OPS Losses",
        type: "ops_loss",
    },
    {
        id: 4,
        label: "DPMO",
        type: "dpmo",
    },
    {
        id: 5,
        label: "Perception",
        type: "perception",
    },
    {
        id: 6,
        label: "Grow Coaching",
        type: "grow",
    },
    {
        id: 7,
        label: "PIP | Initiation",
        type: "pip_initiation",
    },
    {
        id: 8,
        label: "PIP | Follow-Up",
        type: "pip_follow_up",
    },
    {
        id: 9,
        label: "Written Warning",
        type: "written_warning",
    },
    {
        id: 10,
        label: "Expectations",
        type: "expectations",
    },
    {
        id: 11,
        label: "Handover",
        type: "handover",
    },
];
