import { httpClient } from "../../../../clients/http.client";
import { t_ProductionTreeRecord } from "./ProductionTree.types";

const getProductionTreeData = (payload: { month: string }) => {
	return new Promise<t_ProductionTreeRecord[]>((resolve, reject) => {
		var date = new Date(payload.month),
			y = date.getFullYear(),
			m = date.getMonth();
		var start_date = new Date(y, m, 1);
		var end_date = new Date(y, m + 1, 0);

		let body = {
			start_date,
			end_date,
		};

		httpClient.post("/reports/production/tree", body).then(({ data }) => {
			switch (data.status) {
				case "success":
					resolve(data.data.flat_tree);
					break;

				default:
					reject(data.message);
					break;
			}
		});
	});
};

const getProductionTreeDataMulti = (payload: { months: string[] }) => {
  return new Promise<t_ProductionTreeRecord[]>((resolve, reject) => {
    const body = {
      months: payload.months,
    };

    httpClient.post("/reports/production/tree", body).then(({ data }) => {
      switch (data.status) {
        case "success":
          resolve(data.data.flat_tree);
          break;

        default:
          reject(data.message);
          break;
      }
    });
  });
};

export { getProductionTreeData, getProductionTreeDataMulti };
