import React, { useEffect, useState, useContext } from "react";
import SectionCard from "../SectionCard/SectionCard";
import TabGroup, { t_Tab } from "../TabGroup/TabGroup";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import notificationsStyles from "./Notifications.styles";
import { useUserState } from "../../context/user/user.ctx";
import Notifications from "./Notifications";
import TabScrollButton from "@mui/material/TabScrollButton";
import withStyles from "@mui/styles/withStyles";
import { NotificationsContext } from "../Toasts/NotificationsProvider.ctx";

const SideBarNotification = ({ setSidePanelChatBot }: any) => {
    const [sidePanelOverlay, setSidePanelOverlay] = useState<boolean>(false);
    const [tabList, setTabList] = React.useState<t_Tab[]>([
        {
            label: "Notifications",
            _id: 1,
        },
        {
            label: "Weekly Insights",
            _id: 2,
        },
        {
            label: "Metric Ranking",
            _id: 3,
        },
        {
            label: "Performance Reflection",
            _id: 4,
        },
    ]);
    const classes = notificationsStyles();
    const { currentStepSideBar, sideBarTab, setSideBarTab, isOnBoardingOpenSideBar, setIsOnBoardingOpenSideBar } =
        useContext(NotificationsContext);
    const [tabValue, setTabValue] = React.useState<number>(0);
    const [loading, setLoading] = useState(Boolean);
    const tabChange = (newValue: number) => {
        setTabValue(newValue);
    };
    const [value, setValue] = React.useState(0);

    const currentUser = useUserState();
    useEffect(() => {}, [currentUser.entity_no]);

    const MyTabScrollButton = withStyles((theme) => ({
        root: {
            width: 28,
            overflow: "hidden",
            transition: "width 0.5s",
            "&.Mui-disabled": {
                width: 0,
            },
        },
    }))(TabScrollButton);
    return (
        <SectionCard title="BeYou Coach" fullWidth>
            <Tabs
                value={sideBarTab}
                onChange={(_, value) => setSideBarTab(value)}
                ScrollButtonComponent={MyTabScrollButton}
                variant="scrollable"
                className={currentStepSideBar === 1 ? classes.tabs : ""}
                scrollButtons="auto"
                aria-label="notifications section"
                style={{ padding: 16 }}
            >
                <Tab label="Notifications" />
                <Tab label="Performance Reflection" />
                <Tab label="Tips" />
            </Tabs>

            {loading ? (
                <CircularProgress />
            ) : (
                <Notifications
                    currentTab={sideBarTab}
                    entity_no={currentUser?.entity_no}
                    setSidePanelChatBot={setSidePanelChatBot}
                />
            )}
        </SectionCard>
    );
};

export default SideBarNotification;
