import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const helpFileStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			width: "100%",
		},
		flex: {
			display: "flex",
		},
		expand: {
			transform: "rotate(0deg)",
			marginLeft: "auto",
			transition: theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: "rotate(180deg)",
		},
	})
);

export default helpFileStyles;
