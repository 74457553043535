import { Box, Chip, Fade, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";
import { t_Permission } from "../Access/Access.types";

import "react-circular-progressbar/dist/styles.css";
import useWindowDimensions from "../../misc/screenSizes";

interface BreakDownCardProps {
    metric: {
        id: number;
        name: string;
        value: number;
        target: number;
        percentage_of_target: number;
        percentage_change: number;
    };
    bgStrokeColor?: string;
    strokeColor?: {
        strokeColorOne?: string;
        strokeColorTwo?: string;
    };
    loading?: boolean;
    insightsPermissions: boolean | t_Permission;
}

const BreakDownCard: React.FC<BreakDownCardProps> = ({ metric, strokeColor, insightsPermissions }) => {
    const { width: screenWidth } = useWindowDimensions();

    const [isHovering, setIsHovering] = useState(false);
    const theme = useTheme();

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const user = useSelector((state: RootState) => state.user.userInfo);
    const arrow =
        metric.id == 1 || metric.id == 2 || metric.id == 6
            ? metric.percentage_change >= 0
                ? "up"
                : "down"
            : metric.percentage_change > 0
              ? "up"
              : "down";

    const navigate = useNavigate();

    const selectStrokeColor =
        strokeColor?.strokeColorOne && strokeColor.strokeColorTwo
            ? metric.percentage_of_target >= 100
                ? strokeColor?.strokeColorOne
                : metric.percentage_of_target >= 80 && metric.percentage_of_target < 100
                  ? "#FFAC1C"
                  : strokeColor?.strokeColorTwo
            : strokeColor?.strokeColorOne
              ? strokeColor?.strokeColorOne
              : strokeColor?.strokeColorTwo;

    const chipColor =
        strokeColor?.strokeColorOne && strokeColor.strokeColorTwo
            ? arrow === "up"
                ? strokeColor?.strokeColorOne
                : strokeColor?.strokeColorTwo
            : strokeColor?.strokeColorOne
              ? strokeColor?.strokeColorOne
              : strokeColor?.strokeColorTwo;

    const tooltipText = (
        <Box sx={{ p: 1, minWidth: "150px" }}>
            <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                {metric?.name?.toUpperCase()}
            </Typography>
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography>Score</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{metric?.value}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography>Target</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{metric?.target}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography>Percentage </Typography>
                <Typography sx={{ fontWeight: "bold" }}>{metric?.percentage_of_target}%</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: 2 }}>
                <Chip
                    label={`${metric.percentage_change || 0}%*`}
                    avatar={
                        <img
                            src={`${arrow === "up" ? "/images/upArrow.png" : "/images/downArrow.png"}`}
                            style={{
                                width: ".8em",
                                height: ".8em",
                                position: "absolute",
                                top: "30%",
                                right: "15%",
                            }}
                        />
                    }
                    style={{
                        background: `${chipColor}`,
                        color: "white",
                        fontSize: ".7rem",
                        padding: "0 0.6em",
                        height: "1.1rem",
                        width: "80%",
                        position: "relative",
                    }}
                />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: 2 }}>
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                    % of change{" "}
                </Typography>
            </Box>
        </Box>
    );

    const displayedName =
        screenWidth < 1000 && metric?.name?.toLowerCase() === "beyou engagement"
            ? "ENGAGEMENT"
            : `${metric?.name?.toUpperCase().slice(0, 10)}${metric.name.length > 10 ? "..." : ""}`;

    return (
        <Box
            m={1}
            onClick={() => {
                if (insightsPermissions) {
                    const location = {
                        pathname: `/beyoucoach/insights/${metric.id}`,
                        state: {
                            entity_no: user.entity_no,
                            name: user.display_name,
                        },
                    };
                    navigate(location);
                } else {
                    return;
                }
            }}
            style={{
                cursor: insightsPermissions ? "pointer" : "default",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                backgroundColor: isHovering ? "#0000000A" : "",
                maxWidth: 150,
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                    align="center"
                    sx={{
                        fontWeight: "bold",
                        fontSize: screenWidth < 1200 ? "0.6em" : screenWidth < 1400 ? "0.8em" : "1em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                    }}
                >
                    {displayedName}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Box>
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={tooltipText}
                        placement="top"
                        arrow
                        slotProps={{
                            tooltip: {
                                sx: {
                                    color: "#514E6A",
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow:
                                        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                                    "& .MuiTooltip-arrow": {
                                        color: theme.palette.primary.main,
                                    },
                                },
                            },
                        }}
                    >
                        <Box>
                            <CircularProgressbarWithChildren
                                value={metric?.target}
                                strokeWidth={11}
                                styles={buildStyles({
                                    trailColor: "#eee",
                                    pathColor: "rgba(0, 0, 255, 0.1)",
                                })}
                            >
                                {/* Foreground path */}
                                <CircularProgressbarWithChildren
                                    value={Number(metric.percentage_of_target)}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        pathColor: selectStrokeColor,
                                        trailColor: "transparent",
                                        textColor: theme.palette.secondary.contrastText,
                                    })}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize:
                                                screenWidth < 1200 ? "0.8em" : screenWidth < 1400 ? "1em" : "1.5em",
                                        }}
                                    >
                                        {Number(metric.value)}
                                    </Typography>
                                </CircularProgressbarWithChildren>
                            </CircularProgressbarWithChildren>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                    align="center"
                    sx={{ fontSize: screenWidth < 1200 ? "0.6em" : screenWidth < 1400 ? "0.8em" : "1em" }}
                >
                    Target: {metric?.target}
                </Typography>
            </Box>
        </Box>
    );
};

export default BreakDownCard;
