import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const friendSearchStyles = makeStyles((theme: Theme) =>
	createStyles({
		pagination: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
		searchInput: {
			width: 300,
		},
	})
);

export default friendSearchStyles;
