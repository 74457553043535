import React from "react";

import Concerned from "./Concerned";
import Default from "./Default";
import Disbelief from "./Disbelief";
import Eating from "./Eating";
import Grimace from "./Grimace";
import Sad from "./Sad";
import ScreamOpen from "./ScreamOpen";
import Serious from "./Serious";
import Smile from "./Smile";
import Tongue from "./Tongue";
import Twinkle from "./Twinkle";
import Vomit from "./Vomit";

// Define a type for the mouth options
export type MouthOption =
    | "Concerned"
    | "Default"
    | "Disbelief"
    | "Eating"
    | "Grimace"
    | "Sad"
    | "ScreamOpen"
    | "Serious"
    | "Smile"
    | "Tongue"
    | "Twinkle"
    | "Vomit"
    | "default";

// Mapping object for mouth components
const mouthComponentsMap: Record<MouthOption, React.ComponentType> = {
    Concerned,
    Default,
    Disbelief,
    Eating,
    Grimace,
    Sad,
    ScreamOpen,
    Serious,
    Smile,
    Tongue,
    Twinkle,
    Vomit,
    default: Default,
};

interface MouthProps {
    option: MouthOption;
}

const Mouth: React.FC<MouthProps> = ({ option = "default" }) => {
    const SelectedMouth = mouthComponentsMap[option] || mouthComponentsMap["default"];
    return <SelectedMouth />;
};

export default Mouth;
