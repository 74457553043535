import { httpClient } from "../../clients/http.client";
import { GroupedHeaderTableProps } from "../../components/GroupedHeaderTable/GroupedHeaderTable";

const SurveySummaryColumns: GroupedHeaderTableProps<any>["columns"] = [
	{
		//@ts-ignore
		header: "Perception",
		render: (rowData) =>
			`${
				rowData.for_survey == "fcr"
					? `${rowData.score_avg * 100}%`
					: Number(rowData.score_avg).toLocaleString(undefined, {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
					  })
			} (${rowData.survey_n})`,
		columns: [
			{
				title: "Result",
				field: "score_avg",
			},
			{ title: "n", field: "survey_n" },
		],
	},
	{
		header: "Appeals",
		render: (rowData) =>
			`${Math.round(Number(rowData.approved_ratio) * 100)}% (${
				rowData.appeals_n
			})`,
		columns: [
			{
				title: "Approved",
				field: "approved_ratio",
			},
			{
				title: "n",
				field: "appeals_n",
			},
		],
	},
	{
		header: "LSA",
		render: (rowData) =>
			`${Math.round(Number(rowData.capture_ratio) * 100)}% (${
				rowData.lsa_n
			})`,
		columns: [
			{
				title: "Captured",
				field: "capture_ratio",
			},
			{ title: "n", field: "lsa_n" },
		],
	},
];

const getSurveyPeriodsMultipleMonths = async (payload: {
	for_months: Array<Date>;
	surveys: string[];
}) => {
	const response = await httpClient.post("/reports/survey/periods/multi", payload);
	return response.data.data.survey_periods;
};

const getSurveyPeriods = async (payload: {
	for_month: string | Array<Date>;
	surveys: string[];
}) => {
	const response = await httpClient.post("/reports/survey/periods", payload);
	return response.data.data.survey_periods;
};

export { SurveySummaryColumns, getSurveyPeriods, getSurveyPeriodsMultipleMonths };
