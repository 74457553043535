import { configureStore } from "@reduxjs/toolkit";

import { beYouCoreApi } from "./api/beYouCore";
import authReducer from "./slices/authSlice";
import badgesReducer from "./slices/BadgesSlice";
import beYouAvatarReducer from "./slices/BeYouAvatarSlice";
import beYouReducer from "./slices/BeYouSlice";
import coachTipsReducer from "./slices/CoachTipsSlice";
import globalLeaderBoardReducer from "./slices/GlobalLeaderBoardsSlice";
import metricsReducer from "./slices/MetricsSlice";
import summaryReducer from "./slices/SummarySlice";
import teamMembersReducer from "./slices/TeamMemberSlice";
import themeReducer from "./slices/ThemeSlice";
import threadReducer from "./slices/ThreadSlice";
import tipsReducer from "./slices/TipsSlice";
import userReducer from "./slices/UserSlice";
import viewUserReducer from "./slices/ViewUserSlice";
import walkThroughReducer from "./slices/WalkThroughSlice";

export const store: any = configureStore({
    reducer: {
        [beYouCoreApi.reducerPath]: beYouCoreApi.reducer,
        metrics: metricsReducer,
        badges: badgesReducer,
        summary: summaryReducer,
        teamMembers: teamMembersReducer,
        user: userReducer,
        view: viewUserReducer,
        coachTips: coachTipsReducer,
        auth: authReducer,
        theme: themeReducer,
        thread: threadReducer,
        walkThrough: walkThroughReducer,
        tips: tipsReducer,
        beYou: beYouReducer,
        globalLeaderBoard: globalLeaderBoardReducer,
        beYouAvatar: beYouAvatarReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([beYouCoreApi.middleware]),
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
