import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const liveCommentsStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputPaper: {
			padding: theme.spacing(0.5, 1.5),
			boxShadow: "none",
		},
		controlActions: {
			display: "flex",
			justifyContent: "flex-end",
		},
		featuredComment: {
			backgroundColor: "#EBEBEB",
			borderRadius: 10,
			width: "100%",
		},
		fullWidth: {
			width: "100%",
		},
		actionText: {
			padding: "10px 15px",
		},
		commentsContainer: {
			maxHeight: "200px",
			overflowY: "auto",
		},
		loader: {
			display: "flex",
			justifyContent: "center",
		},
	})
);

export default liveCommentsStyles;
