import { HighlightOffOutlined } from "@mui/icons-material";
import { Box, Button, Grid, useTheme } from "@mui/material";
//@ts-ignore
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore

import { setUserInfo } from "../../redux/slices/UserSlice";
import { RootState } from "../../redux/store";
import Avatar from "./avatar/Avatar";
import { ClothesOption } from "./avatar/clothes/Clothes";
import { ClotheColorOption } from "./avatar/clothes/Colors";
import { EyebrowOption } from "./avatar/face/eyebrow/Eyebrow";
import { EyesOption } from "./avatar/face/eyes/Eyes";
import { MouthOption } from "./avatar/face/mouth/Mouth";
import { SkinOption } from "./avatar/Skin";
import { AccessoriesOption } from "./avatar/top/accessories/Accessories";
import { FacialHairOption } from "./avatar/top/facialHair/FacialHair";
import { FacialHairColorOption } from "./avatar/top/facialHair/FacialHairColors";
import { HairColorOptionType } from "./avatar/top/HairColor";
import { HatColorOptionType } from "./avatar/top/HatColor";
import { TopOption } from "./avatar/top/Top";
import CustomiseAvatar from "./CustomiseAvatar";
import useOvation from "./useOvation";

const OvationAvatar = () => {
    const [accessories, setAccessories] = useState<AccessoriesOption>("default");
    const [clothes, setClothes] = useState<ClothesOption>("default");
    const [eyes, setEyes] = useState<EyesOption>("default");
    const [eyeBrows, setEyeBrows] = useState<EyebrowOption>("default");
    const [mouth, setMouth] = useState<MouthOption>("default");
    const [skin, setSkin] = useState<SkinOption>("default");
    const [top, setTop] = useState<TopOption>("default");
    const [facialHair, setFacialHair] = useState<FacialHairOption>("default");
    const [clothesColors, setClothesColors] = useState<ClotheColorOption>("default");
    const [facialHairColors, setFacialHairColors] = useState<FacialHairColorOption>("default");
    const [hatColors, setHatColors] = useState<HatColorOptionType>("default");
    const [hairColors, setHairColors] = useState<HairColorOptionType>("default");
    const [backgroundColor, setBackgroundColor] = useState("#E2E6CA");
    const avatarRef = useRef<SVGSVGElement>(null);
    const theme = useTheme();
    const user = useSelector((state: RootState) => state.user.userInfo);
    const customisedAvatar: DiscoveryBeYou.BeYouAvatar = {
        accessoriesOption: accessories,
        clothesOption: clothes,
        eyesOption: eyes,
        eyebrowOption: eyeBrows,
        mouthOption: mouth,
        skinOption: skin,
        topOption: top,
        facialHair: facialHair,
        clothesColors: clothesColors,
        facialHairColors: facialHairColors,
        hatColors: hatColors,
        hairColors: hairColors,
        backgroundColor,
    };
    const userAvatar = useSelector((state: RootState) => state.beYouAvatar.beYouAvatar);
    const { saveAvatar } = useOvation();
    const isAvatarProfilePicture = Boolean(Number(userAvatar?.is_profile));
    const dispatch = useDispatch();

    const handleSaveAsHtml = (setHtml: (text: string) => void) => {
        if (avatarRef.current) {
            const avatarHtml = avatarRef.current.outerHTML;
            setHtml(avatarHtml);
        }
    };

    const handleRemoveAvatarProfilePicture = async () => {
        try {
            const response = await saveAvatar({ is_profile: false });

            if (response?.success) {
                dispatch(setUserInfo(user.profile_img));
            }
        } catch (error) {
            console.error(error, "Error");
        }
    };

    useEffect(() => {
        if (userAvatar.avatar) {
            setAccessories(userAvatar.avatar.accessoriesOption as AccessoriesOption);
            setClothes(userAvatar.avatar.clothesOption as ClothesOption);
            setEyeBrows(userAvatar.avatar.eyebrowOption as EyebrowOption);
            setEyes(userAvatar.avatar.eyesOption as EyesOption);
            setMouth(userAvatar.avatar.mouthOption as MouthOption);
            setSkin(userAvatar.avatar.skinOption as SkinOption);
            setTop(userAvatar.avatar.topOption as TopOption);
            setFacialHair(userAvatar.avatar.facialHair as FacialHairOption);
            setClothesColors(userAvatar.avatar.clothesColors as ClotheColorOption);
            setFacialHairColors(userAvatar.avatar.facialHairColors as FacialHairColorOption);
            setHatColors(userAvatar.avatar.hatColors as HatColorOptionType);
            setHairColors(userAvatar.avatar.hairColors as HairColorOptionType);
            setBackgroundColor(userAvatar.avatar.backgroundColor);
        } else {
            defaultSettings();
        }
    }, []);

    const defaultSettings = () => {
        setAccessories((userAvatar?.avatar.accessoriesOption as AccessoriesOption) || "default");
        setClothes((userAvatar?.avatar.clothesOption as ClothesOption) || "default");
        setEyeBrows((userAvatar?.avatar.eyebrowOption as EyebrowOption) || "default");
        setEyes((userAvatar?.avatar.eyesOption as EyesOption) || "default");
        setMouth((userAvatar?.avatar.mouthOption as MouthOption) || "default");
        setSkin((userAvatar?.avatar.skinOption as SkinOption) || "default");
        setTop((userAvatar?.avatar.topOption as TopOption) || "default");
        setFacialHair((userAvatar?.avatar.facialHair as FacialHairOption) || "default");
        setClothesColors((userAvatar?.avatar.clothesColors as ClotheColorOption) || "default");
        setFacialHairColors((userAvatar?.avatar.facialHairColors as FacialHairColorOption) || "default");
        setHatColors((userAvatar?.avatar.hatColors as HatColorOptionType) || "default");
        setHairColors((userAvatar?.avatar.hairColors as HairColorOptionType) || "default");
        setBackgroundColor(userAvatar?.avatar.backgroundColor || "#E2E6CA");
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} sx={{ p: 5, mt: 4 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center ",
                        height: "100%",
                        width: "100%",
                        p: 5,
                    }}
                >
                    {isAvatarProfilePicture && (
                        <Button
                            sx={{
                                border: `1px solid ${theme.palette.secondary.contrastText}`,
                                borderRadius: 50,
                                mb: 2,
                            }}
                            onClick={handleRemoveAvatarProfilePicture}
                        >
                            <HighlightOffOutlined sx={{ color: theme.palette.info.main, mr: 1 }} /> Disable Avatar As
                            Profile Picture
                        </Button>
                    )}

                    <svg width="100%" height="100%" ref={avatarRef} style={{ borderRadius: 50 }}>
                        <rect width="100%" height="100%" fill={backgroundColor} />
                        <Avatar
                            accessoriesOption={accessories}
                            clothesOption={clothes}
                            eyebrowOption={eyeBrows}
                            eyesOption={eyes}
                            mouthOption={mouth}
                            skinOption={skin}
                            topOption={top}
                            facialHair={facialHair}
                            clothesColors={clothesColors}
                            facialHairColors={facialHairColors}
                            hatColors={hatColors}
                            hairColors={hairColors}
                        />
                    </svg>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ p: 5, mt: 4 }}>
                <CustomiseAvatar
                    backgroundColor={backgroundColor}
                    setAccessories={setAccessories}
                    setClothes={setClothes}
                    setEyeBrows={setEyeBrows}
                    setEyes={setEyes}
                    setMouth={setMouth}
                    setSkin={setSkin}
                    setTop={setTop}
                    setFacialHair={setFacialHair}
                    setClothesColors={setClothesColors}
                    setFacialHairColors={setFacialHairColors}
                    setHatColors={setHatColors}
                    setHairColors={setHairColors}
                    setBackgroundColor={setBackgroundColor}
                    customisedAvatarInfo={customisedAvatar}
                    numberOfOvations={userAvatar?.data?.unlocks || 0}
                    defaultSettings={defaultSettings}
                    handleSaveAsHtml={handleSaveAsHtml}
                />
            </Grid>
        </Grid>
    );
};

export default OvationAvatar;
