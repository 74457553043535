import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const appealStepperStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputField: {
			width: 300,
		},
		textField: {
			width: "-webkit-fill-available",
		},
		listItem: {
			gap: 8,
		},
		actionsContainer: {
			justifyContent: "space-evenly",
			display: "flex",
			gap: "15px",
			width: "100%",
		},
	})
);

export default appealStepperStyles;
