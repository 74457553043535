import { Avatar, Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import JSConfetti from "js-confetti";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { getImageUrl } from "../../misc/helpers";
import useWindowDimensions from "../../misc/screenSizes";
import useProfileChange from "../Profile/components/CoachProfileCard/userProfileChange";
// import "./badge.css";

interface Badge {
    id: string;
    badge_img: string;
}

const NewBadgeList = () => {
    const navigate = useNavigate();
    const { currentUser } = useProfileChange();
    const badges: Badge[] = currentUser?.badge_collection || [];
    const { width: screenWidth } = useWindowDimensions();
    const badgeRefs = useRef<(HTMLDivElement | null)[]>([]);
    const placeholderBadge = "/images/placeholderbadge.png";

    const maxShowBadges = screenWidth > 1500 ? 4 : screenWidth > 1000 ? 3 : 2;

    const handleBadgeClick = (badgeId: string) => {
        navigate(`/beyoucoach/badges?badge_id=${badgeId}`);
    };

    const handleHoverStart = (index: number) => {
        if (badgeRefs.current[index]) {
            const confettiCanvas = badgeRefs.current[index]!.querySelector("canvas");
            const jsConfetti = new JSConfetti({ canvas: confettiCanvas! });
            jsConfetti.addConfetti({
                confettiRadius: 3,
                confettiNumber: 70,
                confettiColors: ["#ff0000", "#00ff00", "#0000ff", "#ff7f50", "#ffa500", "#9400d3", "#00ced1"],
            });
        }
    };

    useEffect(() => {
        badgeRefs.current.forEach((badge) => {
            if (badge && !badge.querySelector("canvas")) {
                const canvas = document.createElement("canvas");
                canvas.style.position = "absolute";
                canvas.style.top = "0";
                canvas.style.left = "0";
                canvas.style.width = "100%";
                canvas.style.height = "100%";
                badge.appendChild(canvas);
            }
        });
    }, [badges, screenWidth]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            {badges.length > 0 ? (
                badges.slice(0, maxShowBadges).map((badge: Badge, index: number) => (
                    <motion.div
                        key={badge.id}
                        whileHover={{ scale: 1.1 }}
                        onHoverStart={() => handleHoverStart(index)}
                        transition={{ duration: 0.5, yoyo: Infinity }}
                        className="badge"
                        onClick={() => handleBadgeClick(badge.id)}
                        ref={(el) => (badgeRefs.current[index] = el)}
                        style={{ position: "relative" }}
                    >
                        <Avatar
                            src={getImageUrl("badge", badge.badge_img) || placeholderBadge}
                            sx={{ width: 100, height: 100 }}
                        />
                    </motion.div>
                ))
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> user has no badges</Typography>
                </Box>
            )}
        </Box>
    );
};

export default NewBadgeList;
