import { Button, ButtonProps, CircularProgress, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const submitButtonStyles = makeStyles((theme: Theme) =>
	createStyles({
		submitButtonWrapper: {
			position: "relative",
		},
		submitButtonProgress: {
			color: theme.palette.success.main,
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

type Props = ButtonProps & {
	loading?: boolean;
	progress?: number;
};

const SubmitButton: React.FC<Props> = ({
	loading,
	children,
	progress,
	...buttonProps
}) => {
	const classes = submitButtonStyles();

	return (
		<div className={classes.submitButtonWrapper}>
			<Button {...buttonProps}>{children}</Button>
			{loading && (
				<CircularProgress
					size={24}
					className={classes.submitButtonProgress}
					value={progress}
					variant={progress != null ? "determinate" : undefined}
				/>
			)}
		</div>
	);
};

export type { Props as SubmitButtonProps };
export default SubmitButton;
