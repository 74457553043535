import { httpClient } from "../../clients/http.client";

const getIqsObjectives = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/admin/twt/iqsobjectives", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const groups = data.data.results.data;
            res({
              data: groups,
              page: data.pagination.page - 1,
              totalCount: data.total_results,
            });
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err));
  });
};

export {
    getIqsObjectives,
};
