import { Button, ButtonProps, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import surveyButtonStyles from "../Surveys.components.style";

/** @MM just learned a new concept:
 * if prop is type | undefined it will be required by can be undefined
 * if prop is type? it will not be required at all
 */
interface Props {
	status_id: number | undefined;
	status_label: string | undefined;
	required?: boolean;
}

const LsaButton: React.FC<Props & ButtonProps> = ({
	status_id,
	status_label,
	required,
	...buttonProps
}) => {
	const classes = surveyButtonStyles();

	return (
		<Tooltip
			title={
				status_label != null
					? status_label
					: required
					? "LSA Required"
					: "Low Score Analysis"
			}
		>
			<Button
				{...buttonProps}
				className={clsx({
					[classes.incomplete]:
						status_id === 1 || (status_id == null && required),
					[classes.partial]: status_id === 2,
					[classes.valid]: status_id === 3,
				})}
			>
				LSA
			</Button>
		</Tooltip>
	);
};

export default LsaButton;
