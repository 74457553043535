import { LinearProgress, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

interface ProgressBarProps {
	dir?: "ltr" | "rtl" | undefined;
	colour: "green" | "yellow" | "red";
	value: number;
	[propName: string]: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 5,
			height: 5,
			width: "100%",
		},
		background: {
			backgroundColor: theme.palette.secondary.main,
		},
		bar: {
			backgroundColor: (props: ProgressBarProps) => {
				switch (props.colour) {
					case "green":
						return theme.palette.success.main;
					case "yellow":
						return theme.palette.warning.main;
					case "red":
						return theme.palette.error.main;
				}
			},
		},
	})
);

const ProgressBar = (props: ProgressBarProps) => {
	const { dir, value, colour, ...other } = props;
	const classes = useStyles(props);

	return (
		<LinearProgress
			{...other}
			variant='determinate'
			value={dir === "rtl" ? 100 - value : value}
			className={dir === "rtl" ? classes.bar : classes.background}
			classes={{
				root: classes.root,
				bar: dir === "rtl" ? classes.background : classes.bar,
			}}
		/>
	);
};

export default ProgressBar;
