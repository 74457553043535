import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { getUserPeriods } from "../../../Production/tables/ProductionByDate/ProductionByDate.api";
import ProductionPeriodsEditor from "../../tables/ProductionPeriods/ProductionPeriodsEditor";
import { t_ProductionPeriodNew } from "./MaintainPeriods.types";
import { defaultPeriod } from "./MintainProdPeriods.constants";

const MaintainPeriods: React.FC = () => {
    const [year, setYear] = React.useState<Date>(new Date());
    const [periods, setPeriods] = React.useState([defaultPeriod]);

    React.useEffect(() => {
        const now = new Date();

        const selectedMonth = defaultPeriod
            ? defaultPeriod.month
            : new Date(now.getFullYear(), now.getMonth()).toDateString();

        getUserPeriods({ year: year.toDateString() }).then((data) => {
            const periodData = data.periods.map((period: t_ProductionPeriodNew) => ({
                ...period,
                all_incentive_categories: !period.incentive_categories?.length,
            }));
            setPeriods(periodData);
        });
    }, [year]);

    return (
        <SectionCard
            title="Maintain Production Periods"
            secondaryAction={
                <DatePicker
                    value={year}
                    onChange={(date) => date && setYear(date)}
                    openTo="year"
                    views={["year"]}
                    label="Year"
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
        >
            <ProductionPeriodsEditor year={year} periods={periods} setPeriods={setPeriods} />
        </SectionCard>
    );
};

export default MaintainPeriods;
