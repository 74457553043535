import { Box, Button, Dialog, useTheme } from "@mui/material";
import { Column } from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { getTWTgroups } from "../twtGroups.api";
import { t_GroupData } from "../TwtGroup.types";
import EditPoolGroup from "../sections/EditPoolGroup/EditPoolGroup";
import NewPoolGroup from "../sections/NewPoolGroup/NewPoolGroup";
import DeletePoolGroup from "../sections/DeletePoolGroup/DeletePoolGroup";
import styles from "./styles.module.css";

const MaintainTWTgroups = () => {
  const [dialogState, setDialogState] = React.useState<{
    open: boolean;
    row?: t_GroupData;
  }>({
    open: false,
  });
  const [deleteDialogState, setDeleteDialogState] = React.useState<{
    open: boolean;
    row?: t_GroupData;
  }>({
    open: false,
  });
  const [newPoolDialogOpen, setNewPoolDialogOpen] = React.useState(false);

  const [columns] = React.useState<Column<any>[]>([
    {
      title: "Group Name",
      field: "Name",
      sorting: true,
    },
    {
      title: "Service Environment",
      field: "service_team",
      sorting: false,
    },
    {
      title: "Region",
      field: "stream",
      sorting: false,
    },
    {
      title: "Actions",
      align: "center",
      sorting: false,
      render: (data: t_GroupData) => {
        return (
          <Box
            display="flex"
            maxWidth={"60px"}
            m={0}
            justifyContent={"space-between"}
          >
            <div
              className={styles.actionIcon}
              onClick={() => setDialogState({ open: true, row: data })}
            >
              <EditIcon />
            </div>
            <div
              className={styles.actionIcon}
              onClick={() => setDeleteDialogState({ open: true, row: data })}
            >
              <DeleteOutlineIcon />
            </div>
          </Box>
        );
      },
    },
  ]);
  const tableRef = React.useRef<any>();

  const theme = useTheme();

  return (
    <>
      <div className={styles.container}>
        <SectionCard title={"TWT Groups"} fullWidth scrolling>
          <div className={styles.mainBtnGroup}>
            <Button
              className={styles.mainBtn}
              onClick={() =>
                tableRef.current && tableRef.current.onQueryChange()
              }
              style={{
                border: `solid ${theme.palette.info.dark} 1px`,
                color: theme.palette.info.contrastText,
              }}
            >
              Refresh Group List
            </Button>

            <Button
              className={styles.mainBtn}
              onClick={() => setNewPoolDialogOpen(true)}
              style={{
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
                marginLeft: "1em",
              }}
            >
              Create New Group
            </Button>
          </div>

          <BUMaterialTable
            title={"Pool Groups"}
            tableRef={tableRef}
            columns={columns}
            data={(query) =>
              getTWTgroups({
                ...query,
                pagination: {
                  page: query.page + 1,
                  rows_per_page: query.pageSize,
                },
                filter: [
                  ...query.filters,
                  {
                    column_name: "lk_pool_grouping.Name",
                    search_term: query.search,
                  },
                ],
                sort: query.orderBy
                  ? {
                      column_name: query.orderBy?.field || "",
                      order: query.orderDirection,
                    }
                  : "",
              })
            }
            options={{
              search: true,
              sorting: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50],
              paging: true,
              maxBodyHeight: "600px",
            }}
          />
        </SectionCard>
      </div>

      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState((state) => ({ ...state, open: false }))}
        scroll="paper"
      >
        {dialogState.row && (
          <EditPoolGroup
            tableRef={tableRef}
            handleClose={() =>
              setDialogState((state) => ({ ...state, open: false }))
            }
            group={dialogState.row}
          />
        )}
      </Dialog>

      <Dialog
        open={newPoolDialogOpen}
        onClose={() => setNewPoolDialogOpen(false)}
        scroll="paper"
      >
        <NewPoolGroup handleClose={() => setNewPoolDialogOpen(false)} />
      </Dialog>

      <Dialog
        open={deleteDialogState.open}
        onClose={() =>
          setDeleteDialogState((state) => ({ ...state, open: false }))
        }
        scroll="paper"
      >
        {deleteDialogState.row && (
          <DeletePoolGroup
            handleClose={() => (
              setDeleteDialogState((state) => ({ ...state, open: false })),
              tableRef.current.onQueryChange()
            )}
            group={deleteDialogState.row}
          />
        )}
      </Dialog>
    </>
  );
};

export default MaintainTWTgroups;
