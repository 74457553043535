import { Box, Chip, Dialog, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React from "react";

import InitiativeOption from "../../components/ProductionOptions/InitiativeOption";
import { getUserPeriods } from "../../../Production/tables/ProductionByDate/ProductionByDate.api";
import { defaultPeriod } from "../../sections/MaintainPeriods/MintainProdPeriods.constants";
import DeletePeriodOption from "../../components/DeletePeriodOption";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import { Column } from "material-table";
import { t_ProdPeriodUpdateData } from "../../sections/MaintainPeriods/MaintainPeriods.types";

export type t_ProductionPeriod = {
  id?: number;
  all_incentive_categories: boolean;
  incentive_categories?: any[];
  start_date: string;
  end_date: string;
  last_capture: string;
  month: string;
};

const now = new Date();
const selectedMonth = defaultPeriod
  ? defaultPeriod.month
  : new Date(now.getFullYear(), now.getMonth()).toDateString();

interface ProductionPeriodsEditorProps {
  year: Date;
  periods: t_ProductionPeriod[];
  setPeriods?: React.Dispatch<React.SetStateAction<t_ProductionPeriod[]>>;
}

const ProductionPeriodsEditor = ({
  year,
  periods,
  setPeriods,
}: ProductionPeriodsEditorProps) => {
  const [dialogState, setDialogState] = React.useState<{
    open: boolean;
    row?: t_ProductionPeriod;
  }>({
    open: false,
  });
  const [deleteDialogState, setDeleteDialogState] = React.useState<{
    open: boolean;
    row?: t_ProductionPeriod;
  }>({
    open: false,
  });

  const handleDialogClose = (reason?: "submitted" | "cancel") => {
    switch (reason) {
      case "submitted":
        getUserPeriods({ year: year.toDateString() }).then((resp) => {
          resp.periods && setPeriods && setPeriods(() => resp.periods);
        });
        break;
      default:
        break;
    }
    setDialogState({ open: false });
    setDeleteDialogState({ open: false });
  };

  const [columns] = React.useState<Column<t_ProdPeriodUpdateData>[]>([
    {
      title: "Incentive categories",
      render: (data) =>
        data.all_incentive_categories ? (
          <div style={{ textAlign: "center" }}>All</div>
        ) : (
          <div
            style={{
              maxHeight: "80px",
              overflowY: "scroll",
            }}
          >
            {data.incentive_categories?.map((category) => (
              <Chip
                size="small"
                label={category.name}
                style={{ margin: "2px" }}
              />
            ))}
          </div>
        ),
    },
    {
      title: "Month",
      field: "month",
    },
    {
      title: "Start date",
      field: "start_date",
    },
    {
      title: "End date",
      field: "end_date",
    },
    {
      title: "Last captured",
      field: "last_capture",
    },
    {
      title: "Actions",
      align: "center",
      render: (data) => {
        return (
          <Box
            display="flex"
            maxWidth={"60px"}
            m={0}
            justifyContent={"space-between"}
          >
            <div
              onClick={() =>
                setDialogState({ open: true, row: data as t_ProductionPeriod })
              }
            >
              <EditIcon />
            </div>
            <div
              onClick={() => {
                setDeleteDialogState({
                  open: true,
                  row: data as t_ProductionPeriod,
                });
              }}
            >
              <DeleteOutlineIcon />
            </div>
          </Box>
        );
      },
    },
  ]);

  return (
    <>
      <BUMaterialTable
        title={"Edit Production Periods"}
        columns={columns}
        data={periods as t_ProdPeriodUpdateData[]}
        options={{ search: false }}
        actions={[
          {
            icon: () => (
              <Box display="flex">
                <Typography>Add Period</Typography>
                <AddBoxIcon style={{ marginLeft: "6px" }} />
              </Box>
            ),
            tooltip: "Add",
            onClick: () => {
              setDialogState({ open: true });
            },
            isFreeAction: true,
            disabled: false,
          },
        ]}
      />

      <Dialog open={dialogState.open} onClose={() => handleDialogClose()}>
        <InitiativeOption
          option={dialogState.row}
          onClose={handleDialogClose}
        />
      </Dialog>

      <Dialog
        open={deleteDialogState.open}
        onClose={() => setDeleteDialogState({ open: false })}
      >
        <DeletePeriodOption
          deleteDialogState={deleteDialogState}
          handleDialogClose={handleDialogClose}
        />
      </Dialog>
    </>
  );
};

export default ProductionPeriodsEditor;
