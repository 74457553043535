import { Avatar, CardHeader, Grid, IconButton, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import React, { useState } from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import { getImageUrl } from "../../../../misc/helpers";
import dialogStyles from "../../../../theme/styles/dialogStyles.styles";
import UserDetails from "../../../MaintainUser/UserDetails/UserDetails";
import UserAttributes from "../../../User/forms/UserAttributes.form";
import { MemberMetricColumns, t_TeamMember } from "./TeamMembers.api";
import teamMembersStyles from "./TeamMembers.style";

interface TeamMembersProps {
    teamMembers: t_TeamMember[];
    selectUser: (user: t_TeamMember) => void;
    tableRef?: React.RefObject<any>;
}

const TeamMembers: React.FC<TeamMembersProps> = ({ teamMembers = [], selectUser, tableRef }) => {
    const classes = teamMembersStyles();
    const dialogClasses = dialogStyles();
    const theme = useTheme();

    const permissions = usePermissions();

    /** @MM Find unique metrics so that table columns are only the required; */
    const unique_metrics = new Set<string>();
    teamMembers.map(({ metrics }) => Object.keys(metrics).map((name) => unique_metrics.add(name)));

    const columns = MemberMetricColumns(Array.from(unique_metrics).sort());

    const [dialogMember, setDialogMember] = useState<t_TeamMember>();

    return (
        <>
            <BUMaterialTable
                tableRef={tableRef}
                isLoading={teamMembers?.length === 0}
                columns={[
                    {
                        title: "Member",
                        field: "user.name",
                        render: (rowData) => (
                            <CardHeader
                                title={rowData.user.name}
                                subheader={rowData.user.job_title}
                                className={classes.profileCard}
                                onClick={() => selectUser(rowData as t_TeamMember)}
                                avatar={<Avatar className={classes.condensedAvatar} src={rowData.user.profile_img} />}
                                action={
                                    permissions.team_manage && (
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDialogMember(rowData as t_TeamMember);
                                            }}
                                        >
                                            <Settings />
                                        </IconButton>
                                    )
                                }
                            />
                        ),
                        width: 350,
                    },
                    ...columns,
                ]}
                options={{
                    pageSize: 10,
                    maxBodyHeight: 500,
                    headerStyle: {
                        backgroundColor: theme.palette.info.main,
                        color: theme.palette.info.contrastText,
                    },
                }}
                data={teamMembers}
                title="Team Members"
            />
            <SectionedDialog
                open={Boolean(dialogMember)}
                onClose={() => setDialogMember(undefined)}
                title={dialogMember?.user.name}
                fullWidth
                maxWidth="md"
            >
                <Grid container spacing={2} className={dialogClasses.container}>
                    <Grid item sm className={dialogClasses.item}>
                        <UserDetails entity_no={dialogMember?.user.entity_no} />
                    </Grid>
                    <Grid item sm className={dialogClasses.item}>
                        <SectionCard title="User Attributes" titleProps={{ variant: "h6" }} hasBackground fullWidth>
                            <UserAttributes entity_no={dialogMember?.user.entity_no} />
                        </SectionCard>
                    </Grid>
                </Grid>
            </SectionedDialog>
        </>
    );
};
export default TeamMembers;
