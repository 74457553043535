import { httpClient } from "../../../../clients/http.client";
import { MiscBody } from "../../../../types/api.types";
import { t_IncentiveType } from "./MaintainIncentive.types";

const getIncentiveCategories = async (payload?: {} & MiscBody) => {
	const response = await httpClient.post(
		"/admin/incentive/categories",
		payload
	);
	return response.data.data.categories;
};

const createIncentiveCategory = async (payload: { name: string }) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/create",
		payload
	);
	return response.data.data.category;
};

const updateIncentiveCategory = async (payload: {
	category_id: t_IncentiveType["id"];
	name: string;
}) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/update",
		payload
	);
	return response.data.data.category;
};

const deactivateIncentiveCategory = async (payload: {
	category_id: t_IncentiveType["id"];
}) => {
	const response = await httpClient.post(
		"/admin/incentive/categories/deactivate",
		payload
	);
	return response.data.data.result;
};

export {
	getIncentiveCategories,
	createIncentiveCategory,
	updateIncentiveCategory,
	deactivateIncentiveCategory,
};
