import { List, ListItemText, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { t_MetricSurveyRecord } from "../../tables/MetricSurveyRecords/MetricSurveyRecords.types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: 300,
		},
	})
);

interface SurveyMetricRecordProps {
	record: t_MetricSurveyRecord;
}

const SurveyMetricRecord: React.FC<SurveyMetricRecordProps> = ({ record }) => {
	const classes = useStyles();
	return (
		<List>
			<ListItemText
				secondary={record.user?.DisplayName}
				primary='Agent Name'
			/>
			<ListItemText
				secondary={record.EntityNo}
				primary='Agent EntityNo'
			/>
			<ListItemText secondary={record.ResponseID} primary='Response ID' />
			<ListItemText secondary={record.CallID} primary='CallID' />
			<ListItemText
				secondary={record.CallDateAnswered}
				primary='Call Date'
			/>
			<ListItemText
				secondary={record.SurveyScore}
				primary='Response Score'
			/>
		</List>
	);
};

export default SurveyMetricRecord;
