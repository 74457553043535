import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUserDispatch } from "../../../../context/user/user.ctx";
import { useUpdateBioMutation, useUpdateProfilePicureMutation } from "../../../../redux/api/beYouCore";
import { setUserInfo } from "../../../../redux/slices/UserSlice";
import { RootState } from "../../../../redux/store";

const useProfileUpdate = () => {
    const user = useSelector((state: RootState) => state.user.userInfo);
    const [isAvatarOpen, setIsAvatarOpen] = useState(false);
    const [isBioOpen, setIsBioOpen] = useState(false);
    const [isBioUpdating, setIsBioUpdating] = useState(false);
    const [updateProfilePicture] = useUpdateProfilePicureMutation();
    const [updateBio] = useUpdateBioMutation();
    const [textContent, setTextContent] = useState(user.bio);
    const dispatch = useDispatch();
    const userDispatch = useUserDispatch();

    const handleSaveBio = () => {
        setIsBioUpdating(true);
        const updatedUserInfo = { ...user };

        if (textContent !== "" && textContent !== user.bio) {
            updatedUserInfo.bio = textContent;

            updateBio({ bio: textContent })
                .unwrap()
                .then(() => {
                    dispatch(setUserInfo(updatedUserInfo));
                })
                .catch((error) => {
                    console.error("🚀 ~ file: CoachProfileCard.tsx:71 ~ updateBio ~ error:", error);
                })
                .finally(() => {
                    setIsBioOpen(false);
                    setIsBioUpdating(false);
                });
        } else {
            console.error("something went wrong");
            setIsBioUpdating(false);
        }
    };

    const handleProfilePictureChange = (base64: string) =>
        new Promise<boolean>(() => {
            const updatedUserInfo = { ...user };

            if (base64 != "") {
                updateProfilePicture({ base64 })
                    .unwrap()
                    .then((response) => {
                        updatedUserInfo.profile_img = response?.data?.profile_img;
                        dispatch(setUserInfo(updatedUserInfo));
                        userDispatch({ type: "PROFILE_IMG" });
                        setIsAvatarOpen(false);
                    })
                    .catch(() => {
                        enqueueSnackbar("An error occurred saving your profile picture.", { variant: "error" });
                        setIsAvatarOpen(false);
                    });
            }
        });

    const handleTextContentChange = (event: any) => {
        setTextContent(event.target.value);
    };
    return {
        handleProfilePictureChange,
        handleSaveBio,
        handleTextContentChange,
        textContent,
        isBioUpdating,
        isAvatarOpen,
        setIsAvatarOpen,
        isBioOpen,
        setIsBioOpen,
    };
};

export default useProfileUpdate;
