import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const newsfeedItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		featuredItem: {
			backgroundColor: "#EBEBEB",
			borderRadius: 10,
			width: "100%",
		},
		likedIcon: {
			color: "#FF6565",
		},
		activityCard: {
			border: `1px solid ${theme.palette.divider}`,
		},
	})
);

export default newsfeedItemStyles;
