import { httpClient } from "../../../../clients/http.client";
import { t_User } from "../../../../types/user.types";

const getFriendStatus = async (payload: {
	entity_no: t_User.Profile["entity_no"];
}) => {
	const response = await httpClient.post("/friends/status", payload);
	return response.data.data.friend_status;
};

export { getFriendStatus };
