import { Box, Card, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextCard from "../../../../components/TextCard/TextCard";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { getProfileBasics, updateProfileBio } from "./ProfileBio.api";
import profileBioStyles from "./ProfileBio.style";
import { t_UserBio } from "./ProfileBio.types";

interface Props {
    entity_no: number;
    editable?: boolean;
}
const ProfileBio: React.FC<Props> = ({ entity_no, editable = false }) => {
    const classes = profileBioStyles();
    const [user, setUser] = useState<t_UserBio>();

    const handleBioChanged = (old: string, updated: string) => {
        /** api call for bio change */
        return new Promise<boolean>((resolve) => {
            if ((old ?? "").localeCompare(updated) !== 0) {
                updateProfileBio({ bio: updated }).then((data) => {
                    const success = data.status === "success";

                    if (success) {
                        setUser(
                            (state) =>
                                state && {
                                    ...state,
                                    bio: data.data.updated_bio,
                                },
                        );
                    }
                    resolve(success);
                });
            }
        });
    };

    useEffect(() => {
        if (entity_no) {
            getProfileBasics({ entity_no }).then((data) => {
                setUser(data);
            });
        }
    }, [entity_no]);

    return (
        <div className={classes.root}>
            {user ? (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <ProfileCard user={user} editable={editable} />
                    </Grid>
                    <Grid item sm={12}>
                        <TextCard
                            title="Professional Bio"
                            editable={editable}
                            content={user.bio ?? ""}
                            onEdit={handleBioChanged}
                            className={classes.textCard}
                        />
                    </Grid>
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item sm={12}>
                        <Card>
                            <Box height={170} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item sm={12}>
                        <Card>
                            <Box height={280} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </Card>
                    </Grid>
                </React.Fragment>
            )}
        </div>
    );
};

export default ProfileBio;
