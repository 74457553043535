import React, { ReactNode, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { discoveryLogin, getMyProfile, login, masqueradeLogin } from "../../api/user.api";
import { getUserSession } from "../../clients/http.client";
import { setUserInfo } from "../../redux/slices/UserSlice";
import { t_User } from "../../types/user.types";
import { useUserMetrics } from "./metrics.ctx";
import { setCredentials } from "../../redux/slices/authSlice";
import { useGetDarkModeMutation } from "../../redux/api/beYouCore";
import { toggleTheme } from "../../redux/slices/ThemeSlice";

type UserStatus = {
    loading?: boolean;
    error?: boolean;
    lastFetch?: number;
};
type UserObject = {
    entity_no?: any;
    masquerade?: boolean;
    profile?: t_User.Profile;
    status: UserStatus;
};
type InternalActions = {
    type: "UPDATE" | "CLEAR";
    data: {
        profile?: t_User.Profile;
        metric_values?: t_User.Metric[];
        status?: UserStatus;
    };
};
type ExternalActions =
    | {
          type: "REQUEST_LOGIN" | "REQUEST_MASQUERADE";
          data: {
              entity_no: string;
          };
      }
    | {
          type: "END_MASQUERADE" | "LOGOUT";
      }
    | {
          type: "DISCOVERY_LOGIN";
          data: {
              code: string;
          };
      }
    | { type: "PROFILE_IMG" };
// @MM Initial State
const STATIC: {
    initUserObject: UserObject;
    initAuthActions: (actions: ExternalActions) => void;
} = {
    initUserObject: {
        profile: undefined,
        status: {
            loading: true,
            error: false,
            lastFetch: undefined,
        },
    },
    initAuthActions: (actions: ExternalActions) => {},
};

const UserStateContext = React.createContext<UserObject | undefined>(undefined);
const UserDispatchContext = React.createContext<React.Dispatch<ExternalActions> | undefined>(undefined);

const userReducer: React.Reducer<UserObject, InternalActions> = (prevState, action) => {
    return {
        UPDATE: {
            ...prevState,
            ...action.data,
            status: {
                ...prevState.status,
                ...action.data.status,
            },
        },
        CLEAR: STATIC.initUserObject,
    }[action.type];
};
const useUserState = () => {
    const context = React.useContext(UserStateContext);

    if (context === undefined) throw new Error("Context must be used inside a provider. [UserState]");
    return context;
};
const useUserDispatch = (): React.Dispatch<ExternalActions> => {
    const context = React.useContext(UserDispatchContext);

    if (context === undefined) throw new Error("Context must be used inside a provider. [UserDispatch]");
    return context;
};
const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, internalDispatch] = useReducer(userReducer, STATIC.initUserObject);
    const [, actions] = useUserMetrics();
    const dispatch = useDispatch();
    const [getDarkMode] = useGetDarkModeMutation();

    const loadUser = async (update?: any) => {
        internalDispatch({
            type: "UPDATE",
            data: {
                status: {
                    loading: true,
                    error: false,
                },
            },
        });

        try {
            const masquerading = sessionStorage.getItem("masquerade_access_token") !== null;
            const profile = await getMyProfile();
            dispatch(setUserInfo(profile));

            actions.fetch().then(() => {}); // returns promise (we should have that throw notifications)

            internalDispatch({
                type: "UPDATE",
                data: {
                    profile: { ...profile },
                    // metric_values,
                    status: {
                        loading: false,
                        lastFetch: Date.now(),
                    },
                    entity_no: profile.entity_no,
                    masquerade: masquerading,
                    ...update,
                },
            });
        } catch {
            internalDispatch({
                type: "UPDATE",
                data: {
                    status: {
                        loading: false,
                        error: true,
                    },
                },
            });
        }
    };
    const loginUser = async (data?: { entity_no: string }, update?: any) => {
        if (!data?.entity_no) return false;
        const response = await login({
            data: { entity_no: data?.entity_no },
        });

        switch (response.status) {
            case "success":
                const { api_access_token, token_type } = response.data;

                return { api_access_token, token_type };
            case "error":
                internalDispatch({
                    type: "UPDATE",
                    data: {
                        status: {
                            loading: false,
                            error: true,
                        },
                    },
                });
                return false;
            default:
                console.error("Something went wrong...");
        }
    };
    const externalDispatch: React.Dispatch<ExternalActions> = (action) => {
        switch (action.type) {
            case "DISCOVERY_LOGIN":
                discoveryLogin({
                    code: action.data.code,
                }).then((response) => {
                    if (response != null) {
                        if (response.api_access_token != null && response.token_type != null) {
                            sessionStorage.setItem("api_access_token", response.api_access_token);
                            sessionStorage.setItem("token_type", response.token_type);
                            dispatch(setCredentials(response));
                            loadUser();
                        }
                    }
                });

                break;
            case "REQUEST_LOGIN":
                loginUser(action.data, {
                    entity_no: action.data.entity_no,
                }).then((response) => {
                    if (response) {
                        sessionStorage.setItem("api_access_token", response.api_access_token);
                        sessionStorage.setItem("token_type", response.token_type);
                        dispatch(setCredentials(response));
                        /** @MM Legacy methods that will need rework (not good code) */
                        loadUser();
                    } /** @MM the errors are being handled in loginUser... */
                });

                break;
            case "REQUEST_MASQUERADE":
                // change masquerade functionality to use regular client and request from new endpoint

                masqueradeLogin({
                    entity_no: action.data?.entity_no,
                }).then((response) => {
                    if (response) {
                        sessionStorage.setItem("masquerade_access_token", response.api_access_token);
                        dispatch(setCredentials(response));
                        loadUser({ masquerade: true });
                        window.location.reload();
                    }
                });

                break;
            case "END_MASQUERADE":
                sessionStorage.removeItem("masquerade_access_token");
                loadUser({ masquerade: false });
                window.location.reload();
                break;
            case "LOGOUT":
                sessionStorage.removeItem("api_access_token");
                sessionStorage.removeItem("token_type");
                internalDispatch({
                    type: "CLEAR",
                    data: {},
                });
                break;
            case "PROFILE_IMG":
                loadUser();
                break;
        }
    };

    React.useEffect(() => {
        if (getUserSession()) {
            loadUser();
        } else {
            if (location.pathname !== "/login") {
                if (process.env.REACT_APP_DISCOVERY_LOGIN != null) {
                    window.location.href = process.env.REACT_APP_DISCOVERY_LOGIN;
                } else {
                    navigate("/login");
                }
            }
        }

        getDarkMode()
            .unwrap()
            .then((response) => {
                dispatch(toggleTheme(response.data.dark_mode));
            })
            .catch((error) => {
                console.error("🚀 ~ file: user.ctx.tsx:250 ~ getDarkMode ~ error:", error);
            });
    }, []);

    return (
        <UserStateContext.Provider value={user}>
            <UserDispatchContext.Provider value={externalDispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

export { UserProvider, UserStateContext, useUserState, useUserDispatch };
export type { UserObject, ExternalActions as UserActions };
