import { createTheme } from "@mui/material/styles";
import { default as overrides } from "./overrides/overrides";
import { darkPalette, lightPalette, shape } from "./variables/variables";

const DarkTheme = createTheme({
    shape,
    palette: darkPalette,
    components: overrides(darkPalette),
});

const LightTheme = createTheme({
    shape,
    palette: lightPalette,
    components: overrides(lightPalette),
});

export { DarkTheme, LightTheme };
