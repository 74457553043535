import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CoachTip, GlobalLeaderBoard, record } from "../types";

type GlobalLeaderBoardState = {
    leaderBoard: GlobalLeaderBoard;
};

const initialState: GlobalLeaderBoardState = {
    leaderBoard: { records: [] },
};

const globalLeaderBoardSlice = createSlice({
    name: "globalLeaderBoard",
    initialState,
    reducers: {
        setLeaderBoard: (state, action) => {
            state.leaderBoard = action.payload;
        },
    },
});

export const { setLeaderBoard } = globalLeaderBoardSlice.actions;

export default globalLeaderBoardSlice.reducer;
