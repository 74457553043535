import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const navigationStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			alignItems: "center",
			height: "100%",
			// drawer not open
			minWidth: theme.spacing(8),
		},
		drawerOpen: {
			minWidth: theme.spacing(36),
		},
		drawerToggle: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-evenly",
		},
		drawerLinks: {
			width: "100%",
		},
		drawerActions: {
			width: "100%",
			marginTop: "auto",
		},
		appBarControls: {
			// width: theme.spacing(36),
		},
		appBarContent: {
			borderLeft: `1px solid ${theme.palette.divider}`,
			overflow: "hidden",
		},
		appBarActions: {
			marginLeft: "auto",
			borderLeft: `1px solid ${theme.palette.divider}`,
		},
		listItem: {
			display: "flex",
			columnGap: theme.spacing(2),
			color: theme.palette.primary.contrastText,
			textDecoration: "none",
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemActive: {
			backgroundColor: theme.palette.info.main + "!important",
			color: theme.palette.secondary.main,
			"&:hover": {
				background: theme.palette.info.main,
				color: theme.palette.secondary.main,
			},
		},
		listItemIcon: {
			margin: "0 auto",
			minWidth: "auto",
			color: "inherit",
		},
		listItemText: {
			paddingLeft: 12,
		},
		listItemTextHidden: {
			maxWidth: 0,
			overflow: "hidden",
		},
		themeToggle: {
			paddingLeft: 0,
			paddingRight: 0,
			display: "flex",
		},
		switchLabel: {
			display: "flex",
			columnGap: theme.spacing(2),
			margin: "0",
		},
		brandContainer: {
			width: "100%",
			paddingBottom: "100%",
			position: "relative",
			"&::before": {
				content: '""',
				position: "absolute",
				height: "80%",
				width: "80%",
				background: "lightgrey",
				margin: "0 10%",
				borderRadius: "100%",
			},
		},
		navLogo: {},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			display: "flex",
			flexDirection: "row",
		},
		menuOpen: {
			marginLeft: -8,
			marginRight: -8,
		},
		menuClose: {
			marginLeft: "auto",
		},
		toolTip: {
			boxShadow: `${theme.shadows[10]}`,
			borderRadius: 23,
			color: `${theme.palette.primary}`,
			background: `${theme.palette.background.paper}`,
			display: "flex",
			alignItems: "flex-start",
			padding: 20,
			gap: "2rem",
	
		},
		next: {
			width: "100%",
			background: "#c6db34",
		},
		skip: {
			width: "100%",
			background: "transparent",
		},
	})
);

export default navigationStyles;
