import { Box, Button, Typography } from "@mui/material";
import React from "react";

import { deleteProductionPeriod } from "../../tables/ProductionPeriods/ProductionPeriods.api";
import { t_ProductionPeriod } from "../../tables/ProductionPeriods/ProductionPeriodsEditor";

interface DeletePeriodOptionProps {
  deleteDialogState: {
    open: boolean;
    row?: t_ProductionPeriod | undefined;
  };
  handleDialogClose: (reason?: "submitted" | "cancel") => void;
}

const DeletePeriodOption = ({
  deleteDialogState,
  handleDialogClose,
}: DeletePeriodOptionProps) => {
  return (
    <Box p={"24px 32px"}>
      <Typography variant="h6">
        Are you sure you want to Delete this item?
      </Typography>
      <Box display="flex" pt={3}>
        <Button onClick={() => handleDialogClose()}>Cancel</Button>
        <Button
          onClick={() => {
            deleteDialogState.row &&
              deleteDialogState.row.id &&
              deleteProductionPeriod({ id: deleteDialogState.row.id }).then(
                () => handleDialogClose("submitted")
              );
          }}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default DeletePeriodOption;
