import { useEffect } from "react";

import { useGetRequiredFieldsQuery } from "../../redux/api/beYouCore";

const useCoachFormData = ({ formType, entity_no, month }: { formType: string; entity_no: string; month: string }) => {
    const {
        data: { data: requiredFields } = {},
        refetch,
        isLoading,
        isError,
        isFetching,
        error: fetchError,
    } = useGetRequiredFieldsQuery({ formType, entity_no, month });

    useEffect(() => {
        refetch();
    }, [formType, requiredFields, entity_no, month]);
    return { requiredFields, isError, isFetching, isLoading, fetchError };
};

export default useCoachFormData;
