import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const challengeStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridItem: {
			minHeight: "100%",
		},
	})
);

export default challengeStyles;