import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export type t_ProductionPeriod = {
  id: number;
  incentive_categories?: any[];
  start_date: string;
  end_date: string;
  last_capture?: string;
  month: string;
};

interface ProductionSurveyPeriodsProps {
  period: t_ProductionPeriod;
}

const ProductionSurveyPeriods = ({ period }: ProductionSurveyPeriodsProps) => {
  return (
    <Box pb={4}>
      <Typography variant="h6" style={{ padding: "0 10px 10px" }}>
        Production Periods
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Incentive Categories</TableCell>
              <TableCell>Month</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Last Capture</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width={"50%"}>
                <div
                  style={{
                    maxHeight: "80px",
                    overflowY: "scroll",
                  }}
                >
                  {period.incentive_categories?.length ? (
                    period.incentive_categories?.map((category, i) => (
                      <Chip
                        key={i}
                        size="small"
                        label={category.name}
                        style={{ margin: "2px" }}
                      />
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>All</div>
                  )}
                </div>
              </TableCell>
              <TableCell>{period.month}</TableCell>
              <TableCell>{period.start_date}</TableCell>
              <TableCell>{period.end_date}</TableCell>
              <TableCell>{period.last_capture}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductionSurveyPeriods;
