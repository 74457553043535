import React from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    Switch,
    TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as yup from "yup";

import { t_ProductionPeriod } from "../../tables/ProductionPeriods/ProductionPeriodsEditor";
import { updateProductionPeriods } from "../../tables/ProductionPeriods/ProductionPeriods.api";
import {
    t_ProdPeriodOptionsFormActions,
    t_ProdPeriodUpdateData,
} from "../../sections/MaintainPeriods/MaintainPeriods.types";
import { getIncentiveCategories } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.api";
import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";

const ValidatedDatePicker = ({
    formik,
    field,
    label,
}: {
    formik: { [key: string]: any };
    field: string;
    label: string;
}) => {
    return (
        <DatePicker
            value={formik.values[field]}
            onChange={(date) => {
                date && formik.setFieldValue(field, format(date, "yyyy/MM/dd"));
            }}
            // name={field}
            label={label}
            format="yyyy/MM/dd"
            // error={formik.touched[field] && Boolean(formik.errors[field])}
            // helperText={formik.touched[field] && formik.errors[field]}
            // fullWidth
            // autoOk
        />
    );
};

const initiativeOptionSchema = yup.object().shape({
    month: yup.date().required(),
    start_date: yup.date().required(),
    end_date: yup.date().when("start_date", (start_date, schema) => {
        return start_date && schema.min(start_date, "End date cannot be before start date");
    }),
    last_capture: yup.date().required(),
    all_incentive_categories: yup.boolean().required().default(false),
    incentive_categories: yup.array().when("all_incentive_categories", {
        is: false,
        then: yup.array().min(1, "Please select at least 1 option.").required("Please select an option."),
    }),
});

const ProdPeriodOptionsForm: React.FC<{
    formData: t_ProductionPeriod;
    dispatch: (action: t_ProdPeriodOptionsFormActions) => void;
}> = ({ formData, dispatch }) => {
    const formik = useFormik({
        initialValues: formData,
        validationSchema: initiativeOptionSchema,
        onSubmit: (value) => {
            return new Promise((resolve, reject) => {
                updateProductionPeriods(value as t_ProdPeriodUpdateData)
                    .then(
                        () => {
                            dispatch("submitted");
                            resolve(true);
                        },
                        (error) => {
                            reject(error);
                        },
                    )
                    .catch(reject);
                resolve(true);
            });
        },
    });

    const [visualFeedback, setVisualFeedback] = React.useState<{
        categories_loading?: boolean;
    }>({});
    const [incentiveCategories, setIncentiveCategories] = React.useState<t_IncentiveType[]>([]);
    const [search, setSearch] = React.useState<string>("");

    React.useEffect(() => {
        var isActive = true;
        setVisualFeedback((state) => ({ ...state, categories_loading: true }));
        getIncentiveCategories({
            pagination: {
                page: 1,
                rows_per_page: 30,
            },
            filter: [
                {
                    column_name: "Name",
                    search_term: search,
                },
            ],
        }).then((data) => {
            if (isActive) {
                setIncentiveCategories(data);
                setVisualFeedback((state) => ({
                    ...state,
                    categories_loading: false,
                }));
            }
        });
        return () => {
            isActive = false;
        };
    }, [search]);

    React.useEffect(() => {
        formik.setValues(formData);
    }, [formData]);

    return (
        <>
            <Grid container spacing={3} style={{ padding: "0 16px" }}>
                <Grid item sm={6} alignItems="center">
                    <ValidatedDatePicker formik={formik} field="month" label="Period month" />
                </Grid>
                <Grid item sm={6}>
                    <ValidatedDatePicker formik={formik} field="start_date" label="Period start date" />
                </Grid>
                <Grid item sm={6}>
                    <ValidatedDatePicker formik={formik} field="last_capture" label="Period last captured" />
                </Grid>
                <Grid item sm={6}>
                    <ValidatedDatePicker formik={formik} field="end_date" label="Period end date" />
                </Grid>
            </Grid>

            <List>
                <ListItem>
                    <FormControl
                        component="fieldset"
                        required
                        error={
                            formik.touched.all_incentive_categories && Boolean(formik.errors.all_incentive_categories)
                        }
                    >
                        <FormHelperText>For Incentive Categories*:</FormHelperText>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="all_incentive_categories"
                                    checked={formik.values.all_incentive_categories}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="All Categories?"
                        />
                        <FormHelperText>
                            {formik.touched.all_incentive_categories && formik.errors.all_incentive_categories}
                        </FormHelperText>
                    </FormControl>
                </ListItem>

                {!formik.values.all_incentive_categories && (
                    <ListItem>
                        <FormControl
                            required
                            error={formik.touched.incentive_categories && Boolean(formik.errors.incentive_categories)}
                        >
                            <FormHelperText>Select Incentive Categories*</FormHelperText>
                            <Autocomplete
                                multiple
                                size="small"
                                id="incentive_categories"
                                value={formik.values.incentive_categories}
                                onChange={(_, value) => formik.setFieldValue("incentive_categories", value)}
                                onBlur={() =>
                                    formik.setTouched({
                                        incentive_categories: true,
                                    })
                                }
                                onInputChange={(_, value) => setSearch(value)}
                                options={incentiveCategories}
                                loading={visualFeedback.categories_loading}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                style={{ width: 500 }}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                            <FormHelperText>
                                {formik.touched.incentive_categories && formik.errors.incentive_categories}
                            </FormHelperText>
                        </FormControl>
                    </ListItem>
                )}

                <ListItem>
                    <Button onClick={() => dispatch("cancel")}>Cancel</Button>
                    <SubmitButton
                        onClick={formik.submitForm}
                        disabled={formik.isSubmitting}
                        loading={formik.isSubmitting}
                    >
                        {Boolean(formData.id) ? "Save Period" : "Add Period"}
                    </SubmitButton>
                </ListItem>
            </List>
        </>
    );
};

export default ProdPeriodOptionsForm;
