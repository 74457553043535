import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import PublicHolidays from "../../tables/PublicHolidays/PublicHolidays";

const MaintainHolidays: React.FC = () => {
    const [selectedDate, setSelectedDate] = React.useState<any>(new Date(Date.now()));

    return (
        <SectionCard
            title="Public Holidays"
            secondaryAction={
                <DatePicker
                    openTo="month"
                    views={["year"]}
                    // label="Year"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
        >
            <PublicHolidays year={String(selectedDate?.getFullYear())} />
        </SectionCard>
    );
};

export default MaintainHolidays;
