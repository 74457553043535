export const twtHeader = () => {
    return (
        <thead className="MuiTableHead-root">
            <tr
                style={{
                    height: 30,
                    top: 0,
                    zIndex: 10,
                    position: "sticky",
                    backgroundColor: "#ffffff",
                    fontSize: 12,

                }}
                className="MuiTableRow-root MuiTableRow-head"
            >
                <th></th>
                <th  colSpan={14}>Daily Totals</th>
                <th colSpan={14}>MTD Totals</th>
                <th></th>
            </tr>
            <tr style={{
                    height: 30,
                    top: 30,
                    zIndex: 10,
                    position: "sticky",
                    backgroundColor: "#ffffff",
                    fontSize: 12
                }}
                className="MuiTableRow-root MuiTableRow-head">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th colSpan={3}>Overall TWT Score</th>
                <th></th>
                <th>Process In</th>
                <th></th>
                <th colSpan={2}>Pending Inquiries</th>
                <th></th>
                <th colSpan={2}>Closed Inquiries</th>
                <th></th>
                <th colSpan={3}>Overall TWT Score</th>
                <th></th>
                <th>Process In</th>
                <th></th>
                <th colSpan={2}>Pending Inquiries</th>
                <th></th>
                <th colSpan={2}>Closed Inquiries</th>
                <th></th>
            </tr>
            <tr style={{
                    height: 40,
                    top: 60,
                    zIndex: 10,
                    position: "sticky",
                    backgroundColor: "#ffffff",
                    fontSize: 12
                }}
                className="MuiTableRow-root MuiTableRow-head">
                <th>Name</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Measure</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>

                <th>% In TWT</th>
                <th>Total</th>
                <th>In TWT</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Effort</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Total</th>
                <th>In TWT</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Total</th>
                <th>In TWT</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>% In TWT</th>
                <th>Total</th>
                <th>In TWT</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Effort</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Total</th>
                <th>In TWT</th>
                <th style={{width: 1, height: '100%', backgroundColor: '#ccc'}}></th>
                <th>Total</th>
                <th>In TWT</th>
                <th></th>
            </tr>
            <tr style={{ height: 30 }}></tr>
        </thead>
    );
};
