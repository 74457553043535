import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { RootState } from "../../redux/store";
import { t_Permission } from "../Access/Access.types";
import BreakDownCard from "./BreakDownCard";

interface Props {
    bgStrokeColor?: string;
    bgCardColor?: string;
    strokeColor?: {
        strokeColorOne?: string;
        strokeColorTwo?: string;
    };
    month: string;
    entity_no: number;
    comparison_month: string;
    insightsPermissions: boolean | t_Permission;
}

type MetricProps = {
    id: number;
    name: string;
    value: number;
    target: number;
    percentage_of_target: number;
    comparison_value: number;
    comparison_percentage_of_target: number;
    change: number;
    percentage_change: number;
};

const DetailedBreakDown: React.FC<Props> = ({ bgStrokeColor, strokeColor, insightsPermissions }) => {
    const [metrics, setMetrics] = useState<MetricProps[]>([]);

    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const userMetrics = useSelector((state: RootState) => state.view.viewUserInfo.metrics);

    useEffect(() => {
        if (userMetrics) {
            const entries = Object.values(userMetrics) as MetricProps[];
            setMetrics(entries);
        } else {
            setMetrics(allMetrics);
        }
    }, [userMetrics, allMetrics]);

    return (
        <Swiper
            spaceBetween={10}
            slidesPerView={8}
            modules={[Navigation]}
            navigation
            centerInsufficientSlides
            style={{ paddingLeft: 30, paddingRight: 30 }}
        >
            {metrics?.map((metric: MetricProps) => (
                <SwiperSlide key={metric.id}>
                    <BreakDownCard
                        metric={metric}
                        bgStrokeColor={bgStrokeColor}
                        strokeColor={strokeColor}
                        insightsPermissions={insightsPermissions}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default DetailedBreakDown;
