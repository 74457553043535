type t_ProductionLeave = {
	id?: number;
	leave_type_id?: number;
	start_date: string;
	end_date: string;
	comment: string;
	half_day?: boolean;
};

const base_ProductionLeave: t_ProductionLeave = {
	leave_type_id: undefined,
	start_date: new Date().toDateString(),
	end_date: new Date().toDateString(),
	comment: "",
	half_day: false,
};

type t_LeaveType = {
	id: number;
	name: string;
};

export { base_ProductionLeave };
export type { t_ProductionLeave, t_LeaveType };
