import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Close, Delete, Edit, Publish } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import ActiveButton from "../../../../components/ActiveButton/ActiveButton";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { usePermissions } from "../../../../context/user/permissions.ctx";
import { approveDowntime, deleteDowntime, getDowntimeRecords, getDowntimeTypes, saveDowntime } from "./Downtime.api";
import { downtimeFormStyles, downtimeStyles } from "./Downtime.style";
import {
    base_ProductionDowntime,
    t_DowntimeType,
    t_ProductionDowntime,
    t_ProductionDowntimeRecord,
} from "./Downtime.types";
import { NotificationsContext } from "../../../../components/Toasts/NotificationsProvider.ctx";

const downtimeSchema: yup.SchemaOf<t_ProductionDowntime> = yup
    .object({
        id: yup.number(),
        downtime_type_id: yup.number().required("Please select a Downtime type."),
        downtime_type_name: yup.string(),
        minutes: yup.number().positive().min(1).defined("Please enter the amount of minutes."),
        comment: yup.string().defined("Please enter a comment."),
    })
    .defined();

type t_DowntimeFormActions = "submitted" | "clear";

const DowntimeForm: React.FC<{
    initialValues: t_ProductionDowntime;
    downtimeDetails: {
        entity_no: number;
        capture_date: string;
    };
    formRef?: React.RefObject<FormikProps<t_ProductionDowntime>>;
    dispatch: (action: t_DowntimeFormActions) => void;
}> = ({ initialValues, formRef, downtimeDetails, dispatch }) => {
    const classes = downtimeFormStyles();
    const [downtimeTypes, setDowntimeTypes] = React.useState<t_DowntimeType[]>();
    const { currentStepProduction, setCurrentStepProduction, isDowntimeModalActive } = useContext(NotificationsContext);
    function downtimeSubmit(values: t_ProductionDowntime) {
        return new Promise((resolve, reject) => {
            saveDowntime({ ...downtimeDetails, ...values }).then(
                () => {
                    dispatch("submitted");
                    resolve(true);
                    setCurrentStepProduction(10);
                },
                (reason) => reject(reason),
            );
        });
    }

    React.useEffect(() => {
        var isActive = true;
        getDowntimeTypes().then((data) => isActive && setDowntimeTypes(data));
        return () => {
            isActive = false;
        };
    }, []);
    const handleClear = () => {
        dispatch("clear");
        setCurrentStepProduction(5);
    };
    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={downtimeSchema}
                onSubmit={downtimeSubmit}
            >
                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <List>
                            <ListItem>
                                <FormControl
                                    className={`${classes.inputField} downtime-capture-modal`}
                                    required
                                    error={touched.downtime_type_id && Boolean(errors.downtime_type_id)}
                                >
                                    <InputLabel id="downtime_type_label">Downtime Type</InputLabel>
                                    <Select
                                        labelId="downtime_type_label"
                                        name="downtime_type_id"
                                        className="downtime-capture-type"
                                        value={values.downtime_type_id || ""}
                                        onChange={(event) => {
                                            handleChange(event);

                                            if (event.target.value) {
                                                setCurrentStepProduction(6);
                                            }
                                        }}
                                        // onChange={handleChangeT}
                                        // onBlur={handleBlur}
                                        error={touched.downtime_type_id && Boolean(errors.downtime_type_id)}
                                    >
                                        {downtimeTypes ? (
                                            downtimeTypes.map((downtime_type) => (
                                                <MenuItem
                                                    key={`leave-type-${downtime_type.id}`}
                                                    value={downtime_type.id}
                                                >
                                                    {downtime_type.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>
                                                <Skeleton width="100%" />
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        {touched.downtime_type_id && errors.downtime_type_id}
                                    </FormHelperText>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    id="downtime_minutes"
                                    className={`${classes.inputField} downtime-capture-minutes`}
                                    name="minutes"
                                    type="number"
                                    label="Minutes"
                                    value={values.minutes}
                                    onChange={(event) => {
                                        handleChange(event);
                                    }}
                                    // onChange={(event) => {
                                    //     handleChangeT(event);
                                    // }}
                                    onBlur={(event) => {
                                        handleChange(event);
                                        setCurrentStepProduction(7);
                                    }}
                                    error={touched.minutes && Boolean(errors.minutes)}
                                    helperText={touched.minutes && errors.minutes}
                                    required
                                />
                            </ListItem>
                            <ListItem>
                                <FormControl className={classes.inputField}>
                                    <FormHelperText>Comments*</FormHelperText>
                                    <TextField
                                        id="downtime_comments"
                                        className="downtime-capture-comment"
                                        name="comment"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        value={values.comment}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        // onChange={(event) => {
                                        //     handleChangeT(event);
                                        // }}
                                        onBlur={(event) => {
                                            handleChange(event);
                                            setCurrentStepProduction(8);
                                        }}
                                        error={touched.comment && Boolean(errors.comment)}
                                        helperText={touched.comment && errors.comment}
                                        required
                                    />
                                </FormControl>
                            </ListItem>

                            <ListItem className={classes.actionListItem}>
                                <Button className="downtime-capture-clear" onClick={handleClear}>
                                    Clear
                                </Button>
                                <SubmitButton
                                    className="downtime-capture-add"
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >
                                    {values.id != null ? "Save Downtime" : "Add Downtime"}
                                </SubmitButton>
                            </ListItem>
                        </List>
                    </form>
                )}
            </Formik>
        </>
    );
};

interface Props {
    entity_no: number;
    capture_date: string;
    onClose: (reason?: string) => void;
}

const Downtime: React.FC<Props> = ({ entity_no, capture_date, onClose }) => {
    const classes = downtimeStyles();
    const [editing, setEditing] = React.useState<number>();
    const formikRef = React.createRef<FormikProps<t_ProductionDowntime>>();
    const permissions = usePermissions();

    const [existingDowntime, setExistingDowntime] = React.useState<t_ProductionDowntimeRecord[]>();
    const [loading, setLoading] = React.useState(true);
    const { currentStepProduction, setCurrentStepProduction, isDowntimeModalActive } = useContext(NotificationsContext);

    const handleFormEvent = (action: t_DowntimeFormActions) => {
        switch (action) {
            case "submitted":
                formikRef.current?.resetForm();
                setLoading(true);
                break;
            case "clear":
                formikRef.current?.resetForm();
                break;
        }
        setEditing(undefined);
    };

    const handleRowEvents = (
        action: "edit" | "copy" | "approve" | "decline" | "delete",
        row: t_ProductionDowntimeRecord,
    ) => {
        switch (action) {
            case "edit":
                formikRef.current?.setValues(row);
                setEditing(row.id);
                break;
            case "copy":
                formikRef.current?.setValues({ ...row, id: undefined });
                break;
            case "approve":
            case "decline":
                if (row.id != null) {
                    approveDowntime({
                        id: row.id,
                        approval: action === "approve",
                    }).finally(() => {
                        setLoading(true);
                    });
                } else {
                    setLoading(true);
                }
                break;
            case "delete":
                deleteDowntime(row).then(() => setLoading(true));
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        var isActive = true;

        if (loading) {
            getDowntimeRecords({ entity_no, capture_date }).then((data) => {
                if (isActive) {
                    setExistingDowntime(data);
                    setLoading(false);
                }
            });
        }

        return () => {
            isActive = false;
        };
    }, [loading]);
    React.useEffect(() => {
        // if (!isDowntimeModalActive) {
        //     onClose();
        // }
    }, [currentStepProduction, onClose]);

    return (
        <>
            <SectionCard
                title="Downtime Capture"
                titleProps={{ variant: "h6" }}
                secondaryAction={
                    <IconButton onClick={() => onClose()} size="large">
                        <Close />
                    </IconButton>
                }
            >
                <DowntimeForm
                    downtimeDetails={{ entity_no, capture_date }}
                    initialValues={base_ProductionDowntime}
                    formRef={formikRef}
                    dispatch={handleFormEvent}
                />
                <Box>
                    <TableContainer className={classes.tableBody}>
                        <Table size="small" stickyHeader>
                            <TableHead className="downtime-capture-actions">
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="center">Minutes</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                    {permissions.downtime_approve && <TableCell align="center">Verify</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && existingDowntime ? (
                                    existingDowntime.map((row) => (
                                        <TableRow
                                            key={`existing-downtime-${row.id}`}
                                            className={clsx({
                                                [classes.greyRow]: editing != null && editing !== row.id,
                                            })}
                                        >
                                            <TableCell>{row.downtime_type_name}</TableCell>
                                            <TableCell>{row.minutes}</TableCell>
                                            <TableCell>{row.comment}</TableCell>
                                            <TableCell align="center">
                                                {editing != null && editing === row.id ? (
                                                    <Typography variant="button" className={classes.editing}>
                                                        Editing
                                                    </Typography>
                                                ) : (
                                                    <div className={classes.tableCellActions}>
                                                        <IconButton
                                                            disabled={Boolean(editing) && editing !== row.id}
                                                            onClick={() => {
                                                                handleRowEvents("edit", row);
                                                                setCurrentStepProduction(5);
                                                            }}
                                                            size="large"
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={Boolean(editing) && editing !== row.id}
                                                            onClick={() => handleRowEvents("copy", row)}
                                                            size="large"
                                                        >
                                                            <Publish />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={Boolean(editing) && editing !== row.id}
                                                            onClick={() => handleRowEvents("delete", row)}
                                                            size="large"
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </TableCell>
                                            {permissions.downtime_approve && (
                                                <TableCell>
                                                    <ActiveButton
                                                        active={row.verified}
                                                        onClick={() =>
                                                            handleRowEvents(!row.verified ? "approve" : "decline", row)
                                                        }
                                                        disabled={editing != null}
                                                    >
                                                        {row.verified ? "Verified" : "Verify"}
                                                    </ActiveButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            {loading ? <CircularProgress /> : "No records found."}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </SectionCard>
        </>
    );
};

export default Downtime;
