import React from "react";

export interface Props {
    maskID: string;
}

const makeColor = (name: string, color: string) => {
    const ColorComponent: React.FC<Props> = ({ maskID }) => (
        <g id={`Color/Palette/${name}`} mask={`url(#${maskID})`} fillRule="evenodd" fill={color}>
            <rect id="🖍Color" x="0" y="0" width="264" height="280" />
        </g>
    );

    return ColorComponent;
};

const Black = makeColor("Black", "#262E33");
const Blue01 = makeColor("Blue01", "#65C9FF");
const Blue02 = makeColor("Blue02", "#5199E4");
const Blue03 = makeColor("Blue03", "#25557C");
const Gray01 = makeColor("Gray01", "#E6E6E6");
const Gray02 = makeColor("Gray02", "#929598");
const Heather = makeColor("Heather", "#3C4F5C");
const PastelBlue = makeColor("PastelBlue", "#B1E2FF");
const PastelGreen = makeColor("PastelGreen", "#A7FFC4");
const PastelOrange = makeColor("PastelOrange", "#FFDEB5");
const PastelRed = makeColor("PastelRed", "#FFAFB9");
const PastelYellow = makeColor("PastelYellow", "#FFFFB1");
const Pink = makeColor("Pink", "#FF488E");
const Red = makeColor("Red", "#FF5C5C");
const White = makeColor("White", "#FFFFFF");

// Define a type for the hat color options
export type HatColorOptionType =
    | "Black"
    | "Blue01"
    | "Blue02"
    | "Blue03"
    | "Gray01"
    | "Gray02"
    | "Heather"
    | "PastelBlue"
    | "PastelGreen"
    | "PastelOrange"
    | "PastelRed"
    | "PastelYellow"
    | "Pink"
    | "Red"
    | "White"
    | "default";

// Mapping object for color components
const colorComponentsMap: Record<HatColorOptionType, React.ComponentType<Props>> = {
    Black,
    Blue01,
    Blue02,
    Blue03,
    Gray01,
    Gray02,
    Heather,
    PastelBlue,
    PastelGreen,
    PastelOrange,
    PastelRed,
    PastelYellow,
    Pink,
    Red,
    White,
    default: Gray01, // Default to Gray01 if no match is found
};

interface HatColorProps {
    maskID: string;
    hatColor?: HatColorOptionType;
}

const HatColor: React.FC<HatColorProps> = ({ maskID, hatColor = "default" }) => {
    const SelectedColor = colorComponentsMap[hatColor] || colorComponentsMap["default"];
    return <SelectedColor maskID={maskID} />;
};

export default HatColor;
