import { useEffect, useState } from "react";
import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { Icons, toast } from "react-toastify";
import ReviewFeedback from "../../../sections/Feedback/ReviewFeedback";
import { useGetSummaryByIdMutation } from "../../../redux/api/beYouCore";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setPromptId } from "../../../redux/slices/MetricsSlice";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";

const Summaries = ({
    summary,
    reviewFeedbackOpen,
    setReviewFeedbackOpen,
    setSidePanelChatBot,
    removeNotification,
}: any) => {
    const [summaryDetails, setSummaryDetails] = useState<any>();
    const [getSummaryById] = useGetSummaryByIdMutation();
    const dispatch = useDispatch();
    const promptID = useSelector((state: RootState) => state.metrics.promptId);

    useEffect(() => {
        getSummaryById({ id: summary.summary.id })
            .unwrap()
            .then((response: any) => {
                const savedSummary = response?.data?.response[0];

                if (savedSummary) {
                    setSummaryDetails(savedSummary);
                }
            })
            .catch((error) => {
                console.error("🚀 ~ file: Summaries.tsx:28 ~ getSummaryById ~ error:", error);
            });
    }, []);

    return (
        <Box key={summary?.id}>
            {summaryDetails?.id === promptID && (
                <Dialog open={reviewFeedbackOpen}>
                    <ReviewFeedback summaryDetails={summaryDetails} setReviewFeedbackOpen={setReviewFeedbackOpen} />
                </Dialog>
            )}

            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setReviewFeedbackOpen(summaryDetails ? true : false);
                        setSidePanelChatBot(false);
                        dispatch(setPromptId(summary?.summary?.id));
                    }}
                >
                    {Icons.info({
                        theme: "light",
                        type: toast.TYPE.INFO,
                        width: 30,
                        height: 30,
                    })}
                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        Summary
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: 10, maxWidth: "90%" }}>
                            {summary.date}
                        </Typography>
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {summary?.summary.user_name}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={Number(summary?.date)} />
                            </Typography>
                        </Typography>
                        <Button
                            onClick={() => {
                                removeNotification(summary?.id);
                            }}
                        >
                            <Close color="error" fontSize="small" />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Summaries;
