import { httpClient } from "../../../../clients/http.client";

const updateProfilePicture = async (payload: { base64: string }) =>
	new Promise<{ profile_img: string }>((resolve, reject) => {
		httpClient
			.post("/profile/image/save", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export { updateProfilePicture };
