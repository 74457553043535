import { Avatar, CardHeader, TableCell, TableRow } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import DataTable, { ColumnHeaderProps } from "../../components/DataTable/DataTable";
import RefreshLoader from "../../components/RefreshLoader/RefreshLoader";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup, { t_Tab } from "../../components/TabGroup/TabGroup";
import { getImageUrl } from "../../misc/helpers";
import { MiscBody } from "../../types/api.types";
import UserLink from "../User/components/UserLink";
import { getLeaderBoardData } from "./LeaderBoard.api";
import leaderboardStyles from "./LeaderBoard.styles";
import { t_LeaderBoardUser } from "./LeaderBoard.types";

const tableColumns: ColumnHeaderProps[] = [
    {
        label: "Name",
        name: "Name",
        field: "DisplayName",
    },
    {
        label: "Position",
        name: "Position",
        field: "Rank",
    },
    {
        label: "Points",
        name: "Points",
        field: "Elo",
    },
    {
        label: "Tier",
        name: "Tier",
        field: "Display Name",
    },
];

const LeaderBoard: React.FC = () => {
    const classes = leaderboardStyles();

    const tableRef = React.useRef<HTMLElement>(null);
    const [tableData, setTableData] = React.useState<t_LeaderBoardUser[]>([]);
    const [miscBody, setMisc] = React.useState<MiscBody>({
        pagination: {
            page: 1,
            rows_per_page: 20,
        },
    });
    const [tabList, setTabList] = React.useState<t_Tab[]>([
        {
            label: "Team",
            _id: 3,
        },
        {
            label: "Global",
            _id: 1,
        },
        {
            label: "Friends",
            _id: 2,
        },
    ]);
    const [tabValue, setTabValue] = React.useState<number>(0);
    const [optionValue, setOptionValue] = React.useState<number>();

    const [loading, setLoading] = React.useState<{
        timeout: boolean;
        tableData: boolean;
    }>({ timeout: false, tableData: false });

    const innerRef = React.createRef<HTMLDivElement>();

    const tabChange = (newValue: number, option?: number) => {
        setTabValue(newValue);
        setOptionValue(option);
    };

    const handleSort = (sort: MiscBody["sort"]) => {
        fetchLeaderboard(sort).finally(() => {
            setLoading((state) => ({ ...state, tableData: false }));
        });
    };
    const handleLoadMore = () => {
        setMisc((state) => ({
            ...state,
            pagination: {
                page: 1,
                rows_per_page: 10 + (state.pagination?.rows_per_page ?? 0),
            },
        }));
    };

    const fetchLeaderboard = (sort: MiscBody["sort"] = undefined) => {
        const misc = {
            ...miscBody,
            sort,
        };
        return getLeaderBoardData({
            category_id: tabValue >= 0 ? tabList[tabValue]?._id : 1,
            sub_category_id: optionValue,
            ...misc,
        }).then((data) => {
            // Promises can't be cancelled; Avoid state setting if inactive;
            setTableData(data.leaderboard ?? []);
            setMisc((state) => ({
                ...state,
                sort: data.sort,
                total_results: data.total_results,
            }));
        });
    };

    // @MM On tab change fetch data
    React.useEffect(() => {
        let isActive = true;
        // @MM Loading timeout - standardize loading "feel" (remain < 200ms);
        isActive &&
            setLoading((state) => ({
                ...state,
                tableData: true,
                timeout: true,
            }));

        // @MM Load data;
        fetchLeaderboard().finally(() => {
            if (isActive) {
                setLoading((state) => ({
                    ...state,
                    timeout: false,
                    tableData: false,
                }));
            }
        });

        return () => {
            isActive = false;
        };
    }, [tabValue, optionValue]);

    React.useEffect(() => {
        fetchLeaderboard();
    }, [miscBody.pagination]);

    React.useEffect(() => {
        let isActive = true;
        return () => {
            isActive = false;
        };
    }, []);

    return (
        <SectionCard
            title="BeYou Leaderboard"
            primaryAction={<TabGroup tabValue={tabValue} tabList={tabList} tabChange={tabChange} />}
            fullWidth
            scrolling
            innerRef={innerRef}
        >
            <DataTable
                className={classes.rounded}
                rows={tableData}
                columns={tableColumns}
                sort={miscBody.sort}
                sortBy={handleSort}
            >
                {loading.timeout || loading.tableData ? (
                    [...Array(8)].map((e, i) => (
                        <TableRow key={`leader-board-${i}`}>
                            <TableCell>
                                <CardHeader
                                    style={{ padding: 0 }}
                                    avatar={<Skeleton variant="circular" width={40} height={40} />}
                                    title={<Skeleton variant="text" />}
                                />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <React.Fragment>
                        {tableData.map((row, i) => (
                            <TableRow key={`leader-board-user-${row.id}-${i}`} className={classes.rounded}>
                                <TableCell>
                                    <CardHeader
                                        style={{ padding: 0 }}
                                        avatar={<Avatar src={getImageUrl("profile", row.profile_img)} />}
                                        title={<UserLink user={row} />}
                                    />
                                </TableCell>
                                <TableCell>{row.position}</TableCell>
                                <TableCell>{row.points}</TableCell>
                                <TableCell>{row.tier.label}</TableCell>
                            </TableRow>
                        ))}
                        {tableData.length === 0 && (
                            <TableRow key={`leader-board-no-data`} className={classes.rounded}>
                                <TableCell colSpan={4}>
                                    {tabList[tabValue].label} has too few people, try a different tab.
                                </TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                )}
                {Number(miscBody.total_results) > Number(miscBody.pagination?.rows_per_page) && (
                    <TableRow className={classes.noFormatRow}>
                        <TableCell colSpan={tableColumns.length}>
                            <RefreshLoader
                                parentRef={tableRef}
                                onRefresh={handleLoadMore}
                                buttonContent={<ArrowDownward />}
                            />
                        </TableCell>
                    </TableRow>
                )}
            </DataTable>
        </SectionCard>
    );
};

export default LeaderBoard;
