import {
    Backdrop,
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Select,
    Tab,
    Tabs,
    useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
    Bar,
    Cell,
    ComposedChart,
    LabelList,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import BUMaterialTable from "../../components/BUMaterialTable/BUMaterialTable";
import { LineBarTrendData } from "../../components/LineBarTrend/LineBarTrend";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { useUserState } from "../../context/user/user.ctx";
import { useAllMetricsMutation, useStructuredTrendMutation } from "../../redux/api/beYouCore";
import { RootState } from "../../redux/store";
import UserMetricRecords from "../Performance/components/UserMetricRecords/UserMetricRecords";
import { usePerformanceContext } from "../Performance/Performance.ctx";

const Insights = () => {
    const theme = useTheme();
    const currentUser = useUserState();
    const [sectionTab, setSectionTab] = useState(0);
    const [{ month, viewUser, comparison_month }, performanceDispatch] = usePerformanceContext();
    const table1ref = useRef();
    const [trendData, setTrendData] = useState<LineBarTrendData[]>([]);
    const [recordData, setRecordData] = useState<any>();
    const [allMetrics, setAllMetrics] = useState<any>([]);
    const defaultMetric = allMetrics.find((ssmId: any) => ssmId.id == 7)?.id || allMetrics[0]?.id;
    const [selectedMetric, setSelectedMetric] = useState<number>(defaultMetric);
    const [selectedMetricID, setSelectedMetricID] = useState<number>();
    const [selectedRow, setSelectedRow] = useState<number>(1);
    const [tabValue, setTabValue] = useState<number>(0);
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(month));
    const [incentiveMetrics] = useAllMetricsMutation();
    const [selectedStructure, setSelectedStructure] = useState<any>("Monthly");
    const [structuredTrend, { isLoading }] = useStructuredTrendMutation();
    const user = useSelector((state: RootState) => state.user.userInfo);
    const location = useLocation();
    const metricName = location.pathname.split("/").pop();
    const structures = ["Daily", "Weekly", "Monthly"];
    const entity_no = location?.state?.entity_no ? location?.state?.entity_no : currentUser.entity_no;

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    const metricsInsightsColumn = useMemo(
        () => [
            {
                title: "Metric",
                field: "name",
            },

            {
                title: "Target",
                field: "target",
            },
            {
                title: "Score",
                field: "value",
            },
            {
                title: "Last Month's Score",
                field: "comparison_value",
            },
            // {
            //     title: "Percentage of Target",
            //     field: "percentage_of_target",
            // },
            {
                title: "Change",
                field: "percentage_change",
                align: "left",
                render: (rowData: any) => {
                    const arrow =
                        rowData.id == 1 || rowData.id == 2 || rowData.id == 6
                            ? rowData.percentage_change >= 0
                                ? "up"
                                : "down"
                            : rowData.percentage_change > 0
                              ? "up"
                              : "down";
                    const chipColor = arrow === "up" ? "#30D843" : "#FF4040";

                    return (
                        <Box display="flex" alignItems="center" mb={0.5}>
                            <Chip
                                label={`${rowData.percentage_change || 0}%*`}
                                size="small"
                                avatar={
                                    <img
                                        src={`${arrow === "up" ? "/images/upArrow.png" : "/images/downArrow.png"}`}
                                        style={{
                                            width: ".8em",
                                            height: ".8em",
                                            position: "absolute",
                                            top: "30%",
                                            right: "15%",
                                        }}
                                        alt="arrow"
                                    />
                                }
                                style={{
                                    background: `${chipColor}`,
                                    color: "white",
                                    fontSize: ".7rem",
                                    padding: "0 0.6em",
                                    height: "1.1rem",
                                    position: "relative",
                                }}
                            />
                        </Box>
                    );
                },
            },
            {
                title: "Feedback",
                field: "feedback",
                render: (rowData: any) => {
                    let change, message_2, period;
                    if (selectedStructure === "Daily") {
                        period = "month";
                    } else if (selectedStructure === "Weekly") {
                        period = "month";
                    } else if (selectedStructure === "Monthly") {
                        period = "month";
                    }
                    const message = `Over the last ${period}, your ${rowData.name} score has`;
                    if (rowData.id == 1 || rowData.id == 2 || rowData.id == 6) {
                        if (rowData.change < 0) {
                            change = `decreased by ${Math.abs(rowData.change)}.`;
                            message_2 = "Congratulations, keep up the great work!";
                        } else if (rowData.change > 0) {
                            change = `increased by ${Math.abs(rowData.change)}.`;
                            message_2 = "Let's keep pushing!";
                        } else {
                            change = "not changed.";
                            message_2 = "Keep up the good work!";
                        }
                    } else {
                        if (rowData.change > 0) {
                            change = `improved by ${Math.abs(rowData.change)}.`;
                            message_2 = "Congratulations, keep up the great work!";
                        } else if (rowData.change < 0) {
                            change = `dropped by ${Math.abs(rowData.change)}.`;
                            message_2 = "Let's keep pushing!";
                        } else {
                            change = "not changed.";
                            message_2 = "";
                        }
                    }

                    return `${message} ${change} ${message_2}`;
                },
            },
        ],
        [selectedStructure],
    );

    const fetchMetrics = useCallback(() => {
        if (entity_no) {
            incentiveMetrics({ month, comparison_month, entity_no, incentive: false })
                .unwrap()
                .then((data) => {
                    setAllMetrics(data.data.metric_values);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [month, entity_no, incentiveMetrics, comparison_month]);

    const fetchTrendData = useCallback(() => {
        if (selectedMetricID && entity_no) {
            structuredTrend({
                entity_no,
                month,
                metric_id: selectedMetricID,
                structure_type: selectedStructure.toLowerCase(),
            }).then((response: any) => {
                if (response.data) {
                    setTrendData(response?.data?.data?.trend);
                }
            });

            performanceDispatch({
                type: "view",
                viewUser: {
                    user: user ? user : currentUser.profile,
                    metrics: allMetrics,
                },
            });
        }
    }, [
        selectedMetricID,
        selectedStructure,
        month,
        entity_no,
        structuredTrend,
        performanceDispatch,
        user,
        currentUser.profile,
        allMetrics,
    ]);

    const tableData = JSON.parse(JSON.stringify(allMetrics));

    useEffect(() => {
        fetchMetrics();
    }, [fetchMetrics]);

    useEffect(() => {
        fetchTrendData();
    }, [selectedMetricID, selectedStructure, month, entity_no, fetchTrendData]);

    useEffect(() => {
        if (selectedMetricID) {
            const selectedMetricData = allMetrics.find((metric: any) => metric.id === selectedMetricID);
            setRecordData(selectedMetricData);
        }
    }, [selectedMetricID, allMetrics]);

    useEffect(() => {
        if (metricName && metricName !== "insights") {
            setSelectedMetric(Number(metricName));
            setSelectedMetricID(Number(metricName));
            setSelectedRow(Number(metricName));
        } else {
            const defaultMetric = allMetrics.find((ssmId: any) => ssmId.id == 7)?.id || allMetrics[0]?.id;
            setSelectedMetric(defaultMetric);
            setSelectedMetricID(defaultMetric);
            setSelectedRow(defaultMetric);
        }
    }, [metricName, allMetrics]);

    const metricSelectChange = (e: any) => {
        setSelectedMetric(e.target.value);
        setSelectedMetricID(e.target.value);
        setSelectedRow(e.target.value);
    };

    const sortedTrend = useMemo(() => [...trendData]?.reverse(), [trendData]);

    return (
        <Grid>
            <SectionCard
                title="BeYou"
                subtitle={currentUser.profile?.name}
                fullWidth
                primaryAction={
                    <TabGroup
                        tabList={[
                            {
                                _id: 0,
                                label: `${
                                    location?.state?.name ? location?.state?.name : ""
                                } ${selectedStructure} INSIGHTS`,
                            },
                        ]}
                        tabChange={(newValue) => setSectionTab(newValue)}
                        tabValue={sectionTab}
                    />
                }
                secondaryAction={
                    <>
                        <DatePicker
                            openTo="month"
                            views={["year", "month"]}
                            value={new Date(month)}
                            onChange={handleDateChange}
                            closeOnSelect={false}
                            onOpen={() => setIsDateOpen(true)}
                            onClose={() => {
                                performanceDispatch({
                                    type: "update_dates",
                                    month: selectedDate,
                                });
                                setIsDateOpen(false);
                            }}
                            slotProps={{ textField: { variant: "standard" } }}
                        />
                        <Backdrop sx={{ zIndex: 1 }} open={isDateOpen}></Backdrop>
                    </>
                }
            ></SectionCard>
            <Grid container spacing={4} style={{ padding: "1.6em" }}>
                <Grid item xs={12}>
                    <SectionCard
                        title={`${selectedStructure} Insights`}
                        hasBackground
                        fullWidth
                        secondaryAction={
                            <Box>
                                <Select
                                    value={Number(selectedMetric)}
                                    label="Metric Insight"
                                    onChange={metricSelectChange}
                                    variant="standard"
                                >
                                    {allMetrics?.map((metric: any) => (
                                        <MenuItem value={metric?.id} key={metric?.id}>
                                            {metric?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={selectedStructure}
                                    onChange={(e: any) => {
                                        setSelectedStructure(e.target.value);
                                    }}
                                    style={{ marginLeft: ".5em" }}
                                    variant="standard"
                                >
                                    {structures.map((item) => (
                                        <MenuItem value={item} key={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        }
                    >
                        <ResponsiveContainer width="100%" height={400}>
                            {isLoading ? (
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <ComposedChart
                                    width={500}
                                    height={400}
                                    data={sortedTrend}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                >
                                    <XAxis dataKey="month" stroke={theme.palette.primary.contrastText} />
                                    <YAxis stroke={theme.palette.primary.contrastText} />
                                    <Tooltip />
                                    <Legend />
                                    <defs>
                                        {sortedTrend?.map((trend: any, index: any) => {
                                            const isInverseColor =
                                                selectedMetric == 1 || selectedMetric == 2 || selectedMetric == 6;

                                            const colorBarDark = isInverseColor
                                                ? trend.score >= trend.target
                                                    ? "#FF4040"
                                                    : (trend.score / trend.target) * 100 >= 80 &&
                                                        (trend.score / trend.target) * 100 < 100
                                                      ? "#FFAC1C"
                                                      : "#0086FC"
                                                : trend.score >= trend.target
                                                  ? "#0086FC"
                                                  : (trend.score / trend.target) * 100 >= 80 &&
                                                      (trend.score / trend.target) * 100 < 100
                                                    ? "#FFAC1C"
                                                    : "#FF4040";

                                            const colorBarLight = isInverseColor
                                                ? trend.score >= trend.target
                                                    ? "#ff6242"
                                                    : (trend.score / trend.target) * 100 >= 80 &&
                                                        (trend.score / trend.target) * 100 < 100
                                                      ? "#ffd91a"
                                                      : "#00F6FC"
                                                : trend.score >= trend.target
                                                  ? "#00F6FC"
                                                  : (trend.score / trend.target) * 100 >= 80 &&
                                                      (trend.score / trend.target) * 100 < 100
                                                    ? "#ffd91a"
                                                    : "#ff6242";

                                            return (
                                                <linearGradient
                                                    id={`insights${index}`}
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                    key={index}
                                                >
                                                    <stop offset="5%" stopColor={colorBarDark} stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor={colorBarLight} stopOpacity={0.3} />
                                                </linearGradient>
                                            );
                                        })}
                                    </defs>
                                    <Bar
                                        dataKey="score"
                                        fill="#0086FC"
                                        barSize={10}
                                        radius={10}
                                        isAnimationActive={false}
                                    >
                                        {sortedTrend?.map((trend: any, index: any) => (
                                            <Cell fill={`url(#insights${index})`} />
                                        ))}
                                        <LabelList dataKey="score" position="top" />
                                    </Bar>
                                    <Line type="monotone" dataKey="target" stroke="#B8D200" strokeWidth={3} />
                                </ComposedChart>
                            )}
                        </ResponsiveContainer>
                    </SectionCard>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <SectionCard
                        // hasBackground
                        title={`${recordData?.name} Details`}
                        fullWidth
                        primaryAction={
                            <Tabs
                                value={tabValue}
                                onChange={(_, value) => setTabValue(value)}
                                aria-label="notifications section"
                                style={{ padding: 16 }}
                            >
                                <Tab label="Insights" />
                                <Tab label="Records" />
                            </Tabs>
                        }
                    >
                        {tabValue == 0 && (
                            <BUMaterialTable
                                title={"My Insights"}
                                //@ts-ignore
                                columns={metricsInsightsColumn}
                                tableRef={table1ref}
                                data={tableData}
                                isLoading={isLoading}
                                onRowClick={(e, rowData: any) => {
                                    setSelectedMetric(rowData.id);
                                    setSelectedMetricID(rowData.id);
                                    setSelectedRow(rowData.id);
                                }}
                                options={{
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 15],
                                    rowStyle: (rowData) => ({
                                        backgroundColor: selectedRow === rowData.id ? "#C3D830" : "",
                                        color: selectedRow === rowData.id ? "#434343" : "",
                                        borderRadius: "100%",
                                        overflow: "hidden",
                                    }),
                                }}
                            />
                        )}
                        {tabValue == 1 && viewUser && recordData && (
                            <UserMetricRecords viewMetric={recordData} month={month} viewUser={viewUser} />
                        )}
                    </SectionCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default memo(Insights);
