import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import {
	deleteDowntimeOption,
	getDowntimeOptions,
	updateDowntimeOption,
} from "./DowntimeOptions.api";
import { t_DowntimeOption } from "./DowntimeOptions.types";

const DowntimeOptions: React.FC = () => {
	const [columns] = React.useState<Column<t_DowntimeOption>[]>([
		{
			title: "Downtime Type",
			field: "name",
			width: 150,
		},
	]);
	return (
		<BUMaterialTable
			title='Downtime Options'
			columns={columns}
			data={(query) => getDowntimeOptions({ query })}
			editable={{
				onRowAdd: (newData) =>
					updateDowntimeOption({ option: newData }),
				onRowUpdate: (newData) =>
					updateDowntimeOption({ option: newData }),
				onRowDelete: (oldData) =>
					deleteDowntimeOption({ option: oldData }),
			}}
			options={{
				actionsColumnIndex: -1,
			}}
		/>
	);
};

export default DowntimeOptions;
