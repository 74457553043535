import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Metric } from "../types";

type MetricState = {
    allmetrics: Metric[];
    promptDate: string | null;
    promptId: number | null;
};

const initialState: MetricState = {
    allmetrics: [],
    promptDate: null,
    promptId: null,
};

const metricSlice = createSlice({
    name: "metrics",
    initialState,
    reducers: {
        setAllMetrics: (state, action: PayloadAction<Metric[]>) => {
            state.allmetrics = action.payload;
        },
        setPromptDate: (state, action: PayloadAction<string | null>) => {
            state.promptDate = action.payload;
        },
        setPromptId: (state, action: PayloadAction<number | null>) => {
            state.promptId = action.payload;
        },
    },
});

export const { setAllMetrics, setPromptDate, setPromptId } = metricSlice.actions;

export default metricSlice.reducer;
