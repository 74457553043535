import { httpClient } from "../../../../../../clients/http.client";
import { t_SurveyPeriod, t_SurveyPeriodArr } from "../../../../../Surveys/Surveys.types";
import { t_LsaSurveyGraphData, t_LsaSurveyGraphGroups } from "./LsaSurveyGraph.types";

const getLsaSurveyGraphData = (payload: {
    group_by: t_LsaSurveyGraphGroups;
    periods: {
        [key: string]: t_SurveyPeriod;
    };
}) => {
    return new Promise<t_LsaSurveyGraphData[]>((resolve, reject) => {
        httpClient.post("/reports/lsa/graphs/survey", payload).then(({ data }) => {
            switch (data.status) {
                case "success":
                    resolve(data.data.graph_data);
                    break;
                default:
                    reject(data.message);
                    break;
            }
        });
    });
};

const getLsaSurveyGraphDataMulti = (payload: {
    group_by: t_LsaSurveyGraphGroups;
    periods: {
        [key: string]: t_SurveyPeriodArr;
    };
    filter?: {
        [key: string]: string[] | string;
    };
}) => {
    return new Promise<t_LsaSurveyGraphData[]>((resolve, reject) => {
        const suppliedPeriod = payload.periods;
        const suppliedFilter = payload.periods;

        if (Number(suppliedPeriod.length) <= 0 || Object.keys(suppliedFilter).length <= 0) {
            return;
        }
        httpClient.post("/reports/lsa/graphs/survey", payload).then(({ data }) => {
            switch (data.status) {
                case "success":
                    resolve(data.data.graph_data);
                    break;
                default:
                    reject(data.message);
                    break;
            }
        });
    });
};

export { getLsaSurveyGraphData, getLsaSurveyGraphDataMulti };
