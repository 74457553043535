import { TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { t_PoolColumn } from "../../../Production.types";
import productionTableStyles from "../../Production.table.styles";

interface Props {
    poolColumns?: (t_PoolColumn & { field: string })[];
    children?: ReactNode;
}

const ProductionColumns: React.FC<Props> = ({ poolColumns, children }) => {
    const classes = productionTableStyles();

    return (
        <>
            <TableRow>
                <TableCell colSpan={7 + React.Children.toArray(children).length} />
                <TableCell align="center" className={clsx(classes.borderedCell, classes.fadedCell, classes.italicCell)}>
                    weight
                </TableCell>
                {poolColumns?.map((column) => (
                    <TableCell align="center" className={clsx(classes.fadedCell, classes.italicCell)}>
                        {column.weight}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                {children}
                <TableCell className={classes.boldCell}>Leave</TableCell>
                <TableCell className={classes.boldCell}>Target</TableCell>
                <TableCell className={classes.boldCell}>Total</TableCell>
                <TableCell align="center" className={classes.boldCell}>
                    Downtime Volume
                </TableCell>
                <TableCell align="center" className={classes.boldCell}>
                    Downtime
                </TableCell>
                <TableCell align="center" className={classes.boldCell}>
                    Additional Volume
                </TableCell>
                <TableCell align="center">Actual Volume</TableCell>
                <TableCell align="center" className={clsx(classes.borderedCell, classes.boldCell)}>
                    Weighted Volume
                </TableCell>
                {poolColumns?.map((column) => (
                    <TableCell key={`${column.name}-col`} align="center" className={classes.boldCell}>
                        {column.name}
                    </TableCell>
                ))}
            </TableRow>
        </>
    );
};

export default ProductionColumns;
