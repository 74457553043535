import { httpClient } from "../../clients/http.client";
import { MiscBody } from "../../types/api.types";

const postNotificationClear = (payload: { notification_id: number }) => {
	return new Promise((resolve, reject) => {
		httpClient
			.post("/newsfeed/notification/clear", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
						break;
				}
			});
	});
};
const getNewsFeedCategories = async (payload?: {}): Promise<
	{
		label: string;
		_id: any;
		[other: string]: any;
	}[]
> => {
	const data = {
		section: "newsfeed",
	};
	const response = await httpClient.post("/categories", data);
	return response.data.data.categories?.map(
		(category: { id: number; Name: string }) => ({
			_id: category.id,
			label: category.Name,
		})
	);
};
const getNewsFeedItemComments = async (
	payload: { newsfeed_item_id: string } & MiscBody
) => {
	const response = await httpClient.post("/newsfeed/comments", payload);
	return response.data;
};

const getNewsFeedData = async (payload: { category_id: number } & MiscBody) => {
	const { category_id, ...misc } = payload;
	const body = {
		category_id,
		...misc,
	};
	const response = await httpClient.post("/newsfeed", body);
	return response?.data;
};

const getNotifications = async () => {
	const response = await httpClient.post("/newsfeed/notification");
	return response?.data;
};

const postNewsFeedComment = async (payload: {
	newsfeed_item_id: any;
	comment: string;
}) => {
	const response = await httpClient.post("/newsfeed/comment/create", payload);
	return response.data;
};

const postNewsFeedItemInteraction = async (payload: {
	newsfeed_item_id: any;
	interaction_type_id: number;
}) => {
	const response = await httpClient.post("/newsfeed/interaction", payload);

	return response.data;
};

const postNewsFeedCommentInteraction = async (payload: {
	newsfeed_comment_id: any;
	interaction_type_id: any;
}) => {
	const response = await httpClient.post("/newsfeed/interaction", payload);
	return response.data;
};
export {
	getNewsFeedCategories,
	getNewsFeedData,
	getNotifications,
	getNewsFeedItemComments,
	postNewsFeedComment,
	postNewsFeedItemInteraction,
	postNewsFeedCommentInteraction,
	postNotificationClear,
};
