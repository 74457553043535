import { Theme } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const teamLeaderSummaryStyles = makeStyles((theme: Theme) =>
    createStyles({
        valid: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
        },
        partial: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
        },
        incomplete: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
        default: {
            backgroundColor: "none",
        },
        agentTableContainer: {
            padding: theme.spacing(2, 0),
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

export default teamLeaderSummaryStyles;
