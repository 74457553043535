import { List, ListItem, Typography } from "@mui/material";
import { Skeleton } from '@mui/material';
import React from "react";
import { t_User } from "../../../../types/user.types";
import { t_MetricStructureType } from "../../Metric.types";
import MetricProgressList from "../MetricProgressList/MetricProgressList";
import { getUserMetricProgress } from "./GameMetricProgress.api";

interface Props {
	entity_no?: number;
	structure_type?: t_MetricStructureType;
	metric_values?: t_User.Metric[];
	metric_fetch?: string;
	date?: string; // only for game metrics
}

const MetricProgress: React.FC<Props> = ({
	entity_no,
	structure_type,
	date,
	metric_values = [],
	metric_fetch,
}) => {
	const [metricValues, setMetricValues] =
		React.useState<t_User.Metric[]>(metric_values);
	const [visualFeedback, setVisualFeedback] = React.useState<{
		loading: boolean;
		status?: "error" | undefined;
	}>({
		loading: false,
	});

	React.useEffect(() => {
		var isActive = true;
		if (entity_no != null) {
			setVisualFeedback({ loading: true });
			getUserMetricProgress({ entity_no, structure_type, date })
				.then((metric_values) => {
					if (isActive) {
						setMetricValues(metric_values);
						setVisualFeedback({ loading: false });
					}
				})
				.catch((message) => {
					setVisualFeedback({
						loading: false,
						status: "error",
					});
				});
		}
	}, [entity_no]);

	React.useEffect(() => {
		if (metric_fetch != null) {
			setMetricValues(metric_values);
		}
	}, [metric_fetch]);
	return (
		<React.Fragment>
			{!visualFeedback.loading ? (
				metricValues ? (
					<MetricProgressList metric_values={metricValues} />
				) : (
					visualFeedback.status === "error" && (
						<List>
							<ListItem>
								<Typography variant='caption'>
									There was an error fetching data for this
									user.
								</Typography>
							</ListItem>
						</List>
					)
				)
			) : (
				<List>
					<ListItem>
						<Skeleton width='100%' />
					</ListItem>
				</List>
			)}
		</React.Fragment>
	);
};

export default MetricProgress;
