import { t_UserBase } from "../../../User/User.types";

type t_DecisionForm = {
	approval?: boolean;
	comment: string | null;
};

type t_DecisionRecord = {
	decision_by: t_UserBase;
	decision_date: string;
	decision_comment: string;
	status: {
		ApprovalStatusID: number;
		Status: string;
	};
};

const base_DecisionForm: t_DecisionForm = {
	comment: null,
};

type t_AppealDecisionActions = { type: "decision-updated" };

export type { t_DecisionForm, t_DecisionRecord, t_AppealDecisionActions };
export { base_DecisionForm };

