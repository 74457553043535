import {
    Avatar,
    Badge,
    Box,
    Card,
    CardHeader,
    CardProps,
    Grid,
    Hidden,
    IconButton,
    Link,
    Typography,
} from "@mui/material";

import badgeCardStyles from "./BadgeCard.styles";

export interface BadgeCardProps {
    badge_img: string;
    title: string;
    subtitle?: string;
    count?: number;
    variant?: "card" | "flat" | "mini" | undefined;
    subtitleClicked?: () => any;
    avatarClicked?: () => any;
    customClass?: {
        box?: string;
        avatarButton?: string;
    };
    innerProps?: {
        card?: CardProps;
    };
    [propName: string]: any;
}

const BadgeCard = (props: BadgeCardProps) => {
    const placeholderBadge = "/images/placeholderbadge.png";
    const {
        badge_img,
        title,
        subtitle,
        count = 1, // set default to 1
        variant,
        subtitleClicked,
        avatarClicked,
        customClass,
        innerProps,
        ...other
    } = props;
    const classes = badgeCardStyles();
    const handleSubtitleClicked = () => {
        if (subtitleClicked) subtitleClicked();
    };
    const handleAvatarClicked = () => {
        if (avatarClicked) avatarClicked();
    };
    return (
        <Box className={customClass?.box}>
            {!variant || (variant && variant === "card") ? (
                <Card {...innerProps?.card}>
                    <CardHeader
                        avatar={
                            <IconButton onClick={handleAvatarClicked} size="large">
                                <Avatar src={badge_img || placeholderBadge} />
                            </IconButton>
                        }
                        title={title}
                        subheader={
                            subtitle && (
                                <Link
                                    component="button"
                                    variant="subtitle2"
                                    onClick={handleSubtitleClicked}
                                    color="inherit"
                                >
                                    {subtitle}
                                </Link>
                            )
                        }
                    />
                </Card>
            ) : (
                <Grid container justifyContent="center">
                    <Grid
                        item
                        sm={12}
                        container
                        justifyContent="center"
                        // style={{ padding: theme.spacing(2) }}
                    >
                        <IconButton
                            onClick={handleAvatarClicked}
                            // classes={{
                            //     root: customClass?.avatarButton
                            // }}
                            className={customClass?.avatarButton}
                            size="large"
                        >
                            <Badge badgeContent={count > 1 ? count : null} color="error">
                                <Avatar src={badge_img || placeholderBadge} className={classes.largeAvatar} />
                            </Badge>
                        </IconButton>
                    </Grid>
                    <Hidden xsUp={variant === "mini"}>
                        <Grid item sm={12} container justifyContent="center">
                            <Typography variant="subtitle1">{title}</Typography>
                        </Grid>
                        {subtitle && (
                            <Grid item sm={12} container justifyContent="center">
                                <Link
                                    component="button"
                                    variant="subtitle2"
                                    onClick={handleSubtitleClicked}
                                    color="inherit"
                                >
                                    {subtitle}
                                </Link>
                            </Grid>
                        )}
                    </Hidden>
                </Grid>
            )}
        </Box>
    );
};

export default BadgeCard;
