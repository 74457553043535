import { httpClient } from "../../../../../clients/http.client";
import { t_SurveyPeriod } from "../../../../Surveys/Surveys.types";

const getSurveySummaryRecords = async (payload: {
	survey: string;
	period: t_SurveyPeriod;
}) => {
	const { survey, period } = payload;
	const body = {
		for_survey: survey,
		group_by: ["survey", "service_team", "team", "job_title"],
		for_report: "survey",
		from_date: period.start_date,
		to_date: period.end_date,
	};

	const response = await httpClient.post("/reports/survey/summary", body);
	return response.data.data.summaries;
};

export { getSurveySummaryRecords };
