import { Theme, Tooltip } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

const DarkTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "#ddd",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: "10px 15px",
  },
}))(Tooltip);

export default DarkTooltip;
