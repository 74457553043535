import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const lsaCommentsStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column-reverse",
			gap: "12px",
			width: 400,
		},
		bottomActions: {
			display: "grid",
			width: "100%",
			gridTemplateColumns: "1fr auto",
		},
		hidden: {
			opacity: 0,
		},
		submitButton: {
			height: "100%",
		},
		chipWrapper: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-end",
		},
		myWrapper: {
			alignItems: "flex-start",
		},
		chip: {
			background: "none",
		},
		card: {
			width: "85%",
			borderRadius: theme.spacing(2, 0, 2, 2),

			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		myCard: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,
			borderRadius: theme.spacing(0, 2, 2, 2),
		},
	})
);

export default lsaCommentsStyles;
