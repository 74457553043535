import { Avatar, Badge, Box, Chip, Collapse, Dialog, Divider, IconButton, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ContactsType } from "../../pages/Home/Messaging/Messaging";
import Bot from "../../beYou_bot.png";
import { Add, ChevronLeft, ExpandMore } from "@mui/icons-material";
import CreateMessage from "./CreateMessage";
import { useEffect, useState } from "react";
import { setISODay } from "date-fns";
import { AnyNaptrRecord } from "dns";
import { useUserState } from "../../context/user/user.ctx";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import imWebsocket from "../../api/websockets.api";
import { setThreadUpdate } from "../../redux/slices/ThreadSlice";
import ThreadName from "./ThreadName";
import { MessagesProps, ParticipantProp } from "./types";

type ContactsProps = {
    contacts: ContactsType[];
    activeChat: number;
    setActiveChat: (e: number) => void;
    availbleThreads?: any;
    setUpdateContactsList?: any;
};

const Contacts = (props: ContactsProps) => {
    const theme = useTheme();
    const classes = contactsStyles();
    const { activeChat, setActiveChat, contacts, availbleThreads, setUpdateContactsList } = props;
    const [isCreateMessage, setIsCreateMessage] = useState(false);
    const [isShowChannelMessages, setIsShowChannelMessages] = useState(true);
    const [isShowDirectMessages, setIsShowDirectMessages] = useState(true);
    const [messageType, setMessageType] = useState("");
    const currentUser = useUserState();

    const channels = availbleThreads.filter((threads: MessagesProps) => threads.participants.length > 2);
    const directMessages = availbleThreads.filter((threads: MessagesProps) => threads.participants.length <= 2);
    const directMessageName = directMessages
        .flatMap((message: MessagesProps) => message.participants)
        .filter((participant: ParticipantProp) => participant.EntityNo !== currentUser.entity_no);

    const threadCount = useSelector((state: RootState) => state.thread.threadUpdate.data?.threads);

    return (
        <Box className={classes.contactsBox}>
            <Box className={classes.messaages}>
                <Typography variant="h5" style={{ fontWeight: "bold", fontSize: 20 }}>
                    Channels
                    <IconButton
                        onClick={() => setIsShowChannelMessages(!isShowChannelMessages)}
                        sx={{
                            transform: !isShowChannelMessages ? "rotate(0deg)" : "rotate(180deg)",
                            transition: theme.transitions.create("transform", {
                                duration: theme.transitions.duration.shortest,
                            }),
                        }}
                    >
                        <ExpandMore />
                    </IconButton>
                </Typography>
                <IconButton
                    className={classes.addButton}
                    onClick={() => {
                        setIsCreateMessage(true);
                        setMessageType("channel");
                    }}
                >
                    <Add />
                </IconButton>
            </Box>

            <Collapse in={isShowChannelMessages} timeout="auto" unmountOnExit>
                <Box style={{ maxHeight: 240, overflow: "scroll" }}>
                    {channels.map((thread: MessagesProps) => (
                        <ThreadName
                            thread={thread}
                            threadCount={threadCount}
                            activeChat={activeChat}
                            setActiveChat={setActiveChat}
                        />
                    ))}
                </Box>
            </Collapse>

            <Box className={classes.messaages} mt={2}>
                <Typography variant="h5" style={{ fontWeight: "bold", fontSize: 20 }}>
                    Direct Messages
                    <IconButton
                        onClick={() => setIsShowDirectMessages(!isShowDirectMessages)}
                        sx={{
                            transform: !isShowDirectMessages ? "rotate(0deg)" : "rotate(180deg)",
                            transition: theme.transitions.create("transform", {
                                duration: theme.transitions.duration.shortest,
                            }),
                        }}
                    >
                        <ExpandMore />
                    </IconButton>
                </Typography>
                <IconButton
                    className={classes.addButton}
                    onClick={() => {
                        setIsCreateMessage(true);
                        setMessageType("direct");
                    }}
                >
                    <Add />
                </IconButton>
            </Box>
            <Collapse in={isShowDirectMessages} timeout="auto" unmountOnExit>
                <Box style={{ maxHeight: 240, overflow: "scroll" }}>
                    {directMessages.map((thread: MessagesProps) => (
                        <ThreadName
                            thread={thread}
                            messageType="direct"
                            threadCount={threadCount}
                            activeChat={activeChat}
                            setActiveChat={setActiveChat}
                        />
                    ))}
                </Box>
            </Collapse>
            <Dialog open={isCreateMessage} onClose={() => setIsCreateMessage(false)} fullWidth maxWidth="sm">
                <CreateMessage
                    setIsCreateMessage={setIsCreateMessage}
                    messageType={messageType}
                    setUpdateContactsList={setUpdateContactsList}
                />
            </Dialog>
        </Box>
    );
};
const contactsStyles = makeStyles((theme) => ({
    wrapper: {
        height: "calc(95vh - 64px)",
        fontWeight: "bold",
        padding: " 0 !important",
    },
    messaages: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1001,
        position: "relative",
        padding: 16,
        height: 70,
    },
    contactsTile: {
        width: "100%",
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        borderTop: " 1px solid rgb(240, 240, 240)",
        borderBottom: " 1px solid rgb(240, 240, 240)",
        cursor: "pointer",
    },
    activeContact: {
        background: "rgb(240, 253, 137)",
        transistion: "all 0.5s ease-in-out",
        borderRadius: "25px",
    },

    messagesFromCount: {
        borderRadius: "50%",
        background: "rgba(0,0,0,0.12)",
        textAlign: "center",
        alignSelf: "flex-end",
        width: 40,
        height: 40,
    },

    avatar: {
        width: 50,
        height: 50,
        objectFit: "contain",
    },

    contactsBox: {
        background: theme.palette.background.paper,
        borderRadius: 23,
        height: "100%",
        padding: 5,
        boxShadow:
            " 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    },

    addButton: {
        border: `1px solid ${theme.palette.secondary.main}`,
    },
}));

export default Contacts;
