import { t_LsaDetails } from "../../LowScoreAnalysis.type";

type t_LsaForm = t_LsaDetails & {
	lsa_id?: number;
};
const base_LsaForm: t_LsaForm = {
	landing: false,
	stream: null,
	scope: null,
	action: null,
	group: null,
	primary_type: null,
	primary_cause: null,
	secondary_type: null,
	secondary_cause: null,
	custom: null, 
	practice_type: null,
    appeal_type: null,
    missing_information_type: null,
    inquiry_opened_or_ca_ticket_logged: null,
};

type t_lsaStepperActions = {
	type: "save";
};

export { base_LsaForm };
export type { t_LsaForm, t_lsaStepperActions };
