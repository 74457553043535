import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const teamPerformanceStyles = makeStyles((theme: Theme) =>
	createStyles({
		autoComplete: {
			width: 300,
		},
	})
);

export default teamPerformanceStyles;
