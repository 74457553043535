import { httpClient } from "../../../../clients/http.client";

const updateRolePermissions = (payload: {
	role_id: number;
	grant_permissions: number[];
	revoke_permissions: number[];
}) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/roles/permissions/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});
const updateRoleUsers = (payload: {
	role_id: number;
	grant_for_users: number[];
	revoke_for_users: number[];
}) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/roles/users/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

const updateRolesJobLevels = (payload: {
	role_id: number;
	grant_for_job_levels: number[];
	revoke_for_job_levels: number[];
}) =>
	new Promise<boolean>((resolve, reject) => {
		httpClient
			.post("/admin/roles/job_levels/update", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch(reject);
	});

export { updateRolePermissions, updateRoleUsers, updateRolesJobLevels };
