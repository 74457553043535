import { Grid } from "@mui/material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import DowntimeOptions from "../../tables/DowntimeOptions/DowntimeOptions";
import InitiativeOptions from "../../tables/InitiativeOptions/InitiativeOptions";
import PoolWeights from "../../tables/PoolWeights/PoolWeights";
import ProductionGeneral from "../ProductionGeneral/ProductionGeneral";

const MaintainProduction: React.FC = () => {
    const [sectionTab, setSectionTab] = React.useState(0);

    return (
        <SectionCard
            title="Maintain Production"
            primaryAction={
                <TabGroup
                    tabValue={sectionTab}
                    tabChange={(newValue) => setSectionTab(newValue)}
                    tabList={[
                        { _id: 0, label: "Pool Weights" },
                        { _id: 1, label: "Downtime" },
                        { _id: 2, label: "Additional Volume" },
                        { _id: 3, label: "General" },
                    ]}
                />
            }
            scrolling
        >
            <Grid container spacing={0}>
                {sectionTab === 0 && (
                    <Grid item sm={10}>
                        <PoolWeights />
                    </Grid>
                )}
                {sectionTab === 1 && (
                    <Grid item sm={6}>
                        <DowntimeOptions />
                    </Grid>
                )}
                {sectionTab === 2 && (
                    <Grid item sm={10}>
                        <InitiativeOptions />
                    </Grid>
                )}
                {sectionTab === 3 && (
                    <Grid item sm={12}>
                        <ProductionGeneral />
                    </Grid>
                )}
            </Grid>
        </SectionCard>
    );
};
export default MaintainProduction;
