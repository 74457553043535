import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const appealDialogContentStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			maxHeight: "min(700px, 80vh)",
			minWidth: 300,
		},
		item: {
			maxHeight: "inherit",
		},
	})
);

export default appealDialogContentStyles;
