import { httpClient } from "../../../../clients/http.client";

const saveAppealDecision = (payload: {
	appeal_id?: number;
	comment: string | null;
	approval?: boolean;
}) =>
	new Promise<any>((resolve, reject) => {
		httpClient
			.post("/appeal/decision/save", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data);
						break;
					case "error":
						reject(data.message);
						break;
				}
			})
			.catch((reason) => reject(reason));
	});

export { saveAppealDecision };
