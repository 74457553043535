import { httpClient } from "../../clients/http.client";

export const getGlobalReport = (payload: {
  date: string;
  entityno: string;
}) => {
  const pgsPayload = {
    ...payload,
    pagination: {
      page: 1,
      rows_per_page: 30,
    },
    filter: [
      {
        column_name: "lk_twt_service_environment.TwtServiceEnvironmentID",
        search_term:  '' ,
      },
    ],
    group_sort: {
      column_name: "lk_twt_service_environment.Name",
      order: "ASC",
    },
  };
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/admin/twt/reports", pgsPayload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const serviceTeams = Object.keys(data.data.records.data);
            res({ serviceTeams, data: data.data.records.data });
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

export const getGlobalReportFilter = (payload: {
  date: string;
  entityno: string;
  filter: 
    {
      column_name: string,
      search_term:  string[] | string | number [],
    }[]
  
}) => {
  const pgsPayload = {
    ...payload,
    pagination: {
      page: 1,
      rows_per_page: "all",
    },
    sort: {
      column_name: "service_team_name",
      order: "ASC",
    },
  };
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/admin/twt/reports", pgsPayload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const serviceTeams = Object.keys(data.data.records.data);
            res({ serviceTeams, data: data.data.records.data });
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};
