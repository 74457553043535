import ProgressList from "../../../../components/ProgressList/ProgressList";
import { getMetricFormat } from "../../../../misc/helpers";
import { t_User } from "../../../../types/user.types";

interface Props {
	metric_values: t_User.Metric[];
}

const MetricProgressList: React.FC<Props> = ({ metric_values }) => {
	return (
		<ProgressList
			items={metric_values.map((metric) => ({
				label: metric.name,
				value: metric.value,
				value_label: getMetricFormat(metric.id, metric.value),
				target: metric.target,
			}))}
			baseline={100}
			total={150}
		/>
	);
};

export default MetricProgressList;
