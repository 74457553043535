import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const lsaDialogContentStyles = makeStyles((theme: Theme) =>
	createStyles({
		lsaDialog: {
			background: "none",
		},
		lsaDialogContent: {
			padding: "8px 0 0 0",
			overflow: "hidden",
		},
		lsaDialogHeader: {
			backgroundColor: theme.palette.background.paper,
		},
		container: {
			height: "min(640px, 80vh)",
			minWidth: "min(720px, 80vw)",
		},
		item: {
			height: "inherit",
		},
		hiddenComment: {
			visibility: "hidden",
		},
		agentActions: {
			display: "flex",
			width: "100%",
			justifyContent: "space-evenly",
		},
	})
);

export default lsaDialogContentStyles;
