import {
    AutocompleteRenderInputParams,
    CircularProgress,
    Grid,
    List,
    ListItem,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { getIncentiveCategories } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.api";
import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";
import { t_InitiativeType } from "../../forms/AdditionalProduction/AdditionalProduction.types";
import AverageAdditionalVolumeItems from "../../graphs/AverageAdditionalVolumeItems/AverageAdditionalVolumeItems";
import TotalAdditionalVolumeItems from "../../graphs/TotalAdditionalVolumeItems/TotalAdditionalVolumeItems";

type t_autocompleteOptions<T> = {
    term?: string;
    selected?: T;
    loading?: boolean;
};

const AutocompleteInput = (
    props: AutocompleteRenderInputParams &
        TextFieldProps & {
            loading?: boolean;
        },
) => {
    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {props.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
    );
};

interface Props {}

const AdditionalVolumeReport: React.FC<Props> = ({}) => {
    const [incentives, setIncentives] = React.useState<t_IncentiveType[]>([]);
    const [initiatives, setInitiatives] = React.useState<t_InitiativeType[]>([]);
    const [criteria, setCriteria] = React.useState<{
        incentive: t_autocompleteOptions<t_IncentiveType>;
        initiative: t_autocompleteOptions<t_InitiativeType>;
    }>({
        incentive: {},
        initiative: {},
    });

    const handleTermChange = (for_criteria: "incentive" | "initiative", term: string | undefined) => {
        setCriteria((state) => ({
            ...state,
            [for_criteria]: {
                term: term,
                loading: term !== undefined,
            },
        }));
    };

    const handleCriteriaChange = (
        for_criteria: "incentive" | "initiative",
        value: t_IncentiveType | t_InitiativeType | null,
    ) => {
        setCriteria((state) => ({
            ...state,
            [for_criteria]: {
                selected: value ?? undefined,
            },
        }));
    };

    React.useEffect(() => {
        var isActive = true;
        if (criteria.incentive.term !== undefined) {
            getIncentiveCategories({
                filter: [
                    {
                        column_name: "Name",
                        search_term: criteria.incentive.term,
                    },
                ],
                pagination: {
                    page: 1,
                    rows_per_page: 20,
                },
            }).then((data) => {
                isActive && setIncentives(data);
            });
        } else {
            isActive && setIncentives([]);
        }

        return () => {
            isActive = false;
        };
    }, [criteria.incentive.term]);

    React.useEffect(() => {}, [criteria.incentive.selected]);
    return (
        <SectionCard title="Additional Volume" scrolling>
            <Grid container spacing={2}>
                <Grid item sm={12} container>
                    <Grid item sm={4}>
                        <SectionCard title="Criteria" titleProps={{ variant: "h6" }} hasBackground noHeader>
                            <List>
                                <ListItem>
                                    <Autocomplete
                                        id="incentive"
                                        fullWidth
                                        options={incentives}
                                        getOptionLabel={(option) => option.name}
                                        onOpen={() => handleTermChange("incentive", "")}
                                        onClose={() => handleTermChange("incentive", undefined)}
                                        value={criteria.incentive.selected}
                                        onChange={(_, value) => handleCriteriaChange("incentive", value)}
                                        renderInput={(params) => (
                                            <AutocompleteInput
                                                {...params}
                                                onChange={(e: any) => handleTermChange("incentive", e.target.value)}
                                                label="Incentive Category"
                                                loading={criteria.incentive.loading}
                                            />
                                        )}
                                    />
                                </ListItem>
                                <ListItem>
                                    <Autocomplete
                                        id="initiative"
                                        fullWidth
                                        options={initiatives}
                                        getOptionLabel={(option) => option.name}
                                        disabled={!Boolean(criteria.incentive.selected)}
                                        onOpen={() => handleTermChange("initiative", "")}
                                        onClose={() => handleTermChange("initiative", undefined)}
                                        onChange={(_, value) => handleCriteriaChange("initiative", value)}
                                        renderInput={(params) => (
                                            <AutocompleteInput
                                                {...params}
                                                onChange={(e: any) => handleTermChange("initiative", e.target.value)}
                                                label="Initiative"
                                                helperText={
                                                    !Boolean(criteria.incentive.selected) &&
                                                    "Select an incentive category first."
                                                }
                                                loading={criteria.initiative.loading}
                                            />
                                        )}
                                    />
                                </ListItem>
                            </List>
                        </SectionCard>
                    </Grid>
                </Grid>
                <Grid item sm={6}>
                    <TotalAdditionalVolumeItems
                        incentive_category={criteria.incentive.selected}
                        initiative={criteria.initiative.selected}
                    />
                </Grid>
                <Grid item sm={6}>
                    <AverageAdditionalVolumeItems
                        incentive_category={criteria.incentive.selected}
                        initiative={criteria.initiative.selected}
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AdditionalVolumeReport;
