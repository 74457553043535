export const metric_values = [
    {
        id: 1,
        name: "AHT",
        value: 376.57,
        target: 300,
        percentage_of_target: 26,
        comparison_value: 372.68,
        comparison_percentage_of_target: 24,
        change: 3.89,
        percentage_change: -1,
    },
    {
        id: 2,
        name: "DPMO",
        value: 4366.81,
        target: 15301,
        percentage_of_target: 100,
        comparison_value: 0,
        comparison_percentage_of_target: 0,
        change: 4366.81,
        percentage_change: -100,
    },
    {
        id: 3,
        name: "FCR",
        value: 78.57,
        target: 80,
        percentage_of_target: 98,
        comparison_value: 77.78,
        comparison_percentage_of_target: 97,
        change: 0.79,
        percentage_change: 1,
    },
    {
        id: 4,
        name: "MBR",
        value: 9.57,
        target: 9.1,
        percentage_of_target: 100,
        comparison_value: 9.93,
        comparison_percentage_of_target: 100,
        change: -0.36,
        percentage_change: -4,
    },
    {
        id: 5,
        name: "Production",
        value: 22.22,
        target: 40,
        percentage_of_target: 56,
        comparison_value: 7.06,
        comparison_percentage_of_target: 18,
        change: 15.16,
        percentage_change: 215,
    },
    {
        id: 6,
        name: "TransferRatio",
        value: 8.94,
        target: 20,
        percentage_of_target: 100,
        comparison_value: 9.71,
        comparison_percentage_of_target: 100,
        change: -0.77,
        percentage_change: 8,
    },
    {
        id: 7,
        name: "Attendance",
        value: 92.86,
        target: 90,
        percentage_of_target: 100,
        comparison_value: 80.71,
        comparison_percentage_of_target: 90,
        change: 12.15,
        percentage_change: 15,
    },
    {
        id: 11,
        name: "Total Inbound",
        value: 35.45,
        target: 100,
        percentage_of_target: 35,
        comparison_value: 35.28,
        comparison_percentage_of_target: 35,
        change: 0.17,
        percentage_change: 0,
    },
    {
        id: 12,
        name: "Engagement",
        value: 76.67,
        target: 90,
        percentage_of_target: 85,
        comparison_value: 80,
        comparison_percentage_of_target: 89,
        change: -3.33,
        percentage_change: -4,
    },
    {
        id: 13,
        name: "AVG Hold Time",
        value: 212.2,
        target: 10,
        percentage_of_target: 100,
        comparison_value: 211.25,
        comparison_percentage_of_target: 100,
        change: 0.95,
        percentage_change: 0,
    },
];

export const team_metrics = [
    {
        user: {
            id: 5810,
            entity_no: "1118633823",
            name: "Robyn Tyman",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "rrrrrrrrrrrrrrrrr.jpg",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 2,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 158.87,
                target: 300,
                percentage_of_target: 100,
                comparison_value: 184.77,
                comparison_percentage_of_target: 100,
                change: -25.9,
                percentage_change: 14,
                team_avg: 46,
                rank: 2,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 14084.51,
                target: 0,
                percentage_of_target: 0,
                comparison_value: 19531.25,
                comparison_percentage_of_target: 0,
                change: -5446.74,
                percentage_change: 28,
                team_avg: 6,
                rank: 4,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 2.13,
                target: 40,
                percentage_of_target: 5,
                comparison_value: 5.15,
                comparison_percentage_of_target: 13,
                change: -3.02,
                percentage_change: -59,
                team_avg: 17,
                rank: 11,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 90,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 81.82,
                comparison_percentage_of_target: 91,
                change: 8.18,
                percentage_change: 10,
                team_avg: 100,
                rank: 4,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 5.45,
                target: 100,
                percentage_of_target: 5,
                comparison_value: 4.92,
                comparison_percentage_of_target: 5,
                change: 0.53,
                percentage_change: 11,
                team_avg: 30,
                rank: 11,
            },
        },
        score: 2706,
        rank: 1,
    },
    {
        user: {
            id: 4838,
            entity_no: "1095629216",
            name: "Candice Constable",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 5,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 166.21,
                target: 300,
                percentage_of_target: 100,
                comparison_value: 175.85,
                comparison_percentage_of_target: 100,
                change: -9.64,
                percentage_change: 5,
                team_avg: 46,
                rank: 3,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 10791.37,
                target: 0,
                percentage_of_target: 0,
                comparison_value: 0,
                comparison_percentage_of_target: 100,
                change: 10791.37,
                percentage_change: -100,
                team_avg: 6,
                rank: 3,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 3.13,
                target: 40,
                percentage_of_target: 8,
                comparison_value: 2.91,
                comparison_percentage_of_target: 7,
                change: 0.22,
                percentage_change: 8,
                team_avg: 17,
                rank: 9,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 95,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 77.27,
                comparison_percentage_of_target: 86,
                change: 17.73,
                percentage_change: 23,
                team_avg: 100,
                rank: 2,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 1.73,
                target: 100,
                percentage_of_target: 2,
                comparison_value: 1.82,
                comparison_percentage_of_target: 2,
                change: -0.09,
                percentage_change: -5,
                team_avg: 30,
                rank: 13,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 7,
                target: 10,
                percentage_of_target: 70,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 7,
                percentage_change: 100,
                team_avg: 98,
                rank: 12,
            },
        },
        score: 2049,
        rank: 2,
    },
    {
        user: {
            id: 4556,
            entity_no: "1085977930",
            name: "Misha Victor",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 1,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 128.82,
                target: 300,
                percentage_of_target: 100,
                comparison_value: 243.7,
                comparison_percentage_of_target: 100,
                change: -114.88,
                percentage_change: 47,
                team_avg: 46,
                rank: 1,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 8771.93,
                target: 1,
                percentage_of_target: 0,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 8771.93,
                percentage_change: -100,
                team_avg: 6,
                rank: 2,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 7,
                target: 8.8,
                percentage_of_target: 80,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 7,
                percentage_change: 100,
                team_avg: 96,
                rank: 11,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 21.75,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 15.64,
                comparison_percentage_of_target: 78,
                change: 6.11,
                percentage_change: 39,
                team_avg: 17,
                rank: 1,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 100,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 70.45,
                comparison_percentage_of_target: 78,
                change: 29.55,
                percentage_change: 42,
                team_avg: 100,
                rank: 1,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 1.38,
                target: 100,
                percentage_of_target: 1,
                comparison_value: 3.08,
                comparison_percentage_of_target: 3,
                change: -1.7,
                percentage_change: -55,
                team_avg: 30,
                rank: 14,
            },
        },
        score: 1737,
        rank: 3,
    },
    {
        user: {
            id: 5069,
            entity_no: "1101775037",
            name: "Enoch Zantsi",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 2,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 338.61,
                target: 300,
                percentage_of_target: 13,
                comparison_value: 464.13,
                comparison_percentage_of_target: 55,
                change: -125.52,
                percentage_change: 27,
                team_avg: 46,
                rank: 5,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 3597.12,
                target: 0,
                percentage_of_target: 0,
                comparison_value: 15686.27,
                comparison_percentage_of_target: 0,
                change: -12089.15,
                percentage_change: 77,
                team_avg: 6,
                rank: 1,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 2,
                target: 40,
                percentage_of_target: 5,
                comparison_value: 13.17,
                comparison_percentage_of_target: 33,
                change: -11.17,
                percentage_change: -85,
                team_avg: 17,
                rank: 12,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 90,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 68.18,
                comparison_percentage_of_target: 76,
                change: 21.82,
                percentage_change: 32,
                team_avg: 100,
                rank: 4,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 4.15,
                target: 100,
                percentage_of_target: 4,
                comparison_value: 5.31,
                comparison_percentage_of_target: 5,
                change: -1.16,
                percentage_change: -22,
                team_avg: 30,
                rank: 12,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 125.5,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 24,
                comparison_percentage_of_target: 100,
                change: 101.5,
                percentage_change: 423,
                team_avg: 98,
                rank: 10,
            },
        },
        score: 770,
        rank: 4,
    },
    {
        user: {
            id: 11932,
            entity_no: "1096938889",
            name: "Brittany Williams",
            job_title: null,
            job_level: "1",
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: null,
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 3,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 378.17,
                target: 300,
                percentage_of_target: 26,
                comparison_value: 427.01,
                comparison_percentage_of_target: 42,
                change: -48.84,
                percentage_change: 11,
                team_avg: 46,
                rank: 11,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 92.86,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 100,
                comparison_percentage_of_target: 100,
                change: -7.14,
                percentage_change: -7,
                team_avg: 88,
                rank: 2,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9.25,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 9.75,
                comparison_percentage_of_target: 100,
                change: -0.5,
                percentage_change: -5,
                team_avg: 96,
                rank: 6,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 8.09,
                target: 40,
                percentage_of_target: 20,
                comparison_value: 7.11,
                comparison_percentage_of_target: 18,
                change: 0.98,
                percentage_change: 14,
                team_avg: 17,
                rank: 2,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 8.71,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 10.12,
                comparison_percentage_of_target: 100,
                change: -1.41,
                percentage_change: 14,
                team_avg: 100,
                rank: 4,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 95,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 81.82,
                comparison_percentage_of_target: 91,
                change: 13.18,
                percentage_change: 16,
                team_avg: 100,
                rank: 2,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 44.4,
                target: 100,
                percentage_of_target: 44,
                comparison_value: 40.5,
                comparison_percentage_of_target: 41,
                change: 3.9,
                percentage_change: 10,
                team_avg: 30,
                rank: 2,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 10,
                target: 90,
                percentage_of_target: 11,
                comparison_value: 9.09,
                comparison_percentage_of_target: 10,
                change: 0.91,
                percentage_change: 10,
                team_avg: 46,
                rank: 6,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 359.98,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 397.05,
                comparison_percentage_of_target: 100,
                change: -37.07,
                percentage_change: -9,
                team_avg: 98,
                rank: 1,
            },
        },
        score: 136,
        rank: 5,
    },
    {
        user: {
            id: 6616,
            entity_no: "1175501806",
            name: "Ntombi Cengimbo",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 1,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 378.25,
                target: 300,
                percentage_of_target: 26,
                comparison_value: 371.83,
                comparison_percentage_of_target: 24,
                change: 6.42,
                percentage_change: -2,
                team_avg: 46,
                rank: 12,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 88.89,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 90,
                comparison_percentage_of_target: 100,
                change: -1.11,
                percentage_change: -1,
                team_avg: 88,
                rank: 3,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9.83,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 9.63,
                comparison_percentage_of_target: 100,
                change: 0.2,
                percentage_change: 2,
                team_avg: 96,
                rank: 2,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 6.98,
                target: 40,
                percentage_of_target: 17,
                comparison_value: 5.88,
                comparison_percentage_of_target: 15,
                change: 1.1,
                percentage_change: 19,
                team_avg: 17,
                rank: 3,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 7.88,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 10.09,
                comparison_percentage_of_target: 100,
                change: -2.21,
                percentage_change: 22,
                team_avg: 100,
                rank: 3,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 92.5,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 75,
                comparison_percentage_of_target: 83,
                change: 17.5,
                percentage_change: 23,
                team_avg: 100,
                rank: 3,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 38.07,
                target: 100,
                percentage_of_target: 38,
                comparison_value: 41.28,
                comparison_percentage_of_target: 41,
                change: -3.21,
                percentage_change: -8,
                team_avg: 30,
                rank: 7,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 10,
                target: 90,
                percentage_of_target: 11,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 10,
                percentage_change: 100,
                team_avg: 46,
                rank: 6,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 354.36,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 309.97,
                comparison_percentage_of_target: 100,
                change: 44.39,
                percentage_change: 14,
                team_avg: 98,
                rank: 2,
            },
        },
        score: 134,
        rank: 6,
    },
    {
        user: {
            id: 6555,
            entity_no: "1142574837",
            name: "Tasneem Jacobs",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 5,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 7,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 4,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 419.33,
                target: 300,
                percentage_of_target: 40,
                comparison_value: 460.97,
                comparison_percentage_of_target: 54,
                change: -41.64,
                percentage_change: 9,
                team_avg: 46,
                rank: 14,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 100,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 100,
                comparison_percentage_of_target: 100,
                change: 0,
                percentage_change: 0,
                team_avg: 88,
                rank: 1,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9.67,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 10,
                comparison_percentage_of_target: 100,
                change: -0.33,
                percentage_change: -3,
                team_avg: 96,
                rank: 4,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 6.06,
                target: 40,
                percentage_of_target: 15,
                comparison_value: 5.34,
                comparison_percentage_of_target: 13,
                change: 0.72,
                percentage_change: 13,
                team_avg: 17,
                rank: 4,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 10.78,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 8.64,
                comparison_percentage_of_target: 100,
                change: 2.14,
                percentage_change: -25,
                team_avg: 100,
                rank: 7,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 90,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 72.73,
                comparison_percentage_of_target: 81,
                change: 17.27,
                percentage_change: 24,
                team_avg: 100,
                rank: 4,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 41.38,
                target: 100,
                percentage_of_target: 41,
                comparison_value: 36.67,
                comparison_percentage_of_target: 37,
                change: 4.71,
                percentage_change: 13,
                team_avg: 30,
                rank: 6,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 5,
                target: 90,
                percentage_of_target: 6,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 5,
                percentage_change: 100,
                team_avg: 46,
                rank: 7,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 249,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 257.29,
                comparison_percentage_of_target: 100,
                change: -8.29,
                percentage_change: -3,
                team_avg: 98,
                rank: 6,
            },
        },
        score: 126,
        rank: 7,
    },
    {
        user: {
            id: 6122,
            entity_no: "1123872655",
            name: "Jawwaad Gierdien",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 1,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 7,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 14,
                    badge_img: "/images/20DaysEngaged.png",
                    name: "20 Days engaged",
                    description: "Logged in for 20 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 361.32,
                target: 300,
                percentage_of_target: 20,
                comparison_value: 393.32,
                comparison_percentage_of_target: 31,
                change: -32,
                percentage_change: 8,
                team_avg: 46,
                rank: 8,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 66.67,
                target: 80,
                percentage_of_target: 83,
                comparison_value: 75,
                comparison_percentage_of_target: 94,
                change: -8.33,
                percentage_change: -11,
                team_avg: 88,
                rank: 5,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 8.88,
                target: 9.1,
                percentage_of_target: 98,
                comparison_value: 9.8,
                comparison_percentage_of_target: 100,
                change: -0.92,
                percentage_change: -9,
                team_avg: 96,
                rank: 8,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 3.52,
                target: 40,
                percentage_of_target: 9,
                comparison_value: 12.17,
                comparison_percentage_of_target: 30,
                change: -8.65,
                percentage_change: -71,
                team_avg: 17,
                rank: 7,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 7.31,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 10.26,
                comparison_percentage_of_target: 100,
                change: -2.95,
                percentage_change: 29,
                team_avg: 100,
                rank: 1,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 90,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 72.73,
                comparison_percentage_of_target: 81,
                change: 17.27,
                percentage_change: 24,
                team_avg: 100,
                rank: 4,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 33.69,
                target: 100,
                percentage_of_target: 34,
                comparison_value: 29.78,
                comparison_percentage_of_target: 30,
                change: 3.91,
                percentage_change: 13,
                team_avg: 30,
                rank: 8,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 75,
                target: 90,
                percentage_of_target: 83,
                comparison_value: 81.82,
                comparison_percentage_of_target: 91,
                change: -6.82,
                percentage_change: -8,
                team_avg: 46,
                rank: 3,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 256.89,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 270.79,
                comparison_percentage_of_target: 100,
                change: -13.9,
                percentage_change: -5,
                team_avg: 98,
                rank: 5,
            },
        },
        score: 122,
        rank: 8,
    },
    {
        user: {
            id: 11583,
            entity_no: "1182984995",
            name: "Taariq Isaacs",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: null,
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 3,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 5,
                    id: 14,
                    badge_img: "/images/20DaysEngaged.png",
                    name: "20 Days engaged",
                    description: "Logged in for 20 days in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 374.6,
                target: 300,
                percentage_of_target: 25,
                comparison_value: 372.67,
                comparison_percentage_of_target: 24,
                change: 1.93,
                percentage_change: -1,
                team_avg: 46,
                rank: 10,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 18518.52,
                target: 15301,
                percentage_of_target: 21,
                comparison_value: 0,
                comparison_percentage_of_target: 0,
                change: 18518.52,
                percentage_change: -100,
                team_avg: 6,
                rank: 6,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 66.67,
                target: 80,
                percentage_of_target: 83,
                comparison_value: 88.89,
                comparison_percentage_of_target: 100,
                change: -22.22,
                percentage_change: -25,
                team_avg: 88,
                rank: 5,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 10,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 10,
                comparison_percentage_of_target: 100,
                change: 0,
                percentage_change: 0,
                team_avg: 96,
                rank: 1,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 1.75,
                target: 40,
                percentage_of_target: 4,
                comparison_value: 4.93,
                comparison_percentage_of_target: 12,
                change: -3.18,
                percentage_change: -65,
                team_avg: 17,
                rank: 13,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 12.29,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 11.46,
                comparison_percentage_of_target: 100,
                change: 0.83,
                percentage_change: -7,
                team_avg: 100,
                rank: 8,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 95,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 81.82,
                comparison_percentage_of_target: 91,
                change: 13.18,
                percentage_change: 16,
                team_avg: 100,
                rank: 2,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 43.93,
                target: 100,
                percentage_of_target: 44,
                comparison_value: 40.15,
                comparison_percentage_of_target: 40,
                change: 3.78,
                percentage_change: 9,
                team_avg: 30,
                rank: 3,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 80,
                target: 90,
                percentage_of_target: 89,
                comparison_value: 95.45,
                comparison_percentage_of_target: 100,
                change: -15.45,
                percentage_change: -16,
                team_avg: 46,
                rank: 2,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 151.72,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 138.62,
                comparison_percentage_of_target: 100,
                change: 13.1,
                percentage_change: 9,
                team_avg: 98,
                rank: 8,
            },
        },
        score: 121,
        rank: 9,
    },
    {
        user: {
            id: 5466,
            entity_no: "1111299283",
            name: "Shaun Pillay",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 2,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 406.97,
                target: 300,
                percentage_of_target: 36,
                comparison_value: 371.51,
                comparison_percentage_of_target: 24,
                change: 35.46,
                percentage_change: -10,
                team_avg: 46,
                rank: 13,
            },
            DPMO: {
                id: 2,
                name: "DPMO",
                value: 17241.38,
                target: 15301,
                percentage_of_target: 13,
                comparison_value: 17857.14,
                comparison_percentage_of_target: 17,
                change: -615.76,
                percentage_change: 3,
                team_avg: 6,
                rank: 5,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 33.33,
                target: 80,
                percentage_of_target: 42,
                comparison_value: 50,
                comparison_percentage_of_target: 63,
                change: -16.67,
                percentage_change: -33,
                team_avg: 88,
                rank: 7,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9.8,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 7.43,
                comparison_percentage_of_target: 82,
                change: 2.37,
                percentage_change: 32,
                team_avg: 96,
                rank: 3,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 3.5,
                target: 40,
                percentage_of_target: 9,
                comparison_value: 4.09,
                comparison_percentage_of_target: 10,
                change: -0.59,
                percentage_change: -14,
                team_avg: 17,
                rank: 8,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 9.42,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 11.66,
                comparison_percentage_of_target: 100,
                change: -2.24,
                percentage_change: 19,
                team_avg: 100,
                rank: 5,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 100,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 75,
                comparison_percentage_of_target: 83,
                change: 25,
                percentage_change: 33,
                team_avg: 100,
                rank: 1,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 27.6,
                target: 100,
                percentage_of_target: 28,
                comparison_value: 37,
                comparison_percentage_of_target: 37,
                change: -9.4,
                percentage_change: -25,
                team_avg: 30,
                rank: 10,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 350.61,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 293.24,
                comparison_percentage_of_target: 100,
                change: 57.37,
                percentage_change: 20,
                team_avg: 98,
                rank: 3,
            },
        },
        score: 114,
        rank: 10,
    },
    {
        user: {
            id: 4462,
            entity_no: "1082416429",
            name: "Venesh Moodley",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 4,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 4,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 7,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 369.92,
                target: 300,
                percentage_of_target: 23,
                comparison_value: 427,
                comparison_percentage_of_target: 42,
                change: -57.08,
                percentage_change: 13,
                team_avg: 46,
                rank: 9,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 80,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 100,
                comparison_percentage_of_target: 100,
                change: -20,
                percentage_change: -20,
                team_avg: 88,
                rank: 4,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 8.25,
                target: 9.1,
                percentage_of_target: 91,
                comparison_value: 9.75,
                comparison_percentage_of_target: 100,
                change: -1.5,
                percentage_change: -15,
                team_avg: 96,
                rank: 9,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 4.55,
                target: 40,
                percentage_of_target: 11,
                comparison_value: 1.55,
                comparison_percentage_of_target: 4,
                change: 3,
                percentage_change: 194,
                team_avg: 17,
                rank: 6,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 12.56,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 11.15,
                comparison_percentage_of_target: 100,
                change: 1.41,
                percentage_change: -13,
                team_avg: 100,
                rank: 10,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 90,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 50,
                comparison_percentage_of_target: 56,
                change: 40,
                percentage_change: 80,
                team_avg: 100,
                rank: 4,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 31.85,
                target: 100,
                percentage_of_target: 32,
                comparison_value: 28.55,
                comparison_percentage_of_target: 29,
                change: 3.3,
                percentage_change: 12,
                team_avg: 30,
                rank: 9,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 60,
                target: 90,
                percentage_of_target: 67,
                comparison_value: 72.73,
                comparison_percentage_of_target: 81,
                change: -12.73,
                percentage_change: -18,
                team_avg: 46,
                rank: 4,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 168.68,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 142.32,
                comparison_percentage_of_target: 100,
                change: 26.36,
                percentage_change: 19,
                team_avg: 98,
                rank: 7,
            },
        },
        score: 112,
        rank: 11,
    },
    {
        user: {
            id: 5715,
            entity_no: "1116641943",
            name: "Samantha Ndlazilwana",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 3,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 4,
                    id: 14,
                    badge_img: "/images/20DaysEngaged.png",
                    name: "20 Days engaged",
                    description: "Logged in for 20 days in a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 344.95,
                target: 300,
                percentage_of_target: 15,
                comparison_value: 378.46,
                comparison_percentage_of_target: 26,
                change: -33.51,
                percentage_change: 9,
                team_avg: 46,
                rank: 7,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 100,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 100,
                comparison_percentage_of_target: 100,
                change: 0,
                percentage_change: 0,
                team_avg: 88,
                rank: 1,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9,
                target: 9.1,
                percentage_of_target: 99,
                comparison_value: 9.78,
                comparison_percentage_of_target: 100,
                change: -0.78,
                percentage_change: -8,
                team_avg: 96,
                rank: 7,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 2.19,
                target: 40,
                percentage_of_target: 5,
                comparison_value: 2.91,
                comparison_percentage_of_target: 7,
                change: -0.72,
                percentage_change: -25,
                team_avg: 17,
                rank: 10,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 12.42,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 13.17,
                comparison_percentage_of_target: 100,
                change: -0.75,
                percentage_change: 6,
                team_avg: 100,
                rank: 9,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 100,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 81.82,
                comparison_percentage_of_target: 91,
                change: 18.18,
                percentage_change: 22,
                team_avg: 100,
                rank: 1,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 42.4,
                target: 100,
                percentage_of_target: 42,
                comparison_value: 43.95,
                comparison_percentage_of_target: 44,
                change: -1.55,
                percentage_change: -4,
                team_avg: 30,
                rank: 5,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 85,
                target: 90,
                percentage_of_target: 94,
                comparison_value: 90.91,
                comparison_percentage_of_target: 100,
                change: -5.91,
                percentage_change: -7,
                team_avg: 46,
                rank: 1,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 100.62,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 120.09,
                comparison_percentage_of_target: 100,
                change: -19.47,
                percentage_change: -16,
                team_avg: 98,
                rank: 11,
            },
        },
        score: 108,
        rank: 12,
    },
    {
        user: {
            id: 4856,
            entity_no: "1096122773",
            name: "Cumane Nyaba",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: "Wcp",
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 1,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 3,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 234.38,
                target: 300,
                percentage_of_target: 100,
                comparison_value: 251.45,
                comparison_percentage_of_target: 100,
                change: -17.07,
                percentage_change: 7,
                team_avg: 46,
                rank: 4,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 88.89,
                target: 80,
                percentage_of_target: 100,
                comparison_value: 100,
                comparison_percentage_of_target: 100,
                change: -11.11,
                percentage_change: -11,
                team_avg: 88,
                rank: 3,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 7.78,
                target: 9.1,
                percentage_of_target: 85,
                comparison_value: 10,
                comparison_percentage_of_target: 100,
                change: -2.22,
                percentage_change: -22,
                team_avg: 96,
                rank: 10,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 5.06,
                target: 40,
                percentage_of_target: 13,
                comparison_value: 3.86,
                comparison_percentage_of_target: 10,
                change: 1.2,
                percentage_change: 31,
                team_avg: 17,
                rank: 5,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 7.5,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 5.88,
                comparison_percentage_of_target: 100,
                change: 1.62,
                percentage_change: -28,
                team_avg: 100,
                rank: 2,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 95,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 72.73,
                comparison_percentage_of_target: 81,
                change: 22.27,
                percentage_change: 31,
                team_avg: 100,
                rank: 2,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 57.14,
                target: 100,
                percentage_of_target: 57,
                comparison_value: 52.13,
                comparison_percentage_of_target: 52,
                change: 5.01,
                percentage_change: 10,
                team_avg: 30,
                rank: 1,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 5,
                target: 90,
                percentage_of_target: 6,
                comparison_value: 9.09,
                comparison_percentage_of_target: 10,
                change: -4.09,
                percentage_change: -45,
                team_avg: 46,
                rank: 7,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 264.38,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 232.97,
                comparison_percentage_of_target: 100,
                change: 31.41,
                percentage_change: 13,
                team_avg: 98,
                rank: 4,
            },
        },
        score: 104,
        rank: 13,
    },
    {
        user: {
            id: 12183,
            entity_no: "1600172140",
            name: "Niyaaz Dawjee",
            job_title: null,
            job_level: 1,
            tier: {
                label: null,
                color: null,
            },
            profile_img: "defualt_profile.png",
            region: null,
            wins: null,
            losses: null,
            badges: null,
            bio: "This User has no Bio.",
            badge_collection: [
                {
                    count: 2,
                    id: 9,
                    badge_img: "/images/Perfect10.png",
                    name: "Perfect 10",
                    description: "Achieved an average of 10 MBR in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 10,
                    badge_img: "/images/100FCR.png",
                    name: "100% FCR",
                    description: "Achieved a100% FCR is a month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 11,
                    badge_img: "/images/ZeroDefect.png",
                    name: "Zero Defect",
                    description: "0 DPMO achieved in a given month",
                    is_system: 1,
                },
                {
                    count: 1,
                    id: 12,
                    badge_img: "/images/5DaysEngaged.png",
                    name: "5 Days engaged",
                    description: "Logged in for 5 days in a month",
                    is_system: 1,
                },
                {
                    count: 6,
                    id: 13,
                    badge_img: "/images/10DaysEngaged.png",
                    name: "10 Days engaged",
                    description: "Logged in for 10 days in a month",
                    is_system: 1,
                },
                {
                    count: 2,
                    id: 27,
                    badge_img: "/images/perfect_attendance.png",
                    name: "Perfect Attendance",
                    description: "Achieved 100% Attendance in a month",
                    is_system: 1,
                },
            ],
        },
        metrics: {
            AHT: {
                id: 1,
                name: "AHT",
                value: 340.4,
                target: 300,
                percentage_of_target: 13,
                comparison_value: 313.66,
                comparison_percentage_of_target: 5,
                change: 26.74,
                percentage_change: -9,
                team_avg: 46,
                rank: 6,
            },
            FCR: {
                id: 3,
                name: "FCR",
                value: 60,
                target: 80,
                percentage_of_target: 75,
                comparison_value: 62.5,
                comparison_percentage_of_target: 78,
                change: -2.5,
                percentage_change: -4,
                team_avg: 88,
                rank: 6,
            },
            MBR: {
                id: 4,
                name: "MBR",
                value: 9.5,
                target: 9.1,
                percentage_of_target: 100,
                comparison_value: 9.42,
                comparison_percentage_of_target: 100,
                change: 0.08,
                percentage_change: 1,
                team_avg: 96,
                rank: 5,
            },
            Production: {
                id: 5,
                name: "Production",
                value: 5.06,
                target: 40,
                percentage_of_target: 13,
                comparison_value: 6.09,
                comparison_percentage_of_target: 15,
                change: -1.03,
                percentage_change: -17,
                team_avg: 17,
                rank: 5,
            },
            TransferRatio: {
                id: 6,
                name: "TransferRatio",
                value: 9.54,
                target: 20,
                percentage_of_target: 100,
                comparison_value: 10.86,
                comparison_percentage_of_target: 100,
                change: -1.32,
                percentage_change: 12,
                team_avg: 100,
                rank: 6,
            },
            Attendance: {
                id: 7,
                name: "Attendance",
                value: 100,
                target: 90,
                percentage_of_target: 100,
                comparison_value: 72.73,
                comparison_percentage_of_target: 81,
                change: 27.27,
                percentage_change: 37,
                team_avg: 100,
                rank: 1,
            },
            "Total Inbound": {
                id: 11,
                name: "Total Inbound",
                value: 43.33,
                target: 100,
                percentage_of_target: 43,
                comparison_value: 45,
                comparison_percentage_of_target: 45,
                change: -1.67,
                percentage_change: -4,
                team_avg: 30,
                rank: 4,
            },
            Engagement: {
                id: 12,
                name: "Engagement",
                value: 45,
                target: 90,
                percentage_of_target: 50,
                comparison_value: 59.09,
                comparison_percentage_of_target: 66,
                change: -14.09,
                percentage_change: -24,
                team_avg: 46,
                rank: 5,
            },
            "AVG Hold Time": {
                id: 13,
                name: "AVG Hold Time",
                value: 148.5,
                target: 10,
                percentage_of_target: 100,
                comparison_value: 100.16,
                comparison_percentage_of_target: 100,
                change: 48.34,
                percentage_change: 48,
                team_avg: 98,
                rank: 9,
            },
        },
        score: 103,
        rank: 14,
    },
];

export const walkThroughNotifications = {
    badges: [
        {
            id: 526374,
            date: "2023-03-01",
            awarded_by: "System",
            badge_name: "Perfect Attendance",
            badge_image: "/images/perfect_attendance.png",
        },
        {
            id: 534564,
            date: "2023-05-01",
            awarded_by: "System",
            badge_name: "Perfect Attendance",
            badge_image: "/images/perfect_attendance.png",
        },
    ],
    lsa: [
        {
            id: 115807,
            lsa: {
                LsaID: 20246,
                LsaDateAdded: "2022-04-07",
                LsaDataID: "518289",
                LsaDataMetricID: "3",
                StreamID: "1",
                PrimaryQueryTypeID: "2",
                SecondaryQueryTypeID: "41",
                LowScoreGroupingID: "6",
                PrimaryLowScoreCauseID: "41",
                SecondaryLowScoreCauseID: "347",
                ResponsibleScopeID: "6",
                ActionTakenID: "4",
                CreatedByEntityNo: "1031009424",
                CreatedByJobLevel: "3",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "1",
                StatusID: "3",
                SoftLanding: "0",
                AssessorsEntity: "1031009424",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "518289",
                    DateAdded: "2022-04-01 08:30:21",
                    Months: "1-Apr-22",
                    SurveyTypeID: "3",
                    SurveyID: "8660537674",
                    DivisionID: "2",
                    CostCenterID: "30",
                    TeamID: "0",
                    EntityNo: "1123872655",
                    CallID: "8659050794",
                    CallDateAnswered: "2022-03-30",
                    SurveyDate: "2022-03-31",
                    ResponseID: "8660544758",
                    SurveyScore: "1",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments: "",
                    Imported: "1",
                    RemovalLevelID: "0",
                    fcrImport: "1",
                    SurveyEntityNo: "1047917982",
                    KeyWord:
                        "BENPMB (CONFIRM) (CS - General - Benefit Queries) | CIBMEDAP (CIBMEDS) (Chronic) | CLAIMSP (CLAIMQ) (CCP) | CLMPMEXP (COMPLAIN) (CS - General - Claim Queries) | CONBANKD (ECSCONFI) (Essential: Inbound Call Received) | NOOTP (HOTPRES) (Health OTP) | PERSIN",
                    ScoreCaptureDate: "2022-04-07",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Essential Delta Saver",
                    ChangeCount: "1",
                    user: {
                        UserID: 6122,
                        EntityNo: "1123872655",
                        DisplayName: "Jawwaad Gierdien",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "AbdulJG@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1541",
                        JobTitleID: "439",
                        Rank: "8",
                        Firstname: "Jawwaad",
                        Surname: "Gierdien",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "abduljaw1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 6122,
                EntityNo: "1123872655",
                DisplayName: "Jawwaad Gierdien",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "AbdulJG@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1541",
                JobTitleID: "439",
                Rank: "8",
                Firstname: "Jawwaad",
                Surname: "Gierdien",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "abduljaw1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 11958,
                NewsfeedItemID: "115807",
                NewsfeedCommentID: null,
                EntityNo: "1123872655",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-05-12T06:16:48.330000Z",
                updated_at: "2022-05-12T06:16:48.330000Z",
            },
        },
        {
            id: 189163,
            lsa: {
                LsaID: 21793,
                LsaDateAdded: "2022-06-12",
                LsaDataID: "531029",
                LsaDataMetricID: "3",
                StreamID: "1",
                PrimaryQueryTypeID: "5",
                SecondaryQueryTypeID: "51",
                LowScoreGroupingID: "2",
                PrimaryLowScoreCauseID: "255",
                SecondaryLowScoreCauseID: "93",
                ResponsibleScopeID: "6",
                ActionTakenID: "1",
                CreatedByEntityNo: "1031009424",
                CreatedByJobLevel: "3",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "1",
                StatusID: "3",
                SoftLanding: "0",
                AssessorsEntity: "1031009424",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "531029",
                    DateAdded: "2022-06-08 08:30:55",
                    Months: "2022-06-01",
                    SurveyTypeID: "3",
                    SurveyID: "8807497514",
                    DivisionID: "2",
                    CostCenterID: "30",
                    TeamID: "0",
                    EntityNo: "1123872655",
                    CallID: "8803506817",
                    CallDateAnswered: "2022-06-06",
                    SurveyDate: "2022-06-07",
                    ResponseID: "8807503173",
                    SurveyScore: "0",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments: "[Email from external sender. Proceed with caution.]Sent from my Huawei Mobile",
                    Imported: "0",
                    RemovalLevelID: "0",
                    fcrImport: "1",
                    SurveyEntityNo: "1037265541",
                    KeyWord: "EVOLVEIX (EVOLVEIX) (EVOLVE)",
                    ScoreCaptureDate: "2022-06-07",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Essential Saver",
                    ChangeCount: "0",
                    user: {
                        UserID: 6122,
                        EntityNo: "1123872655",
                        DisplayName: "Jawwaad Gierdien",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "AbdulJG@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1541",
                        JobTitleID: "439",
                        Rank: "8",
                        Firstname: "Jawwaad",
                        Surname: "Gierdien",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "abduljaw1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 6122,
                EntityNo: "1123872655",
                DisplayName: "Jawwaad Gierdien",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "AbdulJG@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1541",
                JobTitleID: "439",
                Rank: "8",
                Firstname: "Jawwaad",
                Surname: "Gierdien",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "abduljaw1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 17772,
                NewsfeedItemID: "189163",
                NewsfeedCommentID: null,
                EntityNo: "1123872655",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-09-05T06:57:21.457000Z",
                updated_at: "2022-09-05T06:57:21.457000Z",
            },
        },
        {
            id: 189164,
            lsa: {
                LsaID: 21944,
                LsaDateAdded: "2022-06-20",
                LsaDataID: "529350",
                LsaDataMetricID: "3",
                StreamID: "1",
                PrimaryQueryTypeID: "1",
                SecondaryQueryTypeID: "13",
                LowScoreGroupingID: "6",
                PrimaryLowScoreCauseID: "41",
                SecondaryLowScoreCauseID: "347",
                ResponsibleScopeID: "6",
                ActionTakenID: "1",
                CreatedByEntityNo: "1031009424",
                CreatedByJobLevel: "3",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "1",
                StatusID: "3",
                SoftLanding: "0",
                AssessorsEntity: "1031009424",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "529350",
                    DateAdded: "2022-06-01 08:30:34",
                    Months: "2022-09-01",
                    SurveyTypeID: "3",
                    SurveyID: "8787601961",
                    DivisionID: "2",
                    CostCenterID: "30",
                    TeamID: "0",
                    EntityNo: "1123872655",
                    CallID: "8782594105",
                    CallDateAnswered: "2022-05-27",
                    SurveyDate: "2022-05-30",
                    ResponseID: "8787607659",
                    SurveyScore: "0",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments: "Sent from Mail<https://go.microsoft.com/fwlink/?LinkId=550986> for Windows",
                    Imported: "0",
                    RemovalLevelID: "0",
                    fcrImport: "1",
                    SurveyEntityNo: "1010824454",
                    KeyWord: "EVOLVEIX (EVOLVEIX) (EVOLVE)",
                    ScoreCaptureDate: "2022-05-31",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Classic Smart Plan",
                    ChangeCount: "0",
                    user: {
                        UserID: 6122,
                        EntityNo: "1123872655",
                        DisplayName: "Jawwaad Gierdien",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "AbdulJG@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1541",
                        JobTitleID: "439",
                        Rank: "8",
                        Firstname: "Jawwaad",
                        Surname: "Gierdien",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "abduljaw1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 6122,
                EntityNo: "1123872655",
                DisplayName: "Jawwaad Gierdien",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "AbdulJG@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1541",
                JobTitleID: "439",
                Rank: "8",
                Firstname: "Jawwaad",
                Surname: "Gierdien",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "abduljaw1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 17773,
                NewsfeedItemID: "189164",
                NewsfeedCommentID: null,
                EntityNo: "1123872655",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-09-05T06:58:11.657000Z",
                updated_at: "2022-09-05T06:58:11.657000Z",
            },
        },
        {
            id: 203050,
            lsa: {
                LsaID: 24103,
                LsaDateAdded: "2022-09-12",
                LsaDataID: "549848",
                LsaDataMetricID: "3",
                StreamID: "0",
                PrimaryQueryTypeID: "0",
                SecondaryQueryTypeID: "0",
                LowScoreGroupingID: "0",
                PrimaryLowScoreCauseID: "0",
                SecondaryLowScoreCauseID: "0",
                ResponsibleScopeID: "0",
                ActionTakenID: "0",
                CreatedByEntityNo: "1111299283",
                CreatedByJobLevel: "1",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "0",
                StatusID: "2",
                SoftLanding: "0",
                AssessorsEntity: "0",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "549848",
                    DateAdded: "2022-09-09 08:31:49",
                    Months: "2022-09-01",
                    SurveyTypeID: "3",
                    SurveyID: "9013193930",
                    DivisionID: "2",
                    CostCenterID: "30",
                    TeamID: "0",
                    EntityNo: "1111299283",
                    CallID: "9010680855",
                    CallDateAnswered: "2022-09-05",
                    SurveyDate: "2022-09-06",
                    ResponseID: "9013205802",
                    SurveyScore: "0",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments:
                        '<html><head><META http-equiv="Content-Type" content="text/html; charset=UTF-8"><style type="text/css">\t\t\t.normalfont{font:11pt Calibri; color:#0000000;}\t\t</style></head><body class="normalfont"><p>lady that answered put me through to the correct person an',
                    Imported: "0",
                    RemovalLevelID: "0",
                    fcrImport: "1",
                    SurveyEntityNo: "1036134482",
                    KeyWord: "EVOLVEIX (EVOLVE) (EVOLVE) | OTPPASS (HOTPRES) (Health OTP)",
                    ScoreCaptureDate: "2022-09-08",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Classic Delta Saver",
                    ChangeCount: "0",
                    user: {
                        UserID: 5466,
                        EntityNo: "1111299283",
                        DisplayName: "Shaun Pillay",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "ShaunP2@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1515",
                        JobTitleID: "439",
                        Rank: "104",
                        Firstname: "Shaun",
                        Surname: "Pillay",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "tireshan1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 5466,
                EntityNo: "1111299283",
                DisplayName: "Shaun Pillay",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "ShaunP2@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1515",
                JobTitleID: "439",
                Rank: "104",
                Firstname: "Shaun",
                Surname: "Pillay",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "tireshan1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 18090,
                NewsfeedItemID: "203050",
                NewsfeedCommentID: null,
                EntityNo: "1111299283",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-09-12T06:17:00.397000Z",
                updated_at: "2022-09-12T06:17:00.397000Z",
            },
        },
        {
            id: 216699,
            lsa: {
                LsaID: 24529,
                LsaDateAdded: "2022-10-05",
                LsaDataID: "528015",
                LsaDataMetricID: "4",
                StreamID: "0",
                PrimaryQueryTypeID: "0",
                SecondaryQueryTypeID: "0",
                LowScoreGroupingID: "0",
                PrimaryLowScoreCauseID: "0",
                SecondaryLowScoreCauseID: "0",
                ResponsibleScopeID: "0",
                ActionTakenID: "0",
                CreatedByEntityNo: "1123872655",
                CreatedByJobLevel: "1",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "0",
                StatusID: "2",
                SoftLanding: "0",
                AssessorsEntity: "0",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "528015",
                    DateAdded: "2022-09-21 08:45:42",
                    Months: "2022-09-01",
                    SurveyID: "9038661069",
                    EntityNo: "1123872655",
                    TeamID: "0",
                    CallID: "9036645811",
                    DivisionID: "2",
                    CostCenterID: "30",
                    CallDateAnswered: "2022-09-19",
                    SurveyDate: "2022-09-20",
                    ResponseID: "9038672574",
                    SurveyScore: "1",
                    SurveyTypeID: "3",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments: "",
                    Imported: "0",
                    RemovalLevelID: "0",
                    eMbrImport: "1",
                    SurveyEntityNo: "1092780137",
                    KeyWord: "BENDHRAT (COMPLAIN) (CS - General - Benefit Querie",
                    ScoreCaptureDate: "2022-09-20",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Classic Delta Saver",
                    ChangeCount: "1",
                    user: {
                        UserID: 6122,
                        EntityNo: "1123872655",
                        DisplayName: "Jawwaad Gierdien",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "AbdulJG@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1541",
                        JobTitleID: "439",
                        Rank: "8",
                        Firstname: "Jawwaad",
                        Surname: "Gierdien",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "abduljaw1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 6122,
                EntityNo: "1123872655",
                DisplayName: "Jawwaad Gierdien",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "AbdulJG@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1541",
                JobTitleID: "439",
                Rank: "8",
                Firstname: "Jawwaad",
                Surname: "Gierdien",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "abduljaw1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 18897,
                NewsfeedItemID: "216699",
                NewsfeedCommentID: null,
                EntityNo: "1123872655",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-10-05T10:08:38.597000Z",
                updated_at: "2022-10-05T10:08:38.597000Z",
            },
        },
        {
            id: 241113,
            lsa: {
                LsaID: 21622,
                LsaDateAdded: "2022-06-07",
                LsaDataID: "498019",
                LsaDataMetricID: "4",
                StreamID: "1",
                PrimaryQueryTypeID: "5",
                SecondaryQueryTypeID: "81",
                LowScoreGroupingID: "2",
                PrimaryLowScoreCauseID: "77",
                SecondaryLowScoreCauseID: "355",
                ResponsibleScopeID: "6",
                ActionTakenID: "7",
                CreatedByEntityNo: "1031009424",
                CreatedByJobLevel: "3",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "1",
                StatusID: "3",
                SoftLanding: "0",
                AssessorsEntity: "1031009424",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "498019",
                    DateAdded: "2022-06-06 14:29:39",
                    Months: "2022-06-01",
                    SurveyID: "8796203120",
                    EntityNo: "1082416429",
                    TeamID: "0",
                    CallID: "8792647936",
                    DivisionID: "2",
                    CostCenterID: "30",
                    CallDateAnswered: "2022-06-01",
                    SurveyDate: "2022-06-02",
                    ResponseID: "8796209893",
                    SurveyScore: "1",
                    SurveyTypeID: "3",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments:
                        "[Email from external sender. Proceed with caution.]Your service is very is very poor. I call almost every day but no one knowswhere I can print my card to date",
                    Imported: "0",
                    RemovalLevelID: "0",
                    eMbrImport: "1",
                    SurveyEntityNo: "1182482503",
                    KeyWord: "NOOTP (HOTPRES) (Health OTP)",
                    ScoreCaptureDate: "2022-06-03",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Essential Saver",
                    ChangeCount: "0",
                    user: {
                        UserID: 4462,
                        EntityNo: "1082416429",
                        DisplayName: "Venesh Moodley",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "veneshm@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1780",
                        JobTitleID: "439",
                        Rank: "1",
                        Firstname: "Venesh",
                        Surname: "Moodley",
                        SkillID: "1",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "vanesh1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 4462,
                EntityNo: "1082416429",
                DisplayName: "Venesh Moodley",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "veneshm@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1780",
                JobTitleID: "439",
                Rank: "1",
                Firstname: "Venesh",
                Surname: "Moodley",
                SkillID: "1",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "vanesh1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 20831,
                NewsfeedItemID: "241113",
                NewsfeedCommentID: null,
                EntityNo: "1082416429",
                NewsfeedInteractionTypeID: "6",
                created_at: "2022-11-17T07:20:08.090000Z",
                updated_at: "2022-11-17T07:20:08.090000Z",
            },
        },
        {
            id: 453564,
            lsa: {
                LsaID: 30844,
                LsaDateAdded: "2023-09-13",
                LsaDataID: "593572",
                LsaDataMetricID: "4",
                StreamID: "0",
                PrimaryQueryTypeID: "0",
                SecondaryQueryTypeID: "0",
                LowScoreGroupingID: "0",
                PrimaryLowScoreCauseID: "0",
                SecondaryLowScoreCauseID: "0",
                ResponsibleScopeID: "0",
                ActionTakenID: "0",
                CreatedByEntityNo: "1031009424",
                CreatedByJobLevel: "3",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "0",
                StatusID: "2",
                SoftLanding: "0",
                AssessorsEntity: "0",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "593572",
                    DateAdded: "2023-09-06 08:45:38",
                    Months: "2023-09-01",
                    SurveyID: "9832370338",
                    EntityNo: "1082416429",
                    TeamID: "0",
                    CallID: "9831271774",
                    DivisionID: "2",
                    CostCenterID: "30",
                    CallDateAnswered: "2023-09-04",
                    SurveyDate: "2023-09-05",
                    ResponseID: "9832393036",
                    SurveyScore: "6",
                    SurveyTypeID: "3",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments:
                        '<html><head><META http-equiv="Content-Type" content="text/html; charset=UTF-8"><style type="text/css">\t\t\t.normalfont{font:11pt Calibri; color:#0000000;}\t\t</style></head><body class="normalfont"><p><',
                    Imported: "0",
                    RemovalLevelID: "0",
                    eMbrImport: "1",
                    SurveyEntityNo: "1111710792",
                    KeyWord: "BENDOWEC (CONFIRM) (CS - General - Benefit Queries",
                    ScoreCaptureDate: "2023-09-05",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Coastal Saver",
                    ChangeCount: "0",
                    user: {
                        UserID: 4462,
                        EntityNo: "1082416429",
                        DisplayName: "Venesh Moodley",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "veneshm@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "617",
                        IncentiveCategoryID: "300",
                        Elo: "1780",
                        JobTitleID: "439",
                        Rank: "1",
                        Firstname: "Venesh",
                        Surname: "Moodley",
                        SkillID: "1",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "vanesh1@discovery.co.za",
                        StartDate: "20230526",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 4462,
                EntityNo: "1082416429",
                DisplayName: "Venesh Moodley",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "veneshm@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "617",
                IncentiveCategoryID: "300",
                Elo: "1780",
                JobTitleID: "439",
                Rank: "1",
                Firstname: "Venesh",
                Surname: "Moodley",
                SkillID: "1",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "vanesh1@discovery.co.za",
                StartDate: "20230526",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 32364,
                NewsfeedItemID: "453564",
                NewsfeedCommentID: null,
                EntityNo: "1082416429",
                NewsfeedInteractionTypeID: "6",
                created_at: "2023-09-15T05:18:24.687000Z",
                updated_at: "2023-09-15T05:18:24.687000Z",
            },
        },
        {
            id: 555670,
            lsa: {
                LsaID: 30947,
                LsaDateAdded: "2023-09-19",
                LsaDataID: "620004",
                LsaDataMetricID: "3",
                StreamID: "0",
                PrimaryQueryTypeID: "0",
                SecondaryQueryTypeID: "0",
                LowScoreGroupingID: "0",
                PrimaryLowScoreCauseID: "0",
                SecondaryLowScoreCauseID: "0",
                ResponsibleScopeID: "0",
                ActionTakenID: "0",
                CreatedByEntityNo: "1096122773",
                CreatedByJobLevel: "1",
                CompletedByAgent: "1",
                CompletedByTeamLeader: "0",
                StatusID: "2",
                SoftLanding: "0",
                AssessorsEntity: "0",
                PracticeTypeID: null,
                AppealTypeID: null,
                MissingInformationTypeID: null,
                InquiryOpenedOrCaTicketLogged: null,
                survey_record: {
                    DataID: "620004",
                    DateAdded: "2023-09-07 08:26:17",
                    Months: "2023-09-01",
                    SurveyTypeID: "3",
                    SurveyID: "9834957586",
                    DivisionID: "2",
                    CostCenterID: "30",
                    TeamID: "0",
                    EntityNo: "1096122773",
                    CallID: "9832871867",
                    CallDateAnswered: "2023-09-05",
                    SurveyDate: "2023-09-06",
                    ResponseID: "9835010412",
                    SurveyScore: "0",
                    JobTitleID: "259",
                    TeamLeaderEntityNo: "1031009424",
                    Comments:
                        '<html><head><META http-equiv="Content-Type" content="text/html; charset=UTF-8"><style type="text/css">\t\t\t.normalfont{font:11pt Calibri; color:#0000000;}\t\t</style></head><body class="normalfont"><p>The first contact did not possess the necessary tools to a',
                    Imported: "0",
                    RemovalLevelID: "0",
                    fcrImport: "1",
                    SurveyEntityNo: "1011505383",
                    KeyWord:
                        "BILLCATE (COMPLAIN) (CS - General - Policy Inquiry) | BILLCATE (COMPLAIN) (CS - General - Policy Inquiry) | CHGDOBDE (ECSCHANG) (Essential: Inbound Call Received) | CHGDOBDE (ECSCHANG) (Essential: Inbound Call Received) | CLAWBACK (CONFIRM) (CS - General",
                    ScoreCaptureDate: "2023-09-07",
                    AgentJobTitle: "SERVICE SPECIALIST",
                    Exclude: "0",
                    PlanDescription: "Coastal Saver",
                    ChangeCount: "2",
                    user: {
                        UserID: 4856,
                        EntityNo: "1096122773",
                        DisplayName: "Cumane Nyaba",
                        ProfilePicture: "defualt_profile.png",
                        EmailAddress: "CumaneN@discovery.co.za",
                        JobLevelID: "1",
                        RegionID: "5",
                        TeamID: "440",
                        IncentiveCategoryID: "300",
                        Elo: "1275",
                        JobTitleID: "439",
                        Rank: "43",
                        Firstname: "Cumane",
                        Surname: "Nyaba",
                        SkillID: "15",
                        DivisionID: "39",
                        CompanyID: "4",
                        ChallengeEligible: "1",
                        ManagerEntityNo: "1031009424",
                        ServiceTeamID: "1",
                        DepartmentID: "19",
                        Username: "cumane1@discovery.co.za",
                        StartDate: "20230907",
                        EndDate: "99991231",
                        EmployeeStatus: "Active",
                        EmployeeClass: "Permanent",
                        DiscoveryTeamID: "221",
                        CostCenterID: "65",
                        DarkMode: "0",
                    },
                },
            },
            user: {
                UserID: 4856,
                EntityNo: "1096122773",
                DisplayName: "Cumane Nyaba",
                ProfilePicture: "defualt_profile.png",
                EmailAddress: "CumaneN@discovery.co.za",
                JobLevelID: "1",
                RegionID: "5",
                TeamID: "440",
                IncentiveCategoryID: "300",
                Elo: "1275",
                JobTitleID: "439",
                Rank: "43",
                Firstname: "Cumane",
                Surname: "Nyaba",
                SkillID: "15",
                DivisionID: "39",
                CompanyID: "4",
                ChallengeEligible: "1",
                ManagerEntityNo: "1031009424",
                ServiceTeamID: "1",
                DepartmentID: "19",
                Username: "cumane1@discovery.co.za",
                StartDate: "20230907",
                EndDate: "99991231",
                EmployeeStatus: "Active",
                EmployeeClass: "Permanent",
                DiscoveryTeamID: "221",
                CostCenterID: "65",
                DarkMode: "0",
            },
            interaction: {
                id: 32524,
                NewsfeedItemID: "555670",
                NewsfeedCommentID: null,
                EntityNo: "1096122773",
                NewsfeedInteractionTypeID: "6",
                created_at: "2023-09-19T08:18:01.597000Z",
                updated_at: "2023-09-19T08:18:01.597000Z",
            },
        },
    ],
    appeal: [],
    friend_requests: [],
    downtime: [
        {
            id: 216894,
            date: "2022-10-07",
            user: {
                id: 4556,
                entity_no: "1085977930",
                name: "Misha Victor",
                job_title: "Client Relationship Manager - Escalations",
                job_level: "1",
                tier: {
                    label: "",
                    color: "",
                },
                profile_img: "defualt_profile.png",
                region: "Wcp",
                wins: null,
                losses: null,
                badges: null,
                bio: "This User has no Bio.",
                badge_collection: [
                    {
                        count: 1,
                        id: 9,
                        badge_img: "/images/Perfect10.png",
                        name: "Perfect 10",
                        description: "Achieved an average of 10 MBR in a given month",
                        is_system: 1,
                    },
                    {
                        count: 5,
                        id: 11,
                        badge_img: "/images/ZeroDefect.png",
                        name: "Zero Defect",
                        description: "0 DPMO achieved in a given month",
                        is_system: 1,
                    },
                    {
                        count: 1,
                        id: 27,
                        badge_img: "/images/perfect_attendance.png",
                        name: "Perfect Attendance",
                        description: "Achieved 100% Attendance in a month",
                        is_system: 1,
                    },
                ],
            },
        },
    ],
    additional_volume: [
        {
            id: 216833,
            date: "2022-10-06",
            user: {
                id: 4556,
                entity_no: "1085977930",
                name: "Misha Victor",
                job_title: "Client Relationship Manager - Escalations",
                job_level: "1",
                tier: {
                    label: "",
                    color: "",
                },
                profile_img: "defualt_profile.png",
                region: "Wcp",
                wins: null,
                losses: null,
                badges: null,
                bio: "This User has no Bio.",
                badge_collection: [
                    {
                        count: 1,
                        id: 9,
                        badge_img: "/images/Perfect10.png",
                        name: "Perfect 10",
                        description: "Achieved an average of 10 MBR in a given month",
                        is_system: 1,
                    },
                    {
                        count: 5,
                        id: 11,
                        badge_img: "/images/ZeroDefect.png",
                        name: "Zero Defect",
                        description: "0 DPMO achieved in a given month",
                        is_system: 1,
                    },
                    {
                        count: 1,
                        id: 27,
                        badge_img: "/images/perfect_attendance.png",
                        name: "Perfect Attendance",
                        description: "Achieved 100% Attendance in a month",
                        is_system: 1,
                    },
                ],
            },
        },
        {
            id: 216895,
            date: "2022-10-05",
            user: {
                id: 4556,
                entity_no: "1085977930",
                name: "Misha Victor",
                job_title: "Client Relationship Manager - Escalations",
                job_level: "1",
                tier: {
                    label: "",
                    color: "",
                },
                profile_img: "defualt_profile.png",
                region: "Wcp",
                wins: null,
                losses: null,
                badges: null,
                bio: "This User has no Bio.",
                badge_collection: [
                    {
                        count: 1,
                        id: 9,
                        badge_img: "/images/Perfect10.png",
                        name: "Perfect 10",
                        description: "Achieved an average of 10 MBR in a given month",
                        is_system: 1,
                    },
                    {
                        count: 5,
                        id: 11,
                        badge_img: "/images/ZeroDefect.png",
                        name: "Zero Defect",
                        description: "0 DPMO achieved in a given month",
                        is_system: 1,
                    },
                    {
                        count: 1,
                        id: 27,
                        badge_img: "/images/perfect_attendance.png",
                        name: "Perfect Attendance",
                        description: "Achieved 100% Attendance in a month",
                        is_system: 1,
                    },
                ],
            },
        },
        {
            id: 216896,
            date: "2022-10-07",
            user: {
                id: 4556,
                entity_no: "1085977930",
                name: "Misha Victor",
                job_title: "Client Relationship Manager - Escalations",
                job_level: "1",
                tier: {
                    label: "",
                    color: "",
                },
                profile_img: "defualt_profile.png",
                region: "Wcp",
                wins: null,
                losses: null,
                badges: null,
                bio: "This User has no Bio.",
                badge_collection: [
                    {
                        count: 1,
                        id: 9,
                        badge_img: "/images/Perfect10.png",
                        name: "Perfect 10",
                        description: "Achieved an average of 10 MBR in a given month",
                        is_system: 1,
                    },
                    {
                        count: 5,
                        id: 11,
                        badge_img: "/images/ZeroDefect.png",
                        name: "Zero Defect",
                        description: "0 DPMO achieved in a given month",
                        is_system: 1,
                    },
                    {
                        count: 1,
                        id: 27,
                        badge_img: "/images/perfect_attendance.png",
                        name: "Perfect Attendance",
                        description: "Achieved 100% Attendance in a month",
                        is_system: 1,
                    },
                ],
            },
        },
    ],
    summaries: [
        {
            id: 391458,
            date: "2023-07-06",
            summary: {
                id: 4,
                EntityNo: "1082416429",
                summary: "I did really well in AHT this week.",
                response: "",
                rating: "5",
                user_name: "Venesh Moodley",
                date: "2023-07-06",
            },
        },
        {
            id: 406058,
            date: "2023-07-28",
            summary: {
                id: 5,
                EntityNo: "1082416429",
                summary: "What a great week, I feel enegized and I'm ready to kick @ss! :)",
                response: "",
                rating: "5",
                user_name: "Venesh Moodley",
                date: "2023-07-28",
            },
        },
        {
            id: 406062,
            date: "2023-07-28",
            summary: {
                id: 6,
                EntityNo: "1096938889",
                summary: "had an aweful week, my pet is sick . I think it affecting my performace",
                response: "",
                rating: "1",
                user_name: "Brittany Williams",
                date: "2023-07-28",
            },
        },
    ],
    prompts: [
        {
            id: 385492,
            prompt: {
                id: 19,
                message: "Please submit your weekly summary",
                message_2: null,
                type: "weekly_summary",
                type_id: null,
                date: "2023-07-06",
                created_at: "2023-07-06T06:10:57.973000Z",
                updated_at: "2023-07-06T06:10:57.973000Z",
            },
        },
    ],
};

export const badges = [
    {
        count: 1,
        id: 11,
        badge_img: "images/badges/3-0DPMO.png",
        name: "Zero Defect",
        description: "0 DPMO achieved in a given month",
        is_system: 1,
    },
    {
        count: 1,
        id: 12,
        badge_img: "images/badges/2-5engaged.png",
        name: "5 Days engaged",
        description: "Logged in for 5 days in a month",
        is_system: 1,
    },
    {
        count: 1,
        id: 13,
        badge_img: "images/badges/2-10engaged.png",
        name: "10 Days engaged",
        description: "Logged in for 10 days in a month",
        is_system: 1,
    },
    {
        count: 32,
        id: 15,
        badge_img: "/images/innovation.png",
        name: "Innovation and optimism",
        description:
            "We believe in a better future, and this optimism guides our energy to solve problems in innovative ways. Optimism is also contagious. It inspires our people, who share the idealism we value, while serving as the antidote for cynicism, which we dislike.",
        is_system: 0,
    },
];

export const performance_summary = [
    {
        id: 6,
        EntityNo: 1096938889,
        summary: "had an aweful week, my pet is sick . I think it affecting my performace",
        response: null,
        rating: 1,
        user_name: "Brittany Williams",
        date: "2023-07-28",
        summary_metrics: [
            {
                metric_name: "AHT",
                target: 300,
                value: 392.42,
                percentage_of_target: 100,
            },
            {
                metric_name: "DPMO",
                target: 15301,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "FCR",
                target: 80,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "MBR",
                target: 9.1,
                value: 9.67,
                percentage_of_target: 100,
            },
            {
                metric_name: "Production",
                target: 40,
                value: 10.2,
                percentage_of_target: 26,
            },
            {
                metric_name: "TransferRatio",
                target: 20,
                value: 7.89,
                percentage_of_target: 39,
            },
            {
                metric_name: "Attendance",
                target: 90,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "Adherance",
                target: 0,
                value: null,
                percentage_of_target: 100,
            },
            {
                metric_name: "Total Inbound",
                target: 100,
                value: 55.8,
                percentage_of_target: 56,
            },
            {
                metric_name: "Engagement",
                target: 90,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "AVG Hold Time",
                target: 10,
                value: 319.06,
                percentage_of_target: 100,
            },
        ],
    },
    {
        id: 5,
        EntityNo: 1082416429,
        summary: "What a great week, I feel enegized and I'm ready to kick @ss! :)",
        response: null,
        rating: 5,
        user_name: "Venesh Moodley",
        date: "2023-07-28",
        summary_metrics: [
            {
                metric_name: "AHT",
                target: 300,
                value: 436.47,
                percentage_of_target: 100,
            },
            {
                metric_name: "DPMO",
                target: 15301,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "FCR",
                target: 80,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "MBR",
                target: 9.1,
                value: 9,
                percentage_of_target: 99,
            },
            {
                metric_name: "Production",
                target: 40,
                value: 5.68,
                percentage_of_target: 14,
            },
            {
                metric_name: "TransferRatio",
                target: 20,
                value: 10.29,
                percentage_of_target: 51,
            },
            {
                metric_name: "Attendance",
                target: 90,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "Adherance",
                target: 0,
                value: null,
                percentage_of_target: 100,
            },
            {
                metric_name: "Total Inbound",
                target: 100,
                value: 35,
                percentage_of_target: 35,
            },
            {
                metric_name: "Engagement",
                target: 90,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "AVG Hold Time",
                target: 10,
                value: 122.13,
                percentage_of_target: 100,
            },
        ],
    },
    {
        id: 4,
        EntityNo: 1082416429,
        summary: "I did really well in AHT this week.",
        response: null,
        rating: 5,
        user_name: "Venesh Moodley",
        date: "2023-07-06",
        summary_metrics: [
            {
                metric_name: "AHT",
                target: 300,
                value: 460.21,
                percentage_of_target: 100,
            },
            {
                metric_name: "DPMO",
                target: 15301,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "FCR",
                target: 80,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "MBR",
                target: 9.1,
                value: 9,
                percentage_of_target: 99,
            },
            {
                metric_name: "Production",
                target: 40,
                value: 1.7,
                percentage_of_target: 4,
            },
            {
                metric_name: "TransferRatio",
                target: 20,
                value: 12.2,
                percentage_of_target: 61,
            },
            {
                metric_name: "Attendance",
                target: 90,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "Adherance",
                target: 0,
                value: null,
                percentage_of_target: 100,
            },
            {
                metric_name: "Total Inbound",
                target: 100,
                value: 41,
                percentage_of_target: 41,
            },
            {
                metric_name: "Engagement",
                target: 90,
                value: 80,
                percentage_of_target: 89,
            },
            {
                metric_name: "AVG Hold Time",
                target: 10,
                value: 122.13,
                percentage_of_target: 100,
            },
        ],
    },
    {
        id: 3,
        EntityNo: 1031009424,
        summary: "This week was good!",
        response: "I'm glad it was good.",
        rating: 4,
        user_name: "Sergio Gomes",
        date: "2023-06-15",
        summary_metrics: [
            {
                metric_name: "AHT",
                target: 300,
                value: 372.92,
                percentage_of_target: 100,
            },
            {
                metric_name: "DPMO",
                target: 15301,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "FCR",
                target: 80,
                value: 94.12,
                percentage_of_target: 100,
            },
            {
                metric_name: "MBR",
                target: 9.1,
                value: 9.43,
                percentage_of_target: 100,
            },
            {
                metric_name: "Production",
                target: 40,
                value: 7.55,
                percentage_of_target: 19,
            },
            {
                metric_name: "TransferRatio",
                target: 20,
                value: 12.41,
                percentage_of_target: 62,
            },
            {
                metric_name: "Attendance",
                target: 90,
                value: 79.29,
                percentage_of_target: 88,
            },
            {
                metric_name: "Adherance",
                target: 0,
                value: null,
                percentage_of_target: 100,
            },
            {
                metric_name: "Knowledge",
                target: 90,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "Total Inbound",
                target: 100,
                value: 31.51,
                percentage_of_target: 32,
            },
            {
                metric_name: "Engagement",
                target: 90,
                value: 71.43,
                percentage_of_target: 79,
            },
            {
                metric_name: "AVG Hold Time",
                target: 10,
                value: 214.26,
                percentage_of_target: 100,
            },
        ],
    },
    {
        id: 2,
        EntityNo: 1082416429,
        summary: "I had a difficult week",
        response: null,
        rating: 1,
        user_name: "Venesh Moodley",
        date: "2023-06-15",
        summary_metrics: [
            {
                metric_name: "AHT",
                target: 300,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "DPMO",
                target: 15301,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "FCR",
                target: 80,
                value: 100,
                percentage_of_target: 100,
            },
            {
                metric_name: "MBR",
                target: 9.1,
                value: 10,
                percentage_of_target: 100,
            },
            {
                metric_name: "Production",
                target: 40,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "TransferRatio",
                target: 20,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "Attendance",
                target: 90,
                value: 0,
                percentage_of_target: 0,
            },
            {
                metric_name: "Adherance",
                target: 0,
                value: null,
                percentage_of_target: 100,
            },
            {
                metric_name: "Knowledge",
                target: 90,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "Total Inbound",
                target: 100,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "Engagement",
                target: 90,
                value: null,
                percentage_of_target: 0,
            },
            {
                metric_name: "AVG Hold Time",
                target: 10,
                value: null,
                percentage_of_target: 0,
            },
        ],
    },
];

export const dark_mode = "0";

export const walkThroughTips = [
    {
        CoachTipID: "6",
        Description: "Always ask callers for permission to put them in a waiting situation and wait for a response.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "6",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "6",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "6",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "7",
        Description:
            "If you have to get information, be honest about the amount of time it will take and ask if they\u2019d rather get a call back.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "7",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "7",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "7",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "8",
        Description: "Explain the reason for the delay and give an estimate of how long they will have to wait.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "8",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "8",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "8",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "9",
        Description: "Ensure you have all the facts you need before putting the member in the waiting situation.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "9",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "9",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "9",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "10",
        Description: "Offer the caller an alternative to waiting, be proactive.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "10",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "10",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "10",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "11",
        Description: "Wait for the client to decide.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "11",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "11",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "11",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "12",
        Description: "Never leave the line open, always use the \u201cmute button\u201d.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "12",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "12",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "12",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "13",
        Description: "Don\u2019t EVER say \u201chang on\u201d.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "13",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "13",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "13",
                    MetricID: "1",
                },
            },
        ],
    },
    {
        CoachTipID: "14",
        Description:
            "If the delay is longer than 30 seconds, return to the caller and explain the situation and ask if they\u2019d continue to wait.",
        Group: "Waiting Tips",
        created_at: null,
        updated_at: null,
        metrics: [
            {
                MetricID: 4,
                Name: "MBR",
                pivot: {
                    CoachTipID: "14",
                    MetricID: "4",
                },
            },
            {
                MetricID: 3,
                Name: "FCR",
                pivot: {
                    CoachTipID: "14",
                    MetricID: "3",
                },
            },
            {
                MetricID: 1,
                Name: "AHT",
                pivot: {
                    CoachTipID: "14",
                    MetricID: "1",
                },
            },
        ],
    },
];

export const profile = {
    job_title: "Team Leader Coach",
    job_level: 3,
    permissions: [
        {
            id: 6,
            name: "LSA Tagging",
            slug: "lsa-create",
        },
        {
            id: 7,
            name: "Appeal Tagging",
            slug: "appeal-create",
        },
        {
            id: 9,
            name: "Reporting",
            slug: "reports-view",
        },
        {
            id: 10,
            name: "Downtime Approve",
            slug: "downtime-approve",
        },
        {
            id: 11,
            name: "Additional Volume Approve",
            slug: "additional-volume-approve",
        },
        {
            id: 12,
            name: "External Leave Capture",
            slug: "external-leave-create",
        },
        {
            id: 13,
            name: "Manage Team",
            slug: "team-manage",
        },
    ],
    id: 3473,
    entity_no: "1031009424",
    name: "Sergio Gomes",
    tier: {
        label: null,
        color: null,
    },
    profile_img: "defualt_profile.png",
    region: "Wcp",
    wins: null,
    losses: null,
    badges: null,
    bio: "This User has no Bio.",
    badge_collection: [
        {
            count: 3,
            id: 27,
            badge_img: "/images/perfect_attendance.png",
            name: "Perfect Attendance",
            description: "Achieved 100% Attendance in a month",
            is_system: 1,
        },
    ],
};

//Production Data

export const team_overview = [
    {
        user: {
            entity_no: "1082416429",
            display_name: "Venesh Moodley",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1085977930",
            display_name: "Misha Victor",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1095629216",
            display_name: "Candice Constable",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1101775037",
            display_name: "Enoch Zantsi",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1111299283",
            display_name: "Shaun Pillay",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1115381152",
            display_name: "Jamie Trouncelle",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1116641943",
            display_name: "Samantha Ndlazilwana",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1118633823",
            display_name: "Robyn Tyman",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1123872655",
            display_name: "Jawwaad Gierdien",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1142574837",
            display_name: "Tasneem Jacobs",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1175501806",
            display_name: "Ntombi Cengimbo",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1182984995",
            display_name: "Taariq Isaacs",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1201656079",
            display_name: "Kim Thomas",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1096938889",
            display_name: "Brittany Neer",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        user: {
            entity_no: "1600172140",
            display_name: "Niyaaz Dawjee",
        },
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: "",
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
];
export const walkthroughTeamTotals = {
    team_totals: {
        total_production: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
    },
    team_average: {
        total_production: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
    },
};

export const user_overview = [
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-01",
        day: "Sun",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 9.62,
        downtime_volume: 9.62,
        downtime: 101,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-02",
        day: "Mon",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-03",
        day: "Tue",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-04",
        day: "Wed",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-05",
        day: "Thu",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-06",
        day: "Fri",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-07",
        day: "Sat",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-08",
        day: "Sun",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-09",
        day: "Mon",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-10",
        day: "Tue",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-11",
        day: "Wed",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-12",
        day: "Thu",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-13",
        day: "Fri",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-14",
        day: "Sat",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-15",
        day: "Sun",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-16",
        day: "Mon",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-17",
        day: "Tue",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-18",
        day: "Wed",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-19",
        day: "Thu",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-20",
        day: "Fri",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-21",
        day: "Sat",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: false,
        date: "2023-10-22",
        day: "Sun",
        leave_deduction: 0,
        leave: null,
        target: 0,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-23",
        day: "Mon",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-24",
        day: "Tue",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-25",
        day: "Wed",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
    {
        is_agent: true,
        work_day: true,
        date: "2023-10-26",
        day: "Thu",
        leave_deduction: 0,
        leave: null,
        target: 40,
        total: 0,
        downtime_volume: 0,
        downtime: 0,
        additional_volume: 0,
        actual_volume: 0,
        weighted_volume: 0,
        pool_values: [],
        unapproved_downtime: false,
        unapproved_additional_volume: false,
    },
];

export const walkthroughUserTotals = {
    total_production: 9.62,
    downtime_volume: 9.62,
    downtime: 101,
    additional_volume: 0,
    actual_volume: 0,
    weighted_volume: 0,
    pool_values: [],
    normal_work_days: 19,
    leave_days: 0,
    employee_work_days: 19,
    target: {
        target_value: 760,
        percentage_achieved: 1.27,
    },
};

export const team_members_production = [
    {
        id: 7715,
        entity_no: "1117580249",
        name: "Sebenzile Nodola",
        job_title: "Contact Centre Consultant",
        job_level: 1,
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: "Kzn",
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
    {
        id: 12226,
        entity_no: "1600504599",
        name: "Sikelela Dlamini",
        job_title: "Contact Centre Consultant",
        job_level: 1,
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
    {
        id: 12228,
        entity_no: "1194771257",
        name: "Sanele Tenza",
        job_title: "Contact Centre Consultant",
        job_level: 1,
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
];

//TWT Walkthrough Data

export const team_members_twt = [
    {
        id: 6923,
        entity_no: "1185262092",
        name: "Sinqo Mohale",
        job_title: "Analyst",
        job_level: "1",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: "Gau",
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [
            {
                count: "1",
                id: 12,
                badge_img: "/images/5DaysEngaged.png",
                name: "5 Days engaged",
                description: "Logged in for 5 days in a month",
                is_system: "1",
            },
            {
                count: "1",
                id: 27,
                badge_img: "/images/perfect_attendance.png",
                name: "Perfect Attendance",
                description: "Achieved 100% Attendance in a month",
                is_system: "1",
            },
        ],
    },
    {
        id: 7850,
        entity_no: "1095775100",
        name: "Danny Nochumsohn",
        job_title: "Analyst",
        job_level: "1",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: "Gau",
        wins: null,
        losses: null,
        badges: null,
        bio: "One of the architects of BeYou. Unless if anything is broken, in which case I'm definitely not one of the architects of BeYou",
        badge_collection: [
            {
                count: "8",
                id: 27,
                badge_img: "/images/perfect_attendance.png",
                name: "Perfect Attendance",
                description: "Achieved 100% Attendance in a month",
                is_system: "1",
            },
        ],
    },
    {
        id: 8796,
        entity_no: "1111729560",
        name: "Margaret Watson",
        job_title: "Analyst",
        job_level: "5",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [
            {
                count: "1",
                id: 12,
                badge_img: "/images/5DaysEngaged.png",
                name: "5 Days engaged",
                description: "Logged in for 5 days in a month",
                is_system: "1",
            },
            {
                count: "1",
                id: 13,
                badge_img: "/images/10DaysEngaged.png",
                name: "10 Days engaged",
                description: "Logged in for 10 days in a month",
                is_system: "1",
            },
            {
                count: "3",
                id: 27,
                badge_img: "/images/perfect_attendance.png",
                name: "Perfect Attendance",
                description: "Achieved 100% Attendance in a month",
                is_system: "1",
            },
        ],
    },
    {
        id: 9204,
        entity_no: "1071734485",
        name: "Zeenat Mahmud",
        job_title: "Analyst",
        job_level: "5",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: "Sandton",
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
    {
        id: 13412,
        entity_no: "1091889137",
        name: "Ashton Pieterse",
        job_title: "Developer",
        job_level: "5",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
    {
        id: 14073,
        entity_no: "1100411766",
        name: "Liam Monaghan",
        job_title: "Developer",
        job_level: "5",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [],
    },
    {
        id: 14087,
        entity_no: "1131463570",
        name: "Sne Mdlalose",
        job_title: "Analyst (junior)",
        job_level: "1",
        tier: {
            label: null,
            color: null,
        },
        profile_img: "defualt_profile.png",
        region: null,
        wins: null,
        losses: null,
        badges: null,
        bio: "This User has no Bio.",
        badge_collection: [
            {
                count: "5",
                id: 27,
                badge_img: "/images/perfect_attendance.png",
                name: "Perfect Attendance",
                description: "Achieved 100% Attendance in a month",
                is_system: "1",
            },
        ],
    },
];

export const get_team_pool_twt = [
    {
        PoolID: "1582",
        PoolNumber: "1582",
        Name: "NB NOTIFICATION FAILURES AND EXCEPTIONS",
    },
    {
        PoolID: "1054343015",
        PoolNumber: "1054343015",
        Name: "ST KZN CRM FIRST TIME QUERIES",
    },
    {
        PoolID: "1055284739",
        PoolNumber: "1055284739",
        Name: "ST KZN AF CRM",
    },
    {
        PoolID: "1107897298",
        PoolNumber: "1107897298",
        Name: "DCO ALLIED MOTIVATIONS",
    },
];

export const pools_twt = [
    {
        id: 11890,
        entity_no: "1581",
        name: "NB YER SYSTEM PENDING",
        handling_time: "10.0",
        weight: "1.0",
    },
    {
        id: 11891,
        entity_no: "1582",
        name: "NB NOTIFICATION FAILURES AND EXCEPTIONS",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11892,
        entity_no: "1732",
        name: "DC CIB DATA DISPATCH",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11893,
        entity_no: "1910",
        name: "NDC CIB STANDARDS REVIEW POOL CFF",
        handling_time: "6.0",
        weight: "0.59999999999999998",
    },
    {
        id: 11894,
        entity_no: "1922",
        name: "DC BE_CHRONICS",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11895,
        entity_no: "1936",
        name: "LIFE SERVICE AGENTS",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11896,
        entity_no: "1939",
        name: "CLINICAL RISK MANAGEMENT SUSAN SMITH",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11897,
        entity_no: "1940",
        name: "CLINICAL RISK MANAGEMENT NIRI BHIMSAN",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11898,
        entity_no: "1953",
        name: "INDEXER CFF",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11899,
        entity_no: "1954",
        name: "CST ISC CIB DIGITAL CAPTURE",
        handling_time: "0.0",
        weight: "0.0",
    },
    {
        id: 11900,
        entity_no: "1955",
        name: "DCO CIB DHMS PHARMACY ASSESSING",
        handling_time: "4.0",
        weight: "0.40000000000000002",
    },
    {
        id: 11901,
        entity_no: "5370",
        name: "NB STATUS D CAPTURE",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11902,
        entity_no: "5373",
        name: "NB SEAL (NEWBUS)",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11903,
        entity_no: "5381",
        name: "DHS SD MARK QUERY",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11904,
        entity_no: "5385",
        name: "NB RECEIPTING POOL",
        handling_time: "0.0",
        weight: "0.0",
    },
    {
        id: 11905,
        entity_no: "8207",
        name: "QUOTELINK",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11906,
        entity_no: "8238",
        name: "CC LETTERS - LETTER RUN CONTROL",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11907,
        entity_no: "8361",
        name: "ST ECP SC CLAIMS EDI",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11908,
        entity_no: "8362",
        name: "ST VIT CLAIMS GENERAL ASSESSING",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11909,
        entity_no: "8365",
        name: "ST ECP SC RETRO OH",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11910,
        entity_no: "8393",
        name: "DEBTORS SYSTEM - FINANCE DEPARTMENT",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11911,
        entity_no: "8429",
        name: "ST CEN IS RESCAN CLAIMS",
        handling_time: "2.0",
        weight: "0.20000000000000001",
    },
    {
        id: 11912,
        entity_no: "8446",
        name: "DEL DCO SC ONCOLOGY",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11913,
        entity_no: "9103",
        name: "DHS NB CALC FAILURES",
        handling_time: "0.0",
        weight: "0.0",
    },
    {
        id: 11914,
        entity_no: "9109",
        name: "DHS NB SYS EXCEPTIONS",
        handling_time: "10.25",
        weight: "1.0249999999999999",
    },
    {
        id: 11915,
        entity_no: "9110",
        name: "NB MARKETING TEAM NEW",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11916,
        entity_no: "10012",
        name: "COMMISSION CAPTURE POOL",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11917,
        entity_no: "10032",
        name: "ST CEN IS NB CARD PRINTING",
        handling_time: "0.0",
        weight: "0.0",
    },
    {
        id: 11918,
        entity_no: "10039",
        name: "MESSAGE FLOW ENGINE",
        handling_time: "0.0",
        weight: "1.0",
    },
    {
        id: 11919,
        entity_no: "11658",
        name: "CST ISC CIB INDEXING",
        handling_time: "0.90000000000000002",
        weight: "8.9999999999999997E-2",
    },
];
export const get_individual_team_pool_twt = [
    {
        PoolID: "1582",
        EntityNo: "1185262092",
        DisplayName: "Sinqo Mohale",
        PoolNumber: "1582",
        Name: "NB NOTIFICATION FAILURES AND EXCEPTIONS",
    },
    {
        PoolID: "1054343015",
        EntityNo: "1185262092",
        DisplayName: "Sinqo Mohale",
        PoolNumber: "1054343015",
        Name: "ST KZN CRM FIRST TIME QUERIES",
    },
    {
        PoolID: "1055284739",
        EntityNo: "1185262092",
        DisplayName: "Sinqo Mohale",
        PoolNumber: "1055284739",
        Name: "ST KZN AF CRM",
    },
    {
        PoolID: "1107897298",
        EntityNo: "1185262092",
        DisplayName: "Sinqo Mohale",
        PoolNumber: "1107897298",
        Name: "DCO ALLIED MOTIVATIONS",
    },
];
