import { Column } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { getMetricFormat } from "../../../../misc/helpers";
import { t_User } from "../../../../types/user.types";
import { t_MetricPerformance } from "../../Performance.types";

type t_TeamMember = {
	user: t_User.Profile;
	metrics: { [name: string]: t_MetricPerformance };
};

const MemberMetricColumns = (for_metrics: string[]): Column<t_TeamMember>[] =>
	for_metrics.map((metric_name) => ({
		title: metric_name,
		field: `metrics.${metric_name}.value`,
		width: 120,
		render: (rowData) => {
			const metric = rowData.metrics[metric_name];
			if (metric != null) {
				return getMetricFormat(metric.id, metric.value);
			}
			return "-";
		},
	}));
const getTeamPerformance = async (payload: {
	month: string;
	comparison_month: string;
}) => {
	/** @MM The team summary actually return an object for each user's metrics - not an array; */
	const response = await httpClient.post("/metrics/summary/team", payload);
	return response.data.data.team_metrics;
};

export type { t_TeamMember };
export { getTeamPerformance, MemberMetricColumns };
