import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const otherLeaveFormStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputField: {
			width: "100%",
		},
		dateListItem: {
			display: "flex",
			gap: theme.spacing(1),
		},
		actionListItem: {
			display: "flex",
			justifyContent: "space-between",
		},
	})
);
const otherLeaveStyles = makeStyles((theme: Theme) =>
	createStyles({
		inputField: {
			width: "100%",
		},
		skeletonDateListItem: {
			display: "flex",
			gap: theme.spacing(1),
		},
	})
);

export { otherLeaveStyles, otherLeaveFormStyles };
