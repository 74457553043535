import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import { formatApiDate } from "../../misc/helpers";
import { t_SurveyPeriod } from "../Surveys/Surveys.types";
import { getSurveyPeriods } from "./PerceptionReport.api";
import PerceptionSurveySummary from "./reports/SurveyReport/PerceptionSurveySummary/PerceptionSurveySummary";
import SurveyPeriods from "./tables/SurveyPeriods/SurveyPeriods";

type SurveyReports = "mbr" | "fcr" | "appeal" | "lsa";
const PerceptionReport: React.FC = () => {
    const [periods, setPeriods] = React.useState<{
        [key: string]: t_SurveyPeriod;
    }>({});
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date(Date.now()));

    const handleDateChange = (date: any) => {
        if (date != null) {
            setSelectedDate(date);
        }
    };

    React.useEffect(() => {
        if (selectedDate !== null) {
            getSurveyPeriods({
                for_month: formatApiDate(selectedDate),
                surveys: ["mbr", "fcr"],
            }).then((data) => {
                setPeriods(data);
            });
        }
    }, [selectedDate]);
    return (
        <SectionCard
            title="Perception Report"
            secondaryAction={
                <DatePicker
                    openTo="month"
                    views={["year", "month"]}
                    value={selectedDate}
                    onChange={handleDateChange}
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
            fullWidth
        >
            <Grid container spacing={1}>
                <Grid item sm={8}>
                    <SurveyPeriods periods={periods} />
                </Grid>
                {periods["mbr"] && (
                    <Grid item sm={6}>
                        <PerceptionSurveySummary
                            survey={"mbr"}
                            period={periods["mbr"]}
                            month={selectedDate?.toDateString()}
                        />
                    </Grid>
                )}
                {periods["fcr"] && (
                    <Grid item sm={6}>
                        <PerceptionSurveySummary
                            survey={"fcr"}
                            period={periods["fcr"]}
                            month={selectedDate?.toDateString()}
                        />
                    </Grid>
                )}
            </Grid>
        </SectionCard>
    );
};

export type { SurveyReports };
export default PerceptionReport;
