import React, { useEffect, useState } from "react";
import { MiscBody } from "../../types/api.types";
import { Box, Card, CardContent, CardHeader, Chip, CircularProgress, Dialog, Icon, Typography } from "@mui/material";
import { Pagination } from "@mui/material";
import notificationMessages from "./NotificationMessages.styles";
import { getSummary } from "../../sections/User/User.api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReviewFeedback from "../../sections/Feedback/ReviewFeedback";
import { EditOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setAllSummaries, setPagination } from "../../redux/slices/SummarySlice";
import { useGetSummaryMutation } from "../../redux/api/beYouCore";
import { error } from "console";
import { performance_summary } from "../../pages/Home/BeYouCoach/WalkThroughData";

interface WeeklyFeedBack {
    data?: any;
    entity_no?: any;
    comparedMember?: any;
    reviewFeedbackOpen: boolean;
    setReviewFeedbackOpen: any;
    setSidePanelChatBot: any;
}

const WeeklyFeedBack = ({
    entity_no,
    comparedMember,
    setReviewFeedbackOpen,
    reviewFeedbackOpen,
    setSidePanelChatBot,
}: WeeklyFeedBack) => {
    const [summaryDetails, setSummaryDetails] = useState({});
    const classes = notificationMessages();
    const summary = useSelector((state: RootState) => state.summary);

    const pagination = useSelector((state: RootState) => state.summary.pagination);
    const performanceReflectionWalkThrough = useSelector(
        (state: RootState) => state.walkThrough.performanceReflectionWalkThrough,
    );

    const sort = useSelector((state: RootState) => state.summary.sort);
    const dispatch = useDispatch();
    const [getSummary, { isLoading }] = useGetSummaryMutation();
    const showSummary = performanceReflectionWalkThrough ? performance_summary : summary?.allSummaries;

    const filteredSummary = comparedMember
        ? showSummary?.filter((item: any) => {
              return item.EntityNo == comparedMember.user.entity_no;
          })
        : showSummary;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPage = comparedMember ? null : { ...pagination, page: value };
        dispatch(setPagination(newPage));

        getSummary({ pagination: newPage, sort })
            .unwrap()
            .then((data) => {
                const summaries = data.data.response.data;
                dispatch(setAllSummaries(summaries));
            })
            .catch((error) => {
                console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
            });
    };

    return (
        <>
            {!isLoading ? (
                <Box sx={{ overflowY: "scroll", maxHeight: "400px", width: "100%" }}>
                    <Box style={{ display: "flex", gap: "1rem" }}>
                        <Box style={{ paddingTop: 0, display: "flex", alignItems: "center", gap: ".5rem" }}>
                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                                Good{" "}
                            </Typography>
                            <Chip
                                size="small"
                                style={{
                                    background: "#30D843",
                                    color: "white",
                                    fontSize: ".5rem",
                                    padding: "0 0.5em",
                                    height: ".5rem",
                                    position: "relative",
                                }}
                            />
                        </Box>
                        <Box style={{ paddingTop: 0, display: "flex", alignItems: "center", gap: ".5rem" }}>
                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                                Bad{" "}
                            </Typography>
                            <Chip
                                size="small"
                                style={{
                                    background: "#FF4040",
                                    color: "white",
                                    fontSize: ".5rem",
                                    padding: "0 0.5em",
                                    height: ".5rem",
                                    position: "relative",
                                }}
                            />
                        </Box>
                        <Box style={{ paddingTop: 0, display: "flex", alignItems: "center", gap: ".5rem" }}>
                            <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                                Average
                            </Typography>
                            <Chip
                                size="small"
                                style={{
                                    background: "#FFA500",
                                    color: "white",
                                    fontSize: ".5rem",
                                    padding: "0 0.5em",
                                    height: ".5rem",
                                    position: "relative",
                                }}
                            />
                        </Box>
                    </Box>
                    <Dialog open={reviewFeedbackOpen}>
                        <ReviewFeedback summaryDetails={summaryDetails} setReviewFeedbackOpen={setReviewFeedbackOpen} />
                    </Dialog>
                    {filteredSummary?.length > 0 ? (
                        filteredSummary?.map((item: any, index: number) => {
                            let backgroundColor = "transparent";
                            if (item.rating >= 4) {
                                backgroundColor = "#30D843";
                            } else if (item.rating <= 2 && item.rating !== null && item.rating !== undefined) {
                                backgroundColor = "#FF4040";
                            } else if (item.rating === 3) {
                                backgroundColor = "#FFA500";
                            } else {
                                backgroundColor = "#0000001A";
                            }

                            return (
                                <Box style={{ marginTop: 10, padding: 8, width: "100%" }} key={index}>
                                    <Box
                                        key={index}
                                        style={{
                                            background: backgroundColor,
                                            borderRadius: 23,
                                            width: "100%",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "95%",
                                                borderRadius: 23,
                                                margin: "auto",
                                                padding: 5,
                                                gap: ".5rem",
                                            }}
                                        >
                                            <Icon
                                                onClick={() => {
                                                    setReviewFeedbackOpen(true);
                                                    setSummaryDetails(item);
                                                    setSidePanelChatBot(false);
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {item.summary ? <VisibilityIcon /> : <EditOutlined />}
                                            </Icon>
                                            <Typography className={classes.clampTeam}>{item.user_name}</Typography>
                                            <Typography className={classes.clampTeam}>{item.date}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                padding: 5,
                            }}
                        >
                            <Typography color="textSecondary">No summaries available</Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <CircularProgress />
                </Box>
            )}

            {!comparedMember && (
                <Box display="flex" justifyContent="center" width="100%" style={{ marginTop: 20 }}>
                    <Pagination page={pagination?.page} count={pagination?.count} onChange={handleChange} />
                </Box>
            )}
        </>
    );
};
export default WeeklyFeedBack;
