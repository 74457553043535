import { Box, Chip, CircularProgress, Grid, MenuItem, Select, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
    Bar,
    Cell,
    ComposedChart,
    LabelList,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import BUMaterialTable from "../../components/BUMaterialTable/BUMaterialTable";
import { LineBarTrendData } from "../../components/LineBarTrend/LineBarTrend";
import SectionCard from "../../components/SectionCard/SectionCard";
import { useUserState } from "../../context/user/user.ctx";
import useMultiDateSelect from "../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { useStructuredTrendMutation } from "../../redux/api/beYouCore";
import { RootState } from "../../redux/store";
import LsaFilterProvider, { filterCtx } from "../PerceptionReport/reports/LsaReport/LsaFilterProvider";
import LsaSurveyGraph from "../PerceptionReport/reports/LsaReport/graphs/LsaSurveyGraph/LsaSurveyGraph";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import UserMetricRecords from "../Performance/components/UserMetricRecords/UserMetricRecords";

interface Props {
    entity_no: any;
    month: any;
    metricID: number;
    setMetricID: any;
    formType: string;
    setShowDownloadButton?: (text: boolean) => void;
}

const CoachingFormGraph = ({ entity_no, month, metricID, setMetricID, formType, setShowDownloadButton }: Props) => {
    const currentUser = useUserState();
    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const [selectedMetric, setSelectedMetric] = useState<number>(metricID);
    const [trendData, setTrendData] = useState<LineBarTrendData[]>([]);
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedMetricID, setSelectedMetricID] = useState<number>(metricID);
    const [recordData, setRecordData] = useState<any>();
    const [selectedRow, setSelectedRow] = useState<number>(1);
    const sortedTrend = [...trendData]?.reverse();
    const [structuredTrend, { isLoading }] = useStructuredTrendMutation();
    const theme = useTheme();
    const metric = allMetrics.filter((item: any) => item.id == selectedMetric);
    const tableData = metric && JSON.parse(JSON.stringify(metric));
    const table1ref = useRef<any>();
    const [{ viewUser }, performanceDispatch] = usePerformanceContext();
    const teamMembers = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const [selectedStructure, setSelectedStructure] = useState<string>("Monthly");
    const { periodsArr } = useMultiDateSelect();
    const { filterParam, setFilterParam } = useContext(filterCtx);

    let requiredMetrics: any = [];

    switch (formType.toLowerCase()) {
        case "absence":
            requiredMetrics = [7];
            break;
        case "dpmo":
            requiredMetrics = [2];
            break;
        case "perception":
            requiredMetrics = [3, 4];
            break;
        default:
            requiredMetrics = [];
    }

    const filteredMetricList =
        requiredMetrics.length > 0
            ? allMetrics.filter((metric: any) => requiredMetrics.includes(metric.id))
            : allMetrics;

    const structures = ["Daily", "Weekly", "Monthly"];

    const user = teamMembers.find((member: any) => member.user.entity_no == entity_no);

    useEffect(() => {
        if (entity_no) {
            setFilterParam({ entity_no: entity_no });
            structuredTrend({
                entity_no,
                month,
                metric_id: selectedMetricID,
                structure_type: selectedStructure.toLowerCase(),
            }).then((response: any) => {
                if (response.data) {
                    setTrendData(response?.data?.data?.trend);
                }
            });

            performanceDispatch({
                type: "view",
                viewUser: {
                    user: user ? user?.user : currentUser.profile,
                    metrics: allMetrics,
                },
            });
        }
        if (!isLoading && setShowDownloadButton) {
            setShowDownloadButton(true);
        }
    }, [selectedMetricID, selectedStructure, month, entity_no]);

    const metricSelectChange = (e: any) => {
        setSelectedMetric(e.target.value);
        setSelectedMetricID(e.target.value);
        setSelectedRow(e.target.value);
        setMetricID(e.target.value);
    };

    useEffect(() => {
        if (selectedMetricID) {
            allMetrics.filter((metric: any) => {
                if (metric.id == selectedMetricID) {
                    setRecordData(metric);
                }
            });
        }
    }, [selectedMetricID, month, entity_no, trendData]);

    return (
        <>
            {entity_no ? (
                <SectionCard
                    secondaryAction={
                        filteredMetricList.length > 0 && (
                            <Box>
                                <Select
                                    value={selectedMetric}
                                    label="Metric Insight"
                                    onChange={metricSelectChange}
                                    variant="standard"
                                >
                                    {filteredMetricList?.map((metric: any, index: number) => (
                                        <MenuItem value={metric?.id} key={`${metric?.id}-${index}`}>
                                            {metric?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={selectedStructure}
                                    onChange={(e: any) => {
                                        setSelectedStructure(e.target.value);
                                    }}
                                    style={{ marginLeft: ".5em" }}
                                    variant="standard"
                                >
                                    {structures.map((item, index) => (
                                        <MenuItem value={item} key={`${item}-${index}`}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )
                    }
                >
                    {filteredMetricList.length > 0 ? (
                        <ResponsiveContainer width="100%" height={400}>
                            {isLoading ? (
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <ComposedChart
                                    width={500}
                                    height={400}
                                    data={sortedTrend}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                >
                                    <XAxis dataKey="month" stroke={theme.palette.primary.contrastText} />
                                    <YAxis stroke={theme.palette.primary.contrastText} />
                                    <Tooltip />
                                    <Legend />
                                    <defs>
                                        {sortedTrend?.map((trend: any, index: any) => {
                                            const isInverseColor =
                                                selectedMetric == 1 || selectedMetric == 2 || selectedMetric == 6;

                                            const colorBarDark = isInverseColor
                                                ? trend.score >= trend.target
                                                    ? "#FF4040"
                                                    : (trend.score / trend.target) * 100 >= 80 &&
                                                        (trend.score / trend.target) * 100 < 100
                                                      ? "#FFAC1C"
                                                      : "#0086FC"
                                                : trend.score >= trend.target
                                                  ? "#0086FC"
                                                  : (trend.score / trend.target) * 100 >= 80 &&
                                                      (trend.score / trend.target) * 100 < 100
                                                    ? "#FFAC1C"
                                                    : "#FF4040";

                                            const colorBarLight = isInverseColor
                                                ? trend.score >= trend.target
                                                    ? "#ff6242"
                                                    : (trend.score / trend.target) * 100 >= 80 &&
                                                        (trend.score / trend.target) * 100 < 100
                                                      ? "#ffd91a"
                                                      : "#00F6FC"
                                                : trend.score >= trend.target
                                                  ? "#00F6FC"
                                                  : (trend.score / trend.target) * 100 >= 80 &&
                                                      (trend.score / trend.target) * 100 < 100
                                                    ? "#ffd91a"
                                                    : "#ff6242";

                                            return (
                                                <linearGradient
                                                    id={`insights${index}`}
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                    key={`insights${index}`}
                                                >
                                                    <stop offset="5%" stopColor={colorBarDark} stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor={colorBarLight} stopOpacity={0.3} />
                                                </linearGradient>
                                            );
                                        })}
                                    </defs>
                                    <Bar
                                        dataKey="score"
                                        fill="#0086FC"
                                        barSize={10}
                                        radius={10}
                                        isAnimationActive={false}
                                    >
                                        {sortedTrend?.map((trend: any, index: any) => (
                                            <Cell fill={`url(#insights${index})`} key={`insights${index}`} />
                                        ))}
                                        <LabelList dataKey="score" position="top" />
                                    </Bar>
                                    <Line type="monotone" dataKey="target" stroke="#B8D200" strokeWidth={3} />
                                </ComposedChart>
                            )}
                        </ResponsiveContainer>
                    ) : (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                padding: 5,
                            }}
                        >
                            {isLoading ? (
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Typography color="textSecondary"> No data to display currently.</Typography>
                            )}
                        </Box>
                    )}

                    {recordData && (
                        <SectionCard
                            // hasBackground
                            title={`${recordData?.name} Details`}
                            fullWidth
                            primaryAction={
                                <Tabs
                                    value={tabValue}
                                    onChange={(_, value) => setTabValue(value)}
                                    aria-label="notifications section"
                                    style={{ padding: 16 }}
                                >
                                    <Tab label="Insights" />
                                    <Tab label="Records" />
                                    {(selectedMetricID == 3 || selectedMetricID == 4) && <Tab label="LSA" />}
                                </Tabs>
                            }
                        >
                            {tabValue == 0 && tableData && (
                                <BUMaterialTable
                                    title={"My Insights"}
                                    //@ts-ignore
                                    columns={metricsInsightsColumn}
                                    tableRef={table1ref}
                                    data={tableData}
                                    isLoading={isLoading}
                                    onRowClick={(e, rowData: any) => {
                                        setSelectedMetric(rowData.id);
                                        setSelectedMetricID(rowData.id);
                                        setSelectedRow(rowData.id);
                                    }}
                                    options={{
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 15],
                                        rowStyle: (rowData) => ({
                                            backgroundColor: selectedRow === rowData.id ? "#C3D830" : "",
                                            color: selectedRow === rowData.id ? "#434343" : "",
                                        }),
                                    }}
                                />
                            )}
                            {tabValue == 1 && viewUser && recordData && (
                                <UserMetricRecords viewMetric={recordData} month={month} viewUser={viewUser} />
                            )}
                            {tabValue == 2 && (selectedMetricID == 3 || selectedMetricID == 4) && (
                                <LsaFilterProvider>
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                            backgroundColor: theme.palette.grey[200],
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: 2,
                                        }}
                                    >
                                        <Grid item sm={12}>
                                            <SectionCard
                                                title="Primary Query"
                                                titleProps={{ variant: "subtitle2" }}
                                                hasBackground
                                                fullWidth
                                            >
                                                <Box sx={{ minWidth: 800 }}>
                                                    <LsaSurveyGraph
                                                        group_by="primary_query"
                                                        periods={periodsArr}
                                                        entity_no={entity_no}
                                                        domain={[0, 10]}
                                                        barSize={10}
                                                        radius={10}
                                                    />
                                                </Box>
                                            </SectionCard>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <SectionCard
                                                title="Low Score Grouping"
                                                titleProps={{ variant: "subtitle2" }}
                                                hasBackground
                                                fullWidth
                                            >
                                                <Box sx={{ minWidth: 800 }}>
                                                    <LsaSurveyGraph
                                                        group_by="low_score_grouping"
                                                        periods={periodsArr}
                                                        entity_no={entity_no}
                                                        domain={[0, 10]}
                                                        barSize={10}
                                                        radius={10}
                                                    />
                                                </Box>
                                            </SectionCard>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <SectionCard
                                                title="Action Taken"
                                                titleProps={{ variant: "subtitle2" }}
                                                hasBackground
                                                fullWidth
                                            >
                                                <Box sx={{ minWidth: 800 }}>
                                                    <LsaSurveyGraph
                                                        group_by="action_taken"
                                                        periods={periodsArr}
                                                        entity_no={entity_no}
                                                        domain={[0, 10]}
                                                        barSize={10}
                                                        radius={10}
                                                    />
                                                </Box>
                                            </SectionCard>
                                        </Grid>
                                    </Grid>
                                </LsaFilterProvider>
                            )}
                        </SectionCard>
                    )}
                </SectionCard>
            ) : (
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> Team member not selected</Typography>
                </Box>
            )}
        </>
    );
};

const metricsInsightsColumn = [
    {
        title: "Metric",
        field: "name",
    },

    {
        title: "Target",
        field: "target",
    },
    {
        title: "Score",
        field: "value",
    },
    {
        title: "Last Month's Score",
        field: "comparison_value",
    },
    // {
    //     title: "Percentage of Target",
    //     field: "percentage_of_target",
    // },
    {
        title: "Change",
        field: "percentage_change",
        align: "left",
        render: (rowData: any) => {
            const arrow =
                rowData.id == 1 || rowData.id == 2 || rowData.id == 6
                    ? rowData.percentage_change >= 0
                        ? "up"
                        : "down"
                    : rowData.percentage_change > 0
                      ? "up"
                      : "down";
            const chipColor = arrow === "up" ? "#30D843" : "#FF4040";

            return (
                <Box display="flex" alignItems="center" mb={0.5}>
                    <Chip
                        label={`${rowData.percentage_change || 0}%*`}
                        size="small"
                        avatar={
                            <img
                                src={`${arrow === "up" ? "/images/upArrow.png" : "/images/downArrow.png"}`}
                                style={{
                                    width: ".8em",
                                    height: ".8em",
                                    position: "absolute",
                                    top: "30%",
                                    right: "15%",
                                }}
                                alt="arrow"
                            />
                        }
                        style={{
                            background: `${chipColor}`,
                            color: "white",
                            fontSize: ".7rem",
                            padding: "0 0.6em",
                            height: "1.1rem",
                            position: "relative",
                        }}
                    />
                </Box>
            );
        },
    },
    // {
    //     title: "Percentage Change",
    //     field: "percentage_change",
    // },
    {
        title: "Feedback",
        field: "feedback",
        render: (rowData: any) => {
            let change, message_2;
            // if (selectedStructure === "Daily") {
            //     period = "month";
            // } else if (selectedStructure === "Weekly") {
            //     period = "month";
            // } else if (selectedStructure === "Monthly") {
            //     period = "month";
            // }
            const period = "month";
            const message = `Over the last ${period}, your ${rowData.name} score has`;
            if (rowData.id == 1 || rowData.id == 2 || rowData.id == 6) {
                if (rowData.change < 0) {
                    change = `improved by ${Math.abs(rowData.change)}.`;
                    message_2 = "Congratulations, keep up the great work!";
                } else if (rowData.change > 0) {
                    change = `dropped by ${Math.abs(rowData.change)}.`;
                    message_2 = "Let's keep pushing!";
                } else {
                    change = "not changed.";
                    message_2 = "Keep up the good work!";
                }
            } else {
                if (rowData.change > 0) {
                    change = `improved by ${Math.abs(rowData.change)}.`;
                    message_2 = "Congratulations, keep up the great work!";
                } else if (rowData.change < 0) {
                    change = `dropped by ${Math.abs(rowData.change)}.`;
                    message_2 = "Let's keep pushing!";
                } else {
                    change = "not changed.";
                    message_2 = "";
                }
            }

            return `${message} ${change} ${message_2}`;
        },
    },
];

export default CoachingFormGraph;
