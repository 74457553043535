import { metricConfig } from "./metrics";
import { Metric, MetricReview, PIPOption, PIPPerformance, ToImprove } from "./types";

export const buildMetricData = (metricId: number, metrics: Metric[], metricRatings: Metric[]): Metric => {
    const metric: Metric | undefined = metrics.find((m) => m.metricId == metricId);
    const metricRating: Metric | undefined = metricRatings.find((mr) => mr.metricId == metricId);
    const id = metricConfig.find((m) => m.id == metricId);

    return {
        metricId: id ? id.key : metricId,
        iqs_objective: metric?.iqs_objective || "",
        on_par: metric?.on_par || "",
        target: metric?.target || "",
        review_date: metric?.review_date || "",
        new_target: metricRating?.new_target || "",
        option: metricRating?.option || "",
        measure: metricRating?.measure || "",
        by_when: metricRating?.by_when || "",
        realistic: metricRating?.realistic || "",
    };
};

export const buildPIPMetricData = (
    metricId: number,
    toImprove: ToImprove[],
    metrics: Metric[],
    pipPerformance: PIPPerformance[],
) => {
    const improvement: ToImprove | undefined = toImprove.find((t) => t.metricId == metricId);
    const metric: Metric | undefined = metrics.find((m) => m.metricId == metricId);
    const performance: PIPPerformance | undefined = pipPerformance.find((p) => p.metricId == metricId);
    const id = metricConfig.find((m) => m.id == metricId);

    return {
        metricId: id ? id.key : metricId,
        from: improvement?.from || metric?.iqs_objective || "",
        to: improvement?.to || "",
        review_date: improvement?.review_date || "",
        tl_performance_concerns: performance?.tl_performance_concerns || "",
        my_thoughts: performance?.my_thoughts || "",
        options: performance?.options || "",
        realistic: performance?.realistic || "",
    };
};

export const buildPIPOptionData = (pipOptions: PIPOption[]): PIPOption[] => {
    pipOptions.map((option) => ({
        name: option?.name || "",
        set: option?.set || "",
        followed: option?.followed || "",
        review_date: option?.review_date || "",
    }));

    return pipOptions;
};

export const buildMetricReviewData = (
    metricId: number,
    metricReviews: MetricReview[],
    toImprove: ToImprove[],
    pipPerformance: PIPPerformance[],
): MetricReview => {
    const review: MetricReview | undefined = metricReviews.find((mr) => mr.metricId === metricId);
    const improvement: ToImprove | undefined = toImprove.find((t) => t.metricId === metricId);
    const performance: PIPPerformance | undefined = pipPerformance.find((p) => p.metricId === metricId);
    const id = metricConfig.find((m) => m.id == metricId);

    return {
        metricId: id ? id.key : metricId,
        target: review?.target || "",
        achieved: review?.achieved || "",
        options_followed: review?.options_followed || "",
        review_date: improvement?.review_date || "",
        performance: performance?.tl_performance_concerns || "",
        my_thoughts: performance?.my_thoughts || "",
        options: performance?.options || "",
        realistic: performance?.realistic || "",
    };
};
