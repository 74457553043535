import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    walkThroughMode: false,
    beYouWalkThrough: false,
    notificationsWalkThrough: false,
    performanceReflectionWalkThrough: false,
    tipsWalkThrough: false,
    productionWalkThrough: false,
    twtWalkThrough: false,
};

const walkThroughSlice = createSlice({
    name: "walkThrough",
    initialState,
    reducers: {
        setWalkThroughMode: (state, action) => {
            state.walkThroughMode = action.payload;
        },
        setBeYouWalkThrough: (state, action) => {
            state.beYouWalkThrough = action.payload;
        },
        setNotificationsWalkThrough: (state, action) => {
            state.notificationsWalkThrough = action.payload;
        },
        setPerformanceReflectionWalkThrough: (state, action) => {
            state.performanceReflectionWalkThrough = action.payload;
        },
        setTipsWalkThrough: (state, action) => {
            state.tipsWalkThrough = action.payload;
        },
        setProductionWalkThrough: (state, action) => {
            state.productionWalkThrough = action.payload;
        },
        setTwtWalkThrough: (state, action) => {
            state.twtWalkThrough = action.payload;
        },
    },
});

export const {
    setWalkThroughMode,
    setNotificationsWalkThrough,
    setPerformanceReflectionWalkThrough,
    setProductionWalkThrough,
    setTipsWalkThrough,
    setTwtWalkThrough,
    setBeYouWalkThrough,
} = walkThroughSlice.actions;

export default walkThroughSlice.reducer;
