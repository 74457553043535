import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { formatApiDate } from "../../../../misc/helpers";
import ProductionCaptureDialog from "../../dialogs/ProductionCaptureDialog/ProductionCaptureDialog";
import { t_PoolColumn, t_PoolTableValues } from "../../Production.types";
import ProductionCells from "../components/ProductionCells/ProductionCells";
import { t_ProductionCellsActions } from "../components/ProductionCells/ProductionCells.type";
import ProductionColumns from "../components/ProductionColumns/ProductionColumns";
import {
    productionPoolColumnTransform,
    productionPoolRowTransform,
    productionPoolTotalTransform,
} from "../Production.table.helpers";
import productionTableStyles from "../Production.table.styles";
import { getTeamProduction } from "./ProductionByUser.api";
import { t_ProductionByUser, t_ProductionByUserDispatch, t_ProductionByUser_Totals } from "./ProductionByUser.types";

interface Props {
    entity_no?: number;
    capture_date: string;
    onDispatchEvent?: (action: t_ProductionByUserDispatch) => void;
}

const ProductionByUser: React.FC<Props> = ({ entity_no, capture_date, onDispatchEvent }) => {
    const classes = productionTableStyles();

    const [poolColumns, setPoolColumns] = React.useState<(t_PoolColumn & { field: string })[]>();
    const [tableData, setTableData] = React.useState<{
        rows: (t_ProductionByUser & t_PoolTableValues)[];
        totals: {
            [Property in keyof t_ProductionByUser_Totals]: t_ProductionByUser_Totals[Property] & t_PoolTableValues;
        };
    }>();
    const [dialogState, setDialogState] = React.useState<{
        type: t_ProductionCellsActions["type"];
        entity_no: number;
    }>();
    const [loading, setLoading] = React.useState(true);

    const cellEventDispatch = (action: t_ProductionCellsActions, entity_no: number) => {
        setDialogState({ type: action.type, entity_no });
    };

    const handleDialogClose = () => {
        setLoading(true);
        setDialogState(undefined);
    };
    React.useEffect(() => {
        var isActive = true;

        if (loading) {
            setPoolColumns(undefined);
            setTableData(undefined);

            if (entity_no != null && capture_date != null) {
                const date = new Date(capture_date);

                getTeamProduction({
                    entity_no,
                    capture_date: formatApiDate(date),
                })
                    .then((data) => {
                        if (isActive) {
                            setPoolColumns(productionPoolColumnTransform(data.pools));
                            setTableData({
                                rows: productionPoolRowTransform(data.rows),
                                totals: {
                                    team_totals: productionPoolTotalTransform(data.totals.team_totals),
                                    team_average: productionPoolTotalTransform(data.totals.team_average),
                                },
                            });
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }
        return () => {
            isActive = false;
        };
    }, [loading]);

    React.useEffect(() => {
        setLoading(true);
    }, [entity_no, capture_date]);

    return (
        <React.Fragment>
            <TableContainer className={classes.container} style={{ maxHeight: "50vh", width: "100%" }}>
                <Table size="small" stickyHeader style={{ whiteSpace: "nowrap", width: "max-content" }}>
                    <TableHead>
                        <ProductionColumns poolColumns={poolColumns}>
                            <TableCell className={classes.boldCell}>Team Member</TableCell>
                        </ProductionColumns>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            tableData?.rows.map((row) => (
                                <TableRow key={`row-${row.user.entity_no}`}>
                                    <ProductionCells
                                        entity_no={row.user.entity_no}
                                        poolColumns={poolColumns}
                                        row={row}
                                        onCellEvent={(action) => cellEventDispatch(action, row.user.entity_no)}
                                    >
                                        <TableCell>{row.user.display_name}</TableCell>
                                    </ProductionCells>
                                </TableRow>
                            ))}
                        {(tableData?.rows.length ?? 0) < 10 && (
                            <TableRow key={`empty-row`}>
                                <TableCell
                                    height={50 * (10 - (tableData?.rows.length ?? 0))}
                                    // rowSpan={10 - (tableData?.rows.length ?? 0)}
                                    colSpan={10 + (poolColumns?.length ?? 0)}
                                >
                                    <div className={classes.feedbackContainer}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                {tableData?.rows.length === 0 && (
                                                    <Typography variant="caption">No records to show.</Typography>
                                                )}
                                                {(entity_no == null || capture_date == null) && (
                                                    <Typography variant="caption">Please select a date.</Typography>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell colSpan={3} align="right" className={classes.boldCell}>
                                Team Total
                            </TableCell>
                            <TableCell align="left" className={classes.boldCell}>
                                {tableData?.totals.team_totals.total_production}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.team_totals.downtime_volume}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.team_totals.downtime}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.team_totals.additional_volume}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.team_totals.actual_volume}
                            </TableCell>
                            <TableCell align="center" className={clsx(classes.boldCell, classes.borderedCell)}>
                                {tableData?.totals.team_totals.weighted_volume}
                            </TableCell>
                            {poolColumns?.map((column) => (
                                <TableCell key={`pool-col-${column.id}`} align="center" className={classes.boldCell}>
                                    {tableData?.totals.team_totals.pool_values[column.field].value}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3} align="right">
                                Team Average
                            </TableCell>
                            <TableCell align="left">{tableData?.totals.team_average.total_production}</TableCell>
                            <TableCell align="center">{tableData?.totals.team_average.downtime_volume}</TableCell>
                            <TableCell align="center">{tableData?.totals.team_average.downtime}</TableCell>
                            <TableCell align="center">{tableData?.totals.team_average.additional_volume}</TableCell>
                            <TableCell align="center">{tableData?.totals.team_average.actual_volume}</TableCell>
                            <TableCell align="center" className={clsx(classes.borderedCell)}>
                                {tableData?.totals.team_average.weighted_volume}
                            </TableCell>
                            {poolColumns?.map((column) => (
                                <TableCell align="center" key={`pool-total-${column.id}`}>
                                    {tableData?.totals.team_average.pool_values[column.field].value}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <ProductionCaptureDialog
                dialog_state={dialogState && { capture_date, ...dialogState }}
                onClose={handleDialogClose}
            />
        </React.Fragment>
    );
};

export default ProductionByUser;
