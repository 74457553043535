import { Close } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SectionCard from "../../components/SectionCard/SectionCard";
import { NavItem } from "../../navigation/components/NavItem/NavItem";
import { RootState } from "../../redux/store";
import CoachingFormGraph from "./CoachingFormGraph";
import CoachingFormTables from "./CoachingFormTables";
import CoachingFormUserDetails from "./CoachingFormUserDetails";
import { emojiFace, emojiThumb } from "./metrics";
import { Field, SurveyData } from "./types";

type Props = {
    formInformation: any;
    coachSurveyResponse: SurveyData[];
    selectedPID: string;
    setSelectedPID: (pid: string) => void;
    combinedData: any;
    setCombinedData: (data: any) => void;
    arrayChips: any;
    setArrayChips: (chips: string[]) => void;
    formFields: Field[];
    setFormFields: (fields: Field[]) => void;
    setMetricID: any;
};

const FormComponents = ({
    formInformation,
    coachSurveyResponse,
    selectedPID,
    setSelectedPID,
    combinedData,
    setCombinedData,
    arrayChips,
    setArrayChips,
    formFields,
    setFormFields,
    setMetricID,
}: Props) => {
    const { formUserDetails, month, metricID, optionsList, selectedMember, formType, dropdownlist } = formInformation;

    const [selectedMetrics, setSelectedMetrics] = useState<any[]>([]);
    const allMetrics = useSelector((state: RootState) => state.metrics.allmetrics);
    const [arrayInput, setArrayInput] = useState("");
    const pid_dropdown_list: string[] = coachSurveyResponse.map(
        (responsePIDNo: SurveyData) => responsePIDNo.ResponseID,
    );

    const callID = coachSurveyResponse.filter((callIDNo: SurveyData) => {
        return callIDNo.ResponseID == selectedPID;
    });
    const membershipNumber = coachSurveyResponse.filter((membershipNo: SurveyData) => {
        return membershipNo.ResponseID == selectedPID;
    });

    const handleInputChange = (sectionIndex: number, key: string, newValue: string | any[]) => {
        const updatedFormFields = [...formFields];
        const field = updatedFormFields[sectionIndex][key] as Field;
        if (typeof field !== "string") {
            field.value = newValue;
            field.error = newValue.length <= 0 ? "Input required" : "";

            if (field.type === "table") {
                field.value = field.value || [];
                field.columns = field.columns || [];
            }
        }
        setFormFields(updatedFormFields);
    };

    const handleChange = (event: SelectChangeEvent<number[]>) => {
        const {
            target: { value },
        } = event;

        const selectedIds = typeof value === "string" ? value.split(",").map(Number) : value;

        const allSelected = selectedIds.includes(0);

        if (allSelected) {
            const filteredMetrics = allMetrics.filter((metric: any) => metric.id !== 0);
            setSelectedMetrics(filteredMetrics);
        } else {
            const selectedMetrics = allMetrics.filter((metric: any) => selectedIds.includes(metric.id));
            setSelectedMetrics(selectedMetrics);
        }
    };

    const handleAutocompleteChange = (sectionIndex: number, key: string, newValue: any) => {
        const updatedFormFields = [...formFields];
        const field = updatedFormFields[sectionIndex][key] as Field;
        if (typeof field !== "string") {
            field.value = newValue;
        }
        setFormFields(updatedFormFields);
    };

    const handleDateChange = (sectionIndex: number, key: string, newValue: any) => {
        const updatedFormFields = [...formFields];
        const field = updatedFormFields[sectionIndex][key] as Field;
        if (typeof field !== "string") {
            field.value = newValue;
        }
        setFormFields(updatedFormFields);
    };

    const handleArrayInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setArrayInput(event.target.value);
    };

    const handleArrayKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            //@ts-ignore
            setArrayChips((prevChips) => [...prevChips, arrayInput]);
            setArrayInput("");
        }
    };

    const handleDeleteArrayChip = (indexToRemove: number) => {
        //@ts-ignore
        setArrayChips((prevChips) => prevChips.filter((_, index) => index !== indexToRemove));
    };

    const handleRemoveMetric = (id: any) => {
        setSelectedMetrics(selectedMetrics.filter((metric) => metric.id !== id));
    };

    useEffect(() => {
        setSelectedMetrics(allMetrics);
    }, [allMetrics]);

    const renderUserDetails = () => (
        <Grid item xs={8}>
            <CoachingFormUserDetails
                name={formUserDetails?.name}
                job_title={formUserDetails?.job_title}
                subject={formUserDetails?.subject}
            />
        </Grid>
    );

    const renderMetricsSelection = () => (
        <Grid item xs={8}>
            <SectionCard title="Selected Metrics" hasBackground>
                <FormControl fullWidth sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginBottom: 2 }}>
                        {selectedMetrics.map((metric) => (
                            <Chip
                                key={metric.id}
                                label={metric.name}
                                onDelete={() => handleRemoveMetric(metric.id)}
                                deleteIcon={<Close sx={{ color: "#ff0000" }} />}
                                sx={{ background: "#c6db34", color: "#ffffff" }}
                            />
                        ))}
                    </Box>
                </FormControl>
            </SectionCard>
        </Grid>
    );

    // Mapping component types to rendering logic
    const renderFieldComponent = (field: Field, key: string, index: number) => {
        switch (field.type) {
            case "input":
                return (
                    <TextField
                        type="text"
                        name={key}
                        error={Boolean(field?.error?.length)}
                        helperText={field.error}
                        multiline={field?.multiline || field?.value?.length > 50}
                        fullWidth={field?.multiline}
                        sx={{ width: field?.multiline ? "100%" : "70%", marginBottom: field?.multiline ? 2 : 0 }}
                        variant={field?.multiline ? "outlined" : "standard"}
                        InputProps={{ disableUnderline: !field?.multiline, style: { borderRadius: 20 } }}
                        value={field.value}
                        onChange={(e) => handleInputChange(index, key, e.target.value)}
                        placeholder="start typing"
                    />
                );
            case "array":
                return (
                    <>
                        <TextField
                            multiline={field?.multiline || field?.value?.length > 50}
                            fullWidth={field?.multiline}
                            sx={{ width: field?.multiline ? "100%" : "70%", marginBottom: field?.multiline ? 2 : 0 }}
                            variant={field?.multiline ? "outlined" : "standard"}
                            InputProps={{ disableUnderline: !field?.multiline, style: { borderRadius: 20 } }}
                            value={arrayInput}
                            onChange={handleArrayInputChange}
                            onKeyDown={handleArrayKeyDown}
                            placeholder="start typing"
                            error={Boolean(field?.error?.length)}
                            helperText={field.error || "Click enter after typing a name for an attendee"}
                        />
                        {arrayChips.length > 0 && (
                            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {arrayChips.map((chip: string, index: number) => (
                                    <Chip
                                        key={index}
                                        label={`${index + 1}. ${chip}`}
                                        style={{ margin: 5 }}
                                        deleteIcon={<Close />}
                                        onDelete={() => handleDeleteArrayChip(index)}
                                    />
                                ))}
                            </Box>
                        )}
                    </>
                );
            case "autocomplete":
                return (
                    <Autocomplete
                        sx={{ width: "100%", marginBottom: 3 }}
                        multiple
                        options={optionsList}
                        clearOnBlur={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={field.value}
                                label="Options for why it happened"
                                placeholder="Options for why it happened"
                                error={Boolean(field?.error?.length)}
                                helperText={field.error}
                            />
                        )}
                        onChange={(event, newValue) => handleAutocompleteChange(index, key, newValue)}
                    />
                );
            case "link":
                return (
                    <Box sx={{ p: 2 }}>
                        <NavItem open={true} label={field.label} target={true} route={String(field?.value)} />
                    </Box>
                );
            case "date":
                return (
                    <DatePicker
                        label="Select A Date"
                        slotProps={{ textField: { variant: "standard" } }}
                        value={field?.value}
                        onChange={(newValue) => handleDateChange(index, key, newValue)}
                        format="dd/MM/yyyy"
                    />
                );
            case "dropdown":
                return (
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel>ADHOC Options</InputLabel>
                        <Select
                            value={field?.value}
                            label="Select ADHOC"
                            variant="standard"
                            displayEmpty
                            onChange={(e) => handleInputChange(index, key, e.target.value)}
                        >
                            {dropdownlist.map((listItem: any) => (
                                <MenuItem value={listItem}>{listItem}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case "pid_dropdown":
                return (
                    <FormControl sx={{ width: "70%" }}>
                        <InputLabel>Response ID</InputLabel>
                        <Select
                            value={field?.value}
                            label="Select Response ID"
                            variant="standard"
                            displayEmpty
                            onChange={(e) => handleInputChange(index, key, e.target.value)}
                        >
                            {pid_dropdown_list.map((listItem) => (
                                <MenuItem value={listItem} onClick={() => setSelectedPID(listItem)}>
                                    {listItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case "pid_input_call":
                return (
                    <TextField
                        type="text"
                        name={key}
                        error={Boolean(field?.error?.length)}
                        helperText={field.error}
                        multiline={field?.multiline || field?.value?.length > 50}
                        fullWidth={field?.multiline}
                        sx={{ width: field?.multiline ? "100%" : "70%", marginBottom: field?.multiline ? 2 : 0 }}
                        variant={field?.multiline ? "outlined" : "standard"}
                        InputProps={{ disableUnderline: !field?.multiline, style: { borderRadius: 20 } }}
                        value={callID[0]?.CallID}
                        onChange={(e) => handleInputChange(index, key, callID[0]?.CallID || e.target.value)}
                        placeholder="start typing"
                    />
                );
            case "pid_input_membership":
                return (
                    <TextField
                        type="text"
                        name={key}
                        error={Boolean(field?.error?.length)}
                        helperText={field.error}
                        multiline={field?.multiline || field?.value?.length > 50}
                        fullWidth={field?.multiline}
                        sx={{ width: field?.multiline ? "100%" : "70%", marginBottom: field?.multiline ? 2 : 0 }}
                        variant={field?.multiline ? "outlined" : "standard"}
                        InputProps={{ disableUnderline: !field?.multiline, style: { borderRadius: 20 } }}
                        value={membershipNumber[0]?.EntityMemberNo}
                        onChange={(e) =>
                            handleInputChange(index, key, membershipNumber[0]?.EntityMemberNo || e.target.value)
                        }
                        placeholder="start typing"
                    />
                );
            case "table":
                return (
                    <CoachingFormTables
                        tableType={field?.tableType}
                        combinedData={combinedData}
                        setCombinedData={setCombinedData}
                        viewMetrics={selectedMetrics}
                    />
                );
            case "emojiface":
                return (
                    <Select
                        value={field?.value}
                        label="Select ADHOC"
                        variant="standard"
                        displayEmpty
                        onChange={(e) => handleInputChange(index, key, e.target.value)}
                        sx={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                                field?.value == "😀"
                                    ? "#D4EFDF"
                                    : field?.value == "😐"
                                      ? "#FCF3CF"
                                      : field?.value == "🙁"
                                        ? "#F5B7B1 "
                                        : "",
                        }}
                    >
                        <MenuItem disabled value="">
                            <em>Reaction Options</em>
                        </MenuItem>
                        {emojiFace.map((listItem) => (
                            <MenuItem
                                value={listItem.icon}
                                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                {listItem.icon}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case "emojithumb":
                return (
                    <Select
                        value={field?.value}
                        label="Select ADHOC"
                        variant="standard"
                        displayEmpty
                        onChange={(e) => handleInputChange(index, key, e.target.value)}
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                                //@ts-ignore
                                field?.value?.id == 1
                                    ? "#D4EFDF"
                                    : //@ts-ignore
                                      field?.value?.id == 2
                                      ? "#F5B7B1 "
                                      : "",
                        }}
                    >
                        <MenuItem disabled value="">
                            <em>Reaction Options</em>
                        </MenuItem>
                        {emojiThumb.map((listItem: any) => (
                            <MenuItem
                                value={listItem}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {listItem.icon}
                            </MenuItem>
                        ))}
                    </Select>
                );
            default:
                return null;
        }
    };

    // Render each form field
    const renderFormFields = () =>
        formFields.map((section: any, index: number) => (
            <Grid item xs={8} key={index}>
                <SectionCard key={index} title={section.title} hasBackground fullWidth>
                    {Object.entries(section).map(([key, value], sectionIndex: number) => {
                        if (key === "title") return null;
                        const field = value as Field;

                        const flexStyles =
                            !field.multiline && !field.showGraph ? { display: "flex", flexDirection: "row" } : {};

                        return (
                            <React.Fragment key={sectionIndex}>
                                <Box key={sectionIndex} sx={[flexStyles, { py: 0.5, px: 2 }]}>
                                    {!field.multiline &&
                                        field.type !== "autocomplete" &&
                                        field.type !== "link" &&
                                        field.type !== "table" && (
                                            <Typography
                                                sx={{
                                                    width: field.type === "emojiface" ? "70%" : "30%",
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                {field.label}
                                            </Typography>
                                        )}
                                    {renderFieldComponent(field, key, index)}
                                </Box>
                                {sectionIndex < Object.entries(section).length - 1 && (
                                    <Divider
                                        sx={{ width: "95%", marginLeft: "auto", marginRight: "auto", marginBottom: 1 }}
                                    />
                                )}
                                {field?.showGraph && (
                                    <CoachingFormGraph
                                        entity_no={selectedMember}
                                        month={month}
                                        metricID={metricID}
                                        setMetricID={setMetricID}
                                        formType={formType}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </SectionCard>
            </Grid>
        ));
    return { renderUserDetails, renderMetricsSelection, renderFormFields };
};

export default FormComponents;
