import { Box, Button, Dialog, useTheme } from "@mui/material";
import { Column } from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import BUMaterialTable from "../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard from "../../../components/SectionCard/SectionCard";
import { t_StreamData } from "../TwtGroup.types";
import styles from "./styles.module.css";
import EditStream from "../sections/EditStream/EditStream";
import NewStream from "../sections/NewStream/NewStream";
import { getTWTstreams } from "../twtStreams.api";

const MaintainTWTstream = () => {
    const [dialogState, setDialogState] = React.useState<{
        open: boolean;
        row?: t_StreamData;
    }>({
        open: false,
    });

    const [newStreamDialogOpen, setNewStreamDialogOpen] = React.useState(false);

    const [columns] = React.useState<Column<any>[]>([
        {
            title: "Name",
            field: "Name",
        },
        {
            title: "Actions",
            render: (data: t_StreamData) => {
                return (
                    <Box>
                        <div
                            className={styles.actionIcon}
                            onClick={() =>
                                setDialogState({ open: true, row: data })
                            }
                        >
                            <EditIcon />
                        </div>
                    </Box>
                );
            },
        },
    ]);

    const tableRef = React.useRef<any>();

    const theme = useTheme();

    return (
        <>
            <div className={styles.container}>
                <SectionCard title={"Streams"} fullWidth scrolling>
                    <div className={styles.mainBtnGroup}>
                        <Button
                            className={styles.mainBtn}
                            onClick={() =>
                                tableRef.current &&
                                tableRef.current.onQueryChange()
                            }
                            style={{
                                border: `solid ${theme.palette.info.dark} 1px`,
                                color: theme.palette.info.contrastText,
                            }}
                        >
                            Refresh Stream List
                        </Button>

                        <Button
                            className={styles.mainBtn}
                            onClick={() => setNewStreamDialogOpen(true)}
                            style={{
                                backgroundColor: theme.palette.info.main,
                                color: theme.palette.info.contrastText,
                                marginLeft: "1em",
                            }}
                        >
                            Create New Stream
                        </Button>
                    </div>

                    <BUMaterialTable
                        title={"Streams"}
                        tableRef={tableRef}
                        columns={columns}
                        data={(query) =>
                            getTWTstreams({
                                ...query,
                                pagination: {
                                    page: query.page + 1,
                                    rows_per_page: query.pageSize,
                                },
                                filter: [
                                    ...query.filters,
                                    {
                                        column_name: "Name",
                                        search_term: query.search,
                                    },
                                ],
                                sort: {
                                    column_name: "Name",
                                    order: "ASC",
                                },
                            })
                        }
                        options={{
                            search: true,
                            sorting: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20, 50],
                            paging: true,
                        }}
                    />
                </SectionCard>
            </div>

            <Dialog
                open={dialogState.open}
                onClose={() =>
                    setDialogState((state) => ({ ...state, open: false }))
                }
                scroll="paper"
            >
                {dialogState.row && (
                    <EditStream
                        tableRef={tableRef}
                        handleClose={() =>
                            setDialogState((state) => ({
                                ...state,
                                open: false,
                            }))
                        }
                        group={dialogState.row}
                    />
                )}
            </Dialog>

            <Dialog
                open={newStreamDialogOpen}
                onClose={() => setNewStreamDialogOpen(false)}
                scroll="paper"
            >
                <NewStream handleClose={() => setNewStreamDialogOpen(false)} />
            </Dialog>
        </>
    );
};

export default MaintainTWTstream;
