import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { formatApiDate } from "../../../../misc/helpers";
import ProductionCaptureDialog from "../../dialogs/ProductionCaptureDialog/ProductionCaptureDialog";
import { t_PoolColumn, t_PoolTableValues } from "../../Production.types";
import ProductionCells from "../components/ProductionCells/ProductionCells";
import { t_ProductionCellsActions } from "../components/ProductionCells/ProductionCells.type";
import ProductionColumns from "../components/ProductionColumns/ProductionColumns";
import {
    productionPoolColumnTransform,
    productionPoolRowTransform,
    productionPoolTotalTransform,
} from "../Production.table.helpers";
import productionTableStyles from "../Production.table.styles";
import { getUserProduction } from "./ProductionByDate.api";
import { t_ProductionByDate, t_ProductionByDate_Totals } from "./ProductionByDate.types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { walkthroughUserTotals, user_overview } from "../../../../pages/Home/BeYouCoach/WalkThroughData";

interface Props {
    entity_no?: number;
    date: Date | null;
    month?: string;
    start_date?: string;
    end_date?: string;
    styles?: string | any;
    className?: string;
}

const ProductionByDate: React.FC<Props> = ({ entity_no, date, end_date, start_date, styles, className }) => {
    const productionWalkThrough = useSelector((state: RootState) => state.walkThrough.productionWalkThrough);
    const classes = productionTableStyles();
    const [poolColumns, setPoolColumns] = React.useState<(t_PoolColumn & { field: string })[]>();
    const [tableData, setTableData] = React.useState<{
        rows: (t_ProductionByDate & t_PoolTableValues)[];
        totals: t_ProductionByDate_Totals & t_PoolTableValues;
    }>();
    const [dialogState, setDialogState] = React.useState<{
        type: t_ProductionCellsActions["type"];
        capture_date: string;
    }>();
    const [loading, setLoading] = React.useState(false);

    const cellEventDispatch = (action: t_ProductionCellsActions, capture_date: string) => {
        setDialogState({ type: action.type, capture_date });
    };

    const totalColspan = 3;

    const handleDialogClose = (reason?: string) => {
        setLoading(true);
        setDialogState(undefined);
    };

    React.useEffect(() => {
        var isActive = true;

        if (loading) {
            setPoolColumns(undefined);
            setTableData(undefined);
            if (entity_no != null && date != null) {
                if (productionWalkThrough) {
                    setLoading(false);
                    setTableData({
                        //@ts-ignore
                        rows: user_overview,
                        //@ts-ignore
                        totals: walkthroughUserTotals,
                    });
                } else {
                    getUserProduction({
                        entity_no,
                        periods: {
                            start_date: start_date,
                            end_date: end_date,
                        },
                    })
                        .then((data) => {
                            if (isActive) {
                                setPoolColumns(productionPoolColumnTransform(data.pools));
                                setTableData({
                                    rows: productionPoolRowTransform(data.rows),
                                    totals: productionPoolTotalTransform(data.totals),
                                });
                            }
                        })
                        .finally(() => {
                            if (isActive) {
                                setLoading(false);
                            }
                        });
                }
            } else {
                setLoading(false);
            }
        }
        return () => {
            isActive = false;
        };
    }, [loading, start_date]);

    React.useEffect(() => {
        setLoading(true);
    }, [entity_no, date]);
    return (
        <React.Fragment>
            <TableContainer
                className={`${className} ${classes.container}`}
                style={{ maxHeight: "50vh", width: "100%" }}
            >
                <Table size="small" stickyHeader style={{ ...styles, whiteSpace: "nowrap", width: "max-content" }}>
                    <TableHead>
                        <ProductionColumns poolColumns={poolColumns}>
                            {/* Children here are inserted in a specific spot in the columns (2nd row, 1st col) */}
                            <TableCell className={classes.boldCell}>Date</TableCell>
                        </ProductionColumns>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            entity_no != null &&
                            tableData?.rows.map((row) => (
                                <TableRow
                                    key={row.date}
                                    className={clsx({
                                        [classes.weekendRow]: !row.work_day,
                                    })}
                                >
                                    <ProductionCells
                                        entity_no={entity_no}
                                        row={row}
                                        poolColumns={poolColumns}
                                        onCellEvent={(action) => cellEventDispatch(action, row.date)}
                                    >
                                        <TableCell width="auto">
                                            {formatApiDate(row.date, "eee, dd MMM yyyy")}
                                        </TableCell>
                                    </ProductionCells>
                                </TableRow>
                            ))}
                        {(tableData?.rows.length ?? 0) < 10 && (
                            <TableRow key={`empty-row`}>
                                <TableCell
                                    height={50 * (10 - (tableData?.rows.length ?? 0))}
                                    // rowSpan={10 - (tableData?.rows.length ?? 0)}
                                    colSpan={10 + (poolColumns?.length ?? 0)}
                                >
                                    <div className={classes.feedbackContainer}>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                {tableData?.rows.length === 0 && (
                                                    <Typography variant="caption">No records to show.</Typography>
                                                )}
                                                {(entity_no == null || date == null) && (
                                                    <Typography variant="caption">
                                                        Please select a user and date.
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {/* Totals */}
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right" className={classes.boldCell}>
                                Month Total
                            </TableCell>
                            <TableCell align="left" className={classes.boldCell}>
                                {tableData?.totals.total_production}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.downtime_volume}
                            </TableCell>
                            <TableCell align="center" className={`${classes.boldCell}`}>
                                {tableData?.totals.downtime}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.additional_volume}
                            </TableCell>
                            <TableCell align="center" className={classes.boldCell}>
                                {tableData?.totals.actual_volume}
                            </TableCell>
                            <TableCell align="center" className={clsx(classes.boldCell, classes.borderedCell)}>
                                {tableData?.totals.weighted_volume}
                            </TableCell>
                            {poolColumns?.map((column) => (
                                <TableCell key={`${column.field}-total`} align="center" className={classes.boldCell}>
                                    {tableData?.totals.pool_values[column.field].value}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right">
                                Normal Work Days
                            </TableCell>
                            <TableCell align="left" className={classes.borderedCell}>
                                {tableData?.totals.normal_work_days}
                            </TableCell>
                            <TableCell className={classes.noBorderCell} colSpan={5 + (poolColumns?.length ?? 0)} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right">
                                Leave
                            </TableCell>
                            <TableCell align="left" className={classes.borderedCell}>
                                {tableData?.totals.leave_days}
                            </TableCell>
                            <TableCell className={classes.noBorderCell} colSpan={5 + (poolColumns?.length ?? 0)} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right">
                                Employee Work Days
                            </TableCell>
                            <TableCell align="left" className={classes.borderedCell}>
                                {tableData?.totals.employee_work_days}
                            </TableCell>
                            <TableCell className={classes.noBorderCell} colSpan={5 + (poolColumns?.length ?? 0)} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right">
                                Month's Target
                            </TableCell>
                            <TableCell align="left" className={classes.borderedCell}>
                                {tableData?.totals.target.target_value}
                            </TableCell>
                            <TableCell className={classes.noBorderCell} colSpan={5 + (poolColumns?.length ?? 0)} />
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={totalColspan} align="right" className={classes.boldCell}>
                                &#37; of Target Achieved
                            </TableCell>
                            <TableCell align="left" className={clsx(classes.boldCell, classes.borderedCell)}>
                                {tableData?.totals.target.percentage_achieved}
                            </TableCell>
                            <TableCell className={classes.noBorderCell} colSpan={5 + (poolColumns?.length ?? 0)} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <ProductionCaptureDialog
                dialog_state={dialogState && { entity_no: entity_no ?? 0, ...dialogState }}
                onClose={handleDialogClose}
            />
        </React.Fragment>
    );
};

export default ProductionByDate;
