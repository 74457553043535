import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    FirstPageOutlined,
    LastPageOutlined,
    Search,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { useUserState } from "../../../context/user/user.ctx";
import debounce from "../../../misc/debounce";
import { getImageUrl, stringAvatar } from "../../../misc/helpers";

type Column = {
    title: string;
    field: string;
    render?: (value: any, row: DataItem) => React.ReactNode;
};

type DataItem = {
    [key: string]: any;
};

interface LeaderboardTableProps {
    columns: Column[];
    data: DataItem[];
    title: string;
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ columns, data, title }) => {
    const currentUser = useUserState();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const debouncedSearch = useCallback(
        debounce(300, (text) => {
            setSearchQuery(text);
            setCurrentPage(1);
        }),
        [],
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value);
    };

    const handleRowsPerPageChange = (e: SelectChangeEvent<number>) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const getRowBorderColor = (rank: number): string => {
        if (rank === 1) return "#30D843";
        return theme.palette.info.main;
    };

    const sortedData = useMemo(() => {
        const dataCopy = [...data];
        const loggedInUserIndex = dataCopy.findIndex((item) => item.entity_no === currentUser.entity_no);
        if (loggedInUserIndex !== -1) {
            const [user] = dataCopy.splice(loggedInUserIndex, 1);
            dataCopy.unshift(user);
        }
        return dataCopy;
    }, [data, currentUser]);
    const currentUserLeaderboard = sortedData.find((user) => user.EntityNo == currentUser.entity_no);

    const filteredData = useMemo(
        () =>
            sortedData.filter((item) =>
                columns.some(
                    (column) =>
                        item[column.field] != null &&
                        item[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase()),
                ),
            ),
        [searchQuery, sortedData, columns],
    );

    const paginatedData = useMemo(
        () => filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage),
        [filteredData, currentPage, rowsPerPage],
    );

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const LoggedInUserRank = () => (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "3fr 3fr 3fr  1fr 1fr",
                alignItems: "center",
                border: `2px solid ${getRowBorderColor(currentUserLeaderboard?.rank)}`,
                borderRadius: 5,
                marginY: 1,
                padding: 1,
                background: `${currentUserLeaderboard?.EntityNo === currentUser.entity_no ? "#F0FF8966" : ""}`,
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar src={getImageUrl("profile", currentUserLeaderboard?.profile_img)} />
                <Typography sx={{ ml: 1, fontWeight: "bold" }}>{currentUserLeaderboard?.DisplayName}</Typography>
            </Box>
            <Typography>{currentUserLeaderboard?.role}</Typography>
            {/* <Typography>{currentUserLeaderboard?.department}</Typography> */}
            <Typography>{currentUserLeaderboard?.team_name}</Typography>
            <Typography>{currentUserLeaderboard?.total_sum}</Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.4em" }}>#{currentUserLeaderboard?.rank}</Typography>
        </Box>
    );

    return (
        <Box
            sx={{
                background: theme.palette.primary.main,
                padding: 2,
                borderRadius: 2,
                maxHeight: "80vh",
                overflowY: "scroll",
                mt: 5,
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 4,
                    background: theme.palette.primary.main,
                    paddingY: 2,
                    zIndex: 10,
                }}
            >
                <Typography variant="h6">{title}</Typography>
                <TextField
                    placeholder="Search..."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    onChange={handleSearchChange}
                />
            </Box>
            {data.length > 0 ? (
                <Box>
                    <table style={{ width: "100%" }}>
                        <Box sx={{ display: "grid", gridTemplateColumns: "3fr 3fr 3fr 1fr 1fr" }}>
                            {columns.map((col) => (
                                <Typography key={col.title} sx={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                    {col.title}
                                </Typography>
                            ))}
                        </Box>
                        <Box>
                            {currentUserLeaderboard?.rank > 1 && <LoggedInUserRank />}
                            {paginatedData.map((data) => (
                                <>
                                    <Box
                                        key={data.entity_no}
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "3fr 3fr 3fr  1fr 1fr",
                                            alignItems: "center",
                                            border: `2px solid ${getRowBorderColor(data.rank)}`,
                                            borderRadius: 5,
                                            marginY: 1,
                                            padding: 1,
                                            background: `${data.EntityNo === currentUser.entity_no ? "#F0FF8966" : ""}`,
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Avatar
                                                src={getImageUrl("profile", data.profile_img)}
                                                {...stringAvatar(data.DisplayName)}
                                            />
                                            <Typography sx={{ ml: 1, fontWeight: "bold" }}>
                                                {data.DisplayName}
                                            </Typography>
                                        </Box>
                                        <Typography>{data.role}</Typography>
                                        {/* <Typography>{data.department}</Typography> */}
                                        <Typography>{data.team_name}</Typography>
                                        <Typography>{data.total_sum}</Typography>
                                        <Typography sx={{ fontWeight: "bold", fontSize: "1.4em" }}>
                                            #{data.rank}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </>
                            ))}
                        </Box>
                    </table>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <FormControl>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                sx={{
                                    "& fieldset": {
                                        border: "none",
                                    },
                                }}
                            >
                                {[5, 10, 15, 20].map((num: any) => (
                                    <MenuItem key={num} value={num}>
                                        {num}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box>
                            <IconButton onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                                <FirstPageOutlined />
                            </IconButton>
                            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                <ChevronLeftOutlined />
                            </IconButton>
                            <small>
                                {currentPage} of {totalPages}
                            </small>
                            <IconButton
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                <ChevronRightOutlined />
                            </IconButton>
                            <IconButton
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                            >
                                <LastPageOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                        padding: 5,
                    }}
                >
                    <Typography color="textSecondary"> No records to display currently.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default LeaderboardTable;
