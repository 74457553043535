import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yearSelect: {
      maxWidth: "70px",
      marginRight: "20px",
    },
    select: {
      padding: "0 10px",
    },
  })
);
