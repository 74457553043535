import DateFnsUtils from "@date-io/date-fns";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
import React, { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorHandlerProvider } from "../context/error/error.ctx";
import ModalProvider from "../context/modal";
import { RequestQueueProvider } from "../context/notifications/requestQueue.ctx";
import BUThemeProvider from "../context/theme/theme.ctx";
import { UserMetricsProvider } from "../context/user/metrics.ctx";
import PermissionProvider from "../context/user/permissions.ctx";
import { UserProvider } from "../context/user/user.ctx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PerformanceContextProvider } from "../sections/Performance/Performance.ctx";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { NotificationsProvider } from "../components/Toasts/NotificationsProvider.ctx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const snackbarProps: { anchorOrigin: SnackbarOrigin } = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
};

const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Provider store={store}>
            <BUThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider {...snackbarProps}>
                        <ErrorHandlerProvider>
                            <RequestQueueProvider>
                                <UserMetricsProvider>
                                    <UserProvider>
                                        <NotificationsProvider>
                                            <PermissionProvider>
                                                <PerformanceContextProvider>
                                                    <ModalProvider>{children}</ModalProvider>
                                                </PerformanceContextProvider>
                                            </PermissionProvider>
                                        </NotificationsProvider>
                                    </UserProvider>
                                </UserMetricsProvider>
                            </RequestQueueProvider>
                        </ErrorHandlerProvider>
                    </SnackbarProvider>
                </LocalizationProvider>

                <ToastContainer position="bottom-right" newestOnTop />
            </BUThemeProvider>
        </Provider>
    );
};

export default Providers;
