import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { MiscBody } from "../../../../types/api.types";
import { t_WeightedPool } from "./PoolWeights.types";

const ColumnMap: { [key: string]: string } = {
  entity_no: "PoolNumber",
  name: "Name",
};
const getWeightedPools = (payload: { query: Query<t_WeightedPool> }) => {
  return new Promise<QueryResult<t_WeightedPool>>((resolve, reject) => {
    var converted = convertQuery(payload.query);

    // we convert the columns here due to an outdated iceberg on the backend
    converted.filter = converted.filter.map((filter) => {
      if (filter.column_name in ColumnMap) {
        filter.column_name = ColumnMap[filter.column_name];
      }
      return filter;
    });
    const body = {
      ...converted,
    };
    httpClient.post("/admin/production/pools", body).then(({ data }) =>
      resolve({
        data: data.data.pools,
        page: data.pagination.page - 1,
        totalCount: data.total_results,
      })
    );
  });
};

const getWeightedPoolsSearch = async (payload?: {} & MiscBody) => {
  const resp = await httpClient.post("/admin/production/pools", payload);
  return resp.data;
};

const getWeightedRegionsSearch = async (payload?: {} & MiscBody) => {
  const resp = await httpClient.post("/admin/twt/grouping/regions", payload);
  return resp.data;
};

const getWeightedServiceEnviromentsSearch = async (payload?: {} & MiscBody) => {
  const resp = await httpClient.post("/admin/twt/grouping/serviceenvironments", payload);
  return resp.data;
};

const saveWeightedPool = (payload: { pool: t_WeightedPool }) => {
  return new Promise((resolve, reject) => {
    const body = {
      pool_id: payload.pool.id,
      expected_handling_time: payload.pool.handling_time,
    };
    httpClient.post("/admin/production/pools/update", body).then((response) => {
      switch (response.data?.status) {
        case "success":
          resolve(true);
          break;
        default:
          reject();
          break;
      }
    });
  });
};

export { getWeightedPools, saveWeightedPool, getWeightedPoolsSearch, getWeightedServiceEnviromentsSearch, getWeightedRegionsSearch };
