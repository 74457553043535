import { Box, Button, Grid, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";

import {
    useCoachSurveyMutation,
    useGetSubmissionCountQuery,
    useSubmitCoachFormMutation,
} from "../../redux/api/beYouCore";
import AnimatedText from "./AnimatedText";
import FormComponents from "./FormComponents";
import CoachTableSubmissionData from "./Tables/CoachTableSubmissionData";
import { Section, SurveyData } from "./types";

const FormBuilder = ({ formInformation, setSelectedMember, setUserDetails, setMetricID, region }: any) => {
    const { formData, month, selectedMember, formType } = formInformation;

    const [submitCoachForm] = useSubmitCoachFormMutation();
    const [coachSurvey] = useCoachSurveyMutation();
    const theme = useTheme();
    const [formFields, setFormFields] = useState<any>([]);
    const [submissionError, setSubmissionError] = useState<any>();
    const { enqueueSnackbar } = useSnackbar();

    const [arrayChips, setArrayChips] = useState<string[]>([]);
    const [formTableSubmissionData, setFormTableSubmissionData] = useState<any>();
    const [coachSurveyResponse, setCoachSurveyResponse] = useState<SurveyData[]>([]);
    const [combinedData, setCombinedData] = useState({
        metrics: [],
        metricRatings: [],
        toImprove: [],
        pipOptions: [],
        pipPerformance: [],
        metricReviews: [],
    });

    const formTableData = useMemo(() => {
        return CoachTableSubmissionData(combinedData);
    }, [combinedData]);

    const [selectedPID, setSelectedPID] = useState<any>();

    const { refetch } = useGetSubmissionCountQuery({
        type: formType,
        month,
        entity_no: selectedMember,
    });

    const { renderFormFields, renderMetricsSelection, renderUserDetails } = FormComponents({
        formInformation,
        coachSurveyResponse,
        selectedPID,
        setSelectedPID,
        combinedData,
        setCombinedData,
        arrayChips,
        setArrayChips,
        formFields,
        setFormFields,
        setMetricID,
    });

    useEffect(() => {
        setFormFields(formData);
    }, [formData, formInformation]);

    useEffect(() => {
        if (formTableData) {
            const { grow_coaching_table_data, pip_follow_up_table_data, pip_initiation_table_data } = formTableData;

            switch (formType) {
                case "grow":
                    setFormTableSubmissionData(grow_coaching_table_data);
                    break;
                case "pip_initiation":
                    setFormTableSubmissionData(pip_initiation_table_data);
                    break;
                case "pip_follow_up":
                    setFormTableSubmissionData(pip_follow_up_table_data);
                    break;
                default:
                    setFormTableSubmissionData({});
            }
        }
    }, [formTableData, formType]);

    const callID = coachSurveyResponse.filter((callIDNo: SurveyData) => {
        return callIDNo.ResponseID == selectedPID;
    });
    const membershipNumber = coachSurveyResponse.filter((membershipNo: SurveyData) => {
        return membershipNo.ResponseID == selectedPID;
    });

    useEffect(() => {
        if (formType == "adhoc" || formType == "ops_loss" || formType == "dpmo" || formType == "perception") {
            refetch();
        }
    }, [month, formType]);

    useEffect(() => {
        coachSurvey({ month, entity_no: selectedMember })
            .unwrap()
            .then((response) => {
                if (response.status == "success") {
                    setCoachSurveyResponse(response.data.survey_data);
                } else {
                    throw Error("Error getting coach survey data");
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
    }, [month, selectedMember]);

    const clearAllFields = () => {
        const clearedFormFields = formFields.map((section: Section) => {
            const clearedSection: Section = { ...section };
            Object.keys(clearedSection).forEach((key) => {
                if (key !== "title") {
                    const field = clearedSection[key];
                    if (typeof field !== "string") {
                        field.value = "";
                        field.error = "";
                    }
                }
            });
            return clearedSection;
        });
        setFormFields(clearedFormFields);
        setSelectedMember();
        setUserDetails(null);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        let hasError = false;

        formFields.forEach((section: Section) => {
            Object.keys(section).forEach((key) => {
                if (key != "title") {
                    const field = section[key];
                    if (typeof field != "string") {
                        if (field.type == "array" && arrayChips.length == 0) {
                            field.error = "Please add at least one chip";
                            hasError = true;
                        } else if (field.type == "array") {
                            field.value = arrayChips;
                        } else if (!field.value) {
                            field.error = "Input required";
                            hasError = true;
                        }
                        if (field.value || (field.type == "array" && arrayChips.length > 0)) {
                            field.error = "";
                        }
                        if (field.type == "text") {
                            field.error = "";
                            hasError = false;
                        }
                        if (field.type == "pid_input_call" && callID[0]?.CallID?.length > 0) {
                            field.error = "";
                            hasError = false;
                        }
                        if (field.type == "pid_input_membership" && membershipNumber[0]?.EntityMemberNo?.length > 0) {
                            field.error = "";
                            hasError = false;
                        }
                        if (field.type == "table") {
                            field.error = "";
                            hasError = false;
                        }
                        if (field.type == "link") {
                            field.error = "";
                            hasError = false;
                        }
                        if (field.showGraph) {
                            field.error = "";
                            hasError = false;
                        }
                    }
                }
            });
        });

        if (hasError) {
            console.error(submissionError);
            setFormFields([...formFields]);
            setSubmissionError("Please fill in all required fields.");
            enqueueSnackbar("Please fill in all required fields.", { variant: "error" });
            return;
        }

        const submissionData: { [key: string]: string | any[] } = {};
        formFields.forEach((section: Section) => {
            Object.keys(section).forEach((key) => {
                if (key != "title") {
                    const field = section[key];
                    if (typeof field != "string") {
                        submissionData[key] = field.value;
                    }
                }
            });
        });
        if (formType.toLowerCase() == "written_warning") submissionData["attendees"] = arrayChips;
        if (formType.toLowerCase() == "perception") submissionData["pid"] = callID[0]?.CallID;
        if (formType.toLowerCase() == "perception")
            submissionData["membership_no"] = membershipNumber[0]?.EntityMemberNo;

        const metricsArray = Object.values(formTableSubmissionData);
        const findMetric = (metricId: string, array: any) => {
            return array.find((metric: any) => metric.metricId == metricId) || {};
        };

        switch (formType) {
            case "grow":
                submissionData["ssm_metric"] = findMetric("ssm_metric", metricsArray);
                submissionData["mbr_metric"] = findMetric("mbr_metric", metricsArray);
                submissionData["dpmo_metric"] = findMetric("dpmo_metric", metricsArray);
                submissionData["fcr_metric"] = findMetric("fcr_metric", metricsArray);
                submissionData["production_metric"] = findMetric("production_metric", metricsArray);
                submissionData["aaa_metric"] = findMetric("aaa_metric", metricsArray);
                submissionData["knowledge_metric"] = findMetric("knowledge_metric", metricsArray);
                submissionData["tl_ssm_metric"] = findMetric("tl_ssm_metric", metricsArray);
                submissionData["twt_metric"] = findMetric("twt_metric", metricsArray);
                submissionData["tl_responsibilities_metric"] = findMetric("tl_responsibilities_metric", metricsArray);
                submissionData["service_level_metric"] = findMetric("service_level_metric", metricsArray);
                submissionData["knowledge_assessment_metric"] = findMetric("knowledge_assessment_metric", metricsArray);
                submissionData["tl_people_ssm_metric"] = findMetric("tl_people_ssm_metric", metricsArray);
                submissionData["adhoc_errors_metric"] = findMetric("adhoc_errors_metric", metricsArray);
                submissionData["pinpoint_missed_metric"] = findMetric("pinpoint_missed_metric", metricsArray);
                submissionData["project_evaluation_multiplier_metric"] = findMetric(
                    "project_evaluation_multiplier_metric",
                    metricsArray,
                );
                submissionData["tat_metric"] = findMetric("tat_metric", metricsArray);
                submissionData["sps_metric"] = findMetric("sps_metric", metricsArray);
                submissionData["activation_rate_metric"] = findMetric("activation_rate_metric", metricsArray);
                submissionData["perception_metric"] = findMetric("perception_metric", metricsArray);
                submissionData["quarterly_objective_metric"] = findMetric("quarterly_objective_metric", metricsArray);
                submissionData["combined_arrears_ssm_metric"] = findMetric("combined_arrears_ssm_metric", metricsArray);
                submissionData["bs_recon_metric"] = findMetric("bs_recon_metric", metricsArray);
                submissionData["demographics_ssm_metric"] = findMetric("demographics_ssm_metric", metricsArray);
                submissionData["metric_ssm_metric"] = findMetric("metric_ssm_metric", metricsArray);
                submissionData["rating_metric"] = findMetric("rating_metric", metricsArray);
                submissionData["hit_rate_metric"] = findMetric("hit_rate_metric", metricsArray);
                submissionData["management_rating_metric"] = findMetric("management_rating_metric", metricsArray);
                submissionData["recruitment_target_metric"] = findMetric("recruitment_target_metric", metricsArray);
                submissionData["trainee_performance_metric"] = findMetric("trainee_performance_metric", metricsArray);
                submissionData["tasks_metric"] = findMetric("tasks_metric", metricsArray);
                submissionData["errors_metric"] = findMetric("errors_metric", metricsArray);
                submissionData["visits_metric"] = findMetric("visits_metric", metricsArray);
                submissionData["facility_survey_metric"] = findMetric("facility_survey_metric", metricsArray);
                submissionData["qualifier_metric"] = findMetric("qualifier_metric", metricsArray);
                submissionData["wellness_days_metric"] = findMetric("wellness_days_metric", metricsArray);
                submissionData["employer_liaison_metric"] = findMetric("employer_liaison_metric", metricsArray);
                submissionData["admission_management_metric"] = findMetric("admission_management_metric", metricsArray);
                submissionData["readmission_prevention_metric"] = findMetric(
                    "readmission_prevention_metric",
                    metricsArray,
                );
                submissionData["volume_boosternon_emerg_admis_metric"] = findMetric(
                    "volume_boosternon_emerg_admis_metric",
                    metricsArray,
                );
                submissionData["collection_success_metric"] = findMetric("collection_success_metric", metricsArray);
                submissionData["informa_savings_metric"] = findMetric("informa_savings_metric", metricsArray);
                submissionData["post_go_live_metric"] = findMetric("post_go_live_metric", metricsArray);
                submissionData["admin_metric"] = findMetric("admin_metric", metricsArray);
                submissionData["service_score_metric"] = findMetric("service_score_metric", metricsArray);
                submissionData["coverage_metric"] = findMetric("coverage_metric", metricsArray);
                submissionData["dbr_metric"] = findMetric("dbr_metric", metricsArray);
                submissionData["regular_users_portfolio_metric"] = findMetric(
                    "regular_users_portfolio_metric",
                    metricsArray,
                );
                submissionData["enrollment_metric"] = findMetric("enrollment_metric", metricsArray);
                submissionData["engaged_users_of_portfolio_metric"] = findMetric(
                    "engaged_users_of_portfolio_metric",
                    metricsArray,
                );
                submissionData["engaged_users_of_target_metric"] = findMetric(
                    "engaged_users_of_target_metric",
                    metricsArray,
                );
                submissionData["happiness_survey_metric"] = findMetric("happiness_survey_metric", metricsArray);
                submissionData["monthly_objectives_metric"] = findMetric("monthly_objectives_metric", metricsArray);
                submissionData["transformation_metric"] = findMetric("transformation_metric", metricsArray);
                submissionData["nps_survey_metric"] = findMetric("nps_survey_metric", metricsArray);
                submissionData["disputes_metric"] = findMetric("disputes_metric", metricsArray);
                submissionData["qa_clinical_reporting_metric"] = findMetric(
                    "qa_clinical_reporting_metric",
                    metricsArray,
                );
                submissionData["sample_audit_metric"] = findMetric("sample_audit_metric", metricsArray);
                submissionData["team_target_metric"] = findMetric("team_target_metric", metricsArray);
                submissionData["qa_of_qa_metric"] = findMetric("qa_of_qa_metric", metricsArray);
                submissionData["qa_error_metric"] = findMetric("qa_error_metric", metricsArray);
                submissionData["continuous_improvement_metric"] = findMetric(
                    "continuous_improvement_metric",
                    metricsArray,
                );
                submissionData["engagement_metric"] = findMetric("engagement_metric", metricsArray);
                submissionData["daily_cases_worked_metric"] = findMetric("daily_cases_worked_metric", metricsArray);
                submissionData["book_worked_metric"] = findMetric("book_worked_metric", metricsArray);
                submissionData["improvement_metric"] = findMetric("improvement_metric", metricsArray);
                submissionData["trainer_ready_assess_metric"] = findMetric("trainer_ready_assess_metric", metricsArray);
                submissionData["trainer_survey_metric"] = findMetric("trainer_survey_metric", metricsArray);
                submissionData["management_score_metric"] = findMetric("management_score_metric", metricsArray);
                submissionData["wellness_advisor_connect_metric"] = findMetric(
                    "wellness_advisor_connect_metric",
                    metricsArray,
                );
                submissionData["performance_score_metric"] = findMetric("performance_score_metric", metricsArray);
                submissionData["delivery_to_schedule_metric"] = findMetric("delivery_to_schedule_metric", metricsArray);
                submissionData["testing_metric"] = findMetric("testing_metric", metricsArray);
                submissionData["initiative_metric"] = findMetric("initiative_metric", metricsArray);
                submissionData["ces_metric"] = findMetric("ces_metric", metricsArray);
                submissionData["social_media_ka_metric"] = findMetric("social_media_ka_metric", metricsArray);
                submissionData["blas_loaded_metric"] = findMetric("blas_loaded_metric", metricsArray);
                submissionData["bic_innovation_metric"] = findMetric("bic_innovation_metric", metricsArray);
                submissionData["acc_man_ssm_metric"] = findMetric("acc_man_ssm_metric", metricsArray);
                submissionData["follow_up_metric"] = findMetric("follow_up_metric", metricsArray);
                submissionData["efficiency_metric"] = findMetric("efficiency_metric", metricsArray);
                submissionData["pps_monthly_cib_metric"] = findMetric("pps_monthly_cib_metric", metricsArray);
                submissionData["pps_quarterly_cib_metric"] = findMetric("pps_quarterly_cib_metric", metricsArray);
                submissionData["convert_to_d2c_metric"] = findMetric("convert_to_d2c_metric", metricsArray);
                submissionData["conservation_rate_metric"] = findMetric("conservation_rate_metric", metricsArray);
                submissionData["member_do_arrears_ssm_metric"] = findMetric(
                    "member_do_arrears_ssm_metric",
                    metricsArray,
                );
                submissionData["mda_full_set_target_metric"] = findMetric("mda_full_set_target_metric", metricsArray);
                submissionData["wac_daily_ops_metric"] = findMetric("wac_daily_ops_metric", metricsArray);
                submissionData["paper_claims_received_metric"] = findMetric(
                    "paper_claims_received_metric",
                    metricsArray,
                );
                submissionData["audit_list_completed_filling_metric"] = findMetric(
                    "audit_list_completed_filling_metric",
                    metricsArray,
                );
                submissionData["sampleaudit_metric"] = findMetric("sampleaudit_metric", metricsArray);
                submissionData["dispatcher_error_metric"] = findMetric("dispatcher_error_metric", metricsArray);
                submissionData["parcel_consolidation_metric"] = findMetric("parcel_consolidation_metric", metricsArray);
                submissionData["department_rotation_metric"] = findMetric("department_rotation_metric", metricsArray);
                submissionData["ws_coordinator_score_metric"] = findMetric("ws_coordinator_score_metric", metricsArray);
                submissionData["ws_coordinator_score_card_metric"] = findMetric(
                    "ws_coordinator_score_card_metric",
                    metricsArray,
                );
                submissionData["call_rate_metric"] = findMetric("call_rate_metric", metricsArray);
                submissionData["clinical_audit_metric"] = findMetric("clinical_audit_metric", metricsArray);
                break;

            case "pip_initiation":
                submissionData["dpmo_metric"] = findMetric("dpmo_metric", metricsArray);
                submissionData["fcr_metric"] = findMetric("fcr_metric", metricsArray);
                submissionData["mbr_metric"] = findMetric("mbr_metric", metricsArray);
                submissionData["production_metric"] = findMetric("production_metric", metricsArray);
                submissionData["options_object"] = formTableSubmissionData["options_object"];
                break;

            case "pip_follow_up":
                submissionData["dpmo_metric"] = findMetric("dpmo_metric", metricsArray);
                submissionData["fcr_metric"] = findMetric("fcr_metric", metricsArray);
                submissionData["mbr_metric"] = findMetric("mbr_metric", metricsArray);
                submissionData["production_metric"] = findMetric("production_metric", metricsArray);
                submissionData["options_object"] = formTableSubmissionData["options_object"];
                break;

            default:
                break;
        }

        submitCoachForm({
            submissionData: {
                ...submissionData,
                entity_no: selectedMember,
            },
            formEndPoint: formType,
        })
            .then((response) => {
                //@ts-ignore
                if (response?.data?.status == "success") {
                    clearAllFields();
                    enqueueSnackbar("Form Submitted Successfully.", { variant: "success" });
                }
            })
            .catch((error) => {
                console.error("🚀 ~ coachFormSubmission ~ error:", error);
            });
    };

    let regionImage;

    switch (region.toLowerCase()) {
        case "gau":
            regionImage = "/images/GAU.png";
            break;
        case "wcp":
            regionImage = "/images/WCP.png";
            break;
        case "kzn":
            regionImage = "/images/KZN.png";
            break;
        case "ecp":
            regionImage = "/images/ECP.jpg";
            break;

        default:
            regionImage = "/images/discovery.jpg";
            break;
    }

    return (
        <form>
            <Grid container spacing={2} style={{ padding: 16, placeContent: "center", position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: "repeat-x",
                        backgroundImage: "url(/images/background-wave.png)",
                        opacity: 0.02,
                    }}
                />

                <Grid item xs={8}>
                    <Box
                        sx={{
                            backgroundImage: `url(${regionImage})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "30vh",
                            borderRadius: 1,
                            boxShadow:
                                "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                            display: "flex",
                            alignItems: "center",
                            padding: 4,
                            position: "relative",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                background: "linear-gradient(to right bottom,rgba(0,0,0,0.3),rgba(0,0,0,0.3))",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        />
                        <div
                            style={{
                                position: "relative",
                                zIndex: 2,

                                width: "100%",
                            }}
                        >
                            <AnimatedText text={region.toUpperCase()} />
                        </div>
                    </Box>
                </Grid>
                {renderUserDetails()}
                {(formType === "grow" || formType === "pip_initiation" || formType === "pip_follow_up") &&
                    renderMetricsSelection()}
                {renderFormFields()}
                <Grid item xs={8}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 2 }}>
                        <Button onClick={handleSubmit} variant="contained" sx={{ width: 350 }}>
                            Save
                        </Button>
                        <Button
                            onClick={clearAllFields}
                            variant="outlined"
                            sx={{ width: 350, borderColor: `${theme.palette.secondary.contrastText}` }}
                        >
                            Clear Fields
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
export default FormBuilder;
