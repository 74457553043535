import { Dialog } from "@mui/material";
import React from "react";
import AdditionalProduction from "../../forms/AdditionalProduction/AdditionalProduction";
import Downtime from "../../forms/Downtime/Downtime";
import OtherLeave from "../../forms/OtherLeave/OtherLeave";
import { t_ProductionCellsActions } from "../../tables/components/ProductionCells/ProductionCells.type";

interface Props {
	dialog_state?: {
		type: t_ProductionCellsActions["type"];
		entity_no: number;
		capture_date: string;
	};
	onClose: (reason?: string) => void;
}

const ProductionCaptureDialog: React.FC<Props> = ({
	dialog_state,
	onClose,
}) => {
	return (
		<Dialog open={Boolean(dialog_state)} onClose={() => onClose()}>
			{dialog_state?.type === "leave" && (
				<OtherLeave {...dialog_state} onClose={onClose} />
			)}
			{dialog_state?.type === "downtime" && (
				<Downtime {...dialog_state} onClose={onClose} />
			)}

			{dialog_state?.type === "additional_volume" && (
				<AdditionalProduction {...dialog_state} onClose={onClose} />
			)}
		</Dialog>
	);
};

export default ProductionCaptureDialog;
