import {
	FormControl,
	IconButton,
	Input,
	InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useState } from "react";
import SectionCard from "../../../components/SectionCard/SectionCard";
import HelpFileSearch from "../../../sections/HelpFiles/sections/HelpFileSearch/HelpFileSearch";

const Help: React.FC = () => {
	const [search, setSearch] = useState<string>();
	return (
        <SectionCard
			title='Search'
			primaryAction={
				<FormControl>
					<Input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton size="large">
									<Search />
								</IconButton>
							</InputAdornment>
						}
					></Input>
				</FormControl>
			}
			scrolling
		>
			<HelpFileSearch search={search} />
		</SectionCard>
    );
};

export default Help;
