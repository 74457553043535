import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const agentSurveyRecordsStyles = makeStyles((theme: Theme) =>
	createStyles({
		agentTableContainer: {
			padding: theme.spacing(2, 0),
			backgroundColor: theme.palette.secondary.main,
		},
	})
);

export default agentSurveyRecordsStyles;
