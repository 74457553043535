import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

import { metrics } from "../../metrics";

interface Column {
    id: string;
    label: string;
    type: string;
}

const SubmittedMetricRatingTable = ({ data }: any) => {
    const columns = [
        {
            id: "option",
            label: "Option",
            type: "input",
        },
        {
            id: "measure",
            label: "Measure",
            type: "input",
        },
        {
            id: "by_when",
            label: "By When",
            type: "date",
        },
        {
            id: "realistic",
            label: "Realistic",
            type: "emojiThumb",
        },
    ];

    const createData = (metric: string, data: any) => {
        const metricData = data[metric];
        return {
            metric,
            option: metricData?.option || "",
            measure: metricData?.measure || "",
            by_when: metricData?.by_when || "",
            realistic: metricData?.realistic || "",
        };
    };

    const initialRowsState = metrics.reduce((acc: any, metric) => {
        acc[metric.id] = createData(metric.id, data);
        return acc;
    }, {});

    const [rows, setRows] = useState<{
        [key: string]: { metric: string; option: string; measure: string; by_when: string; realistic: string };
    }>(initialRowsState);

    useEffect(() => {
        setRows(initialRowsState);
    }, [data]);

    const metricsWithData = metrics.filter((metric) => {
        const metricData = data[metric.id];

        if (!metricData) {
            return;
        }

        return (
            metricData.option != null ||
            metricData.measure != null ||
            metricData.by_when != null ||
            metricData.realistic != null ||
            metricData.new_target != null
        );
    });

    return (
        <TableContainer>
            {metricsWithData?.map((metric) => (
                <Table stickyHeader key={metric.id} sx={{ marginBottom: 2 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={2} sx={{ fontWeight: "bold" }}>
                                {metric.metric}
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                Target
                            </TableCell>

                            <TableCell align="center" sx={{ color: "blue" }}>
                                {data[metric.id]?.new_target}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell key={column.id} sx={{ fontWeight: "bold" }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Row
                            row={rows[metric.id]}
                            metricIndex={metric.id}
                            columns={columns}
                            //@ts-ignore
                        />
                    </TableBody>
                </Table>
            ))}
        </TableContainer>
    );
};

interface RowProps {
    row: { metric: string; option: string; realistic: string };
    metricIndex: any;
    // rowIndex: number;
    columns: Column[];
}

const Row: React.FC<RowProps> = ({ row, columns }) => {
    return (
        <TableRow>
            {columns?.map((column) => {
                //@ts-ignore
                const value = row[column.id];

                return (
                    <TableCell
                        key={column.id}
                        align="center"
                        sx={{
                            backgroundColor:
                                value == "😀" || value == "👍" || value == "✔"
                                    ? "#D4EFDF"
                                    : value == "😐"
                                      ? "#FCF3CF"
                                      : value == "🙁" || value == "👎" || value == "❌"
                                        ? "#F5B7B1 "
                                        : "",
                        }}
                    >
                        {column.type == "date"
                            ? moment(value, true).isValid()
                                ? moment(value).format("DD-MM-YYYY")
                                : ""
                            : value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default SubmittedMetricRatingTable;
