import { Avatar, Badge, Box, IconButton } from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import React from "react";
import BadgeCard from "../../../../components/BadgeCard/BadgeCard";
import { getImageUrl } from "../../../../misc/helpers";
import ChallengeCard from "../../../Challenge/components/ChallengeCard/ChallengeCard";
import { t_ActivityData, t_NewsFeedActions } from "../../NewsFeed.types";
import ActivityCard from "../ActivityCard/ActivityCard";
import newsfeedItemStyles from "./NewsfeedItem.styles";

const ItemLikes: React.FC<{
    like_count?: number;
    liked?: boolean;
    handleLiked: () => void;
}> = (props) => (
    <Badge badgeContent={props.like_count}>
        <IconButton aria-label="Heart" onClick={props.handleLiked} size="large">
            {props.liked ? <Favorite style={{ color: "#FF6565" }} /> : <FavoriteBorder />}
        </IconButton>
    </Badge>
);

interface Props {
    activity?: t_ActivityData;
    dispatch: (action: t_NewsFeedActions) => any;
}

const NewsFeedItem: React.FC<Props> = ({ activity, dispatch }) => {
    const classes = newsfeedItemStyles();

    const handleLike = () => {
        dispatch({
            type: "ITEM_INTERACTION",
            item: activity,
        });
    };

    /** @MM Alternative way to return; */
    switch (activity?.type) {
        case "badge":
            if (activity.badge == null) {
                return null;
            }
            return (
                <ActivityCard
                    activity={activity}
                    avatar={<Avatar src={getImageUrl("profile", activity?.profile_img)} />}
                    title={`${activity.display_name} received a new badge`}
                    actions={<ItemLikes {...activity} handleLiked={handleLike} />}
                >
                    <BadgeCard
                        title={activity.badge.name}
                        subtitle={activity.badge.description}
                        badge_img={activity.badge.badge_img}
                        innerProps={{
                            card: {
                                elevation: 0,
                                className: classes.activityCard,
                            },
                        }}
                    />
                </ActivityCard>
            );
        case "tier":
            if (activity.tier == null) {
                return null;
            }
            return (
                <ActivityCard
                    activity={activity}
                    avatar={<Avatar src={getImageUrl("profile", activity?.profile_img)} />}
                    title={
                        <Box component="span" display="inline">
                            {activity.display_name}&nbsp;
                            <span>has just achieved</span>&nbsp;
                            <span
                                style={{
                                    color: `#${activity.tier.colour}`,
                                }}
                            >
                                {activity.tier.name}
                            </span>
                        </Box>
                    }
                    actions={<ItemLikes {...activity} handleLiked={handleLike} />}
                />
            );
        case "challenge":
            // if (activity.challenge == null) {
            // 	return null;
            // }

            if (activity.challenge == null || activity.challenge) {
                return null;
            }

            return (
                <ActivityCard
                    activity={activity}
                    avatar={<Avatar src={getImageUrl("profile", activity?.profile_img)} />}
                    title={`${activity.display_name} shared a challenge`}
                    actions={<ItemLikes {...activity} handleLiked={handleLike} />}
                >
                    <ChallengeCard
                        innerProps={{
                            card: {
                                elevation: 0,
                                className: classes.activityCard,
                            },
                        }}
                        challenge={activity.challenge}
                    />
                </ActivityCard>
            );
        default:
            return <></>;
    }
};

export { ItemLikes };

export default NewsFeedItem;
