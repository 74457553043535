import { Box, CircularProgress, Grid, Pagination } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import removeCookie from "../../hooks/cookies/removeCookies";
import { useAwardedBadgesMutation } from "../../redux/api/beYouCore";
import { RootState } from "../../redux/store";
import { MiscBody } from "../../types/api.types";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import useProfileChange from "../Profile/components/CoachProfileCard/userProfileChange";
import ActiveBadge from "./ActiveBadge";
import BadgeList from "./BadgeList";

const Badges = () => {
    const [{ month, comparison_month }, performanceDispatch] = usePerformanceContext();
    const date = new Date(month);
    const [loading, setLoading] = useState(false);
    const [selectedBadge, setSelectedBadge] = useState<any>();
    const tabNumber = selectedBadge?.is_system == 0 ? 1 : 0;
    const [sectionTab, setSectionTab] = useState<number>(0);

    const [pagination, setPagination] = useState<MiscBody["pagination"]>({
        page: 1,
        rows_per_page: 4,
        count: 1,
    });
    const [badgeAwardedBy, setBadgeAwardedBy] = useState<any>();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const badge_id = queryParams.get("badge_id");

    const [badgeIdNo, setBadgeIdNo] = useState<number | null>(Number(badge_id));
    const { currentUser } = useProfileChange();

    const badges = currentUser?.badge_collection;
    const user = useSelector((state: RootState) => state.user.userInfo);
    const entity_no = user?.entity_no;

    const [awardedBadges] = useAwardedBadgesMutation();

    useEffect(() => {
        if (entity_no) {
            const selectedBadge = badgeIdNo
                ? badges?.find((badge: any) => badge.id == badgeIdNo)
                : sectionTab == 0
                  ? badges?.find((badge: any) => badge?.is_system == 1)
                  : sectionTab == 1
                    ? badges?.find((badge: any) => badge?.is_system == 0)
                    : null;
            setSectionTab(tabNumber);
            setSelectedBadge(selectedBadge);
            setBadgeAwardedBy(null);

            if (selectedBadge) {
                awardedBadges({ badge_id: selectedBadge?.id, entity_no: user.entity_no })
                    .then(({ data }: any) => {
                        setBadgeAwardedBy(data?.data?.badge);
                    })
                    .catch((error) => {
                        console.error("Error retrieving awarded badges:", error);
                    });
            }
        }
    }, [entity_no, month, comparison_month, location.search, selectedBadge, badges]);

    const handleBadgeClickSystem = (badgeId: number) => {
        setBadgeIdNo(badgeId);
        const badge = badges.find((badge: any) => {
            return badge.id == badgeId;
        });

        if (badge) {
            setSelectedBadge(badge);
        }
    };

    const handleDateChange = (date: any) => {
        if (date !== null) {
            performanceDispatch({
                type: "update_dates",
                month: date,
            });
        }
        removeCookie("metrics");
        removeCookie("user profile");
    };

    return (
        <>
            <SectionCard
                title="BeYou Coach"
                subtitle={currentUser.profile?.name}
                fullWidth
                primaryAction={
                    <TabGroup
                        tabList={[
                            {
                                _id: 0,
                                label: "System Assigned Badges",
                            },
                            {
                                _id: 1,
                                label: "User Assigned Badges",
                            },
                        ]}
                        tabChange={(newValue) => {
                            setSectionTab(newValue);
                            setBadgeIdNo(null);
                            setSelectedBadge(null);

                            const filteredBadges =
                                newValue == 0
                                    ? badges?.filter((badge: any) => badge.is_system == 1)
                                    : badges?.filter((badge: any) => badge.is_system == 0);
                            setSelectedBadge(filteredBadges[0]);
                        }}
                        tabValue={sectionTab}
                    />
                }
                secondaryAction={
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={date}
                        onChange={handleDateChange}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                }
            >
                <SectionCard hasBackground fullWidth noHeader>
                    {!loading ? (
                        <Grid container>
                            <Grid item md={12} xs={12} lg={4}>
                                <ActiveBadge data={selectedBadge} badgeAwardedBy={badgeAwardedBy} />
                            </Grid>
                            <Grid item md={12} xs={12} lg={8}>
                                <Box
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexFlow: "column",
                                            width: "100%",
                                            borderLeft: "1px solid #F0F0F0",
                                        }}
                                    >
                                        <BadgeList
                                            selectedBadgeId={selectedBadge}
                                            badges={
                                                sectionTab == 0
                                                    ? badges
                                                          ?.filter((badge: any) => badge.is_system == 1)
                                                          .map((badge: any) => ({
                                                              badge_img: badge.badge_img,
                                                              title: badge.name,
                                                              count: badge.count,
                                                              is_system: badge.is_system,
                                                              id: badge.id,
                                                          }))
                                                    : badges
                                                          ?.filter((badge: any) => badge.is_system == 0)
                                                          .map((badge: any) => ({
                                                              badge_img: badge.badge_img,
                                                              title: badge.name,
                                                              count: badge.count,
                                                              is_system: badge.is_system,
                                                              id: badge.id,
                                                          }))
                                            }
                                            flat
                                            style={{
                                                padding: 16,
                                            }}
                                            justify="flex-start"
                                            onBadgeClick={handleBadgeClickSystem}
                                        />
                                        <Pagination
                                            count={pagination?.count}
                                            page={pagination?.page}
                                            onChange={(_, page) =>
                                                setPagination(
                                                    (state) =>
                                                        state && {
                                                            ...state,
                                                            page,
                                                        },
                                                )
                                            }
                                            style={{ alignSelf: "center" }}
                                            size="large"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </SectionCard>
            </SectionCard>
        </>
    );
};

export default Badges;
