import React from "react";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import BUAutocomplete from "../../../../components/BUAutocomplete/BUAutocomplete";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { MiscBody } from "../../../../types/api.types";
import styles from "./styles.module.css";
import { getWeightedPoolsSearch } from "../../../Production/tables/PoolWeights/PoolWeights.api";
import { t_Pool } from "../../TwtGroup.types";

const defaultOption = {
    id: "",
    name: "",
};

interface TeamGroupPoolProps {
    handleClose: () => void;
    addTeamPool: (poolNumber: number, userEntityNo?: number) => void;
    userEntityNo?: number;
    memberName?: string;
}

const TeamGroupPool = ({ handleClose, addTeamPool, userEntityNo }: TeamGroupPoolProps) => {
    const [currentPool, setCurrentPool] = React.useState<t_Pool>();

    const selectPools = (args: { search: string } & MiscBody) => {
        const { search, ...misc } = args;
        return new Promise<{ id: string; name: string }[]>((res, rej) => {
            getWeightedPoolsSearch({
                ...misc,
                filter: [
                    {
                        column_name: "name",
                        search_term: search,
                    },
                ],
            })
                .then((resp) => {
                    const poolOpts = resp.data.pools.map((pool: any) => ({
                        id: pool.id,
                        name: pool.name,
                    }));

                    res(
                        poolOpts.map((opt: any) => ({
                            ...opt,
                            id: opt.id?.toString() || "",
                        })),
                    );
                })
                .catch((err) => rej(err.message));
        });
    };

    const handleSelectPool = (value: any) => {
        value && setCurrentPool(value);
    };

    const addPool = () => {
        currentPool &&
            getWeightedPoolsSearch({
                filter: [
                    {
                        column_name: "name",
                        search_term: currentPool.name,
                    },
                ],
            })
                .then((resp) => {
                    switch (resp.status) {
                        case "success":
                            return addTeamPool(+resp.data.pools[0].entity_no, userEntityNo);
                        default:
                    }
                })
                .catch((err) => console.error(err.message));
    };

    return (
        <SectionCard
            title={`Assign ${userEntityNo ? "Member" : "Team"} Pool`}
            titleProps={{
                variant: "h6",
            }}
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <div className={styles.autoCompl}>
                <BUAutocomplete
                    label="Select Pool"
                    inputClassName={styles.poolField}
                    dataSource={selectPools}
                    value={defaultOption}
                    onChange={handleSelectPool}
                />
            </div>
            <Button variant="outlined" style={{ display: "block", margin: "2em auto 0" }} onClick={addPool}>
                Add Pool
            </Button>
        </SectionCard>
    );
};

export default TeamGroupPool;
