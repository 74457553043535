import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const userCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			minWidth: 500,
			minHeight: 300,
			display: "grid",
			placeItems: "center",
		},
		card: {
			minWidth: 500,
		},
		actionsDiv: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
		},
	})
);

export default userCardStyles;
