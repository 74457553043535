import React, { useState } from "react";
import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { Box, Button, Dialog, Typography } from "@mui/material";
import ItemActions from "../../Toasts/ToastsActions";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { Icons, toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import LsaDialogContent from "../../../sections/LowScoreAnalysis/dialogs/LsaDialogContent/LsaDialogContent";

const LSA = ({ lsa, removeNotification }: any) => {
    const [openLSADialog, setOpenLSADialog] = useState(false);

    return (
        <Box key={lsa?.id}>
            <Dialog open={openLSADialog} maxWidth="md" onClose={() => setOpenLSADialog(false)}>
                <Box style={{ padding: 30 }}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <Close onClick={() => setOpenLSADialog(false)} style={{ cursor: "pointer" }} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4" gutterBottom>
                            Low Score Analysis
                        </Typography>
                    </Box>
                    <LsaDialogContent
                        lsa_id={lsa.lsa.LsaID}
                        metric_id={lsa.lsa.LsaDataMetricID}
                        record={lsa.lsa.survey_record}
                        lsa={{
                            DataID: lsa.lsa.survey_record.DataID,
                            StatusID: lsa.lsa.StatusID,
                        }}
                    />
                </Box>
            </Dialog>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
                onClick={() => {
                    setOpenLSADialog(true);
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {lsa?.icon ||
                        Icons.info({
                            theme: lsa?.theme || "light",
                            type: toast.TYPE.INFO,
                            width: 30,
                            height: 30,
                        })}
                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        LSA
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {lsa?.user.DisplayName}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={lsa?.lsa.LsaDateAdded} />
                            </Typography>
                        </Typography>
                        {/* <ItemActions
                    notification={lsa}
                    markAsRead={markAsRead}
                    remove={remove}
                        /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LSA;
