import React from "react";

const RaisedExcited = () => {
    return (
        <g id="Eyebrow/Outline/Raised-Excited" fillOpacity="0.599999964">
            <g id="I-Browse" transform="translate(12.000000, 0.000000)">
                <path
                    d="M3.97579559,17.1279169 C5.47099148,7.60476158 18.0585488,1.10867597 27.1635167,5.30104271 C28.1668367,5.76301969 29.3546946,5.32417444 29.8166716,4.32085442 C30.2786486,3.3175344 29.8398033,2.12967649 28.8364833,1.66769952 C17.3488212,-3.62177466 1.93575948,4.3324746 0.0242044059,16.507492 C-0.147121205,17.5986938 0.598585765,18.6221744 1.68978754,18.7935 C2.78098932,18.9648257 3.80446998,18.2191187 3.97579559,17.1279169 Z"
                    id="Eyebrow"
                    fillRule="nonzero"
                />
                <path
                    d="M61.9757956,17.1279169 C63.4709915,7.60476158 76.0585488,1.10867597 85.1635167,5.30104271 C86.1668367,5.76301969 87.3546946,5.32417444 87.8166716,4.32085442 C88.2786486,3.3175344 87.8398033,2.12967649 86.8364833,1.66769952 C75.3488212,-3.62177466 59.9357595,4.3324746 58.0242044,16.507492 C57.8528788,17.5986938 58.5985858,18.6221744 59.6897875,18.7935 C60.7809893,18.9648257 61.80447,18.2191187 61.9757956,17.1279169 Z"
                    id="Eyebrow"
                    fillRule="nonzero"
                    transform="translate(73.000097, 9.410436) scale(-1, 1) translate(-73.000097, -9.410436) "
                />
            </g>
        </g>
    );
};

export default RaisedExcited;
