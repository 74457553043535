import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { useGetAvatarItemsQuery } from "../../redux/api/beYouCore";
import OvationIcon from "./ovationImages/ovation.svg";
import Unlock from "./ovationImages/unlock.svg";
import useOvation from "./useOvation";

type UnlockItemProps = {
    closeModal: () => void;
    icon: string;
    color: string;
    type: string;
    title: string;
    id: number;
    numberOfOvations: number;
};

const UnlockItem = ({ closeModal, icon, type, id, numberOfOvations, color, title }: UnlockItemProps) => {
    const theme = useTheme();
    const { unlockAvatarItem, isLoading } = useOvation();
    const { enqueueSnackbar } = useSnackbar();
    const [itemIsUnlocked, setItemIsUnlocked] = useState(false);

    const { isLoading: avatarItemLoading, refetch } = useGetAvatarItemsQuery(type);

    const handleUnlockItem = async () => {
        try {
            if (numberOfOvations <= 0) {
                enqueueSnackbar("Not enough ovations to unlocks", { variant: "error" });
                return;
            }
            const response = await unlockAvatarItem({ item_id: id });

            if (response?.success) {
                setItemIsUnlocked(true);
                enqueueSnackbar("Item Unlocked Successfully.", { variant: "success" });
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Box sx={{ p: 5 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                <Button aria-label="close" onClick={closeModal}>
                    <Close />
                </Button>
            </Box>
            {!itemIsUnlocked ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #E3ED9D",
                            borderRadius: "20px",
                            width: 400,
                            height: 300,
                            marginBottom: "10px",
                            overflow: "hidden",
                            padding: 6,
                            position: "relative",
                            backgroundColor: color ? color : undefined,
                        }}
                    >
                        {icon && <img src={icon} style={{ width: "100%", height: "100%" }} />}
                    </Box>

                    <Typography sx={{ fontWeight: "bold", fontSize: 25, textAlign: "center" }} gutterBottom>
                        Do you wish to unlock {type}?
                    </Typography>
                    <Typography sx={{ fontWeight: "500", fontSize: 15, textAlign: "center", mb: 1 }}>
                        You need <span style={{ color: theme.palette.info.main, fontWeight: "bold" }}>2</span> Ovations
                        to unlock this customisation
                    </Typography>
                    <Box
                        sx={{
                            border: "1px solid #E3ED9D",
                            borderRadius: "11px",
                            mb: 3,
                            p: 2,
                            width: "50%",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box>
                                <Typography color="GrayText" sx={{ fontWeight: "500", fontSize: 15 }} gutterBottom>
                                    Your Ovations:
                                </Typography>
                                <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>{numberOfOvations}</Typography>
                            </Box>
                            <img
                                src={OvationIcon}
                                style={{ fill: "red", width: "30%", height: "40%", marginLeft: 10 }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Button variant="outlined" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={isLoading} onClick={handleUnlockItem}>
                            {isLoading ? <CircularProgress /> : "Unlock"}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Box sx={{ mb: 5 }}>
                        <img src={Unlock} style={{ width: "100%", height: "100%" }} />
                    </Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: 25, textAlign: "center" }} gutterBottom>
                        Congrats! You've unlocked {title}
                    </Typography>
                    <Button variant="contained" disabled={avatarItemLoading} onClick={() => refetch()}>
                        {avatarItemLoading ? <CircularProgress /> : "close"}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default UnlockItem;
