import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";

import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_ProdPeriodOptionsFormActions } from "../../sections/MaintainPeriods/MaintainPeriods.types";
import { defaultPeriod } from "../../sections/MaintainPeriods/MintainProdPeriods.constants";
import { t_ProductionPeriod } from "../../tables/ProductionPeriods/ProductionPeriodsEditor";
import ProdPeriodOptionsForm from "../Forms/ProdPeriodsOptionsForm";

interface Props {
  option?: t_ProductionPeriod;
  onClose: (reason?: "submitted" | "cancel") => void;
}

const InitiativeOption: React.FC<Props> = ({ option, onClose }) => {
  const handleFormEvent = (action: t_ProdPeriodOptionsFormActions) => {
    onClose(action);
  };

  return (
    <SectionCard
      title={`${option ? "Update" : "Create New"} Production Period`}
      titleProps={{
        variant: "h6",
      }}
      secondaryAction={
        <IconButton onClick={() => onClose()} size="large">
          <Close />
        </IconButton>
      }
    >
      <ProdPeriodOptionsForm
        formData={option ?? defaultPeriod}
        dispatch={handleFormEvent}
      />
    </SectionCard>
  );
};

export default InitiativeOption;
