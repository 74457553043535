import { httpClient } from "../../clients/http.client";
import { MiscBody } from "../../types/api.types";

const TABLE_COLUMNS = [
    // {
    // 	label: "Points",
    // 	name: "Points",
    // },
    // {
    // 	label: "Tier",
    // 	name: "Tier",
    // },
    {
        label: "AHT",
        name: "AHT",
    },
    {
        label: "DPMO",
        name: "DPMO",
    },
    {
        label: "FCR",
        name: "FCR",
    },
    {
        label: "MBR",
        name: "MBR",
    },
    {
        label: "Production",
        name: "Production",
    },
    {
        label: "Attendance",
        name: "Attendance",
    },
    {
        label: "Engagement",
        name: "Engagement",
    },
];

type HistoricData = {
    comparison_date?: string;
};
const getMetricRecords = <T extends object>(payload: { metric_id: number; entity_no: number; month: string }) =>
    new Promise<T[]>((resolve, reject) => {
        const body = {
            entity_no: payload.entity_no,
            metric_id: payload.metric_id,
            month: payload.month,
        };
        httpClient
            .post("/metrics/records", body)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.records);
                        break;

                    default:
                        reject(data.message);
                        break;
                }
            })
            .catch(reject);
    });

const getUserMetrics = async (
    payload: {
        entity_no: number;
        month?: string;
        comparison_month?: string;
        incentive: boolean;
    } & MiscBody,
) => {
    const response = await httpClient.post("/metrics/summary/user", payload);
    return response.data.data.metric_values;
};

export { getMetricRecords, getUserMetrics, TABLE_COLUMNS };
