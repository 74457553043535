import { useEffect, useState } from "react";
import { Grid, Box, Typography, Button, Divider, TextField, CircularProgress, IconButton } from "@mui/material";
import RatingsComponent from "../../components/RatingsComponent/RatingsComponent";
import { Close, ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from "react-toastify";
import { useStyles } from "./Styles";
import { useUserState } from "../../context/user/user.ctx";
import { useSaveSummaryMutation } from "../../redux/api/beYouCore";

const ReviewFeedback = ({ summaryDetails, setReviewFeedbackOpen }: any) => {
    const classes = useStyles();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const [animationFinished, setAnimationFinished] = useState(true);
    const [summaryExpand, setSummaryExpand] = useState(true);
    const [responseExpand, setResponseExpand] = useState(true);
    const [loading, setLoading] = useState(false);
    const currentUser = useUserState();

    const [summaryLoading, setSummaryLoading] = useState(false);
    const [summaryReview, setSummaryReview] = useState({
        user_name: "",
        date: "",
        summary: "",
        rating: 0,
        summary_metrics: [],
        response: "",
        id: 0,
    });

    const [summaryNew, setSummaryNew] = useState("");
    const [summaryResponse, setSummaryResponse] = useState("");
    const [ratingsValue, setRatingsValue] = useState<number | null>(0);
    const [saveSummary] = useSaveSummaryMutation();

    const { EntityNo: entity_no, date: month, id } = summaryDetails;

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        const handleAnimationEnd = () => {
            setAnimationFinished(true);
        };
        const imageElement = document.getElementById("overlay-image");
        imageElement?.addEventListener("animationend", handleAnimationEnd);
        return () => {
            imageElement?.removeEventListener("animationend", handleAnimationEnd);
        };
    }, []);

    useEffect(() => {
        setLoading(true);

        if (summaryDetails) {
            setSummaryReview(summaryDetails);
            setLoading(false);
        }
    }, [summaryDetails]);

    const sortedMetrics = [...summaryDetails?.summary_metrics].sort((a, b) => {
        return b.percentage_of_target - a.percentage_of_target;
    });

    const handleSaveSummary = () => {
        setSummaryLoading(true);

        const editSummary = {
            // user_name: summaryReview.user_name,
            // date: summaryReview.date,
            summary: summaryNew !== "" ? summaryNew : summaryReview.summary,
            rating: ratingsValue ? ratingsValue : 0,
            // summary_metrics: summaryReview?.summary_metrics,
            response: summaryResponse !== "" ? summaryResponse : summaryReview.response,
            summary_id: summaryReview?.id,
        };

        if (editSummary.summary === "") {
            toast.error("A summary is required", {
                position: "bottom-right",
            });
            setSummaryLoading(false);
            return;
        }

        if (summaryReview.response !== "")
            saveSummary(editSummary)
                .then((response: any) => {
                    toast.success(response.data.message, {
                        position: "bottom-right",
                    });
                })
                .catch((error) => {
                    console.error("🚀 ~ file: WeeklyFeedback.tsx:79 ~ getSummary ~ error:", error);
                })
                .finally(() => {
                    setSummaryLoading(false);
                    setReviewFeedbackOpen(false);
                });
    };

    if (loading) {
        return (
            <Box p={4}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className={classes.overlay}>
            <Grid container spacing={2}>
                <Grid
                    item
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "1rem",
                        margin: "auto",
                        position: "relative",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            inset: 0,
                        }}
                        onClick={() => {
                            setReviewFeedbackOpen(false);
                        }}
                    ></div>
                    <Box
                        className={`${classes.textContainer} ${isVisible ? classes.visible : ""}`}
                        style={{ minHeight: "50em" }}
                    >
                        <Box mb={2} display="flex" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6" style={{ fontWeight: "bold" }} gutterBottom>
                                    Perfomance Reflection
                                </Typography>
                                <Typography variant="body2" color="textPrimary">
                                    This was the perfomance reflection for week ending on: {summaryReview?.date}
                                </Typography>
                            </Box>
                            <Close
                                onClick={() => setReviewFeedbackOpen(false)}
                                style={{ cursor: "pointer", color: "gray" }}
                            />
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h6" gutterBottom>
                                Weekly stats for {summaryReview?.date}
                            </Typography>

                            <Divider />
                            <Box
                                style={{
                                    minHeight: "10em",
                                    maxHeight: "13em",
                                    overflowY: "scroll",
                                    scrollBehavior: "smooth",
                                    padding: ".8em",
                                }}
                            >
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    sortedMetrics.map((metric, i) => (
                                        <Box
                                            key={metric.metric_name}
                                            mb={2}
                                            borderRadius="10px"
                                            p={1}
                                            bgcolor={`${
                                                i === sortedMetrics.length - 1 || i === sortedMetrics.length - 2
                                                    ? "#e5ecf2"
                                                    : ""
                                            }`}
                                            className={`${i === 0 || i === 1 ? classes.borderRotate : ""}`}
                                        >
                                            <Box display="flex" justifyContent="space-between" px={1}>
                                                <Typography variant="body2" gutterBottom>
                                                    {metric.metric_name}
                                                </Typography>
                                                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                                    {" "}
                                                    {metric.value}{" "}
                                                    {metric.value && metric.target ? `/ ${metric.target}` : ""}
                                                </Typography>
                                            </Box>
                                            <ProgressBar
                                                completed={metric.percentage_of_target}
                                                height="5px"
                                                maxCompleted={100}
                                                isLabelVisible={false}
                                                bgColor={`${
                                                    i === 0 || i === 1
                                                        ? "#30D843"
                                                        : i === sortedMetrics.length - 1 ||
                                                          i === sortedMetrics.length - 2
                                                        ? "#FF4040"
                                                        : "#0086FC"
                                                }`}
                                            />
                                        </Box>
                                    ))
                                )}
                            </Box>
                            <Divider />
                        </Box>

                        {summaryReview?.rating ? (
                            <Box mb={2} minHeight="6.5em">
                                <Typography variant="h6" gutterBottom>
                                    Rating
                                </Typography>

                                <RatingsComponent value={summaryReview?.rating} />
                            </Box>
                        ) : (
                            <Box mb={2} minHeight="6.5em">
                                <Typography variant="h6" gutterBottom>
                                    Give your rating of this week
                                </Typography>

                                <RatingsComponent
                                    value={ratingsValue ? ratingsValue : 0}
                                    onChange={(e: any) => setRatingsValue(e.target.value)}
                                />
                            </Box>
                        )}
                        {summaryReview?.summary ? (
                            <Box mb={2}>
                                <Box display="flex" alignItems="center" style={{ position: "relative" }}>
                                    {" "}
                                    <Typography variant="h6" gutterBottom>
                                        Summary
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            setSummaryExpand(!summaryExpand);
                                        }}
                                        style={{ position: "absolute", top: 0, left: "30%" }}
                                        size="large"
                                    >
                                        {summaryExpand ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
                                    </IconButton>
                                </Box>

                                <Typography
                                    variant="body2"
                                    style={{
                                        display: `${summaryExpand ? "none" : "block"}`,
                                    }}
                                >
                                    {summaryReview?.summary}
                                </Typography>
                            </Box>
                        ) : (
                            <Box mb={2}>
                                <Typography variant="h6" gutterBottom>
                                    Give your summary of this week
                                </Typography>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder="What went well this week and what could I have done better..."
                                    variant="outlined"
                                    fullWidth
                                    value={summaryNew}
                                    onChange={(e: any) => setSummaryNew(e.target.value)}
                                    required
                                />
                            </Box>
                        )}
                        {summaryReview?.response ? (
                            <Box mb={2}>
                                <Box display="flex" alignItems="center" style={{ position: "relative" }}>
                                    {" "}
                                    <Typography variant="h6" gutterBottom>
                                        Response
                                    </Typography>
                                    <IconButton
                                        onClick={() => setResponseExpand(!responseExpand)}
                                        style={{ position: "absolute", top: 0, left: "30%" }}
                                        size="large"
                                    >
                                        {responseExpand ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
                                    </IconButton>
                                </Box>

                                <Typography variant="body2" style={{ display: `${responseExpand ? "none" : "block"}` }}>
                                    {summaryReview?.response}
                                </Typography>
                            </Box>
                        ) : (
                            currentUser?.profile?.permissions?.some(
                                (permissions: any) => permissions.slug === "team-manage",
                            ) && (
                                <>
                                    <Box mb={2}>
                                        <Typography variant="h6" gutterBottom>
                                            Give your response for this summary
                                        </Typography>
                                        <TextField
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={4}
                                            placeholder="What went well this week and what could I have done better..."
                                            variant="outlined"
                                            fullWidth
                                            value={summaryResponse}
                                            onChange={(e) => {
                                                setSummaryResponse(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </>
                            )
                        )}

                        {!summaryReview.summary ||
                        (!summaryReview.response &&
                            currentUser?.profile?.permissions?.some(
                                (permissions: any) => permissions.slug === "team-manage",
                            )) ? (
                            <Button
                                variant="contained"
                                fullWidth
                                style={{ background: "#C3D830" }}
                                onClick={handleSaveSummary}
                            >
                                <Typography variant="button" color="textPrimary">
                                    {summaryLoading ? <CircularProgress /> : "Submit"}
                                </Typography>
                            </Button>
                        ) : null}
                    </Box>

                    <div style={{ display: "flex", position: "relative", top: -170 }}>
                        <img
                            src="/images/Group 602.svg"
                            style={{ width: "20em" }}
                            className={`${classes.image} ${isVisible ? classes.visible : ""}`}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default ReviewFeedback;
