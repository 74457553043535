import React from "react";

const RaisedExcitedNatural = () => {
    return (
        <g id="Eyebrow/Natural/Raised-Excited-Natural" fillOpacity="0.599999964">
            <path
                d="M22.7663531,1.57844898 L23.6772984,1.17582144 C28.9190996,-0.905265751 36.8645466,-0.0328729562 41.7227321,2.29911638 C42.2897848,2.57148957 41.9021563,3.4519421 41.3211012,3.40711006 C26.4021788,2.25602197 16.3582869,11.5525942 12.9460869,17.8470939 C12.8449215,18.0337142 12.5391523,18.05489 12.4635344,17.8808353 C10.156283,12.5620676 16.9134476,3.89614725 22.7663531,1.57844898 Z"
                id="Eye-Browse-Reddit"
            />
            <path
                d="M80.7663531,1.57844898 L81.6772984,1.17582144 C86.9190996,-0.905265751 94.8645466,-0.0328729562 99.7227321,2.29911638 C100.289785,2.57148957 99.9021563,3.4519421 99.3211012,3.40711006 C84.4021788,2.25602197 74.3582869,11.5525942 70.9460869,17.8470939 C70.8449215,18.0337142 70.5391523,18.05489 70.4635344,17.8808353 C68.156283,12.5620676 74.9134476,3.89614725 80.7663531,1.57844898 Z"
                id="Eye-Browse-Reddit"
                transform="translate(85.000000, 9.000000) scale(-1, 1) translate(-85.000000, -9.000000) "
            />
        </g>
    );
};

export default RaisedExcitedNatural;
