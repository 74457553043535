import React from "react";
import { usePermissions } from "../../../context/user/permissions.ctx";
import LeaderProduction from "../../../sections/Production/sections/LeaderProduction/LeaderProduction";
import UserProduction from "../../../sections/Production/sections/UserProduction/UserProduction";

const Production: React.FC = () => {
	const permissions = usePermissions();

	return permissions.team_manage ? <LeaderProduction /> : <UserProduction />;
};

export default Production;
