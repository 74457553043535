import { Column, Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import { t_SurveyPeriod, t_SurveyPeriodArr } from "../../../Surveys/Surveys.types";
import { SurveyReports } from "../../PerceptionReport";

type TeamMember = {
    DisplayName: string;
    EntityNo: number;
    DiscoveryTeamID: number;
    LineManager: string;
    LineManagerEntityNo: string;
    ServiceTeam: string;
    Skill: string;
    Stream: string;
};

type WithSurvey = {
    SurveyType: string;
    AvgScore: number;
    Appeals: number;
    LSA: number;
};

type WithAppeals = {
    AppealTotal: number;
    AppealPending: number;
    AppealApproved: number;
    AppealDeclined: number;
};

type WithLSA = {
    SurveyType: string;
    LsaTotal: number;
    LsaPartial: number;
    LsaCompleted: number;
};

/** @MM Width auto is implemented to fix bug with material-table re-render; */
const TeamMemberBase: Column<any>[] = [
    { title: "Line Manager", field: "LineManager" },
    {
        title: "Line Manager Entity No",
        field: "manager.EntityNo",
        width: "auto",
    },
    { title: "Service Team", field: "ServiceTeam" },
    { title: "Skill", field: "Skill" },
    { title: "Stream", field: "Stream" },
];
const TeamLeaderColumns: Column<any>[] = [
    { title: "Team Leader", field: "DisplayName", defaultSort: "asc" },
    { title: "Team Leader Entity", field: "EntityNo" },
    ...TeamMemberBase,
];
const AgentColumns: Column<any>[] = [
    { title: "Agent Name", field: "DisplayName" },
    { title: "Agent Entity", field: "EntityNo" },
    ...TeamMemberBase,
];

const WithSurveyColumns = (base: Column<any>[]): Column<TeamMember & WithSurvey>[] => [
    ...base,
    {
        title: "Avg Score",
        field: "AvgScore",
        render: (rowData) => {
            if (rowData != null && rowData.SurveyType != null && rowData.AvgScore != null) {
                if (rowData.SurveyType == "fcr") {
                    return Math.round(Number(rowData.AvgScore) * 100) + "%";
                }
                return Number(rowData.AvgScore).toFixed(2);
            }
        },
    },
    { title: "Appeals", field: "Appeals" },
    { title: "LSA", field: "LSA" },
];

const WithAppealsColumns = (base: Column<any>[]): Column<TeamMember & WithAppeals>[] => [
    ...base,
    { title: "Appeals", field: "AppealTotal" },
    { title: "Pending", field: "AppealPending" },
    { title: "Approved", field: "AppealApproved" },
    { title: "Declined", field: "AppealDeclined" },
];

const WithLsaColumns = (base: Column<any>[]): Column<TeamMember & WithLSA>[] => [
    { title: "Survey", field: "SurveyType" },
    ...base,
    { title: "LSA", field: "LsaTotal" },
    { title: "Partially", field: "LsaPartial" },
    { title: "Completed", field: "LsaCompleted" },
];

// template for consolidation
const getTeamLeaderSummariesAny = (payload: {
    query: Query<any>;
    periods: {
        [key: string]: t_SurveyPeriod | t_SurveyPeriodArr;
    };
    report: SurveyReports;
    isLsa?: boolean;
    survey_type_only?: "mbr" | "fcr";
}) =>
    new Promise<QueryResult<any>>((res, rej) => {
        const isEmptyPeriods = Object.values(payload.periods).every((period) => {
            if (Array.isArray(period)) {
                return period.length === 0;
            }
            return false;
        });

        if (isEmptyPeriods) {
            // If periods is empty or all periods are empty arrays, return an empty result
            res({ data: [], page: 0, totalCount: 0 });
            return;
        }

        const body = {
            for_report: payload.report,
            periods: payload.periods,
            ...convertQuery(payload.query),
            filter:
                payload.report === "lsa"
                    ? [
                          {
                              column_name: "SurveyType",
                              search_term: payload.survey_type_only,
                          },
                      ]
                    : null,
        };

        const url = payload.isLsa
            ? "/reports/survey/team_leaders_lsa"
            : `/reports/survey/team_leaders${Array.isArray(payload.periods[payload.report]) ? "/multi" : ""}`;

        if (url == "/reports/survey/team_leaders") {
            return;
        }
        httpClient.post(url, body).then(({ data }) => {
            res({
                data: data.data.team_leaders.data,
                page: data.pagination.page - 1,
                totalCount: data.total_results,
            });
        });
    });

const getTeamMemberSummariesAny = (payload: {
    query: Query<any>;
    periods: {
        [key: string]: t_SurveyPeriod | t_SurveyPeriodArr;
    };
    report: SurveyReports;
    teamLeader: TeamMember;
    survey_type_only?: string | null;
    isLsa?: boolean;
}) => {
    const isMultiPeriod = Array.isArray(payload.periods[payload.report]);

    return new Promise<any>((resolve, reject) => {
        const body = {
            for_report: payload.report,
            periods: payload.periods,
            ...convertQuery(payload.query),
            summary_for: "user",
            manager_entity: payload.teamLeader.EntityNo,
            survey_type_only: payload.survey_type_only,
        };

        const url = payload.isLsa
            ? "/reports/survey/team_leaders_lsa"
            : `/reports/survey/team_leaders${isMultiPeriod ? "/multi" : ""}`;

        httpClient.post(url, body).then(({ data }) => {
            resolve({
                data: data.data.team_leaders.data,
                page: data.pagination.page - 1,
                totalCount: data.total_results,
            });
        });
    });
};

export type { TeamMember, WithSurvey, WithAppeals, WithLSA };
export {
    getTeamLeaderSummariesAny,
    getTeamMemberSummariesAny,
    TeamLeaderColumns,
    AgentColumns,
    WithSurveyColumns,
    WithAppealsColumns,
    WithLsaColumns,
};
