import { Typography } from "@mui/material";
import React from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_CatDownResponse } from "../MultiDateGraphs/ProductionReportGraph.types";
import { getProductionGraphData } from "../MultiDateGraphs/ProductionReportGraphMulti.api";
import { t_AverageCategoryDowntime } from "./AverageCategoryDowntime.types";

interface Props {
  dates: string[];
}

const AverageCategoryDowntime: React.FC<Props> = ({ dates }) => {
  const [chartColumns, setChartColumns] = React.useState<string[]>();
  const [chartData, setChartData] = React.useState<t_AverageCategoryDowntime[]>(
    []
  );

  React.useEffect(() => {
    let isActive = true;
    if (dates) {
      getProductionGraphData<t_CatDownResponse>(
        { dates },
        "category_downt"
      ).then((response) => {
        if (isActive) {
          setChartColumns(response.categories);
          setChartData(response.data);
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [dates?.length]);

  return (
    <SectionCard
      title="Average Downtime per Category"
      titleProps={{ variant: "h6" }}
      secondaryAction={
        <Typography variant="overline">
          {dates.length ? dates.length + " Months" : "Select Date"}
        </Typography>
      }
      hasBackground
    >
      <ResponsiveContainer height={400}>
        <ComposedChart data={chartData}>
          <XAxis dataKey="service_team" />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid />
          {chartColumns?.map((column) => (
            <Bar dataKey={column} fill="#8884d8" />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </SectionCard>
  );
};

export default AverageCategoryDowntime;
