import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    TextField,
    Typography,
} from "@mui/material";
import { ChatBubble, ChatBubbleOutline, ExpandLess, Send } from "@mui/icons-material";
import React, { ReactNode } from "react";
import useScrollEvent from "../../hooks/lib/useScrollEvent";
import { getImageUrl } from "../../misc/helpers";
import {
    getNewsFeedItemComments,
    postNewsFeedComment,
    postNewsFeedCommentInteraction,
} from "../../sections/NewsFeed/newsFeed.api";
import { LIKE_INTERACTION, t_ActivityComment, t_ActivityData } from "../../sections/NewsFeed/NewsFeed.types";
import { MiscBody } from "../../types/api.types";
import liveCommentsStyles from "./LiveComments.styles";

export interface Comment {
    id: number;
    content: string;
    author: string;
    avatar: ReactNode;
    action?: ReactNode;
}

interface LiveCommentsProps {
    activity_id: t_ActivityData["activity_id"];
    comment_count?: t_ActivityData["comment_count"];
    featured_comment?: t_ActivityData["featured_comment"];
    // comments?: Comment[];
    // commentWrapperRef?: React.RefObject<HTMLElement>;
    // inputStyle?: any;
    // onComment?: (comment: string) => any;
    // loadRecent: () => void;
    // loadHistoric: () => void;
}

enum OPEN_STATE {
    COMMENT = 1,
    ALL = 2,
}
const CommentElement: React.FC<{
    comment: t_ActivityComment;
    dispatch: (reason: string) => void;
}> = ({ comment, dispatch }) => {
    return (
        <Typography variant="caption">
            <Avatar
                src={getImageUrl("profile", comment.profile_img)}
                style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-flex",
                    verticalAlign: "bottom",
                    marginRight: "5px",
                }}
            />
            <b>{comment.name}: </b>
            {comment.comment}
        </Typography>
    );
};
const LiveComments: React.FC<LiveCommentsProps> = (
    // {
    // comments,
    // onComment,
    // inputStyle,
    // commentWrapperRef,
    // loadRecent,
    // loadHistoric,
    // }
    props,
) => {
    const classes = liveCommentsStyles();

    const containerRef = React.createRef<HTMLDivElement>();

    const [open, setOpen] = React.useState<OPEN_STATE | 0>(0);
    const commentInput = React.createRef<HTMLInputElement>();
    const [comments, setComments] = React.useState<t_ActivityComment[]>();
    const [featuredComment, setFeaturedComment] = React.useState<t_ActivityComment>();
    const [commentCount, setCommentCount] = React.useState<number>(props.comment_count ?? 0);
    const [loading, setLoading] = React.useState<boolean>(false);

    const base_rowsPerPage = 5;
    const base_pagination: MiscBody["pagination"] = {
        page: 1,
        rows_per_page: base_rowsPerPage,
        count: Math.ceil((props.comment_count || 1) / base_rowsPerPage),
    };
    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>();

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && Boolean(commentInput.current?.value)) {
            handleCommentSubmit(commentInput.current?.value);
        }
    };
    const handleCommentSend = () => {
        handleCommentSubmit(commentInput.current?.value);
    };

    const handleLoadHistoric = () => {
        if ((pagination?.rows_per_page ?? 0) < commentCount) {
            setPagination((state) => {
                if (!state) {
                    return state;
                }
                return {
                    ...state,
                    rows_per_page: state.rows_per_page + base_rowsPerPage,
                };
            });
        }
    };
    const handleLoadRecent = () => {
        setPagination(base_pagination);
    };

    const handleCommentSubmit = (comment?: string) => {
        if (comment && comment !== "") {
            postNewsFeedComment({
                newsfeed_item_id: props.activity_id,
                comment,
            }).then((response) => {
                if (response.status === "success") {
                    if (commentInput.current) {
                        commentInput.current.value = "";
                    }
                    setPagination(
                        (state) =>
                            state && {
                                ...state,
                                rows_per_page: state.rows_per_page + 1,
                            },
                    );
                }
            });
        }
    };
    const handleCommentInteractionClick = (commentId: number) => {
        postNewsFeedCommentInteraction({
            newsfeed_comment_id: commentId,
            interaction_type_id: LIKE_INTERACTION,
        }).then((response) => {
            if (response.status === "success") {
                setComments((state) => {
                    if (!state) return state;

                    const i = state.findIndex((item) => item.id === commentId);
                    if (i < 0 || !state[i]) return state;

                    const liked = state[i].liked;
                    state[i].liked = !liked;
                    state[i].like_count += liked ? -1 : 1;
                    return [...state];
                });
            }
        });
    };

    const handleOpenToggle = (openState: OPEN_STATE | 0) => {
        setOpen((state) => {
            if (openState === OPEN_STATE.ALL) {
                if (state > 0) {
                    setComments(undefined);
                    setPagination(undefined);
                    return 0;
                } else {
                    setPagination(base_pagination);
                }
            }
            return openState;
        });
    };

    useScrollEvent({
        targetRef: containerRef,
        onScrollToBottom: handleLoadHistoric,
    });

    React.useEffect(() => {
        var isActive = true;
        if (pagination != null) {
            setLoading(true);
            getNewsFeedItemComments({
                newsfeed_item_id: props.activity_id,
                pagination,
                sort: {
                    column_name: "created_at",
                    order: "DESC",
                },
            })
                .then((response) => {
                    if (isActive) {
                        setComments(response.data.comments ?? []);
                        if (response.total_results) {
                            setPagination(pagination);
                            setCommentCount(response.total_results);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }

        return () => {
            isActive = false;
        };
    }, [pagination?.rows_per_page]);

    React.useEffect(() => {
        props.featured_comment != null ? setFeaturedComment(props.featured_comment) : console.log("undefined");
    }, [props.featured_comment]);

    return (
        <Grid container spacing={0} direction="column">
            <List>
                <ListItem>
                    <Box
                        display="flex"
                        // justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        {!open ? (
                            featuredComment && <CommentElement comment={featuredComment} dispatch={() => {}} />
                        ) : (
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Leave a comment..."
                                onKeyPress={handleKeyPress}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleCommentSend} size="large">
                                                <Send />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputRef={commentInput}
                            />
                        )}
                        <Box marginLeft={"auto"}>
                            {!open && (
                                <Badge badgeContent={props.comment_count ?? 0}>
                                    <IconButton onClick={() => handleOpenToggle(OPEN_STATE.ALL)} size="large">
                                        {props.comment_count ? <ChatBubble /> : <ChatBubbleOutline />}
                                    </IconButton>
                                </Badge>
                            )}
                        </Box>
                    </Box>
                </ListItem>
            </List>
            <Collapse in={open > 0}>
                <div className={classes.commentsContainer} ref={containerRef}>
                    <List>
                        {comments?.map((comment) => (
                            <ListItem>
                                <CommentElement comment={comment} dispatch={() => {}} />
                            </ListItem>
                        ))}

                        {loading && (
                            <ListItem className={classes.loader}>
                                <CircularProgress size="20px" />
                            </ListItem>
                        )}
                    </List>
                </div>
                <ListItem>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Box marginLeft={"auto"}>
                            <IconButton onClick={() => handleOpenToggle(0)} size="large">
                                <ExpandLess />
                            </IconButton>
                        </Box>
                    </Box>
                </ListItem>
            </Collapse>
        </Grid>
    );
};

export default LiveComments;
