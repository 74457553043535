import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const appealRecordStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			width: "-webkit-fill-available",
		},
		listItem: {
			gap: 8,
		},
	})
);

export default appealRecordStyles;
