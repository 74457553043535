import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const profileBadgesStyles = makeStyles((theme: Theme) =>
	createStyles({
		action: {
			color: theme.palette.info.main,
			"& > *": { margin: theme.spacing(0) },
		},
		badgeAvatar: {
			width: 80,
			height: 80,
		},
		fullWidthFlex: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			marginTop: theme.spacing(2),
		},
	})
);

export default profileBadgesStyles;
