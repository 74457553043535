import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const productionTableStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: "100%",
		},
		weekendRow: {
			backgroundColor: theme.palette.grey[100],
		},
		fadedCell: {
			color: theme.palette.text.disabled,
		},
		italicCell: {
			fontStyle: "italic",
		},
		boldCell: {
			fontWeight: "bold", //theme.typography.fontWeightBold,
		},
		borderedCell: {
			borderLeft: `solid 1px ${theme.palette.divider}`,
			borderRight: `solid 1px ${theme.palette.divider}`,
		},
		noBorderCell: {
			border: "none",
		},
		cellButton: {
			textTransform: "none",
			padding: theme.spacing(0, 0.5),
		},
		feedbackContainer: {
			width: "100%",
			height: "100%",
			display: "grid",
			placeItems: "center",
		},
	})
);

export default productionTableStyles;
