import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";

type t_InitiativeOption = {
	id?: number;
	weight?: number;
	comment?: string;
	active?: boolean;
	name: string;
	handling_time: number;
	all_incentive_categories: boolean;
	incentive_categories?: t_IncentiveType[];
};

const base_InitiativeOption: t_InitiativeOption = {
	name: "",
	handling_time: 0,
	all_incentive_categories: false,
	incentive_categories: [],
};

export { base_InitiativeOption };
export type { t_InitiativeOption };
