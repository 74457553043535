import { Card, CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import LiveComments from "../../../../components/LiveComments/LiveComments";
import { t_ActivityData } from "../../NewsFeed.types";

interface Props {
    activity: t_ActivityData;
    avatar?: React.ReactNode;
    title?: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode;
}

const ActivityCard: React.FC<Props> = ({ avatar, activity, title, actions, children }) => {
    const theme = useTheme();
    const [timestamp, setTimestamp] = useState<string>("");

    const handleCommentInteractionClick = (commentId: any) => {
        return;
    };

    useEffect(() => {
        setTimestamp(
            (() => {
                if (!activity?.date) return "";

                const current = new Date(Date.now());
                const activity_date = new Date(activity?.date);
                let time = 0;

                if ((time = current.getFullYear() - activity_date.getFullYear()) > 0) return `${time} years ago`;
                if ((time = current.getMonth() - activity_date.getMonth()) > 0) return `${time} months ago`;
                if ((time = current.getDate() - activity_date.getDate()) > 0) return `${time} days ago`;
                if ((time = current.getMinutes() - activity_date.getMinutes()) > 0) return `${time} minutes ago`;
                if ((time = current.getSeconds() - activity_date.getSeconds()) > 0) return `1 minute ago`;
                return "Just now";
            })(),
        );
    }, [activity?.date]);

    return (
        <Card>
            <CardHeader avatar={avatar} action={actions} title={title} subheader={timestamp} />
            {children != null && <CardContent>{children}</CardContent>}
            <Divider />
            <LiveComments {...activity} />
        </Card>
    );
};

export default ActivityCard;
