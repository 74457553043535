import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

const SubmittedMetricReviewTable = ({ data }: any) => {
    const { dpmo_metric, fcr_metric, mbr_metric, production_metric } = data;
    const columns = [
        {
            id: "metric",
            label: "Metric Reviewed",
            type: "name",
        },
        {
            id: "target",
            label: "Target",
            type: "input",
        },
        {
            id: "achieved",
            label: "Achieved",
            type: "input",
        },
        {
            id: "options_followed",
            label: "Option Followed",
            type: "emojiList",
        },
    ];

    const createData = (metric: any, target: any, achieved: any, options_followed: any) => {
        return { metric, target, achieved, options_followed };
    };

    const [rows, setRows] = useState([
        createData("MBR", "", "", ""),
        createData("FCR", "", "", ""),
        createData("DPMO", "", "", ""),
        createData("Production", "", "", ""),
    ]);

    useEffect(() => {
        setRows([
            createData("MBR", mbr_metric?.target, mbr_metric?.achieved, mbr_metric?.options_followed),
            createData("FCR", fcr_metric?.target, fcr_metric?.achieved, fcr_metric?.options_followed),
            createData("DPMO", dpmo_metric?.target, dpmo_metric?.achieved, dpmo_metric?.options_followed),
            createData(
                "Production",
                production_metric?.target,
                production_metric?.achieved,
                production_metric?.options_followed,
            ),
        ]);
    }, [data]);

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} sx={{ fontWeight: "bold" }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, rowIndex) => {
                        const rowValues = Object.values(row).filter((value) => value != row.metric);
                        const isEmptyRow = rowValues.every((value) => value == null || value == "");

                        if (isEmptyRow) return null;
                        return (
                            <TableRow>
                                {columns.map((column) => {
                                    //@ts-ignore
                                    const value = row[column.id];

                                    return (
                                        <TableCell
                                            key={column.id}
                                            sx={{
                                                backgroundColor:
                                                    value == "😀" || value == "👍" || value == "✔"
                                                        ? "#D4EFDF"
                                                        : value == "😐"
                                                        ? "#FCF3CF"
                                                        : value == "🙁" || value == "👎" || value == "❌"
                                                        ? "#F5B7B1 "
                                                        : "",
                                            }}
                                        >
                                            {column.type == "date"
                                                ? moment(value, true).isValid()
                                                    ? moment(value).format("DD-MM-YYYY")
                                                    : ""
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubmittedMetricReviewTable;
