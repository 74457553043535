import { httpClient } from "../../../../clients/http.client";

const getProfileBasics = async (payload: { entity_no: number }) => {
	const response = await httpClient.post("/profile/bio", payload);
	return response.data.data.profile;
};

const updateProfileBio = async (payload: { bio: string }) => {
	const response = await httpClient.post("/profile/bio/update", payload);
	return response.data;
};

export { getProfileBasics, updateProfileBio };
