import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import { useUserState } from "../../context/user/user.ctx";

type Message = {
    messageId?: number;
    userId?: number;
    name?: string;
    message?: string;
    activeChat?: number;
    timeStamp: Date;
    participants: any;
};
const Message = (props: Message) => {
    const { participants, timeStamp, activeChat, message, messageId, name, userId } = props;
    const classes = messageStyles();
    const currentUser = useUserState();
    const entity_no = currentUser.entity_no;
    const messageSent = moment(timeStamp).format("H:mm");

    const senderName = participants.filter((pt: any) => pt.EntityNo == props.userId);
    const nameColor = senderName[0]?.EntityNo.match(/.{1,3}/g);
    const thisColor =
        nameColor &&
        `rgb(${nameColor[0] > 255 ? nameColor[0] - 255 : nameColor[0]},${
            nameColor[1] > 255 ? nameColor[1] - 255 : nameColor[1]
        },${nameColor[2] > 255 ? nameColor[2] - 255 : nameColor[2]})`;

    return (
        <Box
            display="flex"
            style={{ marginLeft: activeChat == userId ? "auto" : "unset" }}
            alignItems={entity_no == userId ? "flex-start" : "flex-end"}
            className={classes.messagesWrapper}
        >
            <Box className={classes.timeStampReceipent}>
                <Box className={classes.timeStampDetails}>
                    <Typography variant="body2" style={{ fontWeight: "lighter", color: thisColor }}>
                        {entity_no == userId ? "You" : senderName[0]?.DisplayName}
                    </Typography>

                    <Typography variant="body2" style={{ fontWeight: "lighter", marginLeft: 15 }}>
                        {messageSent}
                    </Typography>
                </Box>
                <Box
                    className={`${
                        entity_no == userId ? classes.chatBubbleCurrentUser : classes.chatBubbleRecipentUser
                    }`}
                >
                    {message}
                </Box>
            </Box>
        </Box>
    );
};
const messageStyles = makeStyles((theme: any) => ({
    chatBubbleCurrentUser: {
        position: "relative",
        background: "#c6db34",
        width: "100%",
        maxWidth: "100%",
        padding: 15,
        height: "fit-content",
        borderRadius: 23,
        borderBottomLeftRadius: 0,
    },
    chatBubbleRecipentUser: {
        position: "relative",
        background: "#fff",
        width: "100%",
        maxWidth: "100%",
        padding: 15,
        height: "fit-content",
        borderRadius: 23,
        borderBottomRightRadius: 0,
        marginBottom: 30,
        boxShadow: "0px 10px 15px 7px rgba(0,0,0,0.1)",
    },
    messagesWrapper: {
        width: "90%",
        flexFlow: "column",
    },
    message: {
        display: "flex",
        alignItem: "center",
        justifyContent: "space-between",
    },
    timeStampReceipent: {
        display: "flex",
        alignItem: "center",
        justifyContent: "space-between",
        width: "fit-content",
        // margin: "0 auto",
        borderTopLeftRadius: 23,
        borderTopRightRadius: 23,
        padding: 5,
        flexFlow: "column",
    },
    timeStampDetails: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));
export default Message;
