import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import {
	c_SurveyPeriod,
	t_SurveyPeriod,
	t_SurveyPeriodTypes,
} from "./SurveyPeriods.types";

const getSurveyPeriods = (payload: {
	query: Query<t_SurveyPeriod>;
	year?: string;
}) => {
	return new Promise<QueryResult<t_SurveyPeriod>>((resolve, reject) => {
		let query = convertQuery(payload.query);

		if (query.sort != null && query.sort.column_name in c_SurveyPeriod) {
			query.sort.column_name = c_SurveyPeriod[query.sort.column_name];
		}

		var body = {
			...query,
			year: payload.year,
		};
		httpClient
			.post("/admin/periods", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve({
							data: data.data.periods,
							page: data.pagination.page - 1,
							totalCount: data.total_results,
						});
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const getSurveyPeriodTypes = () => {
	return new Promise<t_SurveyPeriodTypes[]>((resolve, reject) => {
		httpClient
			.post("/admin/periods/types")
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(data.data.types);
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const saveSurveyPeriods = (payload: { period: t_SurveyPeriod }) => {
	return new Promise((resolve, reject) => {
		const body = {
			...payload.period,
			survey_type_id: payload.period.survey_type?.id,
		};
		httpClient
			.post("/admin/periods/save", body)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const deleteSurveyPeriods = (payload: { period_id: t_SurveyPeriod["id"] }) => {
	return new Promise((resolve, reject) => {
		httpClient
			.post("/admin/periods/delete", payload)
			.then(({ data }) => {
				switch (data.status) {
					case "success":
						resolve(true);
						break;
					default:
						reject(data.message);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export {
	getSurveyPeriods,
	getSurveyPeriodTypes,
	saveSurveyPeriods,
	deleteSurveyPeriods
};
