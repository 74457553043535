import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

interface DropdownSelectMultiProps {
    name: string;
    selectedArray: number[];
    selectHandler: (newSelectedArray: string[]) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        selector: {
            width: "150px",
        },
    }),
);

const DropdownSelectMulti = ({ name, selectedArray, selectHandler }: DropdownSelectMultiProps) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.selector}>
            <InputLabel id={`${name}-selector-multi`}>{name[0].toUpperCase() + name.slice(1)}</InputLabel>
            <Select
                labelId={`${name}-selector-multi`}
                id={`${name}-selector`}
                multiple
                value={selectedArray}
                onChange={(e: any) => selectHandler(e.target.value as string[])}
                input={<Input />}
                renderValue={(selected: any) => {
                    const selections = selected as number[];
                    const monthIdx = selections.length ? selections[selections.length - 1] : 0;
                    return Months[monthIdx];
                }}
                // MenuProps={MenuProps}
            >
                {Months.map((monthName, index) => (
                    <MenuItem key={monthName} value={index}>
                        <Checkbox checked={selectedArray.includes(index)} />
                        <ListItemText primary={monthName} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DropdownSelectMulti;
