import { Grid, MenuItem } from "@mui/material";
import React from "react";
import DropdownSelect from "../../../../components/DropdownSelect/DropdownSelect";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_User } from "../../../../types/user.types";
import MetricProgress from "../../../Metric/sections/GameMetricProgress/GameMetricProgress";
import { getUserMetricProgress } from "../../../Metric/sections/GameMetricProgress/GameMetricProgress.api";
import UserMetricTrends from "../../../Performance/components/UserMetricTrends/UserMetricTrends";
import profileStatsStyles from "./ProfileStats.styles";

interface Props {
	entity_no?: number;
}
const ProfileStats: React.FC<Props> = ({ entity_no }) => {
	const classes = profileStatsStyles();
	const [viewMetric, setViewMetric] = React.useState<t_User.Metric>();
	const [metricValues, setMetricValues] = React.useState<t_User.Metric[]>();
	const [metricFetch, setMetricFetch] = React.useState<string>();

	const now = new Date(Date.now());

	React.useEffect(() => {
		let isActive = true;

		if (entity_no != null) {
			var month = now;
			month.setDate(1);
			getUserMetricProgress({
				entity_no,
				date: month.toDateString(),
			}).then((metric_values) => {
				if (isActive) {
					if (metric_values != null && metric_values.length > 0) {
						setMetricValues(metric_values);
						setViewMetric(metric_values[0]);
						setMetricFetch(new Date(Date.now()).toString());
					}
				}
			});
		}
		return () => {
			isActive = false;
		};
	}, [entity_no]);

	return (
		<SectionCard
			title='Performance Summary'
			titleProps={{ variant: "h6" }}
			secondaryAction={
				<DropdownSelect
					key={`select-for-fetch-${metricFetch}`}
					value={viewMetric?.id}
				>
					{metricValues?.map((metric) => (
						<MenuItem
							value={metric.id}
							onClick={() => setViewMetric(metric)}
						>
							{metric.name}
						</MenuItem>
					))}
				</DropdownSelect>
			}
			fullWidth
			hasBackground
			scrolling
		>
			<Grid container spacing={2} className={classes.container}>
				<Grid item sm={12}>
					<UserMetricTrends
						metric_id={viewMetric?.id}
						entity_no={entity_no}
						month={new Date().toDateString()}
					/>
				</Grid>
				<Grid item sm={12} className={classes.progressListContainer}>
					<MetricProgress
						metric_values={metricValues}
						metric_fetch={metricFetch}
					/>
				</Grid>
			</Grid>
		</SectionCard>
	);
};
export default ProfileStats;
