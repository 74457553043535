type t_DowntimeType = {
	id: number;
	name: string;
};

type t_ProductionDowntime = {
	id?: number;
	downtime_type_id?: number;
	minutes: number;
	comment: string;
};

type t_ProductionDowntimeRecord = t_ProductionDowntime & {
	downtime_type_name: string;
	verified: boolean;
};

const base_ProductionDowntime: t_ProductionDowntime = {
	id: undefined,
	downtime_type_id: undefined,
	minutes: 0,
	comment: "",
};

export { base_ProductionDowntime };
export type {
	t_DowntimeType,
	t_ProductionDowntime,
	t_ProductionDowntimeRecord,
};
