import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { Box, Button, Typography } from "@mui/material";
import { Icons, toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { AppealProps } from "./NotificationTypes";

const Appeal = ({ appeal, removeNotification }: AppealProps) => {
    return (
        <Box key={appeal?.id}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {Icons.info({
                        theme: "light",
                        type: toast.TYPE.INFO,
                        width: 30,
                        height: 30,
                    })}
                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        Appeal
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {/* {appeal?.message} */}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={Number(appeal?.date)} />
                            </Typography>
                        </Typography>
                        {/* <ItemActions notification={appeal} markAsRead={markAsRead} remove={remove} /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Appeal;
