import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGetUserProfileMutation } from "../../../../redux/api/beYouCore";
import { RootState } from "../../../../redux/store";

const useProfileChange = () => {
    const [getUserProfile] = useGetUserProfileMutation();
    const viewTeamMember = useSelector((state: RootState) => state.view.viewUserInfo);
    const user = useSelector((state: RootState) => state.user.userInfo);
    const [currentUser, setCurrentUser] = useState(user);
    const currentEntityNo = viewTeamMember.user ? viewTeamMember.user.entity_no : user.entity_no;

    useEffect(() => {
        getUserProfile({ entity_no: currentEntityNo })
            .unwrap()
            .then((response: any) => {
                setCurrentUser(response.data.profile);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [currentEntityNo]);
    return { currentUser, user };
};

export default useProfileChange;
