import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import styles from "./styles.module.css";

interface DeleteTeamPoolProps {
  onClose: () => void;
  deleteAction: (poolNumber: number, entityNo?: number) => void;
  submitState: "not submitted" | "pending" | "success" | "error";
  data: any;
}

const DeleteTeamPool = ({
  onClose,
  deleteAction,
  submitState,
  data,
}: DeleteTeamPoolProps) => {
  if (submitState === "success") onClose();

  return (
    <SectionCard
      title={`Delete group`}
      titleProps={{
        variant: "h6",
      }}
      className={styles.deleteGroup}
      secondaryAction={
        <IconButton onClick={onClose} size="large">
          <Close />
        </IconButton>
      }
    >
      <Box width="clamp(30em, 50%, 40em)" margin="0 auto 1em">
        <Typography variant="h6" align="center">
          Deleting group:
        </Typography>
        <Typography variant="h6" align="center">
          {data.Name}
        </Typography>

        <Box width="100%" textAlign="center" margin="2em 0.5em 0">
          <SubmitButton
            variant="outlined"
            onClick={() =>
              data.hasOwnProperty("EntityNo")
                ? deleteAction(data.PoolID, data.EntityNo)
                : deleteAction(data.PoolID)
            }
            loading={submitState === "pending"}
            disabled={submitState === "success" || submitState === "pending"}
          >
            {submitState === "not submitted" && "Delete Group"}
            {submitState === "success" && "Deleted"}
            {submitState === "pending" && "Deleting group"}
            {submitState === "error" && "Please try again"}
          </SubmitButton>
        </Box>
      </Box>
    </SectionCard>
  );
};

export default DeleteTeamPool;
