import Eyebrow, { EyebrowOption } from "./eyebrow/Eyebrow";
import Eyes, { EyesOption } from "./eyes/Eyes";
import Mouth, { MouthOption } from "./mouth/Mouth";
import Nose from "./nose/Nose";

export interface Props {
    mouthOption: MouthOption;
    eyesOption: EyesOption;
    eyebrowOption: EyebrowOption;
}

const Face = ({ mouthOption = "default", eyesOption = "default", eyebrowOption = "default" }: Props) => {
    return (
        <g id="Face" transform="translate(76.000000, 82.000000)" fill="#000000">
            <Mouth option={mouthOption} />
            <Nose />
            <Eyes option={eyesOption} />
            <Eyebrow option={eyebrowOption} />
        </g>
    );
};

export default Face;
