import React, { useState } from "react";
import CoachingFormUserDetails from "../CoachingFormUserDetails";

const CoachFormCard = ({ form, onCardClick }: any) => {
    const [isHover, setIsHover] = useState(false);
    return (
        <div
            style={{
                cursor: "pointer",
                border: isHover ? ".5px solid #30D843" : "",
                borderRadius: 10,
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={onCardClick}
        >
            <CoachingFormUserDetails name={form?.name} job_title={form?.job_title} subject={form?.subject} />
        </div>
    );
};

export default CoachFormCard;
