import { Card, CardContent, CardHeader, CircularProgress, List, ListItem, Skeleton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useState } from "react";

import Notifications from "../../components/Notifications/Notifications";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import { useErrorHandler } from "../../context/error/error.ctx";
import { useUserState } from "../../context/user/user.ctx";
import useScrollEvent from "../../hooks/lib/useScrollEvent";
import { MiscBody } from "../../types/api.types";
import NewsFeedItem from "./components/NewsfeedItem/NewsFeedItem";
import { getNewsFeedCategories, getNewsFeedData, postNewsFeedItemInteraction } from "./newsFeed.api";
import { LIKE_INTERACTION, t_ActivityData, t_NewsFeedActions } from "./NewsFeed.types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: "100%",
        },
        listItem: {
            display: "block",
        },
        table: {
            borderCollapse: "separate",
            borderSpacing: theme.spacing(0, 2),
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        activityItem: {
            borderBottom: "none",
        },
        loader: {
            display: "flex",
            justifyContent: "center",
        },
    }),
);

interface t_DialogOpen {
    appeal: [];
    friend_requests: [];
    lsa: [
        {
            lsa: {
                LsaID: number;
                StatusID: number;
                LsaDataMetricID: number;
                survey_record: {
                    EntityNo: number;
                    DataID: number;
                };
                user: {
                    DisplayName: string;
                };
            };
        },
    ];
}

const base_paginationDefualt: MiscBody["pagination"] = {
    page: 0,
    rows_per_page: 10,
};
const NewsFeed = () => {
    const classes = useStyles();
    const userAuth = useUserState();
    const errorHandler = useErrorHandler();
    const containerRef = React.createRef<HTMLDivElement>();

    const [tabList, setTabList] = useState<{ label: string; _id: any }[]>([]);
    const [tabValue, setTabValue] = useState<number>(-1);

    const [tableData, setTableData] = useState<t_ActivityData[]>([]);

    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>();
    const [totalItems, setTotalItems] = React.useState<number>();

    const [loading, setLoading] = useState<boolean>(false);

    const tabChange = (newValue: number) => {
        setTabValue(newValue);
    };

    const loadNewsfeedCategories = useCallback(
        () =>
            getNewsFeedCategories({})
                .then((data) => {
                    setTabList(data.reverse());
                    data?.length > 0 && setTabValue(0);
                })
                .catch((error) => errorHandler(error)),
        [errorHandler],
    );
    const dispatch = (action: t_NewsFeedActions) => {
        switch (action.type) {
            case "REFRESH_NEWSFEED":
                setTableData([]);
                setPagination((state) => (state != null ? { ...state } : state));
                break;
            case "ITEM_INTERACTION":
                if (action.item != null) {
                    postNewsFeedItemInteraction({
                        newsfeed_item_id: action.item.activity_id,
                        interaction_type_id: LIKE_INTERACTION,
                    }).then((response) => {
                        if (response.status === "success") {
                            setTableData((state) => {
                                const i = state.findIndex((item) => item.activity_id === action.item?.activity_id);
                                if (i < 0 || !state[i]) return state;

                                const liked = state[i].liked ?? false;
                                state[i].liked = !liked;
                                state[i].like_count = (state[i].like_count ?? 0) + (liked ? -1 : 1);

                                return [...state];
                            });
                        }
                    });
                }
        }
    };

    const handleLoadMore = () => {
        if ((totalItems ?? 0) > (pagination?.rows_per_page ?? 0)) {
            setPagination((state) =>
                state != null
                    ? {
                          page: state.page,
                          rows_per_page: state.rows_per_page + 10,
                      }
                    : state,
            );
        }
    };

    useScrollEvent({
        targetRef: containerRef,
        onScrollToBottom: handleLoadMore,
    });

    React.useEffect(() => {
        let isActive = true;
        if (loading && pagination != null && tabList[tabValue] != null) {
            getNewsFeedData({
                category_id: tabList[tabValue]._id,
                pagination,
            })
                .then((response) => {
                    if (isActive) {
                        setTableData(response.data?.newsfeed ?? []);
                        setTotalItems(response.total_results ?? 0);
                    }
                })
                .catch(errorHandler)
                .finally(() => setLoading(false));
        }
        return () => {
            isActive = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (pagination != null) {
            setLoading(true);
        }
    }, [pagination]);
    React.useEffect(() => {
        if (tabValue >= 0) {
            setTableData([]);
            setTotalItems(undefined); // just an added condition to force the loading skeletons (probably should be handled better but not enough time to rework the whole section)
            setPagination(base_paginationDefualt);
            setLoading(true);
        }
    }, [tabValue]);
    React.useEffect(() => {
        !userAuth.status.loading && loadNewsfeedCategories();
    }, [userAuth.status.loading, loadNewsfeedCategories]);

    return (
        <SectionCard
            title="Activity"
            scrolling
            primaryAction={<TabGroup tabValue={tabValue} tabList={tabList} tabChange={tabChange} />}
            innerRef={containerRef}
        >
            <Notifications />
            <List className={classes.list}>
                {totalItems == null ? (
                    [...Array(5)].map((e, i) => (
                        <ListItem key={`newsfeed-skeleton-${i}`} className={classes.listItem}>
                            <Card>
                                <CardHeader
                                    avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                                    title={
                                        <Skeleton
                                            animation="wave"
                                            height={10}
                                            width={80}
                                            style={{
                                                marginBottom: 6,
                                            }}
                                        />
                                    }
                                />
                                <CardContent>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        height={30}
                                        style={{
                                            marginBottom: 6,
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </ListItem>
                    ))
                ) : (
                    <>
                        {tableData.map((activity_record) => (
                            <ListItem key={`newsfeed-row-${activity_record.activity_id}`} className={classes.listItem}>
                                <NewsFeedItem activity={activity_record} dispatch={dispatch} />
                            </ListItem>
                        ))}
                        {loading && (
                            <ListItem className={classes.loader}>
                                <CircularProgress />
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </SectionCard>
    );
};

export default NewsFeed;
