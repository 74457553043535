import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    viewUserInfo: {},
};

const viewUserSlice = createSlice({
    name: "viewuser",
    initialState,
    reducers: {
        setViewUserInfo: (state, action) => {
            state.viewUserInfo = action.payload;
        },
    },
});

export const { setViewUserInfo } = viewUserSlice.actions;

export default viewUserSlice.reducer;
