import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { deletePoolGroup } from "../../twtGroups.api";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { t_GroupData } from "../../TwtGroup.types";
import styles from "./styles.module.css";

interface DeletePoolGroupProps {
  handleClose: () => void;
  group: t_GroupData;
}

const DeletePoolGroup = ({ handleClose, group }: DeletePoolGroupProps) => {
  const [submitState, setSubmitState] = React.useState<
    "not submitted" | "pending" | "success" | "error"
  >("not submitted");

  const onDelete = () => {
    setSubmitState("pending");
    deletePoolGroup({ id: group.id })
      .then(() => setSubmitState("success"))
      .catch((err) => {
        setSubmitState("error");
      });
  };

  return (
    <SectionCard
      title={`Delete group`}
      titleProps={{
        variant: "h6",
      }}
      className={styles.deleteGroup}
      secondaryAction={
        <IconButton onClick={handleClose} size="large">
          <Close />
        </IconButton>
      }
    >
      <Box width="clamp(30em, 50%, 40em)" margin="0 auto 1em">
        <Typography variant="h6" align="center">
          Deleting group:
        </Typography>
        <Typography variant="h6" align="center">
          {group.Name}
        </Typography>

        <Box width="100%" textAlign="center" margin="2em 0.5em 0">
          <SubmitButton
            variant="outlined"
            onClick={onDelete}
            loading={submitState === "pending"}
            disabled={submitState === "success" || submitState === "pending"}
          >
            {submitState === "not submitted" && "Delete Group"}
            {submitState === "success" && "Deleted"}
            {submitState === "pending" && "Deleting group"}
            {submitState === "error" && "Please try again"}
          </SubmitButton>
        </Box>
      </Box>
    </SectionCard>
  );
};

export default DeletePoolGroup;
