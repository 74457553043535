import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const tooltipStyles = makeStyles((theme: Theme) =>
	createStyles({
        tooltip: {
			backgroundColor: '#F0F0F0',
			color: '#5c5c5c',
			border: 1,
			borderColor: '#E6E8ED',
			borderStyle: 'solid',
			padding: 10,
			borderRadius: 10,
			boxShadow: "1px 3px 6px 0px rgba(0,0,0,0.4)",

		},
		tooltipText: {
			lineHeight: 1,
		},

		arrow: {
			"&:before": {
			  border: "1px solid #E6E8ED"
			},
			color: '#F0F0F0',
		  },
	})
);

export default tooltipStyles;
