import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CoachTip } from "../types";

type CoachTipState = {
    allTips: CoachTip[];
};

const initialState: CoachTipState = {
    allTips: [],
};

const coachTipsSlice = createSlice({
    name: "metrics",
    initialState,
    reducers: {
        setAllTips: (state, action) => {
            state.allTips = action.payload;
        },
    },
});

export const { setAllTips } = coachTipsSlice.actions;

export default coachTipsSlice.reducer;
