import { List, ListItem, Typography } from "@mui/material";
import { Pagination, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { MiscBody } from "../../../../types/api.types";
import { t_Challenge } from "../../Challenge.types";
import ChallengeCard from "../../components/ChallengeCard/ChallengeCard";
import { getGroupedChallengeHistory } from "./ChallengeHistory.api";
import challengeHistoryStyles from "./ChallengeHistory.styles";
import { t_ChallengeType } from "./ChallengeHistory.types";

interface Props {
    challenge_type?: t_ChallengeType;
    rows_per_page?: number;
}
const ChallengeHistory: React.FC<Props> = ({ challenge_type = "weekly", rows_per_page = 4 }) => {
    const classes = challengeHistoryStyles();
    const [challengeGroups, setChallengeGroups] = useState<{ challenges: t_Challenge[]; date: string }[]>([]);
    const [visualFeedback, setVisualFeedback] = React.useState<{
        loading: boolean;
    }>({
        loading: false,
    });

    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>({
        rows_per_page: rows_per_page,
        count: 0,
        page: 1,
    });

    useEffect(() => {
        var isActive = true;
        setVisualFeedback((state) => ({ ...state, loading: true }));
        getGroupedChallengeHistory({ challenge_type, pagination }).then((data) => {
            const challenge_history = data.data.challenge_history;
            if (isActive && challenge_history) {
                setChallengeGroups(
                    Object.keys(challenge_history).map((date) => ({
                        date,
                        challenges: challenge_history[date] ?? [],
                    })),
                );
                setPagination(
                    (state) =>
                        state && {
                            ...state,
                            count: Math.ceil((data.total_results ?? 1) / rows_per_page),
                        },
                );
                setVisualFeedback((state) => ({
                    ...state,
                    loading: false,
                }));
            }
        });
    }, [challenge_type, pagination?.page]);
    return (
        <SectionCard
            bottomActions={
                <div className={classes.pagination}>
                    <Pagination
                        page={pagination?.page}
                        count={pagination?.count}
                        onChange={(_, page) => setPagination((state) => state && { ...state, page })}
                    />
                </div>
            }
            fullWidth
            scrolling
            noHeader
        >
            <List>
                <Typography variant="h5" sx={{ pt: 4 }}>
                    Challenge History
                </Typography>
                {challengeGroups.length <= 0
                    ? [...Array(3)].map((e, i) => (
                          <React.Fragment key={`skeleton-${i}`}>
                              <ListItem className={classes.dateListItem}>
                                  <Skeleton width="30%" />
                              </ListItem>

                              <ListItem className={classes.dateListItem}>
                                  <Skeleton variant="rectangular" height={130} width="100%" />
                              </ListItem>
                          </React.Fragment>
                      ))
                    : challengeGroups.map((group) => (
                          <React.Fragment key={`date-group-${group.date}`}>
                              <ListItem className={classes.dateListItem}>
                                  <Typography variant="h6">{group.date}</Typography>
                              </ListItem>
                              {group.challenges.map((challenge) => (
                                  <ListItem
                                      key={`challenge-card-${challenge.challenge_id}`}
                                      // selected={challenge}
                                  >
                                      <ChallengeCard
                                          challenge={challenge}
                                          customClasses={{
                                              card: classes.challengeCard,
                                          }}
                                      />
                                  </ListItem>
                              ))}
                          </React.Fragment>
                      ))}
            </List>
        </SectionCard>
    );
};

export default ChallengeHistory;
