import moment from "moment";

export const defaultPeriod = {
    id: 0,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    month: "-",
    last_capture: "-",
};

