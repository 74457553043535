import { httpClient } from "../../../../clients/http.client";
import { t_PoolColumn } from "../../Production.types";
import { t_ProductionByDate, t_ProductionByDate_Totals } from "./ProductionByDate.types";

const getUserPeriods = async (payload: { year: string; surveys?: string[] }) => {
    return httpClient
        .post("admin/productionperiods", {
            sort: null,
            filter: [],
            pagination: { page: 1, rows_per_page: 20 },
            year: payload.year,
        })
        .then((resp) => {
            return resp.data.data;
        })
        .catch((err) => console.error(err));
};

const getUserPeriodsEntity = (payload: { month: string; entity_no: number }) => {
    return new Promise<any>((res, rej) => {
        httpClient
            .post("admin/productionperiods", {
                entity_no: payload.entity_no,
                month: payload.month,
                sort: null,
                filter: [],
                pagination: { page: 1, rows_per_page: 20 },
            })
            .then((resp) => {
                switch (resp.data.status) {
                    case "success":
                        const periods = resp.data.data.response
                            ? [resp.data.data.response["periods "]]
                            : resp.data.data.periods;
                        return res(periods);
                    default:
                        rej(resp.data.message);
                }
            })
            .catch((err) => rej(err));
    });
};

const getUserProduction = (payload: {
    entity_no: number;
    periods: {
        start_date?: string;
        end_date?: string;
    };
}) =>
    new Promise<{
        pools: t_PoolColumn[];
        rows: t_ProductionByDate[];
        totals: t_ProductionByDate_Totals;
    }>((resolve, reject) => {
        httpClient
            .post("/production/user_overview", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });

export { getUserProduction, getUserPeriods, getUserPeriodsEntity };
