import { Button, CircularProgress, Grid, Input } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserDispatch, useUserState } from "../../../context/user/user.ctx";

const useStyles = makeStyles({
    loginContainer: {
        height: "100vh",
    },
});

const Login = () => {
    const classes = useStyles();
    const userState = useUserState();
    const dispatch = useUserDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isSecret, setIsSecret] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSecretLogin = (entity: any) => {
        if (entity) {
            dispatch({ type: "REQUEST_LOGIN", data: { entity_no: entity } });
        }
    };

    const handleLogin = () => {
        if (process.env.REACT_APP_DISCOVERY_LOGIN != null) {
            window.location.href = process.env.REACT_APP_DISCOVERY_LOGIN;
        }
    };

    React.useEffect(() => {
        if (!userState.status.loading && !userState.status.error) {
            let { from } = location.state || { from: { pathname: "/" } };
            navigate(from, { replace: true });
        }
    }, [userState]);

    React.useEffect(() => {
        const search_params = new URLSearchParams(location.search);
        const code = search_params.get("code");
        const secret = search_params.get("secret");
        if (code != null) {
            dispatch({ type: "DISCOVERY_LOGIN", data: { code } });
            setLoading(true);
        } else {
            if (secret === "fuse_only") {
                setIsSecret(true);
            }
        }
    }, [location, location.search]);

    return (
        <Grid className={classes.loginContainer} container justifyContent="center" alignContent="center">
            {loading ? (
                <CircularProgress />
            ) : isSecret === true ? (
                <>
                    <Grid item xs={12} container justifyContent="center">
                        <Input inputRef={inputRef} />
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Button onClick={() => handleSecretLogin(inputRef.current?.value)}>Login</Button>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12} container justifyContent="center">
                    <Button onClick={handleLogin}>Login</Button>
                </Grid>
            )}
        </Grid>
    );
};
export default Login;
