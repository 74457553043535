import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const fullProfileStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "grid",
			gridTemplateColumns: "5fr 7fr",
			gap: "10px",
			gridTemplateRows: "456px 252px",
			height: "100%",
			minWidth: 1000,
		},
		fullSpan: {
			gridColumn: "1/3",
			height: "fit-content",
		},
	})
);

export default fullProfileStyles;
