import React, { createContext } from "react";

export const defaultFilter: {
    [key: string]: string[];
} = {};

interface LsaFilterProviderProps {
    children: React.ReactNode;
}

export const filterCtx = createContext<{
    filterParam: typeof defaultFilter;
    setFilterParam: React.Dispatch<React.SetStateAction<typeof defaultFilter>>;
}>({
    filterParam: defaultFilter,
    setFilterParam: () => {},
});

const LsaFilterProvider = ({ children }: LsaFilterProviderProps) => {
    const [filterParam, setFilterParam] = React.useState(defaultFilter);

    return <filterCtx.Provider value={{ filterParam, setFilterParam }}>{children}</filterCtx.Provider>;
};

export default LsaFilterProvider;
