import { Checkbox, Grid } from "@mui/material";
import { Options } from "material-table";
import React from "react";
import GroupedHeaderTable, {
  GroupedHeaderTableProps,
} from "../../../../components/GroupedHeaderTable/GroupedHeaderTable";
import GroupedHeaderTableFilter from "../../../../components/GroupedHeaderTable/GroupedHeaderTableFilter";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { t_SurveyPeriodArr } from "../../../Surveys/Surveys.types";
import {
  defaultFilter,
  filterCtx,
} from "../../reports/LsaReport/LsaFilterProvider";
import {
  getPerceptionSummary,
  getPerceptionSummaryMulti,
  getPerceptionSummaryFiltered,
} from "./PerceptionSummary.api";
import { t_PerceptionSummaryData } from "./PerceptionSummary.types";

interface Props<RowData extends object> {
  survey: "mbr" | "fcr";
  report: "survey" | "appeal" | "lsa";
  periods: t_SurveyPeriodArr;
  months: string[];
  columns: GroupedHeaderTableProps<RowData>["columns"];
}

function getDefaultPageRows<RowData extends object>(
  summaryData: t_PerceptionSummaryData<RowData>,
  field: "service_team" | "team" | "job_title"
) {
  let pgSize = 5;
  if (summaryData && summaryData[field] && summaryData[field].length > 50)
    pgSize = 20;

  return pgSize;
}

function PerceptionSummaryMulti<RowData extends object>(
  props: React.PropsWithChildren<Props<RowData>>
) {
  const [summaryData, setSummaryData] = React.useState<
    t_PerceptionSummaryData<RowData>
  >({
    survey: [],
    service_team: [],
    team: [],
    job_title: [],
  });
  const [serviceTeamChecked, setServiceTeamChecked] = React.useState<boolean[]>(
    []
  );
  const [teamChecked, setTeamChecked] = React.useState<boolean[]>([]);
  // const [jobTitleChecked, setJobTitleChecked] = React.useState<boolean[]>([]);

  const TableOptions: Options<RowData> = {
    padding: "dense",
    toolbar: false,
  };

  const setDataAndChecklists = (
    data: t_PerceptionSummaryData<RowData>,
    teamsOnly?: boolean
  ) => {
    setTeamChecked(data.team ? data.team.map((t) => false) : []);
    // setJobTitleChecked(data.job_title ? data.job_title.map((jt) => false) : []);

    if (teamsOnly) {
      setSummaryData((currData) => ({
        ...currData,
        team: data.team,
        job_title: data.job_title,
      }));

      return;
    }

    setSummaryData(data);
    setServiceTeamChecked(
      data.service_team ? data.service_team.map((st) => false) : []
    );
  };

  React.useEffect(() => {
    let isActive = true;
    const { report, survey, periods } = props;
    if (report != null && survey != null && periods != null) {
      getPerceptionSummaryMulti<RowData>({ report, survey, periods }).then(
        (data) => {
          if (isActive) {
            setDataAndChecklists(data);
          }
        }
      );
    }

    return () => {
      isActive = false;
    };
  }, [props.periods, props.report, props.survey]);

  const { filterParam, setFilterParam } = React.useContext(filterCtx);

  const handleServiceTeamFilter = (checkIndex: number) => {
    const { report, survey, periods } = props;
    const newCheckList = serviceTeamChecked.map((ch, i) =>
      i === checkIndex ? !ch : ch
    );
    setServiceTeamChecked(newCheckList);

    const filter = {
      ...filterParam,
      service_team: summaryData.service_team
        .filter((_, i) => newCheckList[i])
        .map((e) => e.service_team),
    };
    setFilterParam(filter.service_team.length > 0 ? filter : {});

    if (report != null && survey != null && periods != null) {
      if (newCheckList.includes(true)) {
        getPerceptionSummaryFiltered<RowData>({
          report,
          survey,
          periods,
          filter,
        }).then((data) => {
          setSummaryData((currData) => ({
            ...currData,
            team: data.team,
            job_title: data.job_title,
          }));
        });
        return;
      }

      getPerceptionSummaryMulti<RowData>({ report, survey, periods }).then(
        (data) => setDataAndChecklists(data)
      );
    }
  };

  const handleTeamFilter = (checkIndex: number) => {
    const { report, survey, periods } = props;
    const newCheckList = teamChecked.map((ch, i) =>
      i === checkIndex ? !ch : ch
    );
    setTeamChecked((checked) =>
      checked.map((ch, i) => (i === checkIndex ? !ch : ch))
    );

    const filter = {
      ...filterParam,
      team: summaryData.team
        .filter((_, i) => newCheckList[i])
        .map((e) => e.team),
    };
    setFilterParam(
      filter.team.length > 0
        ? filter
        : { service_team: filterParam.service_team }
    );

    if (report != null && survey != null && periods != null) {
      if (newCheckList.includes(true)) {
        getPerceptionSummaryFiltered<RowData>({
          report,
          survey,
          periods,
          filter,
        }).then((data) => {
          setSummaryData((currData) => ({
            ...currData,
            job_title: data.job_title,
          }));
        });
        return;
      }

      if (serviceTeamChecked.includes(true)) {
        getPerceptionSummaryFiltered<RowData>({
          report,
          survey,
          periods,
          filter: {
            service_team: summaryData.service_team
              .filter((_, i) => serviceTeamChecked[i])
              .map((e) => e.service_team),
          },
        }).then((data) => {
          setSummaryData((currData) => ({
            ...currData,
            team: data.team,
            job_title: data.job_title,
          }));
        });
      } else {
        getPerceptionSummaryMulti<RowData>({ report, survey, periods }).then(
          (data) => setDataAndChecklists(data, true)
        );
      }
    }
  };

  // const handleJobTitleFilter = (checkIndex: number) => {
  //   const { report, survey, periods } = props;
  //   const newCheckList = jobTitleChecked.map((ch, i) =>
  //     i === checkIndex ? !ch : ch
  //   );
  //   setJobTitleChecked((checked) =>
  //     checked.map((ch, i) => (i === checkIndex ? !ch : ch))
  //   );

  //   if (report != null && survey != null && periods != null) {
  //     getPerceptionSummaryFiltered<RowData>({
  //       report,
  //       survey,
  //       periods,
  //       filter: {
  //         job_title: summaryData.job_title
  //           .filter((_, i) => newCheckList[i])
  //           .map((e) => e.job_title),
  //       },
  //     }).then((data) => {
  //       setSummaryData((currData) => ({
  //         ...currData,
  //         job_title: data.job_title,
  //       }));
  //     });
  //   }
  // };

  return (
    <Grid container spacing={2} style={{ paddingBottom: 16 }}>
      <Grid item sm={12}>
        <SectionCard
          title={"Service Team"}
          titleProps={{ variant: "subtitle2" }}
          fullWidth
          hasBackground
        >
          <GroupedHeaderTableFilter
            forField="service_team"
            columns={[
              {
                header: "Filter",
                field: "checked",
                render: (val) => {
                  return (
                    <Checkbox
                      checked={val.checked}
                      onChange={() => handleServiceTeamFilter(val.tableData.id)}
                    />
                  );
                },
              },
              {
                header: "Service Team",
                field: "service_team",
              },
              ...props.columns,
            ]}
            data={
              summaryData.service_team
                ? summaryData.service_team.map((st, i) => ({
                    checked: serviceTeamChecked[i] || false,
                    ...st,
                  }))
                : []
            }
            options={{
              ...TableOptions,
              pageSize: getDefaultPageRows(summaryData, "service_team"),
            }}
          />
        </SectionCard>
      </Grid>
      <Grid item sm={12}>
        <SectionCard
          title={"Team"}
          titleProps={{ variant: "subtitle2" }}
          fullWidth
          hasBackground
        >
          <GroupedHeaderTableFilter
            forField="team"
            columns={[
              {
                header: "Filter",
                field: "checked",
                render: (val) => {
                  return (
                    <Checkbox
                      checked={val.checked}
                      onChange={() => handleTeamFilter(val.tableData.id)}
                    />
                  );
                },
              },
              {
                header: "Team",
                field: "team",
              },
              ...props.columns,
            ]}
            data={
              summaryData.team
                ? summaryData.team.map((t, i) => ({
                    checked: teamChecked[i] || false,
                    ...t,
                  }))
                : []
            }
            options={{
              ...TableOptions,
              pageSize: getDefaultPageRows(summaryData, "team"),
            }}
          />
        </SectionCard>
      </Grid>
      <Grid item sm={12}>
        <SectionCard
          title={"Job Title"}
          titleProps={{ variant: "subtitle2" }}
          fullWidth
          hasBackground
        >
          <GroupedHeaderTableFilter
            forField="job_title"
            columns={[
              // {
              //   header: "Filter",
              //   field: "checked",
              //   render: (val) => {
              //     return (
              //       <Checkbox
              //         checked={val.checked}
              //         onChange={() => handleJobTitleFilter(val.tableData.id)}
              //       />
              //     );
              //   },
              // },
              {
                header: "Job Title",
                field: "job_title",
              },
              ...props.columns,
            ]}
            data={
              summaryData.job_title
              // ? summaryData.job_title.map((jt, i) => ({
              //     checked: jobTitleChecked[i],
              //     ...jt,
              //   }))
              // : []
            }
            options={{
              ...TableOptions,
              pageSize: getDefaultPageRows(summaryData, "job_title"),
            }}
          />
        </SectionCard>
      </Grid>
    </Grid>
  );
}

export default PerceptionSummaryMulti;
