import {
	SortDirection,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import React from "react";
import { MiscBody } from "../../types/api.types";
import dataTableStyles from "./DataTable.style";

export type ColumnHeaderProps = {
	label: string;
	name: string;
	field: string;
};
export type DataTableSort = { column_name: string; order: "ASC" | "DESC" };
export interface DataTableProps {
	columns?: ColumnHeaderProps[];
	collapsible?: boolean | undefined;
	variant?: "table" | "condensed";
	sort?: MiscBody["sort"];
	sortBy?: (sort: MiscBody["sort"]) => void;
	[propName: string]: any;
}

const DataTable = (props: DataTableProps) => {
	const {
		columns,
		collapsible,
		variant = "table",
		sort,
		sortBy,
		children,
		...other
	} = props;
	const classes = dataTableStyles();

	const handleSortLabelClick = (column: ColumnHeaderProps) => {
		if (sortBy) {
			let sortDirection;
			switch (sort?.order) {
				case "DESC":
					sortDirection = "ASC";
					break;
				case "ASC":
					sortDirection = null;
					break;
				default:
					sortDirection = "DESC";
					break;
			}
			if (sortDirection != null) {
				sortBy({ column_name: column.field, order: sortDirection });
			} else {
				sortBy(undefined);
			}
		}
	};

	return (
		<TableContainer className={classes.tableContainer}>
			<Table
				{...other}
				className={classes.root}
				size={(variant === "condensed" && "small") || undefined}
				stickyHeader
			>
				<TableHead>
					<TableRow className={classes.row}>
						{collapsible && <TableCell />}
						{columns?.map((column, index) => (
							<TableCell
								key={index}
								{...column}
								sortDirection={
									sort?.column_name === column.field
										? (sort?.order.toLowerCase() as SortDirection)
										: undefined
								}
							>
								<TableSortLabel
									active={sort?.column_name === column.field}
									direction={
										sort?.column_name === column.field
											? (sort?.order.toLowerCase() as
													| "asc"
													| "desc")
											: "desc"
									}
									onClick={() => handleSortLabelClick(column)}
								>
									{column.label}
									{sort?.column_name === column.field ? (
										<span
											className={classes.visuallyHidden}
										>
											{sort?.order === "DESC"
												? "sorted descending"
												: "sorted ascending"}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className={classes.row}>{children}</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DataTable;
