import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SummaryData } from "../types";

type SummaryState = {
    allSummaries: SummaryData[];
    pagination: {
        page: number;
        rows_per_page: number;
        count: number;
    };

    sort?: {
        column_name: string;
        order: string;
    };
};

const initialState: SummaryState = {
    allSummaries: [],
    pagination: {
        page: 1,
        rows_per_page: 5,
        count: 1,
    },

    sort: {
        column_name: "created_at",
        order: "DESC",
    },
};
const summarySlice = createSlice({
    name: "metrics",
    initialState,
    reducers: {
        setAllSummaries: (state, action: PayloadAction<SummaryData[]>) => {
            state.allSummaries = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const { setAllSummaries, setPagination } = summarySlice.actions;

export default summarySlice.reducer;
