import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import SectionCard from "../../../components/SectionCard/SectionCard";
import TabGroup from "../../../components/TabGroup/TabGroup";
import { usePermissions } from "../../../context/user/permissions.ctx";
import { useUserState } from "../../../context/user/user.ctx";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";
import TeamPerformance from "../../../sections/Performance/sections/TeamPerformance/TeamPerformance";
import UserPerformance from "../../../sections/Performance/sections/UserPerformance/UserPerformance";
import performanceStyles from "./Performance.style";

type MetricType = {
    id: number;
    name: string;
    value: number;
    details: { target: number; comparison: number };
};

const Performance: React.FC = () => {
    const classes = performanceStyles();
    const location = useLocation();
    const [tab, setTab] = useState<number>(0);
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const date = new Date(month);
    const permissions = usePermissions();
    const currentUser = useUserState();

    const handleDateChange = (date: any) => {
        if (date !== null) {
            performanceDispatch({
                type: "update_dates",
                month: date,
            });
        }
    };

    React.useEffect(() => {
        if (tab === 0 && currentUser.profile != null) {
            performanceDispatch({ type: "refresh" });
        }
    }, [tab, currentUser.status.lastFetch]);

    React.useEffect(() => {
        const metric_param = new URLSearchParams(location.search).get("metric");
        if (metric_param != null) {
            setTab(0);
        }
    }, [location.search]);

    return (
        <Grid item xs={12}>
            <SectionCard
                title="Performance Dashboard"
                subtitle={currentUser.profile?.name}
                fullWidth
                primaryAction={
                    <TabGroup
                        tabList={[
                            {
                                _id: 0,
                                label:
                                    (currentUser.profile != null &&
                                        currentUser.profile?.job_level > 1 &&
                                        "Team's Performance") ||
                                    "My Performance",
                            },
                            { _id: 1, label: "My Team" },
                        ]}
                        tabChange={(newValue) => setTab(newValue)}
                        tabValue={tab}
                    />
                }
                secondaryAction={
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        // label="Month and Year"
                        value={date}
                        onChange={handleDateChange}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                }
            >
                {/* Currently logged in user: */}
                <Grid item xs={12}>
                    {tab === 0 && <UserPerformance />}
                    {/* Team view: */}
                    {tab === 1 && (
                        <TeamPerformance />
                        // <TeamMembers selectUser={handleUserSelected} />
                    )}
                </Grid>
            </SectionCard>
        </Grid>
    );
};

export type { MetricType };
export default Performance;
