import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const sectionCardStyles = makeStyles((theme: Theme) => {
	const border = "1px solid transparent";
	const cardDefault = {
		border,
	};
	return createStyles({
		card: {
			height: "100%",
			position: "relative",
		},
		cardContentScroll: {
			overflow: "hidden scroll",
		},
		elevatedCard: {
			...cardDefault,
		},
		flatCard: {
			...cardDefault,
			background: "none",
		},
		flatHeader: {
			borderBottom: `1px solid ${theme.palette.primary.main}`,
		},
		elevatedHeader: {
			borderBottom: `1px solid ${theme.palette.secondary.main}`,
		},
		capitalize: {
			textTransform: "capitalize",
		},
		titleSection: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-evenly",
		},
		flatDivider: {
			backgroundColor: theme.palette.primary.main,
		},
		elevatedDivider: {
			backgroundColor: theme.palette.secondary.main,
		},
		headerActions: {
			alignSelf: "flex-end",
		},
	});
});

const sectionCardInnerStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridContainer: {
			height: "100%",
		},
		gridItem: {
			height: "inherit",
		},
	})
);

export { sectionCardInnerStyles };
export default sectionCardStyles;
