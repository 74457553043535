import { TableFooter } from "@mui/material";
import { styled } from "@mui/styles";
import MaterialTable, { MaterialTableProps } from "material-table";
import React from "react";

const BUMaterialTable = <RowData extends object>({
	...props
}: MaterialTableProps<RowData>) => {
	const columns = props.columns.map((column) => ({
		...column,
		width: column.width || "auto",
	}));
	return <MaterialTable {...props} columns={columns} />;
};

const BUTableFooter = styled(TableFooter)({
	borderTop: "1px solid lightgrey",
});

export { BUTableFooter };
export default BUMaterialTable;
