import { Box, CircularProgress, Grid, List, ListItem } from "@mui/material";
import { Pagination } from "@mui/material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import { MiscBody } from "../../../../types/api.types";
import HelpFile from "../../components/HelpFile/HelpFile";
import { t_HelpFile } from "../../components/HelpFile/HelpFile.types";
import { getHelpFiles } from "./HelpFileSearch.api";
import { NavItem } from "../../../../navigation/components/NavItem/NavItem";
import { OndemandVideoOutlined } from "@mui/icons-material";

type Props = {
    search?: string;
};

const HelpFileSearch: React.FC<Props> = ({ search }) => {
    const [helpFiles, setHelpFiles] = React.useState<t_HelpFile[]>([]);
    const [pagination, setPagination] = React.useState<MiscBody["pagination"]>({
        page: 1,
        rows_per_page: 5,
    });
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        var isActive = true;

        setLoading(true);
        getHelpFiles({
            search,
            pagination,
        }).then((data) => {
            if (isActive) {
                setHelpFiles(data.data.help_files);
                if (data.total_results != null && data.pagination != null && data.pagination.rows_per_page > 0) {
                    setPagination({
                        ...data.pagination,
                        count: Math.ceil(data.total_results / data.pagination.rows_per_page),
                    });
                }
                setLoading(false);
            }
        });
        return () => {
            isActive = false;
        };
    }, [search, pagination?.page]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={6}>
                <SectionCard
                    // noHeader
                    title="Help Files"
                    bottomActions={
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Pagination
                                page={pagination?.page}
                                count={pagination?.count}
                                onChange={(_, page) => setPagination((state) => state && { ...state, page })}
                            />
                        </div>
                    }
                    scrolling
                >
                    <List>
                        {loading ? (
                            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            helpFiles.map((file) => (
                                <ListItem key={`helpfile-${file.id}`}>
                                    <HelpFile title={file.title} content={file.content} />
                                </ListItem>
                            ))
                        )}
                    </List>
                </SectionCard>
            </Grid>
            <Grid item sm={6}>
                <SectionCard title="Videos">
                    <List>
                        {BeYouVideos.map((video) => (
                            <ListItem>
                                <OndemandVideoOutlined />
                                <NavItem open={true} label={video.label} target={true} route={video.link} />
                            </ListItem>
                        ))}
                    </List>
                </SectionCard>
            </Grid>
        </Grid>
    );
};

const BeYouVideos = [
    {
        id: 1,
        label: "BeYou Teaser",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/EXLlmWuEiW5Bqa7--Ndb7uwBLjM1uMrlWBKGFvSiv6D6aw?e=pR7OyY",
    },
    {
        id: 2,
        label: "BeYou Overview",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/ERSJguMs03dLj9VpAtn4aMcBkxuBrQpCTa8V-QlvyUI3Ow?e=OLhNXg",
    },
    {
        id: 3,
        label: "Update Your Profile",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/EWrBABQwdjNFujlZeX-nE9ABgDI4-7W6TZjLnMCSWjRCbw?e=9HrQrj",
    },
    {
        id: 4,
        label: "Viewing & Allocating Badges",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/EeZ56tWWYYpLqOLGRayON68BVaM1YFpk5DqwHkkiBx7bFQ?e=eoGOG9",
    },
    {
        id: 5,
        label: "Low Score Analysis",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/Ece3rcqDV_ZDs6pxP0t7H4oB0u-GRnCeeu1khD7JfaYvMg?e=pPEgj3",
    },
    {
        id: 6,
        label: "Production Calculator",
        link: "https://discovery365.sharepoint.com/:v:/s/DSY_LearningDesignandDevelopment/EfGS0M18r6VMnyHrw9DuLvQBPdCLnD_2td-Gkum9A6A20w?e=9pu3Qc",
    },
];

export default HelpFileSearch;
