import React from "react";
import { TimeTracker } from "../../Toasts/ToastTimeTracker";
import { Avatar, Box, Button, Typography } from "@mui/material";
import ItemActions from "../../Toasts/ToastsActions";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";
import { Icons, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { AdditionalVolumeProps } from "./NotificationTypes";

const AdditionalVolume = ({ additionalVolume, setSidePanelChatBot }: AdditionalVolumeProps) => {
    const navigate = useNavigate();

    return (
        <Box key={additionalVolume?.id}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    marginBottom: "10px",
                    flex: 1,
                    borderTop: "1px solid #F0F0F0",
                    borderBottom: "solid 1px #F0F0F0",
                }}
                onClick={() => {
                    const location = {
                        pathname: "/performance/production",
                        state: {
                            entity_no: additionalVolume.user.entity_no,
                            date: additionalVolume.date,
                        },
                    };
                    navigate(location);
                    setSidePanelChatBot(false);
                }}
            >
                <Box
                    style={{
                        marginRight: "8px",
                        paddingRight: "8px",
                        borderRight: "1px solid #F0F0F0",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    {Icons.info({
                        theme: "light",
                        type: toast.TYPE.INFO,
                        width: 30,
                        height: 30,
                    })}
                    <Typography variant="body2" style={{ maxWidth: 80, fontSize: 15 }}>
                        Additional <br />
                        Volume
                    </Typography>
                </Box>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            marginBottom: "4px",
                            cursor: "pointer",
                        }}
                    >
                        <Typography variant="body2" style={{ fontSize: 13, maxWidth: "90%" }}>
                            {additionalVolume?.user?.name}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 10, maxWidth: "90%" }}
                            >
                                <TimeTracker createdAt={Number(additionalVolume?.date)} />
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AdditionalVolume;
