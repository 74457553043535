export const toolTips: { [key: string]: { [key: string]: string } } = {
  service_team: {
    completed_ratio:
      "The LSA has been completed by both the team leader and the agent",
    partial_ratio:
      "The LSA has been completed by either the team leader or the agent",
    incomplete_ratio:
      "The LSA has not been completed by both the team leader or the agent",
  },
  team: {
    completed_ratio:
      "The LSA has been completed by both the team leader and the agent",
    partial_ratio:
      "The LSA has been completed by either the team leader or the agent",
    incomplete_ratio:
      "The LSA has not been completed by both the team leader or the agent",
  },
  job_title: {
    completed_ratio:
      "The LSA has been completed by both the team leader and the agent",
    partial_ratio:
      "The LSA has been completed by either the team leader or the agent",
    incomplete_ratio:
      "The LSA has not been completed by both the team leader or the agent",
  },
};
