import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import sectionedDialogStyles from "./SectionedDialog.style";

interface Props extends DialogProps {
	title?: string;
}

const SectionedDialog: React.FC<Props> = ({
	title,
	children,
	...dialogProps
}) => {
	const classes = sectionedDialogStyles();

	return (
        <Dialog
			{...dialogProps}
			classes={{
				paper: classes.dialog,
				...dialogProps.classes,
			}}
		>
			<DialogTitle
				id='form-dialog-title'
				className={classes.dialogHeader}
			>
				{title}
				{dialogProps.onClose && (
					<IconButton
                        onClick={(e) =>
							dialogProps.onClose &&
							dialogProps.onClose(e, "backdropClick")
						}
                        className={classes.closeButton}
                        size="large">
						<Close />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent
				classes={{
					root: classes.dialogContent,
				}}
			>
				{children}
			</DialogContent>
		</Dialog>
    );
};

export default SectionedDialog;
