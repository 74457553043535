import { Column, Query, QueryResult } from "material-table";
import { httpClient } from "../../clients/http.client";
import { convertQuery } from "../../misc/helpers";
import { t_User } from "../../types/user.types";
import { SurveyReports } from "../PerceptionReport/PerceptionReport";
import { t_SurveyPeriod, t_SurveyPeriodArr, t_SurveyRecord } from "./Surveys.types";

const SurveyColumns: Column<t_SurveyRecord>[] = [
    { title: "Response ID", field: "ResponseID" },
    { title: "Survey Type", field: "SurveyType" },
    { title: "Call ID", field: "CallID" },
    { title: "Call Date Answered", field: "CallDateAnswered" },
    { title: "Survey Date", field: "SurveyDate" },
    {
        title: "Survey Score",
        field: "SurveyScore",
        render: (rowData) => {
            if (rowData != null && rowData.SurveyScore != null) {
                if (rowData.Survey == "fcr") {
                    var number = Number(rowData.SurveyScore);
                    if (number === 0) {
                        return "No";
                    }
                    if (number === 1) {
                        return "Yes";
                    }
                    return rowData.SurveyScore;
                }
                return Math.round(Number(rowData.SurveyScore));
            }
        },
    },
];

type t_SurveyTotals = {
    AvgScore: number;
    fcr?: number;
    mbr?: number;
};

const getSurveyRecordsAny = (payload: {
    query: Query<t_SurveyRecord>;
    periods: {
        [key: string]: t_SurveyPeriod | t_SurveyPeriodArr;
    };
    report: SurveyReports;
    entity_no: t_User.Profile["entity_no"];
    survey_type_only?: "mbr" | "fcr";
}) =>
    new Promise<QueryResult<t_SurveyRecord>>((res, rej) => {
        const body = {
            for_report: payload.report,
            periods: payload.periods,
            agent_entity_no_no: payload.entity_no,
            search: payload.survey_type_only,
            ...convertQuery(payload.query),
        };

        const endpoint = `/reports/survey/agent_records${
            Array.isArray(payload.periods[Object.keys(payload.periods)[0]]) ? "/multi" : ""
        }`;

        httpClient.post(endpoint, body).then(({ data }) => {
            const rdata = payload.survey_type_only
                ? data.data.agent_records[0].data.filter((d: any) => d.Survey === payload.survey_type_only)
                : data.data.agent_records[0].data;

            res({
                data: rdata,
                page: data.pagination.page - 1,
                totalCount: data.total_results,
            });
        });
    });

const getSurveyTotals = (payload: {
    periods: {
        [key: string]: t_SurveyPeriod;
    };
    report: SurveyReports;
    entity_no: t_User.Profile["entity_no"];
}) =>
    new Promise<t_SurveyTotals>((resolve, reject) => {
        const body = {
            for_report: payload.report,
            periods: payload.periods,
            agent_entity_no_no: payload.entity_no,
        };

        httpClient.post("/reports/survey/agent_totals", body).then(({ data }) => {
            switch (data.status) {
                case "success":
                    resolve(data.data.totals);
                    break;
                default:
                    reject(data.message);
            }
        });
    });

const getSurveyTotalsMulti = (payload: {
    query?: Query<t_SurveyRecord>;
    periods: {
        [key: string]: t_SurveyPeriod | t_SurveyPeriodArr;
    };
    report: SurveyReports;
    entity_no: t_User.Profile["entity_no"];
    survey_type_only?: "mbr" | "fcr";
}) =>
    new Promise<t_SurveyTotals>((res, rej) => {
        const body = {
            for_report: payload.report,
            periods: payload.periods,
            agent_entity_no_no: payload.entity_no,
            search: payload.survey_type_only,
        };

        const endpoint = `/reports/survey/agent_records${
            Array.isArray(payload.periods[Object.keys(payload.periods)[0]]) ? "/multi" : ""
        }`;

        httpClient.post(endpoint, body).then(({ data }) => {
            const total = payload.survey_type_only
                ? (data.data.agent_records[1].agent_totals[payload.survey_type_only] as number)
                : (data.data.agent_records[1].agent_totals[payload.report] as number);
            const resObj = payload.survey_type_only
                ? { AvgScore: total, [payload.survey_type_only]: total }
                : { AvgScore: total };

            res(resObj);
        });
    });

export { getSurveyRecordsAny, getSurveyTotals, getSurveyTotalsMulti, SurveyColumns };
export type { t_SurveyTotals };
