import { useTheme } from "@mui/material";
import React from "react";
import {
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { getSurveySummaryGraph } from "./SurveyTrendGraph.api";
import { t_SurveyTrendData } from "./SurveyTrendGraph.types";

interface Props {
	survey: "mbr" | "fcr";
	month: string;
}

const SurveyTrendGraph: React.FC<Props> = ({ survey, month }) => {
	const theme = useTheme();
	const [names, setNames] = React.useState<{
		current: string;
		previous: string;
	}>();
	const [trendData, setTrendData] = React.useState<t_SurveyTrendData[]>([]);

	const valueFormatter = (value: any) => {
		const number = Number(value);
		switch (survey) {
			case "fcr":
				return `${number}%`;
			default:
				return number;
		}
	};
	React.useEffect(() => {
		var isActive = true;
		let current = new Date(month);
		let previous = new Date(month);

		previous.setMonth(previous.getMonth() - 12);

		setNames({
			current: current.getFullYear().toString(),
			previous: previous.getFullYear().toString(),
		});

		getSurveySummaryGraph({
			for_month: month,
			for_survey: survey,
		}).then((trend) => {
			if (isActive) {
				setTrendData(trend);
			}
		});
		return () => {
			isActive = false;
		};
	}, [month, survey]);

	return (
		<ResponsiveContainer width='100%' height={200}>
			<LineChart
				data={trendData}
				margin={{
					top: 20,
					right: 30,
					left: 0,
					bottom: 10,
				}}
			>
				<XAxis dataKey='month' />
				<YAxis
					tickFormatter={valueFormatter}
					domain={["dataMin", "dataMax"]}
				/>
				<Tooltip formatter={valueFormatter} />
				<Legend
					verticalAlign='top'
					align='center'
					iconType='rect'
					iconSize={10}
				/>
				<Line
					type='monotone'
					dataKey='previous_year'
					dot={false}
					strokeWidth={2}
					stroke={theme.palette.info.contrastText}
					name={names?.previous}
				/>
				<Line
					type='monotone'
					dataKey='current_year'
					dot={false}
					strokeWidth={2}
					stroke={theme.palette.info.main}
					fontSize={10}
					name={names?.current}
				/>
			</LineChart>
		</ResponsiveContainer>
	);
};

export default SurveyTrendGraph;
