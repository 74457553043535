import { Box, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import BUMaterialTable from "../../components/BUMaterialTable/BUMaterialTable";
import ExportButton from "../../components/ExportButton/ExportButton";
import SectionCard from "../../components/SectionCard/SectionCard";
import TabGroup from "../../components/TabGroup/TabGroup";
import useMultiDateSelect from "../../hooks/component/useMultiDateSelect/useMultiDateSelect";
import { useReportExport } from "../../hooks/export/useReportExport";
import { useMaterialTable } from "../../hooks/material-table/useMaterialTable";
import { getApiUrl } from "../../misc/helpers";
import { styles } from "../Production/sections/LeaderProduction/LeaderProduction.styles";
import { getUsageRecordsMulti, getUsageStatsMulti } from "./UsageReport.api";
import { t_UsageStats, UsageRecord } from "./UsageReport.types";

const UsageTable: React.FC<{ dates?: string[] }> = ({ dates }) => {
    const { tableRef, options, actions, dataSourceConfig, setDataSourceArgs } = useMaterialTable<
        UsageRecord,
        {
            months: string[];
            filter?: any;
        }
    >({
        options: {
            filtering: false,
            sorting: true,
            paginationType: "normal",
        },
        data_source: getUsageRecordsMulti,
    });

    React.useEffect(() => {
        if (dates) {
            setDataSourceArgs({
                months: dates,
            });
        }
    }, [dates]);

    const dataSource = dataSourceConfig("users_display_name");
    React.useEffect(() => {}, [dataSource]);
    return (
        <BUMaterialTable
            tableRef={tableRef}
            title="Individual Usage Report"
            columns={[
                {
                    title: "Name",
                    field: "users_display_name",
                    defaultSort: "asc",
                    searchable: true,
                    render: (rowData) => `${rowData["users_display_name"]} (${rowData["users_entity_no"]})`,
                },
                { title: "Skill", field: "skill_name", filtering: false },
                {
                    title: "Service Team",
                    field: "service_team_name",
                    filtering: false,
                },
                {
                    title: "Manager Name",
                    field: "manager_display_name",
                    filtering: false,
                },
                {
                    title: "Logins",
                    field: "login_count",
                    filtering: false,
                    type: "numeric",
                },
                {
                    title: "Rank",
                    field: "users_rank",
                    filtering: false,
                    type: "numeric",
                },
            ]}
            data={dataSource}
            actions={actions}
            onSearchChange={(text) => {
                setDataSourceArgs({
                    months: dates ?? [],
                    filter: [{ column_name: "users_display_name", search_term: text }],
                });
            }}
            options={{ ...options, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], maxBodyHeight: "50vh" }}
        />
    );
};

const UsageGraphs: React.FC<{ dates: string[] }> = ({ dates }) => {
    const theme = useTheme();
    const [byMonth, setByMonth] = useState<t_UsageStats["by_month"]>([]);
    const [byServiceTeam, setByServiceTeam] = useState<t_UsageStats["by_service_team"]>([]);
    const [byJobTitle, setByJobTitle] = useState<t_UsageStats["by_job_title"]>([]);

    useEffect(() => {
        if (dates) {
            getUsageStatsMulti({ months: dates }).then((data) => {
                setByMonth([...data.by_month]);
                setByServiceTeam([...data.by_service_team]);
                setByJobTitle([...data.by_job_title]);
            });
        }
    }, [dates]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <SectionCard title="Unique User Logins" titleProps={{ variant: "subtitle1" }} hasBackground>
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={byMonth}>
                            <YAxis type="number" yAxisId="average" orientation="right" />
                            <YAxis type="number" yAxisId="unique" orientation="left" />
                            <XAxis type="category" dataKey="month" />
                            <Tooltip />
                            <Legend />
                            <Bar
                                dataKey="unique_logins"
                                name="Unique Logins"
                                fill={theme.palette.info.light}
                                yAxisId="unique"
                            />
                            <Line
                                dataKey="average_logins"
                                name="Average Logins"
                                stroke={theme.palette.info.dark}
                                type="monotone"
                                dot={false}
                                yAxisId="average"
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </SectionCard>
            </Grid>
            <Grid item sm={6}>
                <SectionCard title="Unique Users per Service Team" titleProps={{ variant: "subtitle1" }} hasBackground>
                    <ResponsiveContainer width="100%" height={450}>
                        <BarChart data={byServiceTeam} layout="vertical">
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="service_team" width={250} tick={{ fontSize: "0.8em" }} />
                            <Tooltip />
                            <Bar dataKey="unique_logins" name="Unique Logins" fill={theme.palette.info.light} />
                        </BarChart>
                    </ResponsiveContainer>
                </SectionCard>
            </Grid>
            <Grid item sm={6}>
                <SectionCard title="Unique Users per Job Title" titleProps={{ variant: "subtitle1" }} hasBackground>
                    <ResponsiveContainer width="100%" height={450}>
                        <BarChart data={byJobTitle} layout="vertical">
                            <XAxis type="number" />
                            <YAxis type="category" dataKey="job_title" width={250} tick={{ fontSize: "0.8em" }} />
                            <Tooltip />
                            <Bar dataKey="unique_logins" name="Unique Logins" fill={theme.palette.info.light} />
                        </BarChart>
                    </ResponsiveContainer>
                </SectionCard>
            </Grid>
        </Grid>
    );
};

const UsageReport: React.FC = () => {
    const [tabValue, setTabValue] = useState(0);
    const { loading: exportLoading, downloadExportFile, downloadExportFileMulti } = useReportExport();
    const tabList = [
        { _id: 0, label: "Dashboard" },
        { _id: 1, label: "Details" },
    ];
    const { yearAndMonthSelector, formattedPeriods, datePills, periodsArr, selectedDate } = useMultiDateSelect();

    const classes = styles();

    const handleExportMulti = (file_type: string) => {
        let reqPeriods: string[] = [];
        reqPeriods = periodsArr[Object.keys(periodsArr)[0]].map((period) => {
            if (period && period.month) {
                const [y, m, _] = period.month.split("-");
                return [y, m, "05"].join("-");
            } else return "";
        });

        const usage_exp_url = new URL(`${getApiUrl()}/reports/usage/export/multi`);
        usage_exp_url.searchParams.append("months", reqPeriods.toString());
        usage_exp_url.searchParams.append("file_type", file_type);
        // usage_exp_url.searchParams.append(
        //   "filter",
        //   secondaryTabOptions[secondaryTab]
        // );

        downloadExportFileMulti(usage_exp_url.toString(), "Usage Report");
    };

    return (
        <SectionCard
            title="Usage Report"
            primaryAction={<TabGroup tabValue={tabValue} tabList={tabList} tabChange={(value) => setTabValue(value)} />}
            secondaryAction={yearAndMonthSelector}
            fullWidth={tabValue === 1}
            scrolling
        >
            <Box pb="2em">{datePills}</Box>
            {tabValue === 1 && (
                <ExportButton
                    dispatch={handleExportMulti}
                    loading={exportLoading}
                    disabled={exportLoading}
                    className={classes.exportBtn}
                >
                    Export
                </ExportButton>
            )}
            {tabValue === 0 && <UsageGraphs dates={formattedPeriods} />}
            {tabValue === 1 && <UsageTable dates={formattedPeriods} />}
        </SectionCard>
    );
};

export default UsageReport;
