const AllSubmittedCoachingForms = (
    submittedCoachForm: any,
): {
    absenteeism: any[];
    adhoc: any[];
    dpmo: any[];
    expectation: any[];
    grow_coaching: any[];
    handover: any[];
    ops_losses: any[];
    perception: any[];
    pip_follow_up: any[];
    pip_initiation: any[];
    written_warning: any[];
} => {
    if (!submittedCoachForm) {
        return {
            absenteeism: [],
            adhoc: [],
            dpmo: [],
            expectation: [],
            grow_coaching: [],
            handover: [],
            ops_losses: [],
            perception: [],
            pip_follow_up: [],
            pip_initiation: [],
            written_warning: [],
        };
    }

    const adhoc = [
        {
            adhoc: {
                label: "ADHOC",
                value: submittedCoachForm?.adhoc,
                type: "dropdown",
                multiline: false,
            },
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            expectation: {
                label: "Expectation",
                value: submittedCoachForm?.expectation,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Details of Coaching",
            membership_no: {
                label: "Membership Number",
                value: submittedCoachForm?.membership_no,
                type: "input",
                multiline: false,
            },
            pid: {
                label: "Call Reference Or PID",
                value: submittedCoachForm?.pid,
                type: "input",
                multiline: false,
            },
            details: {
                label: "Details of Coaching",
                value: submittedCoachForm?.details,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Submission Frequency For What Happened",
            submission_frequency: {
                label: "Submission Frequency For What Happened",
                value: "",
                type: "text",
                multiline: true,
            },
        },
        {
            title: "Why Did This Happen?",
            reason: {
                label: "Why Did This Happen",
                value: submittedCoachForm?.reason,
                type: "autocomplete",
                multiline: false,
            },
        },
        {
            title: "Reason Why It Happened",
            reasons_why_it_happened: {
                label: "Reason Why It Happened",
                value: submittedCoachForm?.reasons_why_it_happened,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Options",
            options: {
                label: "",
                value: submittedCoachForm?.options,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const absenteeism = [
        {
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
            support_info: {
                label: "Support",
                value: submittedCoachForm?.support_info,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Links",
            link_perception_report: {
                label: "Click here to access The Leave Policy",
                value: "https://360.discovery.co.za/pol/pol_ppl/Complete%20Employee%20Policy%20Handbook.pdf",
                type: "link",
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "TL Comments",
            tl_comments: {
                label: "TL Comments",
                value: submittedCoachForm?.tl_comments,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Agent Comments",
            agent_comments: {
                label: "Agent Comments",
                value: submittedCoachForm?.agent_comments,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Support Required",
            resource_support: {
                label: "Support Required",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Options",
            options: {
                label: "Options",
                value: submittedCoachForm?.options,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "When You Take Unplanned Leave",
            when_you_take_unplanned_leave: {
                label: "When You Take Unplanned Leave",
                value: "You are required to inform your team leader of intended absence no later than one hour before your shift starts. The ECP Service Team utilises a USSD system that is completely free-of-charge. This system allows you to inform your team from any cell phone at no cost. To use the system dial *134*2276*02# and enter your employee number when prompted.",
                type: "input",
                multiline: true,
            },
        },
    ];

    const ops_losses = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },

        {
            title: "Details",
            membership_no: {
                label: "Membership Number",
                value: submittedCoachForm?.membership_no,
                type: "input",
                multiline: false,
            },
            pid: {
                label: "Ops Loss PID",
                value: submittedCoachForm?.pid,
                type: "input",
                multiline: false,
            },
            call_ref: {
                label: "Call Reference",
                value: submittedCoachForm?.call_ref,
                type: "input",
                multiline: false,
            },
            cost_incurred: {
                label: "Cost Incurred",
                value: submittedCoachForm?.cost_incurred,
                type: "input",
                multiline: false,
            },
            claim_amount: {
                label: "Claim Amount",
                value: submittedCoachForm?.claim_amount,
                type: "input",
                multiline: false,
            },
            claim_ref: {
                label: "Claim Reference",
                value: submittedCoachForm?.claim_ref,
                type: "input",
                multiline: false,
            },
            date_of_service: {
                label: "Date of Service",
                value: submittedCoachForm?.date_of_service,
                type: "date",
                multiline: false,
            },
            provider: {
                label: "Provider Name and Number",
                value: submittedCoachForm?.provider,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Description of The OPS Loss",
            description: {
                label: "Description of The OPS Loss",
                value: submittedCoachForm?.description,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Submission Frequency For What Happened",
            submission_frequency: {
                label: "Submission Frequency For What Happened",
                value: "",
                type: "text",
                multiline: true,
            },
        },
        {
            title: "Why Did This Happen?",
            reason: {
                label: "Why Did This Happen",
                value: submittedCoachForm?.reason,
                type: "autocomplete",
                multiline: false,
            },
        },
        {
            title: "Reason Why It Happened",
            reasons_why_it_happened: {
                label: "Reason Why It Happened",
                value: submittedCoachForm?.reasons_why_it_happened,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Options",
            options: {
                label: "",
                value: submittedCoachForm?.options,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const dpmo = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            expectation: {
                label: "Expectation",
                value: submittedCoachForm?.expectation,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Details of Defect(s)",
            membership_no: {
                label: "Membership Number",
                value: submittedCoachForm?.membership_no,
                type: "input",
                multiline: false,
            },
            pid: {
                label: "Call Reference Or PID",
                value: submittedCoachForm?.pid,
                type: "input",
                multiline: false,
            },
            details: {
                label: "Details of Defect(s)",
                value: submittedCoachForm?.details,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "Submission Frequency For What Happened",
            submission_frequency: {
                label: "Submission Frequency For What Happened",
                value: "",
                type: "text",
                multiline: true,
            },
        },
        {
            title: "Why Did This Happen?",
            reason: {
                label: "Why Did This Happen",
                value: submittedCoachForm?.reason,
                type: "autocomplete",
                multiline: false,
            },
        },
        {
            title: "Reason Why It Happened",
            reasons_why_it_happened: {
                label: "Reason Why It Happened",
                value: submittedCoachForm?.reasons_why_it_happened,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Options",
            options: {
                label: "",
                value: submittedCoachForm?.options,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const perception = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            expectation: {
                label: "Expectation",
                value: submittedCoachForm?.expectation,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Details of Poor Score",
            response_pid: {
                label: "Response PID",
                value: submittedCoachForm?.response_pid,
                type: "input",
                multiline: false,
            },
            membership_no: {
                label: "Membership Number",
                value: submittedCoachForm?.membership_no,
                type: "input",
                multiline: false,
            },
            pid: {
                label: "Call Reference Or PID",
                value: submittedCoachForm?.pid,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "Submission Frequency For What Happened",
            submission_frequency: {
                label: "Submission Frequency For What Happened",
                value: "",
                type: "text",
                multiline: true,
            },
        },
        {
            title: "Why Did This Happen?",
            reason: {
                label: "Why Did This Happen",
                value: submittedCoachForm?.reason,
                type: "autocomplete",
                multiline: false,
            },
        },
        {
            title: "Reason Why It Happened",
            reasons_why_it_happened: {
                label: "Reason Why It Happened",
                value: submittedCoachForm?.reasons_why_it_happened,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Options",
            options: {
                label: "",
                value: submittedCoachForm?.options,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const grow_coaching = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
        },
        {
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "metricTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "My Reality",
            how_am_i_feeling_about_my_performance: {
                label: "How are you feeling about your performance?",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_my_performance_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance_comment,
                type: "input",
                multiline: false,
            },
            how_am_i_feeling_about_work: {
                label: "How are you feeling about work?",
                value: submittedCoachForm?.how_am_i_feeling_about_work,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_work_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_work_comment,
                type: "input",
                multiline: false,
            },
            how_is_your_mental_wellbeing: {
                label: "How is your mental wellbeing?",
                value: submittedCoachForm?.how_is_your_mental_wellbeing,
                type: "emojiface",
                multiline: false,
            },
            how_is_your_mental_wellbeing_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_is_your_mental_wellbeing_comment,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "My Performance Options",
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "metricRatingTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const pip_initiation = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            expectation: {
                label: "Expectation",
                value: submittedCoachForm?.expectation,
                type: "input",
                multiline: false,
            },
            support_info: {
                label: "Support",
                value: submittedCoachForm?.support_info,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },
        {
            metric_table: {
                label: "",
                value: "",
                type: "table",
                tableType: "toImproveTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "My Reality",
            how_am_i_feeling_about_my_performance: {
                label: "How I am feeling about my performance improving/declining",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_my_performance_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance_comment,
                type: "input",
                multiline: false,
            },
            how_am_i_feeling_about_how_pip_is_going: {
                label: "How I am feeling about how PIP is going",
                value: submittedCoachForm?.how_am_i_feeling_about_how_pip_is_going,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_how_pip_is_going_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_how_pip_is_going_comment,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "My Performance Options",
            metric_table: {
                label: "",
                value: "",
                type: "table",
                tableType: "pipPerformanceTable",
                multiline: true,
                optional: true,
            },
        },
        {
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "pipOptionsTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const pip_follow_up = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Goals",
                value: submittedCoachForm?.goals,
                type: "input",
                multiline: false,
            },
            expectation: {
                label: "Expectation",
                value: submittedCoachForm?.expectation,
                type: "input",
                multiline: false,
            },
            support_info: {
                label: "Support",
                value: submittedCoachForm?.support_info,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
        },
        {
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "metricReviewTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "My Reality",
            how_am_i_feeling_about_my_performance: {
                label: "How I am feeling about my performance improving/declining",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_my_performance_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_my_performance_comment,
                type: "input",
                multiline: false,
            },
            how_am_i_feeling_about_how_pip_is_going: {
                label: "How I am feeling about how PIP is going",
                value: submittedCoachForm?.how_am_i_feeling_about_how_pip_is_going,
                type: "emojiface",
                multiline: false,
            },
            how_am_i_feeling_about_how_pip_is_going_comment: {
                label: "Comment",
                value: submittedCoachForm?.how_am_i_feeling_about_how_pip_is_going_comment,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "My Performance Options",
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "pipPerformanceTable",
                multiline: true,
                optional: true,
            },
        },
        {
            metric_table: {
                label: "",
                value: submittedCoachForm,
                type: "table",
                tableType: "pipOptionsTable",
                multiline: true,
                optional: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const written_warning = [
        {
            title: "Attendees",
            attendees: {
                label: "Attendees",
                value: submittedCoachForm?.attendees,
                type: "array",
                multiline: true,
            },
        },

        {
            topic: {
                label: "Reason For Warning",
                value: submittedCoachForm?.reason_for_warning,
                type: "input",
                multiline: false,
            },
            goals: {
                label: "Your Reality",
                value: submittedCoachForm?.your_reality,
                type: "input",
                multiline: false,
            },
            business_reality: {
                label: "Business Reality",
                value: submittedCoachForm?.business_reality,
                type: "input",
                multiline: false,
            },
            support_info: {
                label: "Support",
                value: submittedCoachForm?.support_info,
                type: "input",
                multiline: false,
            },
            details: {
                label: "Details of Sanction",
                value: submittedCoachForm?.details,
                type: "input",
                multiline: false,
            },
        },
    ];

    const expectation = [
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            coaching: {
                label: "Coaching",
                value: submittedCoachForm?.coaching,
                type: "input",
                multiline: false,
            },
            performance: {
                label: "Performance",
                value: submittedCoachForm?.performance,
                type: "input",
                multiline: false,
            },
            late_coming: {
                label: "Late Coming",
                value: submittedCoachForm?.late_coming,
                type: "input",
                multiline: false,
            },
            absenteeism: {
                label: "Absenteeism",
                value: submittedCoachForm?.absenteeism,
                type: "input",
                multiline: false,
            },
            team_responsibilities: {
                label: "Team Responsibilities",
                value: submittedCoachForm?.team_responsibilities,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Are You Clear On What The Expectations Are? ",
            expectations_clear: {
                label: "",
                value: submittedCoachForm?.expectations_clear,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "Do You Have The Resources You Need To Meet Those Expectations? ",
            resources_to_meet_expectations: {
                label: "",
                value: submittedCoachForm?.resources_to_meet_expectations,
                type: "input",
                multiline: true,
            },
        },
        {
            title: "I will need these resources & support",
            resource_support: {
                label: "",
                value: submittedCoachForm?.resource_support,
                type: "input",
                multiline: true,
            },
        },
    ];

    const handover = [
        {
            handover_date: {
                label: "Effective Handover Date",
                value: submittedCoachForm?.handover_date,
                type: "date",
                multiline: false,
            },
            new_team_leader: {
                label: "New Team Leader",
                value: submittedCoachForm?.new_team_leader,
                type: "input",
                multiline: false,
            },
            new_team_leader_email: {
                label: "New Team Leader Email Address",
                value: submittedCoachForm?.new_team_leader_email,
                type: "input",
                multiline: false,
            },
        },
        {
            topic: {
                label: "Topic",
                value: submittedCoachForm?.topic,
                type: "input",
                multiline: false,
            },
            aaa: {
                label: "AAA's",
                value: submittedCoachForm?.aaa,
                type: "input",
                multiline: false,
            },
            performance: {
                label: "Performance",
                value: submittedCoachForm?.performance,
                type: "input",
                multiline: false,
            },
            idp: {
                label: "IDP",
                value: submittedCoachForm?.idp,
                type: "input",
                multiline: false,
            },
            ir_concerns: {
                label: "IR Concerns",
                value: submittedCoachForm?.ir_concerns,
                type: "input",
                multiline: false,
            },
        },
        {
            title: "Tracking and Trending",
            hasGraph: {
                showGraph: true,
            },
        },
        {
            title: "Additional Comments ",
            additional_comments: {
                label: "",
                value: submittedCoachForm?.additional_comments,
                type: "input",
                multiline: true,
            },
        },
    ];

    return {
        absenteeism,
        adhoc,
        dpmo,
        expectation,
        grow_coaching,
        handover,
        ops_losses,
        perception,
        pip_follow_up,
        pip_initiation,
        written_warning,
    };
};

export default AllSubmittedCoachingForms;
