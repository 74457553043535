import { Query, QueryResult } from "material-table";
import { httpClient } from "../../../../clients/http.client";
import { convertQuery } from "../../../../misc/helpers";
import {
  c_ProductionPeriod,
  t_ProductionPeriod,
  t_ProductionPeriodTypes,
} from "./ProductionPeriods.types";

const updateProductionPeriods = (payload: {
  id?: number;
  start_date: string;
  end_date: string;
  last_capture: string;
  month: string;
  all_incentive_categories: boolean;
  incentive_categories: {
    id: number;
    name: string;
  }[];
}) => {
  const body = {
    ...payload,
    all_incentive_categories: payload.all_incentive_categories ?? false,
    incentive_category_ids: payload.incentive_categories.map((cat) => cat.id),
  };
  return new Promise((resolve, reject) => {
    httpClient
      .post("/admin/productionperiods/save", body)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve(true);
            break;
          default:
            reject(data.message);
        }
      })
      .catch((err) => reject(err));
  });
};

const deleteProductionPeriod = (payload: { id: number }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("/admin/productionperiods/delete", {
        period_id: payload.id,
      })
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve(true);
            break;
          default:
            reject(data.message);
        }
      })
      .catch((error) => reject(error));
  });
};

const getSurveyPeriods = (payload: {
  query: Query<t_ProductionPeriod>;
  year?: string;
}) => {
  return new Promise<QueryResult<t_ProductionPeriod>>((resolve, reject) => {
    let query = convertQuery(payload.query);

    if (query.sort != null && query.sort.column_name in c_ProductionPeriod) {
      query.sort.column_name = c_ProductionPeriod[query.sort.column_name];
    }

    var body = {
      ...query,
      year: payload.year,
    };
    httpClient
      .post("/admin/periods", body)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve({
              data: data.data.periods,
              page: data.pagination.page - 1,
              totalCount: data.total_results,
            });
            break;
          default:
            reject(data.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSurveyPeriodTypes = () => {
  return new Promise<t_ProductionPeriodTypes[]>((resolve, reject) => {
    httpClient
      .post("/admin/periods/types")
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve(data.data.types);
            break;
          default:
            reject(data.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const saveSurveyPeriods = (payload: { period: t_ProductionPeriod }) => {
  return new Promise((resolve, reject) => {
    const body = {
      ...payload.period,
      survey_type_id: payload.period.survey_type?.id,
    };
    httpClient
      .post("/admin/periods/save", body)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve(true);
            break;
          default:
            reject(data.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteSurveyPeriods = (payload: {
  period_id: t_ProductionPeriod["id"];
}) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("/admin/periods/delete", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            resolve(true);
            break;
          default:
            reject(data.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export {
  getSurveyPeriods,
  getSurveyPeriodTypes,
  saveSurveyPeriods,
  deleteSurveyPeriods,
  updateProductionPeriods,
  deleteProductionPeriod,
};
