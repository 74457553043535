import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    Switch,
    TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getIncentiveCategories } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.api";
import { t_IncentiveType } from "../../../Metric/sections/MaintainIncentive/MaintainIncentive.types";
import { base_InitiativeOption, t_InitiativeOption } from "../../tables/InitiativeOptions/InitiativeOptions.types";
import { updateInitiativeOption } from "./InitiativeOption.api";

const initiativeOptionSchema: yup.SchemaOf<t_InitiativeOption> = yup
    .object({
        id: yup.number(),
        weight: yup.number(),
        comment: yup.string(),
        active: yup.bool(),
        /** @MM - Required */
        all_incentive_categories: yup.boolean().required(),
        name: yup
            .string()
            .min(4, "Name must have more than 4 characters.")
            .defined("Please enter the initiative's name."),
        handling_time: yup
            .number()
            .min(-60, "Cannot be less than -60.")
            .max(60, "Cannot be greater than 60")
            .required("Please enter the number of minutes."),
        incentive_categories: yup.array().when("all_incentive_categories", {
            is: false,
            then: yup.array().min(1, "Please select at least 1 option.").required("Please select an option."),
        }),
    })
    .defined();

type t_InitiativeOptionFormActions = "submitted" | "cancel";

const InitiativeOptionForm: React.FC<{
    formData: t_InitiativeOption;
    dispatch: (action: t_InitiativeOptionFormActions) => void;
}> = ({ formData, dispatch }) => {
    const formik = useFormik({
        initialValues: formData,
        validationSchema: initiativeOptionSchema,
        onSubmit: (value) =>
            new Promise((resolve, reject) => {
                updateInitiativeOption({ option: value })
                    .then(
                        () => {
                            dispatch("submitted");
                            resolve(true);
                        },
                        (error) => {
                            reject(error);
                        },
                    )
                    .catch(reject);
            }),
    });
    const [visualFeedback, setVisualFeedback] = React.useState<{
        categories_loading?: boolean;
    }>({});
    const [incentiveCategories, setIncentiveCategories] = React.useState<t_IncentiveType[]>([]);
    const [search, setSearch] = React.useState<string>("");

    React.useEffect(() => {
        var isActive = true;
        setVisualFeedback((state) => ({ ...state, categories_loading: true }));
        getIncentiveCategories({
            pagination: {
                page: 1,
                rows_per_page: 30,
            },
            filter: [
                {
                    column_name: "Name",
                    search_term: search,
                },
            ],
        }).then((data) => {
            if (isActive) {
                setIncentiveCategories(data);
                setVisualFeedback((state) => ({
                    ...state,
                    categories_loading: false,
                }));
            }
        });
        return () => {
            isActive = false;
        };
    }, [search]);

    React.useEffect(() => {
        formik.setValues(formData);
    }, [formData]);

    return (
        <List>
            <ListItem>
                <TextField
                    name="name"
                    label="Initiative name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    required
                />
            </ListItem>
            <ListItem>
                <TextField
                    name="handling_time"
                    label="Expected handling time"
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">min</InputAdornment>,
                    }}
                    value={formik.values.handling_time}
                    onChange={formik.handleChange}
                    error={formik.touched.handling_time && Boolean(formik.errors.handling_time)}
                    helperText={formik.touched.handling_time && formik.errors.handling_time}
                    required
                />
            </ListItem>
            <ListItem>
                <FormControl
                    component="fieldset"
                    required
                    error={formik.touched.all_incentive_categories && Boolean(formik.errors.all_incentive_categories)}
                >
                    <FormHelperText>For Incentive Categories*:</FormHelperText>
                    <FormControlLabel
                        control={
                            <Switch
                                name="all_incentive_categories"
                                checked={formik.values.all_incentive_categories}
                                onChange={formik.handleChange}
                            />
                        }
                        label="All Categories?"
                    />
                    <FormHelperText>
                        {formik.touched.all_incentive_categories && formik.errors.all_incentive_categories}
                    </FormHelperText>
                </FormControl>
            </ListItem>
            {!formik.values.all_incentive_categories && (
                <ListItem>
                    <FormControl
                        required
                        error={formik.touched.incentive_categories && Boolean(formik.errors.incentive_categories)}
                    >
                        <FormHelperText>Select Incentive Categories*</FormHelperText>
                        <Autocomplete
                            multiple
                            size="small"
                            id="incentive_categories"
                            value={formik.values.incentive_categories}
                            onChange={(_, value) => formik.setFieldValue("incentive_categories", value)}
                            onBlur={() =>
                                formik.setTouched({
                                    incentive_categories: true,
                                })
                            }
                            onInputChange={(_, value) => setSearch(value)}
                            options={incentiveCategories}
                            loading={visualFeedback.categories_loading}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            style={{ width: 500 }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <FormHelperText>
                            {formik.touched.incentive_categories && formik.errors.incentive_categories}
                        </FormHelperText>
                    </FormControl>
                </ListItem>
            )}
            <ListItem>
                <FormControl>
                    <FormHelperText>Comment</FormHelperText>
                    <TextField
                        id="initiative_description"
                        name="comment"
                        variant="outlined"
                        multiline
                        rows={5}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                    />
                </FormControl>
            </ListItem>
            <ListItem>
                <Button onClick={() => dispatch("cancel")}>Cancel</Button>
                <SubmitButton onClick={formik.submitForm} disabled={formik.isSubmitting} loading={formik.isSubmitting}>
                    {Boolean(formData.id) ? "Save Initiative" : "Add Initiative"}
                </SubmitButton>
            </ListItem>
        </List>
    );
};

interface Props {
    option?: t_InitiativeOption;
    onClose: (reason?: string) => void;
}

const InitiativeOption: React.FC<Props> = ({ option, onClose }) => {
    const handleFormEvent = (action: t_InitiativeOptionFormActions) => {
        onClose(action);
    };

    return (
        <SectionCard
            title={`${option ? "Update" : "Create New"} Initiative`}
            titleProps={{
                variant: "h6",
            }}
            secondaryAction={
                <IconButton onClick={() => onClose()} size="large">
                    <Close />
                </IconButton>
            }
        >
            <InitiativeOptionForm formData={option ?? base_InitiativeOption} dispatch={handleFormEvent} />
        </SectionCard>
    );
};

export default InitiativeOption;
