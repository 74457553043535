import { CircularProgress, useTheme } from "@mui/material";

import "date-fns";
import React, { useState } from "react";
import { RequestQueue } from "../../context/notifications/requestQueue.ctx";
import { useUserState } from "../../context/user/user.ctx";
import Routes from "../../navigation/routes";
import Navigation from "../../sections/Navigation";
import homeStyles from "./Home.styles";
import Routing from "../../navigation/routes";
import { NotificationsContext } from "../../components/Toasts/NotificationsProvider.ctx";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { setBeYouWalkThrough } from "../../redux/slices/WalkThroughSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useClearNotificationsMutation } from "../../redux/api/beYouCore";
const Home: React.FC = () => {
    const classes = homeStyles();
    const theme = useTheme();
    const userProfile = useUserState();
    const [enabled, setEnabled] = React.useState(false);
    const [initialStep, setInitialStep] = React.useState(0);
    const { setSidePanelChatBot, setSideBarTab, isOnBoardingOpen, setIsOnBoarding, setIsSideTabWalkthroughFirstTab } =
        React.useContext(NotificationsContext);
    const drawerRef = React.createRef<HTMLDivElement>();
    const appBarRef = React.createRef<HTMLDivElement>();
    const dispatch = useDispatch();
    const [offset, setOffset] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const promptID = useSelector((state: RootState) => state.metrics.promptId);
    const [clearNotifications] = useClearNotificationsMutation();

    React.useEffect(() => {
        if (drawerRef?.current != null) {
            setOffset(drawerRef.current.offsetWidth);
        }
    }, [drawerRef]);

    React.useEffect(() => {
        if (appBarRef?.current != null) {
            setHeight(appBarRef.current.offsetHeight);
        }
    }, [appBarRef]);

    React.useEffect(() => {
        let isActive = true;
        let timer: NodeJS.Timeout;

        if (userProfile.status.loading === true) {
            setLoading(true);
            timer = setTimeout(() => {
                if (isActive) {
                    setLoading(false);
                }
            }, 1500);

            // executed when transitioning from true -> false
            return;
        } else {
            // executed when transitioning from false -> true;
            return () => {
                isActive = false;
                if (timer != null) {
                    clearTimeout(timer);
                }
            };
        }
    }, [userProfile.status.loading]);

    const removeNotification = (id: number) => {
        if (id) {
            clearNotifications({ notification_id: id })
                .unwrap()
                .then((response) => {})
                .catch((error) => {
                    console.error("🚀 ~ file: OnBoarding.tsx:164 ~ .then ~ error:", error);
                });
        }
    };

    const steps = [
        {
            element: ".beYouCmoach",
            title: "Welcome to your onboarding process",
            intro: "By clicking the next button, you can go through the processes and procedures on BeYou Coach.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".profile-card",
            title: "Profile Section",
            intro: "This section contains information about you, such as your bio, wins and losses.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".metric-rankings",
            title: "Metrics Ranking ",
            intro: "This section contains your team members overall ranking, as well as ranking by metrics.",
            position: "left",
            disableInteraction: true,
        },
        {
            element: ".filter-metric-rankings",
            title: "Metrics Ranking ",
            intro: "You can filter the metrics by clicking this dropdown.",
            position: "left",
            disableInteraction: true,
        },
        {
            element: ".strengths-area",
            title: "Strengths Area",
            intro: "This section shows you which metrics you are performing well in.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".focus-area",
            title: "Focus Area",
            intro: "This section shows you which metrics need extra attention.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".badges-area",
            title: "Badges Section",
            intro: "This section shows you the badges that you have been awarded, either by BeYou Coach or by a user.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".badges-area-view-all",
            title: "View All Badges",
            intro: "To view all your badges, click this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".detailed-break-down-area",
            title: "Detailed Breakdown",
            intro: "This section contains your metric insights, which indicates how far you are from your target, as well as the percent of change from the previous month.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".detailed-break-down-area-view-insights",
            title: "View Insights",
            intro: "To view all metric insights as well as your metric reports, click this button.",
            position: "right",
            disableInteraction: true,
        },
        {
            element: ".toggle-dark-mode",
            title: "Theme Change",
            intro: "By clicking this toggle, you can switch between light and dark mode.",
            position: "right",
            disableInteraction: true,
        },
    ];

    const introOptions = {
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true,
        hideNext: false,
        doneLabel: "NEXT",
        keyboardNavigation: false,
        exitOnOverlayClick: false,
    };

    return (
        <>
            <div className={classes.root}>
                <RequestQueue />
                <nav>
                    <Navigation drawerRef={drawerRef} appBarRef={appBarRef} />
                </nav>
                <div
                    style={{
                        marginLeft: offset,
                        height: `calc(100% - ${height}px)`,
                        padding: theme.spacing(1),
                        overflow: "auto",
                    }}
                >
                    {loading && (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )}
                    <Routing />
                </div>
            </div>
            <Steps
                enabled={isOnBoardingOpen}
                steps={steps}
                options={introOptions}
                initialStep={initialStep}
                onComplete={() => {
                    setIsOnBoarding(false);
                    setSideBarTab(0);
                    setSidePanelChatBot(true);
                    setTimeout(() => {
                        setIsSideTabWalkthroughFirstTab(true);
                    }, 500);
                }}
                onExit={() => {
                    setIsOnBoarding(false);
                    dispatch(setBeYouWalkThrough(false));
                    removeNotification(promptID);
                }}
            />
        </>
    );
};

export default Home;
