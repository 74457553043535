import { httpClient } from "../../clients/http.client";
import { t_TeamMemberPool, t_TeamPool } from "./TwtGroup.types";

const getTeamPools = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/admin/twt/team/getteampool", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const respData = data.data.records.data;
            res({
              data: respData,
              page: data.pagination.page - 1,
              totalCount: data.total_results,
            });
            break;
          case "error":
            rej(data.message);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const getTeamMembers = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/users/team_members", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const retData = data.data.team_members;
            res(retData);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const getTeamMemberPools = (payload: any) => {
  return new Promise<any>((res, rej) => {
    httpClient
      .post("/admin/twt/team/getindividualteampool", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            const respData = data.data.records.data;
            res({
              data: respData,
              page: data.pagination.page - 1,
              totalCount: data.data.records.total,
            });
            break;
          case "error":
            rej(data.message);
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const addPoolToTeam = (payload: any) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/team/addtoindividualteampool", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res();
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

const removeTeamPool = (payload: any) => {
  return new Promise<void>((res, rej) => {
    httpClient
      .post("/admin/twt/team/deleteindividualteampools", payload)
      .then(({ data }) => {
        switch (data.status) {
          case "success":
            res();
            break;
          default:
            rej(data.message);
        }
      })
      .catch((err) => rej(err.message));
  });
};

export {
  getTeamPools,
  getTeamMemberPools,
  getTeamMembers,
  addPoolToTeam,
  removeTeamPool,
};
