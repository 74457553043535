import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { t_StreamData } from "../../TwtGroup.types";
import { saveStreamName } from "../../twtStreams.api";
import muiStyles from "./EditStream.styles";

interface EditStreamProps {
    handleClose: () => void;
    group: t_StreamData;
    tableRef: any;
}

const EditStream = ({ handleClose, group, tableRef }: EditStreamProps) => {
    const [name, setName] = React.useState<any>(group);
    const [loadingName, setLoadingName] = React.useState(false);

    const muiStyle = muiStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onUpdateStreamName = () => {
        if (name.Name.length === 0) {
            return;
        }

        setLoadingName(true);
        saveStreamName({ id: name.id, name: name.Name }).then(() => {
            enqueueSnackbar("Stream updated successfully", {
                className: muiStyle.snack,
            });
            setLoadingName(false);
        });
    };

    return (
        <SectionCard
            title={`Edit Name`}
            titleProps={{
                variant: "h6",
            }}
            className={muiStyle.modalContainer}
            fullWidth
            secondaryAction={
                <IconButton onClick={handleClose} size="large">
                    <Close />
                </IconButton>
            }
        >
            <Box>
                <Box display="grid" gridTemplateColumns="2fr 4fr" padding="1em">
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography>Name</Typography>
                    </Box>
                    <TextField
                        value={name ? name.Name : ""}
                        onChange={(e) =>
                            setName({
                                ...name,
                                Name: e.target.value,
                            })
                        }
                        style={{
                            padding: "2em",
                            width: "clamp(30em, 300px, 40em)",
                        }}
                        error={name.Name.length === 0}
                        helperText={!name.Name.length ? "name is required" : ""}
                    />
                </Box>
                <Box width="100%" textAlign="center" margin="1em 0.5em">
                    <SubmitButton
                        variant="outlined"
                        onClick={onUpdateStreamName}
                        loading={loadingName}
                        disabled={loadingName}
                    >
                        Save Stream Details
                    </SubmitButton>
                </Box>
            </Box>
        </SectionCard>
    );
};

export default EditStream;
