import React from "react";

export interface Props {
    maskID: string;
}

const makeColor = (name: string, color: string) => {
    const ColorComponent: React.FC<Props> = ({ maskID }) => (
        <g id={`Skin/${name}`} mask={`url(#${maskID})`} fill={color}>
            <g transform="translate(0.000000, 0.000000)" id="Color">
                <rect x="0" y="0" width="264" height="280" />
            </g>
        </g>
    );

    return ColorComponent;
};

const Auburn = makeColor("Auburn", "#A55728");
const Black = makeColor("Black", "#2C1B18");
const Blonde = makeColor("Blonde", "#B58143");
const BlondeGolden = makeColor("BlondeGolden", "#D6B370");
const Brown = makeColor("Brown", "#724133");
const BrownDark = makeColor("BrownDark", "#4A312C");
const PastelPink = makeColor("PastelPink", "#F59797");
const Blue = makeColor("Blue", "#000fdb");
const Platinum = makeColor("Platinum", "#ECDCBF");
const Red = makeColor("Red", "#C93305");
const SilverGray = makeColor("SilverGray", "#E8E1E1");

// Define a type for the hair color options
export type HairColorOptionType =
    | "Auburn"
    | "Black"
    | "Blonde"
    | "BlondeGolden"
    | "Brown"
    | "BrownDark"
    | "PastelPink"
    | "Blue"
    | "Platinum"
    | "Red"
    | "SilverGray"
    | "default";

// Mapping object for color components
const colorComponentsMap: Record<HairColorOptionType, React.ComponentType<Props>> = {
    Auburn,
    Black,
    Blonde,
    BlondeGolden,
    Brown,
    BrownDark,
    PastelPink,
    Blue,
    Platinum,
    Red,
    SilverGray,
    default: Black,
};

interface HairColorProps {
    maskID: string;
    hairColor?: HairColorOptionType;
}

const HairColor: React.FC<HairColorProps> = ({ maskID, hairColor = "default" }) => {
    const SelectedColor = colorComponentsMap[hairColor] || colorComponentsMap["default"];
    return <SelectedColor maskID={maskID} />;
};

export default HairColor;
