import { Description, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Backdrop, Box, Button, Dialog, Menu, MenuItem, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

import SectionCard from "../../components/SectionCard/SectionCard";
import { usePerformanceContext } from "../Performance/Performance.ctx";
import NegativeOvationNoteModal from "./NegativeOvationNoteModal";
import OvationReport from "./OvationReport";

const NegativeOvation = () => {
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(month));
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [negativeReport, setNegativeReport] = useState<"FCR" | "MBR">("FCR");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReportSelect = (report: "FCR" | "MBR") => {
        setNegativeReport(report);
        handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const reportOptions = [
        {
            id: 1,
            name: "MBR",
        },
        {
            id: 2,
            name: "FCR",
        },
    ];

    return (
        <SectionCard
            title="Team Development"
            scrolling
            fullWidth
            primaryAction={
                <Box>
                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? "demo-customized-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownOutlined />}
                    >
                        {negativeReport}
                    </Button>
                    <Menu
                        elevation={0}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                    >
                        {reportOptions.map((report: any, index: number) => (
                            <MenuItem onClick={() => handleReportSelect(report.name)} key={`${report.id}-${index}`}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            }
            secondaryAction={
                <>
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={new Date(month)}
                        onChange={handleDateChange}
                        closeOnSelect={false}
                        className="date-picker-beYouCoach"
                        onOpen={() => setIsDateOpen(true)}
                        onClose={() => {
                            performanceDispatch({
                                type: "update_dates",
                                month: selectedDate,
                            });
                            setIsDateOpen(false);
                        }}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                    <Backdrop sx={{ zIndex: 2 }} open={isDateOpen}></Backdrop>
                </>
            }
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginY: 1,
                    padding: 2,
                }}
            >
                <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                    <Description sx={{ color: theme.palette.info.main }} /> Note
                </Button>
            </Box>
            <OvationReport reportOption={negativeReport} />
            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <NegativeOvationNoteModal onclick={() => setIsModalOpen(false)} />
            </Dialog>
        </SectionCard>
    );
};

export default NegativeOvation;
