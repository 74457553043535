import { ReactNode } from "react";

import Angry from "./Angry";
import AngryNatural from "./AngryNatural";
import Default from "./Default";
import DefaultNatural from "./DefaultNatural";
import FlatNatural from "./FlatNatural";
import RaisedExcited from "./RaisedExcited";
import RaisedExcitedNatural from "./RaisedExcitedNatural";
import SadConcerned from "./SadConcerned";
import SadConcernedNatural from "./SadConcernedNatural";
import UnibrowNatural from "./UnibrowNatural";
import UpDown from "./UpDown";
import UpDownNatural from "./UpDownNatural";

export type EyebrowOption =
    | "Angry"
    | "AngryNatural"
    | "DefaultNatural"
    | "FlatNatural"
    | "RaisedExcited"
    | "RaisedExcitedNatural"
    | "SadConcerned"
    | "SadConcernedNatural"
    | "UnibrowNatural"
    | "UpDown"
    | "UpDownNatural"
    | "default";

// Map the option names to their corresponding components
const componentsMap: Record<EyebrowOption, React.ComponentType<{ children?: ReactNode }>> = {
    Angry: Angry,
    AngryNatural: AngryNatural,
    DefaultNatural: DefaultNatural,
    FlatNatural: FlatNatural,
    RaisedExcited: RaisedExcited,
    RaisedExcitedNatural: RaisedExcitedNatural,
    SadConcerned: SadConcerned,
    SadConcernedNatural: SadConcernedNatural,
    UnibrowNatural: UnibrowNatural,
    UpDown: UpDown,
    UpDownNatural: UpDownNatural,
    default: Default,
};

interface EyebrowProps {
    option: EyebrowOption;
    children?: ReactNode;
}

const Eyebrow = ({ option = "default", children }: EyebrowProps) => {
    const SelectedComponent = componentsMap[option] || componentsMap["default"];
    return <SelectedComponent>{children}</SelectedComponent>;
};

export default Eyebrow;
