import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getImageUrl } from "../../misc/helpers";
import { RootState } from "../../redux/store";

const OvationAvatarSvg = () => {
    const [svgContent, setSvgContent] = useState<string>("");
    const user = useSelector((state: RootState) => state.user.userInfo);

    useEffect(() => {
        fetch(getImageUrl("profile", user.profile_img) || "")
            .then((response) => response.text())
            .then((data) => {
                setSvgContent(data);
            })
            .catch((error) => {
                console.error("Error fetching the SVG:", error);
            });
    }, [user.profile_img]);

    return <div dangerouslySetInnerHTML={{ __html: svgContent }} style={{ width: "100px", height: "100px" }} />;
};

export default OvationAvatarSvg;
