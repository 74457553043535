import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SectionCard from "../../../components/SectionCard/SectionCard";
import { useUserState } from "../../../context/user/user.ctx";
import useCoachFormData from "../../../hooks/useCoachFormData/useCoachFormData";
import { RootState } from "../../../redux/store";
import AllCoachingForms from "../../../sections/CoachingForm/AllCoachingForms";
import CoachingAuditForm from "../../../sections/CoachingForm/CoachingAuditForm";
import FormBuilder from "../../../sections/CoachingForm/FormBuilder";
import { formsList } from "../../../sections/CoachingForm/FormsList";
import { getUserDetails } from "../../../sections/MaintainUser/UserDetails/UserDetails.api";
import { usePerformanceContext } from "../../../sections/Performance/Performance.ctx";

interface DetailedUser {
    entity_no: string;
    name: string;
    job_title: string;
    profile_img: string;
    region: string;
    job_level: string;
    team: string;
    incentive_category: string;
    skill: string;
    division: string;
    company: string;
    manager: string;
    service_team: string;
    agent_skill: string;
    department: string;
    discovery_team: string;
    cost_center: string;
    roles: string[];
}

type FormType =
    | "dpmo"
    | "adhoc"
    | "absence"
    | "perception"
    | "grow"
    | "pip_initiation"
    | "pip_follow_up"
    | "written_warning"
    | "expectations"
    | "handover"
    | "ops_loss";

const dropdownlist = ["TWT", "Adherence", "One on One", "Check In", "Escalated Discussion", "ADHOC"];

const CoachingForms = () => {
    const currentUser = useUserState();
    const theme = useTheme();
    const entity_no = currentUser.entity_no;
    const [{ month }, performanceDispatch] = usePerformanceContext();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [formFields, setFormFields] = useState<any>([]);
    const [formInformation, setformInformation] = useState({});
    const [selectedMember, setSelectedMember] = useState("");
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [userDetails, setUserDetails] = useState<DetailedUser | null>();
    const [formType, setFormType] = useState<FormType | "">("");
    const team = useSelector((state: RootState) => state.teamMembers.allTeamMembers);
    const { isError, isLoading, isFetching, fetchError, requiredFields } = useCoachFormData({
        formType,
        entity_no: userDetails?.entity_no!,
        month,
    });
    const [metricID, setMetricID] = useState<number | null>(7);

    const coachingForms = AllCoachingForms({
        formType,
        entity_no: userDetails?.entity_no!,
        month,
    });
    const pageTitle = formsList.filter((form) => form.type == formType);
    const filteredFormsList = formsList.filter((form) => form.id != 0);

    const getInitials = (sentence: string) => {
        if (sentence != "") {
            let initials = "";
            const words = sentence?.split(" ");

            for (let i = 0; i < words?.length; i++) {
                initials += words[i].charAt(0).toUpperCase();
            }

            return initials;
        }
    };

    const businessUnitInitials = getInitials(userDetails?.company ?? "");
    const regionInitials = userDetails?.region ?? "";
    const jobTitleInitials = getInitials(userDetails?.job_title ?? "");
    const lineManagerInitials = getInitials(userDetails?.manager ?? "");
    const agentNameInitials = getInitials(userDetails?.name ?? "");

    const formSubject = `${businessUnitInitials} - ${regionInitials} - ${jobTitleInitials} - ${lineManagerInitials} - ${pageTitle[0]?.label} - ${agentNameInitials} -  ${moment(selectedDate).format("DD MMMM YYYY")}`;

    useEffect(() => {
        if (selectedMember) {
            getUserDetails({ entity_no: Number(selectedMember) }).then((data) => {
                setUserDetails(data);
            });
        }
    }, [selectedMember]);

    useEffect(() => {
        if (coachingForms) {
            const {
                absenteeism,
                adhoc,
                dpmo,
                expectation,
                grow_coaching,
                handover,
                ops_losses,
                perception,
                pip_follow_up,
                pip_initiation,
                written_warning,
            } = coachingForms;

            switch (formType) {
                case "dpmo":
                    setMetricID(2);
                    setFormFields(dpmo);
                    break;
                case "adhoc":
                    setFormFields(adhoc);
                    break;
                case "absence":
                    setMetricID(7);
                    setFormFields(absenteeism);
                    break;
                case "perception":
                    setMetricID(3);
                    setFormFields(perception);
                    break;
                case "grow":
                    setMetricID(7);
                    setFormFields(grow_coaching);
                    break;
                case "pip_initiation":
                    setMetricID(7);
                    setFormFields(pip_initiation);
                    break;
                case "pip_follow_up":
                    setMetricID(7);
                    setFormFields(pip_follow_up);
                    break;
                case "written_warning":
                    setFormFields(written_warning);
                    break;
                case "expectations":
                    setFormFields(expectation);
                    break;
                case "handover":
                    setMetricID(7);
                    setFormFields(handover);
                    break;
                case "ops_loss":
                    setFormFields(ops_losses);
                    break;

                default:
                    setFormFields([]);
            }
        } else {
            setFormFields([]);
        }
    }, [formType, requiredFields]);

    useEffect(() => {
        setformInformation({
            formData: formFields,
            formUserDetails: {
                name: userDetails?.name,
                job_title: userDetails?.job_title,
                subject: formSubject,
                region: userDetails?.region,
            },
            entity_no,
            month,
            metricID,
            optionsList: requiredFields?.keywords,
            selectedMember,
            requiredFields,
            formType,
            dropdownlist,
        });
    }, [formFields, requiredFields, selectedMember, userDetails, entity_no, month]);

    const handleDateChange = (date: any) => {
        if (date !== null) {
            setSelectedDate(date);
        }
    };

    const formSelectChange = (event: SelectChangeEvent) => {
        setFormType(event.target.value as FormType);
    };

    const handleTeamMemberChange = (event: SelectChangeEvent) => {
        setSelectedMember(event.target.value as string);
    };

    return (
        <SectionCard
            title={"Coaching Form"}
            scrolling
            fullWidth
            primaryAction={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControl sx={{ minWidth: 200, mr: 2 }}>
                        <InputLabel>Coaching Forms</InputLabel>
                        <Select
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.palette.info.main,
                                },
                                "& .MuiSvgIcon-root": {
                                    color: theme.palette.info.main,
                                },
                            }}
                            value={formType}
                            label="Coaching Forms"
                            variant="outlined"
                            displayEmpty
                            onChange={formSelectChange}
                        >
                            {filteredFormsList.map((form) => (
                                <MenuItem value={form?.type} key={form?.id}>
                                    {form?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Team Members</InputLabel>
                        <Select
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.palette.info.main,
                                },
                                "& .MuiSvgIcon-root": {
                                    color: theme.palette.info.main,
                                },
                            }}
                            value={selectedMember}
                            label="Team Members"
                            variant="outlined"
                            displayEmpty
                            onChange={handleTeamMemberChange}
                        >
                            {team.map((teamMember: any) => (
                                <MenuItem value={teamMember?.user?.entity_no} key={teamMember?.user?.id}>
                                    {teamMember?.user?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {(!selectedMember || !formType) && (
                        <Typography sx={{ marginLeft: 5 }}>Please select a user and a form</Typography>
                    )}
                    {formType && (
                        <Button variant="contained" onClick={() => setFormType("")} sx={{ marginLeft: 5 }}>
                            iQS Rubric
                        </Button>
                    )}
                </Box>
            }
            secondaryAction={
                <>
                    <DatePicker
                        openTo="month"
                        views={["year", "month"]}
                        value={new Date(month)}
                        onChange={handleDateChange}
                        closeOnSelect={false}
                        className="date-picker-beYouCoach"
                        onOpen={() => setIsDateOpen(true)}
                        onClose={() => {
                            performanceDispatch({
                                type: "update_dates",
                                month: selectedDate,
                            });
                            setIsDateOpen(false);
                        }}
                        slotProps={{ textField: { variant: "standard" } }}
                    />
                    <Backdrop sx={{ zIndex: 1 }} open={isDateOpen}></Backdrop>
                </>
            }
        >
            {(selectedMember?.length <= 0 || formType?.length <= 0) && <CoachingAuditForm />}
            {isError && (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                    <Typography>
                        {
                            //@ts-ignore
                            fetchError?.data?.message
                        }
                    </Typography>
                </Box>
            )}
            {isFetching || isLoading ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {selectedMember?.length > 0 && formType?.length > 0 && (
                        <FormBuilder
                            formInformation={formInformation}
                            setUserDetails={setUserDetails}
                            setSelectedMember={setSelectedMember}
                            setMetricID={setMetricID}
                            region={userDetails?.region || ""}
                        />
                    )}
                </>
            )}
        </SectionCard>
    );
};

export default CoachingForms;
