import { Checkbox, FormControlLabel, List, ListItem, StepButton, StepLabel, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import { Autocomplete } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import * as yup from "yup";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import { getLsaRecord } from "../../LowScoreAnalysis.api";
import { t_LsaOption, t_LsaRecord } from "../../LowScoreAnalysis.type";
import {
    getCustomLsaTypes,
    getLsaActions,
    getLsaGrouping,
    getLsaPrimaryCauses,
    getLsaPrimaryTypes,
    getLsaResponsibleScopes,
    getLsaSecondaryCauses,
    getLsaSecondaryTypes,
    getLsaStreams,
    saveLowScoreAnalysis,
} from "./LsaStepper.api";
import lsaStepperStyles, { lsaAutocompleteStyles } from "./LsaStepper.styles";
import { base_LsaForm, t_LsaForm, t_lsaStepperActions } from "./LsaStepper.types";

const LsaAutocomplete: React.FC<{
    label: string;
    error?: any;
    dependents?: {
        [key: string]: number | undefined;
    };
    query: (args: { [key: string]: number }) => Promise<t_LsaOption[]>;
    isCustom?: any;
    customQuery?: (args: { [key: string]: number }) => Promise<any>;
    option: any | null;
    onChange: (option: t_LsaOption | null) => void;
}> = ({ label, error, dependents, query, option, onChange, customQuery }) => {
    const classes = lsaAutocompleteStyles();
    const [options, setOptions] = React.useState<any[]>([]);

    React.useEffect(() => {
        let isActive = true;
        const hasMissing = dependents != null ? Object.values(dependents).findIndex((item) => item == null) : -1;

        if (hasMissing < 0 && !dependents?.custom) {
            query(dependents as { [key: string]: number }).then((data) => {
                if (isActive) {
                    setOptions(data);
                } else {
                }
            });
        } else {
            if (customQuery)
                customQuery(dependents as { [key: string]: number }).then((data) => {
                    if (isActive) {
                    } else {
                    }
                });
        }

        return () => {
            isActive = false;
        };
    }, [dependents]);

    return (
        <Autocomplete
            className={classes.autoComplete}
            value={option}
            options={options}
            getOptionLabel={(item: any) => item.name}
            getOptionDisabled={(item: any) => !item.active}
            isOptionEqualToValue={(item) => item?.id == option?.id}
            onChange={(_, value) => onChange(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    // {...inputProps}
                    label={label}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error}
                />
            )}
        />
    );
};

const lsaSchema = yup
    .object({
        lsa_id: yup.number(),
        landing: yup.boolean().defined(),
        stream: yup.mixed().label("Stream"),
        scope: yup.mixed().label("Scope"),
        action: yup.mixed().label("Action"),
        group: yup.mixed().label("Group"),
        primary_type: yup.mixed().label("Primary type"),
        primary_cause: yup.mixed().label("Primary cause"),
        secondary_type: yup.mixed().label("Secondary type"),
        secondary_cause: yup.mixed().label("Secondary cause"),
        custom: yup.mixed().optional(),
        practice_type: yup.mixed().optional(),
        appeal_type: yup.mixed().optional(),
        missing_information_type: yup.mixed().optional(),
        inquiry_opened_or_ca_ticket_logged: yup.mixed().optional(),
    })
    .defined();

type Props = {
    lsa_id?: number;
    data_id: number;
    metric_id: number;
    dispatch: (action: t_lsaStepperActions) => void;
};

const LsaStepper: React.FC<Props> = ({ lsa_id, metric_id, data_id, dispatch }) => {
    /** STATES */
    const classes = lsaStepperStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [customInputs, setCustomInputs] = React.useState(false);
    const [customValues, setCustomValues] = React.useState<any>({});
    const [customKeys, setCustomKeys] = React.useState([""]);
    const [lsaRecord, setLsaRecord] = React.useState<t_LsaRecord>();

    const formik = useFormik({
        initialValues: base_LsaForm,
        validationSchema: lsaSchema,
        onSubmit: (formData: any) =>
            new Promise((resolve, reject) => {
                saveLowScoreAnalysis(formData)
                    .then((resp) => {
                        dispatch({ type: "save" });
                        enqueueSnackbar("LSA Saved!", {
                            variant: "success",
                        });
                        resolve(true);
                    })
                    .catch((reason) => {
                        enqueueSnackbar("We ran into an issue.", {
                            variant: "error",
                        });
                        reject(reason);
                    });
            }),
    });

    /** METHODS */

    const handleClear = () => {
        formik.setValues({ ...base_LsaForm, lsa_id: lsaRecord?.lsa_id }, false);
        setActiveStep(0);
    };

    const handleReset = () => {
        formik.setValues(lsaRecord ?? base_LsaForm, false);
    };

    const handleSubmit = () => {
        formik.submitForm();
    };

    const handleStep = (index: number) => {
        setActiveStep(index);
    };

    const resetDependents = (name: string) => {
        switch (name) {
            case "stream":
                return {
                    group: null,
                    primary_type: null,
                    primary_cause: null,
                    secondary_type: null,
                    secondary_cause: null,
                };
            case "group":
                return {
                    primary_cause: null,
                };
            case "primary_type":
                return {
                    secondary_type: null,
                };
            case "primary_cause":
                return {
                    secondary_cause: null,
                };
            default:
                return {};
        }
    };
    const handleSelectChange = React.useCallback(
        (name: string, value: any) => {
            const reset = {
                ...formik.values,
                ...resetDependents(name),
                [name]: value,
            };
            formik.setValues(reset, false);
        },
        [formik],
    );

    React.useEffect(() => {
        var isActive = true;
        if (lsa_id != null) {
            getLsaRecord({ lsa_id }).then((data) => {
                if (isActive) {
                    setLsaRecord(data);
                    if (data?.stream?.custom == 1) {
                        getCustomLsaTypes({
                            stream_id: data?.stream?.id,
                        }).then((data: any) => {
                            setCustomKeys(Object.keys(data));
                            setCustomValues(data);
                        });
                        setCustomInputs(true);
                    }
                    if (data != null) {
                        formik.setValues(data, false);
                    }
                }
            });
        }
    }, [lsa_id]);

    React.useEffect(() => {
        if (formik.values.stream?.custom == 1) {
            setCustomInputs(true);
            getCustomLsaTypes({ stream_id: formik.values.stream.id }).then((data: any) => {
                setCustomKeys(Object.keys(data));
                setCustomValues(data);
            });
        } else {
            setCustomInputs(false);
        }
    }, [formik.values.stream?.custom]);

    return (
        <SectionCard
            // fullWidth
            hasBackground
            noHeader
            // scrolling
            bottomActions={
                <div className={classes.actionsContainer}>
                    {lsa_id != null && (
                        <>
                            <Button onClick={handleClear}>Clear</Button>
                            <Button onClick={handleReset}>Reset</Button>
                        </>
                    )}
                    <SubmitButton loading={formik.isSubmitting} onClick={handleSubmit}>
                        Save
                    </SubmitButton>
                </div>
            }
        >
            <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                <Step completed={formik.values.stream != null}>
                    <StepButton onClick={() => handleStep(0)}>
                        <StepLabel error={Boolean(formik.errors.stream)}>Stream</StepLabel>
                    </StepButton>
                    <StepContent>
                        <List>
                            <ListItem disableGutters>
                                <LsaAutocomplete
                                    label="Select Stream"
                                    query={getLsaStreams}
                                    option={formik.values.stream}
                                    error={formik.errors.stream}
                                    onChange={(option) => {
                                        handleSelectChange("stream", option);
                                    }}
                                />
                            </ListItem>
                        </List>
                    </StepContent>
                </Step>
                {customInputs && customValues
                    ? customKeys.length > 1
                        ? customKeys.map((item: any, index) => {
                              return (
                                  <Step
                                      completed={
                                          customValues[item]
                                              ? formik.values[customValues[item].form_value] != null
                                              : false
                                      }
                                      key={index}
                                  >
                                      <StepButton onClick={() => handleStep(index + 1)}>
                                          <StepLabel>{item}</StepLabel>
                                      </StepButton>
                                      <StepContent>
                                          <List>
                                              <ListItem>
                                                  {Object.keys(customValues).length > 1 ? (
                                                      <Autocomplete
                                                          key={index}
                                                          className={classes.autoComplete}
                                                          value={
                                                              formik.values[customValues[item].form_value]
                                                                  ? formik.values[customValues[item].form_value]
                                                                  : null
                                                          }
                                                          options={customValues[item].data}
                                                          isOptionEqualToValue={(item, value) => item.id == value.id}
                                                          getOptionLabel={(option) => option.name}
                                                          getOptionDisabled={(item: any) => !item.active}
                                                          onChange={(_, value) => {
                                                              handleSelectChange(customValues[item].form_value, value);
                                                          }}
                                                          renderInput={(params) => (
                                                              <TextField
                                                                  {...params}
                                                                  // {...inputProps}
                                                                  label={item}
                                                              />
                                                          )}
                                                      />
                                                  ) : null}
                                              </ListItem>
                                          </List>
                                      </StepContent>
                                  </Step>
                              );
                          })
                        : null
                    : null}
                {!customInputs ? (
                    <Step completed={formik.values.primary_type != null && formik.values.secondary_type != null}>
                        <StepButton onClick={() => handleStep(1)}>
                            <StepLabel
                                error={Boolean(formik.errors.primary_type) || Boolean(formik.errors.secondary_type)}
                            >
                                {!customInputs ? "Query Types" : "Custom Value"}
                            </StepLabel>
                        </StepButton>
                        <StepContent>
                            <List>
                                <ListItem>
                                    <LsaAutocomplete
                                        label="Primary Query Type"
                                        dependents={
                                            formik.values.stream?.custom
                                                ? {
                                                      stream_id: formik.values.stream?.id,
                                                      custom: formik.values.stream?.custom,
                                                  }
                                                : {
                                                      stream_id: formik.values.stream?.id,
                                                  }
                                        }
                                        query={getLsaPrimaryTypes}
                                        customQuery={getCustomLsaTypes}
                                        option={formik.values.primary_type}
                                        error={formik.errors.primary_type}
                                        onChange={(option) => handleSelectChange("primary_type", option)}
                                    />
                                </ListItem>
                                <ListItem>
                                    <LsaAutocomplete
                                        label="Secondary Query Type"
                                        dependents={{
                                            stream_id: formik.values.stream?.id,
                                            primary_query_id: formik.values.primary_type?.id,
                                        }}
                                        query={getLsaSecondaryTypes}
                                        option={formik.values.secondary_type}
                                        error={formik.errors.secondary_type}
                                        onChange={(option) => handleSelectChange("secondary_type", option)}
                                    />
                                </ListItem>
                            </List>
                        </StepContent>
                    </Step>
                ) : null}
                {!customInputs ? (
                    <Step
                        completed={
                            formik.values.group != null &&
                            formik.values.primary_cause != null &&
                            formik.values.secondary_cause != null
                        }
                    >
                        <StepButton onClick={() => handleStep(2)}>
                            <StepLabel
                                error={
                                    Boolean(formik.errors.group) ||
                                    Boolean(formik.errors.primary_cause) ||
                                    Boolean(formik.errors.secondary_cause)
                                }
                            >
                                Low Score
                            </StepLabel>
                        </StepButton>
                        <StepContent>
                            <List>
                                <ListItem>
                                    <LsaAutocomplete
                                        label="Low Score Grouping"
                                        option={formik.values.group}
                                        error={formik.errors.group}
                                        onChange={(value) => handleSelectChange("group", value)}
                                        query={getLsaGrouping}
                                        dependents={{
                                            stream_id: formik.values.stream?.id,
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <LsaAutocomplete
                                        option={formik.values.primary_cause}
                                        error={formik.errors.primary_cause}
                                        onChange={(value) => handleSelectChange("primary_cause", value)}
                                        label="Primary Low Score Cause"
                                        query={getLsaPrimaryCauses}
                                        dependents={{
                                            stream_id: formik.values.stream?.id,
                                            group_id: formik.values.group?.id,
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <LsaAutocomplete
                                        option={formik.values.secondary_cause}
                                        error={formik.errors.secondary_cause}
                                        onChange={(value) => handleSelectChange("secondary_cause", value)}
                                        label="Secondary Low Score Cause"
                                        query={getLsaSecondaryCauses}
                                        dependents={{
                                            stream_id: formik.values.stream?.id,
                                            primary_cause_id: formik.values.primary_cause?.id,
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </StepContent>
                    </Step>
                ) : null}
                {!customInputs ? (
                    <Step completed={formik.values.scope != null}>
                        <StepButton onClick={() => handleStep(3)}>
                            <StepLabel error={Boolean(formik.errors.scope)}>Responsible Department</StepLabel>
                        </StepButton>
                        <StepContent>
                            <List>
                                <ListItem>
                                    <LsaAutocomplete
                                        option={formik.values.scope}
                                        error={formik.errors.scope}
                                        onChange={(value) => handleSelectChange("scope", value)}
                                        label="Select Department"
                                        query={getLsaResponsibleScopes}
                                    />
                                </ListItem>
                            </List>
                        </StepContent>
                    </Step>
                ) : null}
                {!customInputs ? (
                    <Step completed={formik.values.action != null}>
                        <StepButton onClick={() => handleStep(4)}>
                            <StepLabel error={Boolean(formik.errors.action)}>Action Taken</StepLabel>
                        </StepButton>
                        <StepContent>
                            <List>
                                <ListItem>
                                    <LsaAutocomplete
                                        option={formik.values.action}
                                        error={formik.errors.action}
                                        onChange={(value) => handleSelectChange("action", value)}
                                        label="Select Action"
                                        query={getLsaActions}
                                    />
                                </ListItem>
                                <ListItem>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="landing"
                                                checked={formik.values.landing}
                                                onChange={(_, checked) => handleSelectChange("landing", checked)}
                                            />
                                        }
                                        label="Soft Landing"
                                    />
                                </ListItem>
                            </List>
                        </StepContent>
                    </Step>
                ) : null}
            </Stepper>
        </SectionCard>
    );
};

export default LsaStepper;
