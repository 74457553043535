import React from "react";
import { MiscBody } from "../../types/api.types";

type Props<Options, Params> = {
	dataSource: (
		args: { search: string; params?: Params } & MiscBody
	) => Promise<Options[]>;
	initialParams?: Params;
};
// still in progress
function useAutocomplete<Options extends object, Params extends object>({
	dataSource,
	initialParams,
}: Props<Options, Params>) {
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [params, setParams] = React.useState<Params>();
	const [options, setOptions] = React.useState<Options[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const timeout = React.useRef<NodeJS.Timeout>();

	const search = React.useCallback(setSearchTerm, [setSearchTerm]);

	const updateParams = React.useCallback(setParams, [setParams]);

	// on search change or parameter change initiate timer
	React.useEffect(() => {
		var isActive = true;
		if (timeout.current != null) {
			clearTimeout(timeout.current);
		}
		setLoading(true);
		timeout.current = setTimeout(() => {
			dataSource({
				search: searchTerm,
				params: params,
				pagination: {
					page: 0,
					rows_per_page: 30,
				},
			})
				.then((values) => {
					if (isActive) {
						setOptions(values);
					}
				})
				.finally(() => {
					if (isActive) {
						setLoading(false);
					}
				});
		}, 500);

		return () => {
			isActive = false;
			if (timeout.current != null) {
				clearTimeout(timeout.current);
			}
		};
	}, [searchTerm, params, dataSource]);

	// update params may not be necessary but it is here for future use
	return { search, updateParams, options, loading };
}

export type { Props as useAutocompleteProps };
export default useAutocomplete;
