import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const maintainHelpFilesStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// backgroundColor: "#ebebeb",
			height: "100%",
		},
		container: {
			// display: "flex",
			// flexDirection: "column-reverse",
			height: "90%",
		},
		editorContainer: {
			height: "100%",
			width: "100%",
			padding: theme.spacing(1),
		},
		editor: {
			// backgroundColor: "#ebebeb",
			// padding: "20px",
			height: "100%",
			// maxHeight: "200px",
			// overflow: "auto",
			border: `1px solid ${theme.palette.divider}`,
			"& .editor-anchor": {
				color: "blue",
				textDecoration: "underline",
			},
		},
		toolbar: {
			// borderBottom: "1px solid gray",
			// backgroundColor: "#ebebeb",
		},
		placeHolder: {
			// backgroundColor: "#ebebeb",
			// paddingLeft: 20,
			width: "100%",
			// border: `1px solid ${theme.palette.divider}`,
		},
		anchorLink: {
			color: "blue",
			textDecoration: "underline",
		},
	})
);

export default maintainHelpFilesStyles;
