import { httpClient } from "../../../../clients/http.client";
import { MiscBody } from "../../../../types/api.types";

type t_Badge = {
    id: number;
    name: string;
    badge_img: string;
    description: string;
    is_system: number;
};

const getAwardableBadges = (payload: MiscBody) =>
    new Promise<t_Badge[]>((resolve, reject) => {
        httpClient
            .post("/badges/awardable", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(data.data.badges);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });
const awardUserBadge = (payload: { entity_no: number; badge_id: number; comment?: string }) =>
    new Promise<boolean>((resolve, reject) => {
        httpClient
            .post("/badges/award", payload)
            .then(({ data }) => {
                switch (data.status) {
                    case "success":
                        resolve(true);
                        break;
                    default:
                        reject(data.message);
                }
            })
            .catch(reject);
    });

export type { t_Badge };
export { awardUserBadge, getAwardableBadges };
