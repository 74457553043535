import { Button, ButtonGroup, FormControl, Grid, IconButton, Input, InputAdornment } from "@mui/material";
import { Cancel, Delete, Edit, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import SectionCard from "../../components/SectionCard/SectionCard";
import { useUserDispatch } from "../../context/user/user.ctx";
import UserSearch from "../User/sections/UserSearch/UserSearch";
import maintainUserStyles from "./MaintainUser.style";
import UserDetails from "./UserDetails/UserDetails";

const MaintainUser: React.FC = () => {
    const classes = maintainUserStyles();
    const navigate = useNavigate();
    const userObjectDispatch = useUserDispatch();
    const [userSearch, setUserSearch] = useState(" ");
    const [selectedUser, setSelectedUser] = useState<any>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserSearch(event.target.value);
    };

    const masqueradeAs = () => {
        userObjectDispatch({
            type: "REQUEST_MASQUERADE",
            data: { entity_no: selectedUser.entity_no },
        });
        navigate("/beyoucoach/dashboard");
    };

    return (
        <SectionCard title="Maintain User">
            <Grid container spacing={2} className={classes.grid}>
                <Grid item sm={5} className={classes.grid}>
                    <SectionCard
                        title="Search"
                        titleProps={{ variant: "h6" }}
                        primaryAction={
                            <FormControl>
                                <Input
                                    value={userSearch}
                                    onChange={handleSearchChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setUserSearch("" + userSearch)} size="large">
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                ></Input>
                            </FormControl>
                        }
                        scrolling
                    >
                        <UserSearch searchTerm={userSearch} onUserSelected={setSelectedUser} />
                    </SectionCard>
                </Grid>
                {selectedUser && (
                    <Grid item sm={7} className={classes.grid}>
                        <SectionCard
                            title={selectedUser.name}
                            titleProps={{ variant: "h6" }}
                            primaryAction={<Button onClick={() => masqueradeAs()}>Masquerade</Button>}
                            // secondaryAction={
                            //     <ButtonGroup>
                            //         <IconButton size="large">
                            //             <Edit />
                            //         </IconButton>
                            //         <IconButton size="large">
                            //             <Delete />
                            //         </IconButton>
                            //         <IconButton size="large">
                            //             <Cancel />
                            //         </IconButton>
                            //     </ButtonGroup>
                            // }
                            scrolling
                        >
                            <UserDetails entity_no={selectedUser.entity_no} />
                        </SectionCard>
                    </Grid>
                )}
            </Grid>
        </SectionCard>
    );
};

export default MaintainUser;
