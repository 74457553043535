import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import SurveyPeriods from "../../tables/SurveyPeriods/SurveyPeriods";

const MaintainPeriods: React.FC = () => {
    const [year, setYear] = React.useState<Date>(new Date());

    return (
        <SectionCard
            title="Maintain Survey Periods"
            secondaryAction={
                <DatePicker
                    value={year}
                    onChange={(date) => date && setYear(date)}
                    openTo="year"
                    views={["year"]}
                    // label="Year"
                    slotProps={{ textField: { variant: "standard" } }}
                />
            }
            scrolling
        >
            <SurveyPeriods year={year} />
        </SectionCard>
    );
};

export default MaintainPeriods;
