import { Button, CircularProgress, Dialog, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ArrowBack, Clear, Search, Star } from "@mui/icons-material";
import React from "react";
import SectionCard from "../../../../components/SectionCard/SectionCard";
import ProfileCard from "../../../Profile/components/ProfileCard/ProfileCard";
import { t_ProfileCardUser } from "../../../Profile/components/ProfileCard/ProfileCard.types";
import AwardBadge from "../../components/AwardBadge/AwardBadge";
import { t_UserBase } from "../../User.types";
import FullProfile from "../FullProfile/FullProfile";
import { getMyFriends } from "./FriendSearch.api";
import friendSearchStyles from "./FriendSearch.style";
import BeYouCoach from "../../../../pages/Home/BeYouCoach/BeYouCoach";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../../redux/slices/UserSlice";
import FriendProfile from "./FriendProfile";

interface Props {}

const FriendSearch: React.FC<Props> = () => {
    const classes = friendSearchStyles();
    const [friends, setFriends] = React.useState<t_ProfileCardUser[]>([]);
    const [viewFriend, setViewFriend] = React.useState<t_ProfileCardUser>();
    const [awardBadge, setAwardBadge] = React.useState<t_UserBase>();

    const [visualFeedback, setVisualFeedback] = React.useState<{
        loading: boolean;
    }>({
        loading: false,
    });
    const [search, setSearch] = React.useState<string>("");
    const dispatch = useDispatch();

    const handleAwardBadge = () => {
        if (viewFriend != null) {
            // changes to t_UserBase have downstream impact - we don't want user_id's included in all user models
            setAwardBadge({
                ...viewFriend,
                name: viewFriend.display_name,
                id: -1,
            });
        }
    };

    const handleDialogClose = () => {
        setAwardBadge(undefined);

        setViewFriend((state) => {
            if (state == null) return state;

            const entity_no = state.entity_no;

            return {
                ...state,
                entity_no,
            };
        });
    };

    React.useEffect(() => {
        var isActive = true;
        setVisualFeedback((state) => ({ ...state, loading: true }));
        getMyFriends().then((data) => {
            if (isActive) {
                setFriends(data.data.friends);

                setVisualFeedback((state) => ({ ...state, loading: false }));
            }
        });
        return () => {
            isActive = false;
        };
    }, []);
    return (
        <SectionCard
            scrolling
            primaryAction={
                viewFriend ? (
                    <Button startIcon={<ArrowBack />} onClick={() => setViewFriend(undefined)}>
                        Back to Search
                    </Button>
                ) : (
                    <TextField
                        className={classes.searchInput}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: search && (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setSearch("")} size="large">
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Find a friend..."
                        fullWidth
                    />
                )
            }
            secondaryAction={
                viewFriend && (
                    <Button startIcon={<Star />} onClick={handleAwardBadge}>
                        Award a Badge
                    </Button>
                )
            }
        >
            {viewFriend ? (
                <>
                    {/* <FullProfile entity_no={viewFriend.entity_no} /> */}
                    <FriendProfile entity_no={viewFriend.entity_no} />
                </>
            ) : (
                <Grid container spacing={2} justifyContent={visualFeedback.loading ? "center" : undefined}>
                    {!!visualFeedback.loading ? (
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        // We are properly cheating but there are issues with the backend handling pagination on the union friend relation
                        friends
                            .filter(
                                (user) =>
                                    user.display_name?.toLowerCase().includes(search.toLowerCase()) ||
                                    user.entity_no?.toString().includes(search),
                            )
                            .map((friend) => (
                                <Grid item lg={4} md={6} key={`friend-card-${friend.entity_no}`}>
                                    <ProfileCard
                                        user={friend}
                                        onClick={() => {
                                            setViewFriend(friend);
                                            dispatch(setUserInfo(friend));
                                        }}
                                    />
                                </Grid>
                            ))
                    )}
                </Grid>
            )}
            <Dialog open={Boolean(awardBadge)} onClose={handleDialogClose}>
                {awardBadge && <AwardBadge user={awardBadge} />}
            </Dialog>
        </SectionCard>
    );
};

export default FriendSearch;
