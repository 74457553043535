import { Backdrop, Drawer, IconButton, List, ListItemText, useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import ViewList from "@mui/icons-material/ViewList";
import { Column } from "material-table";
import React from "react";
import BUMaterialTable from "../../../../components/BUMaterialTable/BUMaterialTable";
import SectionCard, { SectionCardProps } from "../../../../components/SectionCard/SectionCard";
import SectionedDialog from "../../../../components/SectionedDialog/SectionedDialog";
import TabGroup from "../../../../components/TabGroup/TabGroup";
import AppealRecord from "../../../Appeals/components/AppealRecord/AppealRecord";
import LsaRecord from "../../../LowScoreAnalysis/components/LsaRecord/LsaRecord";
import { t_SurveyPeriodArr } from "../../../Surveys/Surveys.types";
import { actions_AgentSurveyRecord } from "../../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecords.api";
import AgentSurveyRecordsMulti from "../../../Surveys/tables/AgentSurveyRecords/AgentSurveyRecordsMulti";
import { SurveyReports } from "../../PerceptionReport";
import {
    AgentColumns,
    getTeamLeaderSummariesAny,
    getTeamMemberSummariesAny,
    TeamLeaderColumns,
    TeamMember,
} from "./TeamLeaderSummary.api";
import teamLeaderSummaryStyles from "./TeamLeaderSummary.style";

type t_DialogState = {
    lsa_id?: number;
    appeal_id?: number;
    comment?: string;
};

const dialogDispatcher = (state: t_DialogState, { action, rowData }: actions_AgentSurveyRecord): t_DialogState => {
    if (!rowData) return {};

    switch (action) {
        case "lsa":
            return {
                lsa_id: rowData.LsaID,
            };
        case "appeal":
            return {
                appeal_id: rowData.AppealID,
            };
        case "comment":
            return {
                comment: rowData.Comments,
            };
    }
    return {};
};

interface TeamLeaderSummaryMultiProps {
    periods: {
        [key: string]: t_SurveyPeriodArr;
    };
    report: SurveyReports;
    columns: (base: Column<any>[]) => Column<any>[];
    secondaryTab?: number;
    setSecondaryTab?: React.Dispatch<React.SetStateAction<number>>;
    isLsa?: boolean;
    sectionCardProps?: SectionCardProps;
}

export const secondaryTabOptions: ("mbr" | "fcr")[] = ["mbr", "fcr"];

const TeamLeaderSummaryMulti: React.FC<TeamLeaderSummaryMultiProps> = ({
    periods,
    report,
    columns,
    secondaryTab,
    setSecondaryTab,
    isLsa = false,
    sectionCardProps,
}) => {
    const theme = useTheme();
    const classes = teamLeaderSummaryStyles();
    const tableRef = React.createRef<any>();
    const dialogRef = React.createRef<any>();
    const [viewAgent, setViewAgent] = React.useState<TeamMember>();
    const [dialogState, dialogDispatch] = React.useReducer(dialogDispatcher, {});
    const [surveyType, setSurveyType] = React.useState<"mbr" | "fcr">("mbr");

    const handleAgentView = (agent: TeamMember) => {
        setViewAgent(agent);
    };

    React.useEffect(() => {
        if (tableRef.current != null && !tableRef.current.isLoading) {
            tableRef.current.onQueryChange();
        }
    }, [periods, secondaryTab]);

    return (
        <SectionCard
            title="Team Leader Details"
            titleProps={{ variant: "subtitle1" }}
            fullWidth
            primaryAction={
                isLsa && (
                    <TabGroup
                        tabValue={typeof secondaryTab === "undefined" ? 0 : secondaryTab}
                        tabChange={(value) => setSecondaryTab && setSecondaryTab(value)}
                        tabList={[
                            {
                                _id: 0,
                                label: "MBR",
                            },
                            {
                                _id: 1,
                                label: "FCR",
                            },
                        ]}
                    />
                )
            }
            {...sectionCardProps}
        >
            <BUMaterialTable
                key={`${report}-team-leader-table`}
                tableRef={tableRef}
                columns={columns(TeamLeaderColumns)}
                data={(query) =>
                    getTeamLeaderSummariesAny({
                        query,
                        report,
                        periods,
                        isLsa,
                        survey_type_only: secondaryTabOptions[typeof secondaryTab === "undefined" ? 0 : secondaryTab],
                    })
                }
                options={{
                    padding: "dense",
                    toolbar: false,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                detailPanel={(rowData) => (
                    <div className={classes.agentTableContainer}>
                        <BUMaterialTable
                            columns={columns(AgentColumns)}
                            data={(query) =>
                                getTeamMemberSummariesAny({
                                    query,
                                    report,
                                    periods,
                                    teamLeader: rowData as TeamMember,
                                    survey_type_only: rowData.SurveyType,
                                    isLsa,
                                })
                            }
                            localization={{
                                header: {
                                    actions: "View",
                                },
                            }}
                            options={{
                                toolbar: false,
                                padding: "dense",
                            }}
                            actions={[
                                {
                                    icon: ViewList,
                                    tooltip: "View Agent Records",
                                    onClick: (_, rowData) => {
                                        setSurveyType(rowData.SurveyType.toLowerCase());
                                        handleAgentView(rowData as TeamMember);
                                    },
                                },
                            ]}
                        />
                    </div>
                )}
            />
            <SectionedDialog
                // ref={dialogRef}
                open={Boolean(viewAgent)}
                onClose={() => {
                    setViewAgent(undefined);
                    dialogDispatch({});
                }}
                fullWidth
                maxWidth="lg"
                title={`${viewAgent?.DisplayName} - ${report.length > 3 ? report : report.toUpperCase()} Records`}
            >
                <Drawer
                    anchor="right"
                    variant="persistent"
                    PaperProps={{ style: { position: "absolute" } }}
                    BackdropProps={{ style: { position: "absolute" } }}
                    ModalProps={{
                        container: dialogRef.current,
                        style: { position: "absolute" },
                    }}
                    open={Boolean(
                        dialogState.lsa_id !== undefined ||
                            dialogState.appeal_id !== undefined ||
                            dialogState.comment !== undefined,
                    )}
                    onClose={() => dialogDispatch({})}
                >
                    <SectionCard
                        title={
                            (dialogState.lsa_id !== undefined && "LSA Details") ||
                            (dialogState.appeal_id !== undefined && "Appeal Details") ||
                            (dialogState.comment !== undefined && "Comment") ||
                            ""
                        }
                        titleProps={{ variant: "h6" }}
                        secondaryAction={
                            <IconButton onClick={() => dialogDispatch({})} size="large">
                                <ArrowForward />
                            </IconButton>
                        }
                        scrolling
                    >
                        {dialogState.lsa_id !== undefined && <LsaRecord lsa_id={dialogState.lsa_id} />}
                        {dialogState.appeal_id !== undefined && <AppealRecord appeal_id={dialogState.appeal_id} />}
                        {dialogState.comment !== undefined && (
                            <List>
                                <ListItemText
                                    style={{
                                        width: 400,
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {dialogState.comment}
                                </ListItemText>
                            </List>
                        )}
                    </SectionCard>
                </Drawer>
                <Backdrop
                    open={Boolean(
                        dialogState.lsa_id !== undefined ||
                            dialogState.appeal_id !== undefined ||
                            dialogState.comment !== undefined,
                    )}
                    onClick={() => dialogDispatch({})}
                    style={{ zIndex: theme.zIndex.drawer - 1 }}
                />
                {viewAgent && (
                    <AgentSurveyRecordsMulti
                        periods={periods}
                        survey={report}
                        entity_no={viewAgent.EntityNo}
                        dispatch={dialogDispatch}
                        survey_type_only={isLsa ? surveyType : undefined}
                    />
                )}
            </SectionedDialog>
        </SectionCard>
    );
};

export default TeamLeaderSummaryMulti;
